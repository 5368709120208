var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mx-5 my-2" },
    [
      _c("v-card-title", { staticStyle: { "background-color": "gainsboro" } }, [
        _c("h2", { staticClass: "subheader" }, [_vm._v("Summary")]),
      ]),
      _vm._v(" "),
      _c("v-data-table", {
        attrs: {
          headers: _vm.setTableHeaders(),
          items: [_vm.summary],
          "hide-default-footer": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function (props) {
              return [
                _c("tr", [
                  _c("td", { staticClass: "text-xs-center" }, [
                    _vm._v(
                      "£" + _vm._s(props.item["United Kingdom"].toFixed(2))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-xs-center" }, [
                    _vm._v(
                      "£" + _vm._s(props.item["EU VAT Registered"].toFixed(2))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-xs-center" }, [
                    _vm._v(
                      "£" +
                        _vm._s(props.item["EU Non VAT Registered"].toFixed(2))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-xs-center" }, [
                    _vm._v(
                      "£" + _vm._s(props.item["Rest Of World"].toFixed(2))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-xs-center" }, [
                    _vm._v("£" + _vm._s(props.item["All Regions"].toFixed(2))),
                  ]),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }