var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { md: "6", lg: "4", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [_c("v-icon", [_vm._v("today")]), _vm._v(" Calendar\n        ")],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                { staticClass: "d-md-none" },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _vm._v(
                        "\n                    Mobile stack view\n                    "
                      ),
                      _vm._l(
                        _vm.appointmentsByDate.appointments,
                        function (cell, rowIndex) {
                          return _c(
                            "v-col",
                            { key: rowIndex, attrs: { cols: "12" } },
                            [
                              _c("v-flex", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(cell.date) +
                                    " - " +
                                    _vm._s(
                                      cell.isSelected ? "selected" : "false"
                                    ) +
                                    "\n                            "
                                ),
                                _c(
                                  "ul",
                                  _vm._l(
                                    cell.appointments,
                                    function (appointment, apptIndex) {
                                      return _c("li", [
                                        _vm._v(
                                          _vm._s(
                                            appointment.staff.first_name +
                                              " " +
                                              appointment.staff.last_name
                                          ) +
                                            ": " +
                                            _vm._s(appointment.label)
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-container",
                { staticClass: "d-none d-md-flex" },
                [
                  _c("v-layout", { attrs: { row: "", wrap: "" } }, [
                    _vm._v(
                      "\n                    Desktop calendar view\n                "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-btn", { on: { click: _vm.selectPreviousDate } }, [
                _vm._v("< Previous Date"),
              ]),
              _vm._v(" "),
              _c("v-btn", { on: { click: _vm.selectNextDate } }, [
                _vm._v("Next Date >"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }