var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "row-sm": "", column: "" } },
        [
          this.field.schema.displayName != "none"
            ? _c("v-flex", { attrs: { xs12: "" } }, [
                _c("label", { attrs: { for: this.field.fieldName } }, [
                  _vm._v(" " + _vm._s(this.field.schema.displayName) + ": "),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.input,
                  expression: "input",
                },
              ],
              attrs: {
                name: this.field.fieldName,
                type: "text",
                required: this.field.required,
                disabled: this.field.disabled,
              },
              domProps: { value: _vm.input },
              on: {
                keyup: function ($event) {
                  return _vm.updateField()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.input = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _vm.field.errors
            ? _c(
                "v-alert",
                {
                  staticStyle: { width: "100%" },
                  attrs: { type: "error", value: _vm.field.errors },
                },
                _vm._l(_vm.field.errors, function (error) {
                  return _c("li", [
                    _vm._v(
                      "\n                " + _vm._s(error) + "\n            "
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }