<template>
    <v-container fluid>
       <v-layout row-sm column>
            <v-col v-if="this.field.schema.displayName != 'none'" cols="12">
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-col>

            <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable :value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            <v-alert type="warning"  dismissable :value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            <v-alert type="error" dismissable :value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>

            <v-row>
				<v-col sm="12" class="d-flex flex-column">
					<input type="hidden" :name="field.fieldName" :value="field.value">
					<v-row v-for="phone in telephoneNumbers">
						<h4>{{ phone.phone_number }}</h4>
					</v-row>
					<v-text-field
						label="Test"
						:name="this.field.fieldName"
						:disabled="this.field.disabled"
					></v-text-field>

					<v-col>
						 <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
					  		 <li v-for="error in field.errors">
						  		 {{ error }}
							 </li>
					 	</v-alert>
					</v-col>
				</v-col>
            </v-row>
         </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'ies-input-telephone-numbers',
    props: ['field', 'recordData'],
    data () {
        return {
			telephoneNumbers: [],
        }
    },
	created: function() {
		this.getTelephoneNumbers();
	},
	methods: {
		getTelephoneNumbers() {
			axios.get('/api/telephone-numbers/' + this.recordData.hotel_key, {
				params: {}
			}).then(function(response) {
				var allPhones = response.data.phones; 
				if ( this.field.schema.displayName === "Telephone" ) {
					this.telephoneNumbers = allPhones.filter((phone) => { return phone.is_telephone });
				} else if ( this.field.schema.displayName === "Fax" ) {
					this.telephoneNumbers = allPhones.filter((phone) => { return phone.is_fax });
				}
				console.log(this.telephoneNumbers);
			}.bind(this));
		},
	},
	watch: {
		telephoneNumbers: function (newValue) {
			console.log(newValue);
		}
	}	
}
</script>

