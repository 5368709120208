var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "3", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("home")]),
              _vm._v("Manage Inventory \n            "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: { block: "", href: _vm.roomTypesUrl },
                            },
                            [
                              _c("v-icon", [_vm._v("room_preferences")]),
                              _vm._v("Room Types"),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: { block: "", href: _vm.rateTypesUrl },
                            },
                            [
                              _c("v-icon", [_vm._v("price_change")]),
                              _vm._v("Rate Types"),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: {
                                block: "",
                                disabled: "",
                                href: _vm.rateExtrasUrl,
                              },
                            },
                            [
                              _c("v-icon", [_vm._v("add_box")]),
                              _vm._v("Rate Extras"),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: {
                                block: "",
                                disabled: "",
                                href: _vm.longTermOverviewUrl,
                              },
                            },
                            [
                              _c("v-icon", [_vm._v("calendar_view_month")]),
                              _vm._v("Dashboard"),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: { block: "", href: _vm.dailyPlannerUrl },
                            },
                            [
                              _c("v-icon", [_vm._v("edit_calendar")]),
                              _vm._v("Daily Planner"),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: {
                                block: "",
                                disabled: "",
                                href: _vm.batchLoaderUrl,
                              },
                            },
                            [
                              _c("v-icon", [_vm._v("upload_file")]),
                              _vm._v("Batch Loader"),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: { block: "", href: _vm.cloneRateDatesUrl },
                            },
                            [
                              _c("v-icon", [_vm._v("content_copy")]),
                              _vm._v("Clone Historic Rates"),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: {
                                block: "",
                                disabled: "",
                                href: _vm.specialOffersUrl,
                              },
                            },
                            [
                              _c("v-icon", [_vm._v("star")]),
                              _vm._v("Special Offers"),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: {
                                block: "",
                                disabled: "",
                                href: _vm.changeLogUrl,
                              },
                            },
                            [
                              _c("v-icon", [_vm._v("history")]),
                              _vm._v("Change Log"),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: {
                                block: "",
                                disabled: "",
                                href: _vm.rateSummaryUrl,
                              },
                            },
                            [
                              _c("v-icon", [_vm._v("summarize")]),
                              _vm._v("Rate Summary"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }