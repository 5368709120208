<template>
<v-container pa-0 >
    <v-layout row>
        <v-col cols="12">
            <v-select
                :items="regionList"
                label="Region"
                solo
                outlined
                dense
                :search-input.sync="search"
                autocomplete
                v-model="selectedRegion"
                v-on:change="updateRegionSelected"
                v-on:keyup.enter="gotoRegionDashboard"
                prepend-icon="place"
                color="lime"
            ></v-select>
        </v-col>
    </v-layout>
</v-container>
</template>


<script>
    // Import the application event bus so we can listen for data changes and react accordingly
    import EventBus from '../../eventBus';
    import axios from 'axios';

    /**
     * Component to allow the user to select a region and immediately be taken through to their dashboard page
     */
    export default {
            props:[],
            data: function(){
                return {
                    search: '',
                    /** @var [] allRegions     - The list of select options as an array of objects ready for the :items prop of the select box */
                    allRegions:      [],

                    /** @var {} selectedRegion - The view model showing what region the user has selected in the UI */
                    selectedRegion:  { value: '', text: '' },
                };
            },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            this.loadData();
        },

        methods: {
            /**
             * Obtains a fresh local copy of the region listing to be used in this component from the app
             * Updates the this.allRegions list to match the values received, such that the value of the select box will be the region key and the text label will be code and region name
             */
            loadData(){
                var self = this;

                axios.get('/api/regions')
                    .then(self.setRegionList)
                    .catch(function(exception){
                        console.log('%cError obtaining region list for region picker component', 'color:red');
                        console.log(exception);
                    });
            },

            /**
             * On successfully obtaining a new list of regions we need to set up a new view model representing the data in a format that the user interface components can use
             * Presently this is a select box which expects to have an array of objects containing the properties:
             *      'text':     region_name
             *      'value':    region_key
             */
            setRegionList: function(response){
                for (var index = 0; index < response.data.length; index++){
                    this.addToRegionList(response.data[index]);
                }
            },

            /**
             * Self referencing iterative function to loop through all child regions
             * adding the entire tree to the list used by the tool
             */
            addToRegionList: function(region){
                // Append the specified region to the list
                this.allRegions.push( { value: region.region_key, text: region.region_name } );

                // Now add all child regions
                if (region.child_region && region.child_region.length){
                    for (var index = 0; index < region.child_region.length; index++){
                        this.addToRegionList(region.child_region[index]);
                    }
                }
            },

            updateRegionSelected(regionKey){
                this.selectedRegionKey = regionKey;
            },

            /**
             * If the key provided is valid then trigger a page load for its dashboard overview
             *
             * @param int regionKey
             */
            gotoRegionDashboard(){
                if (this.selectedRegionKey){
                    window.location.href="/regions/" + this.selectedRegionKey;
                }
            }
        },

        computed: {
            /**
             * Expose the reactive list of regions for the vue template select box to be powered by
             */
            regionList: function(){
                return this.allRegions;
            }

        }
    }
</script>
