<template>
    <v-container fluid>
       
       <v-alert type="info"  dismissable value="true" v-if="!permission">You do not have permission to edit this page</v-alert>
       <v-alert type="info"  dismissable value="true" v-if="metadata.info">{{ metadata.info }}</v-alert>
       <v-alert type="warn"  dismissable value="true" v-if="metadata.warn">{{ metadata.warn }}</v-alert>
       <v-alert type="error" dismissable value="true" v-if="metadata.error">{{ metadata.error }}</v-alert>


       <v-alert color="warning" icon="priority_high" v-model="updatedUpstream">
           Some of this data has been updated by <strong>{{ upstreamChanges.userName }} ({{ upstreamChanges.userType }})</strong> since you opened this page

			<v-row>
			   <v-col sm="12" v-for="(field, key) in upstreamChanges.record_changes" :key='key' xs12>
				   <v-container fluid>
					   <v-row wrap>
						   <v-col sm="12">
							   <span>The field </span>
							   '<strong>{{ field.fieldName }}</strong>'
							   was changed from
							   '<strong>{{field.old_value }}</strong>'
							   to
							   '<strong>{{ field.new_value }}</strong>'
						   </v-col>
					   </v-row>
				   </v-container>
			   </v-col>
			</v-row>

			<v-row>
				<v-col sm="12">If you save without applying these first then some of their changes may be lost.  Click the button below to try to update the data on this page</v-col>
				<v-col sm="12">			
					<v-btn v-on:click="updatePage()">Update page</v-btn>
					<v-btn v-on:click="reloadPage()">Reload Page (Discard Changes)</v-btn>
					<v-btn v-on:click="openNew()">Open In New Tab</v-btn>
				</v-col>
			</v-row>
       </v-alert>

       <form id="editForm" v-on:submit.prevent>

           <v-card class="no-print d-flex d-md-none">
               <v-btn class="ies-green" v-on:click="saveRecord(metadata.primaryKey)" v-if="permission"><v-icon>save</v-icon></v-btn>
               <v-btn class="ml-1 ies-coral" v-on:click="deleteRecord(metadata.primaryKey)" v-if="permission"><v-icon>delete</v-icon></v-btn>
               <v-btn class="ml-1" v-on:click="emailLink()"><v-icon>email</v-icon></v-btn>
               <v-btn class="ml-1" v-on:click="returnToDashboard()" :disabled="!metadata.parentPage"><v-icon>dashboard</v-icon> Dash</v-btn>
               <v-btn class="ml-1" v-on:click="listView()"><v-icon>view_list</v-icon></v-btn>
               <v-btn class="ml-1" v-on:click="reportProblem()"><v-icon>report_problem</v-icon></v-btn>
           </v-card>
           <v-card class="no-print d-none d-md-flex">
               <v-btn class="ies-green" v-on:click="saveRecord(metadata.primaryKey)" v-if="permission"><v-icon>save</v-icon> Save</v-btn>
               <v-btn class="ml-1 ies-coral" v-on:click="deleteRecord(metadata.primaryKey)" v-if="permission"><v-icon>delete</v-icon> Delete</v-btn>
               <v-btn class="ml-1" onClick="window.print()"><v-icon>print</v-icon> Print</v-btn>
               <v-btn class="ml-1" v-on:click="emailLink()"><v-icon>email</v-icon> Email</v-btn>
               <v-btn class="ml-1" v-on:click="returnToDashboard()" :disabled="!metadata.parentPage"><v-icon>dashboard</v-icon> Dash</v-btn>
               <v-btn class="ml-1" v-on:click="listView()"><v-icon>view_list</v-icon> List</v-btn>
               <v-btn class="ml-1" v-on:click="reportProblem()"><v-icon>report_problem</v-icon> Problem</v-btn>
           </v-card>


           <v-card>
                <v-row class="ma-3" >
					<v-col v-for="(section,i) in formSections" :key="i" v-if="checkSection(section)" cols="12" sm="12" md="12" :lg="section.sectionWidth ? section.sectionWidth : 12">
                     	<v-card flat class="ma-3">
                        	<fieldset>
                            	<legend v-if="section.sectionName">{{ section.sectionName }}</legend>

                            	<v-alert type="info"  dismissable value="true" v-if="section.info">{{ section.info }}</v-alert>
                            	<v-alert type="warn"  dismissable value="true" v-if="section.warn">{{ section.warn }}</v-alert>
                            	<v-alert type="error" dismissable value="true" v-if="section.error">{{ section.error }}</v-alert>
								<div v-if="section.introduction">{{ section.introduction }}</div>

                            	<v-row>
                                	<v-col v-for="(field,k) in section.fields" :key="k" cols="12" sm="12" md="12" :lg="field.fieldWidth ? field.fieldWidth : 12">
                                    	<ies-input-wrapper :field="field" :recordData="resultsArray"></ies-input-wrapper>
                                	</v-col>
                            	</v-row>

                            	<div v-if="section.outroduction">{{ section.outroduction }}</div>
                        	</fieldset>
                     	</v-card>
					</v-col>
				</v-row>
			</v-card>

           <v-card class="no-print d-flex d-md-none">
               <v-btn class="ies-green" v-on:click="saveRecord(metadata.primaryKey)" v-if="permission"><v-icon>save</v-icon></v-btn>
               <v-btn class="ml-1 ies-coral" v-on:click="deleteRecord(metadata.primaryKey)" v-if="permission"><v-icon>delete</v-icon></v-btn>
               <v-btn class="ml-1" v-on:click="emailLink()"><v-icon>email</v-icon></v-btn>
               <v-btn class="ml-1" v-on:click="returnToDashboard()" :disabled="!metadata.parentPage"><v-icon>dashboard</v-icon> Dash</v-btn>
               <v-btn class="ml-1" v-on:click="listView()"><v-icon>view_list</v-icon></v-btn>
               <v-btn class="ml-1" v-on:click="reportProblem()"><v-icon>report_problem</v-icon></v-btn>
           </v-card>
           <v-card class="no-print d-none d-md-flex">
               <v-btn class="ies-green" v-on:click="saveRecord(metadata.primaryKey)" v-if="permission"><v-icon>save</v-icon> Save</v-btn>
               <v-btn class="ml-1 ies-coral" v-on:click="deleteRecord(metadata.primaryKey)" v-if="permission"><v-icon>delete</v-icon> Delete</v-btn>
               <v-btn class="ml-1" onClick="window.print()"><v-icon>print</v-icon> Print</v-btn>
               <v-btn class="ml-1" v-on:click="emailLink()"><v-icon>email</v-icon> Email</v-btn>
               <v-btn class="ml-1" v-on:click="returnToDashboard()" :disabled="!metadata.parentPage"><v-icon>dashboard</v-icon> Dash</v-btn>
               <v-btn class="ml-1" v-on:click="listView()"><v-icon>view_list</v-icon> List</v-btn>
               <v-btn class="ml-1" v-on:click="reportProblem()"><v-icon>report_problem</v-icon> Problem</v-btn>
           </v-card>

       </form>

        <v-snackbar
        :timeout="parseInt(6000)"
        v-model="successSnackbar"
        multi-line
        color="ies-green"
        > 
           <div class="full-height full-width v-align-container text-xs-center">
              <h4 class="ies-dark-gray--text v-align-div">Record Saved</h4>
           </div>
        </v-snackbar>
        <v-snackbar
        :timeout="parseInt(6000)"                          
        v-model="errorSnackbar"
        multi-line
        color="ies-coral"
        > 
           <div class="full-height full-width v-align-container text-xs-center">
              <h4 class="ies-dark-gray--text v-align-div">Save Failed</h4>
           </div>
        </v-snackbar>
    </v-container>
</template>


<script>
    import axios from 'axios';

export default {
    name: 'edit',
    props: ['sectionsData','resultsData', 'metadata', 'permission', 'breadcrumbsData'],
    data () {
        return {
            errorSnackbar:   false,
            successSnackbar: false,
            unsavedChanges:  false,
            updatedUpstream: false,
            pageOpened:      Date.now() / 1000,
            lastSaved:       Date.now() / 1000,
            lastUpdated:     0,
            upstreamChanges: [],
            redirectToUrl:   false,
            formSections: []
        }
    },

    methods: {
        /**
         * Co-ordinate the saving of the record to the database
         */
        saveRecord (ID){
            console.log("THIS", this);
            if (document.getElementById('editForm').checkValidity()) {
                var saveUrl = ID ? '/' + this.metadata.editUrl + '/' + ID : '/' + this.metadata.editUrl + '/create';

                // One last line of defence against people accidentally overwriting other people's changes - make them confirm they know what they're doing
                if (this.updatedUpstream && !confirm("Warning, some of the data on this page has been changed by another user\n\nClick Ok to overwrite their changes with the data on this page")){
                    return; 
                }

                // Create a copy of the data to be submitted to the server via AJAX
                var submissionData = this.formSections;
                var requestConfig = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };


console.log("here i am...", this);
console.log("my submission will be ", saveUrl, submissionData, requestConfig);



                axios.post(saveUrl, {params:submissionData}, requestConfig)
                    .then(function(response){
                        var self = this;

                        if(!response.data.success) {
                            this.errorSnackbar = true;

                            // The individual input components are not directly controlled by this parent component
                            // and so communicating with them requires event listeners to be used to announce the
                            // validation/save response errors
							console.log(response.data);
							console.log(response.data.errors);
                            response.data.errors.forEach(function(errorField){
                                console.log("my mistake: ", errorField);
                                self.$root.$emit('Field: set errors', errorField);
                            });
                        }
                        else if(response.data.success) {
                            this.successSnackbar = true;
                            this.lastUpdated     = Date.now() / 1000;
                            this.lastSaved       = this.lastUpdated;

                            // emit an event that SAVE button was clicked so we can catch it in other vue-components
                            var modelId = ID ? ID : response.data.fields.id;
                            this.$eventHub.$emit('save-button-clicked', {id: modelId, url: saveUrl});

                            // If new user was created, reload the page and redirect to new users edit page.
                            if (!this.metadata.primaryKey && response.data.fields.id){
                                this.redirectToUrl = '/' + this.metadata.editUrl + '/' + response.data.fields.id + '?new';
                                this.safePageRedirect();
                            }

                            console.log(response.data);
                            // For created records the page should redirect after a successful save
                            //if (response.data.task && response.data.task == 'insert' && response.data.id){
                                // Redirect to edit page to continue working on the record
                                //this.redirectToUrl = this.metadata.editUrl.includes('{ID}') ? '/' + this.metadata.editUrl.replace('{ID}', response.data.id) : '/' + this.metadata.editUrl + '/' + response.data.id;
                                // Redirect to the corresponding list page to continue working in the topic in general
                                // This may require additional work if the spec changes to allow created records to redirect to dashboards
                                // instead of lists for certain pages
					
								console.log('Breadcrumbs', this.breadcrumbsData);

                                //this.redirectToUrl = this.breadcrumbsData[this.breadcrumbsData.length - 3].url;
				
                                //this.redirectPage();
								var previousPageURL = document.referrer;
								
								console.log(previousPageURL);

								if (previousPageURL.includes('list')) {
									window.history.go(-4);
								} else {
									window.history.back();
								}
                            //}
                        }
                    }.bind(this));
            }
        },

        /**
         * Call the route which triggers the record to be deleted
         */
        deleteRecord (ID){
            // Check if the user really wishes to delete the record, then call the URL
            if (confirm("Are you sure you want to delete this record?")){
                var deleteUrl = '/' + this.metadata.deleteUrl + '/' + ID;
                axios.delete(deleteUrl)
                    .then(function(response){
                        window.location = this.metadata.listUrl;
                    }.bind(this));
            }
        },
        
        /**
         * Check to see if section should be displayed based on passed data
         */
        checkSection (section){
            console.log("section: ", section);
            var pass = true;
            // If section has rules
            if(section.rules.length > 0 && this.resultsArray){
                // Loop through the rules
				console.log(section.rules.length);
				console.log(section.rules);
                section.rules.forEach(function(value,key){
                    // Check data against rule operator
					console.log('Section rules...');
					console.log(value.operator);
					console.log(value.fielName);
					console.log(value.fieldName);
                    switch(value.operator) {
                        case '==' :
                            if(!(this.resultsArray[value.fieldName] == value.value)){
                                pass = false;                
                            }
                        break;
                        case '!=' :
                            if(!(this.resultsArray[value.fieldName] != value.value)){
                                pass = false;                
                            }
                        break;
                        case '<' :
                            if(!(this.resultsArray[value.fieldName] < value.value)){
                                pass = false;                
                            }
                        break;
                        
                        case '>' :
console.log("if > than:", this.resultsArray[value.fieldName],' > ',value.value);
console.log(this.resultsArray, value.fieldName);
                            if(!(this.resultsArray[value.fieldName] > value.value)){
console.log("should not show section");
                                pass = false;                
                            }
                        break;
                        
                        case '&' :
                            if(!(this.resultsArray[value.fieldName] & value.value)){
                                pass = false;                
                            }
                        break;
                    }
                }.bind(this));

            }
            // return result of check 
            return pass; 
        },

        /**
         * Checks if the database records featuring on this webpage have been saved since this page was opened
         */
        checkUpdatedUpstream(){
            axios.get('/ajax/' + this.metadata.editUrl + '/' + this.metadata.primaryKey + '/last-updated')
                .then(function(response){
                    this.lastUpdated = response.data.lastUpdated;

                    // If this page was last saved after this page was opened then set the flag to warn the user
                    this.updatedUpstream =  (this.lastUpdated > this.pageOpened) && (this.lastUpdated > this.lastSaved);

                    // And make a note of the fields that have changed so the user can be made aware they what data could be lost
                    this.upstreamChanges = response.data;
                }.bind(this));
        },

        /**
         * Formats an email to conveniently link the page to an email recipient in the users default email client
         */
        emailLink(){
            var emailAddress = '';
            var subject      = this.metadata.editUrl + ' ' + this.metadata.primaryKey;
            var body         = 'URL: ' + window.location.href;
            var url          = 'mailto:' + emailAddress + '?subject=' + subject + '&body=' + body;
            window.location.assign(url);
        },


        /**
         * Formats an email to conveniently link the page to an email recipient in the users default email client
         */
        reportProblem(){
            var emailAddress = 'web@i-escape.com';
            var subject      = 'Problem Report: ' + this.metadata.editUrl + ' ' + this.metadata.primaryKey;
            var body         = 'URL: ' + window.location.href;
            var url          = 'mailto:' + emailAddress + '?subject=' + subject + '&body=' + body;
            window.location.assign(url);
        },

        /**
         * Used when the page detects upstream changes and the user opts to discard their changes and load the page afresh
         * This could be done with Javascript and AJAX but it's far safer and easier to just reload
         */
         reloadPage(){
            location.reload();
         },

         /**
          * Used when the page detects upstream changes and the user asks to open the new page alongside the current one in a new tab so
          * they can manually see the differences and decide what to do for themselves
          */
         openNew(){
            window.open(window.location.href, '_blank');
         },

         /**
          * Finds instances of the specified model-field and updates their value accordingly
          */
         setFieldValue(modelName, fieldName, newValue){
             console.log("Before: ", this.formSections);

             this.formSections.forEach(function(section){
                 console.log(section);
                 section.fields.forEach(function(field){
                     console.log(field);
                     if (field.modelName == modelName && field.fieldName == fieldName){
                         console.log("Found it! is currently: ", field, " will set the value to be ", newValue);
                         field.value = newValue;
                     }
                 });
             });

             console.log("After: ", this.formSections);
             console.log("THIS", this);
         },

        /**
         * Automatically resolves any changes made upstream that are 
         */
        updatePage(){
            alert('Sorry, this feature is not yet available.  You must copy and paste the changes manually if you want to keep them');
        },


        /**
         *
         */
        returnToDashboard(){
            // Check if there are unsaved changes
            if (!this.unsavedChanges || confirm('If you leave this page before saving you will lose your changes')){
                //var url = '/' + this.metadata.parentPage;
                //window.location.assign(url);
                alert("This functionality is not yet available");
            }
        },

        listView(){
            // Check if there are unsaved changes
            if (!this.unsavedChanges || confirm('If you leave this page before saving you will lose your changes')){
                var url = '/' + this.metadata.listUrl;
                window.location.assign(url);
            }
        },

        /**
         * If new user was just created, it has '?new' in url and successSnackbar should display
         */
        checkForNewUser(){
            let url = window.location.href;
            if (url.includes('?new')) {
                this.successSnackbar = true;
            }
        },

        /**
         * Method will first check if we can redirect to new Url,
         * For some pages we want to wait until other AJAX calls will be done so we need to check if this is one of this page
         */
        safePageRedirect() {
            // here we store pages that we know that are using AJAX calls and we want to make sure that we won't reload page unitl all of them
            // will be done
            var delayPages = ['Admins', 'Hoteliers', 'Contractors'];

            if (delayPages.includes(this.metadata.editUrl)) {
                setTimeout(() => {
                    this.redirectPage();
                }, 2000)
            }
            else {
                this.redirectPage();
            }
        },

        /**
         * Method will instantly redirect to this.redirectToUrl, but only if this.redirectToUrl was set
         * this.redirectToUrl is being set when we're creating new user/model and we want to redirect for that user/model edit page
         */
        redirectPage() {
            if (this.redirectToUrl) {
                window.location.assign(this.redirectToUrl);
            }
        },
    },
    computed:{
        'resultsArray': {
            get:function(){
                if(this.resultsData['data'] && this.resultsData['data'][0]){
                    return this.resultsData['data'][0];
                }
                else{
                    return [];
                }
            },
            set:function(val){
            }
        }
    },
    // All initialisation actions that should be performed on page ready
    mounted: function () {
        // create copy of main app vue 'this' so we can call 'this' functions from eventHub scope
        var _this = this;

        this.formSections = this.sectionsData;

console.log(this.formSections);


        // Set up a interval timer to poll the server using AJAX for the last time the records behind this page's content were updated
        // This is used to reduce the likelihood of another user causing data conflicts with this one by both editing the same content at the same time
        if (this.metadata.primaryKey){
            setInterval(function () {
                this.checkUpdatedUpstream();
            }.bind(this), 10000);
        }

        this.checkForNewUser();

        // Register an event listener to respond to updates in child components data
        // or request that those values are changed to the given value
        this.$root.$on('Edit: Update Field', function(payload){
            console.log("edit component: update field value heard", payload);
            var modelName = payload.field.modelName;
            var fieldName = payload.field.fieldName;
            var newValue = payload.value;


            console.log("shoudl set: ", modelName, fieldName, newValue);
            _this.setFieldValue(modelName, fieldName, newValue);
        });


        // Register an event listener callback functions which can listen for other vue components
        this.$eventHub.$on('associations-saved', function(data) {
            _this.redirectPage();
        });
    }


}
</script>

<style>
    .ies-actions td:first-child{
        padding:0px;
    }

    input:disabled {
        background: #dddddd;
    }
</style>
