var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", sm: "6", md: "4", lg: "3", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("card_giftcard")]),
              _vm._v("Voucher Editor\n        "),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-text", { staticClass: "body-2" }, [
            _vm.systemError
              ? _c("div", [_vm._v("Sorry, this content is not working")])
              : _vm._e(),
            _vm._v(" "),
            _vm.foundVoucher === false
              ? _c("div", [
                  _vm._v("Sorry, no voucher could be found with this code"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.voucher
              ? _c("div", { staticClass: "m-3" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _vm._v("Code"),
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "8" } }, [
                                _vm._v(_vm._s(_vm.voucher.voucher_code)),
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _vm._v("Value (GBP)"),
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "8" } }, [
                                _vm._v(_vm._s(_vm.voucher.value)),
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _vm._v("Expiry:"),
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "8" } }, [
                                _vm._v(_vm._s(_vm.voucher.expires)),
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _vm._v("Notes:"),
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "8" } }, [
                                _vm._v(_vm._s(_vm.voucher.notes)),
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _vm._v("Status"),
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "8" } }, [
                                _vm._v(_vm._s(_vm.voucher.statusText)),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ies-green",
                          attrs: { href: _vm.editUrl },
                        },
                        [_vm._v("View")]
                      ),
                      _vm._v(" "),
                      _vm.voucher.is_active && !_vm.voucher.is_cancelled
                        ? _c(
                            "v-btn",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.cancelVoucher()
                                },
                              },
                            },
                            [
                              _c("v-icon", [_vm._v("cancel")]),
                              _vm._v(" Cancel"),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.voucher.is_active && !_vm.voucher.redeemed
                        ? _c(
                            "v-btn",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.reactivate()
                                },
                              },
                            },
                            [_vm._v("Reactivate")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.voucher.voucher_type == 1 &&
                      _vm.voucher.is_active &&
                      !_vm.voucher.redeemed
                        ? _c(
                            "v-btn",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.resendVoucher()
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("send")]), _vm._v(" Resend")],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.systemError && _vm.mode == "cancel"
              ? _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _c("div", [
                      _vm._v(
                        "Please enter a cancellation note explaining why this voucher is being cancelled"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reason,
                          expression: "reason",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.reason },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.reason = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ies-red",
                        on: {
                          click: function ($event) {
                            return _vm.submitCancellation()
                          },
                        },
                      },
                      [
                        _c("v-icon", [_vm._v("cancel")]),
                        _vm._v(" Cancel Voucher"),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.systemError && _vm.mode == "reactivate"
              ? _c(
                  "div",
                  [
                    _c("div", [_vm._v("Please choose a new expiry date:")]),
                    _vm._v(" "),
                    _c("v-date-picker", {
                      attrs: { color: "lime", type: "date" },
                      model: {
                        value: _vm.newExpiry,
                        callback: function ($$v) {
                          _vm.newExpiry = $$v
                        },
                        expression: "newExpiry",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "Please enter a note explaining why this voucher is being reactivated"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reason,
                          expression: "reason",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.reason },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.reason = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    !_vm.voucher.is_active && !_vm.voucher.redeemed
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "ies-green",
                            on: {
                              click: function ($event) {
                                return _vm.reactivateVoucher()
                              },
                            },
                          },
                          [_vm._v("Reactivate")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-text-field", {
                attrs: { placeholder: "Voucher code" },
                model: {
                  value: _vm.voucherCode,
                  callback: function ($$v) {
                    _vm.voucherCode = $$v
                  },
                  expression: "voucherCode",
                },
              }),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  on: {
                    click: function ($event) {
                      return _vm.performSearch()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("search")]), _vm._v(" Search")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }