
import Vue from 'vue';

Vue.component('ies-smart-search',           require('./components/common/SmartSearch.vue').default);
Vue.component('ies-smart-search-results',   require('./components/common/SmartSearchResults.vue').default);
Vue.component('ies-page-footer',            require('./components/common/PageFooter.vue').default);

/* Dynamic View Components */
Vue.component('ies-searchlist',             require('./components/dynamicViews/SearchList.vue').default);
Vue.component('ies-list',                   require('./components/dynamicViews/List.vue').default);
Vue.component('ies-list-table',             require('./components/dynamicViews/ListTable.vue').default);
Vue.component('ies-list-stack',             require('./components/dynamicViews/ListStack.vue').default);
Vue.component('ies-list-field',             require('./components/dynamicViews/ListFields.vue').default);
Vue.component('ies-list-options',           require('./components/dynamicViews/ListOptions.vue').default);
Vue.component('ies-list-section-wrapper',   require('./components/dynamicViews/ListSectionWrapper.vue').default);
Vue.component('ies-import',                 require('./components/dynamicViews/Import.vue').default);
Vue.component('ies-edit',                   require('./components/dynamicViews/Edit.vue').default);
Vue.component('ies-input-wrapper',          require('./components/inputs/Wrapper.vue').default);
Vue.component('ies-region-hotel-conversions',require('./components/dynamicViews/RegionHotelConversions.vue').default);
Vue.component('ies-image-browser',          require('./components/dynamicViews/ImageBrowser.vue').default);
Vue.component('ies-image-uploader',         require('./components/dynamicViews/ImageUploader.vue').default);

/* Custom List components */
Vue.component('ies-communications-service-provider-name',  require('./components/outputs/CommunicationsServiceProviderName.vue').default);
Vue.component('ies-newsletter-subscriptions',  require('./components/outputs/NewsletterSubscriptions.vue').default);
Vue.component('ies-facility-categories',    require('./components/outputs/FacilityCategories.vue').default);
Vue.component('ies-status',   		 		require('./components/outputs/Status.vue').default);
Vue.component('ies-display-status',			require('./components/outputs/DisplayStatus.vue').default);
Vue.component('ies-visit-type',				require('./components/outputs/VisitType.vue').default);
Vue.component('ies-yes-no',					require('./components/outputs/YesNo.vue').default);
Vue.component('ies-reviewer-name',			require('./components/outputs/ReviewerName.vue').default);
Vue.component('ies-display-groups',         require('./components/outputs/DisplayGroups.vue').default);
Vue.component('ies-payment-request-link',   require('./components/outputs/PaymentRequestLink.vue').default);
Vue.component('ies-voucher-address-link',   require('./components/outputs/VoucherAddressLink.vue').default);
Vue.component('ies-ical-feed',              require('./components/outputs/ICalFeed.vue').default);
Vue.component('ies-ical-feed-refresh',      require('./components/outputs/ICalFeedRefresh.vue').default);
Vue.component('ies-list-edit-button',       require('./components/outputs/ListEditButton.vue').default);
Vue.component('ies-resend-sent-sms',       require('./components/outputs/ResendSentSMS.vue').default);
Vue.component('ies-resend-failed-sms',       require('./components/outputs/ResendFailedSMS.vue').default);
Vue.component('ies-resend-sent-email',      require('./components/outputs/ResendSentEmail.vue').default);
Vue.component('ies-resend-failed-email',    require('./components/outputs/ResendFailedEmail.vue').default);

/* Bespoke Components */
Vue.component('ies-portal-designer',        require('./components/bespoke/pageDesigner/PortalDesigner.vue').default);
//Vue.component('ies-portal-designer-fields', require('./components/bespoke/pageDesigner/PortalDesignerFields.vue').default);
Vue.component('ies-permission-list',        require('./components/bespoke/PermissionList.vue').default);
Vue.component('ies-page-content',           require('./components/bespoke/pages/page-content/PageContentImportExport.vue').default);
Vue.component('ies-voucher-dashboard',      require('./components/bespoke/pages/Vouchers/VouchersDashboard.vue').default);
Vue.component('ies-voucher-filter-result',  require('./components/bespoke/pages/Vouchers/FilterResults.vue').default);
Vue.component('ies-voucher-cancel',         require('./components/bespoke/pages/Vouchers/VoucherCancel.vue').default);
Vue.component('ies-vouchers-summary-page',  require('./components/bespoke/pages/Vouchers/Summary.vue').default);
Vue.component('ies-voucher-edit-page',      require('./components/bespoke/pages/Vouchers/EditPage.vue').default);
Vue.component('ies-rate-summary-page',      require('./components/bespoke/pages/RateSummary/Page.vue').default);
Vue.component('ies-reservations-history',   require('./components/bespoke/pages/Reservations/ReservationsHistory.vue').default);
Vue.component('ies-ical-connections',       require('./components/bespoke/Channels/ICalConnectionsPage.vue').default);
Vue.component('ies-hotel-facility-manager', require('./components/bespoke/HotelFacilityManager.vue').default);
Vue.component('ies-clone-rate-dates',       require('./components/bespoke/CloneRateDates.vue').default);
Vue.component('ies-menu-builder',           require('./components/bespoke/pages/MenuBuilderPage.vue').default);
Vue.component('ies-collection-page',        require('./components/bespoke/pages/Collections/Page.vue').default);
Vue.component('ies-channel-managed-hotels-with-no-recent-updates', require('./components/bespoke/pages/ChannelManagers/ChannelManagedHotelsWithNoRecentUpdates.vue').default);
Vue.component('ies-hotel-reservation-administration', require('./components/bespoke/reservationAdministration/HotelReservationAdministration.vue').default);
Vue.component('ies-reservation-administration-overview', require('./components/bespoke/reservationAdministration/HotelReservationOverview.vue').default);
Vue.component('ies-reservation-administration-messages', require('./components/bespoke/reservationAdministration/HotelReservationMessages.vue').default);
Vue.component('ies-reservation-administration-enquiry', require('./components/bespoke/reservationAdministration/HotelReservationEnquiry.vue').default);
Vue.component('ies-reservation-administration-cancellation', require('./components/bespoke/reservationAdministration/HotelReservationCancellation.vue').default);
Vue.component('ies-reservation-administration-cancelled', require('./components/bespoke/reservationAdministration/HotelReservationCancelled.vue').default);
Vue.component('ies-reservation-administration-declined', require('./components/bespoke/reservationAdministration/HotelReservationDeclined.vue').default);
Vue.component('ies-reservation-administration-awaiting', require('./components/bespoke/reservationAdministration/HotelReservationAwaiting.vue').default);
Vue.component('ies-reservation-administration-accepted', require('./components/bespoke/reservationAdministration/HotelReservationAccepted.vue').default);
Vue.component('ies-reservation-administration-confirmed', require('./components/bespoke/reservationAdministration/HotelReservationConfirmed.vue').default);
Vue.component('ies-reservation-administration-completed', require('./components/bespoke/reservationAdministration/HotelReservationCompleted.vue').default);
Vue.component('ies-reservation-administration-offer-quote', require('./components/bespoke/reservationAdministration/HotelReservationOfferQuote.vue').default);
Vue.component('ies-reservation-administration-alternative-dates', require('./components/bespoke/reservationAdministration/HotelReservationAlternativeDates.vue').default);
Vue.component('ies-reservation-administration-alternative-rooms', require('./components/bespoke/reservationAdministration/HotelReservationAlternativeRooms.vue').default);
Vue.component('ies-reservation-administration-no-availability', require('./components/bespoke/reservationAdministration/HotelReservationNoAvailability.vue').default);
Vue.component('ies-new-hotel',              require('./components/bespoke/NewHotel.vue').default);

/* Common Components */
Vue.component('ies-navbar',                 require('./components/common/Navbar.vue').default);
Vue.component('ies-navdrawer',              require('./components/common/NavDrawer.vue').default);
Vue.component('ies-quick-access-buttons',   require('./components/common/QuickAccessButtons.vue').default);
Vue.component('ies-hotel-picker',           require('./components/common/HotelPicker.vue').default);
Vue.component('ies-region-picker',          require('./components/common/RegionPicker.vue').default);
Vue.component('ies-dynamic-record-history', require('./components/common/RecordHistory/DynamicRecordHistory.vue').default);
Vue.component('ies-date-range',             require('./components/common/IesDateRange.vue').default);
Vue.component('ies-csv-export',             require('./components/common/ExportCSV.vue').default);
Vue.component('ies-dashboard',              require('./components/common/dashboard/Dashboard.vue').default);

// Dashboard Panels

// Common
Vue.component('ies-salutation',                 require('./components/common/dashboard/panels/Salutation.vue').default);
Vue.component('ies-staff-calendar',             require('./components/common/dashboard/panels/StaffCalendar.vue').default);
Vue.component('ies-news',                       require('./components/common/dashboard/panels/News.vue').default);
Vue.component('ies-notifications',              require('./components/common/dashboard/panels/Notifications.vue').default);
Vue.component('ies-world-clock',                require('./components/common/dashboard/panels/WorldClock.vue').default);
Vue.component('ies-new-members',                require('./components/common/dashboard/panels/NewMembers.vue').default);
Vue.component('ies-email-stats',                     require('./components/common/dashboard/panels/EmailStats.vue').default);
Vue.component('ies-issues',                     require('./components/common/dashboard/panels/Issues.vue').default);
Vue.component('ies-new-subscribers',            require('./components/common/dashboard/panels/NewSubscribers.vue').default);
Vue.component('ies-new-instant-bookings',       require('./components/common/dashboard/panels/NewInstantBookings.vue').default);
Vue.component('ies-new-request-bookings',       require('./components/common/dashboard/panels/NewRequestBookings.vue').default);
Vue.component('ies-newsletter-signups-stats',        require('./components/common/dashboard/panels/NewsletterSignupsStats.vue').default);
Vue.component('ies-server-status',              require('./components/common/dashboard/panels/ServerStatus.vue').default);


// Gift Vouchers
Vue.component('ies-voucher-stats',              require('./components/common/dashboard/panels/VoucherStats.vue').default);
Vue.component('ies-voucher-creator',            require('./components/common/dashboard/panels/VoucherCreator.vue').default);
Vue.component('ies-voucher-quickupdate',        require('./components/common/dashboard/panels/VoucherQuickUpdate.vue').default);
Vue.component('ies-voucher-reportbuttons',        require('./components/common/dashboard/panels/VouchersReportButtons.vue').default);
Vue.component('ies-voucher-typebuttons',        require('./components/common/dashboard/panels/VoucherTypeButtons.vue').default);
Vue.component('ies-voucher-todobuttons',        require('./components/common/dashboard/panels/VoucherWorkToDo.vue').default);


// Reservations (overall)
Vue.component('ies-reservation-stats',          require('./components/common/dashboard/panels/ReservationStats.vue').default);
Vue.component('ies-daily-van-count-stats',      require('./components/common/dashboard/panels/VANStats.vue').default);
Vue.component('ies-summary-card-token-count-stats',require('./components/common/dashboard/panels/CardTokensStats.vue').default);
Vue.component('ies-reservations-quick-buttons', require('./components/common/dashboard/panels/reservations/QuickButtons.vue').default);

// Accounts
Vue.component('ies-billing-report-buttons',     require('./components/common/dashboard/panels/BillingReportButtons.vue').default);
Vue.component('ies-receipt-refunds-report-buttons', require('./components/common/dashboard/panels/ReceiptRefundsReportButtons').default);
Vue.component('ies-statistics-report-buttons',  require('./components/common/dashboard/panels/StatisticsReportButtons.vue').default);
Vue.component('ies-hotel-transaction-picker',   require('./components/common/dashboard/panels/HotelTransactionPicker.vue').default);
Vue.component('ies-accounts-quick-buttons',     require('./components/common/dashboard/panels/accounts/QuickButtons.vue').default);
Vue.component('ies-accounts-stats-week',        require('./components/common/dashboard/panels/accounts/ReservationsStatsWeek.vue').default);
Vue.component('ies-accounts-stats-todate',      require('./components/common/dashboard/panels/accounts/ReservationsStatsToDate.vue').default);
Vue.component('ies-invoices-stats-week',        require('./components/common/dashboard/panels/accounts/InvoicesStatsWeek.vue').default);
Vue.component('ies-invoices-stats-todate',      require('./components/common/dashboard/panels/accounts/InvoicesStatsToDate.vue').default);
Vue.component('ies-invoice-create-buttons',    require('./components/common/dashboard/panels/accounts/InvoiceCreateButtons.vue').default);
Vue.component('ies-invoice-view-buttons',       require('./components/common/dashboard/panels/accounts/InvoiceViewButtons.vue').default);
Vue.component('ies-credit-create-buttons',      require('./components/common/dashboard/panels/accounts/CreditNoteCreateButtons.vue').default);
Vue.component('ies-successful-credit-card-transactions', require('./components/common/dashboard/panels/SuccessfulCreditCardTransactions.vue').default);
Vue.component('ies-accounts-filter',        require('./components/bespoke/pages/Accounts/AccountsFilter.vue').default);
Vue.component('ies-accounts-overview',      require('./components/bespoke/pages/Accounts/Overview.vue').default);
Vue.component('ies-accounts-batch-report',  require('./components/bespoke/pages/Accounts/BatchReport/BatchReport.vue').default);
Vue.component('ies-receipts-report',        require('./components/bespoke/pages/Accounts/ReceiptsReport/Page.vue').default);
Vue.component('ies-voucher-summary',        require('./components/bespoke/pages/Accounts/VoucherSummary.vue').default);
Vue.component('ies-secure-hosting',         require('./components/bespoke/pages/Accounts/SecureHosting.vue').default);
Vue.component('ies-upf-invoicing',          require('./components/bespoke/pages/Accounts/UpfInvoicing.vue').default);

// Channel Managers
Vue.component('ies-channel-manager-requests-chart', require('./components/common/dashboard/panels/ChannelManagerRequestsChart.vue').default);
Vue.component('ies-channels-quick-buttons',       require('./components/common/dashboard/panels/channels/QuickButtons.vue').default);
Vue.component('ies-channels-quick-buttons-audit', require('./components/common/dashboard/panels/channels/QuickButtonsAudit.vue').default);
Vue.component('ies-channels-button-bar',       require('./components/common/dashboard/panels/channels/ButtonBar.vue').default);

// Site Content
Vue.component('ies-sitecontent-homepages-buttons',        require('./components/common/dashboard/panels/sitecontent/HomepagesQuickButtons.vue').default);
Vue.component('ies-sitecontent-newsoffers-buttons',       require('./components/common/dashboard/panels/sitecontent/NewsOffersQuickButtons.vue').default);
Vue.component('ies-sitecontent-aboutus-buttons',          require('./components/common/dashboard/panels/sitecontent/AboutUsQuickButtons.vue').default);
Vue.component('ies-sitecontent-sitetexts-buttons',        require('./components/common/dashboard/panels/sitecontent/SiteTextsQuickButtons.vue').default);
Vue.component('ies-sitecontent-lists-buttons',            require('./components/common/dashboard/panels/sitecontent/ListsQuickButtons.vue').default);
Vue.component('ies-sitecontent-plus-buttons',             require('./components/common/dashboard/panels/sitecontent/PlusQuickButtons.vue').default);

// Hotels (overall)
Vue.component('ies-hotels-quick-buttons',       require('./components/common/dashboard/panels/hotels/QuickButtons.vue').default);

// Hotel Dashboard Panels
Vue.component('ies-hotel-relationship-buttons', require('./components/common/dashboard/panels/hotels/hotel/RelationshipButtons.vue').default);
Vue.component('ies-hotel-overview-buttons', require('./components/common/dashboard/panels/hotels/hotel/OverviewButtons.vue').default);
Vue.component('ies-hotel-policies-buttons', require('./components/common/dashboard/panels/hotels/hotel/PolicyButtons.vue').default);
Vue.component('ies-hotel-rooms-rates-buttons', require('./components/common/dashboard/panels/hotels/hotel/RoomsRatesButtons.vue').default);
Vue.component('ies-hotel-reservations-buttons', require('./components/common/dashboard/panels/hotels/hotel/ReservationsButtons.vue').default);
Vue.component('ies-hotel-reviews-buttons', require('./components/common/dashboard/panels/hotels/hotel/ReviewsButtons.vue').default);
Vue.component('ies-hotel-facilities-buttons', require('./components/common/dashboard/panels/hotels/hotel/FacilitiesButtons.vue').default);
Vue.component('ies-hotel-button-bar', require('./components/common/dashboard/panels/hotels/hotel/ButtonBar.vue').default);
Vue.component('ies-hotel-issues',               require('./components/common/dashboard/panels/hotels/hotel/Issues.vue').default);
Vue.component('ies-hotel-key-info',             require('./components/common/dashboard/panels/hotels/hotel/KeyInfo.vue').default);
Vue.component('ies-hotel-extra-notes',          require('./components/common/dashboard/panels/hotels/hotel/ExtraNotes.vue').default);



/* Forms */
Vue.component('ies-voucher-form',           require('./components/forms/VoucherForm.vue').default);

/* Communications */
Vue.component('ies-communications-quick-buttons', require('./components/common/dashboard/panels/communications/QuickButtons.vue').default);

/* Reports  */
Vue.component('ies-report-options',  require('./components/dynamicViews/ReportOptions.vue').default);
Vue.component('ies-report-visualisation',require('./components/dynamicViews/ReportVisualisation.vue').default);

/* Dynamic Charts (Used for reports) */
Vue.component('ies-chart-bar',       require('./components/dynamicViews/Charts/Bar.vue').default);
Vue.component('ies-chart-line',      require('./components/dynamicViews/Charts/Line.vue').default);
Vue.component('ies-chart-pie',       require('./components/dynamicViews/Charts/Pie.vue').default);
Vue.component('ies-chart-doughnut',  require('./components/dynamicViews/Charts/Doughnut.vue').default);
Vue.component('ies-chart-radar',     require('./components/dynamicViews/Charts/Radar.vue').default);
Vue.component('ies-chart-polar',     require('./components/dynamicViews/Charts/Polar.vue').default);
Vue.component('ies-chart-bubble',    require('./components/dynamicViews/Charts/Bubble.vue').default);
Vue.component('ies-chart-scatter',   require('./components/dynamicViews/Charts/Scatter.vue').default);

// Hotel Planner
Vue.component('ies-hotel-planner',    require('./components/bespoke/pages/HotelPlanner/HotelPlanner.vue').default);
Vue.component('ies-hotel-planner-month-controls', require('./components/bespoke/pages/HotelPlanner/Components/MonthControls.vue').default);
Vue.component('ies-hotel-planner-table-controls', require('./components/bespoke/pages/HotelPlanner/Components/TableControls.vue').default);
Vue.component('ies-hotel-planner-rate-row', require('./components/bespoke/pages/HotelPlanner/Components/RateRow.vue').default);
Vue.component('ies-hotel-planner-availability-row', require('./components/bespoke/pages/HotelPlanner/Components/AvailabilityRow.vue').default);
Vue.component('ies-hotel-planner-universal-rate-row', require('./components/bespoke/pages/HotelPlanner/Components/UniversalRateRow.vue').default);
Vue.component('ies-hotel-planner-edit-form', require('./components/bespoke/pages/HotelPlanner/Components/EditForm.vue').default);

