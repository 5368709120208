<template>
    <v-container fluid>
       <v-layout row-sm column>
            <v-col v-if="this.field.schema.displayName != 'none'" cols="12">
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-col>

            <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            <v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            <v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>

            <v-row>
				<v-col sm="12" class="flex-row">
					<input type="hidden" :name="field.fieldName" :value="field.value">

					<v-col lg="4">
						<label for="latitude">Latitude</label>
						<v-text-field
							name="latitude" 
							v-model="map.latitude" v-on:keyup="updateField()"
							:required="this.field.required" 
							:disabled="this.field.disabled"
						></v-text-field>
					</v-col>
					<v-col lg="4">
						<label for="longitude">Longitude</label>
						<v-text-field
							name="longitude"
							v-model="map.longitude" v-on:keyup="updateField()"
							:required="this.field.required"
							:disabled="this.field.disabled"
						></v-text-field>
					</v-col>
					<v-col lg="4">
						<label for="zoom">Zoom</label>
						<v-text-field
							name="zoom"
							v-model="map.zoom" v-on:keyup="updateField()"
							:required="this.field.required"
							:disabled="this.field.disabled"
						></v-text-field>
					</v-col>
                </v-col>
            </v-row>
           <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
               <li v-for="error in field.errors">
                   {{ error }}
               </li>
           </v-alert>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'ies-google-map',
    props: ['field'],
    data () {
        return {
            map: { 'latitude': 0.0, 'longitude': 0.0, 'zoom': 1 },
        }
    },
    methods: {
		updateViewFromModel: function(){
			var mapSettings = this.field.value.split(",");
			this.map.latitude = mapSettings[0];
			this.map.longitude = mapSettings[1];
			this.map.zoom = mapSettings[2];
		},

		updateModelFromView: function(newValue) {
			this.field.value = this.map.latitude + "," + this.map.longitude + "," + this.map.zoom + ",m";
		}
	},
	
	created:function() {
		this.updateViewFromModel();
	},

	watch: {
		'map.latitude': 	function(){ this.updateModelFromView(); },
		'map.longitude': 	function(){ this.updateModelFromView(); },
		'map.zoom': 		function(){ this.updateModelFromView(); },
	}	
}
</script>
