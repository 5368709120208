<template>
    <div>
        <!-- list must be list of ['text' => x, 'value' => y] -->
<!--
        <v-select
            v-model="value"
            :label="field.schema.displayName"
            :items="field.list"
            autocomplete
        ></v-select>
-->

        <select style="background-color: white; -moz-appearance: auto; -webkit-appearance: auto;" v-model="value">
            <option v-for="item in field.list" 
                    :value="item.value">{{ item.text }}</option>
        </select>


        <input type="hidden" :name="field.fieldName" :value="value">
        <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
            <li v-for="error in field.errors">
                {{ error }}
            </li>
        </v-alert>
    </div>

</template>

<script>
    export default {
        props: ['field'],
        data() {
            return {
                value: parseInt(this.field.oldValue),
            }
        }
    }
</script>
