var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("h1", [_vm._v("UPF Invoicing")]),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "justify-center": "", "pa-4": "" } },
        [
          _c(
            "v-flex",
            { attrs: { shrink: "", "text-xs-right": "", "mx-2": "" } },
            [
              _c("v-date-picker", {
                attrs: {
                  type: "month",
                  landscape: "",
                  color: "lime lighten-1",
                },
                on: {
                  change: function ($event) {
                    _vm.isSubmitted = false
                  },
                },
                model: {
                  value: _vm.queryParams.date,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "date", $$v)
                  },
                  expression: "queryParams.date",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { staticStyle: { "min-width": "180px" }, attrs: { shrink: "" } },
            [
              _c("span", { staticClass: "subheader" }, [_vm._v("Regions")]),
              _vm._v(" "),
              _c("v-checkbox", {
                attrs: { label: "United Kingdom" },
                on: {
                  change: function ($event) {
                    _vm.isSubmitted = false
                  },
                },
                model: {
                  value: _vm.queryParams.uk,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "uk", $$v)
                  },
                  expression: "queryParams.uk",
                },
              }),
              _vm._v(" "),
              _c("v-checkbox", {
                attrs: { label: "EU Vatable" },
                on: {
                  change: function ($event) {
                    _vm.isSubmitted = false
                  },
                },
                model: {
                  value: _vm.queryParams.eu_vat,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "eu_vat", $$v)
                  },
                  expression: "queryParams.eu_vat",
                },
              }),
              _vm._v(" "),
              _c("v-checkbox", {
                attrs: { label: "EU Non-Vatable" },
                on: {
                  change: function ($event) {
                    _vm.isSubmitted = false
                  },
                },
                model: {
                  value: _vm.queryParams.eu_novat,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "eu_novat", $$v)
                  },
                  expression: "queryParams.eu_novat",
                },
              }),
              _vm._v(" "),
              _c("v-checkbox", {
                attrs: { label: "Rest of World" },
                on: {
                  change: function ($event) {
                    _vm.isSubmitted = false
                  },
                },
                model: {
                  value: _vm.queryParams.rest_world,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "rest_world", $$v)
                  },
                  expression: "queryParams.rest_world",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { staticStyle: { "min-width": "180px" }, attrs: { shrink: "" } },
            [
              _c("span", { staticClass: "subheader" }, [
                _vm._v("Payment Method"),
              ]),
              _vm._v(" "),
              _c("v-checkbox", {
                attrs: { label: "Visa" },
                on: {
                  change: function ($event) {
                    _vm.isSubmitted = false
                  },
                },
                model: {
                  value: _vm.queryParams.visa,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "visa", $$v)
                  },
                  expression: "queryParams.visa",
                },
              }),
              _vm._v(" "),
              _c("v-checkbox", {
                attrs: { label: "Mastercard" },
                on: {
                  change: function ($event) {
                    _vm.isSubmitted = false
                  },
                },
                model: {
                  value: _vm.queryParams.mastercard,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "mastercard", $$v)
                  },
                  expression: "queryParams.mastercard",
                },
              }),
              _vm._v(" "),
              _c("v-checkbox", {
                attrs: { label: "American Express" },
                on: {
                  change: function ($event) {
                    _vm.isSubmitted = false
                  },
                },
                model: {
                  value: _vm.queryParams.amex,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "amex", $$v)
                  },
                  expression: "queryParams.amex",
                },
              }),
              _vm._v(" "),
              _c("hr"),
              _c("br"),
              _vm._v(" "),
              _c("v-switch", {
                attrs: {
                  label: `Group By Day: ${
                    _vm.queryParams.groupByDay ? "✔" : "✘"
                  }`,
                },
                on: {
                  change: function ($event) {
                    _vm.isSubmitted = false
                  },
                },
                model: {
                  value: _vm.queryParams.groupByDay,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "groupByDay", $$v)
                  },
                  expression: "queryParams.groupByDay",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "justify-center": "", "pa-2": "" } },
        [
          _c(
            "v-btn",
            { class: { lime: !_vm.isSubmitted }, on: { click: _vm.getReport } },
            [_vm._v("Submit")]
          ),
          _vm._v(" "),
          _c("export-csv", {
            attrs: {
              filename: `${_vm.queryParams.date}-UPF-invoicing`,
              data: _vm.report,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("v-card-title", { staticStyle: { background: "gainsboro" } }, [
            _vm._v("\n            Summary\n        "),
          ]),
          _vm._v(" "),
          _c(
            "v-data-table",
            {
              staticClass: "elevation-1",
              attrs: {
                headers: _vm.summaryHeaders,
                items: [this.report],
                loading: _vm.isLoading,
                "hide-default-footer": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function (props) {
                    return [
                      _c("tr", [
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.count)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            "£" + _vm._s(props.item.sum_quotes.toFixed(2))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            "£" + _vm._s(props.item.sum_deposits.toFixed(2))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            "£" + _vm._s(props.item.sum_commissions.toFixed(2))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            "£" + _vm._s(props.item.sum_vouchers.toFixed(2))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v("£" + _vm._s(props.item.sum_vans.toFixed(2))),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            "£" +
                              _vm._s(props.item.sum_cc_transactions.toFixed(2))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            "£" +
                              _vm._s(props.item.sum_sh_transactions.toFixed(2))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            "£" + _vm._s(props.item.sum_invoiced.toFixed(2))
                          ),
                        ]),
                      ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c("v-progress-linear", {
                attrs: {
                  slot: "progress",
                  color: "lime lighten-1",
                  indeterminate: "",
                },
                slot: "progress",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("v-card-title", { staticStyle: { background: "gainsboro" } }, [
            _vm._v("\n            Detail\n        "),
          ]),
          _vm._v(" "),
          _c(
            "v-data-table",
            {
              staticClass: "elevation-1",
              attrs: {
                headers: _vm.tableHeaders,
                items: this.report.data,
                loading: _vm.isLoading,
                "hide-default-footer": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function (props) {
                    return [
                      _c("td", { staticClass: "text-xs-center" }, [
                        _vm._v(
                          _vm._s(_vm.formattedDateDDMMYYYY(props.item.date))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-center" }, [
                        _vm._v(_vm._s(props.item.reference)),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-center" }, [
                        _vm._v("£{ { props.item.quotes.toFixed(2) } }"),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-center" }, [
                        _vm._v("£" + _vm._s(props.item.deposit.toFixed(2))),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-center" }, [
                        _vm._v("£" + _vm._s(props.item.commission.toFixed(2))),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-center" }, [
                        _vm._v(_vm._s(props.item.is_prepaid)),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-center" }, [
                        _vm._v("£" + _vm._s(props.item.voucher.toFixed(2))),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-center" }, [
                        _vm._v(_vm._s(props.item.payment_method)),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-center" }, [
                        _vm._v(
                          "£" + _vm._s(props.item.cc_transaction.toFixed(2))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-center" }, [
                        _vm._v(
                          "£" + _vm._s(props.item.sh_transaction.toFixed(2))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-center" }, [
                        _vm._v("£" + _vm._s(props.item.van.toFixed(2))),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-center" }, [
                        _vm._v("£" + _vm._s(props.item.invoiced.toFixed(2))),
                      ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c("v-progress-linear", {
                attrs: {
                  slot: "progress",
                  color: "lime lighten-1",
                  indeterminate: "",
                },
                slot: "progress",
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }