<template>
    <v-container fluid class="connectionWrapper">
        <v-layout row justify-space-between>
            <v-flex xs12 md1>
                {{ sort }}.
            </v-flex>
            <v-flex xs12 md3>
                Room: {{ connection.hotelRoomName}}
            </v-flex>
            <v-flex xs12 md3>
                Last updated at: {{ lastUpdated }}
            </v-flex>
            <v-flex xs12 md2>
                <v-switch
                        v-model="active"
                        :label="`Active: ${activeString}`"
                        @change="activeChanged"
                ></v-switch>
            </v-flex>
            <v-flex class="actionIcons" xs1>
                <v-icon
                        color="green"
                        @click="showDetails()"
                >
                    description
                </v-icon>
                <v-icon
                        color="red"
                        @click="deleteConnection()"
                >
                    delete
                </v-icon>
            </v-flex>
        </v-layout>

        <!-- Dialog -->
        <v-dialog v-model="dialog" max-width="700px">
            <v-card>
                <v-card-title>
                    <span class="headline">iCal Connection details</span>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-flex xs12>
                            ical_connection_key: {{ connection.ical_connection_key}}<br>
                            Url: {{ connection.url }}
                        </v-flex>
                        <v-flex xs12 v-if="connection.notes">
                            Notes: {{ connection.notes }}
                        </v-flex>

                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                            @click="updateSingleConnection"
                            outline
                            color="green"
                            :disabled="connectionBusy"
                            :loading="connectionBusy"
                    >Update this connection</v-btn>
                    <v-spacer></v-spacer>

                    <v-btn color="blue darken-1" flat @click="closeDialog()">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import EventBus from '@/eventBus';

    export default {
        props:['connection', 'hotelcode','sort'],
        data() {
            return {
                active: this.connection.is_active,
                dialog: false,
                connectionBusy: false,
            }
        },
        methods: {
            /**
             * Delete this connection and let parent component know that this connection should be removed.
             */
            deleteConnection() {
                let result = confirm("Do you really want to remove this connection? " +
                    "After accepting this message you will still need to save the changes in order to confirm this deletion");

                if (result) {
                    EventBus.$emit('delete-connection', {ical_connection_key : this.connection.ical_connection_key, hotel_code: this.hotelcode});
                }
            },

            /**
             * Trigger update of single connection, because parent component is responsible for communicating with API
             * emit this event to parent and let take care of update logic
             */
            updateSingleConnection() {
                this.connectionBusy = true;

                EventBus.$emit('update-single', {ical_connection_key : this.connection.ical_connection_key});
            },

            connectionUpdated() {
                this.connectionBusy = false;
            },

            activeChanged() {
                this.connection.is_active = this.active;
            },

            /**
             * Show details dialog
             */
            showDetails() {
                this.dialog = true;
            },

            /**
             * Close dialog
             */
            closeDialog() {
                this.dialog = false;
            },

        },
        computed: {
            lastUpdated() {
                if (this.connection.last_update) {
                    return this.connection.last_update;
                }
                else {
                    return 'never';
                }
            },
            activeString() {
                return this.active ? 'true' : 'false';
            }
        },
        mounted() {
            EventBus.$on('update-single-done', () => {
                this.connectionUpdated();
            });
        }

    };
</script>

<style scoped>

    .actionIcons .icon {
        color: red;
        cursor: pointer;
    }

</style>
