<template>
    <v-container fluid>
        <v-flex xs12 v-for="(dashboard, dashboardKey) in section.dashboards" :key="dashboardKey" class="ma-3">
            <v-layout column>
                <v-flex xs12 class="mb-2">
                        <v-layout>
                            <v-flex sm1>
                                <label>Dashboard System Name</label>
                            </v-flex>
                            <v-flex sm4>
                                <!-- @todo: replace with select box/combo box -->
                                <v-text-field outlined v-model="dashboard.dashboardName"></v-text-field>
                            </v-flex>
                        </v-layout>
                </v-flex>
            </v-layout>
            <v-divider class="mt-3 mb-3" ></v-divider>
        </v-flex>
    </v-container>
</template>

<script>
    export default {
        name: 'PortalDesignerDashboard',
        props: ['section', 'sectionkey', 'used_models', 'models', 'field_display_types', 'modelFieldNames', 'joinTypes', 'primaryModel'],
        data () {
            return {
                'currentSection' : this.section,
                'sectionKey' : this.sectionkey,
                'fieldDisplayTypes' : this.field_display_types,
                'fieldCount': this.section.fields.length,
                'runCount': 0,
            }
        },
        methods: {
            /**
             * Try to load this page data from the data service, we have to put it in setTimeout in order to allow JavaScipt to create
             * this object in parent component
             */
            loadData(){
                var _this = this;
                setTimeout(() => {
                    var pageService = window.app.pageService;

                    do {
                        if (pageService) {
                            _this.pageData = window.app.pageService.getPageData();
                            _this.modelFieldNames = _this.pageData['modelFieldNames'];
                            _this.joinTypes = _this.pageData['joinTypes'];
                        }
                    }
                    while(!pageService);

                }, 20);
            },

            updateObject: function(){
                this.section = this.currentSection;
            },
        },
}
</script>

<style>
    .designer-switch {
        margin: 0;
        max-height: 40px;
    }
</style>
