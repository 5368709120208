<template>
    <v-card class="ma-2">
        <v-card-title class="title font-weight-black">
            <v-icon class="pr-3">hotel</v-icon> Upcoming Reservations
        </v-card-title>
        <v-container>
            <v-layout column>
                <v-flex>
                    <v-btn href="/upcoming-confirmed-bookings/list">Upcoming Confirmed Bookings</v-btn>
                </v-flex>
                <v-flex>
                    <v-btn href="/upcoming-confirmed-cancellations/list">Confirmed Cancellations</v-btn>
                </v-flex>
                <v-flex>
                    <v-btn href="/upcoming-unconfirmed-cancellations/list">New Cancellations</v-btn>
                </v-flex>
                <v-flex>
                    <v-btn href="/completed-stays/list">Completed Stays</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </v-card>
</template>

<script>
    export default {
        name: "QuickButtons"
    }
</script>

<style scoped>

</style>
