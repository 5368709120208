<script>
    /**
     * Wrapper for Vue-ChartJS, providing fully automated dynamic scatter graph support for the data set named in the prop
     *
     *
     */

    // Import the application event bus so we can listen for data changes and react accordingly
    import EventBus from '../../../eventBus';

    // Import Vue-ChartJS to perform the rendering for us
    import VueCharts from 'vue-chartjs';
    import { Scatter } from 'vue-chartjs';


    export default{
        extends:    Scatter,
        props:      ['datasource', 'title', 'sectionindex', 'viewid', 'config', 'content'],
        data:       function() {
            return {
                data:       {},
                page:       {},
                loaded:     false,
            }
        },

        methods:    {

            /**
             * Request VueChartJS re-render the chart
             */
            updateView: function(){
                // Overwriting base render method with actual data.************
                this.renderChart({
                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                    datasets: [
                        {
                            label: 'GitHub Commits',
                            backgroundColor: ['#C1CD23', '#F15C61', '#088193', '#23C1CD', '#61F15C', '#CC99FF', '#ff9966', '#ff99ff', '#5C61F1', '#819308', '#FFFF66', '#CCFFFF'],
                            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
                        }
                    ]
                },
                {responsive: true, maintainAspectRatio: false}
                )
            }
        },


        /**
         * Tasks to perform once the Vue component has been constructed and page load event is fired
         *
         */
        mounted () {
            this.data.data = this.content;
            this.updateView();
        },

        /**
         * Tasks to perform when data variables are changed.  This cannot be done by computed properties in this case because we need to call a function
         * to perform the re-render within the parent VueChartJS component
         */
        watch:{
            /**
             * If the main data set is updated then redraw the graph
             */
            data: function(){
                this.updateView();
            }
        },
}
</script>
