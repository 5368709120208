<template>
  <v-col cols="12" md="6" lg="4" ma-1>
    <v-card class="ma-2">
        <v-card-title class="title font-weight-black">
            <v-icon class=pr-3>cloud_upload</v-icon> Channel Activity
        </v-card-title>
        <v-card-text>
            <div><strong>Incoming:</strong></div>
            <div>Incoming price and availability updates from channel managers</div>
            <v-btn class="mt-1"lock href="channel-requests-incoming/list"><v-icon>cloud_download</v-icon> Requests Received</v-btn>
            <v-btn class="mt-1" block href="channel-responses-outgoing/list"><v-icon>cloud_upload</v-icon> Responses Returned</v-btn>
            <v-btn class="mt-1" block href="channel-faults/list"><v-icon>report</v-icon> Channel Faults</v-btn>

            <div class="mt-3"><strong>Outgoing:</strong></div>
            <div>Outgoing booking/cancellation notifications sent to channel managers</div>
            <v-btn class="mt-1" block href="channel-queue/list"><v-icon>queue</v-icon> Queue</v-btn>
            <v-btn class="mt-1" block href="channel-sent/list"><v-icon>send</v-icon> Sent</v-btn>
            <v-btn class="mt-1" block href="channel-failed/list"><v-icon>warning</v-icon> Failed</v-btn>
            <v-btn class="mt-1" block href="channel-audit-events/list">Audit</v-btn>
        </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
    /**
     * Display buttons linking through to accounts reports
     */
    export default {
        props:[],

        data: function() {
            return {
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){},

        methods: {},

        computed: {}
    }
</script>
