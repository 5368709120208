var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "ies-green list-btn",
      attrs: { block: "" },
      on: {
        click: function ($event) {
          return _vm.editRecord()
        },
      },
    },
    [_vm._v("View")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }