<template>
    <v-container>

        <!-- Options bar - save, delete, print, email, etc -->
        <PortalDesignerOptionsBar @saveDesign="saveDesign()"></PortalDesignerOptionsBar>

        <v-layout column>
            <v-flex xs12>
                <v-card xs12>

                    <!-- Page mode selection for new pages -->
                    <v-card flat xs12 class="ma-3" v-if="newdesign">
                        <fieldset>
                            <legend>Select Page Mode</legend>
                            <v-radio-group v-model="design.pageMode" :mandatory="true" hide-details class="pa-0">
                                <v-radio label="List"         class="ies-green--text" value="1"></v-radio>
                                <v-radio label="Edit"         class="ies-green--text" value="2"></v-radio>
                                <v-radio label="Report"       class="ies-green--text" value="3"></v-radio>
                                <v-radio label="Dashboard"    class="ies-green--text" value="4"></v-radio>
                                <v-radio label="Data Import"  class="ies-green--text" value="5"></v-radio>
                                <v-radio label="Preview"      class="ies-green--text" value="99"></v-radio>
                            </v-radio-group>
                        </fieldset>
                    </v-card>

                    <!-- Start of form content for page -->
                    <form id="editForm" v-on:submit.prevent>

                        <!-- Page Display Name -->
                        <v-card flat xs12 class="ma-3">
                            <fieldset>
                                <legend>Page</legend>
                                <v-flex mt-2 mb-4>
                                    <label for="displayName">Page Name</label>
                                    <input id="displayName" type="text" class="full-width text-input-border mt-2 pa-2" name="displayName" v-model="design.pageName">
                                </v-flex>
                                <v-flex mt-2 mb-4>
                                    <label for="urlPattern">URL Pattern</label>
                                    <input id="urlPattern" type="text" class="full-width text-input-border mt-2 pa-2" name="urlPattern" v-model="design.page_url_pattern">
                                </v-flex>
                            </fieldset>
                        </v-card>

                        <!-- Permissions/Content Type -->
                        <v-card flat xs12 class="ma-3">
                            <fieldset>
                                <legend>Permissions</legend>
                                <label for="contentType">Content Type</label>
                                <v-select id="contentType" v-model="design.pageContentType" :items="contentTypeOptions" item-text="text" item-value="value" hide-details ></v-select>
                            </fieldset>
                        </v-card>

                        <!-- Related Pages/Bespoke URLs -->
                        <v-card flat xs12 class="ma-3">
                            <fieldset>
                                <legend>Related Pages</legend>
                                <v-flex class="pb-3" xs12>
                                    <label>Bespoke Urls </label>
                                    <v-divider></v-divider>
                                </v-flex>
                                <v-flex class="pb-3" xs12>
                                    <label for="editUrl">Record Edit Url</label>
                                    <input id="editUrl" type="text" class="full-width text-input-border mt-2 pa-2" name="editUrl" v-model="design.editUrl">
                                </v-flex>
                                <v-flex class="pb-3" xs12>
                                    <label for="createUrl">Record Create Url</label>
                                    <input id="createUrl" type="text" class="full-width text-input-border mt-2 pa-2" name="createUrl" v-model="design.createUrl">
                                </v-flex>
                                <v-flex class="pb-3" xs12>
                                    <label for="deleteUrl">Record Delete Url</label>
                                    <input id="deleteUrl" type="text" class="full-width text-input-border mt-2 pa-2" name="deleteUrl" v-model="design.deleteUrl">
                                </v-flex>
                                <v-flex class="pb-3" xs12>
                                    <label for="parentPage">Parent Page ID (breadcrumb)</label>
                                    <input id="parentPage" type="text" class="full-width text-input-border mt-2 pa-2" name="parentPage" v-model="design.parentPage">
                                </v-flex>
                            </fieldset>
                        </v-card>

                        <!-- Introductory Content - Introduction/information -->
                        <v-card flat xs12 class="ma-3">
                            <fieldset>
                                <legend>Introductory Content</legend>
                                <label for="heading">Page Heading</label>
                                <v-flex mt-2 mb-4>
                                    <input id="heading" type="text" class="full-width text-input-border mt-2 pa-2" name="heading" v-model="design.heading">
                                </v-flex>
                                <label for="introduction">Introduction</label>
                                <v-flex mt-2 mb-4>
                                    <textarea
                                        v-model="design.introduction"
                                        id="introduction"
                                        name="introduction"
                                    ></textarea>
                                </v-flex>
                                <label for="information">Information</label>
                                <v-flex mt-2 mb-4>
                                    <textarea
                                        v-model="design.information"
                                        id="information"
                                        name="information"
                                    ></textarea>
                                </v-flex><label for="warning">Warning</label>
                                <v-flex mt-2 mb-4>
                                    <textarea
                                        v-model="design.warning"
                                        id="warning"
                                        name="warning"
                                    ></textarea>
                                </v-flex>
                            </fieldset>
                        </v-card>

                        <!-- Layout of List pages -->
                        <v-card flat xs12 class="ma-3 pb-3" v-if="this.design.pageMode == 1">
                            <fieldset>
                                <legend>Layout</legend>

                                <!-- Show action buttons/Page size -->
                                <v-layout row wrap>
                                    <v-flex xs12 sm6 class="pb-3" >
                                        <label>Show Action Buttons</label>
                                        <v-checkbox hide-details v-model="design.showActionButtons"></v-checkbox>
                                        <div>When hidden the page will only list the records; the user will have no buttons to click through to other pages</div>
                                    </v-flex>
                                    <v-flex xs12 sm6 class="pb-3" >
                                        <label>Page Size</label>
                                        <v-select
                                            hide-details
                                            class="full-width"
                                            :items="pageSizes"
                                            item-text="text"
                                            item-value="value"
                                            v-model="design.pageSize"
                                        ></v-select>
                                    </v-flex>
                                </v-layout>
                            </fieldset>
                        </v-card>

                        <!-- Layout of Edit pages -->
                        <v-card flat xs12 class="ma-3 pb-3" v-if="this.design.pageMode == 2">
                            <fieldset>
                                <legend>Layout</legend>

                                <v-layout column>

                                    <!-- Header Column -->
                                    <v-flex class="pb-3" xs12 >
                                        <label>Header Column</label>
                                        <v-alert type="info" value="true">This field can be used for naming pages and titles, and is a more helpful way to remember records than ID numbers.  Choose a field if there is a unique name or reference for each record</v-alert>
                                        <v-select
                                            v-model="design.pageHeaderColumn"
                                            :items="headerColumnOptions"
                                            item-text="text"
                                            item-value="value"
                                        ></v-select>
                                    </v-flex>


                                </v-layout>
                            </fieldset>
                        </v-card>
                        <!-- Layout section end -->
                    </form>
                    <!-- End of main form content -->

                </v-card>
            </v-flex>
            <!-- End of first section for page details and layout, start of conditional sections -->




            <!-- Page model sorting (list and reports only) -->
            <v-flex xs 12 class="mb-3" v-if="this.design.pageMode == 1 || this.design.pageMode == 3" >
              <v-expansion-panels>
                <v-expansion-panel >
                    <v-expansion-panel-header>
                        <label>Predefined Sorting {{ sortOrdering.length > 0 ? '('+sortOrdering.length+')' : '' }}</label>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="ies-light-gray">
                        <div slot="header">
                                <label>Predefined Sorting {{ sortOrdering.length > 0 ? '('+sortOrdering.length+')' : '' }}</label>
                        </div>

                        <v-card class="pa-3">
                            <v-layout column>

                                    <!-- Sort Ordering -->
                                    <v-flex class="pb-3" xs12>
                                        <v-layout column>
                                            <v-flex xs12>
                                                <label>Sort order</label>
                                            </v-flex>
                                            <v-flex xs12 class="mt-3">
                                                <v-flex xs12>The records will be listed in the following order when the page is loaded.  Users will be able to re-order the results for themselves afterwards</v-flex>
                                            </v-flex>
                                            <v-flex xs12 v-for="(sortField, index) in sortOrdering" :key='index'>
                                                <v-layout row wrap>
                                                    <v-flex>
                                                        <v-select
                                                            :items="modelFields"
                                                            v-model="sortField.modelField"
                                                            label="The model-field"
                                                            class=""
                                                            item-text="optionText"
                                                            item-value="optionValue"
                                                            single-line
                                                        ></v-select>
                                                    </v-flex>
                                                    <v-flex>
                                                        <v-select
                                                            :items="sortOrderOptions"
                                                            v-model="sortField.order"
                                                            label="The direction"
                                                            class=""
                                                            item-value="value"
                                                            single-line
                                                        ></v-select>
                                                    </v-flex>
                                                    <v-flex xs12 sm1 class="mt-2">
                                                        <v-btn v-on:click="removeSorting(index)"><v-icon>delete</v-icon> Remove Sort Condition</v-btn>
                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row wrap>
                                            <v-flex xs2>
                                                <v-btn v-on:click="addSorting()" class="ies-green"><v-icon>add</v-icon> Add Sort Condition</v-btn>
                                            </v-flex>
                                            <v-flex xs2>
                                                <v-btn v-on:click="removeAllSorting()" :disabled="sortOrdering && sortOrdering.length == 0" class="ies-coral"><v-icon>cancel</v-icon> Remove All Conditions</v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>


                                </v-layout>
                            </fieldset>
                        </v-card>

                    </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-flex>


            <!-- Page model filtering (list and reports only) -->
            <v-flex xs 12 class="mb-3" v-if="this.design.pageMode == 1 || this.design.pageMode == 3">
              <v-expansion-panels>
                <v-expansion-panel >
                    <v-expansion-panel-header>
                        <label>Predefined Filters {{ filters && filters.fields && filters.fields.length > 0 ? '('+filters.fields.length+')' : '' }}</label>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="ies-light-gray">
                        <v-card class="pa-3">
                            <v-layout column>
                                    <v-flex class="pb-3 mt-3" xs12>
                                        <v-layout column>
                                            <v-flex xs12>
                                                <label>Predefined Filters</label>
                                            </v-flex>
                                            <v-flex xs12 class="mt-3">
                                                <v-flex>Show me all records where <strong>all</strong> of these conditions are true:</v-flex>
                                            </v-flex>
                                            <v-flex xs12 v-for="(filter, index) in filters.fields" :key='index' v-if="filters && filters.fields">
                                                <v-layout row wrap>
                                                    <v-flex xs12 sm4 class="mr-2">
                                                        <v-select
                                                            :items="modelFields"
                                                            v-model="filter.modelField"
                                                            label="The model-field"
                                                            class=""
                                                            item-text="optionText"
                                                            item-value="optionValue"
                                                            single-line
                                                        ></v-select>
                                                    </v-flex>
                                                    <v-flex xs12 sm2 class="mr-2">
                                                        <v-select
                                                            :items="operators"
                                                            v-model="filter.operator"
                                                            label="Operator"
                                                            class=""
                                                            item-value="value"
                                                            single-line
                                                        ></v-select>
                                                    </v-flex>
                                                    <v-flex xs12 sm2 class="mr-2">
                                                        <input type="text" v-model="filter.value" class="text-input-border mt-2 pa-2" placeholder="Value">
                                                    </v-flex>
                                                    <v-flex xs12 sm1 class="mt-2">
                                                        <v-btn v-on:click="removeFilter(index)"><v-icon>delete</v-icon> Remove Filter</v-btn>
                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>
                                        <v-card-actions>
                                            <v-btn v-on:click="addFilter()" class="ies-green"><v-icon>add</v-icon> Add Filter</v-btn>
                                            <v-btn v-on:click="removeAllFilters()" :disabled="filters.fields.length == 0" class="ies-coral"><v-icon>cancel</v-icon> Remove All Filters</v-btn>
                                        </v-card-actions>
                                    </v-flex>

                                </v-layout>
                            </fieldset>
                        </v-card>

                    </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-flex>

            <!-- Relationships Section -->
            <v-flex xs 12 class="mb-3">
              <v-expansion-panels>
                <v-expansion-panel >
                    <v-expansion-panel-header>
                        <label>Relationships {{ relationships.length > 0 ? '('+relationships.length+')' : '' }}</label>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="ies-light-gray">
                        <v-card class="pa-3">
                            <v-layout column>
                                <v-flex xs12 class="mb-3">
                                    <label for="primaryModelSelect">Select the primary model</label>
                                </v-flex>
                                <v-flex xs12 mb-3>
                                    <select id="primaryModelSelect" name="primaryModelSelect" v-model="design.primaryModel">
                                        <option value="">Select Primary Key Table</option>
                                        <option :value="option" v-for="option in primaryModelOptions"> {{ option }} </option>
                                    </select>
                                </v-flex>
                                <v-flex xs12>Show records where the following relationships exist between the relevant models:</v-flex>
                                <v-flex xs12 v-for="(relation, index) in relationships" :key='index'>
                                    <v-layout row wrap>
                                        <v-flex xs12 sm4 class="mr-2">
                                            <v-select
                                                    :items="modelFieldNames"
                                                    v-model="relation.modelFieldA"
                                                    label="Model-field A"
                                                    class=""
                                                    item-value="value"
                                                    single-line
                                                    autocomplete
                                            ></v-select>
                                        </v-flex>
                                        <v-flex xs12 sm2 class="mr-2">
                                            <v-select
                                                    :items="joinTypes"
                                                    v-model="relation.joinType"
                                                    label="Join Type"
                                                    class=""
                                                    item-value="value"
                                                    single-line
                                            ></v-select>
                                        </v-flex>
                                        <v-flex xs12 sm4 class="mr-2">
                                            <v-select
                                                    :items="modelFieldNames"
                                                    v-model="relation.modelFieldB"
                                                    label="Model-field B"
                                                    class=""
                                                    item-value="value"
                                                    single-line
                                                    autocomplete
                                            ></v-select>
                                        </v-flex>
                                        <v-flex xs12 sm1 class="mt-2">
                                            <v-btn v-on:click="removeRelationship(index)"><v-icon>delete</v-icon> Remove</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs12>
                                    <v-btn v-on:click="addRelationship()" class="ies-green"><v-icon>add</v-icon> Add Relationship</v-btn>
                                    <v-btn v-on:click="removeAllRelationships()" :disabled="relationships.length == 0" class="ies-coral"><v-icon>cancel</v-icon> Remove All Relationships</v-btn>
                                </v-flex>
                            </v-layout>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-flex>
            <!-- End Relationships Section -->

            <!-- Grouping Section (list and reports only) -->
            <v-flex xs12 class="mb-3" v-if="this.design.pageMode == 1 || this.design.pageMode == 3" >
              <v-expansion-panels>
                <v-expansion-panel >
                    <v-expansion-panel-header>
                        <label>Grouping {{ grouping.length > 0 ? '('+grouping.length+')' : '' }}</label>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="ies-light-gray">
                        <v-card class="pa-3">
                            <v-layout column>
                                <v-flex xs12 mb-3>Compress the results so that there is one row returned for every unique combination of the following fields, in this order:</v-flex>
                                <v-flex v-for="(groupField, index) in grouping" :key='index' xs12>
                                    <v-layout row wrap>
                                        <v-flex xs12 sm4 class="mr-2">
                                            <v-select
                                                    :items="modelFieldNames"
                                                    v-model="groupField.modelField"
                                                    label="Model-field A"
                                                    class=""
                                                    item-value="value"
                                                    single-line
                                                    autocomplete
                                            ></v-select>
                                        </v-flex>
                                        <v-flex xs12 sm1 class="mt-2">
                                            <v-btn v-on:click="removeGrouping(index)"><v-icon>delete</v-icon> Remove</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs12>
                                    <v-btn v-on:click="addGrouping()" class="ies-green"><v-icon>add</v-icon> Add Grouping Field</v-btn>
                                    <v-btn v-on:click="removeAllGrouping()" :disabled="grouping.length == 0" class="ies-coral"><v-icon>cancel</v-icon> Remove Grouping</v-btn>
                                </v-flex>
                            </v-layout>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-flex>
            <!-- End Grouping Section -->

            <!-- Page Structure Sections -->
            <v-flex xs12>
                <v-card>
                    <v-layout column>

                        <!-- Main 'mode' toolbar -->
			<v-row>
                        <v-col class="col-xs-12 mt-3 mb-3">
                            <v-card class="ies-green p-5">
                                <v-row>
                                    <v-col sm="4" class="ies-dark-gray--text title ma-3">
                                        {{ this.getPageMode() }}
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col sm="2" v-if="design.sections.length == 0 || design.pageMode != 3">
                                        <v-btn @click.native.stop="addNewSection()">Add Section</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
			</v-row>

                        <!-- Sections -->
			<v-row class="flex-column" no-gutters>
                        <v-col v-for="(section, sectionKey) in design.sections" :key="sectionKey" class="col-xs-12" v-if="design.pageMode == 1 || design.pageMode == 2 || design.pageMode == 3 || design.pageMode == 4">
                          <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                        <v-row>
                                            <v-col sm="6">
                                                <v-row class="wrap full-height">
                                                    <div class="v-align-container full-height">
                                                        <v-col class="v-align-div" sm="2">
                                                            <label v-bind:class="{ 'white--text': ((sectionKey % 2) != 0), 'grey--text text--darken-4': ((sectionKey % 2) == 0)}">Section Name</label>
                                                        </v-col>
                                                        <v-col class="v-align-div pl-3" sm="3">
                                                            <input type="text" class="pa-2" v-bind:class="{ 'text-input-border-dark': ((sectionKey % 2) === 0), 'text-input-border': ((sectionKey % 2) !== 0)}" v-model="section.sectionName">
                                                        </v-col>
							<v-col class="v-align-div" sm="2">
                                                            <label v-bind:class="{ 'white--text': ((sectionKey % 2) != 0), 'grey--text text--darken-4': ((sectionKey % 2) == 0)}">Section Width</label>
                                                        </v-col>
                                                        <v-col class="v-align-div pl-3" sm="1">
                                                            <input type="number" min="1" max="12" placeholder="12" class="pa-2" v-bind:class="{ 'text-input-border-dark': ((sectionKey % 2) === 0), 'text-input-border': ((sectionKey % 2) !== 0)}" v-model="section.sectionWidth">
                                                        </v-col>

                                                    </div>
                                                </v-row>
                                            </v-col>
                                            <v-col sm="6">
                                                <v-row class="wrap justify-end">
                                                    <v-col sm="3" class="ml-1 mr-1">
                                                        <v-btn block class="full-width" @click.native.stop="moveSection('up', sectionKey)">Move up</v-btn>
                                                    </v-col>
                                                    <v-col sm="3" class="ml-1 mr-1">
                                                        <v-btn block class="full-width ies-coral" @click.native.stop="deleteSection(sectionKey)">Delete</v-btn>
                                                    </v-col>
                                                    <v-col sm="3" class="ml-1 mr-1">
                                                        <v-btn block class="full-width" @click.native.stop="moveSection('down', sectionKey)">Mode Down</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                </v-expansion-panel-header>


                                <v-expansion-panel-content v-bind:class="{ 'ies-dark-gray': ((sectionKey % 2) != 0 ), 'ies-light-gray': ((sectionKey % 2) == 0)}">
                                    <v-card class="pt-3 pr-3 pl-3">
                                        <v-row>
                                            <!-- Editorial Content -->
                                            <v-col sm="12" class="">
                                              <v-expansion-panels>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                            <v-row>
                                                                <v-col>
                                                                    <div class="v-align-container full-height">
                                                                        <div class="v-align-div">
                                                                            <label>Content</label>
                                                                        </div>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                    </v-expansion-panel-header>

                                                    <v-expansion-panel-content class="ies-light-gray">
                                                        <v-card>
															<v-row class="px-3 no-gutters">
																<label for="section-introduction">Introduction</label>
																<v-col sm="12" class="mt-2 mb-4">	
																	<input type="text" class="full-width pa-2" v-bind:class="{ 'text-input-border-dark': ((sectionKey % 2) === 0), 'text-input-border': ((sectionKey % 2) !== 0)}" v-model="section.introduction">
																</v-col>
																<label for="section-introduction">Outroduction</label>
																<v-col sm="12" class="mt-2 mb-4">
																	<input type="text" class="full-width pa-2" v-bind:class="{ 'text-input-border-dark': ((sectionKey % 2) === 0), 'text-input-border': ((sectionKey % 2) !== 0)}" v-model="section.outroduction">
															   </v-col>
															</v-row>
                                                        </v-card>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                              </v-expansion-panels>
                                            </v-col>

                                            <!-- Permissions -->
                                            <v-col sm="12" class="">
                                              <v-expansion-panels>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        <label>Permissions</label>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content class="ies-light-gray">
                                                        <v-card>
                                                            <PortalDesignerPermissions :object="section" :permissions="contentTypeOptions"> </PortalDesignerPermissions>
                                                        </v-card>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                              </v-expansion-panels>
                                            </v-col>

                                            <!-- Rules -->
                                            <v-col sm="12" class="">
                                              <v-expansion-panels>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                            <v-row>
                                                                <v-col>
                                                                    <div class="v-align-container full-height">
                                                                        <div class="v-align-div">
                                                                            <label>Rules</label>
                                                                        </div>
                                                                    </div>
                                                                </v-col>
                                                                <v-spacer></v-spacer>
                                                                <v-col sm="2" class="mx-1 pa-1">
                                                                    <v-btn @click.native.stop="addNewRule(sectionKey)" block class="full-width">Add new Rule</v-btn>
                                                                </v-col>
                                                            </v-row>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content class="ies-light-gray">
                                                        <v-card>
                                                            <PortalDesignerRules
                                                                :section="section"
                                                                :sectionkey="sectionKey"
                                                                :models="models"
                                                            > </PortalDesignerRules>
                                                        </v-card>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                              </v-expansion-panels>
                                            </v-col>

                                            <!-- Fields -->
                                            <v-col sm="12" class="">
                                              <v-expansion-panels>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                            <v-row>
                                                                <v-col>
                                                                    <div class="v-align-container full-height">
                                                                        <div class="v-align-div">
                                                                            <label>Fields</label>
                                                                        </div>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                    </v-expansion-panel-header>

                                                    <v-expansion-panel-content class="ies-light-gray">
                                                        <v-card>
                                                            <v-col cols="12">
                                                                <v-btn @click.native.stop="addNewField(sectionKey,'modelField')" class="ies-green">Add new field </v-btn>
                                                                <v-btn @click.native.stop="addNewField(sectionKey,'customField')">Add Custom field </v-btn>
                                                                <v-btn @click.native.stop="addNewField(sectionKey,'compositeField')">Add Composite field </v-btn>
                                                                <v-btn @click.native.stop="addNewField(sectionKey,'associationField')">Add Associated field </v-btn>
                                                            </v-col>

                                                            <PortalDesignerFields
                                                                v-if="design.pageMode == 1 || design.pageMode == 2 || design.pageMode == 3 || design.pageMode == 4"
                                                                :section="section"
                                                                :sectionkey="sectionKey"
                                                                :used_models="usedModels"
                                                                :models="models"
                                                                :field_display_types="fieldDisplayTypes"
                                                                :modelFieldNames="modelFieldNames"
                                                                :joinTypes="joinTypes"
                                                                :primaryModel="design.primaryModel"
                                                                :design="design"
                                                            > </PortalDesignerFields>
                                                        </v-card>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                              </v-expansion-panels>
                                            </v-col>

                                            <v-col sm="12" class="">
                                              <v-expansion-panels>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                            <v-row>
                                                                <v-col>
                                                                    <div class="v-align-container full-height">
                                                                        <div class="v-align-div">
                                                                            <label>  Visualisations</label>
                                                                        </div>
                                                                    </div>
                                                                </v-col>
                                                                <v-spacer></v-spacer>
                                                                <v-col sm="2" class="mx-1 pa-1">
																	<v-btn @click.native.stop="addNewVisualisation(sectionKey)" block class="full-width">Add New Visualisation</v-btn>
                                                                </v-col>
                                                            </v-row>
                                                    </v-expansion-panel-header>

                                                    <v-expansion-panel-content v-bind:class="{ 'ies-dark-gray': ((sectionKey % 2) != 0 ), 'ies-light-gray': ((sectionKey % 2) == 0)}">
                                                        <v-card>
                                                            <v-flex v-if="design.pageMode == 3">
                                                                <v-flex v-for="(visualisation, visualisationKey) in section.visualisations" :key="visualisationKey" xs12>
                                                                    <PortalDesignerReportVisualisations
                                                                        :section="section"
                                                                        :sectionkey="sectionKey"
                                                                        :used_models="usedModels"
                                                                        :models="models"
                                                                        :field_display_types="fieldDisplayTypes"
                                                                        :modelFieldNames="modelFieldNames"
                                                                        :joinTypes="joinTypes"
                                                                        :primaryModel="design.primaryModel"
                                                                        :visualisation="visualisation"
                                                                     > </PortalDesignerReportVisualisations>
                                                                </v-flex>
                                                            </v-flex>
                                                        </v-card>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                              </v-expansion-panels>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
			</v-row>

                        <v-flex v-if="design.pageMode == 4">
                            <v-flex v-for="(section, sectionKey) in design.sections" :key="sectionKey" xs12>
                               *** 
                            <PortalDesignerDashboard
                                :section="section"
                                :sectionkey="sectionKey"
                                :used_models="usedModels"
                                :models="models"
                                :field_display_types="fieldDisplayTypes"
                                :modelFieldNames="modelFieldNames"
                                :joinTypes="joinTypes"
                                :primaryModel="design.primaryModel"
                            > </PortalDesignerDashboard>

                            </v-flex>
                        </v-flex>

                    </v-layout>
                </v-card>
            </v-flex>
            <!-- End Page Structure Sections -->

        </v-layout>

        <!-- Options bar - save, delete, print, email, etc -->
        <PortalDesignerOptionsBar @saveDesign="saveDesign()" class="mt-3"></PortalDesignerOptionsBar>

        <!-- Snackbars -->
        <v-snackbar
        :timeout="parseInt(6000)"
        v-model="successSnackbar"
        multi-line
        color="ies-green"
        >
           <div class="full-height full-width v-align-container text-xs-center">
              <h4 class="ies-dark-gray--text v-align-div">Design Saved</h4>
           </div>
        </v-snackbar>
        <v-snackbar
        :timeout="parseInt(6000)"
        v-model="errorSnackbar"
        multi-line
        color="ies-coral"
        >
           <div class="full-height full-width v-align-container text-xs-center">
              <h4 class="ies-dark-gray--text v-align-div">Design Not Valid</h4>
           </div>
        </v-snackbar>

    </v-container>
</template>

<script>
    import axios from 'axios';
    import tinymce from '@tinymce/tinymce-vue';
    import PortalDesignerOptionsBar from "./PortalDesignerOptionsBar";

    var permissions = require("./PortalDesignerPermissions.vue").default;
    var rules = require("./PortalDesignerRules.vue").default;
    var fields = require("./PortalDesignerFields.vue").default;
    var dashboard = require("./PortalDesignerDashboard.vue").default;
    var reportVisualisations = require("./PortalDesignerReportVisualisations.vue").default;

    export default {
        name: 'PortalDesigner',
        props:['pagedesign', 'newdesign', 'contenttypes'],
        data: function() {
            return {
                drawer:             true,
                errorSnackbar:      false,
                errorSnackbarData:  [],
                successSnackbar:    false,
                activeView:         null,
                primaryModelOptions:[],
                headerColumnOptions:[],
                sortOrdering:       [],
                sortOrderOptions:   [{ text: 'Ascending' , value: 'ASC' }, { text: 'Descending', value: 'DESC' }],
                /** @var [] contentTypeOptions - A list of PortalContentType records to be used anywhere the user needs to choose a page access permission */
                contentTypeOptions: this.getContentTypes(),
                usedModels:         [],
                fieldDisplayTypes:  [],
                models:             [],
                hasModels:          false,
                design:             this.getDesign(),
                relationships:      [],
                grouping:           [],
                filters:            {"type":"group", "condition":"AND", "fields":[]},
                pageSizes:          [{text:'Large', value: 1},{text:'Medium', value: 2},{text:'Small', value: 3}],
            }
        },
        components: {
            PortalDesignerOptionsBar,
            'PortalDesignerPermissions' : permissions,
            'PortalDesignerRules' : rules,
            'PortalDesignerFields' : fields,
            'PortalDesignerDashboard' : dashboard,
            'PortalDesignerReportVisualisations' : reportVisualisations,
            'tinymce' : tinymce,
        },
        created: function(){
                    this.getDisplayTypes();
        },
        methods:{
           getDesign: function(){
               //
               var design = {
                   'pageName':          '',
                   'pageUrlPattern':    '',
                   'showActionButtons': true,
                   'pageSize':          '',
                   'primaryModel':      '',
                   'pageHeaderColumn':  '',
                   'pageContentType':   '',
                   'relationships':     [],
                   'grouping':          [],
                   'sortOrdering':      [],
                   'pageMode':          '1',
                   'sections':          [],
                   'rules':             [],
                   'permissions':       permissions,
                   'modelFields':       [],
                   'filters':           {"type":"group", "condition":"AND", "fields":[]}
               };


               if(this.pagedesign){
                   design = this.pagedesign;
               }

               if(!design.permissions){
                   design.permissions = permissions;
               }

               this.getModels().then(function(){
                   this.hasModels = true;
                   this.getOptionLists(design);
                   this.getHeaderColumnOptions(design);
               }.bind(this));
               return design;
           },


           /**
            * Takes the portal_content_type records provided and formats them as required into the select box options ready to
            * be presented to the user
            */
           getContentTypes(){
                var contentTypeOptions = [];

                this.contenttypes.forEach(function(record){
                    contentTypeOptions.push({ text:record.name, value:record.portal_content_type_key});
                });

                return contentTypeOptions;
           },

            /**
             *
             */
           getHeaderColumnOptions(design){
                this.headerColumnOptions = [];
                if (design.primaryModel) {
                    var object  = this.getColumns(design.primaryModel);
                    if (object){
                        var options = Object.keys(object);
                        options.forEach(function(value) {
                            this.headerColumnOptions.push({ text:value, value:value });
                        }.bind(this));
                    }
                }
           },

           /**
            * Loads a list of all available models supported by the Portal admin site for use in pages or query relationships
            *
            * @return []
            */
           getModels: function(){
              return axios.get("/ajax/getModels")
               .then(function(response){
                   this.models = response.data;
               }.bind(this));

           },

            /**
             *
             */
           getDisplayTypes: function(dataType){
               axios.get('/ajax/getDisplayTypes').then(function(response){
                   if(response.data.sucess){
                       this.fieldDisplayTypes = response.data.displayTypes;
                   }
               }.bind(this));

            },

            /**
             *
             */
           getPageMode: function(){
               if (this.design.pageMode == 1){
                  return "List Fields";
               }
               else if(this.design.pageMode == 2){
                  return "Editable Form Sections";
               }
               else if(this.design.pageMode == 3){
                    return "Report Sections";
               }
               else if(this.design.pageMode == 4){
                    return "Dashboard Panels";
               }
               else if(this.design.pageMode == 99){
                  return "Preview";
               }
           },

            /**
             * Remove the relationship at the specified index number from the list altogether
             *
             * @param int index - The index position of the relationship within the array
             */
            removeRelationship: function(index){
                this.relationships.splice(index, 1);
            },

            /**
             * Removes all relationships from the page
             */
            removeAllRelationships: function(){
                this.relationships = [];
            },

            /**
             * Add a new blank relationship to the array
             */
            addRelationship: function(){
                this.relationships.push({ 'modelFieldA': '', 'joinType': '', 'modelFieldB': '' });
            },

            /**
             * Remove the group-by field at the specified index number
             *
             * @param int index - The index position of the group-by field within the array
             */
            removeGrouping: function(index){
                this.grouping.splice(index, 1);
            },

            /**
             * Removes all group-by fields from the page query
             */
            removeAllGrouping: function(){
                this.grouping = [];
            },

            /**
             * Add a new blank group-by field name to the array
             */
            addGrouping: function(){
                this.grouping.push({ 'modelField': '' });
            },

            /**
             *
             */
           getColumns: function(modelName){
                var columns = [];
                columns = this.models[modelName];
                return columns;
           },

           /**
            * Interrogates the page structure to get a list of the models and fields used on this page
            */
           getOptionLists: function(design){
               var modelOptions = [];
               var fieldList    = [];

               design.sections.forEach(function(section, sectionKey){
                   section.fields.forEach(function(field,fieldKey){
                       if(field.modelName){
                           if(modelOptions.indexOf(field.modelName) < 0 ){
                               modelOptions.push(field.modelName);
                           }
                       }

                       if (field.fieldName){
                               field.optionText  = '(' + field.modelName + ") - " + field.fieldName;
                               field.optionValue = field.modelName + '.' + field.fieldName;
                       }

                       if(field.isComposite){
                           field.optionText  = "Composite: " + field.displayName;
                           field.optionValue = field.alias;
                       }

                       fieldList.push(field);
                   });
               });

               if(modelOptions.length == 1){
                   this.design.relationships= [];
               }

               // Expose the data we have just compiled so that the view can use it immediately
               this.primaryModelOptions = modelOptions;
           },

           addNewSection: function() {
               var newSection = {};
               switch(parseInt(this.design.pageMode)){
                   case 3:
                       newSection = this.getNewReportSection();
                       break;
                   case 4:
                       newSection = this.getNewDashboardSection();
                       break;
                   default:
                       newSection = this.getNewFormSection();
               }
               this.design.sections.push(newSection);
           },

           getNewDashboardSection: function(){
console.log("will add new section for dash");
               return {
                   'sectionName' : 'Dashboard ' + this.design.sections.length,
                   'sectionWidth': '',
                   'introduction': '',
                   'fields' : [],
                   'dashboards' : [{'displayType': 'dashboard', 'dashboardName': '' }],
                   'rules' : []
                };
           },

           getNewFormSection: function(){
               return {
                   'sectionName' : '',
                   'sectionWidth': '',
                   'introduction': '',
                   'fields' : [],
                   'rules' : []
                };
           },

           getNewReportSection: function(){
               return {
                   'sectionName' : '',
                   'sectionWidth': '',
                   'introduction': '',
                   'fields' : [],
                   'visualisations' : [],
                   'rules' : []
                };
           },

           addNewField: function(sectionKey, type) {

               if(type == 'modelField'){
                   var newField = {'modelName':'', 'fieldName': '', 'displayType': 'default'};
               }
               if(type == 'customField'){
                   var newField = {'isCustom': true, 'displayType': 'custom', 'customComponent': '' };
               }
               if(type == 'compositeField'){
                   var newField = { 'isComposite': true, 'displayType': 'default', 'modelNameA': '', 'fieldNameA': '', 'modelNameB': '', 'fieldNameB': '', 'alias': '', 'displayName': '', 'customComponent' : '' };
               }
               if(type == 'associationField'){
                   var newField = { 'isAssociation': true, 'displayType': 'custom', 'modelName':'', 'fieldName': '','displayName': '', 'fieldRelationships': [] };
               }
               this.design.sections[sectionKey].fields.push(newField);
           },
           addNewRule: function(sectionKey) {

               var newRule = {'modelName':'', 'fieldName': '', 'operator': '', 'value': ''};
               this.design.sections[sectionKey].rules.push(newRule);

           },
           addNewVisualisation: function(sectionKey) {
               var newVisualisation = {'type':'chartLine', 'modelNameA':'', 'fieldNameA':'', 'modelNameB':'', 'fieldNameB':'' };
				if (!this.design.sections[sectionKey].visualisations){
                   this.$set(this.design.sections[sectionKey], 'visualisations', [])
               }
               this.design.sections[sectionKey].visualisations.push(newVisualisation);
           },
           deleteSection:function(section){

               delete this.design['sections'][section];
               this.design['sections']=  this.design['sections'].filter(function(n){ return n != undefined });

           },
           moveSection: function(direction, sectionKey){

              //Determine destination
              if(direction == 'up'){
                  var destinationKey = sectionKey-1
              }
              else{
                  var destinationKey = sectionKey+1
              }

              if( this.design['sections'][destinationKey]){
                  // get sections
                  var sectionToMove = this.design['sections'][sectionKey];
                   var sectionToReplace = this.design['sections'][destinationKey];

                  // swap sections
                  this.design['sections'][sectionKey] = sectionToReplace;
                  this.design['sections'][destinationKey] = sectionToMove

                  // update to refresh render
                  this.design['sections']=  this.design['sections'].filter(function(n){ return n != undefined });
              }

           },

            /**
             * Checks the data for the relationships for this design checks the data is complete and formats for the view
             */
            setViewRelationships: function(){
                var relationships = [];

                for (var index = 0; index < this.design.relationships.length; index++){
                    var relationship = this.design.relationships[index];
                    // Some robustness, checking that the data from the database is in a usable format and not crashing the entire page if it is damaged
                    // This means we get the chance to overwrite it with new copies
                    if (relationship.modelNameA && relationship.fieldNameA && relationship.joinType && relationship.modelNameB && relationship.fieldNameB){
                        relationships.push({
                            'modelFieldA': relationship.modelNameA + '.' + relationship.fieldNameA,
                            'joinType'  : relationship.joinType,
                            'modelFieldB': relationship.modelNameB + '.' + relationship.fieldNameB
                        });
                    }
                }
                // Apply the fresh copy to the template
                this.relationships = relationships;
            },


           setViewGrouping: function(){
               var grouping = [];
               if (this.design.grouping){
               for (var index = 0; index < this.design.grouping.length; index++){
                   var groupBy = this.design.grouping[index];

                   // Some robustness, checking that the data from the database is in a usable format and not crashing the entire page if it is damaged
                   // This means we get the chance to overwrite it with new copies
                   if (groupBy.modelName && groupBy.fieldName){
                       // The view models concatenate the model and field names to save the user clicking twice as many times, and the code juggling lots of
                       // different models
                       var model = groupBy.modelName + '.' + groupBy.fieldName;
                       grouping.push({ 'modelField': model });
                   }
               }
               }

               // Apply the fresh copy to the template
               this.grouping = grouping;
           },

            /**
             * Set up the view models to reflect the sorting defined in the database for this page, if any is set
             *
             * @see this.sortOrdering
             */
             setViewOrdering: function(){
                var sortOrdering = [];

                // check if sortOrdering of this design is set before trying to access it
                if (this.design.sortOrdering) {

                    for (var index = 0; index < this.design.sortOrdering.length; index++){
                        var sortOrder = this.design.sortOrdering[index];

                        // For standard fields we ensure it has the modelField attribute the view is expecting, made as a concatenation of its model name and field name
                        // Some robustness, checking that the data from the database is in a usable format and not crashing the entire page if it is damaged
                        // This means we get the chance to overwrite it with new copies if it becomes invalid
                        if (sortOrder.modelName && sortOrder.fieldName && sortOrder.order){
                            sortOrder.modelField = sortOrder.modelName + '.' + sortOrder.fieldName;
                            sortOrdering.push(sortOrder);
                        }

                        // Composites of two database fields must have their modelField attribute set explicitly because it is a non-standard situation and the view
                        // won't know how to represent something so complex using only a select box label
                        if (sortOrder.isComposite){
                            sortOrdering.push(sortOrder);
                            sortOrder.modelField = 'Composite: ' + sortOrder.alias;
                        }
                    }
                }

                // Apply the fresh copy to the template
                this.sortOrdering = sortOrdering;
             },

           /**
            * This function will update the data model from the view model by iterating over the view model's
            * relationship data and setting the data model to reflect what the user has asked for
            */
            setDataRelationships: function(){
                var relationships = [];

                for (var index = 0; index < this.relationships.length; index++){
                    var relationship  = this.relationships[index];

                    // Only update the data model if the user has completed all the fields
                    if (relationship.modelFieldA &&
                        relationship.joinType &&
                        relationship.modelFieldB
                    ){
                        var modelAParts = relationship.modelFieldA.split('.');
                        var modelBParts = relationship.modelFieldB.split('.');

                        relationships.push({
                            'modelNameA': modelAParts[0],
                            'fieldNameA': modelAParts[1],
                            'joinType'  : relationship.joinType,
                            'modelNameB': modelBParts[0],
                            'fieldNameB': modelBParts[1]
                        });
                    }
                    else console.log("The fields have not been set");
                }
                this.design['relationships'] = relationships;
            },


            setDataGrouping: function(){
                var grouping = [];

                for (var index = 0; index < this.grouping.length; index++){
                    var groupBy = this.grouping[index];

                    // Only update the data model if the user has completed all the fields
                    if (groupBy.modelField){
                        // The model and field names are concatenated strings in the view models so we must split them back out again here.  It's the quickest way
                        // without juggling other models, although it can be easily replaced or upgraded in future as it does not affect the actual data model or
                        // anything else
                        var parts     = groupBy.modelField.split('.');
                        var modelName = parts[0];
                        var fieldName = parts[1];

                        grouping.push({ 'modelName': modelName, 'fieldName': fieldName });
                    }
                }
                this.design['grouping'] = grouping;
            },

           /**
            *
            * Save the design to the database
            */
           saveDesign: function(){
                // Some of the fields are managed through the TinyMCE WYSIWYG editor.  Unfortunately that editor breaks the binding between the textarea and the Vue model
                // thereby meaning we need to manually set the model here to be sure it is correct before saving
                this.design.introduction = document.getElementById('introduction').value;
                this.design.information  = document.getElementById('information').value;
                this.design.warning      = document.getElementById('warning').value;

                // Ensure that the data model relationships are entirely up to date with the view model relationships the user has been interacting with
                this.setDataRelationships();

                // Ensure that the grouping of the data is up to date with the UI components the user has set
                this.setDataGrouping();

                // Copy the sort ordering from the view models used by the user to define what they want into the actual data models to be saved to the database
                this.applySortOrdering();

                // Copy all filters from the view models used b the user to the actual data model
                this.applyFilters();

                // Ensure fields have the right content
                this.ensureFieldStructure();

                // AJAX save request to the i-escape server passing the entire design model as a payload
                axios.post('/ajax/saveDesign', {design: this.design})
                .then(function(response){
                    if(!response.data.success) {
                        this.errorSnackbar = true;
                    }
                    else if(response.data.success) {
                        this.successSnackbar = true;
                    }
                }.bind(this));
           },

           ensureFieldStructure: function(){
               this.design.sections.forEach(function(section, sectionKey){
                   section.fields.forEach(function(field, fieldKey){
                       if (!field.validation){
                           field.validation = [];
                       }
                   });
               });
           },


            validateData: function(){},


            /**
             * The sort ordering view template makes it easy for the user by showing them the model and field name combined, which means we need separate view models
             * for the select box which concatenates the model and field names together.  This is then needed to be converted into the actual data to be saved to the
             * page record afterwards, so this method converts the data format and keeps them in sync with what the user has chosen
             */
            applySortOrdering: function(){
                var newSortOrder = [];

                for (var index = 0; index < this.sortOrdering.length; index++){
                    var sortField = this.sortOrdering[index];


                    if (sortField.modelField.startsWith('Composite: ')){
                        var aliasName = sortField.modelField.replace('Composite: ', '');

                        // Find the referenced field by iterating over each of the fields used in turn and adding the composite one with the matching alias to the list
                        this.design.sections.forEach(function(section, sectionKey){
                            section.fields.forEach(function(field, fieldKey){
                                if (field.isComposite && field.alias == aliasName){
                                    var newSortField    = field;
                                    newSortField.order  = sortField.order;
                                    newSortOrder.push(newSortField);
                                }
                            });
                        });
                    }
                    else {
                        // Do not include any sortings that are missing required information
                        if (sortField.modelField && sortField.order){
                            var parts = sortField.modelField.split('.');
                            var modelName = parts[0];
                            var fieldName = parts[1];
                            newSortOrder.push({ 'modelName': modelName, 'fieldName': fieldName, 'order': sortField.order, 'isComposite': false });
                        }
                        else {
                            console.log('cant add to list as the data is not set');
                        }
                    }
                }
                this.design.sortOrdering = newSortOrder;
            },

            /**
             * Remove the sorting condition at the specified index number from the list altogether
             *
             * @param int index - The index position of the filter within the array
             */
            removeSorting: function(index){
                this.sortOrdering.splice(index, 1);
            },

            /**
             * Removes all sorting from the tool and triggers a re-search, thereby getting an unfiltered and fully up-to-date copy of the full dataset
             */
            removeAllSorting: function(){
                this.sortOrdering = [];
            },

            /**
             * Add a new blank sort-by condition to the array
             */
            addSorting: function(){
                if (!this.sortOrdering){
                    this.sortOrdering = [];
                }
                this.sortOrdering.push({ 'modelField': '', 'order': 'ASC' });
            },

            /**
             * Set up the view models to reflect the predefined filters in the database for this page, if any is set
             *
             * @see this.filters
             */
            setViewFilters() {
                // check if filters of this design is set before trying to access it
                if (this.design.filters && this.design.filters.length > 0) {
                    //var designFilters = JSON.parse(this.design.filters);
                    //designFilters.forEach((filter) => {
                    //    filters.push(filter);
                    //});
                    this.filters = JSON.parse(this.design.filters);
                }
            },

            /**
             * Apply currently set filters to page design
             * @see this.filters
             */
            applyFilters() {
                this.design.filters = this.filters;
            },

            /**
             * Add a new blank filter to the array
             * Make operator IS default
             */
            addFilter() {
                this.filters.fields.push({ 'modelField': '', 'operator': '==', 'value': '' });
            },

            /**
             * Remove the filter at the specified index number from the list altogether
             *
             * @param int index - The index position of the filter within the array
             */
            removeFilter(index) {
                this.filters.fields.splice(index, 1);
            },

            /**
             * Removes all filters from the tool
             */
            removeAllFilters() {
                this.filters = {"type":"group","condition":"AND","fields":[]};
            },

            /**
             * Returns an alphabetised array of names of provided models
             * @param models - an object containing models
             */
            sortModels: function(models){
                let keys = Object.keys(models).sort();
                return keys;
            },

            /**
             * Returns an alphabetised array of fields on a given model
             * @param string modelName - the name of a model
             */
            sortColumns: function(modelName){
                var fields = this.models[modelName];
                if (fields) {
                    let keys = Object.keys(fields).sort();
                    fields = keys;
                }
                return  fields;
            },
        },

        /**
         * When Vue component has finished being instantiated
         */
        mounted: function () {
            // Set the relationships' view models from the data models that actually store the data
            this.setViewRelationships();

            // Set the view models used to represent the grouping clause of the page query being built
            this.setViewGrouping();

            // Set the ordering view models from the data models loaded from the database
            this.setViewOrdering();

            // Set the pre default filters from the data models loaded from the database
            this.setViewFilters();

console.log(this.design);
console.log(this.design.page_url_pattern);
        },

        /**
         * Tasks to perform when the data variables change
         */
        watch:{
            usedModels: function(newdata){
                this.getOptionLists(this.design);
                if(this.primaryModelOptions.length > 1){
                   this.design.relationships = this.getRelationships(this.design);
                }
            },

            'design.primaryModel': function(){
                if(this.primaryModelOptions.length > 1){
                   this.design.relationships = this.getRelationships(this.design);
                }
                this.getHeaderColumnOptions(this.design);
            },

            /**
             * On switching the page mode the page sections will not be valid
             * so erase them and add a new basic section for this new page mode
             * to get started with
             */
            'design.pageMode': function(){
                this.design.sections = [];
                this.addNewSection();
            }
        },


        /**
         * Data that is live bound to the view, so changes to the data is is dependent on can trigger changes in the view template
         */
        computed:{

            /**
             * All of the model-fields supported, which the user can choose to relate models on
             */
            modelFieldNames: function(){
                var modelFieldNames = [];

                Object.entries(this.models).forEach(([modelName, model]) => {

                    Object.entries(model).forEach(([fieldName, fieldData]) => {
                        var text  = modelName + '.' + fieldName;
                        var value = modelName + '.' + fieldName;
                        modelFieldNames.push( { 'text': text, 'value': value } );
                    });
                });

                return modelFieldNames;
            },

            /**
             * Sets the 'primary model' for the page, letting the system know what the main focus of the page is, the record which is most important to the context
             * of the other data loaded for the page, and also used to provide helpful names and titles for the user for circumstances where the ID number of the
             * record is not helpful
             */
            'design.primaryModel': function(){
                if(this.primaryModelOptions.length > 1){
                   this.design.relationships = this.getRelationships(this.design);
                }
                this.getHeaderColumnOptions(this.design);
            },

            /**
             * Construct a list of join types to be applied when relating tables.  Presently this is just a mega list and does not do any computation, nor does it allow
             * any conditions on the join itself, however it is being put in the computed properties of the Vue component ready to accept that at a later time
             *
             * Presently only two join types are used as they should cater for the majority of situations during the first stages of implementation, but be expandable later
             */
            joinTypes: function(){
                return [
                    { 'text': 'Inner Join', 'value': 'INNER JOIN' },
                    { 'text': 'Left Join',  'value': 'LEFT JOIN'  },
                    { 'text': 'Right Join', 'value': 'RIGHT JOIN' }
                ];
            },

            /**
             *
             */
            modelFields: function(){
                var modelOptions = [];
                var modelFields  = [];

                this.design.sections.forEach(function(section, sectionKey){
                    section.fields.forEach(function(field,fieldKey){
                        if (field.modelName){
                            if (modelOptions.indexOf(field.modelName) < 0 ){
                                modelOptions.push(field.modelName);
                            }
                        }

                        if (field.fieldName){
                            field.optionText  = '(' + field.modelName + ") - " + field.fieldName;
                            field.optionValue = field.modelName + '.' + field.fieldName;
                        }

                        if (field.isComposite){
                            field.optionText  = "Composite: " + field.displayName;
                            field.optionValue = "Composite: " + field.alias;
                        }

                        modelFields.push(field);
                    });
                });

                return modelFields;
            },

            /**
             * Construct a list of operators to be applied while searching for data.  Presently this is just a mega list and does not take field data type into account
             */
            operators: function(){
                return [
                    // Value comparisons
                    { 'text': 'Is',                     'value': '==' },
                    { 'text': 'Is Not',                 'value': '!=' },

                    // Textual comparisons
                    { 'text': 'Contains',               'value': 'LIKE' },
                    { 'text': 'Does Not Contain',       'value': 'NOT LIKE' },
                    { 'text': 'Starts With',            'value': 'STARTS' },
                    { 'text': 'Does Not Start With',    'value': 'NOT STARTS' },
                    { 'text': 'Ends With',              'value': 'ENDS' },
                    { 'text': 'Does Not End With',      'value': 'NOT ENDS' },
                    { 'text': 'Is Empty Text',          'value': 'EMPTY' },

                    // Existance checks
                    { 'text': 'Is Null',                'value': 'NULL' },
                    { 'text': 'Is Not Null',            'value': '<> NULL' },

                    // Numeric comparison
                    { 'text': 'Is Less Than',           'value': '<' },
                    { 'text': 'Is Less Than/Equal To',     'value': '<=' },
                    { 'text': 'Is More Than',              'value': '>' },
                    { 'text': 'Is More Than/Equal To',     'value': '>=' },

                    // Boolean comparison
                    { 'text': 'Is True',                'value': 'TRUE' },
                    { 'text': 'Is False',               'value': 'FALSE' },

                    // Bitwise comparison
                    { 'text': 'Logical And',            'value': '&' },
                    { 'text': 'Logical And Invert',     'value': '&~' },
                    { 'text': 'Logical Or',             'value': '|' },
                    { 'text': 'Logical Or Invert',      'value': '|~' },

                ];
            },
        },
    }
</script>

<style>
    .floating-save {
        z-index: 100;
    }

    .spanHint {
        color: red;
    }

    .text-input-border {
        border: 1px solid #c0c0c0;
        border-radius: 2px;
        background-color: #FFFFFF;
    }

    .text-input-border-dark {
        border: 1px solid rgb(148, 148, 148);
        border-radius: 2px;
        background-color: #FFFFFF;
    }

</style>
