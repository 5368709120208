var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-btn",
                {
                  staticClass: "ies-green",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      return _vm.saveDesign()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("save")]), _vm._v(" Save")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ies-green floating-save",
                  attrs: { large: "", fixed: "", bottom: "", right: "" },
                  on: {
                    click: function ($event) {
                      return _vm.saveDesign()
                    },
                  },
                },
                [_vm._v("SAVE")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ies-coral",
                  attrs: { disabled: "" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteRecord()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("delete")]), _vm._v(" Delete")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { onClick: "window.print()" } },
                [_c("v-icon", [_vm._v("print")]), _vm._v(" Print")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { disabled: "" },
                  on: {
                    click: function ($event) {
                      return _vm.emailLink()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("email")]), _vm._v(" Email")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { disabled: "" },
                  on: {
                    click: function ($event) {
                      return _vm.listView()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("view_list")]), _vm._v(" List Version")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { disabled: "" },
                  on: {
                    click: function ($event) {
                      return _vm.editView()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("view_list")]), _vm._v(" Edit Version")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { disabled: "" },
                  on: {
                    click: function ($event) {
                      return _vm.reportProblem()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("report_problem")]), _vm._v(" Problem")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }