/**
 * Mixin that holds reusable methods for getting data about requested AdminUser
 * for serurity reasons for now we can only get AdminUser : 'first_name', 'surname', 'username', 'email'
 *
 * How to import mixin:
 * 1. import this mixin to your component:  eg. import {adminUser} from '@/mixins/adminUser';
 * 2. register mixin in your component:         mixins: [adminUser],
 *
 * now inside of your component you got access to method:
 *    getAdminUser(user_key) -> return {first_name => xxx, surname => xxx, username => xxx, email => xxx }
 *
 */
export const adminUser = {
    data () {
        return {
            /** data object that is being used only by this mixin is encapsulated within adminUserData object */
            adminUserData: {
                /** @var user - object containg requested AdminUser object */
                user: {},
                /** indicated whether we already loaded user object, so we don't have to load it again */
                userLoaded: false,
            },
        }
    },
    /**
     * Code that runs when component was created
     */
    created(){
        //alert('mixin adminUser has been hooked!'); // You can uncommend that to check if the mixin was hooked successfully
    },
    methods:{

        /**
         * Returns requested
         * @param userKey
         * @returns {*}
         */
        getAdminUser(userKey) {
            if (!this.adminUserData.userLoaded) {
                let user = this.loadAdminUserFromAjax(userKey);
                return user;
            }
            else {
                return this.adminUserData.user;
            }
        },

        /**
         * Loads AdminUser object from server using AJAX call
         * @param userKey
         */
        loadAdminUserFromAjax(userKey) {
            if (userKey) {
                // build data object that we have to send with AJAX call
                let dataObj = {user_key: userKey};
                let url = 'ajax/adminuser/get';

                // AJAX to i-escape server to get this AdminUser object
                axios.post(url, dataObj)
                    .then(function(response) {
                        if(response.data.success && response.data.hasOwnProperty('AdminUser')) {
                            this.adminUserData.user = response.data.AdminUser;
                            this.adminUserData.userLoaded = true;

                            return this.adminUserData.user;
                        }
                        else {
                            console.log('error on: loadAdminUserFromAjax');
                        }
                    }.bind(this));

            }
        }
    }
}
