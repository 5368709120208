<template>
    <v-container fluid>
<!--Filter by section-->
        <v-layout row wrap>
            <v-col cols="12" md="3" xl4>
                <h1>Vouchers Summary</h1>
            </v-col>
            <v-col cols="12" sm="3" md="1">
                Filter by:
                <v-radio-group v-model="filterBy" :mandatory="false">
                    <v-radio label="Data range" value="range"></v-radio>
                    <v-radio label="Year" value="year"></v-radio>
                </v-radio-group>
            </v-col>

            <v-col cols="12" v-if="filterBy === 'range'" mt-4>
                <v-layout row wrap>
                    <v-col cols="12" md="3">
                        <div
                                ref="menu"
                                v-model="range.from.open"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                offset-y
                                full-width
                                max-width="290px"
                                min-width="290px"
                        >
                            <v-text-field
                                    v-model="range.from.date"
                                    label="From date"
                                    prepend-icon="event"
                                    readonly
                                    
                            ></v-text-field>
                            <v-date-picker
                                    v-model="range.from.date"
                                    type="month"
                                    no-title
                                    @input="range.from.open = false"
                                    min="2017-11-01"
                                    :max="currentYear"
                            >
                            </v-date-picker>
                        </div>
                    </v-col>
                    <v-col cols="12" md="3">
                        <div
                                ref="menu"
                                v-model="range.to.open"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                lazy
                                transition="scale-transition"
                                offset-y
                                full-width
                                max-width="290px"
                                min-width="290px"
                        >
                            <v-text-field
                                    v-model="range.to.date"
                                    label="To date"
                                    prepend-icon="event"
                                    readonly
                                    
                            ></v-text-field>
                            <v-date-picker
                                    v-model="range.to.date"
                                    type="month"
                                    no-title
                                    @input="range.to.open = false"
                                    min="2017-11-01"
                                    :max="currentYear"
                            >
                            </v-date-picker>
                        </div>
                    </v-col>
                </v-layout>
            </v-col>
            <v-col cols="2" v-if="filterBy === 'year'" mt-4>
                <v-select
                        :items="years"
                        v-model="year"
                        label="Pick year"
                ></v-select>
            </v-col>
            <v-col cols="1" mt-4>
                <v-btn color="lime" @click="filter()">Filter</v-btn>
            </v-col>
            <v-col cols="12">
                <div id="linearWrapper">
                    <v-progress-linear :indeterminate="true" v-show="showProgressIcon" color="lime"></v-progress-linear>
                </div>
            </v-col>
        </v-layout>
<!--Results-->
        <v-layout row wrap justify-center v-if="showStats" mt-2>
            <v-col cols="12" class="tableWrapper">
                <table class="summaryTable">
                    <tr v-for="(row, rowIndex) in tableStats" :key="rowIndex">
                        <td v-for="(column, columIndex) in row" :key="columIndex">
                            <span v-if="isNaN(column.value)" :class="{'fieldTitle': column.type==='title'}">{{ column.value }}</span>
                            <span v-else class="fieldValue">£{{ column.value.toFixed(2) }}</span>
                            <span v-if="column.count > 0" class="fieldCount">{{ column.count}}</span>

                        </td>
                    </tr>
                </table>
            </v-col>
        </v-layout>
<!-- Charts -->
        <v-layout row wrap mt-5 v-show="showPanel">
         <v-col cols="12">
                    Monthly Chart (by voucher count)
                        <v-card>
                            <v-card-text>
                                <div class="chartLinearWrapper">
                                    <line-chart :chartdata="monthlyChartByCount.data" :options="monthlyChartByCount.options" :height="600"></line-chart>
                                </div>
                            </v-card-text>
                        </v-card>
         </v-col>

         <v-col cols="12">
                    Monthly Chart (by voucher value)
                    <v-card>
                        <v-card-text>
                            <div class="chartLinearWrapper">
                                <line-chart :chartdata="monthlyChartByValue.data" :options="monthlyChartByValue.options" :height="600"></line-chart>
                            </div>
                        </v-card-text>
                    </v-card>
         </v-col>

         <v-col cols="12">
                    Average vouchers value
                    <v-card>
                        <v-card-text>
                            <div class="chartLinearWrapper">
                                <line-chart :chartdata="averageMonthlyValueChart.data" :options="averageMonthlyValueChart.options" :height="600"></line-chart>
                            </div>
                        </v-card-text>
                    </v-card>
         </v-col>

         <v-col cols="12">
                    Summary Chart
                    <v-card>
                        <v-card-text>
                            <v-layout row wrap>
                                <v-flex class="chartPieWrapper">
                                    <pie-chart :chartdata="sumChartByCount.data" :options="sumChartByCount.options" :height="250"></pie-chart>
                                </v-flex>
                                <v-flex class="chartPieWrapper">
                                    <pie-chart :chartdata="sumChartByValue.data" :options="sumChartByValue.options" :height="250"></pie-chart>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                    </v-card>
            </v-col>
        </v-layout>

        <!-- Success and error snack bars -->
        <v-snackbar
                :timeout="parseInt(2000)"
                v-model="snackbar.successSnackbar"
                multi-line
                color="ies-green"
        >
            <div class="full-height full-width v-align-container text-xs-center">
                <h4 class="ies-dark-gray--text v-align-div">{{ snackbar.message }}</h4>
            </div>
        </v-snackbar>
        <v-snackbar
                :timeout="parseInt(3000)"
                v-model="snackbar.errorSnackbar"
                multi-line
                color="ies-coral"
        >
            <div class="full-height full-width v-align-container text-xs-center">
                <h4 class="ies-dark-gray--text v-align-div">{{ snackbar.message }}</h4>
            </div>
        </v-snackbar>
    </v-container>
</template>


<script>
    import { format, subDays, subMonths, subYears } from 'date-fns';
    import axios from 'axios';
    import {progressBar} from '@/mixins/progressBar';
    import {urlParams} from '@/mixins/urlParams';
    import LineChart from '@/components/charts/LineChart';
    import PieChart from '@/components/charts/PieChart';

    export default {
        mixins: [progressBar, urlParams],
        data() {
            return {
                // stats arrays
                tableStats: [],
                monthlyStats: [],
                summaryStats: {},
                // other props
                showStats: true,
                filterBy: 'range',
                range: {
                    from: {
                        open: false,
                        date: new Date().toISOString().substr(0, 7),
                    },
                    to: {
                        open: false,
                        date: new Date().toISOString().substr(0, 7),
                    },
                },
                year: '',
                snackbar: {
                    successSnackbar: false,
                    errorSnackbar: false,
                    message: '',
                },
                showPanel: false,

                // temp chart
                monthlyChartByValue: {
                    data: {},
                    options: {
                        maintainAspectRatio: false,
                        responsive: true,
                        title: {
                            display: true,
                            text: 'Chart showing vouchers activity by voucher value in GBP'
                        },
                    }
                },
                monthlyChartByCount: {
                    data: {},
                    options: {
                        maintainAspectRatio: false,
                        responsive: true,
                        title: {
                            display: true,
                            text: 'Chart showing vouchers activity by voucher count'
                        },
                    }
                },
                averageMonthlyValueChart: {
                    data: {},
                    options: {
                        maintainAspectRatio: false,
                        responsive: true,
                        title: {
                            display: true,
                            text: 'Average value in GBP of new vouchers created by each month'
                        },
                    }
                },
                sumChartByValue: {
                    data: {},
                    options: {
                        title: {
                            display: true,
                            text: 'Summary chart showing vouchers by value in GBP',
                        },
                    },
                },
                sumChartByCount: {
                    data: {},
                    options: {
                        title: {
                            display: true,
                            text: 'Summary chart showing vouchers by count',
                        }
                    },
                },
                voucherColors: {
                    redeemed: '#C62828',
                    expired: '#8e5ea2',
                    cancelled: '#ffb95e',
                    reissue: '#FFFF00',
                    balanceLocal: '#8BC34A',
                    created: '#3e95cd'
                },
            }
        },
        components: {
            LineChart, PieChart
        },
        methods: {
            /**
             * Handle click of the Filter button, build object that will be send to API, validates data and call api
             * to get data from backend
             */
            filter() {
                let filters = {
                    filterBy: this.filterBy
                };
                // check if user want to get summary filtered by data range or year
                if (this.filterBy === 'range') {
                    filters.dateFrom = this.range.from.date;
                    filters.dateTo = this.range.to.date;
                }
                else if (this.filterBy === 'year') {
                    filters.year = this.year;
                }

                if (this.validateFilter(filters)) {
                    this.callApi(filters);
                }
                else {
                    this.showSnackbar('error', 'Invalid filter by data');
                }
            },

            /**
             * Calls i-escape API to get vouchers-summary stats, display stats on page
             * @param {} filters
             */
            callApi(filters) {
                this.progressIconShow();

                axios.post('api/vouchers/summary', filters)
                    .then(function(response) {

                        // check if API returned success response
                        if (response.data.success) {
                            this.showPanel= true;
                            this.updateUrlParameters(filters);

                            // update stats object with data from API
                            this.tableStats = response.data.summary.table;
                            this.monthlyStats = response.data.summary.monthlyStats;
                            this.summaryStats = response.data.summary.sumStats;
                            this.updateCharts();
                            this.showSnackbar('success', 'Summary stats updated');
                        }
                        else {
                            this.showSnackbar('error', response.data.error);
                        }
                        this.progressIconHide();
                    }.bind(this))
                    .catch(function(error) { console.log(error);
                        this.showSnackbar('error', 'There was a problem with API call');
                        this.progressIconHide();
                    }.bind(this));
            },

            /**
             * Accepts object that will be send to API and pre-validates it
             * @param {} filters
             * @return boolean
             */
            validateFilter(filters) {
                let valid = false;
                if (filters.filterBy === 'range') {
                    if (filters.hasOwnProperty('dateFrom') && filters.dateFrom && filters.hasOwnProperty('dateTo') && filters.dateTo) {
                        valid = true;
                    }

                }
                else if (filters.filterBy === 'year') {
                    if (filters.hasOwnProperty('year') && filters.year > 0) {
                        if (this.years.includes(filters.year)) {
                            valid = true;
                        }
                    }

                }

                return valid;
            },

            /**
             * Read the GET parameters from URL, if correct parameters are provided on load, validate them,
             * update models and call API for requested data.
             */
            getUrlParameters() {
                // get url params using urlParam mixin
                let yearParam = this.getUrlParam("year");
                let fromParam = this.getUrlParam("from");
                let toParam   = this.getUrlParam("to");

                if (yearParam) {
                    let testObject = {filterBy : 'year', year: yearParam*1};
                    if (this.validateFilter(testObject)) {
                        this.filterBy = 'year';
                        this.year = yearParam*1;

                        this.filter();
                    }
                }
                else if (fromParam, toParam) {
                    let testObject = {filterBy : 'range', dateFrom: fromParam, dateTo: toParam};
                    if (this.validateFilter(testObject)) {
                        this.filterBy = 'range';
                        this.range.from.date = fromParam;
                        this.range.to.date = toParam;

                        this.filter();
                    }
                }
            },

            /**
             * Add new query parameters to URL after new summary data has been filtered, so URL parameters
             * always reflect what is displaying on the page
             */
            updateUrlParameters(filters) {
                if (filters.filterBy === 'range') {
                    this.setUrlParams({from: filters.dateFrom, to: filters.dateTo},'Vouchers Summary');
                }
                else if (filters.filterBy === 'year') {
                    this.setUrlParams({year: filters.year}, 'Vouchers Summary');
                }
            },

            /**
             * After each successful data comes back from API, update charts
             */
            updateCharts() {
                this.updateMonthlyChart('count');
                this.updateMonthlyChart('value');
                this.updateAverageMonthlyValueChart();
                this.updateSumChart('count');
                this.updateSumChart('value');
            },

            capitalizeFirstLetter(str) {
                return str[0].toUpperCase() + str.slice(1);
            },

            /**
             * Update monthly line chart
             * Accpets compareBy string and then loop through each month and add this month count/value to chart dataset
             * @param string compareBy : 'count'|'value'
             */
            updateMonthlyChart(compareBy) {
                let valueField = false;

                if (compareBy === 'count') {
                    valueField = 'totalCount';
                }
                else if (compareBy === 'value') {
                    valueField = 'totalValueNumber';
                }

                // monthly chart
                if (this.monthlyStats.length && valueField) {
                    const data = {
                        labels: [],
                        datasets: [],
                    };

                    // stats that we want to display on line chart
                    const voucherStats = [
                        { prop:'created', display: 'New Vouchers'},
                        { prop:'redeemed', display: 'Redeemed'},
                        { prop: 'expired', display: 'Expired'},
                        { prop: 'cancelled', display: 'Cancelled'},
                        { prop: 'reissue', display: 'Re-issue'}
                    ];
                    // array where we'll add stats for each line/voucher stat
                    const chartLines = [];

                    // create empty dataset for each voucher stat that we want to display on
                    voucherStats.forEach((stat) => {
                        let statObject = {
                            label : stat.display + ' ' + compareBy,
                            data: [],
                            borderColor: this.voucherColors[stat.prop],
                            fill: false
                        };
                        chartLines.push(statObject);
                    });

                    // loop through each month and add data to created charLines
                    this.monthlyStats.forEach((month) => {
                        // build lable/column for each month
                        let monthLabel = month.month.month + ' ' + month.month.year;
                        data.labels.push(monthLabel);

                        // loop through each stat and add this stat this month data
                        voucherStats.forEach((stat, i) => {
                            chartLines[i].data.push(month[stat.prop][valueField]);
                        });

                    });
                    
                    data.datasets = chartLines;

                    // update existing monthly data object which will trigger chart to update it's state
                    if (compareBy === 'count') {
                        this.monthlyChartByCount.data = data;
                    }
                    else if (compareBy === 'value') {
                        this.monthlyChartByValue.data = data;
                    }
                }
            },

            /**
             * Update Average value of created vouchers chart
             */
            updateAverageMonthlyValueChart() {
                if (this.monthlyStats.length) {
                    // blank data object
                    const data = {
                        labels: [],
                        datasets: [],
                    };

                    // line of chart that will be showing average voucher value
                    let statObject = {
                        label : 'Average voucher value in GBP',
                        data: [],
                        borderColor: this.voucherColors.created,
                        fill: false
                    };

                    // loop through each month and add chart data
                    this.monthlyStats.forEach((month) => {
                        // build lable/column for each month
                        let monthLabel = month.month.month + ' ' + month.month.year;
                        data.labels.push(monthLabel);

                        let averageValue = month.created.averageValue;
                        statObject.data.push(averageValue);
                    });

                    // update global chart data object
                    data.datasets.push(statObject);
                    this.averageMonthlyValueChart.data = data;
                }
            },

            /**
             * Updates data that will be shown in sum chart
             */
            updateSumChart(compareBy) {

                if (this.summaryStats !== {} && compareBy) {
                    const data = {
                        labels: ['Redeemed', 'Expired', 'Cancelled', 'Re-issue created', 'Active'],
                        datasets: [{
                            data: [
                                this.summaryStats.redeemed[compareBy],
                                this.summaryStats.expired[compareBy],
                                this.summaryStats.cancelled[compareBy],
                                this.summaryStats.reissue[compareBy],
                                this.summaryStats.balanceLocal[compareBy],
                            ], // old cancell 3cba9f
                            backgroundColor: [
                                this.voucherColors.redeemed,
                                this.voucherColors.expired,
                                this.voucherColors.cancelled,
                                this.voucherColors.reissue,
                                this.voucherColors.balanceLocal
                            ],
                        }],
                    };

                    // update existing summary data object which will trigger chart to update it's state
                    if (compareBy === 'count') {
                        this.sumChartByCount.data = data;
                    }
                    else if (compareBy === 'value') {
                        this.sumChartByValue.data = data;
                    }
                }
            },

            /**
             * Show success or error snack bar
             * usage: this.showSnackbar('success'); | this.showSnackbar('error');
             * @param snack string - success|error
             * @param message = false
             */
            showSnackbar(snack, message = false) {
                var _this = this;
                this.progressIconHide();

                if (message) {
                    _this.snackbar.message = message;
                }

                switch (snack) {
                    case 'success' :
                        if (!message) _this.snackbar.message = 'Success';
                        _this.snackbar.successSnackbar = true;
                        break;
                    case 'error' :
                        if (!message) _this.snackbar.message = 'Error';
                        _this.snackbar.errorSnackbar = true;
                        break;
                }
            },

        },
        /**
         * Methods that initialized this page on page load
         */
        mounted() {
            // get year or date range from URL if set
            this.getUrlParameters();
        },
        computed: {
            /**
             * Get years that can be choose from, from the beginning of the vouchers - 2017 to current year
             * @returns {Array}
             */
            years: function() {
                let years = [];
                let firstYear = 2017;
                let currentYear = new Date().getFullYear();

                years.push(firstYear);

                while (firstYear < currentYear) {
                    firstYear++;
                    years.push(firstYear);
                }

                years.push(currentYear);

                return years;
            },
            currentYear: function() {
                return format(new Date(), 'yyyy-mm-dd');
            }
        },
        watch: {
        },
    };
</script>

<style scoped>

    table.summaryTable, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }
    table.summaryTable td {
        min-width: 60px;
        height: 50px;
    }

    .tableWrapper {
        overflow-x:auto;
        width: 800px;
    }

    .summaryTable .fieldCount {
        color: gray;
        display: block;
        text-align: center;
    }

    .summaryTable .fieldTitle {
        display: inline-block;
        margin: 0 3px 0 5px;
        font-weight: bold;
        min-width: 100px;
    }

    .summaryTable tr:first-child td span {
        font-weight: bold;
        margin: 0 3px;
    }

    .summaryTable tr:first-child, td:first-child  {
        background-color: lightgray;
    }

    .summaryTable .fieldValue {
        text-align: center;
        display: block;
        padding: 0 2px;
    }

    .summaryTable td:last-child span {
        font-weight: bold;
        text-align: center;
        display: block;
    }

    .summaryTable td:last-child span.fieldCount {
        font-weight: normal;
    }

    .summaryTable tr:hover td {
        background: #e8e5e5;
    }

    .summaryTable tr:hover td:first-child {
        /*background: lightgray;*/
    }

    .chartLinearWrapper {
        max-height: 1200px;
    }

    .chartPieWrapper {
        width: 40%;
    }

</style>
