var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { sm: "6", md: "4", lg: "2", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("dns")]),
              _vm._v("Server Status\n        "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "body-2" },
            [
              _vm._v("Server load:\n            "),
              _c("v-progress-circular", {
                staticClass: "right-float",
                attrs: {
                  rotate: -90,
                  value: _vm.serverLoadPercent,
                  color: _vm.getServerLoadColour(),
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "body-2" },
            [
              _vm._v("Memory usage:\n            "),
              _c("v-progress-circular", {
                staticClass: "right-float",
                attrs: {
                  rotate: -90,
                  value: _vm.memoryUsagePercent,
                  color: _vm.getMemoryUsageColour(),
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "body-2" },
            [
              _vm._v("Disk space used:\n            "),
              _c("v-progress-circular", {
                staticClass: "right-float",
                attrs: {
                  rotate: -90,
                  value: _vm.diskSpacePercent,
                  color: _vm.getDiskSpaceColour(),
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-xs-center" },
            [
              _c(
                "v-btn",
                { staticClass: "ies-green btn", on: { click: _vm.getData } },
                [_vm._v("Refresh")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }