<template>
    <v-col cols="12" md="2" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">list</v-icon> To Do
            </v-card-title>

            <v-card-text>
                <v-btn class="mt-1" block :href="allUrl"><v-icon>list</v-icon> All</v-btn>
                <v-btn class="mt-1" block :href="activeUrl"><v-icon>playlist_add</v-icon> Active</v-btn>
                <v-btn class="mt-1" block :href="redeemedUrl"><v-icon>playlist_add_check</v-icon> Redeemed</v-btn>
                <v-btn class="mt-1" block :href="cancelledUrl"><v-icon>cancel_presentation</v-icon> Cancelled</v-btn>
                <v-btn class="mt-1" block :href="refundedUrl"><v-icon>keyboard_return</v-icon> Refunded</v-btn>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<script>
    /**
     * Display buttons linking through to a hotels' pages
     */
    export default {
        props:['pageid'],

        data: function() {
            return {
                allUrl: '/Vouchers/list',
                activeUrl: '/vouchers-active/list',
                redeemedUrl: '/vouchers-redeemed/list',
                cancelledUrl: '/cancelled-vouchers/list',
                refundedUrl: '/refunded-vouchers/list',
            }
        },

        methods: {},

        computed: {}
    }
</script>
