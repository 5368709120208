<template>
    <div>
        <div><strong>Filters</strong></div>
        <v-card class="mb-4 d-flex">
            <!-- room types dropdown -->
            <v-select
                class="pr-2 pl-2 min-width-50-pct"
                :items="roomTypeOptions"
                v-model="selectedRoomTypeOptions"
                label="Room Types"
                item-text="name"
                item-value="hotelRoomKey"
                multiple
                chips
                small-chips
                deletable-chips
                >
            </v-select>
                    
            <!-- rate types -->
            <v-select
                class="pr-2 pl-2 min-width-50-pct"
                :items="rateTypeOptions"
                v-model="selectedRateTypeOptions"
                label="Rate Types"
                item-text="name"
                item-value="rateTypeKey"
                multiple
                chips
                small-chips
                deletable-chips
                >
            </v-select>
        </v-card>
                        
        <div><strong>View Options</strong></div>
        <v-card class="mb-4 d-flex">
            <!-- rate view options -->
            <v-select
                class="pr-2 pl-2 min-width-50-pct"
                :items="rateViewOptions"
                v-model="selectedRateViewOptions"
                label="Rate Options"
                item-text="text"
                item-value="value"
                multiple
                chips
                small-chips
                deletable-chips>
            </v-select>
                
            <!-- restriction view options -->
            <v-select
                class="pr-2 pl-2 min-width-50-pct"
                :items="restrictionViewOptions"
                v-model="selectedRestrictionViewOptions"
                label="Restriction Options"
                item-text="text"
                item-value="value"
                multiple
                chips
                small-chips
                deletable-chips>
            </v-select>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'hotel-planner-table-controls',
    props: {
        'tableViewOptions': Object,
        'selectedTableViewOptions': Object,
    },

    data() {

        return {
            localSelectedTableViewOptions: this.selectedTableViewOptions,    
            
            selectedRoomTypeOptions: [],
            selectedRateTypeOptions: [],
            selectedRateViewOptions: [
                { text: 'Show Standard Rate', value: 'showStandardRate' },
            ],
            selectedRestrictionViewOptions: [],
        }
    },

    computed: {
        roomTypeOptions() {
          return this.tableViewOptions.roomTypeOptions || [];
        },
      
        rateTypeOptions() {
          return this.tableViewOptions.rateTypeOptions || [];
        },

        rateViewOptions() {
          return this.tableViewOptions.rateViewOptions || [];
        },

        restrictionViewOptions() {
          return this.tableViewOptions.restrictionViewOptions || [];
        }
    },

    watch: 
    {
        selectedRoomTypeOptions(newSelectedRoomTypeOptions) {
            this.localSelectedTableViewOptions['roomTypeOptions'] = newSelectedRoomTypeOptions;
        },

        selectedRateTypeOptions(newSelectedRateTypeOptions) {
            this.localSelectedTableViewOptions['rateTypeOptions'] = newSelectedRateTypeOptions;
        },

        selectedRestrictionViewOptions(newSelectedRestrictionViewOptions) {
            this.restrictionViewOptions.forEach((option) => {
                this.localSelectedTableViewOptions[option.value] = newSelectedRestrictionViewOptions.includes(option.value);
            });
        },

        selectedRateViewOptions(newSelectedRateViewOptions) {
            this.rateViewOptions.forEach((option) => {
                this.localSelectedTableViewOptions[option.value] = newSelectedRateViewOptions.includes(option.value);
            });
        },
    },
}
</script>

<style>
    .min-width-50-pct {
        min-width: 50%;
    }
</style>

