<template>
    <v-container fluid>
        <v-layout row-sm column>
            <v-flex v-if="this.field.schema.displayName != 'none'" xs12>
                <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-flex>
            <v-flex xs12 sm6>
                <input :name="this.field.fieldName" type="text" v-model="input" v-on:keyup="updateField()" :required="this.field.required" :disabled="this.field.disabled">
            </v-flex>
            <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
                <li v-for="error in field.errors">
                    {{ error }}
                </li>
            </v-alert>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: 'ies-htmlinput',
        props: ['field'],
        data () {
            return {
                input: this.field.value || this.field.oldValue
            }
        },
        methods: {
            updateField: function() {
                this.field.value = this.input
            }
        },
        watch: {
            input: function(val) {
                this.field.value = this.input;
            }
        }
    }
</script>
