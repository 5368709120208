<template>
<v-card>
    <v-card-title>
        <span class="subheader">Global Summary</span>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="continents"
        item-key="region_key"
        hide-default-footer
    >
    
        <template slot="items" slot-scope="props">
            <tr @click="props.expanded = !props.expanded">
                <td class="text-xs-left">{{ props.item.region_name }}</td>
                <td class="text-xs-center">{{ props.item.invoices | pounds }}</td>
                <td class="text-xs-center">{{ props.item.payments | pounds }}</td>
                <td class="text-xs-center">{{ props.item.balance | pounds }}</td>
            </tr>
        </template>
      
        <template slot="expand" slot-scope="props">
            <h1>{{ props.item.region_key }}</h1>
        </template>

    </v-data-table> 
</v-card>
</template>

<script>
export default {
    props: ['region_key'],
    data() {
        return {
           continents: [],
           headers: [],
        }
    },
    created() {
        this.headers = this.getHeaders();
        this.getData();
    },
    methods: {
        async getData() {
            const res = await axios({
                method: 'GET',
                url: 'api/accounts/continent-summary',
                params: {
                    region_key: this.region_key
                }
            });
            this.continents = res.data;
        },
        getHeaders() {
            return [
                {
                    text: 'Region Name',
                    value: 'region_name',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Invoices',
                    value: 'invoices',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Payments',
                    value: 'payments',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Balance',
                    value: 'balance',
                    align: 'center',
                    sortable: false,
                }
            ];
        }
    }
}
</script>
