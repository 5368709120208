var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", lg: "4", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("hotel")]),
              _vm._v(" Reservations\n        "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              !_vm.systemError && _vm.loading
                ? _c("div", { attrs: { xs12: "" } }, [_vm._v("Loading...")])
                : _vm._e(),
              _vm._v(" "),
              _vm.systemError
                ? _c("div", { attrs: { xs12: "" } }, [
                    _vm._v("Sorry, this content is not working"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.systemError && !_vm.loading
                ? _c(
                    "div",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c("h3", [_vm._v("Upcoming Reservations")]),
                          _vm._v(" "),
                          !_vm.reservations.length
                            ? _c("div", [
                                _vm._v(
                                  "This hotel has no upcoming reservations"
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-list",
                            { attrs: { dense: "" } },
                            [
                              _vm._l(
                                _vm.reservations,
                                function (reservation, index) {
                                  return [
                                    _c(
                                      "v-list-item",
                                      {
                                        key: index,
                                        staticClass: "news-list-item",
                                        attrs: {
                                          ripple: "",
                                          href: reservation.url,
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { left: "", medium: "" },
                                              },
                                              [_vm._v("event")]
                                            ),
                                            _vm._v(" "),
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  reservation.date_arrival_string
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  reservation.stay_length
                                                ) + " nights"
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            !_vm.hotelKey
                                              ? _c("span", [
                                                  _vm._v(
                                                    "at (" +
                                                      _vm._s(
                                                        reservation.hotel
                                                          .hotel_code
                                                      ) +
                                                      ") " +
                                                      _vm._s(
                                                        reservation.hotel
                                                          .hotel_name
                                                      )
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(reservation.reference)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  reservation.user.first_name
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    reservation.user.last_name
                                                  )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    index + 1 < _vm.reservations.length
                                      ? _c("v-divider")
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.enquiries.length
                ? _c(
                    "div",
                    { attrs: { xs12: "" } },
                    [
                      _c("h3", [_vm._v("Enquiries")]),
                      _vm._v(" "),
                      _c(
                        "v-list",
                        { attrs: { dense: "" } },
                        [
                          _vm._l(_vm.enquiries, function (enquiry, index) {
                            return [
                              _c(
                                "v-list-item",
                                {
                                  key: index,
                                  staticClass: "news-list-item",
                                  attrs: { ripple: "", href: enquiry.url },
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { left: "", medium: "" } },
                                        [_vm._v("live_help")]
                                      ),
                                      _vm._v(" "),
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(enquiry.date_arrival_string)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(enquiry.stay_length) +
                                            " nights"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      !_vm.hotelKey
                                        ? _c("span", [
                                            _vm._v(
                                              "at (" +
                                                _vm._s(
                                                  enquiry.hotel.hotel_code
                                                ) +
                                                ") " +
                                                _vm._s(enquiry.hotel.hotel_name)
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(_vm._s(enquiry.reference)),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(enquiry.user.first_name) +
                                            " " +
                                            _vm._s(enquiry.user.last_name)
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              index + 1 < _vm.enquiries.length
                                ? _c("v-divider")
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-btn", { attrs: { block: "", href: _vm.resAdminUrl } }, [
                _vm._v("Reservations Management"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }