var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12" } },
    [
      _c(
        "v-card",
        { staticClass: "ma-2" },
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("home")]),
              _vm._v(" Create New Hotel\n    "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-container", [
                _c(
                  "fieldset",
                  [
                    _c("legend", [_vm._v("Create New Hotel")]),
                    _vm._v(" "),
                    _c(
                      "v-layout",
                      { attrs: { row: "", wrap: "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Hotel Name:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Hotel Name" },
                              model: {
                                value: _vm.hotelName,
                                callback: function ($$v) {
                                  _vm.hotelName = $$v
                                },
                                expression: "hotelName",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Your Name:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-col", { attrs: { cols: "8" } }, [
                          _vm._v(
                            "Skipped for now, as we are unsure how to determine which field(s) it would go into"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Editorial Region:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-select", {
                              attrs: {
                                label: "Region",
                                items: _vm.regions,
                                autocomplete: "",
                              },
                              model: {
                                value: _vm.regionKey,
                                callback: function ($$v) {
                                  _vm.regionKey = $$v
                                },
                                expression: "regionKey",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Address 1:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Address 1" },
                              model: {
                                value: _vm.address1,
                                callback: function ($$v) {
                                  _vm.address1 = $$v
                                },
                                expression: "address1",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Address 2:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Address 2" },
                              model: {
                                value: _vm.address2,
                                callback: function ($$v) {
                                  _vm.address2 = $$v
                                },
                                expression: "address2",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("City:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "City" },
                              model: {
                                value: _vm.city,
                                callback: function ($$v) {
                                  _vm.city = $$v
                                },
                                expression: "city",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Post Code:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Post Code" },
                              model: {
                                value: _vm.postCode,
                                callback: function ($$v) {
                                  _vm.postCode = $$v
                                },
                                expression: "postCode",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Postal Address:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Postal Address" },
                              model: {
                                value: _vm.postalAddress,
                                callback: function ($$v) {
                                  _vm.postalAddress = $$v
                                },
                                expression: "postalAddress",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Website:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Website" },
                              model: {
                                value: _vm.website,
                                callback: function ($$v) {
                                  _vm.website = $$v
                                },
                                expression: "website",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Reservations contact:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Reservations Contact Name" },
                              model: {
                                value: _vm.reservationsName,
                                callback: function ($$v) {
                                  _vm.reservationsName = $$v
                                },
                                expression: "reservationsName",
                              },
                            }),
                            _vm._v(" "),
                            _c("v-text-field", {
                              attrs: { label: "Reservations Email" },
                              model: {
                                value: _vm.reservationsEmail,
                                callback: function ($$v) {
                                  _vm.reservationsEmail = $$v
                                },
                                expression: "reservationsEmail",
                              },
                            }),
                            _vm._v(" "),
                            _c("v-text-field", {
                              attrs: { label: "Reservations Phone" },
                              model: {
                                value: _vm.reservationsPhone,
                                callback: function ($$v) {
                                  _vm.reservationsPhone = $$v
                                },
                                expression: "reservationsPhone",
                              },
                            }),
                            _vm._v(" "),
                            _c("v-checkbox", {
                              attrs: {
                                "hide-details": "",
                                label: "Send SMS Notifications",
                              },
                              model: {
                                value: _vm.reservationsSMS,
                                callback: function ($$v) {
                                  _vm.reservationsSMS = $$v
                                },
                                expression: "reservationsSMS",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Secondary Email:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-col", { attrs: { cols: "8" } }, [
                          _vm._v(
                            "Will skip this as it is not determinable which field it would go into"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Main Contact Name:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Main Contact Name" },
                              model: {
                                value: _vm.keyContact,
                                callback: function ($$v) {
                                  _vm.keyContact = $$v
                                },
                                expression: "keyContact",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Marketing Contact Name:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Marketing Contact Name" },
                              model: {
                                value: _vm.marketingName,
                                callback: function ($$v) {
                                  _vm.marketingName = $$v
                                },
                                expression: "marketingName",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Marketing Email:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Marketing Email" },
                              model: {
                                value: _vm.marketingEmail,
                                callback: function ($$v) {
                                  _vm.marketingEmail = $$v
                                },
                                expression: "marketingEmail",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Owner:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Owner Name" },
                              model: {
                                value: _vm.owner,
                                callback: function ($$v) {
                                  _vm.owner = $$v
                                },
                                expression: "owner",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-label", [
                              _vm._v("GPS Location (Latitude, Longitude):"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "GPS" },
                              model: {
                                value: _vm.googleMapPosition,
                                callback: function ($$v) {
                                  _vm.googleMapPosition = $$v
                                },
                                expression: "googleMapPosition",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Detailed directions:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-textarea", {
                              model: {
                                value: _vm.detailedDirections,
                                callback: function ($$v) {
                                  _vm.detailedDirections = $$v
                                },
                                expression: "detailedDirections",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-label", [
                              _vm._v("When Did Property First Open?"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-textarea", {
                              model: {
                                value: _vm.dateOpened,
                                callback: function ($$v) {
                                  _vm.dateOpened = $$v
                                },
                                expression: "dateOpened",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Type of Property:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-select", {
                              attrs: {
                                label: "Property Type",
                                items: _vm.propertyTypes,
                                autocomplete: "",
                              },
                              model: {
                                value: _vm.propertyType,
                                callback: function ($$v) {
                                  _vm.propertyType = $$v
                                },
                                expression: "propertyType",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Total No. Rooms:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Total No. Rooms" },
                              model: {
                                value: _vm.totalNumRooms,
                                callback: function ($$v) {
                                  _vm.totalNumRooms = $$v
                                },
                                expression: "totalNumRooms",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Rates:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-col", { attrs: { cols: "8" } }, [
                          _vm._v(
                            "Anything regarding rates must be entered by the rates department"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Channel Manager:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Channel Manager" },
                              model: {
                                value: _vm.channelManager,
                                callback: function ($$v) {
                                  _vm.channelManager = $$v
                                },
                                expression: "channelManager",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Currency:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-select", {
                              attrs: {
                                label: "Currency",
                                items: _vm.currencies,
                                autocomplete: "",
                              },
                              model: {
                                value: _vm.currencyKey,
                                callback: function ($$v) {
                                  _vm.currencyKey = $$v
                                },
                                expression: "currencyKey",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Default Rate Basis")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-select", {
                              attrs: {
                                label: "Default Rate Basis",
                                items: _vm.rateBasisOptions,
                                autocomplete: "",
                              },
                              model: {
                                value: _vm.defaultRateBasis,
                                callback: function ($$v) {
                                  _vm.defaultRateBasis = $$v
                                },
                                expression: "defaultRateBasis",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Optional Extras:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-textarea", {
                              model: {
                                value: _vm.rateOptionalExtras,
                                callback: function ($$v) {
                                  _vm.rateOptionalExtras = $$v
                                },
                                expression: "rateOptionalExtras",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Accepted Credit Cards:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { row: "", wrap: "" } },
                              [
                                _c("v-col", { attrs: { cols: "2" } }, [
                                  _c("label", [
                                    _vm._v("Mastercard "),
                                    _c("img", {
                                      staticClass: "cc-logo",
                                      attrs: {
                                        src: "/images/logos/mastercard.svg",
                                        alt: "MasterCard",
                                      },
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "10" } },
                                  [
                                    _c("v-checkbox", {
                                      attrs: { "hide-details": "" },
                                      model: {
                                        value: _vm.acceptsMastercard,
                                        callback: function ($$v) {
                                          _vm.acceptsMastercard = $$v
                                        },
                                        expression: "acceptsMastercard",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-col", { attrs: { cols: "2" } }, [
                                  _c("label", [
                                    _vm._v("Visa "),
                                    _c("img", {
                                      staticClass: "cc-logo",
                                      attrs: {
                                        src: "/images/logos/visa.png",
                                        alt: "Visa",
                                      },
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "10" } },
                                  [
                                    _c("v-checkbox", {
                                      attrs: { "hide-details": "" },
                                      model: {
                                        value: _vm.acceptsVisa,
                                        callback: function ($$v) {
                                          _vm.acceptsVisa = $$v
                                        },
                                        expression: "acceptsVisa",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-col", { attrs: { cols: "2" } }, [
                                  _c("label", [
                                    _vm._v("Amex "),
                                    _c("img", {
                                      staticClass: "cc-logo",
                                      attrs: {
                                        src: "/images/logos/american-express.svg",
                                        alt: "American Express",
                                      },
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "10" } },
                                  [
                                    _c("v-checkbox", {
                                      attrs: { "hide-details": "" },
                                      model: {
                                        value: _vm.acceptsAmex,
                                        callback: function ($$v) {
                                          _vm.acceptsAmex = $$v
                                        },
                                        expression: "acceptsAmex",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-col", { attrs: { cols: "2" } }, [
                                  _c("label", [
                                    _vm._v("Diners "),
                                    _c("img", {
                                      staticClass: "cc-logo",
                                      staticStyle: {
                                        "max-height": "2rem",
                                        "margin-bottom": "-10px",
                                      },
                                      attrs: {
                                        src: "/images/logos/diners.svg",
                                        alt: "Diners Club",
                                      },
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "10" } },
                                  [
                                    _c("v-checkbox", {
                                      attrs: { "hide-details": "" },
                                      model: {
                                        value: _vm.acceptsDiners,
                                        callback: function ($$v) {
                                          _vm.acceptsDiners = $$v
                                        },
                                        expression: "acceptsDiners",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-col", { attrs: { cols: "2" } }, [
                                  _c("label", [
                                    _vm._v("Maestro "),
                                    _c("img", {
                                      staticClass: "cc-logo",
                                      attrs: {
                                        src: "/images/logos/maestro.png",
                                        alt: "Maestro",
                                      },
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "10" } },
                                  [
                                    _c("v-checkbox", {
                                      attrs: { "hide-details": "" },
                                      model: {
                                        value: _vm.acceptsMaestro,
                                        callback: function ($$v) {
                                          _vm.acceptsMaestro = $$v
                                        },
                                        expression: "acceptsMaestro",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Nearest Airport (km):")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Name" },
                              model: {
                                value: _vm.airportName,
                                callback: function ($$v) {
                                  _vm.airportName = $$v
                                },
                                expression: "airportName",
                              },
                            }),
                            _vm._v(" "),
                            _c("v-text-field", {
                              attrs: { label: "Distance" },
                              model: {
                                value: _vm.airportDistance,
                                callback: function ($$v) {
                                  _vm.airportDistance = $$v
                                },
                                expression: "airportDistance",
                              },
                            }),
                            _vm._v(" "),
                            _c("v-text-field", {
                              attrs: { label: "Code" },
                              model: {
                                value: _vm.airportCode,
                                callback: function ($$v) {
                                  _vm.airportCode = $$v
                                },
                                expression: "airportCode",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Display Status:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-select", {
                              attrs: {
                                label: "Display Status",
                                items: _vm.displayStatuses,
                                autocomplete: "",
                              },
                              model: {
                                value: _vm.displayStatus,
                                callback: function ($$v) {
                                  _vm.displayStatus = $$v
                                },
                                expression: "displayStatus",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Travel Operator:")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-select", {
                              attrs: {
                                label: "Travel Operator",
                                items: _vm.travelOperators,
                                autocomplete: "",
                              },
                              model: {
                                value: _vm.travelOperator,
                                callback: function ($$v) {
                                  _vm.travelOperator = $$v
                                },
                                expression: "travelOperator",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Commission (%):")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Commission (%)" },
                              model: {
                                value: _vm.commission,
                                callback: function ($$v) {
                                  _vm.commission = $$v
                                },
                                expression: "commission",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [_c("v-label", [_vm._v("Nearest Restaurant (km):")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Distance" },
                              model: {
                                value: _vm.restaurantDistance,
                                callback: function ($$v) {
                                  _vm.restaurantDistance = $$v
                                },
                                expression: "restaurantDistance",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "lime",
                                on: {
                                  click: function ($event) {
                                    return _vm.save()
                                  },
                                },
                              },
                              [_vm._v("Create")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }