var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-footer",
    { staticClass: "pa-6 no-print mt-3", attrs: { height: "auto" } },
    [
      _c(
        "v-layout",
        { attrs: { "justify-center": "", row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { "lighten-2": "", "text-xs-center": "", xs12: "" } },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _vm._v("i-escape.com (Ram Tinto Associates Ltd)"),
              ]),
              _vm._v(" "),
              _c("v-flex", [
                _vm._v("23 Westfield Park, Bristol, BS6 6LT, United Kingdom"),
              ]),
              _vm._v(" "),
              _c(
                "v-flex",
                [
                  _c("v-icon", [_vm._v("alternate_email")]),
                  _vm._v(" "),
                  _c("a", { attrs: { href: "mailto:info@i-escape.com" } }, [
                    _vm._v("info@i-escape.com"),
                  ]),
                  _vm._v(" |\n               "),
                  _c("v-icon", [_vm._v("phone")]),
                  _vm._v(" "),
                  _c("a", { attrs: { href: "tel:00441179467072" } }, [
                    _vm._v("+44 117 946 7072"),
                  ]),
                  _vm._v(" (general enquiries)\n            "),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-flex", [
                _vm._v("9am-6pm Monday-Thursday, 9am-5pm Friday (UK times)"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }