var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-container",
        { staticClass: "mobile-no-padding", attrs: { fluid: "" } },
        [
          _c(
            "v-flex",
            { staticClass: "mb-3", attrs: { xs12: "" } },
            [
              _c(
                "v-expansion-panel",
                [
                  _c(
                    "v-expansion-panel-content",
                    {
                      staticClass: "quickSearchPanel",
                      attrs: { value: _vm.showSearchPanel },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "header" }, slot: "header" },
                        [_c("v-icon", [_vm._v("search")]), _vm._v(" Filter")],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-daterange", {
                        attrs: {
                          options: _vm.dateRangeOptions,
                          labels: _vm.dateRangeLabels,
                        },
                        on: { input: _vm.setDateRange },
                      }),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ies-green",
                          on: {
                            click: function ($event) {
                              return _vm.loadData()
                            },
                          },
                        },
                        [_vm._v("Search")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _vm.loading
                    ? _c(
                        "v-flex",
                        { staticClass: "text-xs-center", attrs: { xs12: "" } },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "lime" },
                          }),
                          _vm._v(
                            "\n                        Loading... this may take several seconds\n                    "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.error && !_vm.loading
                    ? _c(
                        "v-flex",
                        { staticClass: "text-xs-center", attrs: { xs12: "" } },
                        [
                          _c(
                            "v-alert",
                            { attrs: { value: true, type: "error" } },
                            [
                              _vm._v(
                                "\n                            This content failed to load\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !Object.keys(_vm.regionStats).length &&
                  !_vm.loading &&
                  _vm.hasSearched
                    ? _c(
                        "v-flex",
                        { staticClass: "text-xs-center", attrs: { xs12: "" } },
                        [
                          _c(
                            "v-alert",
                            { attrs: { value: true, type: "info" } },
                            [
                              _vm._v(
                                "\n                            There are no results to display\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.loading
                    ? _c(
                        "v-flex",
                        [
                          _c("h2", [_vm._v("Summary")]),
                          _vm._v(" "),
                          _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              items: _vm.summaryStats,
                              "hide-actions": "",
                              "disable-initial-sort": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "headers",
                                  fn: function (props) {
                                    return [
                                      _c("tr", [
                                        _c(
                                          "th",
                                          { staticClass: "text-xs-left" },
                                          [_vm._v("Type")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          { staticClass: "text-xs-right" },
                                          [_vm._v("New")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          { staticClass: "text-xs-right" },
                                          [_vm._v("Accepted Quotes")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          { staticClass: "text-xs-right" },
                                          [_vm._v("%")]
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                                {
                                  key: "items",
                                  fn: function (props) {
                                    return [
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v(_vm._s(props.item.type)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-right" },
                                          [_vm._v(_vm._s(props.item.new))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-right" },
                                          [
                                            _vm._v(
                                              _vm._s(props.item.quotes_accepted)
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-right" },
                                          [
                                            _vm._v(
                                              _vm._s(props.item.percentage)
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2539993066
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.loading
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "", id: "table-external" } },
                        _vm._l(_vm.regionStats, function (region, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "mt-3" },
                            [
                              _c("h2", [
                                _vm._v(
                                  _vm._s(
                                    region.country_name &&
                                      region.country_name != region.region_name
                                      ? region.country_name +
                                          " : " +
                                          region.region_name
                                      : region.region_name
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-data-table",
                                {
                                  staticClass: "elevation-1",
                                  attrs: {
                                    headers: _vm.headers,
                                    items: region.hotels,
                                    "hide-actions": "",
                                    "disable-initial-sort": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "items",
                                        fn: function (props) {
                                          return [
                                            _c("tr", [
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(props.item.hotel_code)
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(props.item.hotel_name)
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-xs-right",
                                                },
                                                [_vm._v(_vm._s(props.item.new))]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-xs-right",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.item.quotes_accepted
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-xs-right",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.item.percentage
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("template", { slot: "headers" }, [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        { staticClass: "text-xs-left" },
                                        [_vm._v("Hotel Code")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        { staticClass: "text-xs-left" },
                                        [_vm._v("Hotel Name")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        { staticClass: "text-xs-right" },
                                        [_vm._v("New")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        { staticClass: "text-xs-right" },
                                        [_vm._v("Accepted Quotes")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        { staticClass: "text-xs-right" },
                                        [_vm._v("%")]
                                      ),
                                    ]),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }