var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "row-sm": "", column: "" } },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("label", { attrs: { for: this.field.fieldName } }, [
              _vm._v(" " + _vm._s(this.field.schema.displayName) + ": "),
            ]),
          ]),
          _vm._v(" "),
          this.field.introduction
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _vm._v(_vm._s(this.field.introduction)),
              ])
            : _vm._e(),
          _vm._v(" "),
          this.field.information
            ? _c(
                "v-alert",
                { attrs: { type: "info", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.information))]
              )
            : _vm._e(),
          _vm._v(" "),
          this.field.warning
            ? _c(
                "v-alert",
                { attrs: { type: "warning", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.warning))]
              )
            : _vm._e(),
          _vm._v(" "),
          this.field.error
            ? _c(
                "v-alert",
                { attrs: { type: "error", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.error))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.input,
                  expression: "input",
                },
              ],
              attrs: { name: this.field.fieldName, type: "text" },
              domProps: { value: _vm.input },
              on: {
                keyup: function ($event) {
                  return _vm.updateField()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.input = $event.target.value
                },
              },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }