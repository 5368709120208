var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "mx-5" },
        [
          _c(
            "v-card-title",
            { staticStyle: { "background-color": "gainsboro" } },
            [
              _c("span", { staticClass: "subheader" }, [
                _vm._v(_vm._s(_vm.batchReport.region)),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "v-data-table",
            {
              staticClass: "batch-report-table",
              attrs: {
                headers: _vm.headers(),
                items: _vm.batchReport.data,
                "hide-default-footer": "",
                "disable-pagination": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function (props) {
                    return [
                      _c("tr", [
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.ContactName)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.InvoiceNumber)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            _vm._s(
                              _vm.formattedDateDDMMYYYY(
                                props.item.TransactionDate
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            _vm._s(
                              _vm.formattedDateDDMMYYYY(props.item.IssuedDate)
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            _vm._s(
                              _vm.formattedDateDDMMYYYY(props.item.DueDate)
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.Description)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.Quantity)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v("£" + _vm._s(props.item.NetAmount.toFixed(2))),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            "£" + _vm._s(props.item.GrossAmount.toFixed(2))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.AccountCode)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.TaxType)),
                        ]),
                      ]),
                    ]
                  },
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("template", { slot: "footer" }, [
                _c("td", { attrs: { colspan: "7" } }),
                _vm._v(" "),
                _c("td", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    "\n                    £" +
                      _vm._s(_vm.batchReport.totalNet.toFixed(2)) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    "\n                    £" +
                      _vm._s(_vm.batchReport.totalGross.toFixed(2)) +
                      "\n                "
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }