var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "2", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [
                _vm._v("forward_to_inbox"),
              ]),
              _vm._v(" To Do\n        "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { block: "", href: _vm.toPostUrl },
                },
                [_vm._v("Gift Vouchers To Post")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { block: "", href: _vm.scheduledUrl },
                },
                [_vm._v("Upcoming Electronic Deliveries")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }