var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          this.field.introduction
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _vm._v(_vm._s(this.field.introduction)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              this.field.information
                ? _c(
                    "v-alert",
                    { attrs: { type: "info", dismissable: "", value: true } },
                    [_vm._v(_vm._s(this.field.information))]
                  )
                : _vm._e(),
              _vm._v(" "),
              this.field.warning
                ? _c(
                    "v-alert",
                    {
                      attrs: { type: "warning", dismissable: "", value: true },
                    },
                    [_vm._v(_vm._s(this.field.warning))]
                  )
                : _vm._e(),
              _vm._v(" "),
              this.field.error
                ? _c(
                    "v-alert",
                    { attrs: { type: "error", dismissable: "", value: true } },
                    [_vm._v(_vm._s(this.field.error))]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-col", { attrs: { col: "3" } }, [
            _c("label", { attrs: { for: "accept_range" } }, [
              _vm._v("General Children's Policy: "),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c(
                "v-radio-group",
                {
                  attrs: { id: "child-policy", column: "" },
                  on: {
                    change: function ($event) {
                      return _vm.updatePolicy($event)
                    },
                  },
                  model: {
                    value: _vm.viewValues.accept_range,
                    callback: function ($$v) {
                      _vm.$set(_vm.viewValues, "accept_range", $$v)
                    },
                    expression: "viewValues.accept_range",
                  },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-radio", { attrs: { value: 0 } }),
                      _c("span", [_vm._v("Children of all ages are welcome")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-radio", { attrs: { value: 1 } }),
                      _c("span", [
                        _vm._v("Only Children aged "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.viewValues.custom_age_range_end,
                              expression: "viewValues.custom_age_range_end",
                            },
                          ],
                          staticStyle: {
                            border: "1px solid grey",
                            "box-sizing": "border-box",
                            padding: "4px 6px",
                          },
                          attrs: {
                            type: "number",
                            name: "custom_age_range_end",
                            min: "0",
                            max: "18",
                          },
                          domProps: {
                            value: _vm.viewValues.custom_age_range_end,
                          },
                          on: {
                            keydown: _vm.checkAge,
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.viewValues,
                                "custom_age_range_end",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" years and over are accepted"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-radio", { attrs: { value: 2 } }),
                      _c("span", [
                        _vm._v("Other "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.viewValues.other_child_acceptance,
                              expression: "viewValues.other_child_acceptance",
                            },
                          ],
                          staticStyle: {
                            border: "1px solid grey",
                            "box-sizing": "border-box",
                            padding: "4px 6px",
                          },
                          attrs: {
                            type: "text",
                            name: "other_child_acceptance",
                          },
                          domProps: {
                            value: _vm.viewValues.other_child_acceptance,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.viewValues,
                                "other_child_acceptance",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          this.field.schema.displayName != "none"
            ? _c("v-col", { attrs: { cols: "2" } }, [
                _c("label", { attrs: { for: this.field.fieldName } }, [
                  _vm._v(" " + _vm._s(this.field.schema.displayName) + ": "),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: this.field.fieldName },
            domProps: { value: _vm.field.value },
          }),
          _vm._v(" "),
          _vm.errorMessages && _vm.errorMessages.length
            ? _c(
                "v-alert",
                { attrs: { type: "error", dismissable: "", value: true } },
                _vm._l(_vm.errorMessages, function (error, index) {
                  return _c("div", { key: index }, [
                    _vm._v(_vm._s(error.reason) + "*"),
                  ])
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }