<template>
    <v-flex xs12 ma-1>
        <v-card>

            <v-card-text>
                <v-layout row>

                    <!-- Bookings statistics - Previous week -->
                    <v-flex xs12>
                        <v-data-table
                            :headers="headers"
                            :items="stats"
                            class="elevation-1"
                            hide-default-footer
                            disable-initial-sort
                            :loading="loadPercentage < 100"
                        >
                            <v-progress-linear slot="progress" color="lime" v-model="loadPercentage" height="5"></v-progress-linear>

                            <template slot="items" slot-scope="props">
                                <td>{{ props.item.title }}</td>
                                <td>{{ props.item.units }}</td>
                                <td class="text-xs-right">{{ props.item.monthToDate1 }}</td>
                                <td class="text-xs-right">{{ props.item.quarterToDate1 }}</td>
                                <td class="text-xs-right">{{ props.item.yearToDate1 }}</td>
                                <!--<td class="text-xs-right">&nbsp;</td>-->
                                <td class="text-xs-right">{{ props.item.monthToDate2 }}</td>
                                <td class="text-xs-right">{{ props.item.quarterToDate2 }}</td>
                                <td class="text-xs-right">{{ props.item.yearToDate2 }}</td>
                            </template>

                        </v-data-table>
                    </v-flex>

                </v-layout>
            </v-card-text>

        </v-card>
    </v-flex>
</template>

<script>
    /**
     * Display bookings statistics for the calendar year to date compared with the same period(s) in the previous calendar year
     *
     */
    export default {
        props:[],

        data: function() {
            return {
                /** @var [] headers - The Vuetify table headings data */
                headers: [ 
                    {
                        text:   'Bookings',
                        value:  'title'
                    }, 
                    {
                        text:   '',
                        value:  'units'
                    },
                    {
                        text:   'MTD1',
                        value:  'monthToDate1',
                        align:  'right'
                    }, 
                    {
                        text:   'QTD1',
                        value:  'quarterToDate1',
                        align:  'right'
                    },
                    {
                        text:   'YTD1',
                        value:  'yearToDate1',
                        align:  'right'
                    },
                    /*
                    {
                        text:   '-',
                        value:  '',
                    },
                    */
                    {
                        text:   'MTD2',
                        value:  'monthToDate2',
                        align:  'right'
                    },
                    {
                        text:   'QTD2',
                        value:  'quarterToDate2',
                        align:  'right'
                    },
                    {
                        text:   'YTD2',
                        value:  'yearToDate2',
                        align:  'right'
                    }
                ],

                /** @var {} data - The bookings data to be presented to the user */
                data: {
                        newEnquiries:       { M1: { count: '?' }, M2: { count: '?' }, Q1: { count: '?' }, Q2: { count: '?' }, Y1: { count: '?' }, Y2: { count: '?' } }, 
                        quotesAccepted:     { M1: { count: '?' }, M2: { count: '?' }, Q1: { count: '?' }, Q2: { count: '?' }, Y1: { count: '?' }, Y2: { count: '?' } }, 
                        noAvailabilities:   { M1: { count: '?' }, M2: { count: '?' }, Q1: { count: '?' }, Q2: { count: '?' }, Y1: { count: '?' }, Y2: { count: '?' } }, 
                        cancellations:      { M1: { count: '?' }, M2: { count: '?' }, Q1: { count: '?' }, Q2: { count: '?' }, Y1: { count: '?' }, Y2: { count: '?' } } 
                    },

                /** @var int loadingProgress - The percentage of data that has been loaded so far, used in the loading animation progress bar on the data table */
                loadPercentage: 0
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            this.loadData();
        },

        methods: {
            /**
             * Load all bookings statistics for the given parameters, currently fixed as only to the past week from today inclusive for each of the main status types
             */
            loadData: function(){
                this.loadYearData();
                this.loadQuarterData();
                this.loadMonthData();
            },

            /**
             *
             */
            loadYearData: function(){
                var today       = new Date();
                var startDate1  = today.getFullYear() + '-01-01';
                var startDate2  = (today.getFullYear() - 1) + '-01-01';
                var dateTo1     = today.getFullYear() + '-' + (today.getMonth() + 1 < 10 ? '0' : '') + (today.getMonth() + 1) + '-' + (today.getDate() < 10 ? '0' : '') + today.getDate();
                var dateTo2     = (today.getFullYear() - 1) + '-' + (today.getMonth() + 1 < 10 ? '0' : '') + (today.getMonth() + 1) + '-' + (today.getDate() < 10 ? '0' : '') + today.getDate();

                this.loadStatsData(startDate1, dateTo1, 'Y1');
                this.loadStatsData(startDate2, dateTo2, 'Y2');
            },

            /**
             *
             */
            loadQuarterData: function(){
                var today       = new Date();

                // Establish what quarter of the year we are in, given that there are 3 months per quarter
                // Values are used mathematically so we produce them as 0|1|2|3 instead of 1|2|3|4 as it makes the calculations simpler
                var quarter     = parseInt( today.getMonth() / 3 );

                // Establish the first month of this quarter
                var month       = 3 * quarter;

                var monthString = (month < 10) ? ('0' + month) : month;
                var startDate1  = today.getFullYear() + '-' + monthString + '-01';
                var startDate2  = (today.getFullYear() - 1) + '-' + monthString + '-01';
                var dateTo1     = today.getFullYear() + '-' + monthString + '-' + (today.getDate() < 10 ? '0' : '') + today.getDate();
                var dateTo2     = (today.getFullYear() - 1) + '-' + monthString + '-' + (today.getDate() < 10 ? '0' : '') + today.getDate();

                this.loadStatsData(startDate1, dateTo1, 'Q1');
                this.loadStatsData(startDate2, dateTo2, 'Q2');
            },

            /**
             *
             */
            loadMonthData: function(){
                var today       = new Date();
                var month       = today.getMonth() + 1;
                var monthString = (month < 10) ? ('0' + month) : month;
                var startDate1  = today.getFullYear() + '-' + monthString + '-01';
                var startDate2  = (today.getFullYear() - 1) + '-' + monthString + '-01';
                var dateTo1     = today.getFullYear() + '-' + monthString + '-' + (today.getDate() < 10 ? '0' : '') + today.getDate();
                var dateTo2     = (today.getFullYear() - 1) + '-' + monthString + '-' + (today.getDate() < 10 ? '0' : '') + today.getDate();

                this.loadStatsData(startDate1, dateTo1, 'M1');
                this.loadStatsData(startDate2, dateTo2, 'M2');
            },

            /**
             * Method dedicated to loading the new-enquiries from the API for the given date range
             */
            loadStatsData: function(dateFrom, dateTo, reference){
                this.getDataFromAPI(dateFrom, dateTo, 'year', 'new-enquiries',      reference);
                this.getDataFromAPI(dateFrom, dateTo, 'year', 'quotes-accepted',    reference);
                this.getDataFromAPI(dateFrom, dateTo, 'year', 'no-availabilities',  reference);
                this.getDataFromAPI(dateFrom, dateTo, 'year', 'cancellations',      reference);
            },

            /**
             * Obtains the statistical data from the API for the parameters passed
             */
            getDataFromAPI: function(dateFrom, dateTo, granularity, type, reference){
                var params = { dateFrom: dateFrom, dateTo: dateTo, granularity: granularity, type: type, reference: reference };

console.log('params: ', params);
                console.log('load year for ' + reference);
                var self = this;
                axios.get('/api/bookings/statistics', { params: params })
                .then(function(response){ self.processResponse(response, type, reference) })
                .catch(function (error) {
                    console.log('%cError loading statistics from API', 'color:red');
                    console.log(error);
                });
            },

            /**
             *
             */
            processResponse: function(response, type, reference){
                this.setRawData(response, type, reference);

                // There are 24 cells in the table, and so each represents 4.16% of the whole.  This ought to be more dynamic but currently there is no elegant
                // way for the code to know how many data entries it will be producing given it has to run several queries to build it up as it goes, so given
                // this code won't change for a while it is better to be hardcoded for now
                this.incrementLoading(4.2);
            },

            /**
             * Setter method used to unpack the API response data on new enquiries into the format used in this component
             * In this instance the results are formatted in a way we need to extract the first child, since we know we will have only a single one but the API
             * always groups them into child objects as it is designed to cover any date ranges in case we wanted multiple timespans
             */
            setRawData: function(response, type, reference){
                console.log('year data: ', response.data);
                var key  = Object.keys(response.data)[0];
                var data = response.data[key];
                switch(type){
                    case 'new-enquiries':       this.data.newEnquiries[reference]     = data;   break;
                    case 'no-availabilities':   this.data.noAvailabilities[reference] = data;   break;
                    case 'quotes-accepted':     this.data.quotesAccepted[reference]   = data;   break;
                    case 'cancellations':       this.data.cancellations[reference]    = data;   break;
                }
                console.log('this data: ', this.data);
            },

            /**
             *
             */
            incrementLoading: function(incrementBy){
                this.loadPercentage += incrementBy;
                if (this.loadPercentage > 100){
                    this.loadPercentage = 100;
                }
            },

            /**
             * Returns a view model object representing the data ready to be used by the view
             * Accepts the name and units of measurement along with the array of raw data to be packaged and summarised into the view model to be returned
             *
             * @param string title
             * @param string units
             * @param [] rawData
             * @return {}
             * @see computed:stats()
             */
            getViewModel: function(title, units, rawData){
                var viewModel = { title: title, units: units };
                    
                // The Vuetify table expects each column to be based on unique named properties
                console.log('rawdata: ', rawData);
                viewModel.monthToDate1   = rawData.M1.count;
                viewModel.monthToDate2   = rawData.M2.count;
                viewModel.quarterToDate1 = rawData.Q1.count;
                viewModel.quarterToDate2 = rawData.Q2.count;
                viewModel.yearToDate1    = rawData.Y1.count;
                viewModel.yearToDate2    = rawData.Y2.count;

                return viewModel;
            },

            /**
             * return {}
             */
            getConversionRateModel: function(){
                var viewModel = { title: 'Conversion Rate (Accepted/New)', units: '%' };

                viewModel.monthToDate1   = this.getConversionRate('M1');
                viewModel.monthToDate2   = this.getConversionRate('M2');
                viewModel.quarterToDate1 = this.getConversionRate('Q1');
                viewModel.quarterToDate2 = this.getConversionRate('Q2');
                viewModel.yearToDate1    = this.getConversionRate('Y1');
                viewModel.yearToDate2    = this.getConversionRate('Y2');

                return viewModel;
            },

            /**
             *
             */
            getConversionRate: function(key){
                // If we don't yet have all the data we need then return a placeholder.  Since the value is only to be inserted into a view model
                // then there is no problem with returning a string/character in theory as no maths will be done using it
                if (!this.data.quotesAccepted[key].count || !this.data.newEnquiries[key].count){
                    return '?';
                }

                var numAccepted  = this.data.quotesAccepted[key].count;
                var numEnquiries = this.data.newEnquiries[key].count;
                return (numEnquiries) ? (100 * (numAccepted / numEnquiries)).toFixed(2) : 0.00;
            },

            /**
             * @return {}
             */
            getQuotesAcceptedModel: function(){
                var viewModel = { title: 'Value of Quotes Accepted', units: '£' };

                viewModel.monthToDate1   = this.getQuotesAccepted('M1');
                viewModel.monthToDate2   = this.getQuotesAccepted('M2');
                viewModel.quarterToDate1 = this.getQuotesAccepted('Q1');
                viewModel.quarterToDate2 = this.getQuotesAccepted('Q2');
                viewModel.yearToDate1    = this.getQuotesAccepted('Y1');
                viewModel.yearToDate2    = this.getQuotesAccepted('Y2');

                return viewModel;
            },

            /**
             *
             */
            getQuotesAccepted: function(key){
                if (!this.data.quotesAccepted[key] || !this.data.quotesAccepted[key].quote){
                    return '?';
                }

                var record = this.data.quotesAccepted[key];
                return record.quote.sum.toFixed(2);
            }
        },

        computed: {
        
            /**
             * Iterates over the data stored in this component and constructs a new array of computed properties ready formatted for the view template to use
             *
             * return []
             */
            stats: function(){
                var viewModels  = [];

                // Use separate reusable methods to package the data to keep this function as small as possible
                viewModels.push( this.getViewModel('New Enquiries',     'No.', this.data.newEnquiries) );
                viewModels.push( this.getViewModel('Quotes Accepted',   'No.', this.data.quotesAccepted) );
                viewModels.push( this.getViewModel('No Availabilities', 'No.', this.data.noAvailabilities) );
                viewModels.push( this.getConversionRateModel() );
                viewModels.push( this.getViewModel('Cancellations',     'No.', this.data.cancellations) );
                viewModels.push( this.getQuotesAcceptedModel() );

                // Return the entire array
                console.log('view models: ', viewModels);
                return viewModels;
            },

        }
    }
</script>
