var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-flex",
    [
      _vm.value
        ? _c(
            "v-flex",
            [
              _c(
                "v-btn",
                { on: { click: _vm.testFeed } },
                [_c("v-icon", [_vm._v("link")])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.shouldShowAnalysis
        ? _c(
            "v-flex",
            { staticClass: "analysis-panel" },
            [
              _vm.analysis.isValid
                ? _c("v-flex", [_vm._v("The feed appears to be valid")])
                : _vm._e(),
              _vm._v(" "),
              !_vm.analysis.isValid
                ? _c("v-flex", [_vm._v("The feed failed to load")])
                : _vm._e(),
              _vm._v(" "),
              _vm.analysis.isValid
                ? _c(
                    "div",
                    [
                      _c("v-flex", [
                        _vm._v(
                          "Time taken: " +
                            _vm._s(_vm.analysis.timeTaken) +
                            " seconds"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-flex", [
                        _vm._v(
                          "Future Bookings: " +
                            _vm._s(_vm.analysis.futureBookings)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-flex", [
                        _vm._v(
                          "The i-escape system would reduce availability on the following dates:"
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.analysis.updates, function (update) {
                        return _c("v-flex", [
                          _vm._v(
                            "From " +
                              _vm._s(update.from) +
                              " until  " +
                              _vm._s(update.until)
                          ),
                        ])
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-btn", { on: { click: _vm.closeAnalysis } }, [
                _vm._v("Close"),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }