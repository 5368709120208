var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-flex", { attrs: { xs12: "" } }, [
        _c("label", { attrs: { for: this.field.fieldName } }, [
          _vm._v(" " + _vm._s(this.field.schema.displayName) + ": "),
        ]),
      ]),
      _vm._v(" "),
      _c("v-select", {
        attrs: { label: "Link Type", items: _vm.typeOptions, autocomplete: "" },
        model: {
          value: _vm.linkType,
          callback: function ($$v) {
            _vm.linkType = $$v
          },
          expression: "linkType",
        },
      }),
      _vm._v(" "),
      _vm.linkType == 1
        ? _c("v-text-field", {
            attrs: { label: "Route Name" },
            model: {
              value: _vm.routeName,
              callback: function ($$v) {
                _vm.routeName = $$v
              },
              expression: "routeName",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.linkType == 2
        ? _c("v-text-field", {
            attrs: { label: "URL" },
            model: {
              value: _vm.explicitUrl,
              callback: function ($$v) {
                _vm.explicitUrl = $$v
              },
              expression: "explicitUrl",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.linkType == 3
        ? _c("v-select", {
            attrs: {
              label: "Portal Page to Link To",
              items: _vm.portalPageOptions,
              autocomplete: "",
            },
            model: {
              value: _vm.pageID,
              callback: function ($$v) {
                _vm.pageID = $$v
              },
              expression: "pageID",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: _vm.field.fieldName },
        domProps: { value: _vm.field.value },
      }),
      _vm._v(" "),
      _c(
        "v-alert",
        {
          staticStyle: { width: "100%" },
          attrs: { type: "error", value: _vm.field.errors },
        },
        _vm._l(_vm.field.errors, function (error) {
          return _c("li", [
            _vm._v("\n            " + _vm._s(error) + "\n        "),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }