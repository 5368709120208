var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "grid-list-md": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _vm.reservation.show || 1
                        ? _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              _vm.reservation.hotel
                                ? _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c("v-flex", { attrs: { xs4: "" } }, [
                                        _c("strong", [_vm._v("Property")]),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs8: "" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reservation.hotel.hotel_name
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs4: "" } }, [
                                        _c("strong", [_vm._v("Client")]),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs8: "" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reservation.user.first_name
                                          ) +
                                            " " +
                                            _vm._s(
                                              _vm.reservation.user.last_name
                                            )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs4: "" } }, [
                                        _c("strong", [_vm._v("Status")]),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs8: "" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.statusName(
                                              _vm.reservation.status_key
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs4: "" } }, [
                                        _c("strong", [_vm._v("Enquiry")]),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs8: "" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reservation
                                              .last_action_update_date
                                          ) +
                                            " " +
                                            _vm._s(
                                              _vm.reservation
                                                .last_action_update_time
                                            )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs4: "" } }, [
                                        _c("strong", [_vm._v("Arriving")]),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs8: "" } }, [
                                        _vm._v(
                                          _vm._s(_vm.reservation.date_arrival)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs4: "" } }, [
                                        _c("strong", [_vm._v("Leaving")]),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs8: "" } }, [
                                        _vm._v(
                                          _vm._s(_vm.reservation.date_departure)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs4: "" } }, [
                                        _c("strong", [_vm._v("Stay Length")]),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs8: "" } }, [
                                        _vm._v(
                                          _vm._s(_vm.reservation.stay_length)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs4: "" } }, [
                                        _c("strong", [_vm._v("Total Guests")]),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs8: "" } }, [
                                        _vm._v(
                                          _vm._s(_vm.reservation.adults) +
                                            " adults" +
                                            _vm._s(
                                              _vm.reservation.children
                                                ? _vm.reservation.children +
                                                    ", children"
                                                : ""
                                            ) +
                                            _vm._s(
                                              _vm.reservation.will_bring_pets
                                                ? ", + dog"
                                                : ""
                                            )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs4: "" } }, [
                                        _c("strong", [
                                          _vm._v("Rooms Requested"),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs8: "" } },
                                        _vm._l(
                                          _vm.reservation.units,
                                          function (unit, index) {
                                            return _vm.reservation.units
                                              ? _c("div", [
                                                  _vm._v(
                                                    "\n                                      " +
                                                      _vm._s(unit.room.name) +
                                                      " (\n                                          " +
                                                      _vm._s(
                                                        unit.occ_adults
                                                          ? unit.occ_adults +
                                                              " adults"
                                                          : ""
                                                      ) +
                                                      ", \n                                          " +
                                                      _vm._s(
                                                        unit.occ_children
                                                          ? unit.occ_children +
                                                              " young children"
                                                          : ""
                                                      ) +
                                                      ", \n                                          " +
                                                      _vm._s(
                                                        unit.occ_oldchildren
                                                          ? unit.occ_oldchildren +
                                                              " older children"
                                                          : ""
                                                      ) +
                                                      ", \n                                          " +
                                                      _vm._s(
                                                        unit.occ_babies
                                                          ? unit.occ_babies +
                                                              " babies"
                                                          : ""
                                                      ) +
                                                      "\n                                      )\n                                      " +
                                                      _vm._s(
                                                        unit.rate_type
                                                          ? unit.rate_type.name
                                                          : "No rate type specified"
                                                      ) +
                                                      "\n                                  "
                                                  ),
                                                ])
                                              : _vm._e()
                                          }
                                        ),
                                        0
                                      ),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs4: "" } }, [
                                        _c("strong", [_vm._v("Arrival Time")]),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs8: "" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reservation.estimated_arrival
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }