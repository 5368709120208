<template>
    <v-container fluid grid-list-md>
        <v-layout row wrap>
            <v-flex xs12>

                <v-alert type="error" :value="error" >{{ errorMsg }}</v-alert>

                <div v-if="!error">
                    <div v-if="!associationsRequested">
                        <v-btn @click="findAssociations">
                            Show associations
                        </v-btn>
                    </div>

                    <div v-if="associationsRequested">
                        <div v-if="busy">
                            <label> Loading associations... </label>
                            <template>
                                <v-progress-linear :indeterminate="true"></v-progress-linear>
                            </template>
                        </div>

                        <div v-if="!busy">

                            <v-layout row justify-space-around flex>
<!-- HOTEL ASSOCIATIONS TABLE------------ -->
                                <v-flex lg5 md5 sm10 id="hotelBox">
                                    <strong>Hotel Associations:</strong>
                                    <v-data-table
                                            :headers="tableHotels.headers"
                                            :items="associationsHotels"
                                            :rows-per-page-items="[20,50,100, {'text':'All', 'value':-1}]"
                                            footer-props.items-per-page-options="[20,50,100, {'text':'All', 'value':-1}]"
                                            no-data-text="User doesn't have any hotel associations"
                                    >
                                        <template slot="items" slot-scope="props">
                                            <td>{{ props.item.hotel_name }}</td>
                                            <td>{{ props.item.assoc_type }}</td>
                                            <td class="text-xs-right">
                                                <v-btn icon class="mx-0" @click="deleteHotel(props.item.user_association_key)">
                                                    <v-icon color="pink">delete</v-icon>
                                                </v-btn>
                                            </td>
                                        </template>
                                    </v-data-table>

<!-- ADD NEW HOTEL ASSOCIATION-->
                                    <div>
                                        <strong>
                                            Add new hotel association
                                        </strong>
                                        <v-layout row wrap>
                                            <v-flex xs4>
                                                <v-select
                                                        :items="hotels"
                                                        label="Select hotel"
                                                        item-value="name"
                                                        dense
                                                        autocomplete
                                                        v-model="selectedHotel"
                                                        id="hotel-select"
                                                        hint="Type to search for a hotel"
                                                        persistent-hint
                                                >
                                                    <template slot="item" slot-scope="data">
                                                        <option
                                                                v-text="data.item.text"
                                                                :class="{'hotel-offline' : (data.item.display == 0) }"
                                                        ></option>
                                                    </template>
                                                </v-select>
                                            </v-flex>
                                            <v-flex xs1>
                                                <v-subheader>and</v-subheader>
                                            </v-flex>
                                            <v-flex xs4>
                                                <v-select
                                                        :items="typeOfAssociations"
                                                        label="Type of association"
                                                        dense
                                                        item-value="association"
                                                        v-model="selectedAssociationHotel"
                                                        id="association-hotel-select"
                                                ></v-select>
                                            </v-flex>
                                            <v-flex xs1>
                                                <v-btn color="success" @click="addHotel()">Add</v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </div>
                                </v-flex>

                                <div id="separator"></div>

<!-- REGION ASSOCIATIONS TABLE------------ -->
                                <v-flex lg5 md5 sm10 id="regionBox">
                                    <strong>Region Associations:</strong>
                                    <v-data-table
                                            :headers="tableRegions.headers"
                                            :items="associationsRegions"
                                            footer-props.items-per-page-options="[20,50,100, {'text':'All', 'value':-1}]"
                                            no-data-text="User doesn't have any regions associations"
                                    >
                                        <template slot="items" slot-scope="props">
                                            <td>{{ props.item.region_name }}</td>
                                            <td>{{ props.item.assoc_type }}</td>
                                            <td class="text-xs-right">
                                                <v-btn icon class="mx-0" @click="deleteRegion(props.item.user_association_key)">
                                                    <v-icon color="pink">delete</v-icon>
                                                </v-btn>
                                            </td>
                                        </template>

                                    </v-data-table>
<!--ADD NEW REGION ASSOCIATION-->
                                    <div>
                                        <strong>
                                            Add new region association
                                        </strong>
                                        <v-layout row wrap>
                                            <v-flex xs4>
                                                <v-select
                                                        :items="regions"
                                                        v-model="selectedRegions"
                                                        label="Select region"
                                                        autocomplete
                                                        multiple
                                                        item-text="region_name"
                                                        item-value="region_value"
                                                        chips
                                                        dense
                                                        hint="Type to search for a region, you can select multiple regions"
                                                        persistent-hint
                                                        id="region-select"
                                                >
                                                    <template slot="item" slot-scope="data">
                                                        <option
                                                                v-text="data.item.region_name"
                                                                :class="data.item.region_type"
                                                        ></option>
                                                    </template>
                                                </v-select>
                                            </v-flex>
                                            <v-flex xs1>
                                                <v-subheader>and</v-subheader>
                                            </v-flex>
                                            <v-flex xs4>
                                                <v-select
                                                        :items="typeOfAssociations"
                                                        label="Type of association"
                                                        dense
                                                        item-value="association"
                                                        v-model="selectedAssociationRegion"
                                                        id="association-region-select"
                                                ></v-select>
                                            </v-flex>
                                            <v-flex xs1>
                                                <v-btn color="success" @click="addRegion()" >Add</v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </div>
                    </div>
                </div>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import axios from 'axios';
    export default {
        name: 'user-associations',
        props: ['field'],
        data () {
            return {
                fieldName: this.field.fieldName,    // this should be legacy_user_key for existing user / false for new user
                userKey: this.field.value,
                modelName: this.field.modelName,

                associationsHotels: [],             // model that keeps all hotels associations
                associationsRegions: [],            // model that keeps all regions associations
                associationsRequested: false,

                hotels : [],                        // list of all hotels used by hotels select
                regions : [],                       // list of all regions used by regions select

                // selected options
                selectedHotel: false,
                selectedRegions: [],
                selectedAssociationHotel: false,
                selectedAssociationRegion: false,

                // new associations
                newCounter: 'new_0',
                newHotels: [],
                newRegions: [],

                // removed associations
                removeHotels: [],
                removeRegions: [],

                error : false,
                errorMsg: 'Error...',
                busy: false,

                // table blank data
                tableHotels: {
                    headers: [
                        { text: 'Hotel', value: 'hotel_name', sortable: true},
                        { text: 'Associated with', value: 'assoc_type'},
                        { text: 'Delete', value: 'user_association_key', sortable: false, align: 'right'},
                    ],
                },
                tableRegions: {
                    headers: [
                        { text: 'Region', value: 'region_name', sortable: true},
                        { text: 'Associated with', value: 'assoc_type'},
                        { text: 'Delete', value: 'user_association_key', sortable: false, align: 'right'},
                    ],
                },
                typeOfAssociations: [
                    { text: 'Photo library', value: 'photo_library'},
                    { text: 'Reservation', value: 'reservation'},
                    { text: 'Both', value: 'both'}
                ],
            }
        },
        methods: {

            /**
             *  Initialize Associations data load
             */
            findAssociations() {
                if (this.fieldName === 'legacy_user_key' || this.fieldName === 'user_key') {
                    this.associationsRequested = true;
                    this.busy = true;
                    this.getUserAssociations();
                }
            },

            /**
             * call our API to get user associations
             */
            getUserAssociations() {
                // check if this user exists or this is new user
                var currentUser = this.userKey > 0 ? this.userKey : false;

                // create json that will be send in ajax call
                var dataToSend = JSON.stringify({ 'userKey': currentUser, 'column': this.fieldName, 'model': this.modelName });

                // send ajax call
                axios.post('ajax/associations/get',{ data : dataToSend})
                    .then(function(response) {
                        if (response.data.success) {
                            // we found associations for that user, assign response data to vue data objects
                            this.busy = false;
                            this.associationsHotels = response.data.associations.hotels;
                            this.associationsRegions = response.data.associations.regions;
                            this.hotels = response.data.hotels;
                            this.regions = response.data.regions;
                        }
                        else {
                            // no data came from backend
                            this.busy = false;
                        }
                    }.bind(this))
                    .catch(function(error) {
                        this.busy = false;
                        this.error = true;
                        this.errorMsg = 'There was a problem with obtaining associations data...'
                    }.bind(this));
            },

            /**
             * Create array of one or two object for single hotel, that next will be passed to associated hotels
             * If user selected BOTH associations generate two associations for this hotel
             */
            createNewHotelObject(hotel) {
                if (this.selectedAssociationHotel.value === 'both') {
                    var newHotel = [{
                        user_association_key : this.getNewAddedKey(),
                        hotel_key : hotel.hotelKey,
                        hotel_name : hotel.text,
                        assoc_type : 'photo_library'
                    },{
                        user_association_key : this.getNewAddedKey(),
                        hotel_key : hotel.hotelKey,
                        hotel_name : hotel.text,
                        assoc_type : 'reservation'
                    }];
                }
                else {
                    var newHotel = {
                        user_association_key : this.getNewAddedKey(),
                        hotel_key : hotel.hotelKey,
                        hotel_name : hotel.text,
                        assoc_type : this.selectedAssociationHotel.value
                    };
                }

                return Array.isArray(newHotel) ? newHotel : [newHotel];
            },

            /**
             * Create array of object with new regions associations
             * If user selected BOTH associations generate two associations for this region
             */
            createNewRegionObject(regions) {
                var createdRegions = [];

                for(var i = 0; i < regions.length; i++) {
                    // split region_value string which is string in format: 'key+value'
                    let regionData = regions[i].split('+');
                    let key = regionData[0];
                    let name = regionData[1];

                    if(this.selectedAssociationRegion.value === 'both') {
                        // if user selected BOTH generate two associations for this region
                        var newPhoto = {
                            user_association_key  : this.getNewAddedKey(),
                            region_name : name,
                            region_key : key,
                            assoc_type  : 'photo_library'
                        };

                        var newReservation = {
                            user_association_key  : this.getNewAddedKey(),
                            region_name : name,
                            region_key : key,
                            assoc_type : 'reservation'
                        };
                        createdRegions.push(newPhoto);
                        createdRegions.push(newReservation);
                    }
                    else {
                        var newRegion = {
                            user_association_key  : this.getNewAddedKey(),
                            region_name : name,
                            region_key : key,
                            assoc_type  : this.selectedAssociationRegion.value,
                        };
                        createdRegions.push(newRegion);
                    }
                }

                return createdRegions;
            },

            /**
             * Method adds new hotel to associationsHotels vue-model
             */
            addHotel() {
                var type = 'hotel';

                if (this.inputValidation(type)) {
                    // create new hotel object
                    var newHotel = this.createNewHotelObject(this.selectedHotel);

                    for(var i = 0; i < newHotel.length; i++) {

                        // check if don't have already this association
                        if (this.checkForDoubledData(type, newHotel[i])) {
                            this.addNewHotel(newHotel[i]);
                            this.addToTable(type, newHotel[i]);
                            this.resetSelect(type);
                        }
                    }
                }
            },

            /**
             * Methods adds new regions to associationsRegions vue-model
             */
            addRegion() {
                var type = 'region';

                if (this.inputValidation(type)) {
                    // create array containing all added regions objects
                    var newRegions = this.createNewRegionObject(this.selectedRegions);

                    for(var i = 0; i < newRegions.length; i++) {

                        // check if user don't have already this association
                        if (this.checkForDoubledData(type, newRegions[i])) {
                            this.addNewRegion(newRegions[i]);
                            this.addToTable(type, newRegions[i]);
                            this.resetSelect(type);
                        }
                    }
                }
            },

            addNewHotel(newHotel) {
                this.newHotels.push(newHotel);
            },

            addNewRegion(newRegion) {
                this.newRegions.push(newRegion);
            },

            /**
             * Adds to hotels/regions table passed hotel/region object
             */
            addToTable(table, newObject) {
                if (table === 'hotel') {
                    this.associationsHotels.push(newObject);
                }
                else if (table === 'region') {
                    this.associationsRegions.push(newObject)
                }
            },

            /**
             * Methods return key for newly added associations
             * this counter is keep as newCounter data object
             */
            getNewAddedKey() {
                var oldKey = this.newCounter.substring('new_'.length);
                this.newCounter = 'new_' + ++oldKey;

                return this.newCounter;
            },

            /**
             * checks if correct data is submitted, hotel/region + type of association is selected
             */
            inputValidation(type) {
                if (type === 'hotel') {
                    if(!this.selectedAssociationHotel) {
                        this.inputAssociationError(type);
                        return false;
                    }

                    if(!this.selectedHotel || this.selectedHotel.length < 1) {
                        this.inputHotelError();
                        return false;
                    }

                    return true;
                }
                else if (type === 'region') {
                    if(!this.selectedAssociationRegion) {
                        this.inputAssociationError(type);
                        return false;
                    }

                    if(!this.selectedRegions || this.selectedRegions.length < 1) {
                        this.inputRegionError();
                        return false;
                    }

                    return true;
                }
                return false;
            },

            /**
             *  Method checks if given association is already assigned to that user
             */
            checkForDoubledData(type, newObject) {
                if (type === 'hotel') {
                    for(var k = 0; k < this.associationsHotels.length; k++) {
                        if(this.associationsHotels[k].hotel_name === newObject.hotel_name && this.associationsHotels[k].assoc_type === newObject.assoc_type) {
                            var message = newObject.hotel_name + ' - ' + newObject.assoc_type;
                            alert('We already have association [' + message +'] assigned to this user');
                            return false;
                        }
                    }
                }
                else if (type === 'region') {
                    for(var k = 0; k < this.associationsRegions.length; k++) {
                        if(this.associationsRegions[k].region_name === newObject.region_name && this.associationsRegions[k].assoc_type === newObject.assoc_type) {
                            var message = newObject.region_name + ' - ' + newObject.assoc_type;
                            alert('We already have association  [' + message + '] assigned to this user');
                            return false;
                        }
                    }
                }
                return true;
            },

            inputAssociationError(type) {
                switch (type) {
                    case 'hotel'  : var selector = '#association-hotel-select';  break;
                    case 'region' : var selector = '#association-region-select'; break;
                }

                var input = $(selector).focus();
                input.focus();
            },


            inputHotelError() {
                var input = $('#hotel-select').focus();
                input.focus();
            },

            inputRegionError() {
                var input = $('#region-select').focus();
                input.focus();
            },

            /**
             * Method search for given hotel association and removes it from vue-model
             * Then adds this it to removeHotels array
             */
            deleteHotel(userAssociationKey) {
                for(var k = 0; k < this.associationsHotels.length; k++) {
                    if(this.associationsHotels[k].user_association_key === userAssociationKey) {

                        // check if this is association just added and then removed before save
                        if (isNaN(userAssociationKey) && userAssociationKey.search('new_') !== -1) {
                            // if it's new_ association remove it from newHotels
                            for(var i = 0; i < this.newHotels.length; i++) {
                                if (this.newHotels[i].user_association_key === userAssociationKey) {
                                    this.newHotels.splice(i, 1);
                                }
                            }
                        }
                        else {
                            this.removeHotels.push(this.associationsHotels[k]);
                        }

                        this.associationsHotels.splice(k, 1);
                    }
                }
            },

            /**
             * Method search for given region association and removes it from vue-model
             * Then adds this it to removeRegions array
             */
            deleteRegion(userAssociationKey) {
                for(var k = 0; k < this.associationsRegions.length; k++) {
                    if(this.associationsRegions[k].user_association_key === userAssociationKey) {

                        // check if this is association just added and then removed before save
                        if (isNaN(userAssociationKey) && userAssociationKey.search('new_') !== -1) {
                            // if it's new_ association remove it from newRegions
                            for(var i = 0; i < this.newRegions.length; i++) {
                                if (this.newRegions[i].user_association_key === userAssociationKey) {
                                    this.newRegions.splice(i, 1);
                                }
                            }
                        }
                        else {
                            this.removeRegions.push(this.associationsRegions[k]);
                        }

                        this.associationsRegions.splice(k, 1);
                    }
                }
            },

            /**
             * Method clears hotel/region select input, and selectedHotel / selectedRegions vue-model
             * @param type
             */
            resetSelect(type) {
                var selector = '#' + type + '-select';
                if (type === 'hotel') {
                    this.selectedHotel = [];
                }
                else if (type === 'region') {
                    this.selectedRegions = [];
                }
            },

            /**
             * Method return user object for existing user, or if it's new user, get just created user ID from emit data
             */
            getUserForPost(data) {
                if (!this.userKey) {
                    // this is new user
                    this.userKey = data.id;
                    this.fieldName = 'user_key'
                }

                var userObj = {
                    'fieldName'     : this.fieldName,
                    'userKey'       : this.userKey,
                    'modelName'     : this.modelName,
                    };

                return userObj;
            },

            /**
             * Method triggerd when SAVE button is clicked on edit page, sends all edited associations data stored in :
             * newHotels, newRegions, removeHotels, removeRegions arrays to our API save associations which update database
             *
             */
            postAssociations(data) {
                // check if there is something to be send
                if (this.newHotels.length || this.newRegions.length || this.removeHotels.length || this.removeRegions.length) {
                    // generate data object
                    var dataToSend = JSON.stringify({
                        'newHotels'     : this.newHotels,
                        'newRegions'    : this.newRegions,
                        'removeHotels'  : this.removeHotels,
                        'removeRegions' : this.removeRegions,
                        'user' : this.getUserForPost(data),
                    });


                    axios.post('ajax/associations/post',{ data : dataToSend})
                        .then(function(response) {
                            if (response.data.success) {

                                // Database was updated so clear new/remove arrays
                                this.clearArraysAfterSave();

                                // emit that update has been made to other vue components
                                this.$eventHub.$emit('associations-saved', {success: true});
                            }
                            else {
                                // no data came from backend
                                this.busy = false;
                            }
                        }.bind(this))
                        .catch(function(error) {
                            alert('There was a problem with saving user associations, try to save it again');
                        }.bind(this));
                }


            },

            /**
             * Database was updated so empty new/remove arrays
             * also we need to change user_association_keys where they are new_ to saved_ in arrays stored this user hotel + region associations
             * so they can be deleted if someone make a mistake and wants to remove them after the press SAVE without reloading the page.
             */
            clearArraysAfterSave() {
                this.newHotels = [];
                this.newRegions = [];
                this.removeHotels = [];
                this.removeRegions = [];

                if (this.associationsHotels.length > 1) {
                    for(var i = 0; i < this.associationsHotels.length; i++) {
                        if (isNaN(this.associationsHotels[i].user_association_key)) {
                            var str = this.associationsHotels[i].user_association_key;
                            this.associationsHotels[i].user_association_key = str.replace(/new/, "saved");
                        }
                    }
                }

                if (this.associationsRegions > 1) {
                    for(var i = 0; i < this.associationsRegions.length; i++) {
                        if (isNaN(this.associationsRegions[i].user_association_key)) {
                            var str = this.associationsRegions[i].user_association_key;
                            this.associationsRegions[i].user_association_key = str.replace(/new/, "saved");
                        }
                    }
                }
            },
        },
        mounted() {
            // create copy of main app vue 'this' so we can call 'this' functions from eventHub scope
            var _this = this;

            // here we can initialize findAssociations on page load by uncommenting call this.findAssociations()
            // but for now we'll leave it to be initialized by button click 'Show associations'
            // this.findAssociations();


            // Register an event listener callback function which listens when SAVE button is clicked, triggering associations save
            this.$eventHub.$on('save-button-clicked', function(data) {
                _this.postAssociations(data);
            });
        },
    }
</script>

<style>

    #separator {
        height: auto;
        border-right: #dcdfe5 1px dashed;
    }

    strong {
        font-size: 130%;
    }

    .continent {
        padding: 5px;
        background-color: #C1CD23; /* brand primary */
    }

    .country {
        padding: 5px 5px 5px 20px;
        background-color: #66d6ff; /* brand primary */
    }

    .region {
        padding-left: 35px;
    }

    .subregion {
        padding-left: 60px;
    }

    /* this is background-color of the tables that overrides vuetify styling if we want that, but white looks better ;*/
    .application .theme--light.table, .theme--light .table {
        background-color: #fcfcfc;
    }

    .hotel-offline {
        text-decoration: line-through;
        color: darkgray;
    }

</style>
