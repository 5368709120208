<template>
    <v-flex xs12 lg2 ma-1>
        <v-card>
            <v-card-title>
                Create Credit Note
            </v-card-title>

            <v-card-text>
               
                <v-layout column>
                    <v-btn disabled>Annual Fee Credit</v-btn>
                    <v-btn disabled>Manual Credit</v-btn>
                    <v-btn disabled>Arrears Credit</v-btn>
                </v-layout>
                
            </v-card-text>
        </v-card>
    </v-flex>
</template>

<script>
    /**
     * Display buttons linking through to accounts credit note creation pages
     */
    export default {
        props:[],

        data: function() {
            return {
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){},

        methods: {},

        computed: {}
    }
</script>
