var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("accounts-filter", {
            attrs: { settings: _vm.accountFilterSettings },
          }),
          _vm._v(" "),
          _c("v-progress-linear", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLoading,
                expression: "isLoading",
              },
            ],
            attrs: { indeterminate: true, color: "lime" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-container", { attrs: { fluid: "" } }),
      _vm._v(" "),
      _c("v-container", { attrs: { fluid: "" } }, [_c("hotel-summary")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }