var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("h1", [_vm._v("  Collection: " + _vm._s(_vm.collectionData.name))]),
      _vm._v(" "),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-btn",
                {
                  staticClass: "ies-green",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      return _vm.saveCollection()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("save")]), _vm._v(" Save")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ies-coral",
                  on: {
                    click: function ($event) {
                      return _vm.deleteCollection()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("delete")]), _vm._v(" Delete")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { onClick: "window.print()" } },
                [_c("v-icon", [_vm._v("print")]), _vm._v(" Print")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  on: {
                    click: function ($event) {
                      return _vm.emailLink()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("email")]), _vm._v(" Email")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { href: "/collections/list" } },
                [_c("v-icon", [_vm._v("view_list")]), _vm._v(" List")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  on: {
                    click: function ($event) {
                      return _vm.reportProblem()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("report_problem")]), _vm._v(" Problem")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-flex", { attrs: { xs12: "" } }, [
        _c(
          "form",
          {
            attrs: { id: "editForm" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c(
              "v-card",
              [
                _c(
                  "v-layout",
                  { staticClass: "ma-3", attrs: { column: "" } },
                  [
                    _c(
                      "v-card",
                      { staticClass: "ma-3", attrs: { flat: "", xs12: "" } },
                      [
                        _c(
                          "fieldset",
                          [
                            _c("legend", [_vm._v("Collection Info")]),
                            _vm._v(" "),
                            _c(
                              "v-layout",
                              { attrs: { column: "" } },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: { label: "Name" },
                                      model: {
                                        value: _vm.collectionData.name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.collectionData,
                                            "name",
                                            $$v
                                          )
                                        },
                                        expression: "collectionData.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: { label: "System Name" },
                                      model: {
                                        value: _vm.collectionData.system_name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.collectionData,
                                            "system_name",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "collectionData.system_name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.collectionTypes,
                                        label: "Collection Type",
                                      },
                                      model: {
                                        value:
                                          _vm.collectionData
                                            .collection_type_key,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.collectionData,
                                            "collection_type_key",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "collectionData.collection_type_key",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: { label: "Text" },
                                      model: {
                                        value: _vm.collectionData.text,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.collectionData,
                                            "text",
                                            $$v
                                          )
                                        },
                                        expression: "collectionData.text",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: { label: "Title" },
                                      model: {
                                        value: _vm.collectionData.title,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.collectionData,
                                            "title",
                                            $$v
                                          )
                                        },
                                        expression: "collectionData.title",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: { label: "Description" },
                                      model: {
                                        value: _vm.collectionData.description,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.collectionData,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "collectionData.description",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("label", { attrs: { for: "isOnline" } }, [
                                    _vm._v("Is Online: "),
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.collectionData.is_online,
                                        expression: "collectionData.is_online",
                                      },
                                    ],
                                    attrs: { type: "checkbox", id: "isOnline" },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.collectionData.is_online
                                      )
                                        ? _vm._i(
                                            _vm.collectionData.is_online,
                                            null
                                          ) > -1
                                        : _vm.collectionData.is_online,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.collectionData.is_online,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.collectionData,
                                                "is_online",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.collectionData,
                                                "is_online",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.collectionData,
                                            "is_online",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-layout",
                              { attrs: { "row-sm": "", column: "" } },
                              [
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("label", { attrs: { for: "image_key" } }, [
                                    _vm._v(" Image Key: "),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
                                  _c("input", {
                                    attrs: {
                                      name: "image_key",
                                      type: "text",
                                      disabled: "",
                                    },
                                    domProps: {
                                      value: this.collectionData.image_key,
                                    },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("img", {
                                    staticStyle: { "max-height": "200px" },
                                    attrs: {
                                      alt: "Image from photo library",
                                      src: _vm.generatePath(
                                        this.collectionData.image_key
                                      ),
                                    },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c(
                                      "v-btn",
                                      { on: { click: _vm.selectNewImage } },
                                      [_vm._v("Choose Image")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          href: _vm.imageURL,
                                          target: "_blank",
                                          disabled:
                                            !this.collectionData.image_key,
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("image")])],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "ies-coral ies-dark-gray--text",
                                        attrs: {
                                          disabled:
                                            !this.collectionData.image_key,
                                        },
                                        on: { click: _vm.unlinkImage },
                                      },
                                      [_vm._v("Remove Image")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.displayImagePicker
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "fixed",
                                          top: "10vh",
                                          left: "10vw",
                                          width: "80vw",
                                          height: "80vh",
                                          "background-color": "white",
                                          "z-index": "999",
                                          padding: "5rem",
                                        },
                                      },
                                      [
                                        _c("h2", [_vm._v("Image Library...")]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "overflow-y": "scroll",
                                              height: "60vh",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-container",
                                              { attrs: { fluid: "" } },
                                              [
                                                _c(
                                                  "v-layout",
                                                  {
                                                    attrs: {
                                                      row: "",
                                                      wrap: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs12: "" } },
                                                      [
                                                        _vm._v(
                                                          "~~\n                                              "
                                                        ),
                                                        _c(
                                                          "v-expansion-panels",
                                                          [
                                                            _c(
                                                              "v-expansion-panel",
                                                              [
                                                                _c(
                                                                  "v-expansion-panel-header",
                                                                  [
                                                                    _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          "Search "
                                                                        ),
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "search"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-expansion-panel-content",
                                                                  [
                                                                    _c(
                                                                      "fieldset",
                                                                      [
                                                                        _c(
                                                                          "legend",
                                                                          [
                                                                            _vm._v(
                                                                              "Properties"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "ID",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .searchFilters
                                                                                    .imageKey,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.searchFilters,
                                                                                      "imageKey",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "searchFilters.imageKey",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "Minimum Width (px)",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .searchFilters
                                                                                    .minWidth,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.searchFilters,
                                                                                      "minWidth",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "searchFilters.minWidth",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "Minimum Height (px)",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .searchFilters
                                                                                    .minHeight,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.searchFilters,
                                                                                      "minHeight",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "searchFilters.minHeight",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-radio-group",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                mandatory: false,
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .searchFilters
                                                                                    .displayType,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.searchFilters,
                                                                                      "displayType",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "searchFilters.displayType",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-radio",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      "Any",
                                                                                    value:
                                                                                      "",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "v-radio",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      "Display only",
                                                                                    value:
                                                                                      "1",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "v-radio",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      "Hidden only",
                                                                                    value:
                                                                                      "0",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-select",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                items:
                                                                                  _vm.imageTypes,
                                                                                label:
                                                                                  "Select Type of Image",
                                                                                "item-text":
                                                                                  "text",
                                                                                "item-value":
                                                                                  "value",
                                                                                dense:
                                                                                  "",
                                                                                id: "image-type",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .searchFilters
                                                                                    .imageType,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.searchFilters,
                                                                                      "imageType",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "searchFilters.imageType",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "fieldset",
                                                                      [
                                                                        _c(
                                                                          "legend",
                                                                          [
                                                                            _vm._v(
                                                                              "Date Range"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "bold",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Date From:"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-date-picker",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                landscape: true,
                                                                                color:
                                                                                  "lime",
                                                                                type: "date",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .searchFilters
                                                                                    .dateFrom,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.searchFilters,
                                                                                      "dateFrom",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "searchFilters.dateFrom",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "bold",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Date Until:"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-date-picker",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                landscape: true,
                                                                                color:
                                                                                  "lime",
                                                                                type: "date",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .searchFilters
                                                                                    .dateUntil,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.searchFilters,
                                                                                      "dateUntil",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "searchFilters.dateUntil",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "fieldset",
                                                                      [
                                                                        _c(
                                                                          "legend",
                                                                          [
                                                                            _vm._v(
                                                                              "Relationships"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "Hotel Code",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .searchFilters
                                                                                    .hotelCode,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.searchFilters,
                                                                                      "hotelCode",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "searchFilters.hotelCode",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        on: {
                                                                          click:
                                                                            _vm.loadLibraryImages,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Search"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs12: "" } },
                                                      [
                                                        _c(
                                                          "v-container",
                                                          {
                                                            attrs: {
                                                              "grid-list-md":
                                                                "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  row: "",
                                                                  wrap: "",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.libraryImages,
                                                                function (
                                                                  libraryImage,
                                                                  image_key
                                                                ) {
                                                                  return _c(
                                                                    "v-flex",
                                                                    {
                                                                      key: image_key,
                                                                      staticClass:
                                                                        "pt-3",
                                                                      attrs: {
                                                                        xs3: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-card",
                                                                        {
                                                                          staticClass:
                                                                            "clickable",
                                                                          nativeOn:
                                                                            {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.chooseImage(
                                                                                    libraryImage.image_key
                                                                                  )
                                                                                },
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-container",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  flex: "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-layout",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      row: "",
                                                                                      wrap: "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-flex",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          xs12: "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "ID:"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            libraryImage.image_key
                                                                                          ) +
                                                                                          "\n                                                        "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "v-flex",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          xs12: "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "img",
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              "max-height":
                                                                                                "150px",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              alt: "Image from photo library",
                                                                                              src: libraryImage.image_source,
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  libraryImage.hotel
                                                                                    ? _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              xs12: "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "b",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "Hotel:"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " (" +
                                                                                              _vm._s(
                                                                                                libraryImage
                                                                                                  .hotel
                                                                                                  .hotel_code
                                                                                              ) +
                                                                                              ") " +
                                                                                              _vm._s(
                                                                                                libraryImage
                                                                                                  .hotel
                                                                                                  .hotel_name
                                                                                              ) +
                                                                                              "\n                                                        "
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "v-flex",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          xs12: "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Display:"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            libraryImage.display_slideshow
                                                                                              ? "Yes"
                                                                                              : "No"
                                                                                          ) +
                                                                                          "\n                                                            "
                                                                                      ),
                                                                                      _c(
                                                                                        "b",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Type:"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            typeof libraryImage.image_type ===
                                                                                              undefined ||
                                                                                              libraryImage.image_type ==
                                                                                                null ||
                                                                                              typeof libraryImage
                                                                                                .image_type
                                                                                                .display_name_short ===
                                                                                                undefined
                                                                                              ? null
                                                                                              : libraryImage
                                                                                                  .image_type
                                                                                                  .display_name_short
                                                                                          ) +
                                                                                          "\n                                                            "
                                                                                      ),
                                                                                      _c(
                                                                                        "b",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Order:"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            libraryImage.sort_order
                                                                                          ) +
                                                                                          "\n                                                        "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "v-flex",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          xs12: "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Caption:"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            libraryImage.caption
                                                                                          ) +
                                                                                          "\n                                                        "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "v-flex",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          xs12: "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Copyright:"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            libraryImage.copyright
                                                                                          ) +
                                                                                          "\n                                                        "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "v-flex",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          xs12: "",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Description:"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            libraryImage.description
                                                                                          ) +
                                                                                          "\n                                                        "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "100%",
                                              position: "relative",
                                              bottom: "0",
                                              "background-color": "white",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "ies-green ies-dark-gray--text",
                                                on: {
                                                  click: _vm.closeImageSelector,
                                                },
                                              },
                                              [_vm._v("Done")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-btn",
                                              {
                                                on: {
                                                  click: _vm.closeImageSelector,
                                                },
                                              },
                                              [_vm._v("Cancel")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.collectionData.collection_type_key == 1
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline font-weight-black" },
                [
                  _vm._v(
                    "\n                Hotels Assigned to the " +
                      _vm._s(_vm.collectionData.name) +
                      " Collection (" +
                      _vm._s(_vm.collectionHotels.length) +
                      ")\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "v-container",
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            on: {
                              keyup: [
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.onEnter.apply(null, arguments)
                                },
                                function ($event) {
                                  return _vm.updateHotelName()
                                },
                              ],
                            },
                            model: {
                              value: _vm.hotelViewName,
                              callback: function ($$v) {
                                _vm.hotelViewName = $$v
                              },
                              expression: "hotelViewName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.hotelOptions.length
                        ? _c(
                            "v-col",
                            {
                              staticStyle: { display: "block" },
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "ul",
                                _vm._l(_vm.hotelOptions, function (hotel) {
                                  return _c(
                                    "li",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.addHotelToCollection(
                                            hotel.hotel_key
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "(" +
                                          _vm._s(hotel.hotel_code) +
                                          ") " +
                                          _vm._s(hotel.hotel_name)
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-container",
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    _vm._l(_vm.collectionHotels, function (hotel, hotel_key) {
                      return _c(
                        "v-col",
                        {
                          key: hotel,
                          staticClass: "mt-3",
                          attrs: { cols: "12", md: "2" },
                        },
                        [
                          _c(
                            "v-card",
                            { attrs: { "min-height": "200px" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-container",
                                    {
                                      attrs: { flex: "", "justify-center": "" },
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                "d-flex": "",
                                                xs8: "",
                                                "justify-center": "",
                                              },
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "text-xs-right",
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "ml-3" },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      "Hotel: (" +
                                                        _vm._s(
                                                          hotel.hotel_code
                                                        ) +
                                                        ") " +
                                                        _vm._s(
                                                          hotel.hotel_name
                                                        ) +
                                                        ","
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("p", [
                                                    _vm._v(
                                                      _vm._s(hotel.city) +
                                                        ", " +
                                                        _vm._s(
                                                          hotel.country_name
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                "xs-6": "",
                                                "justify-center": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "ies-coral small",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeHotelFromCollectionHotels(
                                                        hotel
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("delete"),
                                                  ]),
                                                  _vm._v(" Remove"),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.collectionData.collection_type_key != 1
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline font-weight-black" },
                [
                  _vm._v(
                    "\n              Selected Filters for the " +
                      _vm._s(_vm.collectionData.name) +
                      " Collection\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.filterOptions, function (filter, key) {
                return _c(
                  "v-flex",
                  { key: key, attrs: { xs8: "", "justify-center": "" } },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", { staticClass: "headline" }, [
                          _vm._v(_vm._s(filter.title)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-container",
                          { staticClass: "p-3" },
                          _vm._l(filter.options, function (option, index) {
                            return _c(
                              "div",
                              { staticClass: "m-3" },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { row: "", wrap: "" } },
                                  [
                                    _c("v-col", { attrs: { cols: "3" } }, [
                                      _c(
                                        "label",
                                        { attrs: { for: _vm.filter_index } },
                                        [_vm._v(_vm._s(option.title) + ":")]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("v-col", { attrs: { cols: "9" } }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.selectedFilters,
                                            expression: "selectedFilters",
                                          },
                                        ],
                                        key: index,
                                        attrs: {
                                          type: "checkbox",
                                          id: "filter_" + index,
                                          label: option.title,
                                          multiple: "",
                                        },
                                        domProps: {
                                          value: option,
                                          checked: option.isSelected,
                                          checked: Array.isArray(
                                            _vm.selectedFilters
                                          )
                                            ? _vm._i(
                                                _vm.selectedFilters,
                                                option
                                              ) > -1
                                            : _vm.selectedFilters,
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$a = _vm.selectedFilters,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = option,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.selectedFilters =
                                                      $$a.concat([$$v]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.selectedFilters = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.selectedFilters = $$c
                                              }
                                            },
                                            function ($event) {
                                              return _vm.checkSelected(option)
                                            },
                                          ],
                                        },
                                      }),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-btn",
                {
                  staticClass: "ies-green",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      return _vm.saveCollection()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("save")]), _vm._v(" Save")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ies-coral",
                  on: {
                    click: function ($event) {
                      return _vm.deleteCollection()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("delete")]), _vm._v(" Delete")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { onClick: "window.print()" } },
                [_c("v-icon", [_vm._v("print")]), _vm._v(" Print")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  on: {
                    click: function ($event) {
                      return _vm.emailLink()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("email")]), _vm._v(" Email")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { href: "/collections/list" } },
                [_c("v-icon", [_vm._v("view_list")]), _vm._v(" List")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  on: {
                    click: function ($event) {
                      return _vm.reportProblem()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("report_problem")]), _vm._v(" Problem")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }