
<template>
    <tr>
        <td class="roomName header-cell interactive font-weight-bold"
            @mouseenter="handleRowMouseEnter(roomType)"
            @mousedown="handleRowMouseDown(roomType)"
            @mouseup="handleCellMouseUp(roomType)"
            :disabled="isDisabled"
            >
            {{ roomType.name }}
        </td>
        
        <template v-for="(availabilityDate) in roomType.availabilityPerDate">
            <td v-bind:class="{ 
                    'interactive': true, 
                    'availability-for-date': true,
                    'room-available': availabilityDate.status_key == 41, 
                    'room-on-request': availabilityDate.status_key == 42, 
                    'room-unavailable': availabilityDate.status_key == 43 || availabilityDate.status_key == 44 || availabilityDate.quantity == 0,
                    'availability-not-specified': availabilityDate.status_key == null,
                    'selected': cellIsSelected(availabilityDate),
                    'updated': cellIsUpdated(availabilityDate),
                    'disabled-cell': dateIsOutOfRange(availabilityDate.date),
                    }"
                :title="`Availability for ${roomType.standard_occupancy} pax on ${availabilityDate.date}`"
                @mousedown="handleCellMouseDown(availabilityDate)"
                @mouseenter="handleCellMouseEnter(availabilityDate)"
                @mouseup="handleCellMouseUp(roomType)"    
                :disabled="isDisabled">
                {{ availabilityDate.quantity }}
        </td>
        </template>
    </tr>
</template>


                                      

<script>
/**
* A Vue component dedicated to letting the user control availability and rates per
* hotel room for a given date range
*
* Props:  hotelkey
*/

export default {
    name: 'hotel-planner-availability-row',
    props: {
        selectedRoomType: {
            type: Object,
            default: {}
        },

        roomType: {
            type: Object,
        },

        availabilityPerDateSaved: {
            type: Object,
        },

        isDisabled: {
            type: Boolean,
        },

        datesWithinRange: {
            type: Array,
        },

        availabilityDateMode: {
            type: Boolean,
        },

        selectedAvailabilityDates: {
            type: Array,
        },

        editingAvailabilityDates: {
            type: Array,
        },
    },

    methods: {
        handleCellMouseDown(availabilityDate){
            this.$emit('update:availabilityDateMode', true);

            this.$nextTick(() => {
                this.selectCell(availabilityDate);
            });
        },

        handleCellMouseUp(roomType){
            if (this.selectedAvailabilityDates.length == 0){
                return;
            }
                        
            this.$emit('update:selectedRoomType', roomType);
            this.$emit('openEditAvailabilityDates');            
        },

        handleRowMouseDown(roomType){
            this.$emit('update:availabilityDateMode', true);
            
            this.$nextTick(() => {
                this.selectRow(roomType);
            });
        },

        handleRowMouseEnter(roomType){
            if (this.availabilityDateMode){
                this.selectRow(roomType);
            }
        },

        handleCellMouseEnter(availabilityDate){
            if (this.availabilityDateMode){

                if (!this.cellIsSelected(availabilityDate)){
                    this.selectCell(availabilityDate);
                }
            }
        },

        cellIsSelected(availabilityDate){
            return this.selectedAvailabilityDates.includes(availabilityDate);
        },

        cellIsBeingEdited(availabilityDate){
            return this.editingAvailabilityDates.includes(availabilityDate);
        },

        selectCell(availabilityDate){
            let dateIsOutOfRange = this.dateIsOutOfRange(availabilityDate.date);
            let dateIsAlreadySelected = this.selectedAvailabilityDates.includes(availabilityDate);

            if (dateIsOutOfRange || dateIsAlreadySelected || !this.availabilityDateMode || this.isDisabled){

                return;
            }

            this.$emit('selectAvailabilityDate', availabilityDate);
        },

        selectRow(roomType){
            let allDates = Object.values(roomType.availabilityPerDate);

            allDates.forEach((a) => {
                this.selectCell(a);
            });
        },

        cellIsUpdated(availabilityDate){
            let quantityIsUpdated = this.availabilityPerDateSaved[availabilityDate.date].quantity != availabilityDate.quantity;
            let statusIsUpdated = this.availabilityPerDateSaved[availabilityDate.date].status_key != availabilityDate.status_key;
            
            return quantityIsUpdated || statusIsUpdated;
        },

        dateIsOutOfRange(date){
            let outOfRange = true
            date = new Date(date);
            this.datesWithinRange.forEach(rangeDate => {
                let sameYear = rangeDate.getFullYear() == date.getFullYear();
                let sameMonth = rangeDate.getMonth() == date.getMonth();
                let sameDay = rangeDate.getDate() == date.getDate();

                if (sameYear && sameMonth && sameDay){
                    outOfRange = false;
                }
            })
            return outOfRange;
        },

    },
}

</script>
