var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "grid-list-md": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _vm.reservation.show || 1
                        ? _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _vm._v(
                                      "\n                                   You have received a new reservation enquiry.  Please respond as soon as possible\n                              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ies-green",
                                          on: { click: _vm.chooseOfferQuote },
                                        },
                                        [_vm._v("Offer Quote")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ies-green",
                                          on: {
                                            click: _vm.chooseAlternativeDates,
                                          },
                                        },
                                        [_vm._v("Alternative Dates")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ies-green",
                                          on: {
                                            click: _vm.chooseAlternativeRooms,
                                          },
                                        },
                                        [_vm._v("Alternative Rooms")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ies-coral",
                                          on: {
                                            click: _vm.chooseNoAvailability,
                                          },
                                        },
                                        [_vm._v("No Availability")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.option == "offerQuote"
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c(
                                            "ies-reservation-administration-offer-quote",
                                            {
                                              attrs: {
                                                reservationkey:
                                                  _vm.reservationkey,
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.option == "alternativeDates"
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c(
                                            "ies-reservation-administration-alternative-dates",
                                            {
                                              attrs: {
                                                reservationkey:
                                                  _vm.reservationkey,
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.option == "alternativeRooms"
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c(
                                            "ies-reservation-administration-alternative-rooms",
                                            {
                                              attrs: {
                                                reservationkey:
                                                  _vm.reservationkey,
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.option == "noAvailability"
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c(
                                            "ies-reservation-administration-no-availability",
                                            {
                                              attrs: {
                                                reservationkey:
                                                  _vm.reservationkey,
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }