// initial state
const state = {
    user: {},
}

// getters
const getters = {
    /**
     * Return logged user
     * @param state
     * @returns []
     */
    user: state => {
        return state.user;
    },
}

// actions
const actions = {

    /**
     * Load logged in user from API
     * @param context
     */
    loadUser(context) {
        axios.get('/api/auth/user').then(function(response) {
            // check if API returned data
            if (response.status === 200 && response.data.user && response.data.user.user_key > 0) {
                // commit user
                context.commit('updateUser', response.data.user)
            }
        });
    },
}

// mutations
const mutations = {

    /**
     * update user state
     * @param state
     * @param menu
     */
    updateUser(state, user) {
        state.user = user;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}