<template>
    <v-flex>{{ type }}</v-flex>
</template>

<script>
/**
 * Outputs Yes or No according to output boolean value
 *
 */
export default {
    name: 'ies-yes-no',
    props: ['field', 'value'],
    data () {
        return {}
    },

    computed: {
        type: function(){
			return parseInt(this.value) ? "Yes" : "No";
        }
    }
}
</script>

