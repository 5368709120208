<template>
    <v-col md="6" lg="4" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">hotel</v-icon> Reservation Statistics
            </v-card-title>
            <div v-if="systemError">Sorry, this content is not working</div>
            <div v-if="!systemError && loading">Loading...</div>
            <div v-if="!systemError && !loading">
            <line-chart :chartdata="chartData" :options="chartOptions"></line-chart>
                <v-container fluid>
                    <v-row>
                        <v-col>
                            Total: {{ chartData.count_total }}
                        </v-col>
                        <v-col>
                            Instant: {{ chartData.count_instant }}
                        </v-col>
                        <v-col>
                            On Request: {{ chartData.count_request }}
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-card>
    </v-col>
</template>

<script>
    import LineChart from '@/components/charts/LineChart';
    import axios from 'axios';
    /**
     * Display reservation statistics to the user
     */
    export default {
        /**
         * Data passed into the component from the view itself via attributes on its tag
         */
        props:['datasource', 'pageid'],
        components: {
            LineChart,
        },

        data() {
            return {
                loading: true,
                systemError: false,
                apiData:  {},
                chartData: {},
                chartOptions: {
                    maintainAspectRatio: false,
                    responsive: true,
                    events: ['click'],
                    title: {
                        display: false,
                        text: ''
                    }
                },
                date:   {
                    from: new Date(new Date().setDate(new Date().getDate()-6)).toISOString().substr(0, 10),
                    to:   new Date().toISOString().substr(0, 10),
                },
                granularity: 'day'
            }
        },
        /**
         * Perform the following actions on startup
         */
        mounted() {
            if (this.pageid != 0){
                this.date.from = new Date(new Date().setDate(new Date().getDate()-365)).toISOString().substr(0, 10);
console.log("page id = ", this.pageid);
                this.granularity = 'month';
            }

            this.loadReservationStats();

            // Refresh periodically
            setInterval( this.loadReservationStats, 60000 );
        },

        methods: {
            /**
             * Load the latest data from the API
             */
            async loadReservationStats() {
                this.loading = true;
                const response = await axios.get('api/accounts/bookings/statistics', {
                   params: {
                        dateFrom: this.date.from,
                        dateTo:   this.date.to,
                        type:     'quotes-accepted',
                        hotelKey: this.pageid ? this.pageid : null,
                        granularity: this.granularity
                   }
                }).then(response => {
                    this.apiData = response.data;
                    this.buildDataObject();
                    this.loading = false;
                    this.systemError = false;
                }).catch(error => {
                    this.systemError = true;
                    console.log(error);
                });
            },
            /**
             * Build the data object required by the line chart using API data to
             * fill the datasets ofjects and lavels
             */
            buildDataObject() {
                let data = {
                    labels: [],
                    count_total: 0,
                    count_instant: 0,
                    count_request: 0,
                    datasets: [{
                        data: [],
                        label: "Value of Quotes Accepted (GBP)",
                        borderColor: "#C1CD23",
                        fill: false
                    }, {
                        data: [],
                        label: "Commissions Value (GBP)",
                        borderColor: "#088193",
                        fill: false
                    }
                    ]
                };
                for (let day of Object.entries(this.apiData)) {
                    // Chart data
                    data.labels.push(day[0]);
                    data.datasets[0].data.push(day[1].quote.sum.toFixed(2));
                    data.datasets[1].data.push(day[1].commission.sum.toFixed(2));

                    // Stats summary data
                    data.count_total   += day[1].count_total;
                    data.count_instant += day[1].count_instant;
                    data.count_request += day[1].count_request;
                }
                this.chartData = data;
            },
        },
    }
</script>

