var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "row-sm": "", column: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
            _c("label", { attrs: { for: "password" } }, [
              _vm._v("New Password"),
            ]),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.password,
                  expression: "data.password",
                },
              ],
              attrs: { type: "password", name: "password" },
              domProps: { value: _vm.data.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.data, "password", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
            _c("label", { attrs: { for: "confirmation" } }, [
              _vm._v("Password Confirmation"),
            ]),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.passwordConfirmation,
                  expression: "data.passwordConfirmation",
                },
              ],
              attrs: { type: "password", name: "confirmation" },
              domProps: { value: _vm.data.passwordConfirmation },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.data,
                    "passwordConfirmation",
                    $event.target.value
                  )
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ies-coral ies-dark-gray--text",
                  on: {
                    click: function ($event) {
                      return _vm.changePassword()
                    },
                  },
                },
                [_vm._v("Change Password")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: parseInt(6000),
            "multi-line": "",
            color: "ies-green",
          },
          model: {
            value: _vm.successSingleSnackbar,
            callback: function ($$v) {
              _vm.successSingleSnackbar = $$v
            },
            expression: "successSingleSnackbar",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "full-height full-width v-align-container text-xs-center",
            },
            [
              _c("h4", { staticClass: "ies-dark-gray--text v-align-div" }, [
                _vm._v("Password Changed"),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: parseInt(6000),
            "multi-line": "",
            color: "ies-coral",
          },
          model: {
            value: _vm.errorSnackbar,
            callback: function ($$v) {
              _vm.errorSnackbar = $$v
            },
            expression: "errorSnackbar",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "full-height full-width v-align-container text-xs-center",
            },
            [
              _c("h4", { staticClass: "ies-dark-gray--text v-align-div" }, [
                _vm._v(_vm._s(this.errorSnackbarText)),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }