<template>
    <v-container>
        <v-layout row wrap justify-center>
            <v-flex xs12 md4 class="quickMenuWrapper">

                <div class="quickMenuHead toolbar__content">
                    <div class="list__tile">Quick Menu Builder</div>
                </div>
                <v-expansion-panel expand>
                    <v-expansion-panel-content
                            v-for="(mainItem, i) in menu"
                            :key="i"
                    >
                        <div slot="header">
                            <div class="list__tile">
                                <div class="itemSortWrapper" @click.stop="">
                                    <v-icon @click="sortUp(mainItem, i)">keyboard_arrow_up</v-icon>
                                    <v-icon @click="sortDown(mainItem, i)">keyboard_arrow_down</v-icon>
                                </div>
                                <div class="list__tile__action">
                                    <v-icon left >&nbsp{{ mainItem.icon }}&nbsp</v-icon>
                                </div>
                                <div class="list__tile__content">
                                    <div class="list__tile__title">
                                        {{ mainItem.name}}
                                        <v-icon
                                                small
                                                class="ml-3 itemEdit"
                                                @click="editItem(mainItem, i)"
                                                @click.stop=""
                                                v-if="canEdit"
                                        >edit</v-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <v-card  class="subItemsTiles">

                            <div class="list__tile"
                                 v-if="mainItem.subItems.length"
                                 v-for="(subItem, k) in mainItem.subItems"
                                 :key="k"
                                 hide-actions

                            >
                                <div class="itemSortWrapper" @click.stop="">
                                    <v-icon @click="sortUp(subItem, k)">keyboard_arrow_up</v-icon>
                                    <v-icon @click="sortDown(subItem, k)">keyboard_arrow_down</v-icon>
                                </div>
                                <div class="list__tile__action">
                                    <v-icon left >&nbsp{{ subItem.icon }}&nbsp</v-icon>
                                </div>
                                <div class="list__tile__content">
                                    <div class="list__tile__title">
                                        {{ subItem.name}}
                                        <v-icon
                                                small
                                                class="ml-3 itemEdit"
                                                @click="editItem(subItem, k)"
                                                @click.stop=""
                                                v-if="canEdit"
                                        >edit</v-icon>
                                    </div>
                                </div>
                            </div>

                            <div class="addNewWrapper"
                                 @click="addNewItemDialog(2, mainItem)"
                                 v-if="canEdit"
                            >
                                <v-icon class="ml-3 itemEdit">add_circle_outline</v-icon> Add new item
                            </div>
                        </v-card>
                    </v-expansion-panel-content>
                    <div class="addNewWrapper"
                         @click="addNewItemDialog(1)"
                         v-if="canEdit"
                    >
                        <v-icon class="ml-3 itemEdit">add_circle_outline</v-icon> Add new item
                    </div>
                </v-expansion-panel>
            </v-flex>

        </v-layout>

        <v-btn
                large
                fixed
                bottom
                right
                class="ies-green"
                v-on:click="saveChanges"
                v-if="canEdit"
        >SAVE</v-btn>

        <!-- Dialog -->
        <v-dialog v-model="dialog" max-width="900px">
            <v-card>
                <v-card-title>
                    <span class="headline">Edit menu item</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-layout row wrap v-if="editableItem.hasOwnProperty('item')">
                            <v-flex xs12 sm6 mr-1>
                                <v-text-field
                                        label="Text"
                                        v-model="editableItem.item.name"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 mr-1>
                                <v-text-field
                                        label="Icon"
                                        v-model="editableItem.item.icon"
                                ></v-text-field>

                            </v-flex>
                            <v-flex xs6 sm2 ml-3>
                                <a href="https://material.io/resources/icons/?style=baseline" target="_blank">
                                    Available icons
                                </a>
                            </v-flex>
                            <v-flex xs12>
                                <v-layout row wrap>
                                    <v-flex xs3>
                                        <v-radio-group label="Link type" v-model="editableItem.item.link.type">
                                            <v-radio label="laravel route" :value="this.consts.TYPE_ROUTE_NAME"></v-radio>
                                            <v-radio label="relative path" :value="this.consts.TYPE_RELATIVE_PATH"></v-radio>
                                        </v-radio-group>
                                    </v-flex>
                                    <v-flex xs4 mr-3>
                                        <v-text-field
                                                :label="editableItem.item.link.type == this.consts.TYPE_ROUTE_NAME ? 'Laravel route name' : 'Relative path'"
                                                v-model="editableItem.item.link.route"
                                                :hint="editableItem.item.link.type == this.consts.TYPE_ROUTE_NAME ? 'Laravel route name format' : 'Relative path must starts with /'"
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex xs3 v-if="editableItem.item.link.type == this.consts.TYPE_ROUTE_NAME">
                                        <v-text-field
                                                label="Route parameters"
                                                v-model="editableItem.item.link.params"
                                                hint="Route params comma delimited, eg.: Reservation, cancelled"
                                        ></v-text-field>
                                    </v-flex>
                                </v-layout>

                            </v-flex>
                            <v-flex xs12 sm6 mr-1>
                                <v-select
                                        label="Permission Content Type"
                                        :items="permissions"
                                        v-model="editableItem.item.portal_content_type_key"
                                        item-text="name"
                                        item-value="portal_content_type_key"
                                        autocomplete
                                ></v-select>
                            </v-flex>
                        </v-layout>

                        <v-layout row class="margin-top-medium" >
                            <v-flex xs12 sm6 mt-3>
                                <v-expansion-panel >
                                    <v-expansion-panel-content>
                                        <div slot="header">Delete item</div>
                                        <v-card>
                                            <v-card-text>
                                                <v-layout row wrap>
                                                    <v-flex xs12>
                                                        Do you really want to delete this menu item?
                                                    </v-flex>
                                                    <v-flex xs12>
                                                        <v-btn color="error" @click="deleteItem">Yes, delete</v-btn>
                                                    </v-flex>
                                                    <v-flex xs12 mt-3>
                                                        Remember you still need to save the edit page to update your changes!
                                                    </v-flex>
                                                </v-layout>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" flat @click="closeDialog">Close</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" flat @click="addNewItem" v-if="editableItem.hasOwnProperty('isNew') && editableItem.isNew">Add</v-btn>
                    <v-btn color="blue darken-1" flat @click="updateEdited" v-else>Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!------- SUCCESS / ERROR SNACKBAR ------------->
        <v-snackbar
                :timeout="parseInt(6000)"
                v-model="snackbar.show"
                multi-line
                :color="snackbar.color"
        >
            <div class="full-height full-width v-align-container text-xs-center">
                <h4 class="ies-dark-gray--text v-align-div">{{ this.snackbar.text }}</h4>
            </div>
        </v-snackbar>
    </v-container>
</template>

<script>
    export default {
        name: 'MenuBuilderPage',
        props: ['access', 'menuItems', 'pageContents'],
        data() {
            return {
                menu: this.menuItems,
                editableItem: {},
                dialog: false,
                snackbar: {
                    show: false,
                    color: '', // ies-green -> success / ies-coral -> error
                    text: '',
                },
                consts: {
                    TYPE_ROUTE_NAME: 1,
                    TYPE_RELATIVE_PATH: 2,
                    HIERARCHY_MAIN: 1,
                    HIERARCHY_SUBITEM: 2,
                }
            }
        },
        computed: {
            /**
             * Portal content type permissions that we can assign to each menu item element
             * @return array
             */
            permissions() {
                // let firstDefault = {name: 'Everyone can see', portal_content_type_key: 0};
                let permissions = this.pageContents;
                // permissions.unshift(firstDefault);

                return permissions;
            },

            /**
             * Check if logged user got edit access to this page
             * @return bool
             */
            canEdit() {
                if (this.access === 2) {
                    return true;
                }
                return false;
            }
        },
        methods: {

            /**
             * Send entire menu to backend to update menu in database
             */
            saveChanges() {
                axios.post('api/menu/builder', { menu : this.menu })
                    .then(function(response) {
                        if (response.data.success) {
                            this.showSnackbar('success', 'Menu Updated');
                        }
                        else {
                            this.showSnackbar('error', 'There was an error updating the menu');
                        }
                    }.bind(this));
            },

            /**
             * Open menu item edit dial box
             */
            editItem(item, currentIndex) {
                // default link object, as fallback if current item has incorrect link set
                let linkObject = {type: this.consts.TYPE_ROUTE_NAME, route: '', params: ''};

                // decode link JSON, check if link contains correct link object, if so use it
                if (item.link && item.link !== '' && this.isJSON(item.link)) {
                    let linkParsed = JSON.parse(item.link);
                    if (linkParsed.hasOwnProperty('type') && linkParsed.hasOwnProperty('route')) {

                        // change param array to param string to easier interaction
                        let paramsString = '';

                        if (linkParsed.hasOwnProperty('params') && Array.isArray(linkParsed.params) && linkParsed.params.length) {
                            paramsString = linkParsed.params.join();
                        }

                        linkParsed.params = paramsString;

                        linkObject = linkParsed;
                    }
                }

                // set new link with object decoded
                item.link = linkObject;

                // set editable item
                this.editableItem.item = item;
                this.editableItem.index = currentIndex;
                this.dialog = true;
            },

            /**
             * Update state of just edited menu item,
             * at the end close dialog and clear editable item
             */
            updateEdited() {
                if (this.editableItem.hasOwnProperty('item') && this.editableItem.hasOwnProperty('index')) {
                    this.parseLinkObject();

                    // check if it's main menu item or child
                    if (this.editableItem.item.hierarchy === this.consts.HIERARCHY_MAIN) {
                        this.$set(this.menu, this.editableItem.index, this.editableItem.item);
                    }
                    else {
                        // find parent menu item
                        this.menu.forEach((parent, i) => {
                            if (this.editableItem.item.belongs_to === parent.quick_menu_item_key) {
                                this.$set(this.menu[i].subItems, this.editableItem.index, this.editableItem.item);
                            }
                        });
                    }
                }

                // clear dialog
                this.editableItem = {};
                this.dialog = false;
            },

            /**
             * Delete menu item from menu list
             */
            deleteItem() {
                if (this.editableItem.hasOwnProperty('item') && this.editableItem.hasOwnProperty('index')) {
                    // check if it's main menu item or child
                    if (this.editableItem.item.hierarchy === this.consts.HIERARCHY_MAIN) {
                        this.$delete(this.menu, this.editableItem.index, this.editableItem.item);
                    }
                    else {
                        // find parent menu item
                        this.menu.forEach((parent, i) => {
                            if (this.editableItem.item.belongs_to === parent.quick_menu_item_key) {
                                this.$delete(this.menu[i].subItems, this.editableItem.index, this.editableItem.item);
                            }
                        });
                    }

                    // clear dialog
                    this.editableItem = {};
                    this.dialog = false;
                }
            },

            /**
             * Move this menu item one item up
             */
            sortUp(item, currentIndex) {
                //check if we can move it up
                if (currentIndex > 0) {
                    // check hierarchy
                    if (item.hierarchy === this.consts.HIERARCHY_MAIN) {
                        // move up in main level hierarchy
                        let newIndex = currentIndex - 1;
                        let itemAbove = this.menu[newIndex];
                        let currentOrder = item.order;

                        // change sort orders
                        item.order = itemAbove.order;
                        itemAbove.order = currentOrder;

                        // update list
                        this.$set(this.menu, newIndex, item);
                        this.$set(this.menu, currentIndex, itemAbove);
                    }
                    else {
                        // move up in sub-menu level hierarchy
                        // find main menu item of this child item
                        this.menu.forEach((parent, i) => {
                            if (item.belongs_to === parent.quick_menu_item_key) {

                                let newIndex = currentIndex - 1;
                                let itemAbove = this.menu[i].subItems[newIndex];
                                let currentOrder = item.order;

                                // change sort orders
                                item.order = itemAbove.order;
                                itemAbove.order = currentOrder;

                                // update list
                                this.$set(this.menu[i].subItems, newIndex, item);
                                this.$set(this.menu[i].subItems, currentIndex, itemAbove);
                            }
                        });
                    }
                }
            },

            /**
             * Move this menu item one item down
             */
            sortDown(item, currentIndex) {
                // check if we can move it down
                if (currentIndex < this.menu.length) {
                    // check hierarchy
                    if (item.hierarchy === this.consts.HIERARCHY_MAIN) {
                        // move down in main level hierarchy
                        let newIndex = currentIndex + 1;
                        let itemLower = this.menu[newIndex];
                        let currentOrder = item.order;

                        // change sort orders
                        item.order = itemLower.order;
                        itemLower.order = currentOrder;
                        // update list
                        this.$set(this.menu, newIndex, item);
                        this.$set(this.menu, currentIndex, itemLower);
                    }
                    else {
                        // move down in sub-menu level hierarchy
                        // find main menu item of this child item
                        this.menu.forEach((parent, i) => {
                            if (item.belongs_to === parent.quick_menu_item_key) {

                                let newIndex = currentIndex + 1;
                                let itemLower = this.menu[i].subItems[newIndex];
                                let currentOrder = item.order;

                                // change sort orders
                                item.order = itemLower.order;
                                itemLower.order = currentOrder;

                                // update list
                                this.$set(this.menu[i].subItems, newIndex, item);
                                this.$set(this.menu[i].subItems, currentIndex, itemLower);
                            }
                        });
                    }
                }
            },

            /**
             * Add new menu item
             * @param hierarchy - target hierarchy where we want to add new item
             * @param {} mainItem - mainItem of sub-item that is being added, undefined when adding main element
             */
            addNewItemDialog(hierarchy, mainItem) {
                // create blank editable item object
                this.editableItem = {
                    item: {
                        name: '',
                        icon: '',
                        link: {type: this.consts.TYPE_ROUTE_NAME, route: '', params: ''},
                        hierarchy: hierarchy,
                        portal_content_type_key: 0,
                        isNew: true,
                    },
                    index: 0,
                    isNew: true,
                };

                // if this is sub menu item, add info to what parent item this child item should belongs to
                if (hierarchy === this.consts.HIERARCHY_SUBITEM && mainItem && mainItem.hierarchy === this.consts.HIERARCHY_MAIN) {
                    this.editableItem.item.belongs_to = mainItem.quick_menu_item_key;

                    // get the last sort order
                    let lastItem = mainItem.subItems[mainItem.subItems.length - 1];
                    let nextOrder = 1;

                    if (lastItem) {
                        nextOrder = lastItem.order + 1;
                    }

                    // set new order
                    this.editableItem.item.order = nextOrder;
                }
                else if (hierarchy === 1) {
                    // get the last sort order
                    let lastItem = this.menu[this.menu.length - 1];
                    let nextOrder = 1;

                    if (lastItem) {
                        let nextOrder = lastItem.order + 1;
                    }

                    // set new order
                    this.editableItem.item.order = nextOrder;
                    this.editableItem.item.belongs_to = null;
                }

                this.dialog = true;
            },

            /**
             * Validate if new menu item can be added
             * @return bool
             */
            validateNewItem() {

                // check main properties
                if (this.editableItem.hasOwnProperty('item') && this.editableItem.hasOwnProperty('isNew') && this.editableItem.isNew && this.canEdit) {
                    let item = this.editableItem.item;

                    if (!item.name) {
                        alert('Name must be set');
                        return false;
                    }

                    if (!item.icon) {
                        alert('Icon must be set');
                        return false;
                    }

                    if (!item.hasOwnProperty('portal_content_type_key')) {
                        alert('Permission content type must be set');
                        return false;
                    }

                    return true;
                }
                else {
                    alert('Invalid data');
                }

                return false;
            },

            /**
             * Check if given string is json
             */
            isJSON(str) {
                try {
                    let json = JSON.parse(str);
                    return (typeof json === 'object');
                } catch (e) {
                    return false;
                }
            },

            /**
             * Validate new item, and add it at the end of menu list
             * first check if this item should be added to main menu list (hierarchy ===1) or it's sub menu item
             * and should be added to main item sub menu list
             */
            addNewItem() {
                if (this.validateNewItem()) {
                    this.parseLinkObject();
                    let newItem = this.editableItem.item;

                    // add this item to list
                    if (this.editableItem.item.hierarchy === this.consts.HIERARCHY_MAIN) {
                        // add it to main menu list
                        this.menu.push(newItem);
                        this.editableItem = {};
                    }
                    else if (this.editableItem.item.hierarchy === this.consts.HIERARCHY_SUBITEM) {
                        // find parent list and add it as sub menu list
                        this.menu.forEach((parent, i) => {
                            if (newItem.belongs_to === parent.quick_menu_item_key) {
                                this.menu[i].subItems.push(newItem);
                                this.editableItem = {};
                            }
                        })
                    }

                    this.dialog = false;
                }
            },

            /**
             * Parse link object to string so it can be easily saved in database
             */
            parseLinkObject() {
                if (this.editableItem.hasOwnProperty('item') && this.editableItem.item.hasOwnProperty('link')) {
                    let link = this.editableItem.item.link;

                    // empty link
                    if (link === '') {
                        this.editableItem.item.link = JSON.stringify({type: this.consts.TYPE_ROUTE_NAME, route: '', params: []});
                    }
                    // laravel route
                    else if (link.hasOwnProperty('type') && link.type == this.consts.TYPE_ROUTE_NAME) {
                        let params = [];
                        if (link.params) {
                            // trim and remove whitespaces
                            link.params = link.params.trim();
                            link.params = link.params.replace(" ", "");
                            params = link.params.split(",");
                        }

                        // make sure route is not null but string
                        if (!link.route) {
                            link.route = '';
                        }

                        this.editableItem.item.link = JSON.stringify({
                            type: this.consts.TYPE_ROUTE_NAME,
                            route: link.route.trim(),
                            params: params,
                        })
                    }
                    // relative path
                    else if (link.hasOwnProperty('type') && link.type == this.consts.TYPE_RELATIVE_PATH) {
                        this.editableItem.item.link = JSON.stringify({
                            type: this.consts.TYPE_RELATIVE_PATH,
                            route: link.route.trim(),
                            params: [],
                        });
                    }
                }
            },

            /**
             * Close edit dialog
             */
            closeDialog() {
                this.editableItem = {};
                this.dialog = false;
            },

            /**
             *  Display success / error snackbar
             */
            showSnackbar(type, text) {
                if (type === 'success') {
                    this.snackbar.color = 'ies-green';
                }
                else if (type === 'error') {
                    this.snackbar.color = 'ies-coral';
                }
                this.snackbar.text = text;
                this.snackbar.show = true;
            },
        },
    }
</script>

<style scoped lang="scss">
    .quickMenuWrapper {

        .quickMenuHead {
            height: 64px;
            background-color: #f5f5f5;
            border-bottom: 1px solid lightgrey;

            .list__tile {
                font-weight: bold;
                margin: 0 auto;
            }
        }

        i.itemEdit {
            color: green!important;
        }

        .itemSortWrapper {
            display: inline-block;
            width: 20px;
            height: 45px;
            margin-right: 20px;

            i {
                font-size: 15px!important;
            }
        }

        .list__tile {
            padding-left:0;
        }

        .subItemsTiles {
            padding-left:60px;
        }

        .addNewWrapper {
            margin: 8px 0;
        }

        .addNewWrapper:hover {
            cursor:pointer;
        }

        i:hover {
            cursor: pointer!important;
        }
    }
</style>