var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "3" } },
    [
      _c(
        "v-card",
        { staticClass: "ma-1" },
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("add_circle")]),
              _vm._v(" Plus\n    "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-btn", { attrs: { block: "", href: "/plus-tile/list" } }, [
                _vm._v("Plus Tiles"),
              ]),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { block: "", href: "/plus-partner-offer/list" } },
                [_vm._v("Partner Offers")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { block: "", href: "/plus-secret-sale/list" } },
                [_vm._v("Secret Sales")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }