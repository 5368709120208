/**
 * Mixin that holds reusable logic for v-progress-linear bar
 *
 * How to use:
 * 1. import this mixin to your component:  eg. import {progressBar} from '@/mixins/progressBar';
 * 2. register mixin in your component:         mixins: [progressBar],
 * 3. enter progress bar to your component html template, example:
 *      <div id="linearWrapper">
 *          <v-progress-linear :indeterminate="true" v-show="showProgressIcon" color="lime"></v-progress-linear>
 *      </div>
 *
 * You can edit progress-linear options but you should keep it wrapped in linearWrapper div and keep v-show property as it is
 *
 */
export const progressBar = {
    data () {
        return {
            /** @var determines whether option progress bar should be displayed */
            showProgressIcon: false,
        }
    },
    /**
     * Code that runs when component was created
     */
    created(){
        // alert('mixin has been hooked!'); // You can uncommend that to check if the mixin was hooked successfully
    },
    methods:{
        /**
         * Shows progress bar
         */
        progressIconShow: function() {
            this.showProgressIcon = true;
        },

        /**
         * Hides progress bar
         */
        progressIconHide: function() {
            this.showProgressIcon = false;
        },
    }
}