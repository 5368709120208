var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "2", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("join_full")]),
              _vm._v(" Setup \n        "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: { block: "", href: _vm.travelOperators },
                            },
                            [_vm._v("Travel Operator")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: { block: "", href: _vm.displayStatus },
                            },
                            [_vm._v("Display Status")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: { block: "", href: _vm.visitStatus },
                            },
                            [_vm._v("Visit Status")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: { block: "", href: _vm.contract },
                            },
                            [_vm._v("Contract and Format")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: { block: "", href: _vm.bookingSystem },
                            },
                            [_vm._v("Booking System")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: { block: "", disabled: "", href: "" },
                            },
                            [_vm._v("Commission")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: { block: "", href: _vm.currency },
                            },
                            [_vm._v("Currency")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: { block: "", disabled: "", href: "" },
                            },
                            [_vm._v("Permissions")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }