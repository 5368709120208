var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("v-select", {
            attrs: {
              label: _vm.field.schema.displayName,
              items: _vm.regions,
              autocomplete: "",
            },
            model: {
              value: _vm.viewValue,
              callback: function ($$v) {
                _vm.viewValue = $$v
              },
              expression: "viewValue",
            },
          }),
          _vm._v(" "),
          _c("h5", [_vm._v(_vm._s(_vm.country))]),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: _vm.field.fieldName },
            domProps: { value: _vm.field.value },
          }),
          _vm._v(" "),
          _vm.field.errors
            ? _c(
                "v-alert",
                {
                  staticStyle: { width: "100%" },
                  attrs: { type: "error", value: _vm.field.errors },
                },
                _vm._l(_vm.field.errors, function (error) {
                  return _c("li", [
                    _vm._v(
                      "\n            \t\t    " +
                        _vm._s(error) +
                        "\n\t            \t"
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }