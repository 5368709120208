var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "white-out",
        on: {
          click: function ($event) {
            return _vm.close()
          },
        },
      },
      [_vm._v(" ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "edit-form",
        on: {
          keyup: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.submitEditForm()
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.close()
            },
          ],
        },
      },
      [
        _c("h2", [_vm._v(_vm._s(_vm.editForm.title))]),
        _vm._v(" "),
        _vm.selectedDateFrom && _vm.selectedDateUntil
          ? _c("p", [
              _c("strong", [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.selectedDateRange) +
                    "\n            "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.editForm.taskDescription))]),
        _vm._v(" "),
        _vm.editForm.errorMessage
          ? _c(
              "p",
              { staticClass: "red--text" },
              [
                _c("v-icon", { attrs: { color: "red" } }, [_vm._v("warning")]),
                _vm._v(" " + _vm._s(_vm.editForm.errorMessage) + ".\n        "),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.openEditAvailabilityDates || _vm.openEditRateDates
          ? _c(
              "div",
              { staticClass: "display-flex mt-3 mb-3" },
              [
                _vm._l(_vm.weekdays, function (day, key) {
                  return [
                    _c("span", { staticClass: "mr-2" }, [
                      _c("label", { attrs: { for: key } }, [
                        _vm._v(_vm._s(day)),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedWeekdays[key],
                            expression: "selectedWeekdays[key]",
                          },
                        ],
                        attrs: { type: "checkbox", id: key },
                        domProps: {
                          checked: Array.isArray(_vm.selectedWeekdays[key])
                            ? _vm._i(_vm.selectedWeekdays[key], null) > -1
                            : _vm.selectedWeekdays[key],
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.selectedWeekdays[key],
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.selectedWeekdays,
                                    key,
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.selectedWeekdays,
                                    key,
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.selectedWeekdays, key, $$c)
                            }
                          },
                        },
                      }),
                    ]),
                  ]
                }),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.openEditAvailabilityDates
          ? _c(
              "div",
              [
                _vm._v(
                  "\n            Number of " +
                    _vm._s(_vm.selectedRoomType.name) +
                    " rooms availabile on these dates (" +
                    _vm._s(_vm.selectedRoomType.totalNumRooms) +
                    " maximum):\n            "
                ),
                _vm._v(" "),
                _vm.newAvailability > _vm.selectedRoomType.totalNumRooms
                  ? _c(
                      "div",
                      { staticClass: "red--text mt-2" },
                      [
                        _c("v-icon", { attrs: { color: "red" } }, [
                          _vm._v("warning"),
                        ]),
                        _vm._v(
                          " Availability exceeds total rooms.\n            "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("v-text-field", {
                  attrs: { placeholder: "Enter availability", autofocus: "" },
                  model: {
                    value: _vm.newAvailability,
                    callback: function ($$v) {
                      _vm.newAvailability = $$v
                    },
                    expression: "newAvailability",
                  },
                }),
                _vm._v("\n\n            Availability Status:\n            "),
                _c("v-select", {
                  attrs: {
                    items: _vm.availabilityStatuses,
                    placeholder: "Availability status",
                  },
                  model: {
                    value: _vm.newAvailabilityStatus,
                    callback: function ($$v) {
                      _vm.newAvailabilityStatus = $$v
                    },
                    expression: "newAvailabilityStatus",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.openEditRateDates
          ? _c("div", [
              _vm.selectedRateColumn == "min_stay" && _vm.minStayAboveMax()
                ? _c(
                    "div",
                    { staticClass: "red--text mt-2" },
                    [
                      _c("v-icon", { attrs: { color: "red" } }, [
                        _vm._v("warning"),
                      ]),
                      _vm._v(" Min stay exceeds max stay.\n            "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedRateColumn == "max_stay" && _vm.maxStayBelowMin()
                ? _c(
                    "div",
                    { staticClass: "red--text mt-2" },
                    [
                      _c("v-icon", { attrs: { color: "red" } }, [
                        _vm._v("warning"),
                      ]),
                      _vm._v(" Max stay under min stay.\n            "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedRateColumnType == "rate"
                ? _c(
                    "div",
                    [
                      _vm._v(
                        "\n                Rates for these dates (" +
                          _vm._s(_vm.currencyCode) +
                          "):\n                "
                      ),
                      _c("v-text-field", {
                        attrs: { placeholder: "Enter rate", autofocus: "" },
                        model: {
                          value: _vm.newRate,
                          callback: function ($$v) {
                            _vm.newRate = $$v
                          },
                          expression: "newRate",
                        },
                      }),
                      _vm._v(" "),
                      _vm.selectedRateColumn == "rate_standard"
                        ? _c("v-select", {
                            attrs: {
                              placeholder: "Rate status",
                              items: _vm.rateStatuses,
                            },
                            model: {
                              value: _vm.rateStatus,
                              callback: function ($$v) {
                                _vm.rateStatus = $$v
                              },
                              expression: "rateStatus",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedRateColumnType == "misc"
                ? _c(
                    "div",
                    [
                      _vm._v(
                        "\n                Enter value:\n                "
                      ),
                      _c("v-text-field", {
                        attrs: { placeholder: "Enter value", autofocus: "" },
                        model: {
                          value: _vm.newRate,
                          callback: function ($$v) {
                            _vm.newRate = $$v
                          },
                          expression: "newRate",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedRateColumnType == "bool"
                ? _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newCheckInOutValue,
                          expression: "newCheckInOutValue",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.newCheckInOutValue)
                          ? _vm._i(_vm.newCheckInOutValue, null) > -1
                          : _vm.newCheckInOutValue,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.newCheckInOutValue,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.newCheckInOutValue = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.newCheckInOutValue = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.newCheckInOutValue = $$c
                          }
                        },
                      },
                    }),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.openEditUniversalRates
          ? _c("div", [
              _vm.selectedRateColumnType == "misc"
                ? _c(
                    "div",
                    [
                      _vm._v(
                        "\n                Enter value (" +
                          _vm._s(_vm.currencyCode) +
                          "):\n                "
                      ),
                      _c("v-text-field", {
                        attrs: { placeholder: "Enter value", autofocus: "" },
                        model: {
                          value: _vm.newRate,
                          callback: function ($$v) {
                            _vm.newRate = $$v
                          },
                          expression: "newRate",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c(
              "v-btn",
              {
                on: {
                  click: function ($event) {
                    return _vm.close()
                  },
                },
              },
              [_vm._v("Cancel")]
            ),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                staticClass: "lime",
                on: {
                  click: function ($event) {
                    return _vm.submitEditForm()
                  },
                },
              },
              [_vm._v("Update")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }