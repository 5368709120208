var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-flex",
    [
      _vm._l(_vm.visualisationList, function (visualisation, index) {
        return _c(
          "v-flex",
          { key: index },
          [
            visualisation.type == "dataTable"
              ? _c(
                  "v-flex",
                  [
                    _c("ies-list", {
                      attrs: {
                        datasource: "pageData0",
                        sectionindex: _vm.sectionindex,
                        viewid: index,
                        title: "Not Yet Set",
                        config: visualisation,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            visualisation.type == "chartBar"
              ? _c(
                  "v-flex",
                  { staticClass: "mt-3", attrs: { xs8: "" } },
                  [
                    _c(
                      "v-card",
                      [
                        _c("ies-chart-bar", {
                          attrs: {
                            datasource: "pageData0",
                            sectionindex: _vm.sectionindex,
                            viewid: index,
                            config: visualisation,
                            content: _vm.content,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            visualisation.type == "chartLine"
              ? _c(
                  "v-flex",
                  { staticClass: "mt-3", attrs: { xs8: "" } },
                  [
                    _c(
                      "v-card",
                      [
                        _c("ies-chart-line", {
                          attrs: {
                            datasource: "pageData0",
                            sectionindex: _vm.sectionindex,
                            viewid: index,
                            config: visualisation,
                            content: _vm.content,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            visualisation.type == "chartPie"
              ? _c(
                  "v-flex",
                  { staticClass: "mt-3", attrs: { xs8: "" } },
                  [
                    _c(
                      "v-card",
                      [
                        _c("ies-chart-pie", {
                          attrs: {
                            datasource: "pageData0",
                            sectionindex: _vm.sectionindex,
                            viewid: index,
                            config: visualisation,
                            content: _vm.content,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            visualisation.type == "chartBubble"
              ? _c(
                  "v-flex",
                  { staticClass: "mt-3", attrs: { xs8: "" } },
                  [
                    _c(
                      "v-card",
                      [
                        _c("ies-chart-bubble", {
                          attrs: {
                            datasource: "pageData0",
                            sectionindex: _vm.sectionindex,
                            viewid: index,
                            config: visualisation,
                            content: _vm.content,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            visualisation.type == "chartPolar"
              ? _c(
                  "v-flex",
                  { staticClass: "mt-3", attrs: { xs8: "" } },
                  [
                    _c(
                      "v-card",
                      [
                        _c("ies-chart-polar", {
                          attrs: {
                            datasource: "pageData0",
                            sectionindex: _vm.sectionindex,
                            viewid: index,
                            config: visualisation,
                            content: _vm.content,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            visualisation.type == "chartRadar"
              ? _c(
                  "v-flex",
                  { staticClass: "mt-3", attrs: { xs8: "" } },
                  [
                    _c(
                      "v-card",
                      [
                        _c("ies-chart-radar", {
                          attrs: {
                            datasource: "pageData0",
                            sectionindex: _vm.sectionindex,
                            viewid: index,
                            config: visualisation,
                            content: _vm.content,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            visualisation.type == "chartDoughnut"
              ? _c(
                  "v-flex",
                  { staticClass: "mt-3", attrs: { xs8: "" } },
                  [
                    _c(
                      "v-card",
                      [
                        _c("ies-chart-doughnut", {
                          attrs: {
                            datasource: "pageData0",
                            sectionindex: _vm.sectionindex,
                            viewid: index,
                            config: visualisation,
                            content: _vm.content,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.visualisations.length == 0
        ? _c("v-flex", { staticClass: "mt-3", attrs: { xs12: "" } }, [
            _vm._v(
              "\n        There is nothing to display.  Please use the buttons on the option bars to choose how you would like to visualise this data\n    "
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }