<template>
    <v-col cols="12">
        <v-btn class="mt-1" :href="reservationsUrl"><v-icon>hotel</v-icon></v-btn>
        <v-btn class="mt-1" :href="editUrl"><v-icon>edit</v-icon></v-btn>
        <v-btn class="mt-1" :href="facilitiesUrl"><v-icon>pool</v-icon></v-btn>
        <v-btn class="mt-1" :href="imageLibraryUrl"><v-icon>insert_photo</v-icon></v-btn>
        <v-btn class="mt-1" :href="cloneRateDatesUrl"><v-icon>content_copy</v-icon></v-btn>
        <v-btn class="mt-1" :href="dailyPlannerUrl"><v-icon>edit_calendar</v-icon></v-btn>
        <v-btn class="mt-1" :href="viewLiveUrl"><v-icon>web</v-icon></v-btn>
        <v-btn class="mt-1" :href="reservationsUrl" disabled><v-icon>hotel</v-icon></v-btn>
        <v-btn class="mt-1" :href="reservationsUrl" disabled><v-icon>hotel</v-icon></v-btn>
    </v-col>
</template>

<script>
    /**
     * Display buttons linking through to a hotels' pages
     */
    export default {
        props:['pageid'],

        data: function() {
            return {
                reservationsUrl: '',
                editUrl: '',
                cloneRateDatesUrl: '',
                dailyPlannerUrl: '',
                facilitiesUrl: '',
                imageLibraryUrl: '',
                viewLiveUrl: ''
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            this.reservationsUrl = "/hotel/" + this.pageid + "/reservation-administration";
            this.editUrl = "/hotels/" + this.pageid;
            this.cloneRateDatesUrl = "/hotel/" + this.pageid + "/clone-rate-dates";
            this.dailyPlannerUrl = "/hotel/" + this.pageid + "/planner";
            this.facilitiesUrl = "/hotel/" + this.pageid + "/facility-management";
            this.imageLibraryUrl = "/photo-library?hotelKey=" + this.pageid;
            this.viewLiveUrl = "https://www.i-escape.com";

            this.loadHotel();
        },

        methods: {
            loadHotel: function(){
                axios.get('/api/hotels/' + this.pageid)
                .then(function(response){
console.log(response.data);
                    this.viewLiveUrl = "https://www.i-escape.com/" + response.data.main_url_name.url_name;
                }.bind(this));
            }
        },

        computed: {}
    }
</script>
