var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mt-3 mb-3 no-print button-bar", attrs: { xs12: "" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            _vm._l(_vm.fields, function (field) {
              return _c(
                "v-flex",
                { key: field.customComponent, attrs: { xs12: "" } },
                [
                  field.displayType == "custom"
                    ? _c(
                        "v-flex",
                        [
                          _c(_vm.getCustomComponent(field.customComponent), {
                            tag: "component",
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }