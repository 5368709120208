<template>
    <v-col md="6" lg="4" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">email</v-icon> Email Statistics
            </v-card-title>
            <line-chart :chartdata="chartData" :options="chartOptions"></line-chart>
        </v-card>
    </v-col>
</template>

<script>
    import LineChart from '@/components/charts/LineChart';
    import axios from 'axios';
    /**
     * Display reservation statistics to the user
     */
    export default {
        /**
         * Data passed into the component from the view itself via attributes on its tag
         */
        props:['datasource'],
        components: {
            LineChart,
        },

        data() {
            return {
                apiData:  {},
                chartData: {},
                chartOptions: {
                    maintainAspectRatio: false,
                    responsive: true,
                    events: ['click'],
                    title: {
                        display: false,
                        text: ''
                    }
                },
            }
        },
        /**
         * Perform the following actions on startup
         */
        mounted() {
            this.loadEmailStats();
            setInterval( this.loadEmailStats, 60000 );
        },

        methods: {
            /**
             * Load the latest data from the API
             */
            async loadEmailStats() {
                const response = await axios.get('api/email-statistics')
                .then(response => {
console.log("email stats ", response.data.stats);
                    this.apiData = response.data.stats;
                    this.buildDataObject();
                }).catch(error => {
                    console.log(error);
                });
            },
            /**
             * Build the data object required by the line chart using API data to
             * fill the datasets ofjects and lavels
             */
             buildDataObject() {
                let data = {
                    labels: [],
                    datasets: [
                      {
                          data: [],
                          label: "No. Successful Emails",
                          borderColor: "#C1CD23",
                          fill: false
                      },
                      {
                          data: [],
                          label: "No. Failed Emails",
                          borderColor: "#088193",
                          fill: false
                      }
                    ]
                };
                for (let day of Object.entries(this.apiData)) {
                    // Chart data
                    data.labels.push(day[0]);
                    data.datasets[0].data.push(day[1].successful);
                    data.datasets[1].data.push(day[1].failed);
                }
                this.chartData = data;
            },
        },
    }
</script>
