var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "row-sm": "" } },
        [
          _c("v-flex", { attrs: { xs12: "", sm1: "" } }, [
            _c("label", [_vm._v(_vm._s(this.field.schema.displayName) + ":")]),
          ]),
          _vm._v(" "),
          this.field.introduction
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _vm._v(_vm._s(this.field.introduction)),
              ])
            : _vm._e(),
          _vm._v(" "),
          this.field.information
            ? _c(
                "v-alert",
                { attrs: { type: "info", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.information))]
              )
            : _vm._e(),
          _vm._v(" "),
          this.field.warning
            ? _c(
                "v-alert",
                { attrs: { type: "warning", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.warning))]
              )
            : _vm._e(),
          _vm._v(" "),
          this.field.error
            ? _c(
                "v-alert",
                { attrs: { type: "error", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.error))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm11: "" } },
            [
              _c("input", {
                attrs: { type: "hidden", name: _vm.field.fieldName },
                domProps: { value: _vm.field.value },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "monthly" } }, [_vm._v("Monthly:")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.subscriptions.monthly,
                    expression: "subscriptions.monthly",
                  },
                ],
                attrs: { name: "monthly", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.subscriptions.monthly)
                    ? _vm._i(_vm.subscriptions.monthly, null) > -1
                    : _vm.subscriptions.monthly,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.subscriptions.monthly,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.subscriptions,
                            "monthly",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.subscriptions,
                            "monthly",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.subscriptions, "monthly", $$c)
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "specialOffers" } }, [
                _vm._v("Special Offers:"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.subscriptions.specialOffers,
                    expression: "subscriptions.specialOffers",
                  },
                ],
                attrs: { name: "specialOffers", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.subscriptions.specialOffers)
                    ? _vm._i(_vm.subscriptions.specialOffers, null) > -1
                    : _vm.subscriptions.specialOffers,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.subscriptions.specialOffers,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.subscriptions,
                            "specialOffers",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.subscriptions,
                            "specialOffers",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.subscriptions, "specialOffers", $$c)
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "family" } }, [_vm._v("Family:")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.subscriptions.family,
                    expression: "subscriptions.family",
                  },
                ],
                attrs: { name: "family", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.subscriptions.family)
                    ? _vm._i(_vm.subscriptions.family, null) > -1
                    : _vm.subscriptions.family,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.subscriptions.family,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.subscriptions,
                            "family",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.subscriptions,
                            "family",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.subscriptions, "family", $$c)
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "v-alert",
                {
                  staticStyle: { width: "100%" },
                  attrs: { type: "error", value: _vm.field.errors },
                },
                _vm._l(_vm.field.errors, function (error) {
                  return _c("li", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(error) +
                        "\n                "
                    ),
                  ])
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }