<template>
    <v-container class="smart-search">
        <v-layout row>
            <v-col class="pa-0" cols="2">
                <v-icon>search</v-icon>
            </v-col>

            <v-col class="pa-0" cols="10">
                <v-text-field
                    v-model="searchText"
                    v-on:keyup="performTextEntry()"
                    @keyup.enter="performTextEntry()"
            ></v-text-field>
            </v-col>

        </v-layout>

        <v-layout row v-if="results.length">
            <ies-smart-search-results></ies-smart-search-results>
        </v-layout>
    </v-container>
</template>

<script>
    import axios from 'axios';
    export default {
            props:[],
            data: function(){
                return {
                    searchText: '',
                    results: [],
                    smartSearchTimer: null
                };
            },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){},

        methods: {
            performTextEntry: function(){
                if (this.smartSearchTimer){
                    clearTimeout(this.smartSearchTimer);
                }

                // Only submit a search if it is for a reasonable length of search text
                if(this.searchText.length > 3){
                    this.smartSearchTimer = setTimeout(this.performSearch, 333);
                }
            },

            performSearch: function(){
                if (this.searchText === '') {
                    return;
                }
                
                axios.get('api/smart-search?search=' + this.searchText )
                    .then(function(response) {
                        if (response.status === 200) {
                            this.results = response.data;
                            console.log("Will emit smart search complete");
                            this.$root.$emit('Smart Search: Search Complete', {'id': 'notset', 'results': this.results, 'searchTool': this });
                        } else {
                            alert('An error was encountered when trying obtain data from the server.  Please report this problem to web@i-escape.com');
                        }
                    }.bind(this))
                    
            }
        },

        computed: {}
    }
</script>

<style>
    .smart-search {
        width: 200px;
    }
</style>
