var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "4", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("edit")]),
              _vm._v(" Extra Notes\n        "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-container", [
                _vm.hasProblem
                  ? _c("div", [_vm._v("Sorry, this content is not working")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.loaded && !_vm.hasProblem
                  ? _c(
                      "div",
                      [
                        _c("v-textarea", {
                          model: {
                            value: _vm.hotel.notes,
                            callback: function ($$v) {
                              _vm.$set(_vm.hotel, "notes", $$v)
                            },
                            expression: "hotel.notes",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "text-align": "right" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "ies-green",
                                on: { click: _vm.saveNotes },
                              },
                              [_c("v-icon", [_vm._v("save")]), _vm._v(" Save")],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }