var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-flex",
    [
      _c(
        "v-container",
        { staticClass: "hotelRoomPickerWrapper" },
        [
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs6: "" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.hotelsList,
                      label: "Select hotel",
                      disabled: !_vm.hotelSelectorActive,
                      dense: "",
                      autocomplete: "",
                      hint: "Type to search for a hotel",
                      "persistent-hint": "",
                      "prepend-icon": "house",
                    },
                    model: {
                      value: _vm.selectedHotel,
                      callback: function ($$v) {
                        _vm.selectedHotel = $$v
                      },
                      expression: "selectedHotel",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                { attrs: { xs5: "" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.hotelRooms,
                      label: "Select room",
                      disabled: !_vm.roomsSelectorActive,
                      "item-text": "name",
                      "item-value": "hotel_room_key",
                      "prepend-icon": "room",
                      "return-object": "",
                      dense: "",
                      autocomplete: "",
                    },
                    model: {
                      value: _vm.selectedRoom,
                      callback: function ($$v) {
                        _vm.selectedRoom = $$v
                      },
                      expression: "selectedRoom",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }