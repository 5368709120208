var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "3" } },
    [
      _c(
        "v-card",
        { staticClass: "ma-1" },
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("info")]),
              _vm._v(" About Us\n    "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-btn", { attrs: { block: "", href: "/site-texts/1" } }, [
                _vm._v("What is i-escape"),
              ]),
              _vm._v(" "),
              _c("v-btn", { attrs: { block: "", href: "/site-texts/2" } }, [
                _vm._v("Who are we"),
              ]),
              _vm._v(" "),
              _c("v-btn", { attrs: { block: "", href: "/site-texts/7" } }, [
                _vm._v("Privacy"),
              ]),
              _vm._v(" "),
              _c("v-btn", { attrs: { block: "", href: "/site-texts/6" } }, [
                _vm._v("T&Cs"),
              ]),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { block: "", href: "/faqs/list" } },
                [_c("v-icon", [_vm._v("info")]), _vm._v(" FAQs")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { block: "", href: "/site-texts/7" } },
                [_c("v-icon", [_vm._v("mail")]), _vm._v(" Contact us")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { block: "", href: "/site-texts/18" } },
                [_c("v-icon", [_vm._v("work")]), _vm._v(" Jobs")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { block: "", href: "/site-texts/2" } },
                [_c("v-icon", [_vm._v("face")]), _vm._v(" Staff Biographies")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }