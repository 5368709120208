import { render, staticRenderFns } from "./ServerStatus.vue?vue&type=template&id=645960cd&scoped=true&"
import script from "./ServerStatus.vue?vue&type=script&lang=js&"
export * from "./ServerStatus.vue?vue&type=script&lang=js&"
import style0 from "./ServerStatus.vue?vue&type=style&index=0&id=645960cd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "645960cd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/live/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('645960cd')) {
      api.createRecord('645960cd', component.options)
    } else {
      api.reload('645960cd', component.options)
    }
    module.hot.accept("./ServerStatus.vue?vue&type=template&id=645960cd&scoped=true&", function () {
      api.rerender('645960cd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/common/dashboard/panels/ServerStatus.vue"
export default component.exports