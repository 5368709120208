var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mt-3 mb-3 no-print button-bar", attrs: { xs12: "" } },
    [
      _c(
        "v-container",
        { staticClass: "pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-col",
                { staticClass: "ma-3 d-none d-md-flex", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ies-green mr-1",
                      on: {
                        click: function ($event) {
                          return _vm.addNew()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("add")]), _vm._v(" Add New")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      on: {
                        click: function ($event) {
                          return _vm.refresh()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("refresh")]), _vm._v(" Refresh")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      attrs: { depressed: _vm.liveRefresh },
                      on: {
                        click: function ($event) {
                          return _vm.toggleLiveRefresh()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("refresh")]), _vm._v(" Live View")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      on: {
                        click: function ($event) {
                          return _vm.downloadCSV()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("file_download")]), _vm._v(" CSV")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      attrs: { disabled: "" },
                      on: {
                        click: function ($event) {
                          return _vm.downloadPDF()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("file_download")]), _vm._v(" PDF")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      on: {
                        click: function ($event) {
                          return _vm.printPage()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("print")]), _vm._v(" Print")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      on: {
                        click: function ($event) {
                          return _vm.sendPage()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("email")]), _vm._v(" Email")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      on: {
                        click: function ($event) {
                          return _vm.reportProblem()
                        },
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("report_problem")]),
                      _vm._v(" Problem"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "ma-3 d-flex d-md-none", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ies-green mr-1",
                      on: {
                        click: function ($event) {
                          return _vm.addNew()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("add")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      on: {
                        click: function ($event) {
                          return _vm.refresh()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("refresh")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      on: {
                        click: function ($event) {
                          return _vm.sendPage()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("email")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      on: {
                        click: function ($event) {
                          return _vm.reportProblem()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("report_problem")])],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-alert",
                { attrs: { type: "info", value: _vm.liveRefresh } },
                [
                  _vm._v(
                    "\n                Live view mode is active. The data will refresh automatically\n             "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "linearWrapper" } },
            [
              _c("v-progress-linear", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showProgressIcon,
                    expression: "showProgressIcon",
                  },
                ],
                attrs: { indeterminate: true, color: "lime" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }