var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12" } },
    [
      _c(
        "v-btn",
        { staticClass: "mt-1", attrs: { href: _vm.channelManagersUrl } },
        [_c("v-icon", [_vm._v("sync")])],
        1
      ),
      _vm._v(" "),
      _c("v-btn", { staticClass: "mt-1", attrs: { href: _vm.icalUrl } }, [
        _vm._v("iCal"),
      ]),
      _vm._v(" "),
      _c(
        "v-btn",
        { staticClass: "mt-1", attrs: { href: _vm.channelsSizeReportUrl } },
        [_c("v-icon", [_vm._v("show_chart")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        { staticClass: "mt-1", attrs: { href: _vm.bookingStatusReportUrl } },
        [_c("v-icon", [_vm._v("show_chart")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        { staticClass: "mt-1", attrs: { href: _vm.channelFaultsUrl } },
        [_c("v-icon", [_vm._v("report")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }