<template>
    <v-flex xs12>
        <v-data-table
                :headers="hotelsTableHeaders"
                :items="hotels"
                :pagination.sync="pagination"
            >
            <template slot="items" slot-scope="props">
                <td>{{ props.item.hotel_key }}</td>
                <td>{{ props.item.hotel_code }}</td>
                <td>{{ props.item.hotel_name }}</td>
                <td>{{ props.item.most_recent_update }}</td>
            </template>
        </v-data-table>
    </v-flex>
</template>

<script>
    import axios from 'axios';

    export default {
        props:[],
        data: function() {
            return {
                hotels: [],
                hotelsTableHeaders: [
                    {text:'Hotel Key', value: 'hotel_key'},
                    {text:'Hotel Code', value: 'hotel_code', sortable: false},
                    {text:'Hotel Name', value: 'hotel_name'},
                    {text:'Most Recent Update', value: 'most_recent_update'}
                ],
                pagination: { rowsPerPage: -1 }
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            this.loadHotelsWithNoRecentUpdates();
        },

        methods:{
            loadHotelsWithNoRecentUpdates() {
                axios.get('api/channel-managed-hotels-no-recent-updates')
                    .then(function (response) {
                        console.log(response);
                        this.hotels = response.data;
                    }.bind(this));
            }
        }
    }

</script>
