var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "3" } },
    [
      _c(
        "v-card",
        { staticClass: "ma-1" },
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [
                _vm._v("format_list_numbered"),
              ]),
              _vm._v(" Lists\n    "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-btn", { attrs: { block: "", disabled: "", href: "" } }, [
                _vm._v("Pop ups"),
              ]),
              _vm._v(" "),
              _c("v-btn", { attrs: { block: "", disabled: "", href: "" } }, [
                _vm._v("URLs"),
              ]),
              _vm._v(" "),
              _c("v-btn", { attrs: { block: "", disabled: "", href: "" } }, [
                _vm._v("Info tags"),
              ]),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { block: "", href: "/newsletter-subscribers/list" } },
                [_vm._v("Mailing list")]
              ),
              _vm._v(" "),
              _c("v-btn", { attrs: { block: "", disabled: "", href: "" } }, [
                _vm._v("Import Mailing list"),
              ]),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { block: "", href: "/competition-entrants/list" } },
                [_vm._v("Competition Entrants")]
              ),
              _vm._v(" "),
              _c("v-btn", { attrs: { block: "", href: "/messagings/list" } }, [
                _vm._v("Messaging"),
              ]),
              _vm._v(" "),
              _c("v-btn", { attrs: { block: "", href: "/facilities/list" } }, [
                _vm._v("Facility Management"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }