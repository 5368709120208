var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("h1", [_vm._v("Edit Voucher: " + _vm._s(_vm.voucher.voucher_code))]),
      _vm._v(" "),
      _c("p", [
        _vm._v("Note: this page is deprecated, and will be removed in future"),
      ]),
      _vm._v(" "),
      _c(
        "v-flex",
        { staticStyle: { margin: "20px 0" }, attrs: { xs12: "" } },
        [
          _c(
            "v-card",
            { staticClass: "no-print" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ies-green",
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v("Save")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ies-coral",
                  attrs: { disabled: !_vm.canBeCancelled },
                  on: {
                    click: function ($event) {
                      return _vm.openDialog("cancel")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c("ies-resend-voucher", {
                staticStyle: { display: "inline" },
                attrs: { field: _vm.metaData },
              }),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: !_vm.canBeReactivated,
                    outline: "",
                    color: "blue",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.openDialog("reactivate")
                    },
                  },
                },
                [_vm._v("Reactivate")]
              ),
              _vm._v(" "),
              !_vm.canBeReactivated
                ? _c("span", [
                    _vm._v(
                      "Reactivation Denied: " +
                        _vm._s(_vm.cannotReactiveReason) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { id: "linearWrapper" } },
                [
                  _c("v-progress-linear", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showProgressIcon,
                        expression: "showProgressIcon",
                      },
                    ],
                    attrs: { indeterminate: true, color: "lime" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "justify-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: { row: "", wrap: "", "justify-space-between": "" },
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs3: "" } },
                        [
                          _c(
                            "v-alert",
                            {
                              attrs: {
                                value: _vm.showRememberAlert,
                                type: "warning",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Remember to press save button\n                            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { staticClass: "alertStatus", attrs: { xs2: "" } },
                        [
                          _c(
                            "v-alert",
                            { attrs: { value: true, type: _vm.alertType } },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.actionStatus) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-layout",
                        { staticClass: "ma-3", attrs: { column: "" } },
                        [
                          _c(
                            "fieldset",
                            [
                              _c("legend", [_vm._v("Details:")]),
                              _vm._v(" "),
                              _c(
                                "v-layout",
                                { attrs: { column: "" } },
                                [
                                  _c("not-editable", {
                                    attrs: {
                                      label: "Voucher key:",
                                      text: _vm.voucher.voucher_key,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("not-editable", {
                                    attrs: {
                                      label: "Created at:",
                                      text: _vm.createdAt,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { padding: "16px" } },
                                    [
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c("strong", [_vm._v("Expires at:")]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(_vm.voucher.expires) +
                                              "\n                                        "
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-2 editExpiryBtn",
                                              attrs: { small: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openDialog(
                                                    "expiry"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("edit")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    [
                                      _c("not-editable", {
                                        attrs: {
                                          label: "Created on page:",
                                          text: _vm.sourcePage,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.voucher.reissue_voucher_key > 0
                                        ? _c("not-editable", {
                                            attrs: {
                                              label:
                                                "Reissue parent voucher_key:",
                                              text: _vm.voucher
                                                .reissue_voucher_key,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.voucher.creation_purpose > 0
                                        ? _c("not-editable", {
                                            attrs: {
                                              label: "Creation purpose:",
                                              text: _vm.creationPurpose,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("not-editable", {
                                        attrs: {
                                          label: "Voucher type:",
                                          text: _vm.voucherType,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", md4: "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c("not-editable", {
                                            attrs: {
                                              label: "Value:",
                                              text: _vm._f("pounds")(
                                                _vm.voucher.value
                                              ),
                                            },
                                          }),
                                          _vm._v(" "),
                                          !_vm.voucher.is_active
                                            ? _c("not-editable", {
                                                attrs: {
                                                  label: "Redeemed value:",
                                                  text: _vm._f("pounds")(
                                                    _vm.voucher.redeemed_value
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", md4: "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticStyle: { padding: "16px" },
                                              attrs: { xs12: "" },
                                            },
                                            [
                                              _c("strong", [_vm._v("Status:")]),
                                              _c("br"),
                                              _vm._v(" "),
                                              _vm.voucher.is_active
                                                ? _c(
                                                    "strong",
                                                    {
                                                      staticClass:
                                                        "voucherActive",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                Active "
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "lime",
                                                          },
                                                        },
                                                        [_vm._v("done_outline")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "strong",
                                                    {
                                                      staticClass:
                                                        "voucherInactive",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                Inactive "
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [_vm._v("clear")]
                                                      ),
                                                      _vm._v(
                                                        " - " +
                                                          _vm._s(
                                                            _vm.actionStatus
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.status ===
                                          _vm.consts.ACTION_REDEMPTION
                                            ? _c(
                                                "v-flex",
                                                {
                                                  attrs: { xs12: "", sm10: "" },
                                                },
                                                [
                                                  _c("not-editable", {
                                                    attrs: {
                                                      label: "Redeemed value:",
                                                      text: _vm._f("pounds")(
                                                        _vm.voucher
                                                          .redeemed_value
                                                      ),
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("not-editable", {
                                                    attrs: {
                                                      label: "Redeemed at:",
                                                      text: _vm.voucher
                                                        .redeemed,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.voucher.reservation
                                                    ? _c("not-editable", {
                                                        attrs: {
                                                          label: "Reservation:",
                                                          text: _vm.voucher
                                                            .reservation
                                                            .reference,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.voucher.reservation
                                                    ? _c("not-editable", {
                                                        attrs: {
                                                          label:
                                                            "Booking value:",
                                                          text: _vm._f(
                                                            "pounds"
                                                          )(
                                                            _vm.voucher
                                                              .reservation
                                                              .quote_amount_gbp
                                                          ),
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.voucher.reservation
                                                    ? _c("not-editable", {
                                                        attrs: {
                                                          label: "Commission:",
                                                          text: _vm._f(
                                                            "pounds"
                                                          )(
                                                            _vm.voucher
                                                              .reservation
                                                              .commission_amount_local
                                                          ),
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.status ===
                                          _vm.consts.ACTION_EXPIRATION
                                            ? _c(
                                                "v-flex",
                                                {
                                                  attrs: { xs12: "", sm10: "" },
                                                },
                                                [
                                                  _c("not-editable", {
                                                    attrs: {
                                                      label: "Expired at:",
                                                      text: _vm.voucher.expires,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("not-editable", {
                                                    attrs: {
                                                      label: "Deactivated at:",
                                                      text: _vm.voucher
                                                        .deactivate_date,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.status ===
                                          _vm.consts.ACTION_CANCELLATION
                                            ? _c(
                                                "v-flex",
                                                {
                                                  staticClass:
                                                    "cancellationWrapper",
                                                  attrs: { xs12: "", sm10: "" },
                                                },
                                                [
                                                  _c("not-editable", {
                                                    attrs: {
                                                      label: "Cancelled at:",
                                                      text: _vm.voucher
                                                        .cancelled_date,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("not-editable", {
                                                    attrs: {
                                                      label: "Cancelled by:",
                                                      text: _vm.voucher
                                                        .cancelled_by_username,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("input-wrapper", {
                                                    attrs: {
                                                      field:
                                                        _vm.fields
                                                          .cancellation_notes,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "fieldset",
                            [
                              _c("legend", [_vm._v("Customer:")]),
                              _vm._v(" "),
                              _c(
                                "v-layout",
                                { attrs: { column: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "customerInfo",
                                      attrs: { xs12: "", md4: "" },
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs10: "" } },
                                            [
                                              _c("input-wrapper", {
                                                attrs: {
                                                  field:
                                                    _vm.fields.purchaser_email,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("input-wrapper", {
                                                attrs: {
                                                  field:
                                                    _vm.fields.recipient_email,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("input-wrapper", {
                                                attrs: {
                                                  field: _vm.fields.to_text,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("input-wrapper", {
                                                attrs: {
                                                  field: _vm.fields.message,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "fieldset",
                            [
                              _c("legend", [_vm._v("Switches:")]),
                              _vm._v(" "),
                              _c(
                                "v-layout",
                                { attrs: { column: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", md4: "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          staticClass: "switchesWrapper",
                                          attrs: { row: "", wrap: "" },
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs10: "" } },
                                            [
                                              _c("input-wrapper", {
                                                attrs: {
                                                  field:
                                                    _vm.fields.is_promotional,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("input-wrapper", {
                                                attrs: {
                                                  field: _vm.fields.is_referral,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _vm.voucher.is_referral
                                                ? _c("not-editable", {
                                                    attrs: {
                                                      label: "Referral type:",
                                                      text: _vm.voucher.source,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c("input-wrapper", {
                                                attrs: {
                                                  field:
                                                    _vm.fields.is_delivered,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("input-wrapper", {
                                                attrs: {
                                                  field:
                                                    _vm.fields.creation_purpose,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "fieldset",
                            [
                              _c("legend", [_vm._v("Address:")]),
                              _vm._v(" "),
                              _c(
                                "v-layout",
                                { attrs: { column: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", md4: "" } },
                                    [
                                      _vm.voucher.scheduled_delivery
                                        ? _c(
                                            "v-layout",
                                            { attrs: { row: "", wrap: "" } },
                                            [
                                              _c("not-editable", {
                                                attrs: {
                                                  label:
                                                    "Scheduled delivery at:",
                                                  text: _vm.voucher
                                                    .scheduled_delivery,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _vm.voucher.is_delivered
                                                ? _c("not-editable", {
                                                    attrs: {
                                                      label: "Delivered:",
                                                      text: "Yes",
                                                    },
                                                  })
                                                : _c("not-editable", {
                                                    attrs: {
                                                      label: "Delivered:",
                                                      text: "No",
                                                    },
                                                  }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.voucher.address_key > 0 &&
                                      _vm.voucher.address
                                        ? _c(
                                            "v-layout",
                                            {
                                              staticClass: "addressWrapper",
                                              attrs: { row: "", wrap: "" },
                                            },
                                            [
                                              _c("not-editable", {
                                                attrs: {
                                                  label: "Address",
                                                  text: _vm.voucher.address
                                                    .address_1,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("not-editable", {
                                                attrs: {
                                                  label: "",
                                                  text: _vm.voucher.address
                                                    .address_2,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("not-editable", {
                                                attrs: {
                                                  label: "Postcode",
                                                  text: _vm.voucher.address
                                                    .postcode,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("not-editable", {
                                                attrs: {
                                                  label: "Town",
                                                  text: _vm.voucher.address
                                                    .town,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("not-editable", {
                                                attrs: {
                                                  label: "To",
                                                  text: _vm.voucher.address
                                                    .recipient_name,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _vm.voucher.delivery_type
                                                ? _c("not-editable", {
                                                    attrs: {
                                                      label: "Delivery Type",
                                                      text: _vm.deliveryType,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "fieldset",
                            [
                              _c("legend", [_vm._v("Staff notes:")]),
                              _vm._v(" "),
                              _c(
                                "v-layout",
                                { attrs: { column: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", xs10: "" } },
                                    [
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.notes,
                                            expression: "notes",
                                          },
                                        ],
                                        attrs: {
                                          name: "main-notes",
                                          type: "text",
                                        },
                                        domProps: { value: _vm.notes },
                                        on: {
                                          change: _vm.updateNotes,
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.notes = $event.target.value
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "no-print" },
        [
          _c(
            "v-btn",
            {
              staticClass: "ies-green",
              on: {
                click: function ($event) {
                  return _vm.save()
                },
              },
            },
            [_vm._v("Save")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "ies-coral",
              attrs: { disabled: !_vm.canBeCancelled },
              on: {
                click: function ($event) {
                  return _vm.openDialog("cancel")
                },
              },
            },
            [_vm._v("Cancel")]
          ),
          _vm._v(" "),
          _c("ies-resend-voucher", {
            staticStyle: { display: "inline" },
            attrs: { field: _vm.metaData },
          }),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                disabled: !_vm.canBeReactivated,
                outline: "",
                color: "blue",
              },
              on: {
                click: function ($event) {
                  return _vm.openDialog("reactivate")
                },
              },
            },
            [_vm._v("Reactivate")]
          ),
          _vm._v(" "),
          !_vm.canBeReactivated
            ? _c("span", [
                _vm._v(
                  "Reactivation Denied: " +
                    _vm._s(_vm.cannotReactiveReason) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "linearWrapper" } },
            [
              _c("v-progress-linear", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showProgressIcon,
                    expression: "showProgressIcon",
                  },
                ],
                attrs: { indeterminate: true, color: "lime" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("RecordHistory", {
        staticStyle: { padding: "20px 0" },
        attrs: { "meta-data": _vm.metaData },
      }),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.dialog.show,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "show", $$v)
            },
            expression: "dialog.show",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm.dialog.mode === "expiry"
                  ? _c("span", { staticClass: "headline" }, [
                      _vm._v("Change voucher expiry date"),
                    ])
                  : _c("span", { staticClass: "headline" }, [
                      _vm._v(
                        "Do you want to " +
                          _vm._s(_vm.dialog.mode) +
                          " this voucher?"
                      ),
                    ]),
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _vm.dialog.mode === "cancel"
                        ? _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _vm._v(
                                  "\n                                Enter cancellation note why this voucher is being cancel\n                            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "mt-2": "" } },
                                [
                                  _c("input-wrapper", {
                                    attrs: {
                                      field: _vm.fields.cancellation_notes,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.dialog.mode === "reactivate" ||
                      _vm.dialog.mode === "expiry"
                        ? _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _vm.dialog.mode === "reactivate"
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                                    Add the reason why / when / by who this voucher is being reactivate\n                                "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.dialog.mode === "expiry"
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                                    Add the reason why / when / by who expiry date is being change\n                                "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "mt-2": "" } },
                                [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.notes,
                                        expression: "notes",
                                      },
                                    ],
                                    attrs: {
                                      name: "dialog-notes",
                                      type: "text",
                                    },
                                    domProps: { value: _vm.notes },
                                    on: {
                                      change: _vm.updateNotes,
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.notes = $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "mt-1": "" } },
                                [
                                  _vm._v(
                                    "\n                                Enter new expiration date:\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("input", {
                                  attrs: {
                                    type: "datetime-local",
                                    id: "new-expiry",
                                    name: "new-expiry",
                                  },
                                  domProps: { value: _vm.expiryLocal },
                                }),
                              ]),
                              _vm._v(" "),
                              _vm.dialog.mode === "expiry"
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "mt-4": "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "info" },
                                          on: { click: _vm.editExpires },
                                        },
                                        [_vm._v("Change expiry date")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.dialog.mode === "expiry"
                                ? _c("v-flex", { attrs: { xs12: "" } }, [
                                    _vm._v(
                                      "\n                                Remember you still need to save the voucher page to update your changes!\n                            "
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.dialog.mode === "reactivate" ||
                      _vm.dialog.mode === "cancel"
                        ? _c(
                            "v-layout",
                            {
                              staticClass: "margin-top-medium",
                              attrs: { row: "" },
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md10: "", lg8: "" } },
                                [
                                  _c(
                                    "v-expansion-panel",
                                    [
                                      _c(
                                        "v-expansion-panel-content",
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "header" },
                                              slot: "header",
                                            },
                                            [
                                              _vm._v(
                                                "Do you really want to " +
                                                  _vm._s(_vm.dialog.mode) +
                                                  " this voucher?"
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      attrs: {
                                                        row: "",
                                                        wrap: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs12: "" } },
                                                        [
                                                          _vm.dialog.mode ===
                                                          "cancel"
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "error",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.cancel,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Yes, cancel"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm.dialog.mode ===
                                                          "reactivate"
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "info",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.reactivate,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Yes, reactivate"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          attrs: {
                                                            xs12: "",
                                                            "mt-3": "",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                        Remember you still need to save the voucher page to update your changes!\n                                                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", flat: "" },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: parseInt(2000),
            "multi-line": "",
            color: "ies-green",
          },
          model: {
            value: _vm.successSnackbar,
            callback: function ($$v) {
              _vm.successSnackbar = $$v
            },
            expression: "successSnackbar",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "full-height full-width v-align-container text-xs-center",
            },
            [
              _c("h4", { staticClass: "ies-dark-gray--text v-align-div" }, [
                _vm._v("Voucher updated"),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: parseInt(3000),
            "multi-line": "",
            color: "ies-coral",
          },
          model: {
            value: _vm.errorSnackbar,
            callback: function ($$v) {
              _vm.errorSnackbar = $$v
            },
            expression: "errorSnackbar",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "full-height full-width v-align-container text-xs-center",
            },
            [
              _c("h4", { staticClass: "ies-dark-gray--text v-align-div" }, [
                _vm._v("Error " + _vm._s(_vm.errorMessage)),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }