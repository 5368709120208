<template>
    <v-container>
        <span v-if="fieldSchema.displayType == 'text' || fieldSchema.displayType == 'default'">{{ val }}</span>

        <span v-if="fieldSchema.displayType == 'checkbox' || fieldSchema.displayType == 'checkbox_words'">{{ val }}</span>

        <div class="numeric" v-if="fieldSchema.displayType == 'currency' || fieldSchema.displayType == 'integer'">{{ val }}</div>

        <div class="date" v-if="fieldSchema.displayType == 'date' || fieldSchema.displayType == 'datetime'">{{ val }}</div>

        <div v-if="fieldSchema.displayType == 'ordered_list'" class="list-div">
            <ol v-if="val.length">
                <li v-for="item in val">{{ item }}</li>
            </ol>
        </div>

        <div v-if="fieldSchema.displayType == 'unordered_list'" class="list-div">
            <ul v-if="val.length" v-for="item in val">
                <li>{{ item }}</li>
            </ul>
        </div>

        <div v-if="fieldSchema.displayType == 'HotelAssociationListPage'">
            <span v-for="hotel in val" class="associated-hotel">{{ hotel }}</span>
        </div>

        <div v-if="fieldSchema.displayType == 'button'">
            <v-btn :color="color" @click="openLink()">{{ val }}</v-btn>
        </div>

        <div v-if="fieldSchema.displayType == 'editButton'">
            <ies-list-edit-button :pageRecord="pageRecord" :field="headerProp" :value="value"></ies-list-edit-button>
        </div>

        <div v-if="fieldSchema.displayType == 'newsletterSubscriptions'">
            <ies-newsletter-subscriptions :field="headerProp" :value="value"></ies-newsletter-subscriptions>
        </div>

        <div v-if="fieldSchema.displayType == 'facilityCategories'">
            <ies-facility-categories :field="headerProp" :value="value"></ies-facility-categories>
        </div>

		<div v-if="fieldSchema.displayType == 'Status'">
            <ies-status :field="headerProp" :value="value"></ies-status>
        </div>

		<div v-if="fieldSchema.displayType == 'visitType'">
            <ies-visit-type :field="headerProp" :value="value"></ies-visit-type>
        </div>

		<div v-if="fieldSchema.displayType == 'yesNo'">
            <ies-yes-no :field="headerProp" :value="value"></ies-yes-no>
        </div>


		<div v-if="fieldSchema.displayType == 'reviewerName'">
            <ies-reviewer-name :field="headerProp" :value="value"></ies-reviewer-name>
        </div>

		<div v-if="fieldSchema.displayType == 'displayStatus'">
            <ies-display-status :field="headerProp" :value="value"></ies-display-status>
        </div>

        <div v-if="fieldSchema.displayType == 'displayGroups'">
            <ies-display-groups :field="headerProp" :value="value"></ies-display-groups>
        </div>

        <div v-if="fieldSchema.displayType == 'resendSentSMS'">
            <ies-resend-sent-sms :field="headerProp" :value="value"></ies-resend-sent-sms>
        </div>
        <div v-if="fieldSchema.displayType == 'resendFailedSMS'">
            <ies-resend-failed-sms :field="headerProp" :value="value"></ies-resend-failed-sms>
        </div>
        <div v-if="fieldSchema.displayType == 'resendSentEmail'">
            <ies-resend-sent-email :field="headerProp" :value="value"></ies-resend-sent-email>
        </div>
        <div v-if="fieldSchema.displayType == 'resendFailedEmail'">
            <ies-resend-failed-email :field="headerProp" :value="value"></ies-resend-failed-email>
        </div>

        <div v-if="fieldSchema.displayType == 'paymentRequestLink'">
            <ies-payment-request-link :field="headerProp" :value="value"></ies-payment-request-link>
        </div>

        <div v-if="fieldSchema.displayType == 'iCalFeed'">
            <ies-ical-feed :field="headerProp" :value="value"></ies-ical-feed>
        </div>

        <div v-if="fieldSchema.displayType == 'iCalFeedRefresh'">
            <ies-ical-feed-refresh :field="headerProp" :value="value"></ies-ical-feed-refresh>
        </div>

        <div v-if="fieldSchema.displayType == 'voucherAddressLink'">
            <ies-voucher-address-link :field="headerProp" :value="value"></ies-voucher-address-link>
        </div>
    </v-container>
</template>


<script>
    // Export the component for inclusion within the page
    export default {
        props: ['value', 'headerProp', 'fieldSchema', 'pageRecord'],

        /**
         * Core data required by the component and template
         */
        data: function(){
            return {
                field: 'text',          // text field as default,
                header: this.headerProp,
                color: 'info',          // default color
                link: '',               // link what button field will use on click
            };
        },
        mounted: function(){
            console.log("LIAST FIELD mounted - ", this.pageRecord, this.pagerecord);
        },

        /**
         * Business logic functions performing useful tasks for this component
         */
        methods: {
            /**
             * Establishes how the view should display the data value, as some data represents dates or boolean values, and the raw value is unhelpful
             * in this regard as it will simply state that it is a string or integer for example.  The page designer tool will have specified what
             * the data value should be interpreted as being, so we need to consult with the data passed in about the field's display type before
             * deciding how the raw data value should actually be represented to the user in the view template
             *
             * @return
             */
            getDisplayValue(val){
                var _this = this;
                var field = 'text';

                // We need to prepare the display value for the view based on what data type it is. Some types cannot be printed directly to the user in any meaningful way
                // and need some alternative content to be displayed instead
                switch(this.fieldSchema.displayType){
                    // tinyInteger - ticks and nulls to represent the checkbox values
                    case 'checkbox':
                        val = (val == '1' ? '✓' : 'x');
                        break;

                    case 'checkbox_words':
                        val = (val == '1' ? 'Yes' : 'No');
                        break;

                    case 'currency':
                        if(val == null || val == 0){
                            val = '';
                        }
                        else {
                            val = '£'+ parseFloat(Math.round(val * 100) / 100).toFixed(2)
                        }
                        break;

                    case 'date':
                    case 'datetime':
                        if(val && val != null){
                            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                            var date = new Date(val);
                            val = date.getDate()+' '+months[date.getMonth()]+' '+date.getFullYear();
                        }
                        break;

                    // Numeric values which need formatting or contain bitwise values which need to be represented using text labels
                    case 'integer':
                        if (this.fieldSchema.isBitwise){
                            val = "BITWISE(to do)";
                        }
                        break;

                    // In the case of the value needing to be interpreted as a newsletter 
                    // subscription list then we simply need to say so and allow the template
                    // to render the component in the place, passing on the data as required
                    // as its prop so it can do the work for itself
                    case 'paymentRequestLink':
                        field = 'paymentRequestLink';
                        break;

                    case 'iCalFeed':
                        field = 'iCalFeed';
                        break;

                    case 'iCalFeedRefresh':
                        field = 'iCalFeedRefresh';
                        break;

                    case 'newsletter_subscriptions':
                        field = 'newsletterSubscriptions';
                        break;

                    case 'facility_categories':
                        field = 'facilityCategories';
                        break;

					case 'Status':
						field = 'Status';
						break;

					case 'visitType':
						field = 'visitType';
						break;

					case 'yesNo':
						field = 'yesNo';
						break;

					case 'displayStatus':
						field = 'displayStatus';
						break;

					case 'reviewerName':
						field = 'reviewerName';
console.log("******");
						break;


                    case 'displaygroup':
                        field = 'displayGroups';
                        break;

                    case 'unordered_list':
                        field = 'unordered_list';
                        val = _this.getAssociatedValuesFromObject(val, this.fieldSchema, field);
                        break;

                    case 'ordered_list':
                        field = 'ordered_list';
                        val = _this.getAssociatedValuesFromObject(val, this.fieldSchema, field);
                        break;

                    case 'text-asso':
                        field = 'text';
                        val = _this.getAssociatedValuesFromObject(val, this.fieldSchema, field);
                        // value now is an array with found values, we want to  display it as simple text, so combine it as a comma delimited string
                        val = _this.parseArrayToStringValue(val);
                        break;

                    case 'currency-asso':
                        field = 'text';
                        val = _this.getAssociatedValuesFromObject(val, this.fieldSchema, field);
                        val = _this.parseArrayToStringValue(val);
                        if (val) {
                            val = '£' + val;
                        }
                        break;

                    case 'custom' :
                        var custom = this.handleCustomComponent(val, this.fieldSchema);
                        val = custom.val;
                        field = custom.field;
                        break;

                    default:
                        // If the field has a fixed number of possible values then use the labels which correspond to those to avoid confusing the user if the raw
                        // value does not match.  Iterate over the options and if a match is found use its label instead

                        // check first if this is maybe composite field
                        if (this.fieldSchema.hasOwnProperty('isComposite')) {
                            if (this.fieldSchema.isComposite) {
                                var composite = _this.handleCompositeComponent(val, this.fieldSchema);
                                val = composite.val;
                                field = composite.field;
                            }
                        }
                        else if (this.fieldSchema.options  && this.fieldSchema.options.length > 0){
                            val = "has option";
                            for(var isFound=false, index=0; index < this.fieldSchema.options.length; index++){
                                var option = this.fieldSchema.options[index];
                                if(option.value === col){
                                    val = option.label;
                                }
                            }
                        }
                        else {
                            // Alphanumeric: strings may need truncation
                            if(val && val.length > 50){
                                val = val.substring(0,47) +" ...";
                            }
                        }
                        break;
                }

                // Assign display type to global vue data object, so vue will know which field to use to render that field value
                _this.field = field;

console.log(this.headerProp, val);
console.log("field", _this.field, val, this);

                return val;
            },


            /**
             * This method will handle custom component logic for how do display values on list page
             *
             * @param val - value that should be displayed - to process by individual custom component
             * @param header - object contains information about how this field should be displayed
             * @return object containing processed value ready to pass to view and field name from template that needs to be used to render it
             */
            handleCustomComponent(val, header) {
                var component = header.customComponent;
                var field = 'text';  // default field type


                switch (component) {

                    case 'RoleAsString' :
                        val = this.getRoleAsString(val);
                        break;

                    case 'DepartmentAsString' :
                        val = this.getDepartmentAsString(val);
                        break;

                    case 'HotelAssociationListPage' :
                        val = this.getHotelAssociationListPage(val);
                        field = 'HotelAssociationListPage';
                        break;

                    case 'CompetitionVotesButton' :
                        field = 'button';
                        val = 'Votes';
                        this.link = 'competition-entries/list';
                        break;

                    case 'VoucherType':
                        val = this.getVoucherType(val);
                        break;

                    default :
                        console.log('You enter incorrect custom component name');
                        break;
                }

                return {
                    'val'  : val,
                    'field': field
                };
            },

            /**
             * Method parses passed composite component field to be ready to use by view
             * First if check if it's already easy to use text field, or JSON object
             * if it's JSON Objects, its parses it to array so vue call loop through it and display all the values as unordered_list
             *
             * composite fields containing relationship columns by default will be display as an unordered_list
             * composite fields containing data from primary model will be displayed as text
             *
             * @param val - value that should be displayed - to process by individual custom component
             * @param header - object contains information about how this field should be displayed
             * @return object containing processed value ready to pass to view and field name from template that needs to be used to render it
             */
            handleCompositeComponent(val, header) {
                // check if this is json or simple text field that needs to be display as text
                try {
                    val = JSON.parse(val); // reassign val to be easy to use array
                    var field = 'unordered_list';
                }
                catch (e) {
                    var field = 'text';
                }

                return {
                    'val': val,
                    'field': field
                };
            },

            /**
             * Method is decoding passed json as value, and combine all the values in unique array and
             * returns array - unique values
             */
            getAssociatedValuesFromObject(val, header, field) {
                if (val) {
                    // try to decode json and convert it to array
                    try {
                        var jsonObject = JSON.parse(val);
                    }
                    catch (e) {
                        // fallback just in case someones sets unordered_list for field that doesn't contain list
                        console.log('invalid data passed to ', field);
                        var jsonObject = [];
                    }

                    if (jsonObject.length) {
                        var list = [];
                        var fieldName = header.fieldName;

                        jsonObject.forEach((elem) => {
                            if (elem.hasOwnProperty(fieldName)) {
                                list.push(elem[fieldName]);
                            }
                            else {
                                list.push(elem);
                            }
                        });

                        // get only unique values from the list
                        let unique = [...new Set(list)];

                        val = unique ? unique : list;
                    }
                    else {
                        val = [];
                    }
                }

                return val;
            },

            /** Parses array with values for associated field to easy to ready string to be displayed as text box
             * @param array
             * @return string
             */
            parseArrayToStringValue(val) {
                var valString = '';
                if (val && val.length) {
                    for(let i = 0; i < val.length; i++) {
                        let delim = ((i+1) == val.length) ? '' : ', ';
                        if (val[i]) {
                            valString += val[i] + delim;
                        }
                    }
                    val = valString;
                }
                return val;
            },


            // METHODS THAT HANDLE CUSTOM COMPONENTS BELOW

            /**
             * Transform role_key into role name
             */
            getRoleAsString(val) {
                val = parseInt(val);

                switch (val) {
                    case 0 :    val = '';               break;
                    case 1 :    val = 'Intern';         break;
                    case 2 :    val = 'Staff';          break;
                    case 3 :    val = 'Manager';        break;
                }
                return val;
            },

            /**
             * Transform department_key into department name
             */
            getDepartmentAsString(val) {
                val = parseInt(val);

                switch (val) {
                    case 0 :    val = '';               break;
                    case 1 :    val = 'Bookings';       break;
                    case 2 :    val = 'Directors';      break;
                    case 3 :    val = 'Editorial';      break;
                    case 4 :    val = 'Marketing';      break;
                    case 5 :    val = 'Rates';          break;
                    case 6 :    val = 'Web';            break;
                }
                return val;
            },

            /**
             * Transform int value of the voucher type to string
             * @param val integer
             * @return string
             */
            getVoucherType(val) {
                var text = 'unknown';

                switch (parseInt(val)) {
                    case 1 :    text = 'Physical';      break;
                    case 2 :    text = 'Digital';       break;
                }

                return text;
            },

            /**
             * Generates string that will be displayed on list from received hotel object
             * @param array of hotel objects
             * @return array of strings to display on list
             */
            getHotelAssociationListPage(val) {
                var jsonObject = JSON.parse(val); // this should be array with entire model object

                if (jsonObject.length) {
                    var list = [];
                    jsonObject.forEach((model) => {
                        var text = model['hotel_code'] + ' - ' + model['hotel_name'];
                        if (!list.includes(text)) {
                            list.push(text);
                        }
                    });

                    val = list;
                }
                else {
                    val = [];
                }
                return val;
            },

            /**
             * Redirect the page to the link that is set in data object
             */
            openLink() {
                window.location.href = '/' + this.link;
            },


        },
        computed: {
            val: {
                get() {
console.log("*************************data table computed val get************************", this.value);
                    // The actual value displayed to the user via the view template is generated by the component
                    // based on interpreting the raw data in the way the page designer tool has requested, such as 
                    // interpreting a numeric field as a boolean or currency for example
                    return this.getDisplayValue(this.value);
                },
                set() {
                    this.currentValue = this.value;
                }
            },
        },

        watch: {
            value: function(newValue, oldValue){
                console.log("watch called  for data display type  " + this.fieldSchema.displayType, " - the data value becomes: "+newValue, "was: "+ oldValue);
            }
        },

        /**
         * On page load complete build the component
         */
        mounted: function(){
        },
    }
</script>

<style>
    .numeric, .date {
        text-align: right;
    }

    li {
        text-align: left;
    }

    .list-div {
        max-width: 300px;
        margin-left: 50%;
    }

    .associated-hotel {
        display:block;
    }
</style>

