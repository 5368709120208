<template>
    <v-container fluid grid-list-md>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                     <v-card-text>
                         <v-container fluid v-if="reservation.show || 1">
                              <v-layout row wrap>
                                  <v-flex xs12>
                                      <strong>Confirm Availability and Total Rate</strong>
                                  </v-flex>

                                  <v-flex xs4>
                                      <strong>Total Rate:</strong>
                                  </v-flex>
                                  <v-flex xs8>
                                      <div v-for="(unit, index) in reservation.units">
                                          <strong>{{ unit.room.name }}</strong> (
                                              {{ unit.occ_adults ? unit.occ_adults + ' adults' : '' }}, 
                                              {{ unit.occ_children ? unit.occ_children + ' young children' : '' }}, 
                                              {{ unit.occ_oldchildren ? unit.occ_oldchildren + ' older children' : '' }}, 
                                              {{ unit.occ_babies ? unit.occ_babies + ' babies' : '' }}
                                          )
                                          {{ unit.rate_type.name }} for {{ reservation.stay_length }} nights
                                      </div>

                                      <div>
                                          <label for="noQuote">A quote cannot be given at this time</label>
                                          <input id="noQuote" name="noQuote" type="radio" v-model="quoteNotPossible" value="1">
                                          <label for="quote">A quote can be given</label>
                                          <input id="quote" name="quote" type="radio" v-model="quoteNotPossible" value="0">
                                      </div>

                                      <div v-for="(unit, index) in reservation.units" v-if="!quoteNotPossible">
                                          {{ unit.room.name }} using {{ unit.rate_type.name }}:
                                          <v-container fluid>
                                              <v-layout column>

                                                  <v-flex xs12>
                                                      <v-container fluid>
                                                          <v-layout row wrap>
                                                              <v-flex xs6 sm3>
                                                                  <strong>Date</strong>
                                                              </v-flex>
                                                              <v-flex xs6 sm3>
                                                                  <strong>Rate ({{ reservation.hotel.currency.currency_code }})</strong> for {{ unit.total_guests }} guests
                                                              </v-flex>
                                                              <v-flex xs6 sm2>
                                                                  <strong>Total Daily Rate</strong>
                                                              </v-flex>
                                                              <v-flex xs6 sm2>
                                                                  <strong>Commission Rate (%)</strong>
                                                              </v-flex>
                                                              <v-flex xs6 sm2>
                                                                  <strong>Commission Amount ({{ reservation.hotel.currency.currency_code }})</strong>
                                                              </v-flex>
                                                          </v-layout>
                                                      </v-container>
                                                  </v-flex>


                                                  <v-flex xs12 v-for="(quoteDate, index) in unit.std_rates">
                                                      <v-container fluid>
                                                          <v-layout row wrap>
                                                              <v-flex xs6 sm3>
                                                                  <strong>{{ quoteDate.date }}</strong>
                                                              </v-flex>
                                                              <v-flex xs6 sm3>
                                                                  <input type="text" style="max-width: 10rem;" v-model="quoteDate.rate">
                                                              </v-flex>
                                                              <v-flex xs6 sm2>
                                                                  {{ parseFloat(quoteDate.commission) }}
                                                              </v-flex>
                                                              <v-flex xs6 sm2>
                                                                  ???
                                                              </v-flex>
                                                              <v-flex xs6 sm2>
                                                                  ???
                                                              </v-flex>
                                                          </v-layout>
                                                      </v-container>
                                                  </v-flex>

                                                  <v-flex xs12>
                                                      <strong>Total Rate: <input type="text" v-model="totalRates"> {{ reservation.hotel.currency.currency_code }}</strong>
                                                  </v-flex>

                                              </v-layout>
                                          </v-container>
                                      </div>
                                  </v-flex>
                                  <v-flex xs4>
                                      <strong>Deposit:</strong>
                                  </v-flex>
                                  <v-flex xs8>
                                      <div v-if="reservation.depositRequired"><strong>Deposit required at time of booking {{ reservation.deposit_amount_local }} {{ reservation.hotel.currency.currency_code }}</strong></div>
                                      <div v-if="reservation.is_advance_payment">Full pre-payment charged to the client by i-escape to secure the booking</div>
                                      <div v-if="reservation.hotel.collect_deposit == 1">Amount charged to the client by i-escape to secure the booking</div>
                                  </v-flex>
                                  <v-flex xs4>
                                      <strong>Payment Policy:</strong>
                                  </v-flex>
                                  <v-flex xs8>
                                      ???
                                  </v-flex>
                                  <v-flex xs4>
                                      <strong>Cancellation Policy:</strong>
                                  </v-flex>
                                  <v-flex xs8>
                                      ???
                                  </v-flex>
                                  <v-flex xs4>
                                      <strong>Room Held Until</strong>
                                  </v-flex>
                                  <v-flex xs8>
                                      <v-date-picker v-model="reservation.quote_expiry" no-title scrollable>
                                      </v-date-picker>
                                  </v-flex>

                              </v-layout>
                          </v-container>
                      </v-card-text>

                      <v-card-actions>
                          <v-btn class="ies-green" v-on:click="sendQuote">Send to client</v-btn>
                      </v-card-actions>
                 </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    /**
     * A Vue component dedicated to letting the user manage a reservations
     * by the ID specified
     *
     * Props:
     *     int 'reservationkey' - The ID of the reservation to display
     */

    import axios from 'axios';
    export default {
        name: 'hotel-reservation-enquiry',
        props: ['reservationkey'],
        data () {
            return {
                reservation: {},
                quoteNotPossible: false,
                totalRates: 0.0
            }
        },
        methods: {

            loadReservation(){
                axios.get('/api/reservation/' + this.reservationkey)
                .then(function(response){
                    this.reservation = response.data;

                    // Set a property to dictate whether the booking would require the client to pay a deposit
                    // The logic being if i-escape collects one for the hotel, or the rates require one, or the
                    // hotel's policy requires it for all bookings
                    this.reservation.depositRequired = this.reservation.hotel.collect_deposit || this.reservation.is_advance_payment || ((this.reservation.hotel.hotel_payment_policy.deposit_required == 1) || (this.reservation.hotel.hotel_payment_policy.deposit_required == 2));
                    if (!this.reservation.quote_expiry){
                        var date = new Date();
                        date.setDate(date.getDate() + 5);
                        this.reservation.quote_expiry = date.getUTCFullYear() +"-"+ (date.getUTCMonth()+1) +"-"+ date.getUTCDate();
                    }

                    var startDate = Date.parse(this.reservation.date_arrival);
console.log(startDate);
                    for(var index=0; index < this.reservation.stay_length; index++){
                        var datePointer = new Date(startDate.valueOf());
                        datePointer.setDate(datePointer.getDate() + index);
                        var dateString = datePointer.getUTCDate() + " " + this.getMonthAbbreviated(datePointer.getUTCMonth()) + " " + datePointer.getUTCFullYear() + ', ' + this.getDayAbbreviated(datePointer.getDay())
                        this.quote.push({ "dateString": dateString, "date": date, "rateValue": 0.0 })
                    }
                    console.log(this.dateRange);


console.log("offer quote: ", this.reservation);
                }.bind(this));
            },

            sendQuote(){
                axios.post('/api/reservation/' + this.reservationkey + '/send-quote', {"reservation": this.reservation})
                .then(function(response){
                    alert("Quote sent to client");
                }.bind(this));
            },

            /**
             * Returns the day of the week by abbreviated name
             * e.g. 1 = "Mon", 2 = "Tue" etc
             */
            getDayAbbreviated(dayIndex){
                var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                return days[dayIndex];
            },

            /**
             * Returns the month of the year by abbreviated name
             * e.g. 1 = "Feb", 2 = "Mar" etc
             */
            getMonthAbbreviated(monthIndex){
                var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                return months[monthIndex];
            }
        },

        /**
         * Once mounted this component needs to load the reservation specified
         */
        mounted() {
            this.loadReservation();
        },

        computed: {}
    }
</script>
