<template>
<div>
    <h1>Voucher Summary</h1>

    <v-expansion-panels>
    <v-expansion-panel>
        <v-expansion-panel-header>
            Date: {{ date }} Voucher Type: {{ voucherType }} Grouped: {{ groupByDay }}
        </v-expansion-panel-header>
        <v-expansion-panel-content  :value="1"> 
            <!-- date picker -->
            <v-layout justify-center ma-4>
                <v-flex>
                    <v-date-picker
                        :landscape="true"
                        v-model="date"
                        color="lime"
                        type="month"
                    ></v-date-picker> 
                </v-flex>
                <v-flex>
                    <!-- group by day -->
                    <v-switch v-model="groupByDay" :label="`Group by day ${groupByDay.toString()}`" />
                    <!-- voucher type -->
                    <v-radio-group v-model="voucherType">
                        <v-radio label="Digital" :value="2"></v-radio>
                        <v-radio label="Physical" :value="1"></v-radio>
                        <v-radio label="Both" :value="3"></v-radio>
                    </v-radio-group>
                </v-flex>
            </v-layout>
            <!-- submit -->
            <v-layout justify-center ma-4>
                <v-btn @click="getVouchers()">Submit</v-btn>
            </v-layout>

            <v-layout justify-center ma-2>
                <export-csv :filename="'accounts-vouchers'" :data="vouchers" />
            </v-layout>
        </v-expansion-panel-content>
    </v-expansion-panel>
    </v-expansion-panels>

    <v-data-table
            :headers="headers"
            :items="vouchers.data"
            hide-default-footer
            class="elevation-1"
        >
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-center">{{ formattedDateDDMMYYYY(props.item.creation_date) }}</td>
            <td class="text-xs-center">{{ props.item.purchaser_email }}</td>
            <td class="text-xs-center">{{ props.item.voucher_code }}</td>
            <td class="text-xs-center">{{ props.item.voucher_type | voucher-type }}</td>
            <td class="text-xs-center">£{{ props.item.voucher_value.toFixed(2) }}</td>
            <td class="text-xs-center">£{{ props.item.transaction_value.toFixed(2) }}</td>
          </tr>
        </template>

         <template slot="footer">
            <td colspan="3"></td>
            <td class="font-weight-bold text-xs-center">
                Total
            </td>
            <td class="font-weight-bold text-xs-center">
                £{{ vouchers.total_value.toFixed(2) }}
            </td>
            <td class="font-weight-bold text-xs-center">
                £{{ vouchers.total_transaction.toFixed(2) }}
            </td>
        </template>
  </v-data-table>
</div>
    
</template>

<script>
import ExportCsv from '@/components/common/ExportCSV'

export default {
    components: {
        ExportCsv
    },
    data() {
        return {
            headers: [],
            vouchers: [],
            date: '',
            voucherType: 3,
            groupByDay: false,
        }
    },
    created() {
        this.setHeaders();
        this.setStartDate();
        this.getVouchers();
    },
    methods: {
        async getVouchers() {
            const res = await axios('/api/accounts/vouchers', {
                method: 'GET',
                params: {
                    date: this.date,
                    voucherType: this.voucherType,
                    groupByDay: this.groupByDay,
                },
            });

            this.vouchers = res.data;
        },
        setStartDate() {
            const date = new Date();
            this.date = `${date.getFullYear()}-${date.getMonth() + 1}`;
            console.log(this.date)
        },
        setHeaders() {
            this.headers = [
                {
                    text: 'Creation Date',
                    align: 'center',
                    value: 'creation_date'
                },
                {
                    text: 'Purchaser Email',
                    align: 'center',
                    value: 'purchaser_email'
                },
                {
                    text: 'Voucher Code',
                    align: 'center',
                    value: 'voucher_code'
                },
                {
                    text: 'Voucher Type',
                    align: 'center',
                    value: 'voucher_type'
                },
                {
                    text: 'Voucher Value',
                    align: 'center',
                    value: 'voucher_value'
                },
                  {
                    text: 'Transaction Value',
                    align: 'center',
                    value: 'transaction_value'
                },
            ]
        },

        formattedDateDDMMYYYY(dateYYYYMMDD){
            console.log(dateYYYYMMDD);
            if (!dateYYYYMMDD || dateYYYYMMDD == '0000-00-00'){
                return '';
            }
            var date = new Date(Date.parse(dateYYYYMMDD));
            var day = date.getDate();
            var month = date.getMonth() + 1;
            var year = date.getFullYear();
            return (day < 10 ? '0'+day : day) + '/' + (month < 10 ? '0'+month : month) + '/' + year;
        }
    }
    
}
</script>

<style lang="scss" scoped>
    .font-weight-bold {
        font-weight: 700;
    }
</style>

