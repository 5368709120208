<template>
    <v-container fluid>
        <v-flex xs12 v-for="(field, fieldKey) in section.fields" :key="fieldKey" class="ma-3">
            <v-layout row>
                    <v-col cols="12" class="mb-2">
                    <v-layout row>
                        <v-flex sm2>
                            <strong v-if="field.isCustom">Custom field:</strong>
                            <strong v-else-if="field.isComposite">Composite field:</strong>
                            <strong v-else-if="field.isAssociation">Associated field:</strong>
                            <strong v-else="field.isModelField">Model field:</strong>
                        </v-flex>
                    </v-layout>

                    	<v-col cols="12" class="mb-5">
                        	<v-btn @click.native.stop="moveField('up', fieldKey)">Move up </v-btn>
                        	<v-btn class="ies-coral" @click.native.stop="deleteField(fieldKey)">  Delete </v-btn>
                        	<v-btn @click.native.stop="moveField('down', fieldKey)"> Mode Down </v-btn>
                   	 </v-col>
                    </v-col>

                    <!-- Select Model -->
                    <v-col cols="12" md="5" class="mb-3" v-if="!field.isCustom && !field.isComposite">
                        <v-layout row>
                            <v-flex sm4>
                                <label>Model</label>
                            </v-flex>
                            <v-flex sm8>
                                <select  class="full-width"
                                v-model="field.modelName"
                                @change="field.fieldName = ''; selectModel(field.modelName)">
                                    <option value="">Please Select A Model </option>
                                    <option :value="modelName" v-for="modelName in sortModels(models)" :key="modelName" > {{ modelName }} </option>
                                </select>
                            </v-flex>
                        </v-layout>
                    </v-col>

                    <!-- Select column -->
                    <v-col cols="12" md="5" class="mb-3"  v-if="!field.isCustom && (!field.isComposite || field.isAssociation)">
                        <v-layout row>
                            <v-flex sm4>
                                <label> Column</label>
                            </v-flex>
                            <v-flex sm8>
                                <select class="full-width"
                                v-model="field.fieldName"
                                v-on:change="field.displayType = 'default'" >
                                    <option value="">Please Select a Column</option>
                                    <option :value="columnFieldName" v-for="columnFieldName in sortColumns(field.modelName)" > {{ columnFieldName }} </option>
                                    <option v-if="field.isAssociation" value="all">Whole model (works only with custom component)</option>
                                </select>
                            </v-flex>
                        </v-layout>
                    </v-col>

                    <!-- Composite field -->
                    <v-col cols="12" md="5" class="mb-3">
                        <v-layout>
                            <v-flex sm4>
                                <label>Display Name</label>
                            </v-flex>
                            <v-flex sm8>
                                <v-text-field outlined v-model="field.displayName"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-col>

                    <!-- Used to provide a field name for the system to use when referencing the column, regardless of its display name for human peoples -->
                    <v-col cols="12" md="5" class="mb-3">
                        <v-layout>
                            <v-flex sm4>
                                <label>Alias Field Name</label>
                            </v-flex>
                            <v-flex sm8>
                                <v-text-field outlined v-model="field.alias"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-col>

                    <!-- select display type -->
                    <!--its better here in v-if to refactor and say when we DO NOT want to display it, but at that point im not sure when it could be-->
                    <v-col cols="12" md="5" v-if="(field.fieldName && field.fieldName != '' && !field.isCustom) || (field.models && field.models.length > 0) || (field.isAssociation || field.isComposite)" class="mb-3">
                        <v-layout row>
                            <v-flex sm4>
                                <label>Display Type</label>
                            </v-flex>
                            <v-flex sm8>
                                <select class="full-width"
                                v-model="field.displayType">
                                    <option value="default" >Default</option>
                                    <option value="custom" >Custom</option>
                                    <option :value="type.value" v-for="(type, typeKey) in selectDisplayTypes(field)" :key="typeKey"> {{ type.name }} </option>
                                </select>
                            </v-flex>
                        </v-layout>
                    </v-col>

                    <!-- Select Width -->
                    <v-col cols="12" md="5" class="mb-3" v-if="design.page_mode == '2'">
                        <v-row>
                            <v-col sm="4">
                                <label>Field Width</label>
                            </v-col>
                            <v-col sm="8">
                                <v-text-field outlined type="number" min="1" max="12" placeholder="12" v-model="field.fieldWidth"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>

                     <!-- Required switch only visible where required statement was implemented -->
                    <v-col cols="12" md="3" class="mb-3 designer-switch"
                            v-if="( ['custom', 'text', 'password','integer','email','tinymce', 'htmlinput'].includes(field.displayType) )">
                        <v-layout>
                            <v-flex sm4>
                                <label>Required?</label>
                            </v-flex>
                            <v-flex sm8>
                                <v-switch v-model="field.required" light></v-switch>
                            </v-flex>
                        </v-layout>
                    </v-col>

                    <!-- Disabled switch only visible where required statement was implemented -->
                    <v-col cols="12" md="3" class="mb-3 designer-switch"
                            v-if="( ['text', 'password','integer','email', 'textarea', 'htmlinput'].includes(field.displayType) )">
                        <v-layout>
                            <v-flex sm4>
                                <label>Disabled?</label>
                            </v-flex>
                            <v-flex sm8>
                                <v-switch color="red" v-model="field.disabled" light></v-switch>
                            </v-flex>
                        </v-layout>
                    </v-col>
                    <v-col cols="12" class="mb-3" v-if="design.page_mode == '2'">
                        <v-layout>
                            <v-flex sm4>
                                <label>Pre-Notice</label>
                            </v-flex>
                            <v-flex sm8>
                                <v-text-field outlined v-model="field.introduction"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-col>
                    <v-col cols="12" class="mb-3" v-if="design.page_mode == 2">
                        <v-layout>
                            <v-flex sm4>
                                <label>Information</label>
                            </v-flex>
                            <v-flex sm8>
                                <v-text-field outlined v-model="field.information"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-col>
                    <v-col cols="12" class="mb-3" v-if="design.page_mode == 2">
                        <v-layout>
                            <v-flex sm4>
                                <label>Warning</label>
                            </v-flex>
                            <v-flex sm8>
                                <v-text-field outlined v-model="field.warning"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-col>
                    <v-col cols="12"  class="mb-3" v-if="design.page_mode == 2">
                        <v-layout>
                            <v-flex sm4>
                                <label>Post-Notice</label>
                            </v-flex>
                            <v-flex sm8>
                                <v-text-field outlined v-model="field.outroduction"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-col>


                    <!-- Composite field -->
                    <v-col cols="12" class="mb-3">
                        <v-layout row>
                            <v-col cols="12" md="2">
                                <label>Validation</label>
                            </v-col>


                            <v-col cols="12">
                              <v-container>
                                <v-layout row v-for="(validation, ruleIndex) in field.validation" :key="ruleIndex">
                                    <v-col cols="3">
                                    <select class="full-width"
                                        label="Rule Type"
                                        v-model="validation.ruleType">
                                        <option value="">Please Select Type</option>
                                        <option value="minLength">Minimum Length</option>
                                        <option value="maxLength">Maximum Length</option>
                                        <option value="minValue">Minimum Value</option>
                                        <option value="maximumValue">Maximum Value</option>
                                        <option value="notEmpty">Not Empty</option>
                                        <option value="regEx">Regular Expression</option>
                                    </select>
                                    </v-col>
    
                                    <v-col cols="3">
                                        <v-text-field label="Value (where applicable)" v-model="validation.value"></v-text-field>
                                    </v-col>
                                    <v-col cols="1">
                                        <v-btn v-on:click="removeValidation(fieldKey, ruleIndex)">- Remove</v-btn>
                                    </v-col>
                                </v-layout>
                              </v-container>
                            </v-col>

                            <v-col cols="12">
                                <v-btn v-on:click="addValidation(fieldKey)">+ Add Rule</v-btn>
                            </v-col>
                        </v-layout>
                    </v-col>

                    <v-col cols="12" v-if="(field.fieldName && field.fieldName != '' && !field.isCustom) || (field.models && field.models.length > 0) || (field.isAssociation || field.isComposite)" class="mb-3">
                        <v-layout row>
                            <v-flex sm4>
                                <label>Aggregation</label>
                            </v-flex>
                            <v-flex sm8>
                                <select class="full-width"
                                v-model="field.aggregation">
                                    <option value="" >None - use Raw Value</option>
                                    <option value="AVG" >Average</option>
                                    <option value="COUNT" >Count</option>
                                    <option value="MAX" >Max</option>
                                    <option value="MIN" >Min</option>
                                    <option value="SUM" >Sum</option>
                                </select>
                            </v-flex>
                        </v-layout>
                    </v-col>

                    <!-- Text box custom component -->
                    <v-col cols="12" v-if="field.displayType && field.displayType == 'custom'" class="mb-3">
                        <v-layout>
                            <v-flex sm4>
                                <label>Custom Component</label>
                            </v-flex>
                            <v-flex sm8>
                                <input type="text" v-model="field.customComponent">
                            </v-flex>
                        </v-layout>
                    </v-col>


                    <!-- Composite field -->
                    <v-flex class="mb-3" v-if="field.isComposite">
                        <v-flex xs12>
                            <v-layout row>

                                <v-flex sm4>
                                    <label>Model A</label>
                                </v-flex>
                                <v-flex sm8>
                                    <select  class="full-width"
                                        v-model="field.modelNameA"
                                        v-on:change="field.fieldNameA = '';  selectModel(field.modelNameA)" >
                                            <option value="">Please Select Model A</option>
                                            <option :value="modelName" v-for="modelName in sortModels(models)" :key="modelName" > {{ modelName }} </option>
                                    </select>
                                </v-flex>

                                <v-flex sm4>
                                    <label> Column A</label>
                                </v-flex>
                                <v-flex sm8>
                                    <select class="full-width"
                                        v-model="field.fieldNameA"
                                        v-on:change="field.displayType = 'default'" >
                                            <option value="">Please Select Column A</option>
                                            <option :value="columnFieldName" v-for="columnFieldName in sortColumns(field.modelNameA)" > {{ columnFieldName }} </option>
                                    </select>
                                </v-flex>

                            </v-layout>
                        </v-flex>



                        <v-flex xs12>
                            <v-layout row>

                                <v-flex sm4>
                                    <label>Model B</label>
                                </v-flex>
                                <v-flex sm8>
                                    <select  class="full-width"
                                        v-model="field.modelNameB"
                                        v-on:change="field.fieldNameB = '';  selectModel(field.modelNameB)" >
                                            <option value="">Please Select Model B</option>
                                            <option :value="modelName" v-for="modelName in sortModels(models)" :key="modelName" > {{ modelName }} </option>
                                    </select>
                                </v-flex>

                                <v-flex sm4>
                                    <label> Column B</label>
                                </v-flex>
                                <v-flex sm8>
                                    <select class="full-width"
                                        v-model="field.fieldNameB"
                                        v-on:change="field.displayType = 'default'" >
                                            <option value="">Please Select Column B</option>
                                            <option :value="columnFieldName" v-for="columnFieldName in sortColumns(field.modelNameB)" > {{ columnFieldName }} </option>
                                    </select>
                                </v-flex>

                            </v-layout>
                        </v-flex>
                    </v-flex>

                <!-- Additional relationships -->
                <v-flex class="mb-3" v-if="showAdditionalRelationships(field)">
                    <v-flex xs12>
                        <v-layout row>
                            <v-flex xs12><strong>Enter relationships between primary model and the model that you want to display in this field.</strong></v-flex>
                        </v-layout>

                        <v-layout row v-for="(relation, index) in field.fieldRelationships" :key='index'>
                            <v-flex xs12>

                                <v-container fluid class="pt-0 pb-0">
                                    <v-layout row wrap>

                                        <v-flex xs12 sm4 class="mr-2">
                                            <v-select
                                                    :items="modelFieldNames"
                                                    v-model="relation.modelFieldA"
                                                    label="Model-field A"
                                                    class=""
                                                    item-value="value"
                                                    single-line
                                                    autocomplete
                                            ></v-select>
                                        </v-flex>

                                        <v-flex xs12 sm2 class="mr-2">
                                            <v-select
                                                    :items="joinTypes"
                                                    v-model="relation.joinType"
                                                    label="Join Type"
                                                    class=""
                                                    item-value="value"
                                                    single-line
                                            ></v-select>
                                        </v-flex>

                                        <v-flex xs12 sm4 class="mr-2">
                                            <v-select
                                                    :items="modelFieldNames"
                                                    v-model="relation.modelFieldB"
                                                    label="Model-field B"
                                                    class=""
                                                    item-value="value"
                                                    single-line
                                                    autocomplete
                                            ></v-select>
                                        </v-flex>

                                        <v-flex xs12 sm1 class="mt-2">
                                            <v-btn v-on:click="removeRelationship(fieldKey, index)"><v-icon>delete</v-icon> Remove</v-btn>
                                        </v-flex>

                                    </v-layout>
                                </v-container>

                            </v-flex>
                        </v-layout>


                        <v-flex xs12>
                            <v-btn v-on:click="addRelationship(fieldKey)"><v-icon>add</v-icon> Add Relationship</v-btn>
                        </v-flex>

                        <v-card-actions>
                            <v-btn v-on:click="removeAllRelationships(fieldKey)" :disabled="getFieldRelationshipsLength(fieldKey) == 0"><v-icon>cancel</v-icon> Remove Relationships</v-btn>
                        </v-card-actions>
                    </v-flex>
                </v-flex>

                </v-layout>
                <v-divider class="mt-3 mb-3" ></v-divider>
            </v-flex>
        </v-container>
</template>

<script>
    export default {
        name: 'PortalDesignerFields',
        props: ['section', 'sectionkey', 'used_models', 'models', 'field_display_types', 'modelFieldNames', 'joinTypes', 'primaryModel', 'design'],
        data () {
            return {
                'currentSection' : this.section,
                'sectionKey' : this.sectionkey,
                'fieldDisplayTypes' : this.field_display_types,
                'fieldCount': this.section.fields.length,
                'runCount': 0
            }
        },
        mounted: function(){
            console.log("page design:", this.design);
        },
        methods: {
            updateObject: function(){
                this.section = this.currentSection;
            },
            selectModel: function(modelName){
                if(this.runCount < this.fieldCount){
                    this.runCount +=1;
                    return;
                }
                if(modelName != ''){
                    this.used_models.push(modelName);
                }
            },

            /**
             * Returns an alphabetised array of names of provided models
             * @param models - an object containing models
             */
            sortModels: function(models){
                let keys = Object.keys(models).sort();
                return keys;
            },

            /**
             * Returns an alphabetised array of fields on a given model
             * @param string modelName - the name of a model
             */
            sortColumns: function(modelName){
                var fields = this.models[modelName];
                if (fields) {
                    let keys = Object.keys(fields).sort();
                    fields = keys;
                }
                return  fields;
            },
            selectDisplayTypes: function(field){

               if (field.isComposite) {
                   return this.field_display_types['composite'];
               }

               if (field.isAssociation) {
                   return this.field_display_types['association'];
               }

               var fields = this.models[field.modelName];

               if(fields){
                   var dataType = fields[field.fieldName].dataType;
                   if(dataType){
                       if(this.field_display_types[dataType]){
                           return this.field_display_types[dataType];
                       }
                   }
               }
            },
            deleteField: function(field){
               delete this.section['fields'][field];
               this.section['fields'] =  this.section['fields'].filter(function(n){ return n != undefined });
               this.currentSection = this.section;
            },
            moveField: function(direction, fieldKey) {
               //determine desintation

               if(direction == 'up'){
                   var destinationKey = fieldKey-1
               }
               else{
                   var destinationKey = fieldKey+1
               }

               if( this.section['fields'][destinationKey]){
                   // get fields
                   var fieldToMove = this.section['fields'][fieldKey];
                   var fieldToReplace = this.section['fields'][destinationKey];

                   // swap fields
                   this.section['fields'][fieldKey] = fieldToReplace;
                   this.section['fields'][destinationKey] = fieldToMove

                   // update to refresh render
                   this.currentSection = this.section;
                   this.section['fields'] =  this.section['fields'].filter(function(n){ return n != undefined });
               }

            },

            addValidation: function(fieldKey){
console.log("adding validation");
                var field = this.section.fields[fieldKey];
                if (!field.validation){
                    field.validation = [];
                }
                field.validation.push({"ruleType": "", "value": ""});
                console.log(field);
            },

            removeValidation: function(fieldKey, index){
                var field = this.section.fields[fieldKey];
console.log(field);
                field.validation.splice(index, 1);
            },

            /**
             * This method checks current field and decides if it should display section when we can add additional relationships
             * between primary model and this field.
             *
             * Show this field if:
             *  1) it's association field
             *  2) it's composite field and it's using foreign model
             *
             *  @returns boolean
             */
            showAdditionalRelationships(field) {
                if (field.hasOwnProperty('isAssociation')) {
                    if (field.isAssociation) {
                        return true;
                    }
                }

                if (field.hasOwnProperty('isComposite')) {
                    let primary = this.primaryModel;
                    if (field.isComposite && (primary !== field.modelNameA || primary !== field.modelNameB) &&
                        (field.modelNameA !== '' && field.modelNameB !== '')) {
                        return true;
                    }
                }
            },
            /**
             * Add blank relationship to this field
             * @param fieldKey
             */
            addRelationship(fieldKey) {
                // get currect field
                var field = this.section.fields[fieldKey];

                // fallback if this field doesn't have fieldRelationship because it's old field, add it
                if (!field.hasOwnProperty('fieldRelationships')) {
                    field['fieldRelationships'] = [];
                }

                // add new relationship to this field
                field.fieldRelationships.push({ 'modelFieldA': '', 'joinType': '', 'modelFieldB': '' });
            },

            /**
             * Remove clicked relationship from this field
             * @param fieldKey
             * @param index
             */
            removeRelationship(fieldKey, index) {
                // get currect field
                var field = this.section.fields[fieldKey];

                field.fieldRelationships.splice(index, 1);
            },
            /**
             * Get number of relationship that this field has
             * @param fieldKey
             * @returns {number}
             */
            getFieldRelationshipsLength(fieldKey) {
                var field = this.section.fields[fieldKey];

                // fallback if this field doesn't have fieldRelationship because it's old field, add it
                if (!field.hasOwnProperty('fieldRelationships')) {
                    field['fieldRelationships'] = [];
                }

                return field.fieldRelationships.length;
            },
            /**
             * Remove all relationships from current field
             * @param fieldKey
             */
            removeAllRelationships(fieldKey) {
                // get currect field
                var field = this.section.fields[fieldKey];

                field.fieldRelationships = [];
            },
        },


}
</script>

<style>
    .designer-switch {
        margin: 0;
        max-height: 40px;
    }
</style>
