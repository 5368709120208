<template>
    <v-flex xs12 sm12 md2 lg2 ma-1>
        <v-card>
            <v-card-text>
              <a href="/newsletter-subscribers/list">
                <div class="stats-icon"><v-icon style="font-size: 3rem;">send</v-icon></div>
                <div class="stats-text">
                    <div class="stats-figure">{{ stats }}</div>
                    <div class="stats-descriptor">New Subscribers</div>
                </div>
              </a>
            </v-card-text>
        </v-card>
    </v-flex>
</template>

<script>
    import axios from 'axios';

    export default {
        props:['type'],
        data: function() {
            return {
                data: { new: '...' },
                yesterday: null,
                yesterdayString: ''
            }
        },
        created:function() {
            this.fetchNotifications();
        },
        methods:{

            fetchNotifications:function(){
                this.yesterday = new Date();
                this.yesterday.setDate(this.yesterday.getDate() - 1);
                this.yesterdayString = this.yesterday.getFullYear() + '-' + (this.yesterday.getMonth() < 9  ? '0'+(this.yesterday.getMonth() + 1) : (this.yesterday.getMonth() + 1)) + '-' + (this.yesterday.getDate() < 10 ? '0'+this.yesterday.getDate() : this.yesterday.getDate());
                var params = { dateFrom: this.yesterdayString};
                axios.get('/api/newsletter-signups', { params: params })
                .then(function(response){
console.log('newsletter signups: response data', response.data.stats);
                    this.data.new = response.data.stats[this.yesterdayString].monthlyNewsletterSignups + response.data.stats[this.yesterdayString].familyNewsletterSignups;
                }.bind(this));
            }
        },
        computed: {
            stats: function(){
                return this.data.new;
            }
        }
    }
</script>

<style>
@media only screen and (max-width: 600px) {
    .list__tile__title {
        font-size: 10px;
    }
}

.stats-icon {
    font-size: 3rem;
    width: 33%;
    display: inline-block;
    text-align: center;
}

.stats-text {
    width: 65%;
    display: inline-block;
}

.stats-figure{
    text-decoration: bold;
    font-size: 1.5rem;
}

.stats-descriptor{
}
</style>
