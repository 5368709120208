<template>
    <v-container fluid>
        <h2>Quick Search</h2>
        <v-layout row wrap justify-center>
            <v-col cols="12" md="6" lg="8">
            
             
             <v-layout row wrap justify-center>
             <v-col cols="12" md="6" lg="6" ma-1
                    id="daterangeColumn"
                    xs12
                    md6
            >
                <v-flex pl-3>
                    <h6 class="title">Filter by Date</h6>
                </v-flex>
                <!-- DATE PICKER -->

                <v-date-picker range
                               color="lime"
                               v-model="drprange"
                               @input="onDateRangeChange"
                ></v-date-picker>

            </v-col>


            <!-- FILTERS -->
            <v-col cols="12" md="6" lg="4" ma-1
                    id="filtersColumn"
                    xs2
                    md3
                    lg4
            >
                <v-layout row wrap>
                    
                        <v-col pl-3 cols="12">
                            <h6 class="title">Filter by Properties</h6>
                        </v-col>

                        <v-col cols="5">
                            <v-subheader>Date selector</v-subheader>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                    v-model="filters.dateColumn"
                                    :items="filterOptions.dateColumn"
                                    return-object
                            ></v-select>
                        </v-col>

                            <v-col cols="4">
                                <v-subheader>Voucher Type</v-subheader>
                            </v-col>
                            <v-col cols="7">
                                <v-select
                                        v-model="filters.voucherType"
                                        :items="filterOptions.voucherType"
                                        return-object
                                ></v-select>
                            </v-col>

                            <v-col cols="4">
                                <v-subheader>Source Type</v-subheader>
                            </v-col>
                            <v-col cols="7">
                                <v-select
                                        v-model="filters.sourceType"
                                        :items="filterOptions.sourceType"
                                        multiple
                                ></v-select>
                            </v-col>

                            <v-col cols="4">
                                <v-subheader>Redeem status</v-subheader>
                            </v-col>
                            <v-col cols="7">
                                <v-select
                                        v-model="filters.redeemStatus"
                                        :items="filterOptions.redeemStatus"
                                        return-object
                                ></v-select>
                            </v-col>

                            <v-col cols="4">
                                <v-subheader>Active status</v-subheader>
                            </v-col>
                            <v-col cols="7">
                                <v-select
                                        v-model="filters.activeStatus"
                                        :items="filterOptions.activeStatus"
                                        return-object
                                ></v-select>
                            </v-col>
                    
                </v-layout>
            </v-col>


            <!-- Buttons -->
            <v-col cols="12" style="text:align: center;">
                <v-btn class="ies-green" @click="filter()">Filter</v-btn>
                <v-btn @click="reset()">Reset</v-btn>
            </v-col>


            </v-layout>
            </v-col>
            


            <!-- Results -->
            <v-col cols="12" md="6" lg="4" ma-1
                    xs12
                    sm6
                    md3
                    lg2
            >
                <ies-voucher-filter-result
                        :show="showFilterResults"
                        :result="filterResults"
                        :range="filteredDates"
                ></ies-voucher-filter-result>
            </v-col>

        </v-layout>

        <div id="linearWrapper">
            <v-progress-linear :indeterminate="true" v-show="showProgressIcon" color="lime"></v-progress-linear>
        </div>

    <h2>Comparison With Past Dates</h2>
    <v-expansion-panels>
        <!-- Comparison -->
        <v-expansion-panel
                expand
        >
            <v-expansion-panel-header>
                <label>Compare With Past Dates</label>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-layout row wrap>
                    <div full-width 
                            offset-y 
                            :close-on-content-click="false" 
                            v-model="comparisonMenu" 
                            bottom 
                        >
                        <!--<v-btn color="lime" outlined >{{ compareRange.start }} &mdash; {{ compareRange.end }}</v-btn>-->
                        <v-card>
                            <v-card-text>
                                <v-date-picker range
                                   color="lime"
                                   v-model="compdrprange"
                                   @input="onCompareDateRangeChange"
                                ></v-date-picker>
                            </v-card-text>
                            <v-layout row wrap justify-center>
                                <v-btn id="compareBtn" color="lime" @click="compare()"><v-icon>compare_arrows</v-icon>Compare</v-btn>
                            </v-layout>
                        </v-card>
                        <!--<i class="material-icons closeIcon" @click="closeCompareDatePicker()">close</i>-->
                    </div>
                </v-layout>

                <v-layout
                        v-if="showComparisonResultsTables"
                        row
                        wrap
                        justify-center
                >
                    <v-flex
                            xs12
                            sm6
                            md3
                            lg3
                    >
                        <ies-voucher-filter-result
                                :show="showFilterResults"
                                :result="filterResults"
                                :range="filteredDates"
                                subname="A"
                        ></ies-voucher-filter-result>
                    </v-flex>
                    <v-flex
                            xs12
                            sm6
                            md3
                            lg3
                    >
                        <ies-voucher-filter-result
                                :show="showFilterResults"
                                :result="filterComparisonResults"
                                :range="filteredComparisonDates"
                                subname="B"
                        ></ies-voucher-filter-result>
                    </v-flex>
                    <v-flex
                            xs12
                            sm6
                            md3
                            lg3
                    >
                        <ies-voucher-filter-result
                                :show="showFilterResults"
                                :result="comparisonDifferences"
                                difference="true"
                        ></ies-voucher-filter-result>
                    </v-flex>
                </v-layout>

            </v-expansion-panel-content>
         </v-expansion-panel>


         <!-- Table with result filtered vouchers -->
         <v-expansion-panel>
            <v-expansion-panel-header>
                <label>Result Table</label>
            </v-expansion-panel-header>
            <v-expansion-panel-content :value="showResultTable">
                <div id="filterResultsTable">
                    <v-layout row wrap align-center justify-center>
                        <v-flex

                                xs12
                                md10
                        >
                            <v-layout row wrap justify-space-between>
                                <v-flex xs3 id="tableSearch">
                                    <v-text-field
                                            v-model="search"
                                            append-icon="search"
                                            label="Search table"
                                    ></v-text-field>
                                </v-flex>
                                <v-btn v-on:click="downloadCSV()" color="lime"><v-icon>file_download</v-icon> CSV</v-btn>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                    <v-layout row wrap justify-center>
                        <v-flex
                                xs12
                                md10
                        >***
                            <v-data-table
                                    :headers="vouchersTableHeaders"
                                    :items="filteredVouchers"
                                    class="elevation-1"
                                    :search="search"
                                    :footer-props.rows-per-page-options="vouchersTableSettings.items"

                            >
                                <template v-slot:item="props">
                                  <tr>
                                    <td>{{ props.item.voucher_code }}</td>
                                    <td>{{ props.item.value }}</td>
                                    <td>{{ props.item.voucher_creation_date }}</td>
                                    <td>{{ props.item.voucher_expiry_date }}</td>
                                    <td>{{ props.item.is_active_string }}</td>
                                    <td>{{ props.item.purchaser_email }}</td>
                                    <td>{{ props.item.recipient_email }}</td>
                                    <td><v-icon class="openVoucherBtn" small
                                                @click="openVoucherPage(props.item.voucher_key)"
                                        >send</v-icon>
                                    </td>
                                  </tr>
                                </template>
                                <v-alert slot="no-results" :value="true" color="error" icon="warning">
                                    Your search for "{{ search }}" found no results.
                                </v-alert>
                            </v-data-table>
                        </v-flex>
                    </v-layout>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- All Time Overview-->
        <v-expansion-panel>
            <v-expansion-panel-header>
                <label>All time - Overview stats</label>
            </v-expansion-panel-header>
            <v-expansion-panel-content value="true">
                <v-card>
                    <v-card-text>
                        
                        <v-layout row wrap>
                            <v-col cols="2" sm2 md2 lg2 v-for="item in overview.allTime"">
                                <v-card flat>
                                    <v-card-title><h5>{{ item.name }}</h5></v-card-title>
                                    <v-divider></v-divider>
                                    <v-list dense v-if="item.name == 'Refer a friend vouchers'">
                                        <v-list-item>
                                            <v-list-item-content>Total Count:</v-list-item-content>
                                            <v-list-item-content class="align-end">{{ item.totalCount }}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>Active Count:</v-list-item-content>
                                            <v-list-item-content class="align-end">{{ item.activeCount }}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>Used Count:</v-list-item-content>
                                            <v-list-item-content class="align-end">{{ item.usedCount }}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>Used Value:</v-list-item-content>
                                            <v-list-item-content class="align-end">£ {{ item.usedTotalValue }}</v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                    <v-list dense v-else-if="item.name == 'Scheduled delivery'">
                                        <v-list-item>
                                            <v-list-item-content>Total Delivered:</v-list-item-content>
                                            <v-list-item-content class="align-end">{{ item.totalDelivered }}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>Delivery failed:</v-list-item-content>
                                            <v-list-item-content class="align-end">{{ item.totalUndelivered }}</v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                    <v-list dense v-else>
                                        <v-list-item>
                                            <v-list-item-content>Total Count:</v-list-item-content>
                                            <v-list-item-content class="align-end">{{ item.totalCount }}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>Total Value:</v-list-item-content>
                                            <v-list-item-content class="align-end">£ {{ item.totalValue }}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>Average Value:</v-list-item-content>
                                            <v-list-item-content class="align-end">£ {{ item.averageValue }}</v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-col>

                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-expansion-panel-content>
         </v-expansion-panel>


         <!-- Last 30 Days Overview-->
         <v-expansion-panel>
            <v-expansion-panel-header>
                <label>Last 30 days - Overview stats</label>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-card>
                    <v-card-text>
                        <v-layout row wrap>
                            <v-col cols="2" v-for="item in overview.recent">
                                <v-card flat>
                                    <v-card-title><h5>{{ item.name }}</h5></v-card-title>
                                    <v-divider></v-divider>
                                    <v-list dense v-if="item.name == 'Refer a friend vouchers'">
                                        <v-list-item>
                                            <v-list-item-content>Total Count:</v-list-item-content>
                                            <v-list-item-content class="align-end">{{ item.totalCount }}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>Active Count:</v-list-item-content>
                                            <v-list-item-content class="align-end">{{ item.activeCount }}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>Used Count:</v-list-item-content>
                                            <v-list-item-content class="align-end">{{ item.usedCount }}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>Used Value:</v-list-item-content>
                                            <v-list-item-content class="align-end">£ {{ item.usedTotalValue }}</v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                    <v-list dense v-else-if="item.name == 'Scheduled delivery'">
                                        <v-list-item>
                                            <v-list-item-content>Total Delivered:</v-list-item-content>
                                            <v-list-item-content class="align-end">{{ item.totalDelivered }}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>Delivery failed:</v-list-item-content>
                                            <v-list-item-content class="align-end">{{ item.totalUndelivered }}</v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                    <v-list dense v-else>
                                        <v-list-item>
                                            <v-list-item-content>Total Count:</v-list-item-content>
                                            <v-list-item-content class="align-end">{{ item.totalCount }}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>Total Value:</v-list-item-content>
                                            <v-list-item-content class="align-end">£ {{ item.totalValue }}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>Average Value:</v-list-item-content>
                                            <v-list-item-content class="align-end">£ {{ item.averageValue }}</v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-col>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

<!-- Success and error snack bars -->
        <v-snackbar
                :timeout="parseInt(2000)"
                v-model="successSnackbar"
                multi-line
                color="ies-green"
        >
            <div class="full-height full-width v-align-container text-xs-center">
                <h4 class="ies-dark-gray--text v-align-div">Results updated</h4>
            </div>
        </v-snackbar>
        <v-snackbar
                :timeout="parseInt(3000)"
                v-model="errorSnackbar"
                multi-line
                color="ies-coral"
        >
            <div class="full-height full-width v-align-container text-xs-center">
                <h4 class="ies-dark-gray--text v-align-div">Error</h4>
            </div>
        </v-snackbar>
    </v-container>
</template>


<script>
    import { format, subDays, subMonths, subYears } from 'date-fns';
    import axios from 'axios';
    import FileSaver from 'file-saver';
    import {progressBar} from '@/mixins/progressBar';

    export default {
        props: ['overview', 'defaults'],
        mixins: [progressBar],
        data() {
            return {
                /** Selected by user filters */
                filters: {},
                /** Selected main date range, default [today, today] */
                range: {"start": "2024-01-01", "end": "2024-01-10"},

                /** @var array drprange - date range picker view model as an array ['YYYY-MM-DD', 'YYYY-MM-DD'] for interacting with the input element - the real defaults are set dynamically after the component is mounted, these are just todays dates to get started and setup the component in the meantime */
                drprange: [ ((new Date()).getFullYear())+'-' + ((new Date()).getMonth() + 1 < 10 ? '0'+(new Date()).getMonth(): (new Date()).getMonth()) + '-' + ((new Date()).getDate() < 10 ? '0' + (new Date()).getDate() : (new Date()).getDate()), ((new Date()).getFullYear())+'-' + ((new Date()).getMonth() + 1 < 10 ? '0'+(new Date()).getMonth(): (new Date()).getMonth()) + '-' + ((new Date()).getDate() < 10 ? '0' + (new Date()).getDate() : (new Date()).getDate()) ],

                /** Selected compare date range, default [today, today] */
                compareRange: {"start": "2023-01-01", "end": "2023-01-10"},

                /** date range picker view model for interacting with the input element */
                compdrprange: [ "2023-01-01", "2023-01-10" ],

                comparisonMenu: false,
                /** Indicates which panel with overview data should be open on page load */
                panel : [true, false],
                successSnackbar: false,
                errorSnackbar: false,

                showFilterResults: true,
                /** Object that stores results from filter API */
                filterResults: {},
                /** array with date range - from and to which was used to filter voucher stats */
                filteredDates: [],
                filteredComparisonDates: [],
                /** Array that stores filtered vouchers to be displayed in data table*/
                filteredVouchers: [],
                /** Object that stores results from comparison filter */
                filterComparisonResults: {},
                showComparisonTab: false,
                showComparisonResultsTables: false,

                /** default filters that should be applied on load */
                defaultFilters: {
                    voucherType: {text: 'All', value: 'all'},
                    sourceType: ['All'],
                    redeemStatus: {text: 'All', value: 'all'},
                    activeStatus: {text: 'All', value: 'all'},
                    dateColumn: {text: 'Creation date', value: 'creation_date'},
                },

                /** all the filter options that user can filter by */
                filterOptions: {
                    voucherType: [
                        {text: 'All', value: 'all'},
                        {text: 'Physical', value: 'physical'},
                        {text: 'E-Card ', value: 'e-card'},
                    ],
                    sourceType: ['All', 'Customer bought', 'Created on portal','Refer a friend', 'Promotional', 'Re-issues', 'Birthday'],
                    redeemStatus: [
                        {text: 'All', value: 'all'},
                        {text: 'Redeemed', value: 'redeemed'},
                        {text: 'Unredeemed', value: 'unredeemed'},
                    ],
                    activeStatus: [
                        {text: 'All', value: 'all'},
                        {text: 'Inactive', value: 'inactive'},
                        {text: 'Active', value: 'active'},
                        {text: 'Expired', value: 'expired', },
                        {text: 'Cancelled', value: 'cancelled'},
                    ],
                    dateColumn: [
                        {text: 'Creation date', value: 'creation_date'},
                        {text: 'Expiration date', value: 'expires'},
                        {text: 'Redemption date', value: 'redeemed'},
                        {text: 'Cancellation date', value: 'cancelled_date'},
                    ],

                },

                /** date range picker options */
                dateRangeOptions: {
                    startDate: format(new Date(), 'yyyy-mm-dd'),
                    endDate: format(new Date(), 'yyyy-mm-dd'),
                    minDate: '2017-11-01',
                    maxDate: '2025-01-01',
                    format: 'yyyy-mm-dd',
                    presets: [
                        {
                            label: 'Today',
                            range: {
                                "start": format(new Date(), 'yyyy-mm-dd'),
                                "end": format(new Date(), 'yyyy-mm-dd'),
                            },
                        },
                        {
                            label: 'Yesterday',
                            range: {
                                "start": format(subDays(new Date(), 1), 'yyyy-mm-dd'),
                                "end": format(subDays(new Date(), 1), 'yyyy-mm-dd'),
                            },
                        },
                        {
                            label: 'Last 7 Days',
                            range: {
                                "start": format(subDays(new Date(), 7), 'yyyy-mm-dd'),
                                "end": format(new Date(), 'yyyy-mm-dd'),
                            },
                        },
                        {
                            label: 'Last 30 Days',
                            range: {
                                "start": format(subDays(new Date(), 30), 'yyyy-mm-dd'),
                                "end": format(new Date(), 'yyyy-mm-dd'),
                            },
                        },
                        {
                            label: 'Last 6 Months',
                            range: {
                                "start": format(subMonths(new Date(), 6), 'yyyy-mm-dd'),
                                "end": format(new Date(), 'yyyy-mm-dd'),
                            },
                        },
                        {
                            label: 'All Time',
                            range: {
                                "start": '2017-10-01', // first created voucher was 2017-11-01, subtracted a month just in case
                                "end": format(new Date(), 'yyyy-mm-dd'),
                            },
                        },
                    ],
                },
                dateRangeOptionsCompare: {
                    startDate: format(new Date(), 'yyyy-mm-dd'),
                    endDate: format(new Date(), 'yyyy-mm-dd'),
                    format: 'yyyy-mm-dd',
                    presets: [
                        {
                            label: 'Today',
                            range: {
                                "start": format(new Date(), 'yyyy-mm-dd'),
                                "end": format(new Date(), 'yyyy-mm-dd'),
                            },
                        },
                        {
                            label: 'Yesterday',
                            range: {
                                "start": format(subDays(new Date(), 1), 'yyyy-mm-dd'),
                                "end": format(subDays(new Date(), 1), 'yyyy-mm-dd'),
                            },
                        },
                        {
                            label: 'Last 7 Days',
                            range: {
                                "start": format(subDays(new Date(), 7), 'yyyy-mm-dd'),
                                "end": format(new Date(), 'yyyy-mm-dd'),
                            },
                        },
                        {
                            label: 'Last 30 Days',
                            range: {
                                "start": format(subDays(new Date(), 30), 'yyyy-mm-dd'),
                                "end": format(new Date(), 'yyyy-mm-dd'),
                            },
                        },
                        {
                            label: 'Last 6 Months',
                            range: {
                                "start": format(subMonths(new Date(), 6), 'yyyy-mm-dd'),
                                "end": format(new Date(), 'yyyy-mm-dd'),
                            },
                        },
                        {
                            label: 'All Time',
                            range: {
                                "start": '2017-10-01', // first created voucher was 2017-11-01, subtracted a month just in case
                                "end": format(new Date(), 'yyyy-mm-dd'),
                            },
                        },
                        {
                            label: 'Same period year ago',
                            range: {
                                "start": format(subYears(new Date(), 1), 'yyyy-mm-dd'),
                                "end": format(subYears(new Date(), 1), 'yyyy-mm-dd'),
                            },
                        },

                    ],
                },
                dateRangeLabels: {
                    start: 'Start Date',
                    end: 'End Date',
                    preset: 'Date range'
                },

                vouchersTableHeaders: [
                    {text:'Voucher Code', value: 'voucher_code', sortable: false},
                    {text:'Value', value: 'value'},
                    {text:'Creation date', value: 'voucher_creation_date'},
                    {text:'Expiry date', value: 'voucher_expiry_date'},
                    {text:'Active', value: 'is_active_string'},
                    {text:'Purchaser email', value: 'purchaser_email'},
                    {text:'Recipient email', value: 'recipient_email'},
                ],
                search: '',
                vouchersTableSettings: {
                    items: [10, 20, 50],
                },
            }
        },
        methods: {
            /**
             * Logic what code should do when any of the dates changes on main date picker, updates this.range object
             * also update year ago date for comparison 'same date year ago' preset
             */
            onDateRangeChange(range) {
//                this.range = range;

                // The date prange picker view model stores the data in an
                // array with start date as element 0, end as element 1
                // so we should take our data model data values from that
console.log(range);
console.log(this.range);
                this.range.start = this.drprange[0];
                this.range.end = this.drprange[1];

                console.log("range", this.range, this.drprange);

                // Only if there are two valid dates can we use them for the
                // same-time-last-year checks
                if (this.range.start && this.range.end){
                    var start = Date.parse(this.range.start);
                    var end = Date.parse(this.range.end);

console.log(start);

                    // If the user entered the dates the wrong way around then we
                    // need to switch the start and end dates to make sense
                    if (start > end){
                        var a = this.range.start;
                        var b = this.range.end;

                        this.range.start = b;
                        this.range.end = a;
                    }
                    this.updateYearAgoDate();
                }
            },

            /**
             * Logic what code should do when any of the dates changes on compare date picker, updates this.range object
             */
            onCompareDateRangeChange(range) {
console.log('on compare date range change ', range);
                this.compareRange = {"start": range[0], "end": range[1]};
console.log('cr set to ', this.compareRange);
            },

            /**
             * Sets default date range that is going to be set in date picker, default date is today's
             */
            setDefaultDateRange() {
console.log(this.dateRangeOptions);
                //this.range = {"start": this.dateRangeOptions.startDate, "end": this.dateRangeOptions.endDate};
                //this.compareRange = {"start": this.dateRangeOptions.startDate, "end": this.dateRangeOptions.endDate};
console.log('cr set to ', this.compareRange);

                var today = new Date();
                var date = today.getDate();
                var month = today.getMonth() + 1;
                var year = today.getFullYear();
          
                var todayString = year + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date);
                var lastYrString = (year - 1) + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date);

                this.range = {"start": todayString, "end": todayString},
                this.drprange = [ todayString, todayString ];
                this.compareRange = {"start": lastYrString, "end": lastYrString},
                this.compdrprange = [ lastYrString, lastYrString ];
            },

            /**
             * Sets default filters that are going to be displayed in all filter selects
             */
            setDefaultFilters() {
                this.filters = {
                    voucherType: {text: 'All', value: 'all'},
                    sourceType: ['All'],
                    redeemStatus: {text: 'All', value: 'all'},
                    activeStatus: {text: 'All', value: 'all'},
                    dateColumn: {text: 'Creation date', value: 'creation_date'},
                };
            },

            /**
             * Loads default data to filter result table
             */
            setDefaultResults() {
                this.filterResults = this.defaults.stats;
                this.filteredVouchers = this.defaults.vouchers;
                this.filteredDates = this.range;
            },

            /**
             * get filtered data from main filter search,
             * also it checks if comparison table was used, if yes also update comparison results
             */
            filter() {
                this.progressIconShow();

                this.getData(this.range, this.filters, 'filter');
                
                if (this.showComparisonResultsTables) {
                    this.compare();
                }
            },

            /**
             * Trigger compare between main filter results and selected comparison date range
             * First check if main filters are up to date, or maybe user change main date range
             * and didn't press filter button, we want to make sure that main filter results so if
             * they are not, we'll update main results first,
             * then get stats for comparison range and calculates the difference
             * at the end make all three results tables visible
             */
            compare() {
                this.progressIconShow();

                // check if main filters are up to date
                if ((this.range.start !== this.filteredDates.start) || (this.range.end !== this.filteredDates.end)) {
                    // if not update main filter results
                    this.getData(this.range, this.filters, 'filter');
                }

                this.getData(this.compareRange, this.filters, 'compare');

                // show compare results
                this.showComparisonResults();

                this.closeCompareDatePicker();
            },

            /**
             * Sends ajax to our dashboard API endpoint and updates appropriate properties depends what action triggered that call
             */
            getData(range, filters, action) {
                // create json with selected date range and filters that will be send in ajax call
                var dataToSend = JSON.stringify({'range':range, 'filters':filters, 'action': action});

                // send ajax call
                axios.post('api/vouchers/dashFilter',{ data : dataToSend})
                    .then(function(response) {

                        if (response.data.success) {

                            // depends on the action, API will return different data in response
                            if (action === 'filter') {
                                this.filterResults = response.data.data.stats;
                                this.filteredVouchers = response.data.data.vouchers;
                                this.filteredDates = range;
                                this.showFilterResults = true;
                            }
                            else if (action === 'compare') {
                                this.filterComparisonResults = response.data.data.stats;
                                this.filteredComparisonDates = range;
                            }

                            this.showSnackbar('success');
                        }
                        else {
                            this.showSnackbar('error');
                        }
                        this.progressIconHide();
                    }.bind(this))
                    .catch(function(error) {
                        this.showSnackbar('error', 'There was a problem with API call');
                        this.progressIconHide();
                    }.bind(this));
            },

            /**
             * makes visible three tables with comparison data:
             * 1. stats for main data range and filters
             * 2. stats for comparison data range
             * 3. difference data
             * But only show comparison results where results were calculated correctly
             */
            showComparisonResults() {
                if (this.comparisonDifferences != {}) {
                    this.showComparisonResultsTables = true;
                }
            },


            /**
             * Calculate difference between given two values from main and comparison filter
             * @param main number - value from main filter
             * @param comp number - value from comparison filter
             */
            calculateSingleDifference(main, comp) {
                let diff = main - comp;
                comp = comp == 0 ? '1' : comp;
                let percentDiff = main / comp * 100;

                return this.roundToTwo(diff) + ' (' + this.roundToTwo(percentDiff) + '%)';
            },

            /**
             * Round given value to 2 decimal places
             */
            roundToTwo(value) {
                return(Math.round(value * 100) / 100);
            },

            /**
             * Resets filters to default values and set selected date as Today
             * We're using 'click' method, not simply updating model values, to make sure both model and what is displaying
             * on date picker is the same default values
             */
            reset() {
                this.setDefaultFilters();
                // set date to Today, we use here jquery click method as changing range data doesn't change dates highlighted on date picker
                $("div.list__item__content:contains('Today')").click();
            },

            /**
             * Builds array with filtered vouchers and calls out API to get content for CSV file,
             * generates CSV file and download it
             */
            downloadCSV() {
                if (this.filteredVouchers.length) {
                    this.progressIconShow();

                    // parse vouchers data to correct CSV file structure so we can use /getDynamicCsv method
                    let vouchersAsArray = this.parseVouchersToCSV();

                    // build data object that we have to send with AJAX call
                    let dataObj = {array: vouchersAsArray};
                    let url = 'ajax/getDynamicCsv';

                    // AJAX to i-escape server to get CSV file
                    axios.post(url, dataObj)
                        .then(function(response) {
                            if(response.data && response.status == 200) {
                                this.exportFile(response.data);
                                this.progressIconHide();
                            }
                            else {
                                this.progressIconHide();
                                alert('There was a problem with exporting a CSV file');
                            }
                        }.bind(this));
                }
            },

            /**
             * Method builds array that contains objects for each row of the file - with this voucher data that we want to
             * create CSV file from
             */
            parseVouchersToCSV() {
                let csvContent = [];

                // get table headings
                let headings = this.vouchersTableHeaders;

                if (this.filteredVouchers.length) {

                    this.filteredVouchers.forEach((voucher) => {
                        // generate csv row
                        let row = {};
                        headings.forEach((header) => {
                            row[header.text] = voucher[header.value];
                        });
                        csvContent.push(row);
                    });
                }

                return csvContent;
            },

            /**
             * Generates the file with given content
             */
            exportFile(csv) {
                var filename = this.generateFilename('voucher_dashboard');

                // create file
                var file = new Blob([csv], {type: 'text/csv;charset=utf-8'});
                FileSaver.saveAs(file, filename);

                this.progressIconHide();
            },

            /**
             * Methods adds current date to filename
             */
            generateFilename(name) {
                var today = new Date();
                var todayString = '_' + today.getDate() + '_' + (Number(today.getMonth())+1) + '_' + today.getFullYear();
                var filename = name + todayString + '.csv';

                return filename;
            },

            /**
             * Open this voucher edit page in new window
             */
            openVoucherPage(voucherKey) {
                let key = parseInt(voucherKey);
                if (key) {
                    let url = '/Vouchers/' + key;
                    window.open(url, '_blank')
                }
            },

            /**
             * Show success or error snack bar
             * @param snack string - success|error
             * @param message = false
             */
            showSnackbar(snack, message = false) {
                var _this = this;

                switch (snack) {
                    case 'success' :
                        _this.successSnackbar = true;
                        break;
                    case 'error' :
                        _this.errorSnackbar = true;
                        break;
                }
            },

            closeCompareDatePicker() {
                this.comparisonMenu =false;
            },

            /**
             * Methods updates last option in comparison preset - Same date year ago,
             * when main filter has been change it updates that date range so 'Same date year ago' is always set and up to date
             */
            updateYearAgoDate() {
                var last = (this.dateRangeOptionsCompare.presets).slice(-1)[0];

console.log(this.range);
console.log("start ", this.range.start, "end", this.range.end);

                let selectedFromParts = (this.range.start).split('-');
                let selectedToParts = (this.range.end).split('-');
                selectedFromParts[0] = selectedFromParts[0] - 1;
                selectedToParts[0] = selectedToParts[0] - 1;

                let fromDate = selectedFromParts.join('-');
                let toDate = selectedToParts.join('-');

                last.range = {"start": fromDate, "end": toDate};
            },

        },
        /**
         * Methods that initialized this page on page load
         */
        mounted() {
            this.setDefaultDateRange();
            this.setDefaultFilters();
            this.setDefaultResults();
        },
        computed: {

            /** calculated difference between main filter results and comparison object*/
            comparisonDifferences() {
                // get object that stores main filter results
                let main = this.filterResults;
                // get object that stores result from comparison filter
                let comp = this.filterComparisonResults;

                let comparisonDifferences = {};

                // check if the objects to compare are set correctly
                if (main.hasOwnProperty('averageBookingValue') && comp.hasOwnProperty('averageBookingValue')) {
                    // create object with differences between these two objects
                    comparisonDifferences = {
                        averageBookingValue: this.calculateSingleDifference(main.averageBookingValue, comp.averageBookingValue),
                        averageCommissionValue: this.calculateSingleDifference(main.averageCommissionValue, comp.averageCommissionValue),
                        averageVoucherValue: this.calculateSingleDifference(main.averageVoucherValue, comp.averageVoucherValue),
                        totalBookingValue: this.calculateSingleDifference(main.totalBookingValue, comp.totalBookingValue),
                        totalBookings: this.calculateSingleDifference(main.totalBookings, comp.totalBookings),
                        totalCommissionValue: this.calculateSingleDifference(main.totalCommissionValue, comp.totalCommissionValue),
                        totalVoucherNumber: this.calculateSingleDifference(main.totalVoucherNumber, comp.totalVoucherNumber),
                        totalVoucherValue: this.calculateSingleDifference(main.totalVoucherValue, comp.totalVoucherValue),
                        totalRedeemedValue: this.calculateSingleDifference(main.totalRedeemedValue, comp.totalRedeemedValue),
                        averageRedeemedValue: this.calculateSingleDifference(main.averageRedeemedValue, comp.averageRedeemedValue)
                    };
                }

                return comparisonDifferences;
            },
            /**  properties decides whether we should open or hide results table expansion panel */
            showResultTable() {
                if (this.filteredVouchers.length) {
                    return true;
                }
                return false;
            },
            /** assign filters.sourceType to computed property so we can use it in watcher and check new and old value  */
            selectedSourceTypes() {
                return this.filters.sourceType;
            }
        },
        watch: {
            /**
             * Watcher for sourceTypes selected by user
             * When users select different than 'All' source types, uncheck All
             * When user select 'All' uncheck others so there is no confusion
             */
            selectedSourceTypes: {
                handler: function (newObj, oldObj) {
                    if (oldObj) {
                        // if user selects source type 'All' uncheck other source types
                        if (oldObj.indexOf('All') === -1 && newObj.indexOf('All') !== -1) {
                            this.filters.sourceType = ['All'];
                        }

                        // if user select any other source type and 'All' is checked, uncheck it
                        if (oldObj.indexOf('All') !== -1 && newObj.length > 1) {
                            let indexAll = this.filters.sourceType.indexOf('All');
                            this.filters.sourceType.splice(indexAll,1);
                        }
                    }
                }
            }
        },
    };
</script>

<style>
    .date-range .date-range__pickers input {
        border: none;
    }
    #daterangeColumn {
        max-width: 830px;
    }
    .input-group__selections__comma {
        font-size: 14px!important;
    }

    .elevation-1 {
        width: 100%!important;
    }

    #tableSearch input {
        border: none;
    }

    .closeIcon {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }

    #compareWithSpan {
        margin-left: 25px;
        margin-top: 12px;
    }

    #compareBtn {
        top: -25px;
    }

    .expansion-panel__header {
        background-color: #f9f9f9;
    }

    .menu__content--select {
        max-height: 500px!important;
    }

    i.openVoucherBtn:hover {
        color: #C1CD23;
        cursor: pointer;
    }

</style>
