<template>
<v-container pa-0 >
    <v-layout row>
        <v-col cols="12">
<!--
            <v-select
                :items="hotelList"
                label="Hotel"
                solo
                outlined
                dense
                :search-input.sync="search"
                autocomplete
                v-model="selectedHotel"
                @keyup.enter="gotoHotelDashboard"
                prepend-icon="home"
                color="lime"
            ></v-select>
-->
<!--
        <v-text-field
            v-model="searchText"
            label="Hotels"
            prepend-icon="home"
            @keyup.enter="gotoHotelDashboard"
            @keyup="onChange"
        ></v-text-field>
-->
        <select style="background-color: white; -moz-appearance: auto; -webkit-appearance: auto;" @change="onChange()" v-model="selectedHotel">
            <option v-for="item in allHotels" :value="item.hotel_key">{{ item.hotel_name }}</option>
        </select>

        </v-col>
    </v-layout>
</v-container>
</template>

<script>
    // Import the application event bus so we can listen for data changes and react accordingly
    import EventBus from '../../eventBus';
    import axios from 'axios';


    /**
     * Component to allow the user to select a hotel and immediately be taken through to their dashboard page
     */
    export default {
            props:[],
            data: function(){
                return {
                    search: '',
                    /** @var [] allHotels     - The list of select options as an array of objects ready for the :items prop of the select box */
                    allHotels:      [],
                    /** @var {} selectedHotel - The view model showing what hotel the user has selected in the UI */
                    selectedHotel: '',
                    searchText: "",
                    numMatches: 0
                };
            },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            this.loadData();
        },

        methods: {
            /**
             * Obtains a fresh local copy of the hotel listing to be used in this component from the app
             * Updates the this.allHotels list to match the values received, such that the value of the select box will be the hotel key and the text label will be code and hotel name
             */
            loadData(){
                var self = this;

                axios.get('/api/user/hotels')
                    .then(self.setHotelList)
                    .catch(function(exception){
                        console.log('%cError obtaining hotel list for hotel picker component', 'color:red');
                        console.log(exception);
                    });
            },

            onChange: function(){
                console.log("On change called...", this.selectedHotel);
                if (this.selectedHotel){
                    window.location.href="/hotel/" + this.selectedHotel + '/dashboard';
                }
            },

            /**
             * On successfully obtaining a new list of user hotels we need to set up a new view model representing the data in a format that the user interface components can use
             * Presently this is a select box which expects to have an array of objects containing the properties:
             *      'text':     labels of (hotel_code) hotel_name
             *      'value':    hotel_key
             */
            setHotelList: function(response){
                var selectList  = [];
                console.log(response.data);
                selectList[ selectList.length ] = { hotel_code: '', hotel_name: 'Hotel', hotel_key: '' };
                response.data.forEach(function(hotel){
         //           selectList[ selectList.length ] = ( { value: hotel.hotel_key, text: hotel.hotel_name + '(' + hotel.hotel_code + ')' } );
                      selectList[ selectList.length ] = { hotel_code: hotel.hotel_code, hotel_name: hotel.hotel_name, hotel_key: hotel.hotel_key };
                });
                this.allHotels = selectList;
             //     this.allHotels = response.data;
            },

            /**
             * If the key provided is valid then trigger a page load for its dashboard overview
             *
             * @param int hotelKey
             */
            gotoHotelDashboard(){
                var matches = [];
                var searchText = this.searchText.toLowerCase();

                this.allHotels.forEach(function(hotel){
                    if (hotel.hotel_name.toLowerCase().includes(searchText) || hotel.hotel_code.toLowerCase().includes(searchText)){
                        matches.push(hotel);
                    }
                });
                
                if (matches.length){
                    window.location.href="/hotel/" + matches[0].hotel_key + '/dashboard';
                }
            }
        },

        computed: {
            /**
             * Expose the reactive list of hotels for the vue template select box to be powered by
             */
            hotelList: function(){
                var matches = [];
        
                //if (this.searchText != ''){
                var searchText = this.searchText.toLowerCase();
                this.allHotels.forEach(function(hotel){
//                  console.log("HOTEL: ", hotel);
                    if (hotel.hotel_name.toLowerCase().includes(searchText) || hotel.hotel_code.toLowerCase().includes(searchText)){
                        matches.push(hotel);
                    }
                });
                //}
                console.log("DONE: ", matches);
                this.numMatches = matches.length;
                return matches;
            },

            hotelsLabel: function(){
                return this.numMatches ? "Hotels ("+this.hotelList.length+")" : "Hotel";
            }

        }
    }
</script>
