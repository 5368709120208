<template>
     <v-col cols="12" md="6" lg="4" class="mb-3" v-if="type == 'list'">
         <v-container fluid>
             <v-card>
                 <v-card-title primary-title class="headline">
                     <div class="v-align-container full-width">
                         <h4 class="v-align-div"><v-icon large>info</v-icon> Notifications ({{data.length}}) !!!</h4>
                     </div>
                 </v-card-title>
                 <v-card-text >
                     <div v-if="loading">Loading...</div>

                     <v-list dense v-if="!loading">
                         <template v-for="(item, index) in data">
                             <v-list-item ripple v-bind:key="index" :href="item.url" class="news-list-item">
                                 <v-list-item-title><v-icon left medium>{{item.icon}}</v-icon> {{ item.text }}</v-list-item-title>
                             </v-list-item>
                             <v-divider v-if="index + 1 < data.length"></v-divider>
                         </template>
                     </v-list>
                 </v-card-text>
              </v-card>
           </v-container>
        </v-col>

        <div v-else-if="type == 'icon'">
            <v-btn @click.native="data = [];"  class="mr-5 ies-coral ">clear data</v-btn>
            <a href="/" >
            <v-icon large v-badge="{ value: data.length, left: true }" class="ies-green--after ies-dark-gray--text--after">mail</v-icon>
            </a>
             <v-snackbar
             :timeout="parseInt(6000)"
             v-model="snackbar"
             multi-line
             color="ies-green"
             >
               <div class="full-height full-width v-align-container text-xs-center">
                  <h4 class="ies-dark-gray--text v-align-div">New Notification</h4>
               </div>
            </v-snackbar>
        </div>

</template>

<script>
    import axios from 'axios';

    export default {
        props:['type'],
        data: function() {
            return {
                loading: true,
                data: [],
                snackbar: false
            }
        },
        created:function() {
            this.fetchNotifications(true);
            setInterval( this.fetchNotifications ,60000 );
        },
        methods:{

            fetchNotifications:function(updateSnackbar){
                var self = this;
                axios.get('/api/notifications')
                .then(function(response){
                   var  newData = false;

                   if(this.data.length != response.data.notifications.length){
                       newData = true;
                   }
                   else {
                       var d = this.data;
                       response.data.notifications.forEach(function(item,key){

                            if(item.text != d[key].text){
                                newData = true;
                            }

                       });

                   }
                   if(newData){
                       if(!updateSnackbar && this.type == 'icon'){
                           this.snackbar= true;
                       }
                       this.data = response.data.notifications;
                   }
                   self.loading = false;
                }.bind(this));
            },

            
            formatDateFromString(dateString){
                var dateFormatted = 'N/A';
                var date = Date.parse(dateString);

                var dd = date.getDate();
                var mm = date.getMonth()+1;
                var yyyy = date.getFullYear();
                if(dd<10) {dd='0'+dd}
                if(mm<10) {mm='0'+mm}
                date = yyyy+'-'+mm+'-'+dd;
                return dateFormatted;
            },
        }
    }
</script>

<style>
.news-list-item {
    height: auto;
}

a.v-btn:link {
    color: black !important;
}
</style>
