var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "grid-list-md": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _vm.reservation.show || 1
                        ? _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _vm._v(
                                      "\n                                   The client has cancelled their reservation. Please acknowledge, and state any cancellation charges/refunds that will be made\n                              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs4: "" } }, [
                                    _c("strong", [_vm._v("Rooms")]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs8: "" } },
                                    _vm._l(
                                      _vm.reservation.rooms,
                                      function (room, index) {
                                        return _c("div", [
                                          _vm._v(
                                            "\n                                      1 x " +
                                              _vm._s(room.name) +
                                              "\n                                  "
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs4: "" } }, [
                                    _c("strong", [_vm._v("Guests")]),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs8: "" } }, [
                                    _vm._v(
                                      "\n                                  ???\n                              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs4: "" } }, [
                                    _c("strong", [_vm._v("Rates")]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs8: "" } },
                                    _vm._l(
                                      _vm.reservation.rateTypes,
                                      function (rateType, index) {
                                        return _c("div", [
                                          _vm._v(
                                            "\n                                      " +
                                              _vm._s(rateType.name) +
                                              " (reservation.stay_length) nights\n                                  "
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs4: "" } }, [
                                    _c("strong", [_vm._v("Total Rate")]),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs8: "" } }, [
                                    _vm._v(
                                      "\n                                  ???\n                              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs4: "" } }, [
                                    _c("strong", [_vm._v("Tax")]),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs8: "" } }, [
                                    _vm._v(
                                      "\n                                  ???\n                              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs4: "" } }, [
                                    _c("strong", [
                                      _vm._v("Total Cost Inclusive"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs8: "" } }, [
                                    _vm._v(
                                      "\n                                  ???\n                              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs4: "" } }, [
                                    _c("strong", [_vm._v("Deposit")]),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs8: "" } }, [
                                    _vm._v(
                                      "\n                                  ???\n                              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs4: "" } }, [
                                    _c("strong", [_vm._v("Amount Paid")]),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs8: "" } }, [
                                    _vm._v(
                                      "\n                                  Please enter the total amount the client will have paid you after cancellation charges/refunds have been made (assuming the room/house/trip is not resold):\n                                  ???\n                                  CURRENCY\n                              "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ies-green",
                          on: { click: _vm.confirmCancellation },
                        },
                        [_vm._v("Confirm Cancellation")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }