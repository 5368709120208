var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "row-sm": "", column: "" } },
        [
          this.field.schema.displayName != "none"
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _c("label", { attrs: { for: this.field.fieldName } }, [
                  _vm._v(" " + _vm._s(this.field.schema.displayName) + ": "),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          this.field.introduction
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _vm._v(_vm._s(this.field.introduction)),
              ])
            : _vm._e(),
          _vm._v(" "),
          this.field.information
            ? _c(
                "v-alert",
                { attrs: { type: "info", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.information))]
              )
            : _vm._e(),
          _vm._v(" "),
          this.field.warning
            ? _c(
                "v-alert",
                { attrs: { type: "warning", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.warning))]
              )
            : _vm._e(),
          _vm._v(" "),
          this.field.error
            ? _c(
                "v-alert",
                { attrs: { type: "error", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.error))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "d-flex", attrs: { md: "12" } },
                    [
                      _c("input", {
                        attrs: { type: "hidden", name: _vm.field.fieldName },
                        domProps: { value: _vm.field.value },
                      }),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", id: "best_for_kids" } },
                            [
                              _c("label", { attrs: { for: "babies" } }, [
                                _vm._v("Babies (0-1 years) :"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.bestFor.babies,
                                    expression: "bestFor.babies",
                                  },
                                ],
                                attrs: { name: "babies", type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(_vm.bestFor.babies)
                                    ? _vm._i(_vm.bestFor.babies, null) > -1
                                    : _vm.bestFor.babies,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.bestFor.babies,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.bestFor,
                                            "babies",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.bestFor,
                                            "babies",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.bestFor, "babies", $$c)
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "toddlers" } }, [
                                _vm._v("Toddlers (1-4 years):"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.bestFor.toddlers,
                                    expression: "bestFor.toddlers",
                                  },
                                ],
                                attrs: { name: "toddlers", type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(_vm.bestFor.toddlers)
                                    ? _vm._i(_vm.bestFor.toddlers, null) > -1
                                    : _vm.bestFor.toddlers,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.bestFor.toddlers,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.bestFor,
                                            "toddlers",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.bestFor,
                                            "toddlers",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.bestFor, "toddlers", $$c)
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "children" } }, [
                                _vm._v("Children (4-12 years) :"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.bestFor.children,
                                    expression: "bestFor.children",
                                  },
                                ],
                                attrs: { name: "children", type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(_vm.bestFor.children)
                                    ? _vm._i(_vm.bestFor.children, null) > -1
                                    : _vm.bestFor.children,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.bestFor.children,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.bestFor,
                                            "children",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.bestFor,
                                            "children",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.bestFor, "children", $$c)
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "teenagers" } }, [
                                _vm._v("Teenagers (12-18 years):"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.bestFor.teenagers,
                                    expression: "bestFor.teenagers",
                                  },
                                ],
                                attrs: { name: "teenagers", type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(_vm.bestFor.teenagers)
                                    ? _vm._i(_vm.bestFor.teenagers, null) > -1
                                    : _vm.bestFor.teenagers,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.bestFor.teenagers,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.bestFor,
                                            "teenagers",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.bestFor,
                                            "teenagers",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.bestFor, "teenagers", $$c)
                                    }
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _vm.errorMessages && _vm.errorMessages.length
                        ? _c(
                            "v-alert",
                            {
                              attrs: {
                                type: "error",
                                dismissable: "",
                                value: true,
                              },
                            },
                            _vm._l(_vm.errorMessages, function (error, index) {
                              return _c("div", { key: index }, [
                                _vm._v(_vm._s(error.reason) + "*"),
                              ])
                            }),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }