<template>
    <v-container>
        <v-layout column>
            <v-card xs12 class="mt-3 mb-3 no-print button-bar">
                <v-container fluid class="pa-3">
                    <v-layout row wrap>
                        <v-flex xs2 mr-3>
                            <h1>{{ title }}</h1>
                        </v-flex>
                        <v-flex xs1>
                            <v-btn @click="toggleVoucherType">{{ voucherBtnText }}</v-btn>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card>
        </v-layout>

        <input
                :type="formInput.voucher_type.displayType"
                :name="formInput.voucher_type.fieldName"
                :value="formInput.voucher_type.value"
        >

        <v-layout row wrap justify-center mt-3>
            <!--Both-->
            <v-flex xs12 sm12 md4 mr-5>
                <input-wrapper :field="formInput.purchaser_email"></input-wrapper>
                <input-wrapper :field="formInput.recipient_email"></input-wrapper>
                <input-wrapper :field="formInput.value"></input-wrapper>
                <input-wrapper :field="formInput.is_promotional"></input-wrapper>
                <input-wrapper :field="formInput.purpose"></input-wrapper>
                <input-wrapper  :field="formInput.notes"></input-wrapper>
                <input-wrapper  :field="formInput.message"></input-wrapper>
            </v-flex>

            <!--Physical-->
            <v-flex xs12 sm12 md4 v-if="formInput.voucher_type.value == 1">
                <input-wrapper :field="formInput.quantity"></input-wrapper>
                <input-wrapper :field="formInput.address_1"></input-wrapper>
                <input-wrapper :field="formInput.address_2"></input-wrapper>
                <input-wrapper :field="formInput.city"></input-wrapper>
                <input-wrapper :field="formInput.postcode"></input-wrapper>
                <input-wrapper :field="formInput.country"></input-wrapper>

                <label>Postage:</label>
                <label>UK</label>
                <input name="delivery" type="radio" value="1" :checked="old.delivery == 1">
                <label>Europe</label>
                <input name="delivery" type="radio" value="2" :checked="old.delivery == 2">
                <label>World Wide</label>
                <input name="delivery" type="radio" value="3" :checked="old.delivery == 3">
            </v-flex>

            <!--Digital-->
            <v-flex xs12 sm12 md4 v-if="formInput.voucher_type.value == 2">
                <input-wrapper :field="formInput.to_text"></input-wrapper>
                <input-wrapper :field="formInput.from_text"></input-wrapper>
            </v-flex>

        </v-layout>

        <!--Submit button-->
        <v-layout row wrap justify-center>
            <v-flex xs12 sm12 md4>
                <v-btn type="submit" block class="ies-green">Make Voucher</v-btn>
            </v-flex>
        </v-layout>


    </v-container>
</template>

<script>
    import InputWrapper from '../inputs/Wrapper';

    const PHYSICAL_VOUCHER = 1;
    const DIGITAL_VOUCHER  = 2;

    export default {
        components: {
            InputWrapper
        },
        props: ['errors', 'old', 'countries'],
        data () {
            return {
                voucherBtnText: 'Digital Voucher',
                formInput: {},
            }
        },
        created () {
            this.formInput = Object.assign(
                this.generalVoucherFields(),
                this.physicalVoucherFields(),
                this.digitalVoucherFields()
            );

            this.toggleButtonText();

        },
        methods: {
            // Toggle voucher type in hidden field
            toggleVoucherType () {
                this.formInput.voucher_type.value = this.formInput.voucher_type.value == PHYSICAL_VOUCHER ? DIGITAL_VOUCHER : PHYSICAL_VOUCHER;
                this.toggleButtonText()
            },
            // Toggle text in voucher type button
            toggleButtonText () {
                let voucherType = this.formInput.voucher_type.value;
                this.title = voucherType == PHYSICAL_VOUCHER ? 'Physical Voucher' : 'Digital Voucher';
                this.voucherBtnText = voucherType == PHYSICAL_VOUCHER ? 'Switch to Digital Voucher' : 'Switch to Physical Voucher';
            },
            // Voucher fields present on both types of voucher.
            generalVoucherFields () {
                return {
                    voucher_type: {
                        displayType: 'hidden',
                        fieldName: 'voucher_type',
                        value: this.old.voucher_type || 2,
                        oldValue: this.old.voucher_type,
                    },
                    purchaser_email: {
                        displayType: 'email',
                        fieldName: 'purchaser_email',
                        errors: this.errors.purchaser_email,
                        oldValue: this.old.purchaser_email,
                        schema: { displayName: 'Purchaser Email' }
                    },
                    recipient_email: {
                        displayType: 'email',
                        fieldName: 'recipient_email',
                        errors: this.errors.recipient_email,
                        oldValue: this.old.recipient_email,
                        schema: { displayName: 'Recipient Email' }
                    },
                    value: {
                        displayType: 'text',
                        fieldName: 'value',
                        errors: this.errors.value,
                        oldValue: this.old.value,
                        schema: { displayName: 'Value' }
                    },
                    purpose: {
                        displayType: 'select',
                        fieldName: 'purpose',
                        errors: this.errors.purpose,
                        oldValue: this.old.purpose,
                        schema: { displayName: 'Select purpose for creating this voucher' },
                        list: [
                            {text: 'Replacement', value: 1},
                            {text: 'Freebie/goodwill', value: 2},
                            {text: 'Competition gift', value: 3},
                            {text: 'Test', value: 4}
                        ]
                    },
                    is_promotional: {
                        displayType: 'switch',
                        fieldName: 'is_promotional',
                        errors: this.errors.is_promotional,
                        oldValue: this.old.is_promotional,
                        schema: { displayName: 'Is promotional?' }
                    },
                    message: {
                        displayType: 'textarea',
                        fieldName: 'message',
                        errors: this.errors.message,
                        oldValue: this.old.message,
                        schema: { displayName: 'Message'}
                    },
                    notes: {
                        displayType: 'text',
                        fieldName: 'notes',
                        errors: this.errors.notes,
                        oldValue: this.old.notes,
                        schema: {displayName: 'Staff Notes'}
                    },
                }
            },
            // Voucher fields only present on digital vouchers.
            digitalVoucherFields () {
                return {
                    to_text: {
                        displayType: 'text',
                        fieldName: 'to_text',
                        errors: this.errors.to_text,
                        oldValue: this.old.to_text,
                        schema: { displayName: 'To text'}
                    },
                    from_text: {
                        displayType: 'text',
                        fieldName: 'from_text',
                        errors: this.errors.from_text,
                        oldValue: this.old.from_text,
                        schema: { displayName: 'From text'}
                    },
                }
            },
            // Voucher fields only present on physical vouchers.
            physicalVoucherFields () {
                return {
                    quantity: {
                        displayType: 'text',
                        fieldName: 'quantity',
                        errors: this.errors.quantity,
                        oldValue: this.old.quantity,
                        schema: { displayName: 'Quantity' }
                    },
                    address_1: {
                        displayType: 'text',
                        fieldName: 'address_1',
                        errors: this.errors.address_1,
                        oldValue: this.old.address_1,
                        schema: { displayName: 'Address 1' }
                    },
                    address_2: {
                        displayType: 'text',
                        fieldName: 'address_2',
                        errors: this.errors.address_2,
                        oldValue: this.old.address_2,
                        schema: { displayName: 'Address 2' }
                    },
                    city: {
                        displayType: 'text',
                        fieldName: 'city',
                        errors: this.errors.city,
                        oldValue: this.old.city,
                        schema: { displayName: 'City' }
                    },
                    postcode: {
                        displayType: 'text',
                        fieldName: 'postcode',
                        errors: this.errors.postcode,
                        oldValue: this.old.postcode,
                        schema: { displayName: 'Postcode' }
                    },
                    country: {
                        displayType: 'select',
                        fieldName: 'country_key',
                        list: this.countries,
                        errors: this.errors.country_key,
                        oldValue: this.old.country_key,
                        schema: { displayName: 'Country' }
                    },
                }
            }
        }
    }

</script>