var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "grid-list-md": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-alert", { attrs: { type: "error", value: _vm.error } }, [
                _vm._v(_vm._s(_vm.errorMsg)),
              ]),
              _vm._v(" "),
              !_vm.error
                ? _c("div", [
                    !_vm.associationsRequested
                      ? _c(
                          "div",
                          [
                            _c(
                              "v-btn",
                              { on: { click: _vm.findAssociations } },
                              [
                                _vm._v(
                                  "\n                            Show associations\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.associationsRequested
                      ? _c("div", [
                          _vm.busy
                            ? _c(
                                "div",
                                [
                                  _c("label", [
                                    _vm._v(" Loading associations... "),
                                  ]),
                                  _vm._v(" "),
                                  [
                                    _c("v-progress-linear", {
                                      attrs: { indeterminate: true },
                                    }),
                                  ],
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.busy
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        row: "",
                                        "justify-space-around": "",
                                        flex: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            lg5: "",
                                            md5: "",
                                            sm10: "",
                                            id: "hotelBox",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("Hotel Associations:"),
                                          ]),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            attrs: {
                                              headers: _vm.tableHotels.headers,
                                              items: _vm.associationsHotels,
                                              "rows-per-page-items": [
                                                20,
                                                50,
                                                100,
                                                { text: "All", value: -1 },
                                              ],
                                              "footer-props.items-per-page-options":
                                                "[20,50,100, {'text':'All', 'value':-1}]",
                                              "no-data-text":
                                                "User doesn't have any hotel associations",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "items",
                                                  fn: function (props) {
                                                    return [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.item
                                                              .hotel_name
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.item
                                                              .assoc_type
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "text-xs-right",
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mx-0",
                                                              attrs: {
                                                                icon: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteHotel(
                                                                      props.item
                                                                        .user_association_key
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "pink",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "delete"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3798115786
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  "\n                                            Add new hotel association\n                                        "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: { row: "", wrap: "" },
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs4: "" } },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items: _vm.hotels,
                                                          label: "Select hotel",
                                                          "item-value": "name",
                                                          dense: "",
                                                          autocomplete: "",
                                                          id: "hotel-select",
                                                          hint: "Type to search for a hotel",
                                                          "persistent-hint": "",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "item",
                                                              fn: function (
                                                                data
                                                              ) {
                                                                return [
                                                                  _c("option", {
                                                                    class: {
                                                                      "hotel-offline":
                                                                        data
                                                                          .item
                                                                          .display ==
                                                                        0,
                                                                    },
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          data
                                                                            .item
                                                                            .text
                                                                        ),
                                                                    },
                                                                  }),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          3334907291
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.selectedHotel,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.selectedHotel =
                                                              $$v
                                                          },
                                                          expression:
                                                            "selectedHotel",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs1: "" } },
                                                    [
                                                      _c("v-subheader", [
                                                        _vm._v("and"),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs4: "" } },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items:
                                                            _vm.typeOfAssociations,
                                                          label:
                                                            "Type of association",
                                                          dense: "",
                                                          "item-value":
                                                            "association",
                                                          id: "association-hotel-select",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectedAssociationHotel,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.selectedAssociationHotel =
                                                              $$v
                                                          },
                                                          expression:
                                                            "selectedAssociationHotel",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs1: "" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "success",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.addHotel()
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Add")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { attrs: { id: "separator" } }),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            lg5: "",
                                            md5: "",
                                            sm10: "",
                                            id: "regionBox",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("Region Associations:"),
                                          ]),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            attrs: {
                                              headers: _vm.tableRegions.headers,
                                              items: _vm.associationsRegions,
                                              "footer-props.items-per-page-options":
                                                "[20,50,100, {'text':'All', 'value':-1}]",
                                              "no-data-text":
                                                "User doesn't have any regions associations",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "items",
                                                  fn: function (props) {
                                                    return [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.item
                                                              .region_name
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.item
                                                              .assoc_type
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "text-xs-right",
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mx-0",
                                                              attrs: {
                                                                icon: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteRegion(
                                                                      props.item
                                                                        .user_association_key
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "pink",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "delete"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2794549450
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  "\n                                            Add new region association\n                                        "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: { row: "", wrap: "" },
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs4: "" } },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items: _vm.regions,
                                                          label:
                                                            "Select region",
                                                          autocomplete: "",
                                                          multiple: "",
                                                          "item-text":
                                                            "region_name",
                                                          "item-value":
                                                            "region_value",
                                                          chips: "",
                                                          dense: "",
                                                          hint: "Type to search for a region, you can select multiple regions",
                                                          "persistent-hint": "",
                                                          id: "region-select",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "item",
                                                              fn: function (
                                                                data
                                                              ) {
                                                                return [
                                                                  _c("option", {
                                                                    class:
                                                                      data.item
                                                                        .region_type,
                                                                    domProps: {
                                                                      textContent:
                                                                        _vm._s(
                                                                          data
                                                                            .item
                                                                            .region_name
                                                                        ),
                                                                    },
                                                                  }),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2939331896
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.selectedRegions,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.selectedRegions =
                                                              $$v
                                                          },
                                                          expression:
                                                            "selectedRegions",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs1: "" } },
                                                    [
                                                      _c("v-subheader", [
                                                        _vm._v("and"),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs4: "" } },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items:
                                                            _vm.typeOfAssociations,
                                                          label:
                                                            "Type of association",
                                                          dense: "",
                                                          "item-value":
                                                            "association",
                                                          id: "association-region-select",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectedAssociationRegion,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.selectedAssociationRegion =
                                                              $$v
                                                          },
                                                          expression:
                                                            "selectedAssociationRegion",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs1: "" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "success",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.addRegion()
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Add")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }