<template>
    <v-container fluid class="pa-0 no-print"> 
        <v-layout row wrap>
            
            <v-flex xs12>
                <v-card>
                <!-- Update -->
                <v-btn v-on:click="save" class="ies-green" disabled><v-icon>save</v-icon> Save</v-btn>
                <v-btn v-on:click="refresh()" disabled><v-icon>refresh</v-icon> Refresh</v-btn>

                <!-- Obtain in other formats -->
                <v-btn v-on:click="downloadCSV()" disabled><v-icon>file_download</v-icon> CSV</v-btn>
                <v-btn v-on:click="downloadPDF()" disabled><v-icon>file_download</v-icon> PDF</v-btn>
                <v-btn v-on:click="printPage()"><v-icon>print</v-icon> Print</v-btn>

                <!-- Send to others -->
                <v-btn v-on:click="email()" disabled><v-icon>email</v-icon> Email</v-btn>
                <v-btn v-on:click="reportProblem()" disabled><v-icon>report_problem</v-icon> Problem</v-btn>

                <!-- Visualisation -->
                <v-btn v-on:click="toggleConfig()" :depressed="showConfig" disabled><v-icon>web</v-icon> Edit View</v-btn>
                </v-card>
             </v-flex>



            <!-- Menu allowing the user to choose which fields to compare in the visualisation graphs etc -->
            <v-flex class="mt-3" xs12 v-if="showConfig">
                <v-card>
                <h3 style="margin: 15px;">How Would You Like To View This Report?</h3>

                <!-- Data view types -->
                <fieldset>
                <label>Add New View</label>
                <v-flex class="ma-3" xs12>
                    <v-btn v-on:click="addVisualisation('dataTable')"      ><v-icon>view_list</v-icon> Table</v-btn>
                    <v-btn v-on:click="addVisualisation('chartBar')"       ><v-icon>insert_chart</v-icon> Bar</v-btn>
                    <v-btn v-on:click="addVisualisation('chartLine')"      ><v-icon>show_chart</v-icon> Line</v-btn>
                    <v-btn v-on:click="addVisualisation('chartPie')"       ><v-icon>pie_chart</v-icon> Pie</v-btn>
                    <v-btn v-on:click="addVisualisation('chartBubble')"    disabled><v-icon>bubble_chart</v-icon> Bubble</v-btn>
                    <v-btn v-on:click="addVisualisation('chartPolar')"     ><v-icon>pie_chart</v-icon> Polar</v-btn>
                    <v-btn v-on:click="addVisualisation('chartRadar')"     ><v-icon>filter_tilt_shift</v-icon> Radar</v-btn>
                    <v-btn v-on:click="addVisualisation('chartDoughnut')"  ><v-icon>donut_small</v-icon> Doughnut</v-btn>
                    <v-btn v-on:click="addVisualisation('chartScatter')"   disabled><v-icon>show_chart</v-icon> Scatter</v-btn>
                </v-flex>
                </fieldset>

                <v-expansion-panel>
                    <v-expansion-panel-content v-for="(view, index) in visualisations" :key="index">
                        <div slot="header">
                            <v-icon v-if="view.type == 'dataTable'">view_list</v-icon>
                            <v-icon v-if="view.type == 'chartBar'">insert_chart</v-icon>
                            <v-icon v-if="view.type == 'chartLine'">show_chart</v-icon>
                            <v-icon v-if="view.type == 'chartPie'">pie_chart</v-icon>
                            <v-icon v-if="view.type == 'chartBubble'">bubble_chart</v-icon>
                            <v-icon v-if="view.type == 'chartPolar'">pie_chart</v-icon>
                            <v-icon v-if="view.type == 'chartRadar'">filter_tilt_shift</v-icon>
                            <v-icon v-if="view.type == 'chartDoughnut'">donut_small</v-icon>
                            <v-icon v-if="view.type == 'chartScatter'">show_chart</v-icon>
                            Configure 
                            <span v-if="view.type == 'dataTable'">Table</span>
                            <span v-if="view.type == 'chartBar'">Bar Chart</span>
                            <span v-if="view.type == 'chartLine'">Line Graph</span>
                            <span v-if="view.type == 'chartPie'">Pie Chart</span>
                            <span v-if="view.type == 'chartBubble'">Bubble Chart</span>
                            <span v-if="view.type == 'chartPolar'">Polar Graph</span>
                            <span v-if="view.type == 'chartRadar'">Radar Chart</span>
                            <span v-if="view.type == 'chartDoughnut'">Doughnut Chart</span>
                            <span v-if="view.type == 'chartScatter'">Scatter Graph</span>
                        </div>

                        <v-card>
                            <v-card-text v-if="view.type != 'dataTable'">
                                <div>Which fields would you like to compare?</div>
                        
                                <v-layout row>
                                    <v-flex>
                                        For each 

                                        <v-select
                                            :items="modelFields"
                                            v-model="view.modelFieldA"
                                            label="Column A"
                                            class=""
                                            item-value="value"
                                            single-line
                                        ></v-select>

                                        (on the X-axis)
                                    </v-flex>

                                    <v-flex>
                                        show me the

                                        <v-select
                                            :items="modelFields"
                                            v-model="view.modelFieldB"
                                            label="Column B"
                                            class=""
                                            item-value="value"
                                            single-line
                                        ></v-select>

                                        (on the Y-axis)
                                    </v-flex>
                                </v-layout>

                                
                            </v-card-text>

                            <v-card-actions>
                                <v-btn v-on:click="updateViews()">Update View</v-btn>
                                <v-btn v-on:click="deleteView(index)"><v-icon>delete</v-icon> Delete View</v-btn>
                            </v-card-actions>
                        </v-card>

                    </v-expansion-panel-content>
                </v-expansion-panel>
                </v-card>
    
            </v-flex>
            
        </v-layout>
    </v-container>
</template>


<script>
    // Import the application event bus so we can listen for user or menu data changes and react accordingly
    import EventBus from '../../eventBus';

    // Export the component for inclusion within the page
    export default {
        props: ['datasource', 'sectionindex'],

        /**
         * Core data required by the component and template
         */
        data: function(){
            return {
                /** @var {} metaData        - Gives contextual data about the page and content this component is managing */
                metaData:    {},

                /** @var {} data            - The results of the data */
                data:        {},

                /** @var boolean loaded     - Flag identifying if the page has finished loading yet */
                loaded:      false,

                /** @var boolean showConfig - Toggles the display of an option box where the user gets to choose which fields to compare in relevant visualisations, such as comparing field X against field Y on a bar chart */
                showConfig:     false,

                pageSections: [],

                /** @var [] visualisations  - Array of visualisations currently being shown to the user */
                visualisations: []

            };
        },

        /**
         * On page load complete build the component
         */
        mounted: function(){
            // Register an event listener callback function with the page service so we can be alerted when the service updates its data with fresh copies
            EventBus.$on('dataChange', this.loadData);
            EventBus.$on('visualisationChange', this.loadData);
            EventBus.$on('pageServiceReady', this.loadData);
        },

        /**
         * Business logic functions performing useful tasks for this component
         */
        methods: {
            /**
             *
             */
            loadData: function(){
                this.metaData      = window.app.pageService.getMetaData();
                this.pageRecord = window.app.pageService.getPageRecord();
                this.pageSections = window.app.pageService.getPageSections();
                this.visualisations = this.pageSections[this.sectionindex].visualisations;
            },

            /**
             * Adds a new visualisation to the list for this dataset
             *
             */
            addVisualisation: function(type){
                var viewID  = this.visualisations.length;
                var newView = { type: type };

                window.app.pageService.addVisualisation(this.datasource, viewID, newView);
            },


            /**
             * Updates a view's configuration settings
             */
            updateView: function(viewID){
                var parts = filter.modelField.split('.');
                var modelName = parts[0];
                var fieldName = parts[1];
                this.visualisations[viewID].modelNameA = modelName;
                this.visualisations[viewID].fieldNameA = fieldNameA;
                this.visualisations[viewID].modelNameB = modelNameB;
                this.visualisations[viewID].fieldNameB = fieldNameB;
            },
            
            /**
             * Triggers all views on the page to be updated to reflect the visualisations as specified in the configuration panel
             */
            updateViews: function(){
                window.app.pageService.setVisualisations(this.datasource, this.visualisations);
            },

            /**
             * Remove the specified visualisation from the list
             *
             * @param int index - Identifier of the visualisation to remove from the page
             */
            deleteView: function(index){
                window.app.pageService.removeVisualisation(this.datasource, index);
            },

            /**
             * Saves the report to the database so the next person to open the page will see the same query in the same visualisations as the current user is now
             */
            save: function(){},


            /**
             * Toggles the display of the report's visualisation config tool which lets the user choose how they want to see the data visualised for themselves
             */
            toggleConfig: function(){
                this.showConfig = !this.showConfig;
            },

            /**
             * Triggers a download of the data in CSV format
             */
            downloadCSV: function(){
//                window.open('/' + this.metaData.listUrl + '/csv');
            },

            /**
             * Triggers a download of the data in a PDF document
             */
            downloadPDF: function(){
                alert('Sorry, this feature is not yet available');
                //window.open('/' + this.metaData.listUrl + '/pdf');
            },

            /**
             * Triggers the browser to open a print dialogue box
             */
            printPage: function(){
                window.print();
            }

        },


        /**
         * Live bindings which trigger immediate re-rendering of the components using them on the template.  This is used to allow data changes within the component
         * on 'data' attributes to be reflected on the view
         */
        computed: {

            /**
             * All of the models used by this page which the user can choose to search on
             */
            modelFields: function(){
                var modelFields = [];
/*
                var x = this.data;

                if(this.data.fields){
                    for (var index = 0; index < this.data.fields.length; index++){
                        var field     = this.data.fields[index];

                        // Do not bother processing hidden fields, as we will not let them see it anyway
                        if (field.displayType !== 'hidden') {
                            var fieldName = field.fieldName;
                            var modelName = field.modelName;
                            var text      = '(' + modelName +') ' + fieldName;
                            var value     = modelName + '.' + fieldName;

                            // If any data aggregation has been applied against the values in this field
                            // then the user will need to be made aware it is not the raw values that will
                            // be displayed.  The best way is to describe the aggregation they should expect
                            // in natural language
                            if (field.aggregation){
                                switch (field.aggregation){
                                    case 'COUNT': text = 'No. of '   + text + 's';  break;
                                    case 'SUM'  : text = 'Sum of '   + text + 's';  break;
                                    case 'AVG'  : text = 'Average '  + text;        break;
                                    case 'MIN'  : text = 'Smallest ' + text;        break;
                                    case 'MAX'  : text = 'Largest '  + text;        break;
                                }
                            }

                            // Append to the list of fields allowed to be used
                            modelFields.push({ 'text': text, 'value': value });
                        }
                    }
                }
*/
/*
                this.pageSections[this.sectionindex].fields.forEach(function(field){
                    this.push({ 'dataOption': field.modelName + '.' + field.fieldName });
                }).bind(this);
*/
                    for (var index = 0; index < this.pageSections[this.sectionindex].fields.length; index++){
                        var field     = this.pageSections[this.sectionindex].fields[index];

                        // Do not bother processing hidden fields, as we will not let them see it anyway
                        if (field.displayType !== 'hidden') {
                            var fieldName = field.fieldName;
                            var modelName = field.modelName;
                            var text      = '(' + modelName +') ' + fieldName;
                            var value     = modelName + '.' + fieldName;

                            // If any data aggregation has been applied against the values in this field
                            // then the user will need to be made aware it is not the raw values that will
                            // be displayed.  The best way is to describe the aggregation they should expect
                            // in natural language
                            if (field.aggregation){
                                switch (field.aggregation){
                                    case 'COUNT': text = 'No. of '   + text + 's';  break;
                                    case 'SUM'  : text = 'Sum of '   + text + 's';  break;
                                    case 'AVG'  : text = 'Average '  + text;        break;
                                    case 'MIN'  : text = 'Smallest ' + text;        break;
                                    case 'MAX'  : text = 'Largest '  + text;        break;
                                }
                            }

                            // Append to the list of fields allowed to be used
                            modelFields.push({ 'text': text, 'value': value });
                        }
                    }
                
                return modelFields;
            },
        
        }
    }

</script>
