var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "row-sm": "", column: "" } },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("label", { attrs: { for: this.field.fieldName } }, [
              _vm._v(" " + _vm._s(this.field.schema.displayName) + ": "),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            _vm._l(_vm.viewModel, function (dataGroup, groupIndex) {
              return _c(
                "div",
                [
                  _c(
                    "div",
                    [
                      _c("v-text-field", {
                        attrs: { placeholder: "Name" },
                        on: {
                          keyup: function ($event) {
                            return _vm.updateField()
                          },
                        },
                        model: {
                          value: dataGroup.name,
                          callback: function ($$v) {
                            _vm.$set(dataGroup, "name", $$v)
                          },
                          expression: "dataGroup.name",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.removeGroup(groupIndex)
                            },
                          },
                        },
                        [
                          _c("v-icon", [_vm._v("delete")]),
                          _vm._v(" Delete Group"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(dataGroup.properties, function (dataProperty, index) {
                    return _c(
                      "div",
                      [
                        dataProperty
                          ? _c(
                              "v-layout",
                              { attrs: { row: "" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "5" } },
                                  [
                                    _vm._v(
                                      "\n                       The data item named:\n                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "3" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: { placeholder: "Name" },
                                      on: {
                                        keyup: function ($event) {
                                          return _vm.updateField()
                                        },
                                      },
                                      model: {
                                        value: dataProperty.name,
                                        callback: function ($$v) {
                                          _vm.$set(dataProperty, "name", $$v)
                                        },
                                        expression: "dataProperty.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "5" } },
                                  [
                                    _vm._v(
                                      "\n                       has the value:\n                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "5" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: { placeholder: "Value" },
                                      on: {
                                        keyup: function ($event) {
                                          return _vm.updateField()
                                        },
                                      },
                                      model: {
                                        value: dataProperty.value,
                                        callback: function ($$v) {
                                          _vm.$set(dataProperty, "value", $$v)
                                        },
                                        expression: "dataProperty.value",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "2" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeProperty(
                                              groupIndex,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", [_vm._v("delete")]),
                                        _vm._v(" Delete Property"),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.addNewProperty(groupIndex)
                            },
                          },
                        },
                        [
                          _c("v-icon", [_vm._v("add")]),
                          _vm._v(" Add Data Property"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.addNewGroup()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("add")]), _vm._v(" Add Group")],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: _vm.field.fieldName },
                    domProps: { value: _vm.field.value },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-alert",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { type: "error", value: _vm.field.errors },
                    },
                    _vm._l(_vm.field.errors, function (error) {
                      return _c("li", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(error) +
                            "\n                    "
                        ),
                      ])
                    }),
                    0
                  ),
                ],
                2
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }