<template>
    <v-container fluid>

        <v-flex md1>
            <v-btn id="addNewType" color="info" v-on:click="openNewDialog">Add New</v-btn>
        </v-flex>

        <v-btn large fixed bottom right class="ies-green" v-on:click="saveChanged">SAVE</v-btn>


        <v-dialog v-model="editDialog" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">Edit Content Type</span>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>

                            <v-flex md10>
                                Edit name
                                <input type="text" v-model="editName">
                            </v-flex>
                            <div>
                            <v-flex md10>
                                <br><br>
                                <div>
                                    <v-expansion-panel>
                                        <v-expansion-panel-content>
                                            <div slot="header">Do you want to delete this content type?</div>
                                            <v-card>
                                                <v-card-text>
                                                    <v-btn color="error" :data-key="editKey" v-on:click="deleteRecord">Delete</v-btn>
                                                </v-card-text>
                                            </v-card>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </div>
                            </v-flex>
                            </div>

                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" flat v-on:click="closeDialog">Close</v-btn>
                    <v-btn color="blue darken-1" flat v-on:click="updateName">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="newDialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Add new Content Type</span>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex md12>
                                <div class="margin-bottom-medium">
                                    Enter name
                                    <input type="text" v-model="newType.name" maxlength="30">
                                </div>
                                <br>

                                <div class="margin-bottom-medium">
                                    <span class="headline">GUARD</span>
                                    <v-layout row justify-center>
                                        <v-flex xs4>
                                            <span>{{this.guards[1]}}</span>
                                            <v-flex xs8>
                                                <v-select class="input-group--focused"  light dense v-model="newType.permissions.guard[1]" :items="newType.permissionsOptions" item-text="text" item-value="value"></v-select>
                                            </v-flex>
                                        </v-flex>
                                        <v-flex xs4>
                                            <span>{{this.guards[2]}}</span>
                                            <v-flex xs8>
                                                <v-select class="input-group--focused"  light dense v-model="newType.permissions.guard[2]" :items="newType.permissionsOptions" item-text="text" item-value="value"></v-select>
                                            </v-flex>
                                        </v-flex>
                                        <v-flex xs4>
                                            <span>{{this.guards[3]}}</span>
                                            <v-flex xs8>
                                                <v-select class="input-group--focused"  light dense v-model="newType.permissions.guard[3]" :items="newType.permissionsOptions" item-text="text" item-value="value"></v-select>
                                            </v-flex>
                                        </v-flex>
                                    </v-layout>
                                </div>

                                <div class="margin-bottom-medium">
                                    <span class="headline">DEPARTMENTS</span>
                                    <v-layout row justify-center>
                                        <v-flex xs4>
                                            <span>{{this.departments[3]}}</span>
                                            <v-flex xs10>
                                                <v-select class="input-group--focused"  light dense v-model="newType.permissions.department[3]" :items="newType.permissionsOptions" item-text="text" item-value="value"></v-select>
                                            </v-flex>
                                        </v-flex>
                                        <v-flex xs4>
                                            <span>{{this.departments[6]}}</span>
                                            <v-flex xs10>
                                                <v-select class="input-group--focused"  light dense v-model="newType.permissions.department[6]" :items="newType.permissionsOptions" item-text="text" item-value="value"></v-select>
                                            </v-flex>
                                        </v-flex>
                                        <v-flex xs4>
                                            <span>{{this.departments[4]}}</span>
                                            <v-flex xs10>
                                                <v-select class="input-group--focused"  light dense v-model="newType.permissions.department[4]" :items="newType.permissionsOptions" item-text="text" item-value="value"></v-select>
                                            </v-flex>
                                        </v-flex>
                                        <v-flex xs4>
                                            <span>{{this.departments[5]}}</span>
                                            <v-flex xs10>
                                                <v-select class="input-group--focused"  light dense v-model="newType.permissions.department[5]" :items="newType.permissionsOptions" item-text="text" item-value="value"></v-select>
                                            </v-flex>
                                        </v-flex>
                                        <v-flex xs4>
                                            <span>{{this.departments[1]}}</span>
                                            <v-flex xs10>
                                                <v-select class="input-group--focused"  light dense v-model="newType.permissions.department[1]" :items="newType.permissionsOptions" item-text="text" item-value="value"></v-select>
                                            </v-flex>
                                        </v-flex>
                                        <v-flex xs4>
                                            <span>{{this.departments[2]}}</span>
                                            <v-flex xs10>
                                                <v-select class="input-group--focused"  light dense v-model="newType.permissions.department[2]" :items="newType.permissionsOptions" item-text="text" item-value="value"></v-select>
                                            </v-flex>
                                        </v-flex>
                                        <v-flex xs4>
                                            <span>{{this.departments[7]}}</span>
                                            <v-flex xs10>
                                                <v-select class="input-group--focused"  light dense v-model="newType.permissions.department[7]" :items="newType.permissionsOptions" item-text="text" item-value="value"></v-select>
                                            </v-flex>
                                        </v-flex>
                                    </v-layout>
                                </div>

                                <div>
                                    <span class="headline">ROLES</span>
                                    <v-layout row justify-center>
                                        <v-flex xs4>
                                            <span>{{this.roles[1]}}</span>
                                            <v-flex xs8>
                                                <v-select class="input-group--focused"  light dense v-model="newType.permissions.role[1]" :items="newType.permissionsOptions" item-text="text" item-value="value"></v-select>
                                            </v-flex>
                                        </v-flex>
                                        <v-flex xs4>
                                            <span>{{this.roles[2]}}</span>
                                            <v-flex xs8>
                                                <v-select class="input-group--focused"  light dense v-model="newType.permissions.role[2]" :items="newType.permissionsOptions" item-text="text" item-value="value"></v-select>
                                            </v-flex>
                                        </v-flex>
                                        <v-flex xs4>
                                            <span>{{this.roles[3]}}</span>
                                            <v-flex xs8>
                                                <v-select class="input-group--focused"  light dense v-model="newType.permissions.role[3]" :items="newType.permissionsOptions" item-text="text" item-value="value"></v-select>
                                            </v-flex>
                                        </v-flex>
                                    </v-layout>
                                </div>

                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" flat v-on:click="closeDialog">Close</v-btn>
                    <v-btn color="blue darken-1" flat v-on:click="saveNew">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-snackbar
                :timeout="parseInt(6000)"
                v-model="successSingleSnackbar"
                multi-line
                color="ies-green"
        >
            <div class="full-height full-width v-align-container text-xs-center">
                <h4 class="ies-dark-gray--text v-align-div">{{ this.snackbarText }}</h4>
            </div>
        </v-snackbar>

        <v-snackbar
                :timeout="parseInt(6000)"
                v-model="errorSnackbar"
                multi-line
                color="ies-coral"
        >
            <div class="full-height full-width v-align-container text-xs-center">
                <h4 class="ies-dark-gray--text v-align-div">{{ this.snackbarText }}</h4>
            </div>
        </v-snackbar>
    </v-container>
</template>


<script>
import axios from 'axios';
export default {
    name: 'PortalDesignerFields',
    props: ['phpguards', 'phpdepartments', 'phproles'],
    data () {
        return {
            guards: this.phpguards,
            departments: this.phpdepartments,
            roles: this.phproles,
            changed: [],
            errorSnackbar: false,
            snackbarText: '',
            successSingleSnackbar:false,
            editDialog: false,
            editName: false,
            editKey: false,
            newDialog: false,
            ajaxSuccess: false,
            newType: {
                name : '',
                permissions: {"guard":{"1":0,"2":0,"3":0},"role":{"1":0,"2":0,"3":0},"department":{"3":0,"6":0,"4":0,"5":0,"1":0,"2":0,"7":0}},
                permissionsOptions:   [{'text':'-', value:0},{'text':'R', value:1},{'text':'RW', value:2}],
            },
        }
    },
    methods: {
        /**
         * Set up even listeners
         */
        whenReady() {
            var vueComponent = this;

            $('select').on('change', function () {
                var value = $(this).val();
                var data = $(this).data()['permission'];

                vueComponent.addChanged(value, data);
                // change color
                if(value == 1) {
                    $(this).removeClass();
                    $(this).addClass('optionRead');
                }
                else if(value == 2) {
                    $(this).removeClass();
                    $(this).addClass('optionReadWrite');
                }
                else if(value == 0) {
                    $(this).removeClass();
                }
            });

            $('button.editTypeButton').on('click', function () {
                var name = $(this).data()['name'];
                var typeKey = $(this).data()['typekey'];

                vueComponent.openEditDialog(name, typeKey);
            });

            vueComponent.checkForNewType();
        },

        addChanged: function(value, data) {
            data.value = value;
            this.$data.changed.push(data);
        },

        /**
         *  Method that will send ajax and display success/error message
         *  @param url - address to ajax endpoint, dataToSent - json data
         */
        sendAjax: function (url, dataToSent) {
            var vueComponent = this;
            axios.post(url , { data : dataToSent })
                .then(function(response) {
                    if (response.data.success) {
                        this.successSingleSnackbar = true;
                        this.snackbarText = response.data.message;

                        // behavior whats happen next when response=success have to be in this method because
                        // 'this' binding is not working and whatever I set inside this method to DATA is not visible outside....
                        let clause = response.data.clause;
                        if(clause === 'create') {
                            window.location.assign('/permissions-list?new');
                        }
                        else if(clause === 'delete') {
                            vueComponent.removeRow();
                        }
                    }
                    else {
                        this.snackbarText = response.data.error;
                        this.errorSnackbar = true;
                    }
                }.bind(this));
        },

        /**
         * send ajax to update content types with all changed select boxes
         */
        saveChanged: function() {
            let changedData = this.$data.changed;

            // check if changed is not empty -> send ajax
            if(changedData.length > 0) {
                // prepare data to send
                let object = $.extend({}, changedData);
                var prepared = JSON.stringify(object);
                this.sendAjax('/ajax/permissions/edit', prepared);
            }
            else {
                this.snackbarText = "You didn't change anything";
                this.errorSnackbar = true;
            }
        },

        updateName: function() {
            let dataToChange = {'key' : this.editKey, 'name' : this.editName};
            this.sendAjax('/ajax/permissions/editName', dataToChange);
            setTimeout(this.closeDialog(),100);
            // update content type name on the page
            let key = '#key-' + this.editKey;
            let newText = this.editName;
            $('table').find(key).text(newText);
        },

        openEditDialog: function(name, key) {
            this.editName = name;
            this.editKey = key;
            this.editDialog = true;
        },

        openNewDialog: function() {
            this.newDialog = true;
        },

        closeDialog: function() {
            this.editDialog = false;
            this.newDialog = false;
        },

        deleteRecord: function() {
            let data = {'delete' : true, 'key' : this.editKey};
            this.sendAjax('/ajax/permissions/delete', data);
        },

        removeRow: function () {
            // delete row with its content
            let key = '#key-' + this.editKey;
            var row = $('table').find(key).parent();
            row.css('background-color', 'red');
            row.hide('slow', function () {
                row.remove();
            });
            this.closeDialog();
        },

        saveNew: function() {
            // check if name is filled
            let name = this.newType.name.trim();

            if(name.length > 0 && name.length < 30) {
                let data = {'permissions' : this.newType.permissions, 'name' : name};
                this.sendAjax('/ajax/permissions/create', data);
            }
            else {
                alert('Make sure you enter correct content type name, and that name length is no longer than 30 characters');
            }
        },

        /**
         * If new content type was just created, it has '?new' in url and successSingleSnackbar should display
         */
        checkForNewType: function(){
            let url = window.location.href;
            if (url.includes('?new')) {
                this.snackbarText = 'Success! new content created!';
                this.successSingleSnackbar = true;
                // scroll to the bottom of the page
                    // $('html, body').animate({
                    //     scrollTop: $("#addNewType").offset().top
                    // }, 2000);
                // delete ?new from url
                setTimeout(function() {
                    window.history.pushState({}, null, '/permissions-list');
                },2100);
            }
        },

        reloadPage: function (url) {
            setTimeout(function () {
                window.location.assign(url);
            }, 500);
        },


    },
    created() {
      // this needs to be in setTimeout because vue...
      setTimeout(this.whenReady,10);
    },
}

</script>