<template>
    <div>
        <!-- list must be list of ['text' => x, 'value' => y] -->
        <v-select
            v-model="viewValue"
            :label="field.schema.displayName"
            :items="regions"
            autocomplete
        ></v-select>
        <input type="hidden" :name="field.fieldName" :value="field.value">
        <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
            <li v-for="error in field.errors" v-bind:key="error">
                {{ error }}
            </li>
        </v-alert>
    </div>

</template>

<script>
    /**
     * Allows the selection of the channel manager region
     */
    export default {
        name: 'ies-input-channel-manager-region',
        props: ['field'],
        data() {
            return {
                regions: [],
                viewValue: this.field.value,
            }
        },
        created:function() {
            this.getRegions();
        },
        methods: {
            getRegions:function(){
				this.regions = [
					{ text: "Please choose..", value: ""},
					{ text: "APAC", value: "APAC" },
					{ text: "EMEA", value: "EMEA" }
				];
            },
        },
        watch: {
            viewValue: function (newValue) {
                this.field.value = newValue;
            }
        }
    }
</script>

