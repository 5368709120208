<template>

    <v-container fluid>
       <v-layout row-sm wrap>

    <!-- Search tools -->
    <v-flex xs12>
        <v-card class="pa-0">
            <v-card-title><v-icon>search</v-icon> Search</v-card-title>
            <v-card-text class="pa-0">
              <v-expansion-panels multiple v-model="openedSearchPanels">
                <v-expansion-panel>
                    <v-expansion-panel-header><b>Relationships</b></v-expansion-panel-header>
                    <v-expansion-panel-content ripple>
                        <fieldset>
                            <v-layout row wrap>

                            <v-col cols="12">
                            <ies-hotel-and-room-key 
                                :field="searchByRoomField"
                                configuration='{"shouldEmitOnSelect":true, "selectHotelEventName":"Image Browser: search by hotel", "selectRoomEventName": "Image Browser: search by room type"}'></ies-hotel-and-room-key>
                            </v-col>

                            <v-col cols="12">
                            <v-select
                                v-model="searchFilters.region_key"
                                label="Region"
                                :items="regions"
                                autocomplete
                            ></v-select>

                            </v-col>

                            </v-layout>
                        </fieldset>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-model="panel">
                    <v-expansion-panel-header><b>Properties</b></v-expansion-panel-header>
                    <v-expansion-panel-content ripple>
                        <fieldset style="display: flex; align-items: center;">
                            <v-text-field label="ID" 
                                          v-model="searchFilters.imageKey" 
                                          v-on:keyup.enter="performSearch" 
                                          style="margin: 10px;"></v-text-field>
                            <v-text-field label="Min Width (px)"  
                                          v-model="searchFilters.minWidth" 
                                          v-on:keyup.enter="performSearch" 
                                          style="margin: 10px;"></v-text-field>
                            <v-text-field label="Min Height (px)" 
                                          v-model="searchFilters.minHeight" 
                                          v-on:keyup.enter="performSearch" 
                                          style="margin: 10px;"></v-text-field>
                            <v-text-field label="Ratio (W:H)" 
                                          v-model="searchFilters.ratio" 
                                          v-on:keyup.enter="performSearch" 
                                          style="margin: 10px;"></v-text-field>

                            <v-radio-group v-model="searchFilters.displayType" :mandatory="false">
                                <v-radio color="lime" label="Any" value=""/>
                                <v-radio color="lime" label="Display only" value="1"/>
                                <v-radio color="lime" label="Hidden only"  value="0"/>
                            </v-radio-group>

                            <v-select
                                :items="imageTypes"
                                v-model="searchFilters.imageType"
                                label="Select Type of Image"
                                item-text="text"
                                item-value="value"
                                dense
                                id="image-type"
                            >
                            </v-select>
                        </fieldset>
                    </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- This is commented out because the editorial department said they don't want it but aren't sure, we will keep it for now in case they do need it
            <v-expansion-panel>
                    <v-expansion-panel-header><b>Date Range</b></v-expansion-panel-header>
                    <v-expansion-panel-content ripple>
                            <fieldset style="display: flex;">
                                <div style="margin: auto;">
                                    <div class="bold">Date From:</div>
                                    <v-date-picker
                                        :landscape="true"
                                        v-model="searchFilters.dateFrom"
                                        color="lime"
                                        type="date"
                                    ></v-date-picker>
                                </div>
                                <div style="margin: auto;">
                                    <div class="bold">Date Until:</div>
                                    <v-date-picker
                                        :landscape="true"
                                        v-model="searchFilters.dateUntil"
                                        color="lime"
                                        type="date"
                                    ></v-date-picker>
                                </div>
                            </fieldset>
                    </v-expansion-panel-content>
            </v-expansion-panel>
            -->
            <!--
            <v-expansion-panel>
                    <v-expansion-panel-header><b>Tags</b></v-expansion-panel-header>
                    <v-expansion-panel-content ripple>
                        <v-flex xs12 v-if="imageKeywords.length" v-for="(tag, index) in imageKeywords">
                            <label>{{ tag.label }}</label>
                            <input type="checkbox"
                               v-model="tag.isTicked"
                            >
                        </v-flex>
                    </v-expansion-panel-content>
            </v-expansion-panel>
            -->
            </v-expansion-panels>


            </v-card-text>
            <v-card-actions>
                <v-btn @click="performSearch" class="ies-green"><v-icon>search</v-icon> Search</v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>

    <v-flex xs12 class="mt-3">
          <v-card class="pa-1">
                <v-btn :disabled="selectedImages.images.length === libraryImages.length" @click="selectAll">Select All</v-btn>
                <v-btn :disabled="selectedImages.images.length === 0" @click="deselectAll">Deselect All</v-btn>
                <v-btn :disabled="selectedImages.images.length === 0" class="ies-green" @click="openImageSelector">Edit Selected{{ selectedImages.images.length ? " ("+selectedImages.images.length+")" : "" }}</v-btn>
                <v-btn :disabled="selectedImages.images.length === 0" @click="openImageSuitability">Check Suitability</v-btn>
                <v-btn @click="refreshLibraryImages">Refresh Results</v-btn>
                <v-btn @click="clearAllFilters">Clear All Filters</v-btn>
                <v-btn :disabled="selectedImages.images.length === 0" @click="deleteSelected" class="red">Delete Selected</v-btn>
                <v-btn :disabled="displayMode != 'inline'" @click="saveInlineChanges" class="ies-green"><v-icon>save</v-icon> Save Changes</v-btn>
          </v-card>
    </v-flex>

    <a name="topOptionsBar" id="topOptionsBar"></a>
    <v-flex xs12 class="mt-3 sticky" v-if="showSticky">
          <v-card class="pa-1">
                <v-btn :disabled="selectedImages.images.length === libraryImages.length" @click="selectAll">Select All</v-btn>
                <v-btn :disabled="selectedImages.images.length === 0" @click="deselectAll">Deselect All</v-btn>
                <v-btn :disabled="selectedImages.images.length === 0" class="ies-green" @click="openImageSelector">Edit Selected{{ selectedImages.images.length ? " ("+selectedImages.images.length+")" : "" }}</v-btn>
                <v-btn :disabled="selectedImages.images.length === 0" @click="openImageSuitability">Check Suitability</v-btn>
                <v-btn @click="refreshLibraryImages">Refresh Results</v-btn>
                <v-btn @click="clearAllFilters">Clear All Filters</v-btn>
                <v-btn :disabled="selectedImages.images.length === 0" @click="deleteSelected" class="red">Delete Selected</v-btn>
                <v-btn :disabled="displayMode != 'inline'" @click="saveInlineChanges" class="ies-green"><v-icon>save</v-icon> Save Changes</v-btn>
          </v-card>
    </v-flex>
    
   <v-flex xs12 class="mt-4 white">
        <v-layout row align-center class="">  
             <v-flex>
                <strong>Total:</strong> {{ totalItems }} Items
            </v-flex>      
            <v-spacer></v-spacer>

            <v-flex>
                <v-btn v-on:click="goToFirstPage()" depressed><v-icon>first_page</v-icon></v-btn>
                <v-btn v-on:click="goToPrevPage()" depressed><v-icon>navigate_before</v-icon></v-btn>

                Page {{ pageNumber + 1 }} of {{ totalPages }}

                <v-btn v-on:click="goToNextPage()" depressed><v-icon>navigate_next</v-icon></v-btn>
                <v-btn v-on:click="goToLastPage()" depressed><v-icon>last_page</v-icon></v-btn>
            </v-flex>

            <v-spacer></v-spacer>

            <v-flex>
                <strong class="text-left">Rows per Page:</strong>

                <v-select
                    :items="rowsPerPageOpts"
                    v-model="chosenRowsPerPage"
                    label=""
                    class=""
                    item-value="value"
                    single-line
                    justify-end
                ></v-select>

            </v-flex>
        </v-layout>
   </v-flex>

    <span v-if="message" align-center>{{ message }} </span>


    <v-flex xs12 class="white-out" v-if="displayWhiteOut">&nbsp;</v-flex>

    <!-- Image Browser -->
    <v-flex class="mt-3" xs2  v-for="(libraryImage, image_key) in libraryImages" :key="image_key">
        <!-- Disabled click to edit -->
        <!-- <v-card @click.native="selectImage(libraryImage.image_key)" --> 
        <v-card
                class="pa-3" 
                v-bind:class="{ selected: libraryImage.is_selected }"
                style="height:100%;">
            <v-container flex>
            <v-layout row wrap>
                <v-flex xs9>
                    <b>ID:</b> {{ libraryImage.image_key }}
                </v-flex>
                <v-flex xs3 style="text-align: right;">
                    <a :href="libraryImage.image_source" download><v-icon>download</v-icon></a>
                    <input type="checkbox" 
                           class="selection-checkbox" 
                           v-model="libraryImage.is_selected" 
                           v-on:click.stop 
                           v-on:change="selectLibraryImage(libraryImage)">
                </v-flex>

                <!-- Thumbnail -->
                <v-flex xs12>
                    <img
                        class="thumbnail"
                        :class="{'image-landscape': libraryImage.original_x > libraryImage.original_y }"
                        alt="Photo library image not found"
                        v-bind:src="libraryImage.image_source"
                    >
                </v-flex>

                <!-- Description -->
                <v-flex xs12 v-if="libraryImage.hotel">
                    <b>Hotel:</b> ({{ libraryImage.hotel.hotel_code }}) {{ libraryImage.hotel.hotel_name }}
                </v-flex>
                <v-flex xs12>
                    <b>Display:</b> <span v-if="displayMode != 'inline'">{{ libraryImage.display_slideshow ? 'Yes' : 'No' }}</span>
                    <input type="checkbox" 
                           v-model="libraryImage.display_slideshow" 
                           v-on:change="inlineImageEdited(libraryImage)"
                           v-if="displayMode == 'inline'">
                    <b>Type:</b> <span v-if="displayMode != 'inline'">{{ (typeof libraryImage.image_type === undefined || libraryImage.image_type == null || typeof libraryImage.image_type.display_name_short === undefined) ? null : libraryImage.image_type.display_name_short }}</span>

                            <select
                                v-if="displayMode == 'inline'"
                                class="inline-image-type"
                                :items="imageTypes"
                                v-model="libraryImage.image_type_key"
                                item-text="textShort"
                                item-value="value"
                                v-on:change="inlineImageEdited(libraryImage)"
                                dense
                                id="image-type"
                            >
                                <option v-for="imageType in imageTypes" :value="imageType.value">{{ imageType.textShort }}</option>
                            </select>

                    <b>Order:</b>
                    <span v-if="displayMode != 'inline'"> {{ libraryImage.sort_order }}</span>
                    <input type="text" 
                           v-model="libraryImage.sort_order" 
                           v-on:change="inlineImageEdited(libraryImage)"
                           v-on:keyup="inlineImageEdited(libraryImage)"
                           v-if="displayMode == 'inline'" 
                           class="inline-sort-order">
                </v-flex>
                <v-flex xs12 v-if="libraryImage.hotel_room">
                    <b>RT:</b> <a :href="'/hotel-rooms/'+libraryImage.hotel_room.hotel_room_key">{{ libraryImage.hotel_room.name.length < 28 ? libraryImage.hotel_room.name : libraryImage.hotel_room.name.slice(0, 25) + "..." }}</a>
                </v-flex>
                <v-flex xs12 v-if="libraryImage.caption || displayMode == 'inline'">
                    <b>Caption:</b> <span v-if="displayMode != 'inline'">{{ libraryImage.caption.length < 53 ? libraryImage.caption : libraryImage.caption.slice(0, 50) + "..." }} </span>
                    <input type="text" 
                           v-model="libraryImage.caption" 
                           v-on:change="inlineImageEdited(libraryImage)"
                           v-on:keyup="inlineImageEdited(libraryImage)"
                           v-if="displayMode == 'inline'">
                </v-flex>
                <v-flex xs12 v-if="libraryImage.copyright_info || displayMode == 'inline'">
                    <b>Copyright:</b> <span v-if="displayMode != 'inline'">{{ libraryImage.copyright_info.length < 53 ? libraryImage.copyright_info : libraryImage.copyright_info.slice(0, 50) + "..." }}</span>
                    <input type="text" 
                           v-model="libraryImage.copyright_info" 
                           v-on:change="inlineImageEdited(libraryImage)"
                           v-on:keyup="inlineImageEdited(libraryImage)"
                           v-if="displayMode == 'inline'">
                </v-flex>
                <v-flex xs12 v-if="libraryImage.description || displayMode == 'inline'">
                    <b>Description:</b> <span v-if="displayMode != 'inline'">{{ libraryImage.description.length < 53 ? libraryImage.description : libraryImage.description.slice(0, 50) + "..." }}</span>
                    <input type="text" 
                           v-model="libraryImage.description" 
                           v-on:change="inlineImageEdited(libraryImage)"
                           v-on:keyup="inlineImageEdited(libraryImage)"
                           v-if="displayMode == 'inline'">
                </v-flex>
                <v-flex xs12>
                    <b>Date:</b> {{ dateYYYYMMDD(libraryImage.creation_date) }}
                </v-flex>
                <v-flex xs12>
                    <b>Pixels:</b> {{ libraryImage.original_x }}x{{ libraryImage.original_y }} ({{ imageRatio(libraryImage.original_x, libraryImage.original_y) }})
                </v-flex>

                <!-- Tag icons - for now this is hardcoded, but needs to be dynamic -->
                <v-flex xs12>
                    <!-- <b>Tags:</b> -->
                    <span v-if="libraryImage.image_keywords && libraryImage.image_keywords.length">
                    <span v-for="(tag, index) in libraryImage.image_keywords">
		        <v-icon :title="tag.keyword.name">{{ tag.keyword.icon_name }}</v-icon>
                    </span>
                    </span>
                </v-flex>
            </v-layout>
            </v-container>

        </v-card>
    </v-flex>

      <!-- Image suitability panel -->
      <v-flex xs12 v-if="displayImageSuitability" class="modal-80">
        <span class="close-button"><v-btn v-on:click="closeImageSuitability()"><v-icon>cancel</v-icon></v-btn></span>
        <v-card>
          <v-container fluid>
              <v-layout row-sm wrap>

                    <v-flex xs12>
                        <v-container fluid>
                            <v-layout row-sm wrap>
                               <h1>Image Suitability</h1>

                               <v-flex xs12 v-for="(selectedImage, image_key) in selectedImages.images" :key="image_key">
                                    <h2>{{ selectedImage.image_key }}</h2>
                                    <div>Full size: {{ selectedImage.original_x }} x {{ selectedImage.original_y }}px ({{ imageRatio(selectedImage.original_x, selectedImage.original_y) }}) {{ imageRatio(selectedImage.original_x, selectedImage.original_y).includes('~') ? "This ratio is approximate; exercise caution when using the image in a page where the image ratio matters" : "" }}</div>
                                    <div>Home Double (795x395px) {{ selectedImage.original_x < 795 || selectedImage.original_y < 395 ? "Warning: This image may not be large enough" : "Ok" }}</div>
                                    <div>Home Single (395x395px) {{ selectedImage.original_x < 395 || selectedImage.original_y < 395 ? "Warning: This image may not be large enough" : "Ok" }}</div>
                                    <div>Desktop Hero (1900x600px) {{ selectedImage.original_x < 1900 || selectedImage.original_y < 600 ? "Warning: This image may not be large enough for desktop screens" : "Ok" }}</div>
                                    <div>PTS Hotel Thumbnail (467x300px) {{ selectedImage.original_x < 467 || selectedImage.original_y < 300 ? "Warning: This image may not be large enough" : "Ok" }}</div>
                                    <div>PTS Footer (395x395px) {{ selectedImage.original_x < 395 || selectedImage.original_y < 395 ? "Warning: This image may not be large enough" : "Ok" }}</div>

                                 <v-expansion-panels multiple v-model="openedSuitabilityPanels">
                                 <v-expansion-panel>
                                  <v-expansion-panel-header>
                                      <label>More Details</label>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <v-layout row>

                                    <v-col cols="12">
                                    <h3 class="mt-5">Hero Image:</h3>
                                    <div>1900x600px {{ selectedImage.original_x < 1900 || selectedImage.original_y < 600 ? "Warning: This image may not be large enough for desktop screens" : "Ok" }}</div>
                                    <div>Note that the actual size of the hero image will vary according to the screen size, and the design of the page it is displayed on</div>
                                    <div class="hero-container" :style="{'background-image': 'url(' + selectedImage.image_source + ')'}">
                                        <span class="hero-text">Lorem Ipsum Dolor Sit Amet</span>
<!--
                                        <img
                                            alt="Image from photo library"
                                            v-bind:src="selectedImage.image_source"
                                            class="hero"
                                        >
-->
                                    </div>
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                    <h3 class="mt-5">PTS Thumbnail:</h3>
                                    <div>467x300px {{ selectedImage.original_x < 467 || selectedImage.original_y < 300 ? "Warning: This image may not be large enough" : "Ok" }}</div>
                                    <div class="pts-thumbnail">
                                        <span class="hotel-listing--thumb_notice">20% OFF</span>
                                        <img class="pts-prev" src="/images/thumbnail-left-arrow.png" alt="Lef chevron" width="30px" height="48px">
                                        <img class="pts-next" src="/images/thumbnail-right-arrow.png" alt="Right chevron" width="30px" height="48px">

                                        <img
                                            alt="Image from photo library"
                                            v-bind:src="selectedImage.image_source"
                                            class="pts-thumbnail-image"
                                        >
                                    </div>
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                    <h3 class="mt-5">PTS Footer Links:</h3>
                                    <div>395x395px {{ selectedImage.original_x < 395 || selectedImage.original_y < 395 ? "Warning: This image may not be large enough" : "Ok" }}</div>
                                    <div class="pts-footer-container">
                                        <span class="pts-footer-text">Insiders' Guide:<br>Lorem Ipsum</span>
                                        <img
                                            alt="Image from photo library"
                                            v-bind:src="selectedImage.image_source"
                                            class="pts-footer-image"
                                        >
                                    </div>
                                    </v-col>

                                    <v-col cols="12">
                                    <h3 class="mt-5">Home Page Tiles:</h3>
                                    <div>Double: 795x395px {{ selectedImage.original_x < 795 || selectedImage.original_y < 395 ? "Warning: This image may not be large enough" : "Ok" }}</div>
                                    <div>Single: 395x395px {{ selectedImage.original_x < 395 || selectedImage.original_y < 395 ? "Warning: This image may not be large enough" : "Ok" }}</div>
                                    <div>
                                        <div class="tile-double-container">
                                            <span class="tile-double-text">consectetur adipiscing elit<br/>sed do eiusmod</span>
                                            <img
                                                alt="Image from photo library"
                                                v-bind:src="selectedImage.image_source"
                                                class="tile-double-image"
                                            >
                                        </div>
                                        <div class="tile-single-container">
                                            <span class="tile-single-text">Lorem Ipsum Dolor<br/>Sit Amet</span>
                                            <img
                                                alt="Image from photo library"
                                                v-bind:src="selectedImage.image_source"
                                                class="tile-single-image"
                                            >
                                        </div>
                                    </div>
                                    </v-col>

                                    </v-layout>

                                   </v-expansion-panel-content>
                                  </v-expansion-panel>
                                  </v-expansion-panels>

                                  <hr>

                               </v-flex>
                            </v-layout>
                        </v-container>
                    </v-flex>
               </v-layout>
           </v-container>

           <v-card-actions>
               <v-btn v-on:click="closeImageSuitability()">Close</v-btn>
           </v-card-actions>
        </v-card>
      </v-flex>

      <!-- Image editor panel -->
      <v-flex xs12 v-if="displayImageEditor" class="modal-80">
        <span class="close-button"><v-btn v-on:click="closeImageSelector()" depressed><v-icon>cancel</v-icon></v-btn></span>
        <v-card>
          <v-container fluid>
              <v-layout row-sm wrap>

                    <v-col cols="12">
                        <v-container fluid>
                            <v-layout row-sm wrap>

                               <v-flex xs1 v-for="(selectedImage, image_key) in selectedImages.images" :key="image_key">
                                    {{ selectedImage.image_key }}
                                    <img
                                        alt="Image from photo library"
                                        v-bind:src="selectedImage.image_source"
                                        style="max-height: 150px;"
                                    >
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-col>

                    <v-col cols="12">
                        <b style="color:red;">Note:</b> <u>All</u> selected images will be given the properties below.  Their original values will <u>not</u> be preserved if you leave the fields blank.
                    </v-col>

                    <v-col cols="4">
                    <fieldset>
                        <legend>Common Properties</legend>

                        <v-select
                            :items="imageTypes"
                            v-model="selectedImages.imageType"
                            label="Select Type of Image"
                            item-text="text"
                            item-value="value"
                            dense
                            id="image-type"
                        ></v-select>

                        <v-flex xs12>
                            <v-text-field
                                label="Image Caption:"
                                name="caption"
                                v-model="selectedImages.caption"
                            ></v-text-field>
                        </v-flex>

                        <v-flex xs12>
                            <b>Copyright:</b>
                            <v-text-field
                                label="Copyright Information:"
                                name="copyright"
                                v-model="selectedImages.copyright_info"
                            ></v-text-field>
                        </v-flex>

                        <v-flex xs12>
                            <b>Description:</b>
                            <textarea name="description" type="text" class="image-description" v-model="selectedImages.description">
                            </textarea>
                        </v-flex>
                    </fieldset>
                    </v-col>

                    <v-col cols="3">
                    <fieldset>
                        <legend>Slideshows</legend>

                        <v-flex xs12>
                            <label>Display in Slideshows: </label>
                            <input type="checkbox"
                               v-model="selectedImages.display_slideshow"
                            >
                        </v-flex>

                        <v-flex xs12>
                            <v-text-field
                                label="Sort Order in Slideshows:"
                                name="sort_order"
                                v-model="selectedImages.sort_order"
                            ></v-text-field>
                        </v-flex>
                  </fieldset>
                  </v-col>

                  <v-col cols="3" style="display: none;">
                    <fieldset>
                        <legend>Image Tags</legend>
                        <v-flex xs12 v-for="(tag, index) in selectedImages.imageKeywordOptions">

                            <input type="checkbox"
                               v-model="tag.isTicked"
                            >
                            <label>{{ tag.label }}</label>
                        </v-flex>
                        <v-flex xs12>
                            <a href="/keywords/list">Manage Keywords</a>
                        </v-flex>
                    </fieldset>
                  </v-col>


                  <v-col cols="5">
                    <fieldset>
                       <v-layout row wrap>

                       <legend>Relationships</legend>
                       <!--<v-text-field label="Hotel Code" v-model="selectedImages.hotelCode"></v-text-field>*-->
                
                       <!--
                       <ies-hotel-key configuration='{"shouldEmitOnSelect":true, "selectEventName":"Image Browser: select hotel"}'></ies-hotel-key>
                       <ies-hotel-room-key configuration='{"shouldEmitOnSelect":true, "selectEventName":"Image Browser: select room type"}'></ies-hotel-room-key>
                       -->

                        <ies-hotel-key 
                            :field="selectHotelField"
                            configuration='{"shouldEmitOnSelect":true, "selectEventName":"Image Browser: select hotel"}'></ies-hotel-key>

                        <ies-hotel-room-key 
                            :field="selectRoomField"
                            configuration='{"shouldEmitOnSelect":true, "selectEventName":"Image Browser: select room type"}'></ies-hotel-room-key>

                       <!--
                       <v-select
                           v-model="selectedImages.hotel_room_key"
                           label="Room Type"
                           :items="hotelRooms"
                           autocomplete
                        ></v-select>*
                        -->

                       <v-select
                           v-model="selectedImages.region_key"
                           label="Region"
                           :items="regions"
                           autocomplete
                        ></v-select>
                            
                        </v-layout>
                    </fieldset>
                    </v-col>

                    <v-col cols="12">
                        <v-btn @click="saveChanges" class="ies-green">Save Changes</v-btn>
                         <v-btn @click="deleteSelected" class="red">Delete Selected</v-btn>
                        <v-btn @click="closeImageSelector" >Cancel</v-btn>
                    </v-col>

                    </v-layout>
                    </v-container>
                    </v-card>
              </v-flex>


              <!-- Bottom pagination bar -->
                <v-flex v-if="totalItems >= 1" xs12 class="mt-4 white">
                    <v-layout row align-center class="">  
                        <v-flex>
                            <strong>Total:</strong> {{ totalItems }} Items
                        </v-flex>      
                        <v-spacer></v-spacer>

                        <v-flex>
                            <v-btn v-on:click="goToFirstPage()" depressed><v-icon>first_page</v-icon></v-btn>
                            <v-btn v-on:click="goToPrevPage()" depressed><v-icon>navigate_before</v-icon></v-btn>

                            Page {{ pageNumber + 1 }} of {{ totalPages }}

                            <v-btn v-on:click="goToNextPage()" depressed><v-icon>navigate_next</v-icon></v-btn>
                            <v-btn v-on:click="goToLastPage()" depressed><v-icon>last_page</v-icon></v-btn>
                        </v-flex>

                        <v-spacer></v-spacer>

                        <v-flex>
                            <strong class="text-left">Images per Page:</strong>

                            <v-select
                                :items="rowsPerPageOpts"
                                v-model="chosenRowsPerPage"
                                label=""
                                class=""
                                item-value="value"
                                single-line
                                justify-end
                            ></v-select>

                        </v-flex>
                    </v-layout>
                </v-flex>

              <!-- Bottom options bar -->
              <v-flex xs12 class="mt-2">
                  <v-card>
                        <v-btn :disabled="selectedImages.images.length === libraryImages.length" @click="selectAll">Select All</v-btn>
                        <v-btn :disabled="selectedImages.images.length === 0" @click="deselectAll">Deselect All</v-btn>
                        <v-btn :disabled="selectedImages.images.length === 0" class="ies-green" @click="openImageSelector">Edit Selected{{ selectedImages.images.length ? " ("+selectedImages.images.length+")" : "" }}</v-btn>
                        <v-btn :disabled="selectedImages.images.length === 0" @click="openImageSuitability">Check Suitability</v-btn>
                        <v-btn @click="refreshLibraryImages">Refresh Results</v-btn>
                        <v-btn @click="clearAllFilters">Clear All Filters</v-btn>
                        <v-btn :disabled="selectedImages.images.length === 0" @click="deleteSelected" class="red">Delete Selected</v-btn>
                        <v-btn :disabled="displayMode != 'inline'" @click="saveInlineChanges" class="ies-green"><v-icon>save</v-icon> Save Changes</v-btn>
                  </v-card>
              </v-flex>

        </v-layout>
    </v-container>
</template>

<script>

    //import EventBus from '@/eventBus';
    //import hotelPicker from "./inputs/HotelKey";
    import hotelKey from '../inputs/HotelKey';
    import hotelRoomTypeKey from '../inputs/HotelRoomTypeKey';
    import hotelAndRoomKey from '../inputs/HotelAndRoomKey';


/**
 * A simple image browser tool for navigating the photo library
 * to allow the user to view and interact with the photo library to some extent
 *
 * This component is designed as a bare bones initial device to get basic functionality
 * and can be further modified or replaced at a later date with something more
 * sophisticated
 */
export default {
    name: 'ies-disabled',
    components: { 'ies-hotel-key': hotelKey, 'ies-hotel-room-key': hotelRoomTypeKey, 'ies-hotel-and-room-key': hotelAndRoomKey },
    props: [],
    data () {
        return {
            /** @var array regions */
            regions: [],
            hotelRooms: [],

            showSticky: false,

            /** @var {} imageField - A copy of the image field being edited, used to allow Vue to react to changes on the data model with changes in the computed methods without the need for watchers */
            imageField:     {},

            /** @var boolean displayImageEditor - Should the user be presented with an image picker modal to choose the replacement image record */
            displayImageEditor: false,

            /** @var boolean displayImageSuitability - A tool to show how well the image will perform for different purposes */
            displayImageSuitability: false,

            /** @var boolean displayWhiteOut - Toggle the div to display blanking out the background */
            displayWhiteOut: false,

            /** @var {} searchFilters - An object encapsulating all of the different parameters that the user is searching the image library by */
            searchFilters:  { imageKey: null, hotelKey: null, hotelCode: null, hotelRoomKey: null, dateFrom: null, dateUntil: null, imageType: null, regionKey: null, displayType: '', minWidth: null, minHeight: null, ratio: null, maxRecords: 50, offset: 0, keywords: [] },

            /** @var [{}] libraryImages - An array of objects representing the images returned by the server to choose from */
            libraryImages:  [],

            /** @var [] imageTypes - An array of image types for the user to choose between when searching the photo library */
            imageTypes:     [],

            imageKeywords: [],

            /** @var array panel - array for vuetify to use to know which expansion panels to open by default*/
            panel: [0],

            openedSuitabilityPanels: [0],

            openedSearchPanels: [0, 1],

            // The JSON for the field as required by the hotel key picker - @todo - adjust the hotel key picker so it doesnt need one!
            searchByHotelField: {fieldName: "searchByHotel", value: "", schema:{displayName: "Hotel"}},
            searchByRoomField: {fieldName: "searchByHotelRoom", value: "", schema:{displayName: "Room Type"}},

            selectHotelField: {fieldName: "selectHotel", value: "", schema:{displayName: "Hotel"}},
            selectRoomField: {fieldName: "selectHotelRoom", value: "", schema:{displayName: "Room Type"}},

            showImageEditor: false,
            displayMode: 'inline',

            /**@var {} chosenRowsPerPage - Number of rows the user would like to see per page */
            chosenRowsPerPage: 50,
            pageNumber:     0,
            totalItems: 0,
            message: '',
            rowsPerPageOpts:[{ 'text': '20', 'value': 20 }, {'text': '50', 'value': 50}, {'text': '100', 'value': 100}, {'text':'All', 'value':'All'}],
            totalImages: 0,
            totalPages: 0,

            /** @var array selectedImages - An array of image records which the user wants to edit in bulk */
            selectedImages:  { images:[], imageKeywordOptions:[] },

            /** @var {} */
            imageTypeInitials: {
                                    '1':  'O',  // Overview
                                    '2':  'R',  // rooms
                                    '3':  'E',  // eating
                                    '4':  'A',  // activities
                                    '5':  'F',  // features
                                    '6':  'S',  // site_photo
                                    '7':  'H',  // home
                                    '9':  'PH', // photo_library
                                    '10': 'OL', // overview_large
                                    '11': 'HL', // home_large
                                    '12': 'RL', // rooms_large
                                    '13': 'EL', // eating_large
                                    '14': 'AL', // activities_large
                                    '15': 'F',  // features_large
                                    '16': 'SL', //site_photo_large
                                    '17': 'K',  // kids
                                    '18': 'KL', //  kids_large
                                    '19': 'V',  //  physical_voucher
                                },
        }
    },

    /**
     * On mounting the component we are cloning the field to this.data
     * so Vue will be reactive to changes and automatically trigger the
     * computed methods to run without us needing to set up numerous
     * watchers to handle everything we want to happen now and in the future
     */
    mounted: function(){
        this.getHotelRooms();
        this.getRegions();
        this.loadImageTypes();
        this.setFiltersFromURL();
        this.loadLibraryImages();
        this.setUpEventListeners();
        this.loadKeywords();
    },

    watch: {
        /**
         * The server side will restrict the maximum records downloaded in a single request so the user won't actually get all of the dataset above that threshold
         * As a result we need to pre-empt this problem and tell the user what is about to happen, and then offer them some helpful advice on how to still get all
         * the data they need
         */
        chosenRowsPerPage: function(newValue){
            if (newValue == 'All' && this.totalItems > 500){
                this.message = 'Loading all ' + this.totalItems + ' in one page is not allowed for performance reasons. \n\nTry adding some filters to narrow down your search.';
                   console.log('Loading all ' + this.totalItems + ' in one page is not allowed for performance reasons. \n\nTry adding some filters to narrow down your search.');

            } else {
                this.message = '';
            }
            this.searchFilters.maxRecords = newValue;
            this.loadLibraryImages();
            if (this.pageNumber > this.totalPages) {
                this.lastPage();
            }
        },

        libraryImages: function() {
            this.getImageCount();
        },

        offset: function() {
            this.searchFilters.offset = this.offset;
        },

        totalItems: function() {
            if (this.totalItems == 0) {
                this.message = 'Sorry, your search didn\'t match any results. Try some different filters.';
            } else {
                this.message = '';
            }
        }

    },


    methods: {
        // Format the dates in purely YYYY-MM-DD
        dateYYYYMMDD:function(myDateString){
            var myDate = new Date(Date.parse(myDateString));
            return myDate.getFullYear() + "-" + ((myDate.getMonth() + 1) < 10 ? "0" + (myDate.getMonth() + 1) : myDate.getMonth() + 1) + "-" + (myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate());
        },


        // Examples found online to calculate lowest common multiple for a pair of numbers:
        gcd: function(a, b) { 
            for (let temp = b; b !== 0;) { 
                b = a % b; 
                a = temp; 
                temp = b; 
            } 
            return a; 
        },
  
        lcm: function(a, b) { 
            const gcdValue = this.gcd(a, b); 
            return (a * b) / gcdValue; 
        },

/*
// Alternative:
findLCM: function(a, b) {
    let lar = Math.max(a, b);
    let small = Math.min(a, b);
    var i = null; 
    for (i = lar; ; i += lar) {
        if (i % small == 0)
            return i;
    }
},
*/
        imageRatio: function(x, y){
            var mikesSpecialRatios = ['1:1', '3:2', '4:3', '16:9'];
            var exactRatio = this.imageRatioExact(x, y);
            // If the image ratio is one of the few special ones Mike wants to see
            // then return it - the user will be reassured to know it is correct.
            // Otherwise we must return an approximation using Mike's logic.
            if (mikesSpecialRatios.includes(exactRatio)){
                return exactRatio;
            }
            return this.imageRatioApprox(x, y);
        },

        imageRatioExact: function(x, y){
            //console.log("imageRatio: ", x, y);

            // Square (no point calculating anything)
            if (x == y){
                //console.log("... is square");
                return "1:1";
            }

            // Landscape
            if (x > y){
                //console.log("... is landscape ", (x/y)+":1");
                var lowestCommonMultiple = this.lcm(x, y);
                //console.log("LCM ", lowestCommonMultiple);
                return (lowestCommonMultiple / y) + ":" + (lowestCommonMultiple / x);
            }

            // Portrait
            if (x < y){
                //console.log("... is portrait", "1:"+(y/x));
                //console.log("... is landscape ", (x/y)+":1");
                var lowestCommonMultiple = this.lcm(x, y);
                //console.log("LCM ", lowestCommonMultiple);
                return (lowestCommonMultiple / x) + ":" + (lowestCommonMultiple / y);
            }

            return '';
        },

        /**
         * Mike doesnt want the image ratios to display correctly.  Instead
         * he wants the editorial team to upload any images they feel like and
         * the approximate aspect ratio will be displayed instead.  This approach
         * seems crude so in order to satisfy the brief we will do it but prepend
         * a tilde so it is clear it is an approximate ratio where necessary
         *
         * Mike: 
         *   'Note: if the ratio is none of the above, we would prefer the ratio to be left blank (rather than repeat the pixels, or lowest divisible variant thereof)'
         */
        imageRatioApprox: function(x, y){
            var xApprox = 1;
            var yApprox = 1;

            var widthByHeight = x / y;
            if (widthByHeight > 0.98 && widthByHeight < 1.02){
                return '~1:1';
            } 
            if (widthByHeight > 1.48 && widthByHeight < 1.52){
                return '~3:2';
            } 
            if (widthByHeight > 0.3 && widthByHeight < 1.35){
                return '~4:3';
            } 
            if (widthByHeight > 0.75 && widthByHeight < 1.8){
                return '~16:9';
            } 

            // As per Mike's brief, they'd rather not know the image ratio if it doesn't fit the approximate ones above
            return '';
        },

        getRegions:function(){
            axios.get('/api/selectable-regions')
            .then(function(response){
                var regions = [];
                response.data.regions.forEach(function(record){
                    regions.push({ text: record.region_name, value: record.region_key });
                });
                this.regions = regions;
            }.bind(this));
        },

        getHotelRooms:function(){
                axios.get('/api/hotelsWithRooms')
                .then(function(response){
                    var hotelRooms = [];
                    response.data.forEach(function(hotel){
                        hotel.rooms.forEach(function(roomType){
                            hotelRooms.push({ text: '(' + hotel.hotel_code + ') ' + roomType.name, value: roomType.hotel_room_key });
                        });
                    });
                    this.hotelRooms = hotelRooms;

                }.bind(this));
        },

        /**
         * Specifies that the given image key should be selected, and that all
         * others currently selected will also be deselected
         */
        selectImage: function(imageKey){
            this.deselectAll();
            this.addImageToSelection(imageKey);
            this.openImageSelector();            
        },

        /**
         * Specifies that the given image should be selected, and added to the
         * existing list of others currently selected
         */
        selectLibraryImage: function(image){
            if (image.is_selected){
                this.addImageToSelection(image.image_key);
            }
            else {
                this.deselectImage(image.image_key);
            }
        },

        /**
         * Specifies that every image currently displayed on this page of library
         * imnages should be selected ready for batch manipulation
         */
        selectAll: function(){
            var self = this;
            this.libraryImages.forEach(function(image){
                self.selectedImages.images.push(image);
                image.is_selected = true;
            });
        },

        /**
         * Specifies that the given image key should be deselected
         */
        deselectImage: function(imageKey){
            var self = this;
            var index = 0;
            this.selectedImages.images.forEach(function(image){
                if (image.image_key === imageKey){
                    image.is_selected = false;

                    console.log(self.selectedImages.images, index);
                    self.selectedImages.images.splice(index, 1);
                }
                index++;
            });

            this.libraryImages.forEach(function(image){
                if(image.image_key === imageKey){
                    image.is_selected = false;
                }
            });
        },

        /**
         * Specifies that all images currently displayed to the user in the page
         * of library images should be deselected for manipulation
         */
        deselectAll: function(){
            console.log("will deselect: ", this.libraryImages);
            this.libraryImages.forEach(function(image){
                image.is_selected = false;
            });
            this.selectedImages = { images: [], imageKeywordOptions:[] };
        },

        /**
         * Logical function which performs the work of adding an image to the
         * selection list to be sent to the API, and also flagging its library
         * entry for highlighting in the view template
         */
        addImageToSelection: function(imageKey){
            var self = this;
            this.libraryImages.forEach(function(image){
                if (image.image_key == imageKey){
                    self.selectedImages.images.push(image);
                    image.is_selected = true;
                }
            });
        },

        /**
         * Specifies that the given image key should be flagged as highlighted
         * in the list of library images shown in the view template
         */
        flagLibraryImageSelected: function(imageKey){
            var self = this;
            this.libraryImages.forEach(function(image){
                if (image.image_key == imageKey){
                    image.is_selected = true;
                }
            });
        },

        setEditorComponents: function(){
            var self = this;
            this.selectedImages.images.forEach(function(selectedImage){
                self.selectedImages.caption = selectedImage.caption;
                self.selectedImages.copyright_info = selectedImage.copyright_info;
                self.selectedImages.description = selectedImage.description;
                self.selectedImages.display_slideshow = selectedImage.display_slideshow;
                self.selectedImages.imageType = selectedImage.image_type_key;
                self.selectedImages.hotelCode = selectedImage.hotel.hotel_code;
                self.selectedImages.hotelKey = selectedImage.hotel_key;
                self.selectedImages.hotelRoomKey = selectedImage.hotel_room_key;
                self.selectedImages.regionKey = selectedImage.region_key;
                //self.selectedImages.region = selectedImage.hotel.hotel_code;// not yet supported
                self.selectedImages.sort_order = selectedImage.sort_order;

                // Update view model for hotel and room selector UI components
                self.selectHotelField.value = self.selectedImages.hotelKey;
                self.selectRoomField.value = self.selectedImages.hotelRoomKey;
            });
        },

        openImageSelector: function(){
            this.loadKeywords();
            this.displayWhiteOut = true;
            this.displayImageEditor = true;
            this.setEditorComponents();
        },

        closeImageSelector: function(){
            this.displayWhiteOut = false;
            this.displayImageEditor = false;
        },

        openImageSuitability: function(){
            this.displayWhiteOut = true;
            this.displayImageSuitability = true;
        },

        closeImageSuitability: function(){
            this.displayWhiteOut = false;
            this.displayImageSuitability = false;
        },

        /**
         * Triggers the API to update the selected images to all have the same
         * properties as the user has entered into the form
         */
        saveChanges: function(){

console.log("I will save the following: ", this.selectedImages);

            axios.patch('api/images', { saveType: 'batch', images : this.selectedImages })
            .then(function(response) {
                if (response.data.success) {
                    alert("Changes have been saved");
                    this.deselectAll();
                    this.loadLibraryImages();
                    this.closeImageSelector();
                }
                else {
                    alert("An error occurred whilst saving your images");
                }
            }.bind(this));
        },

        inlineImageEdited(libraryImage){
            console.log("Inline image edited: ", libraryImage);
            libraryImage.editedInline = true;
        },

        saveInlineChanges: function(){
            var dataToSave = { images: [] };

            console.log("These are the library images which have been changed inline form:");
            this.libraryImages.forEach(function(libraryImage){
                // If the image has been flagged for saving then add it to the list
                if (libraryImage.editedInline){
                    console.log(libraryImage);
                    dataToSave.images.push(libraryImage);
                }
            });

            // If we have a list then pass it to the API for update
            if (dataToSave.images){
                axios.patch('api/images', { saveType: 'individual', images : dataToSave })
                .then(function(response) {
                    if (response.data.success) {
                        alert("Changes have been saved");
                        this.deselectAll();
                        this.loadLibraryImages();
                        this.closeImageSelector();
                    }
                    else {
                        alert("An error occurred whilst saving your images");
                    }
                }.bind(this));
            }
        },

        /**
         * Triggers the API to delete the selected images
         */
        deleteSelected: function(){
            var keys = this.getSelectedKeys();
            if(confirm('Are you sure you want to delete the selected images?')) {
                axios.delete('api/images', { params : keys })
                .then(function(response) {
                   if (response.data.success) {
                       alert("Images have been deleted");
                       this.deselectAll();
                       this.loadLibraryImages();
                   }
                   else {
                       alert("An error occurred whilst deleting your images");
                   }
               }.bind(this));
            }
        },

        getSelectedKeys: function(){
            var keys = [];
            this.selectedImages.images.forEach(function(image){
                keys.push(image.image_key);
            });
            return keys;
        },

        performSearch: function(){
            this.deselectAll();
            this.resetPageNumber();
            this.loadLibraryImages();
        },

        /**
         * Requests an array of Image objects from the API which match the
         * criteria specified by the user (if any) limited to a max number
         * of records
         */
        loadLibraryImages: function(){
            var self = this;

            // The tags work differently - theyre presented as a permanent list of available
            // options but we actually only want to submit an array of IDs so we need to add them
            // in now before we submit the search
            self.searchFilters.keywords = [];
            this.imageKeywords.forEach(function(keyword){
                if (keyword.isTicked){
                    self.searchFilters.keywords.push(keyword.keywordKey);
                }
            });

            this.getImageCount();
            this.searchFilters.offset = this.getOffset();
                axios.get('api/images', { params: this.searchFilters } )
                .then(function(response){
                    this.libraryImages = response.data;
                    console.log("library images set", this.libraryImages);
                }.bind(this))
                .then(function(){
                    // fail
                }.bind(this));
        },

        loadKeywords: function(){
            var imageKeywords = [];
            axios.get('api/keywords')
            .then(function(response){
                this.imageKeywords = [];
                this.selectedImages.imageKeywordOptions = [];
                var self = this;
           
                response.data.forEach(function(keyword){
                    var foundInAllImages = true;

                    self.selectedImages.images.forEach(function(selectedImage){
                        // Add new view model to the image which represent all of the keywords available
                        // If the image has any keywords tagged to it then update the relevant view model tick status
                        if (selectedImage.image_keywords && selectedImage.image_keywords.length){
                            var foundInThisImage = false;
                            selectedImage.image_keywords.forEach(function(imageKeyword){
                                if (imageKeyword.keyword_key == keyword.keyword_key){
                                    // Pretick the selected images keyword option because
                                    // we have now found at least one of the selected images owns it?
                                    foundInThisImage = true;
                                }
                            });

                            if (!foundInThisImage){
                                foundInAllImages = false;
                            }
                        }
                        // If the image has no tagged keywords at all then it is of course an instant fail
                        else {
                            foundInAllImages = false;
                        }
                    });
                   
                    // Populate the list of keywords the user can tag for this selection of images
                    self.selectedImages.imageKeywordOptions.push({keywordKey: keyword.keyword_key, label: keyword.name, isTicked: foundInAllImages});

                    // Populate the list of keywords the user can search for
                    self.imageKeywords.push({keywordKey: keyword.keyword_key, label: keyword.name, isTicked: false});
                });
            }.bind(this))
            .then(function(){
                // fail
            }.bind(this));
        },

        refreshLibraryImages: function() {
            this.deselectAll();
            this.loadLibraryImages();
        },

        /**
         * Removes search filters, deselects images and loads images again
         */
        clearAllFilters: function() {
            this.searchFilters.dateFrom = null;
            this.searchFilters.dateUntil = null;
            this.searchFilters.displayType = "";
            this.searchFilters.hotelCode = null;
            this.searchFilters.hotelKey = null;
            this.searchFilters.imageKey = null;
            this.searchFilters.imageType = null;
            this.searchFilters.minHeight = null;
            this.searchFilters.minWidth = null;
            this.searchFilters.keywords = [];
            this.deselectAll();
            this.loadLibraryImages();
        },

        getImageCount: function() {
            axios.get('api/images/images-count', { params: this.searchFilters } )
                .then(function(response){
                    this.totalItems = response.data;
                }.bind(this))
                .then(function(){
                    // fail
                }.bind(this));
                this.totalPages = this.getTotalPages();
        },

        /**
         * Requests all the different types of images recognised by the system, used for searching
         * the photo library
         */
        loadImageTypes: function(){
            var self = this;
            axios.get('api/images/types')
            .then(function(response){
                self.imageTypes = [ { text: 'Any', textShort: '-', value: '' } ];
                response.data.forEach(function(imageType){
                    self.imageTypes.push( { text: imageType.display_name, textShort: imageType.display_name_short, value: imageType.image_type_key });
                });
                console.log("image types: ", self.imageTypes);
            }.bind(this))
            .then(function(){
                // fail
            }.bind(this));
        },
        /**
         * Returns the number of pages for a list
         * @return - int
         */
        pages: function() {
            return Math.ceil(this.totalItems / this.chosenRowsPerPage) - 1
        },

        getOffset: function() {
            if (this.chosenRowsPerPage == 'All'){
                return 0;
            }
            return (this.pageNumber * this.chosenRowsPerPage);
        },

        resetPageNumber: function(){
            this.pageNumber = 0;
        },

        /**
         * Navigate to the first page
         */
        goToFirstPage: function(){
            this.resetPageNumber();
            this.deselectAll();
            this.loadLibraryImages();
        },

        /**
         * Navigate to the page before this one
         */
        goToPrevPage: function(){
            if (this.pageNumber >= 1) {
                this.pageNumber--;
            }
            this.deselectAll();
            this.loadLibraryImages();
        },

        /**
         * Navigate to the next page
         */
        goToNextPage: function(){
            if (this.pageNumber < this.pages()) {
                this.pageNumber++;
            }
            this.deselectAll();
            this.loadLibraryImages();
        },

        /**
         * Navigate to the last page
         */
        goToLastPage: function(){
            this.pageNumber = this.pages();
            this.deselectAll();
            this.loadLibraryImages();
        },

        /**
         * Recalculates the number of pages required to list every record using the current settings
         */
        getTotalPages: function(){
            if (this.chosenRowsPerPage == 'All'){
                    return 1;
            }
           return Math.ceil(this.totalItems / this.chosenRowsPerPage);
        },

        handleScroll: function(){
            var anchor = topOptionsBar;
            if (anchor){
                this.showSticky = window.scrollY > anchor.getBoundingClientRect().top + document.documentElement.scrollTop;
                console.log(window.scrollY > anchor.getBoundingClientRect().top + document.documentElement.scrollTop);
            }
        },

        setUpEventListeners: function() {
            var self = this;

            this.$root.$on('Image Browser: search', function(payload){
                console.log("!!! Have to search for ", payload);
                if (payload.filters.length){
                    payload.filters.forEach(function(filter){
                        if (filter.fieldName == 'hotelKey'){
                            self.searchFilters.hotelKey = filter.value;
                            console.log(self.searchFilters);
                        }
                    });
                }
                self.performSearch();
            });

            console.log("Will listen for ", 'Image Browser: search by hotel');
            this.$root.$on('Image Browser: search by hotel', function(payload){
                console.log("!!! ib: Have to search for ", payload);
                self.searchFilters.hotelKey = payload.hotelKey;
            });

            this.$root.$on('Image Browser: search by room type', function(payload){
                console.log("!!! ib: Have to search for ", payload);
                self.searchFilters.hotelRoomKey = payload.roomTypeKey;
            });

            this.$root.$on('Image Browser: select hotel', function(payload){
                console.log("ib: Have heard ", payload);
                self.selectedImages.hotelKey = payload.hotelKey;
            });

            this.$root.$on('Image Browser: select room type', function(payload){
                console.log("ib: Have heard ", payload);
                self.selectedImages.hotelRoomKey = payload.roomTypeKey;
            });

            window.addEventListener('scroll', this.handleScroll);
        },



        setFiltersFromURL: function(){
//          var urlParts = window.location.href.split('?');
//          var self = this;
            var searchParams = new URLSearchParams(window.location.search);

//          if (urlParts.length > 0){
//                console.log("Has query string portion", urlParts[1]);

                if(searchParams.get('hotelKey')){
                    this.searchFilters.hotelKey = parseInt(searchParams.get('hotelKey'));
                    
                }
 //         }
        }

    },
}
</script>
<style lang="scss" scoped>
    .sticky {
        position: fixed;
        top: 0;
        z-index: 9;
        margin-top: 0 !important;
    }

    .bold {
        font-weight: bold;
    }

    .clickable:hover {
        cursor: pointer;
    }

    .selected {
        border:solid 1px #C1CD23;
    }

    .modal-80 {
        display: block;
        position: fixed;
        width: 80vw;
        max-width: 80vw;
        height: 80vh;
        max-height: 80vh;
        overflow: auto;
        top: 10vh;
        left: 10vw;
        z-index: 9;
        background-color: white;
        border: solid 1px gray;
    }
    .hero-container{
        overflow: hidden;
        position: relative;
        min-height: 250px;
        /*max-height: calc(100vh - 190px);*/
        max-height: 0.3vh;
        /*position: relative;*/
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        height: 100%;
    }

    .hero-text{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        text-align: center;
        color: #FFF;
        text-transform: uppercase;
        font-family: 'raleway',sans-serif;
        text-shadow: 0 0 25px rgba(100,100,100,0.9);
        font-size: 2.4rem;
        font-weight: bolder;
        vertical-align: middle;
        display: table-cell;
        top: 45%;
    }

    .pts-thumbnail {
        height: 300px;
        width: 467px;
        overflow: hidden;
        position: relative;
    }

    .hotel-listing--thumb_notice {
        display: inline-block;
        position: absolute;
        top: 1rem;
        left: 0;
        text-transform: uppercase;
        background-color: #088193;
        padding: 0.5rem;
        font-weight: bold;
        color: white;
    }

    .pts-footer-container, .tile-double-container, .tile-single-container{
        height: 395px;
        width: 395px;
        overflow: hidden;
        position: relative;
    }

    .pts-footer-text, .tile-double-text, .tile-single-text {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        text-align: center;
        color: #FFF;
        text-transform: uppercase;
        font-family: 'raleway',sans-serif;
        font-size: 22px;
        font-weight: bolder;
        vertical-align: middle;
        display: table-cell;
        top: 45%;
    }

    .pts-footer-image, .tile-double-image, .tile-single-image, pts-thumbnail-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        vertical-align: middle;
    }

    .pts-thumbnail-image {
        height: 300px;
        object-fit: cover;
        max-width: 467px;
        width: 100%;
    }

    .pts-prev {
        position: absolute;
        left: 1px;
        height: 50px;
        width: 30px;
        top: 125px;
        z-index: 2;
        border: none;
        background: none;
    }

    .pts-next{
        position: absolute;
        right: 1px;
        height: 50px;
        width: 30px;
        top: 125px;
        z-index: 2;
        border: none;
        background: none;
    }

    .pts-footer-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .tile-double-container {
        width: 795px;
        display: inline-block;
    }

    .tile-single-container {
        display: inline-block;
    }

    .white-out {
        background-color: white;
        opacity: 0.6;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 8;
    }

    .close-button {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
    }

    .thumbnail {
        max-height: 150px;
        max-width: 154px;
        height: auto;
        width: auto;
    }

    .selection-checkbox {
        width: 20px;
        height: 20px;
    }

    .image-landscape {
        max-width: 100%;
        width: 100%;
    }

    textarea.image-description {
        max-height: 5rem;
    }

    .inline-sort-order {
        width: 2rem;
    }

    /* We want to use a standard select box for this as it must be small to fit the very restricted space */
    .inline-image-type {
        display: inline-block;
        width: 3rem;

        // Allow it to look like a select box, as the Vue framework will have overridden default appearance
        -moz-appearance: auto;
    }

    input {
        border: solid 1px lightgray;
        max-width: 100%;
    }
</style>
