// initial state
// shape: [{ menu }]
const state = {
    menu: [],
    currentRoute: '',
}

// getters
const getters = {
    /**
     * Return array with ready to display nav bar quick menu items with nested subitems
     * @param state
     * @returns []
     */
    menu: state => {
        return state.menu;
    },

    /**
     * Return current route name that users is on
     * @param state
     * @returns string
     */
    currentRoute: state => {
        return state.currentRoute;
    },
}

// actions
const actions = {

    /**
     * Get menu items from API
     * @param context
     */
    getMenu(context) {
        axios.get('/api/menu/list').then(function(response){
            // check if API returned data
            if (response.status === 200 && response.data.menu.length) {
                // commit menu
                context.commit('updateMenu', response.data.menu)

                // commit route
                if (response.data.hasOwnProperty('currentRoute')) {
                    context.commit('updateRoute', response.data.currentRoute)
                }
            }
        });
    },
}

// mutations
const mutations = {

    /**
     * update menu state
     * @param state
     * @param menu
     */
    updateMenu(state, menu) {
        state.menu = menu;
    },

    /**
     * update currentRoute state
     * @param state
     * @param menu
     */
    updateRoute(state, route) {
        state.currentRoute = route;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}