<template>
    <v-container fluid>
			<v-col cols="12">
	        	<!-- list must be list of ['text' => x, 'value' => y] -->
    	    	<v-select
        	   		v-model="viewValue"
	            	:label="field.schema.displayName"
    	       		:items="regions"
        	    	autocomplete
        		></v-select>

				<h5>{{country}}</h5>
		        <input type="hidden" :name="field.fieldName" :value="field.value">
    		    <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
        		    <li v-for="error in field.errors">
            		    {{ error }}
	            	</li>
    	    	</v-alert>
			</v-col>
    </v-container>

</template>

<script>
    /**
     * Allows the selection of an editorial region ID by choosing its name from
     * a selection downloaded from the API on creation
     */
    export default {
        name: 'ies-region-key',
        props: ['field'],
        data() {
            return {
                regions: [],
				country: '',
                viewValue: parseInt(this.field.value),
            }
        },
        created:function() {
            this.getRegions();
			this.setCountry();
        },

        methods: {
            getRegions:function(){
                axios.get('/api/selectable-regions')
                .then(function(response){
                    var regions = [];
                    response.data.regions.forEach(function(record){
                        regions.push({ text: record.region_name, value: record.region_key });
                    });
                    this.regions = regions;
                }.bind(this));
            },
			setCountry:function(){
				if (this.field.value) {
					console.log('Region key:', this.field.value);
					axios.get('/api/get-country-from-region/' + this.field.value).then((response) => {
						this.country = response.data.country.region_name;
					});
				} else {
					this.country = '';
				}
			},
        },
        watch: {
            viewValue: function (newValue) {
                this.field.value = newValue;
				this.setCountry();
            }
        }
    }
</script>
