<template>
    <v-container fluid>
		<v-row>
			<v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>

			<v-col cols="12">
				<v-alert type="info"  dismissable :value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
           		<v-alert type="warning"  dismissable :value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            	<v-alert type="error" dismissable :value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>
			</v-col>

			<v-col col="2">
				<label for="range_baby_end">Age Ranges: </label>
			</v-col>
			<v-col id="age-ranges" cols="10">
				<p style="margin-bottom: 2.4em">Enter the Age ranges for Babies, Children and Adults that determine the rates charged, use of cots and extra beds etc.<br>
					If Children are NOT accepted, enter Range 4 only.<br>
					If Children ARE accepted but there is no rate difference, enter Range 1 as 0-2 yrs and Range 2 as 2-12 yrs)</p>
				<v-row>
					<v-col cols="4">
						<p style="font-weight: bold">Range 1(optional): Babies</p>
					</v-col>
					<v-col cols="1">
						<v-select
							:items="[ 
								{ text: '', value: -1 }, 
								{ text:  0, value:  0 },
								{ text:  1, value:  1 },
								{ text:  2, value:  2 }, 
								{ text:  3, value:  3 },
								{ text:  4, value:  4 },
								{ text:  5, value:  5 }
							]"
							v-model="viewValues.range_baby_end"
						></v-select>
					</v-col>
					<v-col cols="4">
						<p> years and under in Baby Cot</p>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="4">
						<p style="font-weight: bold">Range 2(optional): Children</p>
					</v-col>
					<v-col cols="1">
						<v-select
							:items="[ 
								{ text: '', value: -1 }, 
								{ text:  0, value:  0 },
								{ text:  1, value:  1 },
								{ text:  2, value:  2 },
								{ text:  3, value:  3 },
								{ text:  4, value:  4 },
								{ text:  5, value:  5 },
								{ text:  6, value:  6 },
								{ text:  7, value:  7 },
								{ text:  8, value:  8 },
								{ text:  9, value:  9 },
								{ text: 10, value: 10 },
								{ text: 11, value: 11 },
								{ text: 12, value: 12 },
								{ text: 13, value: 13 },
								{ text: 14, value: 14 },
								{ text: 15, value: 15 },
								{ text: 16, value: 16 },
								{ text: 17, value: 17 },
								{ text: 18, value: 18 }
							]"
							v-model="viewValues.range_child_start"
						></v-select>
					</v-col>
					<v-col cols="2">
						<p>years to</p>
					</v-col>

					<v-col cols="1">
						<v-select
							:items="[ 
								{ text: '', value: -1 }, 
								{ text:  0, value:  0 },
								{ text:  1, value:  1 },
								{ text:  2, value:  2 },
								{ text:  3, value:  3 },
								{ text:  4, value:  4 },
								{ text:  5, value:  5 },
								{ text:  6, value:  6 },
								{ text:  7, value:  7 },
								{ text:  8, value:  8 },
								{ text:  9, value:  9 },
								{ text: 10, value: 10 },
								{ text: 11, value: 11 },
								{ text: 12, value: 12 },
								{ text: 13, value: 13 },
								{ text: 14, value: 14 },
								{ text: 15, value: 15 },
								{ text: 16, value: 16 },
								{ text: 17, value: 17 },
								{ text: 18, value: 18 }
							]"
							v-model="viewValues.range_child_end"
						></v-select>
					</v-col>

					<v-col cols="2">
						<p>years</p>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="4">
						<p style="font-weight: bold">Range 3(optional): Older Children</p>
					</v-col>
					<v-col cols="1">
						<v-select
							:items="[ 
								{ text: '', value: -1 }, 
								{ text:  0, value:  0 },
								{ text:  1, value:  1 },
								{ text:  2, value:  2 },
								{ text:  3, value:  3 },
								{ text:  4, value:  4 },
								{ text:  5, value:  5 },
								{ text:  6, value:  6 },
								{ text:  7, value:  7 },
								{ text:  8, value:  8 },
								{ text:  9, value:  9 },
								{ text: 10, value: 10 },
								{ text: 11, value: 11 },
								{ text: 12, value: 12 },
								{ text: 13, value: 13 },
								{ text: 14, value: 14 },
								{ text: 15, value: 15 },
								{ text: 16, value: 16 },
								{ text: 17, value: 17 },
								{ text: 18, value: 18 }
							]"
							v-model="viewValues.range_oldchild_start"
						></v-select>
					</v-col>
					<v-col cols="2">
						<p>years to</p>
					</v-col>

					<v-col cols="1">
						<v-select
							:items="[ 
								{ text: '', value: -1 }, 
								{ text:  0, value:  0 },
								{ text:  1, value:  1 },
								{ text:  2, value:  2 },
								{ text:  3, value:  3 },
								{ text:  4, value:  4 },
								{ text:  5, value:  5 },
								{ text:  6, value:  6 },
								{ text:  7, value:  7 },
								{ text:  8, value:  8 },
								{ text:  9, value:  9 },
								{ text: 10, value: 10 },
								{ text: 11, value: 11 },
								{ text: 12, value: 12 },
								{ text: 13, value: 13 },
								{ text: 14, value: 14 },
								{ text: 15, value: 15 },
								{ text: 16, value: 16 },
								{ text: 17, value: 17 },
								{ text: 18, value: 18 }
							]"
							v-model="viewValues.range_oldchild_end"
						></v-select>
					</v-col>

					<v-col cols="2">
						<p>years</p>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="4">
						<p style="font-weight: bold">Range 4: Adult rates are charged for guests</p>
					</v-col>
					<v-col cols="1">
						<v-select
							:items="[ 
								{ text: '', value: -1 }, 
								{ text:  0, value:  0 },
								{ text:  1, value:  1 },
								{ text:  2, value:  2 },
								{ text:  3, value:  3 },
								{ text:  4, value:  4 },
								{ text:  5, value:  5 },
								{ text:  6, value:  6 },
								{ text:  7, value:  7 },
								{ text:  8, value:  8 },
								{ text:  9, value:  9 },
								{ text: 10, value: 10 },
								{ text: 11, value: 11 },
								{ text: 12, value: 12 },
								{ text: 13, value: 13 },
								{ text: 14, value: 14 },
								{ text: 15, value: 15 },
								{ text: 16, value: 16 },
								{ text: 17, value: 17 },
								{ text: 18, value: 18 }
							]"
							v-model="viewValues.range_adult_start"
						></v-select>
					</v-col>
					<v-col cols="4">
						<p>+  years</p>
					</v-col>
				</v-row>
			</v-col>

    		<v-col v-if="this.field.schema.displayName != 'none'" cols="2">
        		<label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
    		</v-col>

			<input type="hidden" :name="this.field.fieldName" :value="field.value">

        	<v-alert type="error" dismissable :value="true" v-if="errorMessages && errorMessages.length">
               	<div v-for="(error, index) in errorMessages" :key="index" >{{ error.reason }}*</div>
            </v-alert>

		</v-row>
	</v-container>
</template>
<style>
#age-ranges .row {
	padding-bottom: 4px;
}
#age-ranges .col {
	padding-bottom: 0 !important;
}
#age-ranges p:not(:first-child) {
	margin-bottom: 0 !important;
}
#age-ranges .v-text-field {
     padding-top: 0;
     margin-top: 0;
}
#age-ranges .v-text-field__details {
	display: none !important;
}
#age-ranges .v-select__selection--comma {
	text-overflow: unset;
}
</style>

<script>
    /**
     * Allows the the setting of age range policies
	 *
     */
    export default {
        name: 'ies-input-age-ranges',
        props: ['field', 'recordData'],
        data() {
            return {
				showValidationGuide: false,
				errorMessages: [],
                viewValues: {
					'children_policy_key': parseInt(this.recordData.hotel_children_policy_key),
					'range_baby_end': null,
					'range_child_start': null,
					'range_child_end': null,
					'range_oldchild_start': null,
					'range_oldchild_end': null,
					'range_adult_start': null,
				},
            }
        },

		created: function() {
			this.addEventListeners();
			this.getChildPolicyData();
			this.field.value = this.viewValues;
		},

		methods: {
			addEventListeners: function() {
				var self = this;

           		this.$root.$on('Field: set errors', function(payload){
               		console.log("TEXT: I heard ", payload);
                	if (payload.modelName = self.field.modelName && payload.fieldName == self.field.fieldName){
                  		self.errorMessages = payload.errors;
                   		console.log("TEXT: I set ", self.field.errors);
               		}
            	});
			},
			
			getChildPolicyData: function() {
				axios.get("/api/child-policy-data/" + this.recordData.hotel_children_policy_key).then((response) => {
					let policy = response.data.policy;
					this.viewValues.range_baby_end = policy.range_baby_end;
					this.viewValues.range_child_start = policy.range_child_start;
					this.viewValues.range_child_end = policy.range_child_end;
					this.viewValues.range_oldchild_start = policy.range_oldchild_start;
					this.viewValues.range_oldchild_end = policy.range_oldchild_end;
					this.viewValues.range_adult_start = policy.range_adult_start;
				});
			},

		},

        watch: {
			'viewValues.range_baby_end': function(newValue) {
				this.field.value = this.viewValues;
			},
			'viewValues.range_child_start': function(newValue) {
				this.field.value = this.viewValues;
			},
			'viewValues.range_child_end': function(newValue) {
				this.field.value = this.viewValues;
			},
			'viewValues.range_oldchild_start': function(newValue) {
				this.field.value = this.viewValues;
			},
			'viewValues.range_oldchild_end': function(newValue) {
				this.field.value = this.viewValues;
			},
			'viewValues.range_adult_start': function(newValue) {
				this.field.value = this.viewValues;
			},
        }
    }
</script>
