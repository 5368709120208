var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          this.field.schema.displayName != "none"
            ? _c("v-col", { attrs: { cols: "2" } }, [
                _c("label", { attrs: { for: this.field.fieldName } }, [
                  _vm._v(" " + _vm._s(this.field.schema.displayName) + ": "),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          this.showValidationGuide &&
          this.field.validation &&
          this.field.validation.length
            ? _c(
                "v-alert",
                { attrs: { type: "info" } },
                _vm._l(this.field.validation, function (rule, ruleIndex) {
                  return _c("div", { key: ruleIndex }, [
                    _vm._v(_vm._s(_vm.validationRuleAsString(rule))),
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.shouldShowPicker,
                      expression: "!shouldShowPicker",
                    },
                  ],
                  on: { click: _vm.chooseCurrency },
                },
                [_vm._v(_vm._s(_vm.buttonLabel))]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selectedCurrency,
                      expression: "selectedCurrency",
                    },
                  ],
                  on: { click: _vm.clearCurrency },
                },
                [_vm._v("Clear")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.shouldShowPicker
            ? _c(
                "v-col",
                { attrs: { cols: "10" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c("v-col", { attrs: { cols: "2" } }, [
                            _vm._v("Currency: "),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "10" } },
                            [
                              _c("v-text-field", {
                                on: {
                                  keyup: function ($event) {
                                    return _vm.updateCurrencyName()
                                  },
                                },
                                model: {
                                  value: _vm.currencyViewName,
                                  callback: function ($$v) {
                                    _vm.currencyViewName = $$v
                                  },
                                  expression: "currencyViewName",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.currencyOptions.length
                            ? _c("v-col", { attrs: { cols: "12" } }, [
                                _c(
                                  "ul",
                                  _vm._l(
                                    _vm.currencyOptions,
                                    function (currency) {
                                      return _c(
                                        "li",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.selectCurrency(
                                                currency.currency_key
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "(" +
                                              _vm._s(currency.currency_code) +
                                              ") " +
                                              _vm._s(currency.currency_name)
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-btn", { on: { click: _vm.done } }, [
                                _vm._v("Done"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: _vm.field.fieldName },
            domProps: { value: _vm.field.value },
          }),
          _vm._v(" "),
          _vm.errorMessages && _vm.errorMessages.length
            ? _c(
                "v-alert",
                { attrs: { type: "error", dismissable: "", value: "true" } },
                _vm._l(_vm.errorMessages, function (error, index) {
                  return _c("div", { key: index }, [
                    _vm._v(_vm._s(error.reason) + "*"),
                  ])
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }