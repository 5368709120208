<template>
    <v-container fluid>
        <v-layout row wrap justify-center>
            <v-flex xs8 md10 lg9>
                <v-layout row wrap justify-space-between>
                    <v-flex xs12 md3 lg3>
                        <h3>iCal connections</h3>
                    </v-flex>
                    <v-flex xs12 md4 lg4 v-if="updateMessage.display" mt-2>
                        <span>
                            {{ updateMessage.text }}
                        </span>
                    </v-flex>
                    <v-flex xs12 md3 lg3>
                        <v-layout row justify-end>
                            <v-flex xs3 mr-2>
                                <div class="searchProgressIcon" v-if="updateBusy" style="float:right">
                                    <v-progress-circular
                                            :width="3"
                                            color="blue"
                                            indeterminate
                                    ></v-progress-circular>
                                </div>
                            </v-flex>
                            <v-flex xs6>
                                <v-btn
                                        @click="runFullUpdate"
                                        outline
                                        color="green"
                                        block
                                        :disabled="updateBusy"
                                >Run full update</v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex xs12 md10 lg9 mt3 mb3 v-if="connectionErrors.length" class="connectionErrors">
                <h4>Connections errors:</h4>
                <div v-for="(error,key) in connectionErrors" :key="key">
                    <v-alert
                            :value="true"
                            type="error"
                    >
                        <span>
                            {{ error.message }}
                        </span>
                        <div class="connectionErrorsDelete">
                            <v-icon
                                    color="white"
                                    @click="deleteErrorConnection(error.ical_connection, key)"
                            >
                                delete
                            </v-icon>
                        </div>
                    </v-alert>
                </div>
            </v-flex>

            <v-flex xs12 md10 lg9 mb-4>
                <v-card class="statsSection">
                    <div v-for="(val,name) in stats" :key="name">
                        <strong>{{ name }}:</strong> {{ val }}
                    </div>
                </v-card>
            </v-flex>


            <v-flex xs8 md10 lg9>
                <v-layout row wrap>
                    <v-expansion-panel expand>
                        <v-expansion-panel-content  v-for="(data, hotelCode) in connectionsByHotels" :key="hotelCode">
                            <div slot="header">
                                <strong>{{ hotelCode}} - {{ data.hotel.hotel_name}}</strong>
                                <span> - connected rooms: {{data.connections.length}}</span>
                            </div>
                            <v-card>
                                <v-card-text>
                                    <v-layout row wrap>
                                        <v-flex xs12 v-for="(connection, key) in data.connections" :key="connection.ical_connection_key">
                                            <i-cal-connection :connection="connection" :hotelcode="hotelCode" :sort="key + 1"></i-cal-connection>
                                        </v-flex>
                                    </v-layout>
                                </v-card-text>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-layout>
            </v-flex>
        </v-layout>

        <v-layout row wrap justify-center>
            <v-flex xs8 md10 lg9>
                <v-layout wrap justify-space-between>
                    <v-flex xs1 ml-0>
                        <v-btn
                                color="primary"
                                class="white--text"
                                @click="newConnectionDialog = true"
                                :disabled="updateBusy"
                        >
                            Add new connection
                            <v-icon right dark>add</v-icon>
                        </v-btn>
                    </v-flex>
                    <v-flex xs1 mr-0>
                        <v-btn
                                color="lime"
                                @click="save()"
                                :disabled="updateBusy"
                        >SAVE</v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>

        <!-- Dialog -->
        <v-dialog v-model="newConnectionDialog" max-width="700px">
            <v-card>
                <v-card-title>
                    <span class="headline">add new iCal connection</span>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-flex xs12>
                            <hotel-room-picker name="ical"></hotel-room-picker>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field
                                    label="iCal URL"
                                    v-model="newConnection.url"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field
                                    label="Staff notes"
                                    v-model="newConnection.notes"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs2>
                            <v-btn
                                    color="pink"
                                    class="white--text"
                                    @click="testConnection"
                                    :disabled="!newConnectionTestValid"
                            >
                                Test connection
                                <v-icon right dark>compare_arrows</v-icon>
                            </v-btn>

                            <div class="searchProgressIcon" v-show="showProgressIcon">
                                <v-progress-circular
                                        :width="3"
                                        color="blue"
                                        indeterminate
                                ></v-progress-circular>
                            </div>
                        </v-flex>

                        <v-flex xs12 v-if="newConnectionValid">
                            <span>
                                Connection test: <strong style="color: green">success</strong><br>
                                Connection can be added
                            </span>
                        </v-flex>

                        <v-flex xs12 v-if="newConnectionValid">
                            <v-btn
                                    color="primary"
                                    @click="addNewConnection"
                                    :disabled="apiBusy"
                            >Add</v-btn>
                        </v-flex>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" flat @click="clearNewConnectionDialog">Clear</v-btn>
                    <v-spacer></v-spacer>

                    <v-btn color="blue darken-1" flat @click="newConnectionDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Success and error snack bars -->
        <v-snackbar
                :timeout="parseInt(2000)"
                v-model="snackbar.successSnackbar"
                multi-line
                color="ies-green"
        >
            <div class="full-height full-width v-align-container text-xs-center">
                <h4 class="ies-dark-gray--text v-align-div">{{ snackbar.message }}</h4>
            </div>
        </v-snackbar>
        <v-snackbar
                :timeout="parseInt(3000)"
                v-model="snackbar.errorSnackbar"
                multi-line
                color="ies-coral"
        >
            <div class="full-height full-width v-align-container text-xs-center">
                <h4 class="ies-dark-gray--text v-align-div">{{ snackbar.message }}</h4>
            </div>
        </v-snackbar>
    </v-container>
</template>


<script>
    import EventBus from '@/eventBus';
    import axios from 'axios';
    import {progressBar} from '@/mixins/progressBar';
    import {loggedUser} from '@/mixins/loggedUser';
    import { format, differenceInSeconds } from 'date-fns';
    import ICalConnection from '@/components/bespoke/Channels/ICalConnection';
    import HotelRoomPicker from '@/components/common/HotelRoomPicker';

    export default {
        mixins: [progressBar, loggedUser],
        props: ['hotelconnections', 'stats'],
        components: {
            ICalConnection, HotelRoomPicker
        },
        data() {
            return {
                /** array all iCal connections grouped by hotel_code */
                connectionsByHotels: this.hotelconnections.valid_connections,
                /** array connection errors that we want to display at the top of the page */
                connectionErrors: this.hotelconnections.errors,
                /** array ical_connections_key's of connections that should be removed */
                connectionsToDelete: [],
                newConnectionDialog: false,
                /** snackbar object */
                snackbar: {
                    successSnackbar: false,
                    errorSnackbar: false,
                    message: '',
                },
                /** new connection object */
                newConnection: {
                    notes: '',
                    url: '',
                    hotelRoomName: '',
                    hotel_key: '',
                    hotel_room_key: '',
                    is_active: true,
                    ical_connection_key: false,
                },
                newConnectionValid: false,
                apiBusy: false,
                updateBusy: false,
                panel: [],
                updateMessage: {
                    text: '',
                    display: false,
                    updateStart: ''
                },
            }
        },
        methods: {

            /**
             * Calls i-escape API
             * @param {} filters
             */
            callApi(url, postData, callback) {
                this.progressIconShow();

                axios.post(url, postData)
                    .then(function(response) {
                        callback(response.data);

                        // show error message where success not returned
                        if (!response.data.success) {
                            this.showSnackbar('error', response.data.error);
                        }

                        this.progressIconHide();
                    }.bind(this))
                    .catch(function(error) {
                        this.showSnackbar('error', 'There was a problem with API call');
                        this.progressIconHide();
                    }.bind(this));
            },

            /**
             * Sends save request to back-end API to removed deleted connections and change active status
             */
            save() {
                let postData = {
                    toDelete: this.connectionsToDelete,
                    connections: this.connectionsByHotels,
                };

                this.callApi('/api/ical/connections-save', postData, this.saveCallback);
            },

            /**
             * Accepts data response from API after connections-save request
             */
            saveCallback(data) {
                if (data.success) {
                    this.snackbar.message = 'iCal connections updated';
                    this.snackbar.successSnackbar = true;
                }
            },

            /**
             * Sends new connection to our API to run the tests on that connection
             */
            testConnection() {
                let postData = {newConnection : this.newConnection};
                this.callApi('/api/ical/connection-test', postData, this.testConnectionCallback);
            },

            /**
             * Accepts data response from API after connection-test request
             */
            testConnectionCallback(data) {
                if (data.success) {
                    this.newConnectionValid = true;
                }
                else {
                    this.newConnectionValid = false;
                }
            },

            /**
             * Adds new iCal connection
             * at this point method assumes that connection is already valid and was tested by testConnection()
             *
             * disable button so user won't double send create ajax
             */
            addNewConnection() {
                if (this.newConnectionValid) {
                    this.apiBusy = true;
                    let postData = {newConnection : this.newConnection, loggedUserKey: this.getLoggedUserKey() };
                    this.callApi('/api/ical/connection-add', postData, this.addNewConnectionCallback);
                }
            },

            /**
             * Accepts data response from API after adding new connection
             *
             * We want to display success message and reload the page
             */
            addNewConnectionCallback(data) {
                if (data.success) {
                    this.snackbar.message = 'New iCal connection has been added';
                    this.snackbar.successSnackbar = true;

                    this.newConnectionDialog = false;

                    this.reloadPage();
                }
                else {
                    this.apiBusy = false;
                }
            },

            /**
             * clears new connection object so it can be used for new connection
             */
            clearNewConnection() {
                this.newConnection = {
                    notes: '',
                    url: '',
                    hotelRoomName: '',
                    hotel_key: '',
                    hotel_room_key: '',
                    is_active: true,
                    ical_connection_key: false,
                };
            },

            /**
             * Clears all selection on new connection dialog, emit event to let HotelRoomPicker to clear its state
             */
            clearNewConnectionDialog() {
                this.clearNewConnection();
                EventBus.$emit('HotelRoomPickerClear:ical');
            },

            /**
             * Ask user if he want's to remove this error connection and display confirm box
             * @param connection
             * @param key
             */
            deleteErrorConnection(connection, key) {
                let result = confirm("Do you really want to remove this connection? " +
                    "After accepting this message you will still need to save the changes in order to confirm this deletion");

                if (result) {
                    // add this connection to connectionsToDelete array so it can be removed from database by API call
                    this.deleteConnection(connection.ical_connection_key);

                    // delete this connection from page view
                    this.connectionErrors.splice(key, 1);
                }
            },

            /**
             * Remove given iCal connection
             * @param connectionKey
             * @param hotelCode
             */
            deleteConnection(connectionKey, hotelCode) {
                if (connectionKey) {
                    // add this connection to connectionsToRemove array that will be posted with save ajax
                    this.connectionsToDelete.push(connectionKey);

                    // remove this connection from page
                    if (hotelCode) {
                        let i = false;
                        this.connectionsByHotels[hotelCode].connections.forEach((connection, key) => {
                            if (connection.ical_connection_key === connectionKey) {
                                i = key;
                            }
                        });

                        if (i !== false) {
                            this.connectionsByHotels[hotelCode].connections.splice(i, 1);
                        }
                    }
                }
            },

            /**
             * Runs full iCal connections update
             */
            runFullUpdate() {
                this.resetUpdateData();

                // trigger update calling i-escape API
                let postData = {action : 'full-update'};
                this.callApi('/api/ical/update', postData, this.runUpdateCallback);

            },

            /**
             * Runs iCal connection update for a single connection
             * @param connectionKey -> ical_connection_key
             */
            runSingleUpdate(connectionKey) {
                this.resetUpdateData();

                let postData = {action: 'single-update', 'ical_connection_key': connectionKey};
                this.callApi('/api/ical/update', postData, this.runUpdateCallback);
            },

            /**
             * Accepts data response from API after update request
             */
            runUpdateCallback(data) {
                this.updateBusy = false;

                if (data.success) {
                    let message = data.message;
                    if (!message) {
                        message = 'iCal availability updated';
                    }

                    if (data.hasOwnProperty('single') && data.single) {
                        EventBus.$emit('update-single-done');
                    }

                    this.showUpdateMessage(message.trim());
                }
            },

            /**
             * Resets update data before running new update
             */
            resetUpdateData() {
                this.updateBusy = true;

                // clear previous message
                this.updateMessage.text = '';
                this.updateMessage.display = false;
                this.updateMessage.updateStart = new Date();
            },

            /**
             * Show update message that was received after successful iCal update
             *
             */
            showUpdateMessage(message) {
                this.showSnackbar('success', message);

                let updateStart = this.updateMessage.updateStart;
                let times = ' at ' + format(updateStart, 'YYYY-MM-DD HH:mm:ss');

                this.updateMessage.text = message + times;
                this.updateMessage.display = true;
            },

            /**
             * Show success or error snack bar
             * usage: this.showSnackbar('success'); | this.showSnackbar('error');
             * @param snack string - success|error
             * @param message = false
             */
            showSnackbar(snack, message = false) {
                var _this = this;
                this.progressIconHide();

                if (message) {
                    _this.snackbar.message = message;
                }

                switch (snack) {
                    case 'success' :
                        if (!message) _this.snackbar.message = 'Success';
                        _this.snackbar.successSnackbar = true;
                        break;
                    case 'error' :
                        if (!message) _this.snackbar.message = 'Error';
                        _this.snackbar.errorSnackbar = true;
                        break;
                }
            },

            /**
             * Reload page
             */
            reloadPage() {
                let url = window.location.href;

                setTimeout(function () {
                    window.location.assign(url);
                }, 1500);
            },

        },
        /**
         * Methods that initialized this page on page load
         * Set up even listeners
         */
        mounted() {
            // set up delete-connection even listener
            EventBus.$on('delete-connection', connection => {
                this.deleteConnection(connection.ical_connection_key, connection.hotel_code);
            });

            EventBus.$on('HotelRoomPickerUpdated:ical', hotelData => {
                if (hotelData && hotelData.hotel_key && hotelData.hotel_room_key) {

                    // update newConnection object with the data from HotelRoomPicker
                    this.newConnection.hotel_key = hotelData.hotel_key;
                    this.newConnection.hotel_room_key = hotelData.hotel_room_key;
                    this.newConnection.hotelRoomName = hotelData.room_name;
                }
            });

            EventBus.$on('update-single', connection => {
                this.runSingleUpdate(connection.ical_connection_key);
            });
        },
        computed: {
            /**
             * Live-validation if new connection data are provided and it's ready to run backend test against it
             * @returns {boolean}
             */
            newConnectionTestValid: function() {

                if (this.newConnection.url.length > 10 && this.newConnection.hotel_key > 0 && this.newConnection.hotel_room_key) {
                    return true;
                }

                return false;
            },
        },
        watch: {
        },
    };
</script>

<style scoped>
    .searchProgressIcon {
        display: inline-block;
        position: relative;
        top:10px;
    }

    .connectionErrors h4 {
        color:red;
        font-size: 16px;
    }

    .connectionErrors .connectionErrorsDelete {
        display: inline-block;
        width: 50px;
        float:right;
    }

    .connectionErrors i:hover {
        cursor: pointer;
    }

    .statsSection {
        padding: 10px 25px;
    }

    .statsSection div {
        padding: 2px 0;
    }

</style>
