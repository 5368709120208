var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "2", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("description")]),
              _vm._v(" Property Details \n            "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: { block: "", href: _vm.mainContactsUrl },
                            },
                            [_vm._v("Main Contacts")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: {
                                block: "",
                                href: _vm.reservationContactsUrl,
                              },
                            },
                            [_vm._v("Reservation Contacts")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: { block: "", href: _vm.telephonesUrl },
                            },
                            [_vm._v("Telephones")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: {
                                block: "",
                                href: _vm.alternativeNamesUrl,
                              },
                            },
                            [_vm._v("Alternative Names")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: { block: "", href: _vm.hotelUrlsUrl },
                            },
                            [_vm._v("Supported URLs")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: { block: "", href: _vm.backgroundInfoUrl },
                            },
                            [_vm._v("Background Info")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1",
                              attrs: { block: "", href: _vm.hotelLocationUrl },
                            },
                            [_vm._v("Location")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }