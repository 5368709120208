var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("PortalDesignerOptionsBar", {
        on: {
          saveDesign: function ($event) {
            return _vm.saveDesign()
          },
        },
      }),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                { attrs: { xs12: "" } },
                [
                  _vm.newdesign
                    ? _c(
                        "v-card",
                        { staticClass: "ma-3", attrs: { flat: "", xs12: "" } },
                        [
                          _c(
                            "fieldset",
                            [
                              _c("legend", [_vm._v("Select Page Mode")]),
                              _vm._v(" "),
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "pa-0",
                                  attrs: {
                                    mandatory: true,
                                    "hide-details": "",
                                  },
                                  model: {
                                    value: _vm.design.pageMode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.design, "pageMode", $$v)
                                    },
                                    expression: "design.pageMode",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    staticClass: "ies-green--text",
                                    attrs: { label: "List", value: "1" },
                                  }),
                                  _vm._v(" "),
                                  _c("v-radio", {
                                    staticClass: "ies-green--text",
                                    attrs: { label: "Edit", value: "2" },
                                  }),
                                  _vm._v(" "),
                                  _c("v-radio", {
                                    staticClass: "ies-green--text",
                                    attrs: { label: "Report", value: "3" },
                                  }),
                                  _vm._v(" "),
                                  _c("v-radio", {
                                    staticClass: "ies-green--text",
                                    attrs: { label: "Dashboard", value: "4" },
                                  }),
                                  _vm._v(" "),
                                  _c("v-radio", {
                                    staticClass: "ies-green--text",
                                    attrs: { label: "Data Import", value: "5" },
                                  }),
                                  _vm._v(" "),
                                  _c("v-radio", {
                                    staticClass: "ies-green--text",
                                    attrs: { label: "Preview", value: "99" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      attrs: { id: "editForm" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "ma-3", attrs: { flat: "", xs12: "" } },
                        [
                          _c(
                            "fieldset",
                            [
                              _c("legend", [_vm._v("Page")]),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { "mt-2": "", "mb-4": "" } },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "displayName" } },
                                    [_vm._v("Page Name")]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.design.pageName,
                                        expression: "design.pageName",
                                      },
                                    ],
                                    staticClass:
                                      "full-width text-input-border mt-2 pa-2",
                                    attrs: {
                                      id: "displayName",
                                      type: "text",
                                      name: "displayName",
                                    },
                                    domProps: { value: _vm.design.pageName },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.design,
                                          "pageName",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { "mt-2": "", "mb-4": "" } },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "urlPattern" } },
                                    [_vm._v("URL Pattern")]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.design.page_url_pattern,
                                        expression: "design.page_url_pattern",
                                      },
                                    ],
                                    staticClass:
                                      "full-width text-input-border mt-2 pa-2",
                                    attrs: {
                                      id: "urlPattern",
                                      type: "text",
                                      name: "urlPattern",
                                    },
                                    domProps: {
                                      value: _vm.design.page_url_pattern,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.design,
                                          "page_url_pattern",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card",
                        { staticClass: "ma-3", attrs: { flat: "", xs12: "" } },
                        [
                          _c(
                            "fieldset",
                            [
                              _c("legend", [_vm._v("Permissions")]),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "contentType" } }, [
                                _vm._v("Content Type"),
                              ]),
                              _vm._v(" "),
                              _c("v-select", {
                                attrs: {
                                  id: "contentType",
                                  items: _vm.contentTypeOptions,
                                  "item-text": "text",
                                  "item-value": "value",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.design.pageContentType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.design, "pageContentType", $$v)
                                  },
                                  expression: "design.pageContentType",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card",
                        { staticClass: "ma-3", attrs: { flat: "", xs12: "" } },
                        [
                          _c(
                            "fieldset",
                            [
                              _c("legend", [_vm._v("Related Pages")]),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { staticClass: "pb-3", attrs: { xs12: "" } },
                                [
                                  _c("label", [_vm._v("Bespoke Urls ")]),
                                  _vm._v(" "),
                                  _c("v-divider"),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { staticClass: "pb-3", attrs: { xs12: "" } },
                                [
                                  _c("label", { attrs: { for: "editUrl" } }, [
                                    _vm._v("Record Edit Url"),
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.design.editUrl,
                                        expression: "design.editUrl",
                                      },
                                    ],
                                    staticClass:
                                      "full-width text-input-border mt-2 pa-2",
                                    attrs: {
                                      id: "editUrl",
                                      type: "text",
                                      name: "editUrl",
                                    },
                                    domProps: { value: _vm.design.editUrl },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.design,
                                          "editUrl",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { staticClass: "pb-3", attrs: { xs12: "" } },
                                [
                                  _c("label", { attrs: { for: "createUrl" } }, [
                                    _vm._v("Record Create Url"),
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.design.createUrl,
                                        expression: "design.createUrl",
                                      },
                                    ],
                                    staticClass:
                                      "full-width text-input-border mt-2 pa-2",
                                    attrs: {
                                      id: "createUrl",
                                      type: "text",
                                      name: "createUrl",
                                    },
                                    domProps: { value: _vm.design.createUrl },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.design,
                                          "createUrl",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { staticClass: "pb-3", attrs: { xs12: "" } },
                                [
                                  _c("label", { attrs: { for: "deleteUrl" } }, [
                                    _vm._v("Record Delete Url"),
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.design.deleteUrl,
                                        expression: "design.deleteUrl",
                                      },
                                    ],
                                    staticClass:
                                      "full-width text-input-border mt-2 pa-2",
                                    attrs: {
                                      id: "deleteUrl",
                                      type: "text",
                                      name: "deleteUrl",
                                    },
                                    domProps: { value: _vm.design.deleteUrl },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.design,
                                          "deleteUrl",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { staticClass: "pb-3", attrs: { xs12: "" } },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "parentPage" } },
                                    [_vm._v("Parent Page ID (breadcrumb)")]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.design.parentPage,
                                        expression: "design.parentPage",
                                      },
                                    ],
                                    staticClass:
                                      "full-width text-input-border mt-2 pa-2",
                                    attrs: {
                                      id: "parentPage",
                                      type: "text",
                                      name: "parentPage",
                                    },
                                    domProps: { value: _vm.design.parentPage },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.design,
                                          "parentPage",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card",
                        { staticClass: "ma-3", attrs: { flat: "", xs12: "" } },
                        [
                          _c(
                            "fieldset",
                            [
                              _c("legend", [_vm._v("Introductory Content")]),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "heading" } }, [
                                _vm._v("Page Heading"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { "mt-2": "", "mb-4": "" } },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.design.heading,
                                        expression: "design.heading",
                                      },
                                    ],
                                    staticClass:
                                      "full-width text-input-border mt-2 pa-2",
                                    attrs: {
                                      id: "heading",
                                      type: "text",
                                      name: "heading",
                                    },
                                    domProps: { value: _vm.design.heading },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.design,
                                          "heading",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "introduction" } }, [
                                _vm._v("Introduction"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { "mt-2": "", "mb-4": "" } },
                                [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.design.introduction,
                                        expression: "design.introduction",
                                      },
                                    ],
                                    attrs: {
                                      id: "introduction",
                                      name: "introduction",
                                    },
                                    domProps: {
                                      value: _vm.design.introduction,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.design,
                                          "introduction",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "information" } }, [
                                _vm._v("Information"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { "mt-2": "", "mb-4": "" } },
                                [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.design.information,
                                        expression: "design.information",
                                      },
                                    ],
                                    attrs: {
                                      id: "information",
                                      name: "information",
                                    },
                                    domProps: { value: _vm.design.information },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.design,
                                          "information",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _c("label", { attrs: { for: "warning" } }, [
                                _vm._v("Warning"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { "mt-2": "", "mb-4": "" } },
                                [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.design.warning,
                                        expression: "design.warning",
                                      },
                                    ],
                                    attrs: { id: "warning", name: "warning" },
                                    domProps: { value: _vm.design.warning },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.design,
                                          "warning",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      this.design.pageMode == 1
                        ? _c(
                            "v-card",
                            {
                              staticClass: "ma-3 pb-3",
                              attrs: { flat: "", xs12: "" },
                            },
                            [
                              _c(
                                "fieldset",
                                [
                                  _c("legend", [_vm._v("Layout")]),
                                  _vm._v(" "),
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "pb-3",
                                          attrs: { xs12: "", sm6: "" },
                                        },
                                        [
                                          _c("label", [
                                            _vm._v("Show Action Buttons"),
                                          ]),
                                          _vm._v(" "),
                                          _c("v-checkbox", {
                                            attrs: { "hide-details": "" },
                                            model: {
                                              value:
                                                _vm.design.showActionButtons,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.design,
                                                  "showActionButtons",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "design.showActionButtons",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "When hidden the page will only list the records; the user will have no buttons to click through to other pages"
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "pb-3",
                                          attrs: { xs12: "", sm6: "" },
                                        },
                                        [
                                          _c("label", [_vm._v("Page Size")]),
                                          _vm._v(" "),
                                          _c("v-select", {
                                            staticClass: "full-width",
                                            attrs: {
                                              "hide-details": "",
                                              items: _vm.pageSizes,
                                              "item-text": "text",
                                              "item-value": "value",
                                            },
                                            model: {
                                              value: _vm.design.pageSize,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.design,
                                                  "pageSize",
                                                  $$v
                                                )
                                              },
                                              expression: "design.pageSize",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      this.design.pageMode == 2
                        ? _c(
                            "v-card",
                            {
                              staticClass: "ma-3 pb-3",
                              attrs: { flat: "", xs12: "" },
                            },
                            [
                              _c(
                                "fieldset",
                                [
                                  _c("legend", [_vm._v("Layout")]),
                                  _vm._v(" "),
                                  _c(
                                    "v-layout",
                                    { attrs: { column: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "pb-3",
                                          attrs: { xs12: "" },
                                        },
                                        [
                                          _c("label", [
                                            _vm._v("Header Column"),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                type: "info",
                                                value: "true",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "This field can be used for naming pages and titles, and is a more helpful way to remember records than ID numbers.  Choose a field if there is a unique name or reference for each record"
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.headerColumnOptions,
                                              "item-text": "text",
                                              "item-value": "value",
                                            },
                                            model: {
                                              value:
                                                _vm.design.pageHeaderColumn,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.design,
                                                  "pageHeaderColumn",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "design.pageHeaderColumn",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          this.design.pageMode == 1 || this.design.pageMode == 3
            ? _c(
                "v-flex",
                { staticClass: "mb-3", attrs: { xs: "", 12: "" } },
                [
                  _c(
                    "v-expansion-panels",
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c("label", [
                              _vm._v(
                                "Predefined Sorting " +
                                  _vm._s(
                                    _vm.sortOrdering.length > 0
                                      ? "(" + _vm.sortOrdering.length + ")"
                                      : ""
                                  )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            { staticClass: "ies-light-gray" },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "header" }, slot: "header" },
                                [
                                  _c("label", [
                                    _vm._v(
                                      "Predefined Sorting " +
                                        _vm._s(
                                          _vm.sortOrdering.length > 0
                                            ? "(" +
                                                _vm.sortOrdering.length +
                                                ")"
                                            : ""
                                        )
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card",
                                { staticClass: "pa-3" },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { column: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "pb-3",
                                          attrs: { xs12: "" },
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { column: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c("label", [
                                                    _vm._v("Sort order"),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                {
                                                  staticClass: "mt-3",
                                                  attrs: { xs12: "" },
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs12: "" } },
                                                    [
                                                      _vm._v(
                                                        "The records will be listed in the following order when the page is loaded.  Users will be able to re-order the results for themselves afterwards"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.sortOrdering,
                                                function (sortField, index) {
                                                  return _c(
                                                    "v-flex",
                                                    {
                                                      key: index,
                                                      attrs: { xs12: "" },
                                                    },
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        {
                                                          attrs: {
                                                            row: "",
                                                            wrap: "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  items:
                                                                    _vm.modelFields,
                                                                  label:
                                                                    "The model-field",
                                                                  "item-text":
                                                                    "optionText",
                                                                  "item-value":
                                                                    "optionValue",
                                                                  "single-line":
                                                                    "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    sortField.modelField,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        sortField,
                                                                        "modelField",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "sortField.modelField",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-flex",
                                                            [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  items:
                                                                    _vm.sortOrderOptions,
                                                                  label:
                                                                    "The direction",
                                                                  "item-value":
                                                                    "value",
                                                                  "single-line":
                                                                    "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    sortField.order,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        sortField,
                                                                        "order",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "sortField.order",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "mt-2",
                                                              attrs: {
                                                                xs12: "",
                                                                sm1: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeSorting(
                                                                          index
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "delete"
                                                                    ),
                                                                  ]),
                                                                  _vm._v(
                                                                    " Remove Sort Condition"
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-layout",
                                            { attrs: { row: "", wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs2: "" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "ies-green",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addSorting()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("add"),
                                                      ]),
                                                      _vm._v(
                                                        " Add Sort Condition"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs2: "" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "ies-coral",
                                                      attrs: {
                                                        disabled:
                                                          _vm.sortOrdering &&
                                                          _vm.sortOrdering
                                                            .length == 0,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeAllSorting()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("cancel"),
                                                      ]),
                                                      _vm._v(
                                                        " Remove All Conditions"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          this.design.pageMode == 1 || this.design.pageMode == 3
            ? _c(
                "v-flex",
                { staticClass: "mb-3", attrs: { xs: "", 12: "" } },
                [
                  _c(
                    "v-expansion-panels",
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c("label", [
                              _vm._v(
                                "Predefined Filters " +
                                  _vm._s(
                                    _vm.filters &&
                                      _vm.filters.fields &&
                                      _vm.filters.fields.length > 0
                                      ? "(" + _vm.filters.fields.length + ")"
                                      : ""
                                  )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            { staticClass: "ies-light-gray" },
                            [
                              _c(
                                "v-card",
                                { staticClass: "pa-3" },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { column: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "pb-3 mt-3",
                                          attrs: { xs12: "" },
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { column: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      "Predefined Filters"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                {
                                                  staticClass: "mt-3",
                                                  attrs: { xs12: "" },
                                                },
                                                [
                                                  _c("v-flex", [
                                                    _vm._v(
                                                      "Show me all records where "
                                                    ),
                                                    _c("strong", [
                                                      _vm._v("all"),
                                                    ]),
                                                    _vm._v(
                                                      " of these conditions are true:"
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.filters.fields,
                                                function (filter, index) {
                                                  return _vm.filters &&
                                                    _vm.filters.fields
                                                    ? _c(
                                                        "v-flex",
                                                        {
                                                          key: index,
                                                          attrs: { xs12: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                row: "",
                                                                wrap: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    xs12: "",
                                                                    sm4: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-select",
                                                                    {
                                                                      attrs: {
                                                                        items:
                                                                          _vm.modelFields,
                                                                        label:
                                                                          "The model-field",
                                                                        "item-text":
                                                                          "optionText",
                                                                        "item-value":
                                                                          "optionValue",
                                                                        "single-line":
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          filter.modelField,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              filter,
                                                                              "modelField",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "filter.modelField",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    xs12: "",
                                                                    sm2: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-select",
                                                                    {
                                                                      attrs: {
                                                                        items:
                                                                          _vm.operators,
                                                                        label:
                                                                          "Operator",
                                                                        "item-value":
                                                                          "value",
                                                                        "single-line":
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          filter.operator,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              filter,
                                                                              "operator",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "filter.operator",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    xs12: "",
                                                                    sm2: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            filter.value,
                                                                          expression:
                                                                            "filter.value",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "text-input-border mt-2 pa-2",
                                                                    attrs: {
                                                                      type: "text",
                                                                      placeholder:
                                                                        "Value",
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        filter.value,
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            $event
                                                                              .target
                                                                              .composing
                                                                          )
                                                                            return
                                                                          _vm.$set(
                                                                            filter,
                                                                            "value",
                                                                            $event
                                                                              .target
                                                                              .value
                                                                          )
                                                                        },
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    xs12: "",
                                                                    sm1: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.removeFilter(
                                                                              index
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "delete"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " Remove Filter"
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ies-green",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addFilter()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [_vm._v("add")]),
                                                  _vm._v(" Add Filter"),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ies-coral",
                                                  attrs: {
                                                    disabled:
                                                      _vm.filters.fields
                                                        .length == 0,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeAllFilters()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("cancel"),
                                                  ]),
                                                  _vm._v(" Remove All Filters"),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-flex",
            { staticClass: "mb-3", attrs: { xs: "", 12: "" } },
            [
              _c(
                "v-expansion-panels",
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _c("label", [
                          _vm._v(
                            "Relationships " +
                              _vm._s(
                                _vm.relationships.length > 0
                                  ? "(" + _vm.relationships.length + ")"
                                  : ""
                              )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel-content",
                        { staticClass: "ies-light-gray" },
                        [
                          _c(
                            "v-card",
                            { staticClass: "pa-3" },
                            [
                              _c(
                                "v-layout",
                                { attrs: { column: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "mb-3",
                                      attrs: { xs12: "" },
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          attrs: { for: "primaryModelSelect" },
                                        },
                                        [_vm._v("Select the primary model")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "mb-3": "" } },
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.design.primaryModel,
                                              expression: "design.primaryModel",
                                            },
                                          ],
                                          attrs: {
                                            id: "primaryModelSelect",
                                            name: "primaryModelSelect",
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.design,
                                                "primaryModel",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "" } },
                                            [_vm._v("Select Primary Key Table")]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.primaryModelOptions,
                                            function (option) {
                                              return _c(
                                                "option",
                                                { domProps: { value: option } },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(option) + " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _vm._v(
                                      "Show records where the following relationships exist between the relevant models:"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.relationships,
                                    function (relation, index) {
                                      return _c(
                                        "v-flex",
                                        { key: index, attrs: { xs12: "" } },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { row: "", wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { xs12: "", sm4: "" },
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items:
                                                        _vm.modelFieldNames,
                                                      label: "Model-field A",
                                                      "item-value": "value",
                                                      "single-line": "",
                                                      autocomplete: "",
                                                    },
                                                    model: {
                                                      value:
                                                        relation.modelFieldA,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          relation,
                                                          "modelFieldA",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "relation.modelFieldA",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { xs12: "", sm2: "" },
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.joinTypes,
                                                      label: "Join Type",
                                                      "item-value": "value",
                                                      "single-line": "",
                                                    },
                                                    model: {
                                                      value: relation.joinType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          relation,
                                                          "joinType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "relation.joinType",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { xs12: "", sm4: "" },
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items:
                                                        _vm.modelFieldNames,
                                                      label: "Model-field B",
                                                      "item-value": "value",
                                                      "single-line": "",
                                                      autocomplete: "",
                                                    },
                                                    model: {
                                                      value:
                                                        relation.modelFieldB,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          relation,
                                                          "modelFieldB",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "relation.modelFieldB",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                {
                                                  staticClass: "mt-2",
                                                  attrs: { xs12: "", sm1: "" },
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeRelationship(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("delete"),
                                                      ]),
                                                      _vm._v(" Remove"),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ies-green",
                                          on: {
                                            click: function ($event) {
                                              return _vm.addRelationship()
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [_vm._v("add")]),
                                          _vm._v(" Add Relationship"),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ies-coral",
                                          attrs: {
                                            disabled:
                                              _vm.relationships.length == 0,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeAllRelationships()
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [_vm._v("cancel")]),
                                          _vm._v(" Remove All Relationships"),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          this.design.pageMode == 1 || this.design.pageMode == 3
            ? _c(
                "v-flex",
                { staticClass: "mb-3", attrs: { xs12: "" } },
                [
                  _c(
                    "v-expansion-panels",
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c("label", [
                              _vm._v(
                                "Grouping " +
                                  _vm._s(
                                    _vm.grouping.length > 0
                                      ? "(" + _vm.grouping.length + ")"
                                      : ""
                                  )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            { staticClass: "ies-light-gray" },
                            [
                              _c(
                                "v-card",
                                { staticClass: "pa-3" },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { column: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", "mb-3": "" } },
                                        [
                                          _vm._v(
                                            "Compress the results so that there is one row returned for every unique combination of the following fields, in this order:"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.grouping,
                                        function (groupField, index) {
                                          return _c(
                                            "v-flex",
                                            { key: index, attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: { row: "", wrap: "" },
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        xs12: "",
                                                        sm4: "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items:
                                                            _vm.modelFieldNames,
                                                          label:
                                                            "Model-field A",
                                                          "item-value": "value",
                                                          "single-line": "",
                                                          autocomplete: "",
                                                        },
                                                        model: {
                                                          value:
                                                            groupField.modelField,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              groupField,
                                                              "modelField",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "groupField.modelField",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass: "mt-2",
                                                      attrs: {
                                                        xs12: "",
                                                        sm1: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeGrouping(
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("delete"),
                                                          ]),
                                                          _vm._v(" Remove"),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ies-green",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addGrouping()
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [_vm._v("add")]),
                                              _vm._v(" Add Grouping Field"),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ies-coral",
                                              attrs: {
                                                disabled:
                                                  _vm.grouping.length == 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeAllGrouping()
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [_vm._v("cancel")]),
                                              _vm._v(" Remove Grouping"),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "col-xs-12 mt-3 mb-3" },
                            [
                              _c(
                                "v-card",
                                { staticClass: "ies-green p-5" },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "ies-dark-gray--text title ma-3",
                                          attrs: { sm: "4" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(this.getPageMode()) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _vm.design.sections.length == 0 ||
                                      _vm.design.pageMode != 3
                                        ? _c(
                                            "v-col",
                                            { attrs: { sm: "2" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.addNewSection()
                                                    },
                                                  },
                                                },
                                                [_vm._v("Add Section")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          staticClass: "flex-column",
                          attrs: { "no-gutters": "" },
                        },
                        _vm._l(
                          _vm.design.sections,
                          function (section, sectionKey) {
                            return _vm.design.pageMode == 1 ||
                              _vm.design.pageMode == 2 ||
                              _vm.design.pageMode == 3 ||
                              _vm.design.pageMode == 4
                              ? _c(
                                  "v-col",
                                  { key: sectionKey, staticClass: "col-xs-12" },
                                  [
                                    _c(
                                      "v-expansion-panels",
                                      [
                                        _c(
                                          "v-expansion-panel",
                                          [
                                            _c(
                                              "v-expansion-panel-header",
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { sm: "6" } },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "wrap full-height",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "v-align-container full-height",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    staticClass:
                                                                      "v-align-div",
                                                                    attrs: {
                                                                      sm: "2",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        class: {
                                                                          "white--text":
                                                                            sectionKey %
                                                                              2 !=
                                                                            0,
                                                                          "grey--text text--darken-4":
                                                                            sectionKey %
                                                                              2 ==
                                                                            0,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Section Name"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    staticClass:
                                                                      "v-align-div pl-3",
                                                                    attrs: {
                                                                      sm: "3",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                section.sectionName,
                                                                              expression:
                                                                                "section.sectionName",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "pa-2",
                                                                        class: {
                                                                          "text-input-border-dark":
                                                                            sectionKey %
                                                                              2 ===
                                                                            0,
                                                                          "text-input-border":
                                                                            sectionKey %
                                                                              2 !==
                                                                            0,
                                                                        },
                                                                        attrs: {
                                                                          type: "text",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              section.sectionName,
                                                                          },
                                                                        on: {
                                                                          input:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                $event
                                                                                  .target
                                                                                  .composing
                                                                              )
                                                                                return
                                                                              _vm.$set(
                                                                                section,
                                                                                "sectionName",
                                                                                $event
                                                                                  .target
                                                                                  .value
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    staticClass:
                                                                      "v-align-div",
                                                                    attrs: {
                                                                      sm: "2",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        class: {
                                                                          "white--text":
                                                                            sectionKey %
                                                                              2 !=
                                                                            0,
                                                                          "grey--text text--darken-4":
                                                                            sectionKey %
                                                                              2 ==
                                                                            0,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Section Width"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    staticClass:
                                                                      "v-align-div pl-3",
                                                                    attrs: {
                                                                      sm: "1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                section.sectionWidth,
                                                                              expression:
                                                                                "section.sectionWidth",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "pa-2",
                                                                        class: {
                                                                          "text-input-border-dark":
                                                                            sectionKey %
                                                                              2 ===
                                                                            0,
                                                                          "text-input-border":
                                                                            sectionKey %
                                                                              2 !==
                                                                            0,
                                                                        },
                                                                        attrs: {
                                                                          type: "number",
                                                                          min: "1",
                                                                          max: "12",
                                                                          placeholder:
                                                                            "12",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            value:
                                                                              section.sectionWidth,
                                                                          },
                                                                        on: {
                                                                          input:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              if (
                                                                                $event
                                                                                  .target
                                                                                  .composing
                                                                              )
                                                                                return
                                                                              _vm.$set(
                                                                                section,
                                                                                "sectionWidth",
                                                                                $event
                                                                                  .target
                                                                                  .value
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { sm: "6" } },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "wrap justify-end",
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "ml-1 mr-1",
                                                                attrs: {
                                                                  sm: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "full-width",
                                                                    attrs: {
                                                                      block: "",
                                                                    },
                                                                    nativeOn: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.moveSection(
                                                                            "up",
                                                                            sectionKey
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Move up"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "ml-1 mr-1",
                                                                attrs: {
                                                                  sm: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "full-width ies-coral",
                                                                    attrs: {
                                                                      block: "",
                                                                    },
                                                                    nativeOn: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.deleteSection(
                                                                            sectionKey
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Delete"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "ml-1 mr-1",
                                                                attrs: {
                                                                  sm: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "full-width",
                                                                    attrs: {
                                                                      block: "",
                                                                    },
                                                                    nativeOn: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.moveSection(
                                                                            "down",
                                                                            sectionKey
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Mode Down"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-expansion-panel-content",
                                              {
                                                class: {
                                                  "ies-dark-gray":
                                                    sectionKey % 2 != 0,
                                                  "ies-light-gray":
                                                    sectionKey % 2 == 0,
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-card",
                                                  {
                                                    staticClass:
                                                      "pt-3 pr-3 pl-3",
                                                  },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { sm: "12" },
                                                          },
                                                          [
                                                            _c(
                                                              "v-expansion-panels",
                                                              [
                                                                _c(
                                                                  "v-expansion-panel",
                                                                  [
                                                                    _c(
                                                                      "v-expansion-panel-header",
                                                                      [
                                                                        _c(
                                                                          "v-row",
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "v-align-container full-height",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "v-align-div",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "label",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Content"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-expansion-panel-content",
                                                                      {
                                                                        staticClass:
                                                                          "ies-light-gray",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-card",
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              {
                                                                                staticClass:
                                                                                  "px-3 no-gutters",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        for: "section-introduction",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Introduction"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mt-2 mb-4",
                                                                                    attrs:
                                                                                      {
                                                                                        sm: "12",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "input",
                                                                                      {
                                                                                        directives:
                                                                                          [
                                                                                            {
                                                                                              name: "model",
                                                                                              rawName:
                                                                                                "v-model",
                                                                                              value:
                                                                                                section.introduction,
                                                                                              expression:
                                                                                                "section.introduction",
                                                                                            },
                                                                                          ],
                                                                                        staticClass:
                                                                                          "full-width pa-2",
                                                                                        class:
                                                                                          {
                                                                                            "text-input-border-dark":
                                                                                              sectionKey %
                                                                                                2 ===
                                                                                              0,
                                                                                            "text-input-border":
                                                                                              sectionKey %
                                                                                                2 !==
                                                                                              0,
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            type: "text",
                                                                                          },
                                                                                        domProps:
                                                                                          {
                                                                                            value:
                                                                                              section.introduction,
                                                                                          },
                                                                                        on: {
                                                                                          input:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              if (
                                                                                                $event
                                                                                                  .target
                                                                                                  .composing
                                                                                              )
                                                                                                return
                                                                                              _vm.$set(
                                                                                                section,
                                                                                                "introduction",
                                                                                                $event
                                                                                                  .target
                                                                                                  .value
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        for: "section-introduction",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Outroduction"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mt-2 mb-4",
                                                                                    attrs:
                                                                                      {
                                                                                        sm: "12",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "input",
                                                                                      {
                                                                                        directives:
                                                                                          [
                                                                                            {
                                                                                              name: "model",
                                                                                              rawName:
                                                                                                "v-model",
                                                                                              value:
                                                                                                section.outroduction,
                                                                                              expression:
                                                                                                "section.outroduction",
                                                                                            },
                                                                                          ],
                                                                                        staticClass:
                                                                                          "full-width pa-2",
                                                                                        class:
                                                                                          {
                                                                                            "text-input-border-dark":
                                                                                              sectionKey %
                                                                                                2 ===
                                                                                              0,
                                                                                            "text-input-border":
                                                                                              sectionKey %
                                                                                                2 !==
                                                                                              0,
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            type: "text",
                                                                                          },
                                                                                        domProps:
                                                                                          {
                                                                                            value:
                                                                                              section.outroduction,
                                                                                          },
                                                                                        on: {
                                                                                          input:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              if (
                                                                                                $event
                                                                                                  .target
                                                                                                  .composing
                                                                                              )
                                                                                                return
                                                                                              _vm.$set(
                                                                                                section,
                                                                                                "outroduction",
                                                                                                $event
                                                                                                  .target
                                                                                                  .value
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { sm: "12" },
                                                          },
                                                          [
                                                            _c(
                                                              "v-expansion-panels",
                                                              [
                                                                _c(
                                                                  "v-expansion-panel",
                                                                  [
                                                                    _c(
                                                                      "v-expansion-panel-header",
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          [
                                                                            _vm._v(
                                                                              "Permissions"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-expansion-panel-content",
                                                                      {
                                                                        staticClass:
                                                                          "ies-light-gray",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-card",
                                                                          [
                                                                            _c(
                                                                              "PortalDesignerPermissions",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    object:
                                                                                      section,
                                                                                    permissions:
                                                                                      _vm.contentTypeOptions,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { sm: "12" },
                                                          },
                                                          [
                                                            _c(
                                                              "v-expansion-panels",
                                                              [
                                                                _c(
                                                                  "v-expansion-panel",
                                                                  [
                                                                    _c(
                                                                      "v-expansion-panel-header",
                                                                      [
                                                                        _c(
                                                                          "v-row",
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "v-align-container full-height",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "v-align-div",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "label",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Rules"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "v-spacer"
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                staticClass:
                                                                                  "mx-1 pa-1",
                                                                                attrs:
                                                                                  {
                                                                                    sm: "2",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    staticClass:
                                                                                      "full-width",
                                                                                    attrs:
                                                                                      {
                                                                                        block:
                                                                                          "",
                                                                                      },
                                                                                    nativeOn:
                                                                                      {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            return _vm.addNewRule(
                                                                                              sectionKey
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Add new Rule"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-expansion-panel-content",
                                                                      {
                                                                        staticClass:
                                                                          "ies-light-gray",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-card",
                                                                          [
                                                                            _c(
                                                                              "PortalDesignerRules",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    section:
                                                                                      section,
                                                                                    sectionkey:
                                                                                      sectionKey,
                                                                                    models:
                                                                                      _vm.models,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { sm: "12" },
                                                          },
                                                          [
                                                            _c(
                                                              "v-expansion-panels",
                                                              [
                                                                _c(
                                                                  "v-expansion-panel",
                                                                  [
                                                                    _c(
                                                                      "v-expansion-panel-header",
                                                                      [
                                                                        _c(
                                                                          "v-row",
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "v-align-container full-height",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "v-align-div",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "label",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Fields"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-expansion-panel-content",
                                                                      {
                                                                        staticClass:
                                                                          "ies-light-gray",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-card",
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    staticClass:
                                                                                      "ies-green",
                                                                                    nativeOn:
                                                                                      {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            return _vm.addNewField(
                                                                                              sectionKey,
                                                                                              "modelField"
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Add new field "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    nativeOn:
                                                                                      {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            return _vm.addNewField(
                                                                                              sectionKey,
                                                                                              "customField"
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Add Custom field "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    nativeOn:
                                                                                      {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            return _vm.addNewField(
                                                                                              sectionKey,
                                                                                              "compositeField"
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Add Composite field "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    nativeOn:
                                                                                      {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            return _vm.addNewField(
                                                                                              sectionKey,
                                                                                              "associationField"
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Add Associated field "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm
                                                                              .design
                                                                              .pageMode ==
                                                                              1 ||
                                                                            _vm
                                                                              .design
                                                                              .pageMode ==
                                                                              2 ||
                                                                            _vm
                                                                              .design
                                                                              .pageMode ==
                                                                              3 ||
                                                                            _vm
                                                                              .design
                                                                              .pageMode ==
                                                                              4
                                                                              ? _c(
                                                                                  "PortalDesignerFields",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        section:
                                                                                          section,
                                                                                        sectionkey:
                                                                                          sectionKey,
                                                                                        used_models:
                                                                                          _vm.usedModels,
                                                                                        models:
                                                                                          _vm.models,
                                                                                        field_display_types:
                                                                                          _vm.fieldDisplayTypes,
                                                                                        modelFieldNames:
                                                                                          _vm.modelFieldNames,
                                                                                        joinTypes:
                                                                                          _vm.joinTypes,
                                                                                        primaryModel:
                                                                                          _vm
                                                                                            .design
                                                                                            .primaryModel,
                                                                                        design:
                                                                                          _vm.design,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { sm: "12" },
                                                          },
                                                          [
                                                            _c(
                                                              "v-expansion-panels",
                                                              [
                                                                _c(
                                                                  "v-expansion-panel",
                                                                  [
                                                                    _c(
                                                                      "v-expansion-panel-header",
                                                                      [
                                                                        _c(
                                                                          "v-row",
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "v-align-container full-height",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "v-align-div",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "label",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "  Visualisations"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "v-spacer"
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                staticClass:
                                                                                  "mx-1 pa-1",
                                                                                attrs:
                                                                                  {
                                                                                    sm: "2",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    staticClass:
                                                                                      "full-width",
                                                                                    attrs:
                                                                                      {
                                                                                        block:
                                                                                          "",
                                                                                      },
                                                                                    nativeOn:
                                                                                      {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            return _vm.addNewVisualisation(
                                                                                              sectionKey
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Add New Visualisation"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-expansion-panel-content",
                                                                      {
                                                                        class: {
                                                                          "ies-dark-gray":
                                                                            sectionKey %
                                                                              2 !=
                                                                            0,
                                                                          "ies-light-gray":
                                                                            sectionKey %
                                                                              2 ==
                                                                            0,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-card",
                                                                          [
                                                                            _vm
                                                                              .design
                                                                              .pageMode ==
                                                                            3
                                                                              ? _c(
                                                                                  "v-flex",
                                                                                  _vm._l(
                                                                                    section.visualisations,
                                                                                    function (
                                                                                      visualisation,
                                                                                      visualisationKey
                                                                                    ) {
                                                                                      return _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          key: visualisationKey,
                                                                                          attrs:
                                                                                            {
                                                                                              xs12: "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "PortalDesignerReportVisualisations",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  section:
                                                                                                    section,
                                                                                                  sectionkey:
                                                                                                    sectionKey,
                                                                                                  used_models:
                                                                                                    _vm.usedModels,
                                                                                                  models:
                                                                                                    _vm.models,
                                                                                                  field_display_types:
                                                                                                    _vm.fieldDisplayTypes,
                                                                                                  modelFieldNames:
                                                                                                    _vm.modelFieldNames,
                                                                                                  joinTypes:
                                                                                                    _vm.joinTypes,
                                                                                                  primaryModel:
                                                                                                    _vm
                                                                                                      .design
                                                                                                      .primaryModel,
                                                                                                  visualisation:
                                                                                                    visualisation,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  1
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e()
                          }
                        ),
                        1
                      ),
                      _vm._v(" "),
                      _vm.design.pageMode == 4
                        ? _c(
                            "v-flex",
                            _vm._l(
                              _vm.design.sections,
                              function (section, sectionKey) {
                                return _c(
                                  "v-flex",
                                  { key: sectionKey, attrs: { xs12: "" } },
                                  [
                                    _vm._v(
                                      "\n                               *** \n                            "
                                    ),
                                    _c("PortalDesignerDashboard", {
                                      attrs: {
                                        section: section,
                                        sectionkey: sectionKey,
                                        used_models: _vm.usedModels,
                                        models: _vm.models,
                                        field_display_types:
                                          _vm.fieldDisplayTypes,
                                        modelFieldNames: _vm.modelFieldNames,
                                        joinTypes: _vm.joinTypes,
                                        primaryModel: _vm.design.primaryModel,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("PortalDesignerOptionsBar", {
        staticClass: "mt-3",
        on: {
          saveDesign: function ($event) {
            return _vm.saveDesign()
          },
        },
      }),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: parseInt(6000),
            "multi-line": "",
            color: "ies-green",
          },
          model: {
            value: _vm.successSnackbar,
            callback: function ($$v) {
              _vm.successSnackbar = $$v
            },
            expression: "successSnackbar",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "full-height full-width v-align-container text-xs-center",
            },
            [
              _c("h4", { staticClass: "ies-dark-gray--text v-align-div" }, [
                _vm._v("Design Saved"),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: parseInt(6000),
            "multi-line": "",
            color: "ies-coral",
          },
          model: {
            value: _vm.errorSnackbar,
            callback: function ($$v) {
              _vm.errorSnackbar = $$v
            },
            expression: "errorSnackbar",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "full-height full-width v-align-container text-xs-center",
            },
            [
              _c("h4", { staticClass: "ies-dark-gray--text v-align-div" }, [
                _vm._v("Design Not Valid"),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }