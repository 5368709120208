<template>
<v-card xs12 class="mt-3 mb-3 no-print button-bar">
    <v-container fluid class="pa-0"> 
        <v-layout row wrap>
            <v-col cols="12" class="ma-3 d-none d-md-flex">
                <!-- Create -->
                <v-btn class="ies-green mr-1" v-on:click="addNew()"><v-icon>add</v-icon> Add New</v-btn>

                <!-- Update -->
                <v-btn v-on:click="refresh()" class="mr-1"><v-icon>refresh</v-icon> Refresh</v-btn>
                <v-btn v-on:click="toggleLiveRefresh()" :depressed="liveRefresh" class="mr-1"><v-icon>refresh</v-icon> Live View</v-btn>

                <!-- Obtain in other formats -->
                <v-btn v-on:click="downloadCSV()" class="mr-1"><v-icon>file_download</v-icon> CSV</v-btn>
                <v-btn disabled v-on:click="downloadPDF()" class="mr-1"><v-icon>file_download</v-icon> PDF</v-btn>
                <v-btn v-on:click="printPage()" class="mr-1"><v-icon>print</v-icon> Print</v-btn>

                <!-- Send to others -->
                <v-btn v-on:click="sendPage()" class="mr-1"><v-icon>email</v-icon> Email</v-btn>
                <v-btn v-on:click="reportProblem()" class="mr-1"><v-icon>report_problem</v-icon> Problem</v-btn>
             </v-col>

             <v-col cols="12" class="ma-3 d-flex d-md-none">
                <v-btn class="ies-green mr-1" v-on:click="addNew()"><v-icon>add</v-icon></v-btn>
                <v-btn v-on:click="refresh()" class="mr-1"><v-icon>refresh</v-icon></v-btn>
                <v-btn v-on:click="sendPage()" class="mr-1"><v-icon>email</v-icon></v-btn>
                <v-btn v-on:click="reportProblem()" class="mr-1"><v-icon>report_problem</v-icon></v-btn>
             </v-col>


             <v-alert type="info" :value="liveRefresh">
                Live view mode is active. The data will refresh automatically
             </v-alert>

        </v-layout>
        <div id="linearWrapper">
            <v-progress-linear :indeterminate="true" v-show="showProgressIcon" color="lime"></v-progress-linear>
        </div>
    </v-container>
</v-card>
</template>


<script>
    // Import the application event bus so we can listen for user or menu data changes and react accordingly
    import EventBus from '../../eventBus';
    import axios from 'axios';
    import FileSaver from 'file-saver';
    import {progressBar} from '@/mixins/progressBar';

    // Export the component for inclusion within the page
    export default {
        props: ['datasource'],
        mixins: [progressBar],

        /**
         * Core data required by the component and template
         */
        data: function(){
            return {
                /** @var The list of filters to be applied to the search */
                filters:     [],

                /** @var The page record model */
                page:        {},

                /** @var Gives contextual data about the page and content this component is managing */
                metaData:    {},

                /** @var The results of the data */
                data:        {},

                /** @var Flag identifying if the page has finished loading yet */
                loaded:      false,

                /** @var Feedback snackbar toggles, dictating whether the response messages should show informing the user that an action has occurred */
                successSnackbar:    false,
                errorSnackbar:      false,

                /** @var Toggles live view, periodically auto refreshing the data on the page after a defined period of time */
                liveRefresh:    false,
            };
        },

        /**
         * On page load complete build the component
         */
        mounted: function(){
            this.registerEventListeners();
        },

        /**
         * Business logic functions performing useful tasks for this component
         */
        methods: {
            registerEventListeners(){
                var self = this;

                // A component has asked the list to obtain a batched segment of the paginated results from the API
                this.$root.$on('List: Live view changed', function(payload){
                    console.log("List options: I should change the toggle of live view to match what has happened elsewhere: ", payload); 
                    self.liveRefresh = payload.liveRefresh;
                });
            },

            /**
             * Requests the data to be re-downloaded by the central page service.  This is handled by the service so that every independent component working
             * with that data can use the single central point of reference to operate, rather than have to do every job itself and then tell the other components
             * each time
             */
            refresh: function(){
                this.$root.$emit('List: Refresh', {'id': 'notset'});
            },

            /**
             * Open the creation page for adding a new record
             */
            addNew: function(){
                this.$root.$emit('List: New record', {'id': 'notset'});
            },

            sendPage: function(){
                this.$root.$emit('List: Send page', {'id': 'notset'});
            },

            reportProblem: function(){
                this.$root.$emit('List: Report problem', {'id': 'notset'});
            },

            /**
             * Triggers a download of the data in CSV format
             */
            downloadCSV: function(){
                this.$root.$emit('List: Download CSV', {'id': 'notset'});
            },

            /**
             * Methods adds current date to filename
             */
            generateFilename() {
                // get current page to include in filename
                var pageName = this.page.page_name;

                var today = new Date();
                var todayString = '_' + today.getDate() + '_' + (Number(today.getMonth())+1) + '_' + today.getFullYear();
                var filename = pageName + todayString + '.csv';

                return filename;
            },

            /**
             * Triggers a download of the data in a PDF document
             */
            downloadPDF: function(){
                this.$root.$emit('List: Download PDF', {'id': 'notset'});
            },

            /**
             * Triggers the browser to open a print dialogue box
             */
            printPage: function(){
                this.$root.$emit('List: Print page', {'id': 'notset'});
            },

            /**
             * Turns on/off the periodic auto-refresh the data
             *
             */
            toggleLiveRefresh: function(){
                this.liveRefresh = !this.liveRefresh;
                this.$root.$emit('List: Auto Refresh', {'id': 'notset', 'liveRefresh': this.liveRefresh});
            },
        }
    }

</script>
