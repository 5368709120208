var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _c("span", { staticClass: "subheader" }, [
          _vm._v("Hotels with outstanding balances"),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs8: "", lg6: "", "offset-lg1": "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      "append-icon": "search",
                      label: "Search",
                      "single-line": "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs2: "",
                    "offset-xs2": "",
                    lg2: "",
                    "offset-lg3": "",
                  },
                },
                [
                  _c("export-csv", {
                    attrs: {
                      filename: "OutstandingBalances",
                      data: _vm.filteredItems,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-data-table",
        {
          ref: "table",
          staticClass: "elevation-1",
          attrs: {
            headers: _vm.headers,
            items: _vm.listing,
            search: _vm.search,
            loading: _vm.isLoading,
          },
          scopedSlots: _vm._u([
            {
              key: "items",
              fn: function (props) {
                return [
                  _c("td", { staticClass: "text-xs-center" }, [
                    _vm._v(_vm._s(props.item.hotel_code)),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-xs-center" }, [
                    _vm._v(_vm._s(props.item.hotel_name)),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-xs-center" }, [
                    _vm._v(_vm._s(props.item.hotel_type)),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-xs-center" }, [
                    _vm._v(_vm._s(props.item.transactions)),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-xs-center" }, [
                    _vm._v(_vm._s(_vm._f("pounds")(props.item.invoiced))),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-xs-center" }, [
                    _vm._v(_vm._s(_vm._f("pounds")(props.item.payments))),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-xs-center" }, [
                    _vm._v(_vm._s(_vm._f("pounds")(props.item.balance))),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _c("v-progress-linear", {
            attrs: { slot: "progress", color: "lime", indeterminate: "" },
            slot: "progress",
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }