var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "grid-list-md": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _vm.reservation.show || 1
                        ? _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c("strong", [
                                      _vm._v(
                                        "Confirm Availability and Total Rate"
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs4: "" } }, [
                                    _c("strong", [_vm._v("Total Rate:")]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs8: "" } },
                                    [
                                      _vm._l(
                                        _vm.reservation.units,
                                        function (unit, index) {
                                          return _c("div", [
                                            _c("strong", [
                                              _vm._v(_vm._s(unit.room.name)),
                                            ]),
                                            _vm._v(
                                              " (\n                                          " +
                                                _vm._s(
                                                  unit.occ_adults
                                                    ? unit.occ_adults +
                                                        " adults"
                                                    : ""
                                                ) +
                                                ", \n                                          " +
                                                _vm._s(
                                                  unit.occ_children
                                                    ? unit.occ_children +
                                                        " young children"
                                                    : ""
                                                ) +
                                                ", \n                                          " +
                                                _vm._s(
                                                  unit.occ_oldchildren
                                                    ? unit.occ_oldchildren +
                                                        " older children"
                                                    : ""
                                                ) +
                                                ", \n                                          " +
                                                _vm._s(
                                                  unit.occ_babies
                                                    ? unit.occ_babies +
                                                        " babies"
                                                    : ""
                                                ) +
                                                "\n                                      )\n                                      " +
                                                _vm._s(unit.rate_type.name) +
                                                " for " +
                                                _vm._s(
                                                  _vm.reservation.stay_length
                                                ) +
                                                " nights\n                                  "
                                            ),
                                          ])
                                        }
                                      ),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c(
                                          "label",
                                          { attrs: { for: "noQuote" } },
                                          [
                                            _vm._v(
                                              "A quote cannot be given at this time"
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.quoteNotPossible,
                                              expression: "quoteNotPossible",
                                            },
                                          ],
                                          attrs: {
                                            id: "noQuote",
                                            name: "noQuote",
                                            type: "radio",
                                            value: "1",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.quoteNotPossible,
                                              "1"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              _vm.quoteNotPossible = "1"
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { attrs: { for: "quote" } },
                                          [_vm._v("A quote can be given")]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.quoteNotPossible,
                                              expression: "quoteNotPossible",
                                            },
                                          ],
                                          attrs: {
                                            id: "quote",
                                            name: "quote",
                                            type: "radio",
                                            value: "0",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.quoteNotPossible,
                                              "0"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              _vm.quoteNotPossible = "0"
                                            },
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.reservation.units,
                                        function (unit, index) {
                                          return !_vm.quoteNotPossible
                                            ? _c(
                                                "div",
                                                [
                                                  _vm._v(
                                                    "\n                                      " +
                                                      _vm._s(unit.room.name) +
                                                      " using " +
                                                      _vm._s(
                                                        unit.rate_type.name
                                                      ) +
                                                      ":\n                                      "
                                                  ),
                                                  _c(
                                                    "v-container",
                                                    { attrs: { fluid: "" } },
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        {
                                                          attrs: { column: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-container",
                                                                {
                                                                  attrs: {
                                                                    fluid: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-layout",
                                                                    {
                                                                      attrs: {
                                                                        row: "",
                                                                        wrap: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              xs6: "",
                                                                              sm3: "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "strong",
                                                                            [
                                                                              _vm._v(
                                                                                "Date"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              xs6: "",
                                                                              sm3: "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "strong",
                                                                            [
                                                                              _vm._v(
                                                                                "Rate (" +
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .reservation
                                                                                      .hotel
                                                                                      .currency
                                                                                      .currency_code
                                                                                  ) +
                                                                                  ")"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " for " +
                                                                              _vm._s(
                                                                                unit.total_guests
                                                                              ) +
                                                                              " guests\n                                                          "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              xs6: "",
                                                                              sm2: "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "strong",
                                                                            [
                                                                              _vm._v(
                                                                                "Total Daily Rate"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              xs6: "",
                                                                              sm2: "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "strong",
                                                                            [
                                                                              _vm._v(
                                                                                "Commission Rate (%)"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              xs6: "",
                                                                              sm2: "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "strong",
                                                                            [
                                                                              _vm._v(
                                                                                "Commission Amount (" +
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .reservation
                                                                                      .hotel
                                                                                      .currency
                                                                                      .currency_code
                                                                                  ) +
                                                                                  ")"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _vm._l(
                                                            unit.std_rates,
                                                            function (
                                                              quoteDate,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs12: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-container",
                                                                    {
                                                                      attrs: {
                                                                        fluid:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-layout",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              row: "",
                                                                              wrap: "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-flex",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  xs6: "",
                                                                                  sm3: "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "strong",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      quoteDate.date
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-flex",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  xs6: "",
                                                                                  sm3: "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "input",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "model",
                                                                                        rawName:
                                                                                          "v-model",
                                                                                        value:
                                                                                          quoteDate.rate,
                                                                                        expression:
                                                                                          "quoteDate.rate",
                                                                                      },
                                                                                    ],
                                                                                  staticStyle:
                                                                                    {
                                                                                      "max-width":
                                                                                        "10rem",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      type: "text",
                                                                                    },
                                                                                  domProps:
                                                                                    {
                                                                                      value:
                                                                                        quoteDate.rate,
                                                                                    },
                                                                                  on: {
                                                                                    input:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        if (
                                                                                          $event
                                                                                            .target
                                                                                            .composing
                                                                                        )
                                                                                          return
                                                                                        _vm.$set(
                                                                                          quoteDate,
                                                                                          "rate",
                                                                                          $event
                                                                                            .target
                                                                                            .value
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-flex",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  xs6: "",
                                                                                  sm2: "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                              " +
                                                                                  _vm._s(
                                                                                    parseFloat(
                                                                                      quoteDate.commission
                                                                                    )
                                                                                  ) +
                                                                                  "\n                                                          "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-flex",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  xs6: "",
                                                                                  sm2: "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                              ???\n                                                          "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-flex",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  xs6: "",
                                                                                  sm2: "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                              ???\n                                                          "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: "",
                                                              },
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Total Rate: "
                                                                ),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm.totalRates,
                                                                      expression:
                                                                        "totalRates",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "text",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      _vm.totalRates,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.totalRates =
                                                                          $event.target.value
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .reservation
                                                                        .hotel
                                                                        .currency
                                                                        .currency_code
                                                                    )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs4: "" } }, [
                                    _c("strong", [_vm._v("Deposit:")]),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs8: "" } }, [
                                    _vm.reservation.depositRequired
                                      ? _c("div", [
                                          _c("strong", [
                                            _vm._v(
                                              "Deposit required at time of booking " +
                                                _vm._s(
                                                  _vm.reservation
                                                    .deposit_amount_local
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.reservation.hotel.currency
                                                    .currency_code
                                                )
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.reservation.is_advance_payment
                                      ? _c("div", [
                                          _vm._v(
                                            "Full pre-payment charged to the client by i-escape to secure the booking"
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.reservation.hotel.collect_deposit == 1
                                      ? _c("div", [
                                          _vm._v(
                                            "Amount charged to the client by i-escape to secure the booking"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs4: "" } }, [
                                    _c("strong", [_vm._v("Payment Policy:")]),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs8: "" } }, [
                                    _vm._v(
                                      "\n                                  ???\n                              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs4: "" } }, [
                                    _c("strong", [
                                      _vm._v("Cancellation Policy:"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs8: "" } }, [
                                    _vm._v(
                                      "\n                                  ???\n                              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs4: "" } }, [
                                    _c("strong", [_vm._v("Room Held Until")]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs8: "" } },
                                    [
                                      _c("v-date-picker", {
                                        attrs: {
                                          "no-title": "",
                                          scrollable: "",
                                        },
                                        model: {
                                          value: _vm.reservation.quote_expiry,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.reservation,
                                              "quote_expiry",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "reservation.quote_expiry",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ies-green",
                          on: { click: _vm.sendQuote },
                        },
                        [_vm._v("Send to client")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }