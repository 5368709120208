<template>
    <v-col cols="12" md="2" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">shopping_basket</v-icon> Gift Voucher Products
            </v-card-title>

            <v-card-text>
                <v-btn class="mt-1" block :href="typesUrl">Gift Voucher Types</v-btn>
                <v-btn class="mt-1" block :href="productsUrl" >Gift Voucher Products</v-btn>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<script>
    /**
     * Display buttons linking through to a hotels' pages
     */
    export default {
        props:['pageid'],

        data: function() {
            return {
                typesUrl: '/voucher-types/list',
                productsUrl: '/voucher-products/list',
            }
        },

        methods: {},

        computed: {}
    }
</script>
