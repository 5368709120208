<template>

    <v-container fluid>
       <v-layout row-sm column>
            <v-col cols="12">
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-col>

            <v-col cols="12">
    
        <div v-for="(dataProperty, index) in viewModel">
          <v-layout row>
            <v-col cols="12" md="5">
                The data item named:
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field placeholder="Name" v-model="dataProperty.name" v-on:keyup="updateField()" class="w-25"></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
                has the value:
            </v-col>
            <v-col cols="12" md="5">
                <v-text-field placeholder="Value" v-model="dataProperty.value" v-on:keyup="updateField()" class="w-25"></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-btn v-on:click="removeProperty(index)"><v-icon>delete</v-icon></v-btn>
            </v-col>
          </v-layout>
        </div>
        <div>
            <v-btn v-on:click="addNewProperty()"><v-icon>add</v-icon></v-btn>
        </div>
        <input type="hidden" :name="field.fieldName" :value="field.value">
        <v-alert type="error" :value="field.errors" style="width: 100%">
            <li v-for="error in field.errors">
                {{ error }}
            </li>
        </v-alert>
        </div>
            </v-col>
        </v-layout>
    </v-container>

</template>

<script>
    /**
     * Allows the management of a hotel's channel manager configuration
     * 
     */
    export default {
        name: 'ies-channelManagerHotelConfig',
        props: ['field'],
        data() {
            return {
                dataModel: {},
                viewModel: []
            }
        },
        created:function() {
            this.setDataModelFromJSON();
            this.setViewModelFromDataModel();
        },
        methods: {
            setDataModelFromJSON: function(){
                console.log(this.field.value);
                if (!this.field.value){
console.log("has no config data yet");
                    return [];
                }
                this.dataModel = JSON.parse(this.field.value);
                console.log(this.dataModel);
            },
            setViewModelFromDataModel: function(){
                this.viewModel = [];
                console.log(this.dataModel);
                var self = this;
                Object.keys(this.dataModel).forEach(function(key){
                    console.log(key);
                    self.viewModel.push({"name":key, "type": "value", "value": self.dataModel[key]});
                });
            },
            updateField: function(){
                console.log("Not yet working");
                this.setDataModelFromViewModel();
                this.setFieldValueFromDataModel();
            },
            setDataModelFromViewModel: function(){
                var newDataModel = {};
                this.viewModel.forEach(function(viewModel){

                    // Ordinary data value
                    newDataModel[viewModel.name] = viewModel.value;
                });
                this.dataModel = newDataModel;
                console.log(this.dataModel);
            },
            setFieldValueFromDataModel: function(){
                console.log(JSON.stringify(this.dataModel));
                this.field.value = JSON.stringify(this.dataModel);
            },
            addNewProperty: function(){
                this.viewModel.push({"name":"", "type": "value", "value": ""})
            },
            removeProperty: function(indexToRemove){
                var newViewModel = [];
                for(var index = 0; index < this.viewModel.length; index++){
                    if(index != indexToRemove){
                        newViewModel.push(this.viewModel[index]);
                    }
                }
                this.viewModel = newViewModel;
                this.updateField();
            }
        },
        watch: {},
        computed: {}
    }
</script>
