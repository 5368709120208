<template>
    <v-flex v-if="show">

            <v-card class="card-with-shadow">
                <v-card-title><h5>Filter results {{ title }}</h5></v-card-title>
                <v-divider></v-divider>
                <v-list dense>
                    <v-list-item>
                        <v-list-item-content>Total count:</v-list-item-content>
                        <v-list-item-content class="align-end">{{ result.totalVoucherNumber }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Total value:</v-list-item-content>
                        <v-list-item-content class="align-end">£{{ result.totalVoucherValue }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Average value:</v-list-item-content>
                        <v-list-item-content class="align-end">£{{ result.averageVoucherValue }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Bookings count:</v-list-item-content>
                        <v-list-item-content class="align-end">{{ result.totalBookings }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Total redeemed value:</v-list-item-content>
                        <v-list-item-content class="align-end">£{{ result.totalRedeemedValue }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Average redeemed value:</v-list-item-content>
                        <v-list-item-content class="align-end">£{{ result.averageRedeemedValue }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Total bookings value:</v-list-item-content>
                        <v-list-item-content class="align-end">£{{ result.totalBookingValue }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Average bookings value:</v-list-item-content>
                        <v-list-item-content class="align-end">£{{ result.averageBookingValue }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Total commission value:</v-list-item-content>
                        <v-list-item-content class="align-end">£{{ result.totalCommissionValue }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Average commission value:</v-list-item-content>
                        <v-list-item-content class="align-end">£{{ result.averageCommissionValue }}</v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>

    </v-flex>
</template>

<script>
    export default {
        name: 'VoucherDashboardFilterResults',
        props: ['show', 'range', 'result', 'difference', 'subname'],
        data() {
            return {

            }
        },
        methods: {

        },
        computed: {
            title() {
                if (this.difference) {
                    return 'Difference A-B (A to B %)';
                }
                else {
                    let prefix = '';
                    if (this.subname) {
                        prefix = this.subname + ': ';
                    }
                    return prefix + 'from ' + this.range.start + ' to ' + this.range.end;
                }
            },
        },
    }
</script>
<style scoped>
    .card-with-shadow {
        box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)!important;
    }
</style>
