<template>
    <v-flex xs12 pa-3>

        <v-layout row wrap xs12>

            <component v-for="panel in panels" 
                       v-bind:is="panel.system_name" 
                       :key="panel.dashboard_panel_key"
                       :pageid="primarykey"
            ></component>

            <v-flex xs1 offset-xs11 v-if="configuration.user_configurable">
                <v-btn outline large disabled>
                    <v-icon>settings</v-icon>
                </v-btn>
            </v-flex>

        </v-layout>

    </v-flex>
</template>

<script>
    import axios from 'axios';

    /**
     * Display a dashboard window in the page and autopopulates it with the panels specified in the API for this dashboardname
     */
    export default {
        props:['dashboardname', 'primarykey'],

        data: function() {
            return {

                /** @var [] panels  - An array of the panels that should be embedded within this component */
                panels:     [],

                /** @var {} configuration - An object encapsulating all of the data describing how this dashboard should behave */
                configuration:  {
                                    // Default to false
                                    userConfigurable: false
                                }
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
console.log("primarykey", this.primarykey);
            this.loadPanels();
        },

        methods: {

            /**
             * Load the data describing all the panels for the dashboard to host by requesting them from the API
             */
            loadPanels(){
                axios.get('/api/dashboards/' + this.dashboardname + '/panels')
                .then(this.setUpPanels)
                .catch(function (error) {
                    console.log('%cError loading dashboard content', 'color:red');
                    console.log(error);
                });

            },

            /**
             * 
             */
            setUpPanels(response){
                this.panels = response.data;
            }

        }
    }
</script>
