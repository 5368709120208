<template>
    <v-container fluid>
       <v-layout row-sm column>
	    <v-col v-if="this.field.schema.displayName != 'none'" cols="12">
    	    <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
  		</v-col>
        <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
        <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
        <v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
        <v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>
    	<v-col cols="12">
        <!-- list must be list of ['text' => x, 'value' => y] -->
        <v-select
            v-model="viewValue"
            :items="carHireTypes"
            autocomplete
        ></v-select>
        <input type="hidden" :name="field.fieldName" :text="field.text" :value="field.value">
        <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
            <li v-for="error in field.errors" v-bind:key="error">
                {{ error }}
            </li>
        </v-alert>
    </v-col>
    </v-layout>
  </v-container>
</template>


<script>
    /**
     * Allows the selection of a voucher type ID by choosing its name from
     * a selection downloaded from the API on creation
     */
    export default {
        name: 'ies-carHireType',
        props: ['field'],
        data() {
            return {
                carHireTypes: [],
                viewValue: parseInt(this.field.value),
            }
        },
        created:function() {
            this.getCarHireTypes();
        },
        methods: {
            getCarHireTypes:function(){
                axios.get('/api/car-hire-types')
                .then(function(response){
                    console.log(response);
                    var carHireTypes = [];
                    response.data.types.forEach(function(carHireType){
                        carHireTypes.push({ text: carHireType.car_hire, value: carHireType.car_hire_type_key });
                    });
                    this.carHireTypes = carHireTypes;
                }.bind(this));
            },
        },
        watch: {
            viewValue: function (newValue) {
                this.field.value = newValue;
            }
        }
    }
</script>

