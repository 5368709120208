<template>
    <div>
        <h1 class="mx-5 my-3">{{ componentTitle }}</h1>
        <v-layout justify-center ma-4>
            <v-flex text-xs-right>
                <v-date-picker 
                    color="lime" 
                    v-model="date" 
                    :landscape="true" 
                    type="month" 
                    @change="isSubmitted = false"
                />
            </v-flex>
            <v-flex style="max-width: 180px;">
                <!-- type of invoice -->
                <h3 class="subheader">Type Of Invoice</h3>
                <v-radio-group v-model="invoiceType" :mandatory="true" @change="isSubmitted = false">
                    <v-radio label="Commissions" value="1"/>
                    <v-radio label="Annual Fees" value="2"/>
                    <v-radio label="Ad Hoc"      value="3"/>
                </v-radio-group>
                    <!-- group by day -->
                <v-switch 
                    v-model="groupByDay" 
                    :label="`Group by day ${groupByDay ? '✔' : '✘'}`" 
                    @change="isSubmitted = false" 
                />
            </v-flex>
            <v-flex>
                <!-- type of hotel contract -->
                <h3 class="subheader">Type Of Hotel</h3>
                <v-radio-group v-model="hotelType" :mandatory="true" @change="isSubmitted = false">
                    <v-radio label="Upfront" value="1"></v-radio>
                    <v-radio label="Arrears" value="2"></v-radio>
                    <v-radio label="Both"    value="3"></v-radio>
                </v-radio-group>
            </v-flex>
        </v-layout>

        <!-- Summary -->
        <v-layout justify-center ma-2>
            <v-btn :class="{'lime' : !isSubmitted}" @click="getAllBatchReports">Submit</v-btn>
            <export-csv :filename="`${date}-${batchReportType}-batch-report`" :data="regions" />
        </v-layout>

        <!-- Summary -->
        <batch-report-summary :summary="summary" />
        
        <!-- Reports -->
        <div v-for="region in regions" :key="region.key">
            <batch-report-data-table v-if="region.report" :batchReport="region.report"/>
        </div>
    </div>
</template>

<script>
import BatchReportSummary from './BatchReportSummary';
import BatchReportDataTable from './BatchReportDataTable'
import ExportCsv from '@/components/common/ExportCSV';

/**
 * In order to make this component work for Invoices and Credit Notes
 * we find out which type of report this component will produce by
 * looking at the URL.
 */
const URL               = window.location.pathname;
const BATCH_REPORT_TYPE = URL.substring(URL.lastIndexOf('/') + 1);

/**
 * These constants match the accounts regions constants we have
 * in TransactionPortal in the backend.
 */
const REGION_UK         = 1;
const REGION_EU_VAT     = 2;
const REGION_EU_NO_VAT  = 3;
const REGION_WORLDWIDE  = 4;

export default {
    components: {
        BatchReportSummary,
        BatchReportDataTable,
        ExportCsv
    },
    data() {
        return {
            batchReportType: BATCH_REPORT_TYPE,
            date: new Date().toISOString().substr(0, 10),
            invoiceType: "1",
            hotelType: "1",
            groupByDay: false,
            summary: {},
            isSubmitted: true,
            regions: [
                {   
                    key: REGION_UK,
                    report: {},
                },
                {   
                    key: REGION_EU_VAT,
                    report: {},
                },
                {   
                    key: REGION_EU_NO_VAT,
                    report: {},
                },
                {   
                    key: REGION_WORLDWIDE,
                    report: {},
                },
            ]
        }
    },
    mounted() {
        this.getAllBatchReports();
    },
    computed: {
        componentTitle: function() {
            switch(this.batchReportType) {
                case 'invoice':
                    return 'Invoices Batch Report';
                case 'credit-note':
                    return 'Credit Notes Batch Report';
            }
        }
    },
    watch: {
        regions: function() {
            this.createSummary();
        }
    },
    methods: {

        /**
         * Creates a summary for all the reports once they have all returned
         */
        createSummary() {
            // Get empty region.reports (i.e. the ones that haven't come back from axios request)
            const hasEmptyReport = this.regions.filter(region => _.isEmpty(region.report));
            // If we have all the reports we can start building the summary.
            if (!hasEmptyReport.length) {
                const summary = {}; 
                this.regions.forEach(region => {
                    summary[region.report.region] = region.report.totalGross;
                });

                summary["All Regions"] = Object.keys(summary).reduce((sum, key) => ( sum += summary[key] ), 0);

                this.summary = summary;
            }
        },
        /**
         * Fires a report request for each accounts regions
         */
        getAllBatchReports() {
            this.regions.forEach(region => this.getBatchReport(region.key));
        },
        /**
         * Gets the relevant batch report
         */
        async getBatchReport(regionKey) {
            const res = await axios(`api/accounts/batch-report/${BATCH_REPORT_TYPE}`, {
                method: 'GET',
                params: {
                    invoiceType: this.invoiceType,
                    hotelType: this.hotelType,
                    date: this.date,
                    region: regionKey,
                    groupByDay: this.groupByDay
                }
            });

            this.regions = this.regions.map(region => { 
                return region.key === regionKey ? {key: regionKey, report: res.data } : region
            });
        },
    },
}
</script>
