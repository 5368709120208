<template>
    <v-flex xs3 ma-1>
        <v-card>
            <v-card-text>
               
                (Hotel transaction picker goes here)
                
            </v-card-text>
        </v-card>
    </v-flex>
</template>

<script>
    /**
     * Display buttons linking through to accounts reports
     */
    export default {
        props:[],

        data: function() {
            return {
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){},

        methods: {},

        computed: {}
    }
</script>
