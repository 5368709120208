<template>
    <v-col cols="12" md="3" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">home</v-icon>Manage Inventory 
            </v-card-title>

            <v-card-text>
                <v-container fluid>
                    <v-row>
						<v-col>
							<v-btn class="mt-1" block :href="roomTypesUrl"><v-icon>room_preferences</v-icon>Room Types</v-btn>
                            <v-btn class="mt-1" block :href="rateTypesUrl"><v-icon>price_change</v-icon>Rate Types</v-btn>
                            <v-btn class="mt-1" block disabled :href="rateExtrasUrl"><v-icon>add_box</v-icon>Rate Extras</v-btn>
                            <v-btn class="mt-1" block disabled :href="longTermOverviewUrl"><v-icon>calendar_view_month</v-icon>Dashboard</v-btn>
                            <v-btn class="mt-1" block :href="dailyPlannerUrl"><v-icon>edit_calendar</v-icon>Daily Planner</v-btn>
                            <v-btn class="mt-1" block disabled :href="batchLoaderUrl"><v-icon>upload_file</v-icon>Batch Loader</v-btn>
                            <v-btn class="mt-1" block :href="cloneRateDatesUrl"><v-icon>content_copy</v-icon>Clone Historic Rates</v-btn>
                            <v-btn class="mt-1" block disabled :href="specialOffersUrl"><v-icon>star</v-icon>Special Offers</v-btn>
                            <v-btn class="mt-1" block disabled :href="changeLogUrl"><v-icon>history</v-icon>Change Log</v-btn>
                            <v-btn class="mt-1" block disabled :href="rateSummaryUrl"><v-icon>summarize</v-icon>Rate Summary</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card> 
    </v-col>
</template>

<script>
    /**
     * Display buttons linking through to a hotels' pages
     */
    export default {
        props:['pageid'],

        data: function() {
            return {
                batchLoaderUrl: '',
                changeLogUrl: '',
                cloneRateDatesUrl: '',
                dailyPlannerUrl: '',
                longTermOverviewUrl: '',
                rateExtrasUrl: '',
                rateSummaryUrl: '',
                rateTypesUrl: '',
                roomTypesUrl: '',
                specialOffersUrl: ''
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            this.batchLoaderUrl = '';
            this.changeLogUrl = '';
            this.cloneRateDatesUrl = "/hotel/" + this.pageid + '/clone-rate-dates';
            this.dailyPlannerUrl = "/hotel/" + this.pageid + '/planner';
            this.longTermOverviewUrl = '';
            this.rateExtrasUrl = '';
            this.rateSummaryUrl = '';
            this.rateTypesUrl = "/rate-types/list?s=as&c[]=Hotel-hotel_key&o[]===&v[]=" + this.pageid;
            this.roomTypesUrl = "/hotel-rooms/list?s=as&c[]=HotelRoom-hotel_key&o[]===&v[]=" + this.pageid;
            this.specialOffersUrl = '';
        },

        methods: {},

        computed: {}
    }
</script>
<style>
    .major-icon {
        font-size: 5rem;
    }
</style>

