<template>
    <v-flex xs3 ma-1>
        <v-card>
            <v-card-title>
                <v-icon>attach_money</v-icon> Billing Reports
            </v-card-title>
            <v-card-text>
               
                <v-layout column>
                    <v-btn block disabled>Debtor Report</v-btn>
                    <v-btn block disabled>Sales Invoices & Sales Credit Note Batch Report</v-btn>
                    <v-btn block disabled>VAT Report</v-btn>
                    <v-btn block disabled>Billing History Report</v-btn>
                    <v-btn block disabled>Commission Report</v-btn>
                </v-layout>
                
            </v-card-text>
        </v-card>
    </v-flex>
</template>

<script>
    /**
     * Display buttons linking through to accounts reports
     */
    export default {
        props:[],

        data: function() {
            return {
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){},

        methods: {},

        computed: {}
    }
</script>
