var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    [
      _c(
        "td",
        {
          staticClass: "roomName header-cell interactive font-weight-bold",
          attrs: { disabled: _vm.isDisabled },
          on: {
            mouseenter: function ($event) {
              return _vm.handleRowMouseEnter(_vm.roomType)
            },
            mousedown: function ($event) {
              return _vm.handleRowMouseDown(_vm.roomType)
            },
            mouseup: function ($event) {
              return _vm.handleCellMouseUp(_vm.roomType)
            },
          },
        },
        [_vm._v("\n        " + _vm._s(_vm.roomType.name) + "\n    ")]
      ),
      _vm._v(" "),
      _vm._l(_vm.roomType.availabilityPerDate, function (availabilityDate) {
        return [
          _c(
            "td",
            {
              class: {
                interactive: true,
                "availability-for-date": true,
                "room-available": availabilityDate.status_key == 41,
                "room-on-request": availabilityDate.status_key == 42,
                "room-unavailable":
                  availabilityDate.status_key == 43 ||
                  availabilityDate.status_key == 44 ||
                  availabilityDate.quantity == 0,
                "availability-not-specified":
                  availabilityDate.status_key == null,
                selected: _vm.cellIsSelected(availabilityDate),
                updated: _vm.cellIsUpdated(availabilityDate),
                "disabled-cell": _vm.dateIsOutOfRange(availabilityDate.date),
              },
              attrs: {
                title: `Availability for ${_vm.roomType.standard_occupancy} pax on ${availabilityDate.date}`,
                disabled: _vm.isDisabled,
              },
              on: {
                mousedown: function ($event) {
                  return _vm.handleCellMouseDown(availabilityDate)
                },
                mouseenter: function ($event) {
                  return _vm.handleCellMouseEnter(availabilityDate)
                },
                mouseup: function ($event) {
                  return _vm.handleCellMouseUp(_vm.roomType)
                },
              },
            },
            [
              _vm._v(
                "\n            " + _vm._s(availabilityDate.quantity) + "\n    "
              ),
            ]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }