<template>
    <v-container fluid>
		<v-row>
			<v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>

			<v-col cols="12">
				<v-alert type="info"  dismissable :value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
           		<v-alert type="warning"  dismissable :value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            	<v-alert type="error" dismissable :value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>
			</v-col>

			<v-col col="3">
				<label for="accept_range">General Children's Policy: </label>
			</v-col>
			<v-col cols="9">
				<v-radio-group
					id="child-policy"
					v-model="viewValues.accept_range"
					@change="updatePolicy($event)"
					column>
					<v-row><v-radio :value="0"></v-radio><span>Children of all ages are welcome</span></v-row>
					<v-row><v-radio :value="1"></v-radio><span>Only Children aged <input style="border: 1px solid grey; box-sizing: border-box; padding: 4px 6px;" type="number" name="custom_age_range_end" min="0" max="18" v-model="viewValues.custom_age_range_end" @keydown="checkAge"> years and over are accepted</span></v-row>
					<v-row><v-radio :value="2"></v-radio><span>Other <input type="text" style="border: 1px solid grey; box-sizing: border-box; padding: 4px 6px;" name="other_child_acceptance" v-model="viewValues.other_child_acceptance"></span></v-row>
				</v-radio-group>
			</v-col>

    		<v-col v-if="this.field.schema.displayName != 'none'" cols="2">
        		<label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
    		</v-col>

			<input type="hidden" :name="this.field.fieldName" :value="field.value">

        	<v-alert type="error" dismissable :value="true" v-if="errorMessages && errorMessages.length">
               	<div v-for="(error, index) in errorMessages" :key="index" >{{ error.reason }}*</div>
            </v-alert>

		</v-row>
	</v-container>
</template>
<style>
#child-policy .row {
	padding-bottom: 4px;
}
</style>



<script>
    /**
     * Allows the the setting of general child policies
	 *
     */
    export default {
        name: 'ies-input-child-policy',
        props: ['field', 'recordData'],
        data() {
            return {
				showValidationGuide: false,
				errorMessages: [],
                viewValues: {
					'children_policy_key': parseInt(this.recordData.hotel_children_policy_key),
					'accept_range': parseInt(this.recordData.accept_range),
					'custom_age_range_end': 0,
					'other_child_acceptance': '',
				},
            }
        },

		created: function() {
			this.addEventListeners();
			this.getChildPolicyData();
			this.field.value = this.viewValues;
		},

		methods: {
			addEventListeners: function() {
				var self = this;

           		this.$root.$on('Field: set errors', function(payload){
               		console.log("TEXT: I heard ", payload);
                	if (payload.modelName = self.field.modelName && payload.fieldName == self.field.fieldName){
                  		self.errorMessages = payload.errors;
                   		console.log("TEXT: I set ", self.field.errors);
               		}
            	});
			},
			
			getChildPolicyData: function() {
				axios.get("/api/child-policy-data/" + this.recordData.hotel_children_policy_key).then((response) => {
					let policy = response.data.policy;
					console.log(policy);
					this.viewValues.accept_range = policy.accept_range;
					this.viewValues.custom_age_range_end = policy.custom_age_range_end;
					this.viewValues.other_child_acceptance = policy.other_child_acceptance;
				});
			},

			updatePolicy: function(event) {
				let value = event;
				switch(parseInt(value)) {
					case 0:
						this.viewValues.custom_age_range_end = 0;
						this.viewValues.other_child_acceptance = null;
						break;
					case 1:
						this.viewValues.other_child_acceptance = null;
						break;
					case 2:
						this.viewValues.custom_age_range_end = 0;
						break;
				}
			},

			checkAge: function(e) {
				let key = e.key;
				let value = e.target.value;
				let new_value = Number(value + key);
                let max = Number(event.target.max);
                if(new_value > max){ event.preventDefault(); }
			}
		},

        watch: {
			'viewValues.accept_range': function(newValue) {
				this.field.value = this.viewValues;
			},
			'viewValues.custom_age_range_end': function(newValue) {
				this.field.value = this.viewValues;
			},
			'viewValues.other_child_acceptance': function(newValue) {
				this.field.value = this.viewValues;
			},
        }
    }
</script>






