<template>
    <div>
        <v-flex xs12>
         <!--   <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label> -->
            <label>Reason for payment request:</label>
        </v-flex>
        <v-flex xs12>
            Reference code of the reservation or hotel annual fee invoice:
        </v-flex>
        <v-flex xs12 sm6>
            <v-text-field
                name="reason_reference" 
                v-model="viewValue" v-on:keyup="updateField()"
            ></v-text-field>
        </v-flex>

        <v-flex xs12 sm6 v-if="reasonKey == reasonBookingDeposit">
            <strong>Booking deposit</strong> </br>
            To be issued to {{ reservation.user.first_name }} {{ reservation.user.last_name }} <a :href="emailLink">{{ reservation.user.email }}</a> <br/>
            Reservation {{ reservation.reservation_key }} at ({{ reservation.hotel.hotel_code }}) {{ reservation.hotel.hotel_name }}
        </v-flex>

        <v-flex xs12 sm6 v-if="reasonKey == reasonVoucherPurchase">
            <strong>Voucher Purchase</strong> </br>
            To be issued to {{ voucher.purchaser_email }} <a :href="emailLink">{{ voucher.purchaser_email }}</a> <br/>
            For gift voucher {{ voucher.voucher_reference }} ({{ voucher.value }} GBP)
        </v-flex>

        <v-flex xs12 sm6 v-if="reasonKey == reasonBookingTopUp">
            <strong>Booking deposit top up</strong> </br>
            To be issued to {{ reservation.user.first_name }} {{ reservation.user.last_name }} <a :href="emailLink">{{ reservation.user.email }}</a> <br/>
            Reservation {{ reservation.reservation_key }} at ({{ reservation.hotel.hotel_code }}) {{ reservation.hotel.hotel_name }}<br />
            Deposit amount {{ reservation.deposit_amount_gbp }} (GBP), Quote amount {{ reservation.quote_amount_gbp }} (GBP)
        </v-flex>

        <v-flex xs12 sm6 v-if="reasonKey == reasonHotelAnnualFee">
            <strong>Hotel Annual Fee</strong> </br>
            To be issued to ({{ invoice.hotel.hotel_code }}) {{ invoice.hotel.hotel_name }} <br/>
            Invoice {{ invoice.invoice_portal_key }} ({{ invoice.amount_gbp }} GBP)
        </v-flex>

        <v-flex xs12 sm6 v-if="reasonKey == reasonHotelArrears">
            <strong>In-Arrears Hotel Commission Invoice</strong> </br>
            To be issued to ({{ invoice.hotel.hotel_code }}) {{ invoice.hotel.hotel_name }} <br/>
            Invoice {{ invoice.invoice_portal_key }} ({{ invoice.amount_gbp }} GBP)
        </v-flex>

        <v-flex xs12 sm6 v-if="reasonKey == reasonAdHoc">
            <strong>Untracked payment</strong> </br>
            This payment request has no association to any reservation, hotel or gift voucher
        </v-flex>

        <input type="hidden" name="payment_reason_key" :value="reasonKey">
        <input type="hidden" name="record_key" :value="recordKey">

        <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
            <li v-for="error in field.errors">
                {{ error }}
            </li>
        </v-alert>
    </div>
</template>

<script>
    /**
     * Allows the setting of payment request's status from a list of
     * human readable options, and through extension any business logic
     * additionally required when this is changed
     */
    export default {
        name: 'ies-paymentRequestReason',
        props: ['field', 'recordData'],
        data() {
            return {
                options: [],
                viewValue: '',
                reasonBookingDeposit: 1,
                reasonVoucherPurchase: 2,
                reasonHotelAnnualFee: 3,
                reasonBookingTopUp: 4,
                reasonAdHoc: 5,
                reasonHotelArrears: 6,
                reasonKey: 5,
                recordKey: 0
            }
        },

        created:function() {
//            this.setOptions();
            this.initialiseRecordType();
        },

        methods: {
            initialiseRecordType:function(){
console.log("********",this.recordData);
                switch(parseInt(this.recordData.payment_reason_key)){
                    case this.reasonBookingDeposit:  return this.initialiseForBookingDeposit();
                    case this.reasonVoucherPurchase: return this.initialiseForVoucherPurchase();
                    case this.reasonBookingTopUp:    return this.initialiseForBookingTopUp();
                    case this.reasonHotelAnnualFee:  return this.initialiseForHotelAnnualFee();
                    case this.reasonHotelArrears:    return this.initialiseForHotelArrears();
                    default: 
                        return this.initialiseForAdHoc();
                }
            },

            initialiseForBookingDeposit:function(){
                alert("Cannot initialise data for booking deposit payments - this feature is not yet supported");
            },

            initialiseForVoucherPurchase:function(){
                alert("Cannot initialise data for gift voucher purchases - this feature is not yet supported");
            },

            initialiseForHotelAnnualFee:function(){
                console.log("initialise for annual fee");
                axios.get('/api/accounts/invoices/id/'+parseInt(this.recordData.record_key))
                .then(function(response){
                    console.log(response);
                    if(response.data.invoice){
                        this.invoice = response.data.invoice;
                        this.reasonKey = this.reasonHotelAnnualFee;
                        this.recordKey = this.invoice.invoice_portal_key;

                        this.field.value = { reason_key: this.reasonKey, record_key: this.recordKey };
                        this.viewValue = this.invoice.reference;
                    }
                }.bind(this));
            },

            initialiseForHotelArrears:function(){
                console.log("initialise for in-arrears hotel commission fee");
                axios.get('/api/accounts/invoices/id/'+parseInt(this.recordData.record_key))
                .then(function(response){
                    console.log(response);
                    if(response.data.invoice){
                        this.invoice = response.data.invoice;
                        this.reasonKey = this.reasonHotelArrears;
                        this.recordKey = this.invoice.invoice_portal_key;

                        this.field.value = { reason_key: this.reasonKey, record_key: this.recordKey };
                        this.viewValue = this.invoice.reference;
                    }
                }.bind(this));
            },

            initialiseForBookingTopUp:function(){
                console.log("initialise for booking top up");
                axios.get('/api/reservations/id/'+parseInt(this.recordData.record_key))
                .then(function(response){
                    if(response.data.reservation){
                        this.reservation = response.data.reservation;
                        this.reasonKey = this.reasonBookingTopUp;
                        this.recordKey = this.reservation.reservation_key;

                        this.emailLink = "mailto:" + this.reservation.user.email;
                        this.field.value = { reason_key: this.reasonKey, record_key: this.recordKey };
                        this.field.value = { reason_key: this.reasonKey, record_key: this.recordKey };
                        this.viewValue = this.reservation.reference;
                    }
                }.bind(this));
            },

            initialiseForAdHoc:function(){
                console.log("initialise for ad-hoc");
                this.recordKey = null;
                this.reasonKey = this.reasonAdHoc;
            },

            getRelatedRecordType:function(){
                console.log("I need to search for something called: " + this.viewValue);
                if(this.viewValue.toUpperCase().substring(0, 4) == 'IES-'){
                    console.log("I think this is a reservation");
                    this.getReservationFromApi();
                }
                else {
                    console.log("I need to check if this is an invoice, it doesnt match a reservation reference...");
                    this.getInvoiceFromApi();
                }
            },

            getReservationFromApi:function(reservationReference){
                console.log("Get reservation reference here");
                var self = this;
                axios.get('/api/reservations/reference/'+this.viewValue.trim())
                .then(function(response){
                    if(response.data.reservation){
                        this.reservation = response.data.reservation;
                        this.emailLink = "mailto:" + this.reservation.user.email;
                        this.reasonKey = this.reasonBookingTopUp;
                        this.recordKey = this.reservation.reservation_key;
                        this.field.value = { reason_key: this.reasonKey, record_key: this.recordKey };
                        this.viewValue = this.reservation.reference;
console.log("updated live field", this.field);
                    }
                    else {
                        console.log("NO reservation found");
                        this.reasonKey = this.reasonAdHoc;
                        this.recordKey = null;
                    }
                }.bind(this))
                .catch(function(exception){
                    // All we can do on failure, including a 404, is to revert to ad-hoc
                    // as we didn't have any other way to find and associate the record
                    console.log(exception);
                    self.reasonKey = self.reasonAdHoc;
                    self.recordKey = null;
                    self.field.value = { reason_key: self.reasonKey, record_key: self.recordKey };
                });
            },

            

            getInvoiceFromApi:function(invoiceReference){
                console.log("Get invoice reference here");
                var self = this;
                axios.get('/api/accounts/invoices/reference/' + this.viewValue.trim())
                .then(function(response){
                    if(response.data.invoice){
                        this.invoice = response.data.invoice;
                        // At this time there's not the infrastructure in the database to record what the invoice is for
                        // so this very crude way of flagging the reason code is all that we have available right now
                        this.reasonKey = this.invoice.reference.includes("INV-AF") ? this.reasonHotelAnnualFee : this.reasonHotelArrears;
                        
                        this.recordKey = this.invoice.invoice_portal_key;

                        this.field.value = { reason_key: this.reasonKey, record_key: this.recordKey };
console.log("will change reason key" + this.reasonKey);
                    }
                    else {
                        console.log("No invoice fgound");
                        this.reasonKey = this.reasonAdHoc;
                        this.recordKey = null;
                    }
                }.bind(this))
                .catch(function(exception){
                    // All we can do on failure, including a 404, is to revert to ad-hoc
                    // as we didn't have any other way to find and associate the record
                    console.log(exception);
                    console.log(this);
                    console.log(self);
                    self.reasonKey = self.reasonAdHoc;
                    self.recordKey = null;
                    self.field.value = { reason_key: self.reasonKey, record_key: self.recordKey };
                });
            },

            updateField:function(){
                console.log(this.viewValue);
                this.getRelatedRecordType();
            }
        },
    }
</script>
