<template>
    <v-flex v-if="value">
        {{ value }}
        <v-btn v-on:click="triggerUpdate">
            <v-icon>update</v-icon>
        </v-btn>
    </v-flex>
</template>

<script>
/**
 * Triggers a request to the server to update this room type
 */
export default {
    name: 'ies-ical-feed-refresh',
    props: ['field', 'value'],
    data () {
        return {}
    },

    methods: {
        triggerUpdate: function(){
            var url = "/api/ical/room-type/update?roomKey=" + this.value;
console.log(this);
console.log(url);

            axios.get(url)
            .then(function(response){
                alert("Update triggered");
            }.bind(this));
        },
    }
}
</script>
