var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      !_vm.permission
        ? _c(
            "v-alert",
            { attrs: { type: "info", dismissable: "", value: "true" } },
            [_vm._v("You do not have permission to edit this page")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.metadata.info
        ? _c(
            "v-alert",
            { attrs: { type: "info", dismissable: "", value: "true" } },
            [_vm._v(_vm._s(_vm.metadata.info))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.metadata.warn
        ? _c(
            "v-alert",
            { attrs: { type: "warn", dismissable: "", value: "true" } },
            [_vm._v(_vm._s(_vm.metadata.warn))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.metadata.error
        ? _c(
            "v-alert",
            { attrs: { type: "error", dismissable: "", value: "true" } },
            [_vm._v(_vm._s(_vm.metadata.error))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { id: "importForm" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _vm.uxStage == "choose"
            ? _c(
                "v-card",
                { staticClass: "no-print" },
                [
                  _c("v-card-text", [
                    _c("p", [_vm._v("Please choose a file to import (.csv)")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("input", {
                        attrs: { type: "file", accept: "text/csv" },
                        on: {
                          change: function ($event) {
                            return _vm.fileSelected($event)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ies-green",
                          on: { click: _vm.uploadFile },
                        },
                        [
                          _c("v-icon", [_vm._v("cloud_upload")]),
                          _vm._v(" Upload"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.uxStage == "validated"
            ? _c(
                "v-card",
                { staticClass: "no-print" },
                [
                  _c(
                    "v-card-text",
                    [
                      _vm._v(
                        "\n                The following fields were identified in the file:\n\n                "
                      ),
                      _c(
                        "ul",
                        _vm._l(
                          _vm.validation.identifiedFieldNames,
                          function (identifiedField, key) {
                            return _c("li", { key: key }, [
                              _vm._v(
                                "'" +
                                  _vm._s(identifiedField.fileFieldName) +
                                  "' as " +
                                  _vm._s(identifiedField.schema.modelName) +
                                  " " +
                                  _vm._s(identifiedField.schema.fieldName)
                              ),
                            ])
                          }
                        ),
                        0
                      ),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "There are " +
                            _vm._s(_vm.validation.numRecordsFound) +
                            " records in the file"
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.validation.validation.length > 0
                        ? _c(
                            "v-alert",
                            {
                              attrs: {
                                type: "error",
                                dismissable: "",
                                value: "true",
                              },
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  "Validation failed with " +
                                    _vm._s(_vm.validation.validation.length) +
                                    " problems.  Please correct the problems identified below and resubmit the file"
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                _vm._l(
                                  _vm.validation.validation,
                                  function (failedCell, key) {
                                    return _c("div", [
                                      _vm._v(
                                        "\n                            Row " +
                                          _vm._s(failedCell.rowIndex + 1) +
                                          " Column " +
                                          _vm._s(failedCell.columnIndex + 1) +
                                          ": " +
                                          _vm._s(failedCell.message) +
                                          "\n                        "
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.validation.validation.length == 0
                        ? _c("div", [
                            _vm._v(
                              "If this is what you expected to see then you can continue to import the data to the database using the controls below"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-btn", { on: { click: _vm.resetForm } }, [
                        _vm._v("Upload New File"),
                      ]),
                      _vm._v("\n\n                Or\n\n                "),
                      _c("v-text-field", {
                        attrs: {
                          label: "Match records based on the field:",
                          name: "matchField",
                        },
                        model: {
                          value: _vm.matchField,
                          callback: function ($$v) {
                            _vm.matchField = $$v
                          },
                          expression: "matchField",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ies-green",
                          attrs: {
                            disabled: _vm.validation.validation.length > 0,
                          },
                          on: { click: _vm.acceptValidation },
                        },
                        [_vm._v("Import This Data")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.uxStage == "complete"
            ? _c(
                "v-card",
                { staticClass: "no-print" },
                [
                  _c("v-card-text", [
                    _vm._v(
                      "\n                The data file will now be imported.  This may take a few minutes\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-btn", { on: { click: _vm.resetForm } }, [
                        _vm._v("Upload New File"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ies-green",
                          attrs: { href: "/mailing-list/list" },
                        },
                        [_vm._v("View Records")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }