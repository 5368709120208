var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "row-sm": "", column: "" } },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("label", { attrs: { for: this.field.fieldName } }, [
              _vm._v(" " + _vm._s(this.field.schema.displayName) + ": "),
            ]),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.input,
                    expression: "input",
                  },
                ],
                staticStyle: { width: "200px" },
                attrs: {
                  name: this.field.fieldName,
                  required: this.field.required,
                  disabled: this.field.disabled,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.input = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.updateField()
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { disabled: "", value: "" } }, [
                  _vm._v("Select type of user"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.typesOfUser, function (value, key) {
                  return _c("option", { domProps: { value: value } }, [
                    _vm._v(_vm._s(key)),
                  ])
                }),
              ],
              2
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }