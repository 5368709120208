var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Voucher Summary")]),
      _vm._v(" "),
      _c(
        "v-expansion-panels",
        [
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _vm._v(
                  "\n            Date: " +
                    _vm._s(_vm.date) +
                    " Voucher Type: " +
                    _vm._s(_vm.voucherType) +
                    " Grouped: " +
                    _vm._s(_vm.groupByDay) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-expansion-panel-content",
                { attrs: { value: 1 } },
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "", "ma-4": "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c("v-date-picker", {
                            attrs: {
                              landscape: true,
                              color: "lime",
                              type: "month",
                            },
                            model: {
                              value: _vm.date,
                              callback: function ($$v) {
                                _vm.date = $$v
                              },
                              expression: "date",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        [
                          _c("v-switch", {
                            attrs: {
                              label: `Group by day ${_vm.groupByDay.toString()}`,
                            },
                            model: {
                              value: _vm.groupByDay,
                              callback: function ($$v) {
                                _vm.groupByDay = $$v
                              },
                              expression: "groupByDay",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "v-radio-group",
                            {
                              model: {
                                value: _vm.voucherType,
                                callback: function ($$v) {
                                  _vm.voucherType = $$v
                                },
                                expression: "voucherType",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "Digital", value: 2 },
                              }),
                              _vm._v(" "),
                              _c("v-radio", {
                                attrs: { label: "Physical", value: 1 },
                              }),
                              _vm._v(" "),
                              _c("v-radio", {
                                attrs: { label: "Both", value: 3 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "", "ma-4": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.getVouchers()
                            },
                          },
                        },
                        [_vm._v("Submit")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "", "ma-2": "" } },
                    [
                      _c("export-csv", {
                        attrs: {
                          filename: "accounts-vouchers",
                          data: _vm.vouchers,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-data-table",
        {
          staticClass: "elevation-1",
          attrs: {
            headers: _vm.headers,
            items: _vm.vouchers.data,
            "hide-default-footer": "",
          },
          scopedSlots: _vm._u([
            {
              key: "item",
              fn: function (props) {
                return [
                  _c("tr", [
                    _c("td", { staticClass: "text-xs-center" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formattedDateDDMMYYYY(props.item.creation_date)
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-xs-center" }, [
                      _vm._v(_vm._s(props.item.purchaser_email)),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-xs-center" }, [
                      _vm._v(_vm._s(props.item.voucher_code)),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-xs-center" }, [
                      _vm._v(
                        _vm._s(_vm._f("voucher-type")(props.item.voucher_type))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-xs-center" }, [
                      _vm._v("£" + _vm._s(props.item.voucher_value.toFixed(2))),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-xs-center" }, [
                      _vm._v(
                        "£" + _vm._s(props.item.transaction_value.toFixed(2))
                      ),
                    ]),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("template", { slot: "footer" }, [
            _c("td", { attrs: { colspan: "3" } }),
            _vm._v(" "),
            _c("td", { staticClass: "font-weight-bold text-xs-center" }, [
              _vm._v("\n                Total\n            "),
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "font-weight-bold text-xs-center" }, [
              _vm._v(
                "\n                £" +
                  _vm._s(_vm.vouchers.total_value.toFixed(2)) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "font-weight-bold text-xs-center" }, [
              _vm._v(
                "\n                £" +
                  _vm._s(_vm.vouchers.total_transaction.toFixed(2)) +
                  "\n            "
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }