<template>
    <v-col cols="12" md="4" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">edit</v-icon> Extra Notes
            </v-card-title>

            <v-card-text>
                <v-container>
                    <div v-if="hasProblem">Sorry, this content is not working</div>
                    <div v-if="loaded && !hasProblem">
                        <v-textarea v-model="hotel.notes"></v-textarea>
                        <div style="text-align: right;">
                            <v-btn class="ies-green" v-on:click="saveNotes"><v-icon>save</v-icon> Save</v-btn>
                        </div>
                    </div>
                </v-container>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<script>
    /**
     * Display buttons linking through to a hotels' pages
     */
    export default {
        props:['pageid'],

        data: function() {
            return {
                hotel: {},
                loaded: false,
                hasProblem: false
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            this.loadHotel();
        },

        methods: {
            loadHotel: function(){

                try {
                    // I am hooking this up to the summary info URL to avoid setting a new one right now
                    // but perhaps one will become necessary anyway
                    axios.get('/api/hotel/' + this.pageid + '/summary-information/')
                        .then(function(response){
                           this.hotel = response.data.hotel;

                           this.showProgressIcon = false;
                           this.loaded = true;
                           console.log(response);
                           console.log(this.hotel);
                        }.bind(this));
                    }
                catch(exception){
                    console.log(exception);
                    this.hasProblem = true;
                }
            },

            saveNotes: function(){
                var requestConfig = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };
                axios.post('/api/hotel/' + this.pageid + '/extra-notes', {action:"update", notes: this.hotel.notes}, requestConfig)
                .then((response)=>{
                    if (response.data.success){
                        alert("Saved");
                    }
                    else {
                        alert("A problem occurred and the notes could not be updated");
                    }
                });
            }
        }
    }
</script>
<style>
.parity-good {
    background-color: rgb(204,255,204);
    border-color: solid 1px green;
}

.parity-partial {
    background-color: rgb(255,255,204);
    border: solid 1px yellow;
}

.parity-bad {
    background-color: rgb(255,200,200);
    border: solid 1px red;
}
</style>
