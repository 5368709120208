<template>
    <v-col cols="12" md="3">
    <v-card class="ma-1">
        <v-card-title class="title font-weight-black">
            <v-icon class=pr-3>text_format</v-icon> Site Texts
        </v-card-title>

        <v-card-text>
            <v-btn block href="/site-texts/create">Add New Site text</v-btn>
            <v-btn block href="/site-texts/list">Site Text All</v-btn>
            <v-btn block href="/site-texts/list?s=qs&value=home">Site Text Homepage</v-btn>
            <v-btn block disabled href="">Site Text My Escapes</v-btn>
            <v-btn block disabled href="">Site Text TG & PTS</v-btn>
            <v-btn block disabled href="">Site Text News</v-btn>
            <v-btn block href="/site-texts/list?s=qs&value=about_us">Site Text About Us</v-btn>
            <v-btn block href="/site-images/list">Site Images</v-btn>
        </v-card-text>
    </v-card>
    </v-col>
</template>

<script>
    /**
     * Display buttons linking through to hotels' pages
     */
    export default {
        props:[],

        data: function() {
            return {
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){},

        methods: {},

        computed: {}
    }
</script>
