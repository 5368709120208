<template>
  <v-col cols="12" md="6" lg="4" ma-1>
    <v-card class="ma-2">
        <v-card-title class="title font-weight-black">
            <v-icon class=pr-3>phone</v-icon> Communications
        </v-card-title>
        <v-container>
            <v-layout column>
                <v-flex>
                    <v-btn href="/messagings/list" block>Message Templates</v-btn>
                </v-flex>

                <hr>

                <v-flex>
                    <v-btn href="/email-queue/list" block>Email Queue</v-btn>
                </v-flex>
                <v-flex>
                    <v-btn href="/emails-sent/list" block>Emails Sent</v-btn>
                </v-flex>
                <v-flex>
                    <v-btn href="/emails-failed/list" block>Emails Failed</v-btn>
                </v-flex>

                <hr>

                <v-flex>
                    <v-btn href="/sms-queue/list" block>SMS Queue</v-btn>
                </v-flex>
                <v-flex>
                    <v-btn href="/sms-sent/list" block>SMSs Sent</v-btn>
                </v-flex>
                <v-flex>
                    <v-btn href="/sms-failed/list" block>SMSs Failed</v-btn>
                </v-flex>
                <hr>

                <v-flex>
                    <v-btn href="/communications-service-providers/list" block>Service Providers</v-btn>
                </v-flex>
                <v-flex>
                    <v-btn href="/communication-types/list" block>Communication Types</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </v-card>
  </v-col>
</template>

<script>
    export default {
        name: "QuickButtons"
    }
</script>

<style scoped>

</style>
