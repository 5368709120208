<template>
    <v-flex>{{ groups }}</v-flex>
</template>

<script>
/**
 * Outputs a list of display groups for which a hotel's facilities are available.
 * Represents the values stored in the field which is an integer, but the output is a string
 *
 */
export default {
    name: 'ies-display-groups',
    props: ['field', 'value'],
    data () {
        return {}
    },

    computed: {
        /**
         * On-site       = 1
         * In-room       = 2
         */
        groups: function(){
            var groupsAsString = "";
            if (this.value & 1){
                groupsAsString += 'On-site';
            }

            if (this.value & 2){
                if (groupsAsString){
                    groupsAsString += ', ';
                }
                groupsAsString += 'In-room';
            }
            return groupsAsString;
        }
    }

}
</script>
