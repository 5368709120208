var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", sm: "6", md: "4", lg: "2", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("alarm")]),
              _vm._v("World Clock\n            "),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-checkbox", {
            staticClass: "pa-2",
            attrs: { label: "British Summer Time", "hide-details": "" },
            model: {
              value: _vm.bstCheckbox,
              callback: function ($$v) {
                _vm.bstCheckbox = $$v
              },
              expression: "bstCheckbox",
            },
          }),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("v-select", {
            staticClass: "pa-2",
            attrs: { items: _vm.zoneList, "hide-details": "" },
            model: {
              value: _vm.zoneSelectionOne,
              callback: function ($$v) {
                _vm.zoneSelectionOne = $$v
              },
              expression: "zoneSelectionOne",
            },
          }),
          _vm._v(" "),
          _c("v-card-text", { staticClass: "text-xs-center title" }, [
            _vm._v(
              "\n                " + _vm._s(_vm.clockOne) + "\n            "
            ),
          ]),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c("v-select", {
            staticClass: "pa-2",
            attrs: { items: _vm.zoneList, "hide-details": "" },
            model: {
              value: _vm.zoneSelectionTwo,
              callback: function ($$v) {
                _vm.zoneSelectionTwo = $$v
              },
              expression: "zoneSelectionTwo",
            },
          }),
          _vm._v(" "),
          _c("v-card-text", { staticClass: "text-xs-center title" }, [
            _vm._v(
              "\n                " + _vm._s(_vm.clockTwo) + "\n            "
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }