<template>
    <v-container>
        <v-layout row wrap>
            <v-col v-if="this.field.schema.displayName != 'none'" cols="2">
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}:<span v-if="this.field.required">*</span> </label>
            </v-col>

			<v-col cols="1">
				<span v-on:click="toggleValidationGuide" v-if="this.field.validation && this.field.validation.length"><v-icon>information_outline</v-icon></span>
			</v-col>
			
            <v-col cols="3">
                <v-btn v-on:click="chooseHotel" 
                       v-show="!shouldShowPicker">{{ buttonLabel }}</v-btn>
                <v-btn v-on:click="clearHotel" 
                       v-show="selectedHotel">Clear</v-btn>
				            </v-col>

			<v-col cols="6">
				<v-alert type="info" v-if="this.showValidationGuide && this.field.validation && this.field.validation.length">
                	<div v-for="(rule, ruleIndex) in this.field.validation" :key="ruleIndex">{{ validationRuleAsString(rule) }}</div>
            	</v-alert>
			</v-col>

            <v-col cols="10" v-if="shouldShowPicker">
                <v-container>
                    <v-layout row wrap>
 
                        <v-col cols="10">
                            <v-text-field
                                v-model="hotelViewName" 
                                v-on:keyup="updateHotelName()"
								:required="this.field.required" 
                             ></v-text-field>

                             <div class="selectable-hotels-results" v-if="hotelOptions.length && shouldShowHotels">
                                 <ul class="selectable-hotels-list">
                                     <li v-for="hotel in hotelOptions" v-on:click="selectHotel(hotel.hotel_key)">({{ hotel.hotel_code }}) {{ hotel.hotel_name }}</li>
                                 </ul>
                             </div>
                         </v-col>
<!--
                          <v-col cols="12">
                              <v-btn v-on:click="done">Done</v-btn>
                          </v-col>
-->
                   </v-layout>
               </v-container>
           </v-col>

           <input type="hidden" :name="field.fieldName" :value="field.value">
			<v-alert type="error" dismissable value="true" v-if="errorMessages && errorMessages.length">
                <div v-for="(error, index) in errorMessages" :key="index" >{{ error.reason }}*</div>
            </v-alert>
           <!-- <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
               <li v-for="error in field.errors">
                   {{ error }}
               </li>
           </v-alert> -->
        </v-layout>
    </v-container>
</template>

<script>
    /**
     * Allows the selection of a hotel ID by choosing its name or code from
     * a selection downloaded from the API on creation
     *
     * @todo refactor, make more efficient
     */
    export default {
        name: 'ies-hotelKey',
        props: ['field', 'configuration'],
        data() {
            return {
				showValidationGuide: false,

				errorMessages: [],

                /** string view model */
                hotelViewName: '',

                /** array of hotel options presented to the user */
                hotelOptions: [],

                /** data model of all supported hotels */
                hotels: [],

                /** view model to determine whether the hotel  picker should display */
                shouldShowPicker: true,

                /** The hotel chosen by the user */
                viewValue: parseInt(this.field.value),

                selectedHotel: null,

                /** For where the component is used in event mode to talk to other components */
                config: {'shouldEmitOnSelect': false, 'selectEventName': ''}
            }
        },
        created:function() {
            this.getHotels();

            if (this.configuration){
                var config = JSON.parse(this.configuration);
                console.log(config);
                if (config.shouldEmitOnSelect && config.selectEventName){
                    this.config.shouldEmitOnSelect = config.shouldEmitOnSelect;
                    this.config.selectEventName = config.selectEventName;
                }
            }

            // We may want to move this into the configuration rather than assume all of the instances may need to listen
            this.addEventListeners();
        },

        methods: {
            addEventListeners: function(){
                var self = this;

            	this.$root.$on('Field: set errors', function(payload){
                	console.log("TEXT: I heard ", payload);
                	if (payload.modelName = self.field.modelName && payload.fieldName == self.field.fieldName){
                   		self.errorMessages = payload.errors;
                    	console.log("TEXT: I set ", self.field.errors);
                	}
            	});

			//	updateField: function(){
            //		this.field.value = this.input;
            //		this.errorMessages = [];
            //		this.$root.$emit('Edit: Update Field', {'id': 'notset', 'field': this.field, 'value': this.field.value});
        	//	},

                this.$root.$on('Hotel Key: set', function(payload){
                    console.log("Have heard ", payload);
                    if (payload.hotelKey){
                        self.selectHotel(payload.hotelKey);
                    }
                    else {
                        self.clearHotel();
                    }
                });
            },
			
			toggleValidationGuide: function(){
    	        this.showValidationGuide = !this.showValidationGuide;
	        },

			validationRuleAsString: function(rule){
            	console.log("explain rule");
            	var explainer = '';
           		switch(rule.ruleType){
            	    case 'minLength': explainer = "The text must be at least " + rule.value + " characters long"; break;
        	        case 'maxLength': explainer = "The text must not be more than " + rule.value + " characters long"; break;
    	            case 'minValue': explainer = "The value must be more than " + rule.value; break;
	                case 'maxValue': explainer = "The value must be less than " + rule.value; break;
                	case 'notEmpty': explainer = "The field must not be empty"; break;
                	case 'regExp': explainer = "Pattern: " + rule.value; break;
                	default:
                	    explainer = rule.ruleType + ' ' + rule.value;
            	}
            	console.log(explainer);
            	return explainer;
        	},

            getHotels:function(){
                var self = this;
                axios.get('/api/hotelsWithRooms')
                .then(function(response){
                    this.hotels = response.data;
                    this.hotels.forEach(function(hotel){
                        if (hotel.hotel_key == self.field.value){
                            self.selectedHotel = hotel;

                            // Set the view models so that it is reflected to the users
                            self.hotelViewName = hotel.hotel_name;
                        }
                    });
                }.bind(this));
            },

            updateHotelName: function(){
                if (this.hotelTypeTimer){
                    clearTimeout(this.hotelTypeTimer);
                }
                this.hotelTypeTimer = setTimeout(this.refineHotelOptions, 333);
           },


            refineHotelOptions: function(){
                var self = this;
 
                var hotelOptions = [];
                this.hotels.forEach(function(hotel){
                    if (hotel.hotel_code.toLowerCase().includes(self.hotelViewName.toLowerCase()) || hotel.hotel_name.toLowerCase().includes(self.hotelViewName.toLowerCase())){
                        hotelOptions.push(hotel);
                    }
                });
                this.hotelOptions = hotelOptions;

                // If there is only one hotel left in the list of options then we select it
                if (this.hotelOptions.length === 1){
                    this.selectedHotel = this.hotelOptions[0];
                    this.updateFieldValue();
                }
                this.shouldShowHotels = true;
            },

            selectHotel: function(hotelKey){
                var self = this;
                this.hotels.forEach(function(hotel){
                    if (hotel.hotel_key === hotelKey){
                        self.selectedHotel = hotel;
                        self.hotelViewName = hotel.hotel_name;
                        self.updateFieldValue();
                    }
                });
                this.shouldShowHotels = false;
            },

            chooseHotel: function(){
                this.shouldShowPicker = true;
            },

            clearHotel: function(){
                this.selectedHotel = null;
                this.hotelViewName = '';
                this.field.value = null;

                if (this.config.shouldEmitOnSelect){
                    console.log("I should emit ", this.config.selectEventName);
                    this.$root.$emit(this.config.selectEventName, {hotelKey: null});
                }
            },

            done: function(){
                this.shouldShowPicker = false;
            },

            updateFieldValue: function(){
                this.field.value = this.selectedHotel.hotel_key;

                console.log("updatefieldvalue on select: ", this.config);
                if (this.config.shouldEmitOnSelect){
                    console.log("I should emit ", this.config.selectEventName);
                    this.$root.$emit(this.config.selectEventName, {hotelKey: this.field.value});
                }
            }
        },
        watch: {
            viewValue: function (newValue) {
            }
        },
        computed: {
            buttonLabel: function(){
                return this.selectedHotel ? "(" + this.selectedHotel.hotel_code + ") " + this.selectedHotel.hotel_name : "Choose";
            }
        }

    }
</script>

<style>
.selectable-hotels-results{
    background-color: white;
    border: solid 1px #f5f5f5;
    width: auto;
    min-width: 20rem;
    max-height: 10rem;
    overflow-y: auto;
    position: absolute;
    z-index: 1;
}

.selectable-hotels-list {
  list-style-type: none;
}

.selectable-hotels-list li {
    cursor: pointer;
    padding: 3px;
}

.selectable-hotels-list li:hover {
    background-color: #f5f5f5;
}
</style>
