<template>

    <v-container fluid>
       <v-layout row-sm wrap>

        <div class="drag-drop-image"
             @drop="dropFiles" 
             @dragenter="checkDrop" 
             @dragover="checkDrop"
             v-if="shouldShowDropBox">
             <span class="close-button"><v-btn v-on:click="closeDropBox()"><v-icon>cancel</v-icon></v-btn></span>
             <h2><v-icon>file_upload</v-icon> Drag your images here to upload</h2>
        </div>

<v-flex xs12>
        <v-card class="pa-0">
            <v-card-title><v-icon>upload</v-icon> Upload</v-card-title>
            <v-card-text class="pa-0">
  <v-expansion-panels>
    <v-expansion-panel model="openPanels">
        <v-expansion-panel-header><b><v-icon>cloud_upload</v-icon> Upload New Images</b></v-expansion-panel-header>
        <v-expansion-panel-content>
    	    <v-container fluid>
	    <v-layout row-sm wrap>

            <v-col cols="12" sm="6" md="4">
            <fieldset>
                <legend>Photos From Your Device</legend>

                <v-flex>
                    <v-card-text>Select files for upload:
                        <input type="file" accept="image/jpeg" @change="imageFileSelected($event)" multiple>
                    </v-card-text>
                </v-flex>
                <v-card-text>Selected Files:</v-card-text>
                <v-flex v-for="(file, index) in selectedFiles" v-bind:key="index">
                    <v-card-text>{{file.file.name}} <v-btn @click="removeFile(index)">Deselect</v-btn></v-card-text>
                </v-flex>

            </fieldset>
            </v-col>

            <v-col cols="12" sm="6">
            <fieldset>
                <legend>Properties</legend>

                <v-flex xs12>
                    <label for="display">Display in Slideshows:</label>
                    <input name="display" 
                       id="display"
                       type="checkbox" 
                       v-model="allImages.display_slideshow"
                    >
                </v-flex>

                <v-flex xs12>
                    <v-text-field
                        label="Sort Order in Slideshows:"
                        name="sort_order"
                        v-model="allImages.sort_order"
                    ></v-text-field>
                </v-flex>


                <v-flex xs12>
                <v-select
                    :items="imageTypes"
                    v-model="allImages.imageType"
                    label="Select Type of Image"
                    item-text="text"
                    item-value="value"
                    dense
                    id="image-type"
                ></v-select>
                </v-flex>

                <v-flex xs12>
                    <v-text-field
                        label="Image Caption:"
                        name="caption"
                        v-model="allImages.caption"
                    ></v-text-field>
                </v-flex>

                <v-flex xs12 v-if="selectedFiles.length < 2">
                    <v-text-field
                        label="Copyright Information:"
                        name="copyright"
                        v-model="allImages.copyright"
                    ></v-text-field>
                </v-flex>

                <v-flex xs12 v-if="selectedFiles.length < 2">
                    <v-text-field
                        label="Description:"
                        name="description"
                        v-model="allImages.description"
                    ></v-text-field>
                </v-flex>
            </fieldset>
            </v-col>


          <v-col cols="12">
            <fieldset>
               <legend>Relationships</legend>
               <v-layout row wrap>

               <v-col cols="12"
                   v-show="shouldShowHotelPicker">
                   <ies-hotel-and-room-key 
                       :field="selectRoomField"
                       configuration='{"shouldEmitOnSelect":true, "selectHotelEventName":"Image Uploader: select hotel", "selectRoomEventName": "Image Uploader: select room type"}'></ies-hotel-and-room-key>
               </v-col>

               <v-col cols="12"
                    v-show="shouldShowRegionPicker">
                   <v-select
                    v-model="allImages.region_key"
                    @change="regionChosen"
                    label="Region"
                    :items="regions"
                    autocomplete
                    ></v-select>
                    <v-btn @click="clearRegion" :disabled="!allImages.region_key">Clear</v-btn>
               </v-col>
               
               </v-layout> 
            </fieldset>
            </v-col>

            <v-col cols="12">
                <v-btn @click="uploadToLibrary" class="ies-green"><v-icon>save</v-icon> Save</v-btn>
            </v-col>

            </v-layout>
            </v-container>

        </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</v-card-text>
</v-card>
</v-flex>




        </v-layout>
    </v-container>
</template>

<script>

    //import EventBus from '@/eventBus';
    import hotelKey from '../inputs/HotelKey';
    import hotelRoomTypeKey from '../inputs/HotelRoomTypeKey';
    import hotelAndRoomKey from '../inputs/HotelAndRoomKey';


/**
 * A simple image uploader tool for pushing one or more image files from the local
 * computer to the photo library on the server, and giving each of them the data
 * attributes entered by the user
 *
 * This component is designed as a bare bones initial device to get basic functionality
 * and can be further modified or replaced at a later date with something more
 * sophisticated
 */
export default {
    name: 'ies-image-uploader',
    props: [],
    components: { 'ies-hotel-key': hotelKey, 'ies-hotel-room-key': hotelRoomTypeKey, 'ies-hotel-and-room-key': hotelAndRoomKey },
    data () {
        return {
            /** @var array regions - all selectable regions to which the images can be assigned */
            regions: [],

            hotelRoomTypes: [],

            /** @var array selectedFiles  */
            selectedFiles: [],

            /** @var boolean shouldShowDropBox a boolean flag dictating whether or not the drag n drop box should display */
            shouldShowDropBox: false,
            shouldShowHotelPicker: true,
            shouldShowRegionPicker: true,

            openPanels: [],

            /** @var {} allImages - A copy of the image field being edited, used to allow Vue to react to changes on the data model with changes in the computed methods without the need for watchers */
            allImages:     [],

            /** @var [] imageTypes - An array of image types for the user to choose between when searching the photo library */
            imageTypes:     [],

            selectHotelField: {fieldName: "selectHotel", value: "", schema:{displayName: "Hotel"}},
            selectRoomField: {fieldName: "selectHotelRoom", value: "", schema:{displayName: "Room Type"}},

            /** @var {} */
            imageTypeInitials: {
                                    '1':  'O',  // Overview
                                    '2':  'R',  // rooms
                                    '3':  'E',  // eating
                                    '4':  'A',  // activities
                                    '5':  'F',  // features
                                    '6':  'S',  // site_photo
                                    '7':  'H',  // home
                                    '9':  'PH', // photo_library
                                    '10': 'OL', // overview_large
                                    '11': 'HL', // home_large
                                    '12': 'RL', // rooms_large
                                    '13': 'EL', // eating_large
                                    '14': 'AL', // activities_large
                                    '15': 'F',  // features_large
                                    '16': 'SL', // site_photo_large
                                    '17': 'K',  // kids
                                    '18': 'KL', // kids_large
                                    '19': 'V',  // physical_voucher
                                },
        }
    },

    /**
     * On mounting the component we are cloning the field to this.data
     * so Vue will be reactive to changes and automatically trigger the
     * computed methods to run without us needing to set up numerous
     * watchers to handle everything we want to happen now and in the future
     */
    mounted: function(){
        this.getHotelRoomTypes();
        this.getRegions();
        this.loadImageTypes();
        this.addEventListeners();
    },

    methods: {
        addEventListeners: function(){
            var self = this;
            // Register an event listener so that any time the user drags anything
            // over the page it tells the uploader tool to display the box to drop them into
            window.document.body.addEventListener("dragenter", this.openDropBox);
            
            this.$root.$on('Image Uploader: select hotel', function(payload){
                console.log("iu: Have heard ", payload);
                self.allImages.hotel_key = payload.hotelKey;
                self.shouldShowRegionPicker = self.allImages.hotel_key ? false : true;
console.log("iu: should emit " + 'Room Type Key: set hotel');
                //lf.$root.$emit('Room Type Key: set hotel', {'id': 'uploadRoomkey', 'hotelKey': payload.hotelKey});
            });

            this.$root.$on('Image Uploader: select room type', function(payload){
                console.log("Have heard ", payload);
                self.allImages.hotel_room_key = payload.roomTypeKey;

                // Ask the hotel key picker to set its own value to 
console.log("iu: should emit " + 'Hotel Key: set');
                //self.$root.$emit('Hotel Key: set', {'id': 'uploadhotelkey', 'hotelKey': payload.hotelKey});
                // it may need to be tweaked a little to allow null values, 
                //self.shouldShowHotelPicker = self.allImages.hotel_room_key ? false : true;
            });
        },

        clearRegion: function(){
            this.allImages.region_key = null;
            this.regionChosen();
        },

        regionChosen: function(){
            console.log("region changed");
            //this.shouldShowHotelPicker = this.allImages.region_key ? false : true;
        },

        getRegions:function(){
            axios.get('/api/selectable-regions')
            .then(function(response){
                var regions = [];
                response.data.regions.forEach(function(record){
                    regions.push({ text: record.region_name, value: record.region_key });
                });
                this.regions = regions;
            }.bind(this));
        },

        getHotelRoomTypes:function(){
            // it may need to be tweaked a little to allow null values, 
                axios.get('/api/hotelsWithRooms')
                .then(function(response){
                    var hotelRoomTypes = [];
                    response.data.forEach(function(hotel){
                        hotel.rooms.forEach(function(roomType){
                            hotelRoomTypes.push({ text: '(' + hotel.hotel_code + ') ' + roomType.name, value: roomType.hotel_room_key });
                        });
                    });
                    this.hotelRoomTypes = hotelRoomTypes;

                }.bind(this));
        },

        /**
         * Requests all the different types of images recognised by the system, used for searching
         * the photo library
         */
        loadImageTypes: function(){
            var self = this;

            axios.get('api/images/types')
            .then(function(response){
                self.imageTypes = [ { text: 'Any', value: '' } ];
                response.data.forEach(function(imageType){
                    self.imageTypes.push( { text: imageType.display_name, value: imageType.image_type_key });
                });
            }.bind(this))
            .then(function(){
                // fail
            }.bind(this));
        },

        /**
         * Event handler method for when the user has selected a local
         * image file from their device
         */
        imageFileSelected: function(event){
            for (var index = 0; index < event.target.files.length; index++) {
                var imageFile = event.target.files[index];
                this.selectedFiles.push({ 'file': imageFile });
            }
        },

        removeFile: function(selection){
            this.selectedFiles.splice(selection, 1);
        },

        checkDrop: function(event){
            event.preventDefault();
            event.stopPropagation();
        },

        dropFiles: function(event){
            event.preventDefault();
            event.stopPropagation();
            for (var index = 0; index < event.dataTransfer.files.length; index++){
                this.selectedFiles.push({ file: event.dataTransfer.files[index] });
            }

            // Auto close the drop box
            this.closeDropBox();
            this.openPanels = [0];
        },

        openDropBox: function(){
            this.shouldShowDropBox = true;
        },

        closeDropBox: function(){
            this.shouldShowDropBox = false;
        },

        /**
         * Uploads the selected files to the server as a batch with the data
         * entered by the user cloned to each of them so the user needs only add
         * the data once but the server can receive explicit copies
         */
        uploadToLibrary: function(){
            if (this.selectedFiles.length > 0) {
                let payload = new FormData();
                this.selectedFiles.forEach(selectedFile => {
                    payload.append(selectedFile.file.name, selectedFile.file);
                });

                let details = {};
                Object.assign(details, this.allImages);

                //console.log(details);

                Object.keys(details).forEach(function (detail) {
                    //console.log(detail);
                    payload.append(detail, details[detail]);
                });


                axios.post('api/images', payload)
                    .then(response => {
                        this.selectedFiles = [];
                        alert("Files uploaded");

                        // Respond to the successful upload by requesting the image browser search for the hotel ID, if one is set
                        if (this.allImages.hotel_key){
                            var eventPayload = {'id': 'imageUploader', 'filters': [ {'fieldName':'hotelKey', 'value': this.allImages.hotel_key } ]};
                            this.$root.$emit('Image Browser: search', eventPayload);
                        }
                        else {
                            this.$root.$emit('List: Search', {'id': 'imageUploader', 'filters': [] });
                        }
                }).catch(function (error) {
                    alert("File Upload Error: " + error);
                });
            } else {
                alert("No files to upload!");
            }
        },
    },

}
</script>
<style lang="scss" scoped>
    .bold {
        font-weight: bold;
    }

    .clickable:hover {
        cursor: pointer;
    }

    .drag-drop-image {
        background-color: darkgray;
        border: dashed 5px lime;
        position: fixed;
        display: block;
        padding: 2rem;
        top: calc(5vh + 65px);
        left: 5vw;
        width: 90vw;
        height: calc(90vh - 64px);
        z-index: 10;
    }

    .close-button {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
    }
</style>
