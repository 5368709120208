
<template>
    <div>

        <div class="white-out" @click="close()">&nbsp;</div>
        
        <div class="edit-form" 
                @keyup.enter="submitEditForm()"
                @keyup.esc="close()"
            >
            <h2>{{ editForm.title }}</h2>
            <p v-if="selectedDateFrom && selectedDateUntil">
                <strong>
                    {{ selectedDateRange}}
                </strong>
            </p>
            <p>{{ editForm.taskDescription }}</p>
    
            <p v-if="editForm.errorMessage" class="red--text">
                <v-icon color="red">warning</v-icon> {{ editForm.errorMessage }}.
            </p>

            <div v-if="openEditAvailabilityDates || openEditRateDates" class="display-flex mt-3 mb-3">
                <template v-for="(day, key) in weekdays">
                    <span class="mr-2">
                        <label :for="key">{{ day }}</label>
                        <input type="checkbox" :id="key" v-model="selectedWeekdays[key]">
                    </span>
                </template>
            </div>
    
            <div v-if="openEditAvailabilityDates">
                Number of {{  selectedRoomType.name }} rooms availabile on these dates ({{ selectedRoomType.totalNumRooms }} maximum):
                <!--warning if over max  -->
                <div class="red--text mt-2" v-if="newAvailability > selectedRoomType.totalNumRooms">
                    <v-icon color="red">warning</v-icon> Availability exceeds total rooms.
                </div>
    
                <v-text-field v-model="newAvailability" placeholder="Enter availability" autofocus></v-text-field>
    
                Availability Status:
                <v-select v-model="newAvailabilityStatus" :items="availabilityStatuses" placeholder="Availability status"></v-select>
            </div>
    
            <div v-if="openEditRateDates">
                <!-- warning if over max -->
                <div class="red--text mt-2" v-if="selectedRateColumn == 'min_stay' && minStayAboveMax()">
                    <v-icon color="red">warning</v-icon> Min stay exceeds max stay.
                </div>
    
                <!-- warning if under min -->
                <div class="red--text mt-2" v-if="selectedRateColumn == 'max_stay' && maxStayBelowMin()">
                    <v-icon color="red">warning</v-icon> Max stay under min stay.
                </div>
    
                <div v-if="selectedRateColumnType == 'rate'">
                    Rates for these dates ({{ currencyCode }}):
                    <v-text-field v-model="newRate" placeholder="Enter rate" autofocus></v-text-field>
    
                    <v-select v-if="selectedRateColumn == 'rate_standard'" v-model="rateStatus" placeholder="Rate status" :items="rateStatuses"></v-select>
                </div>
    
                <div v-if="selectedRateColumnType == 'misc'">
                    Enter value:
                    <v-text-field v-model="newRate" placeholder="Enter value" autofocus></v-text-field> 
                </div>
    
                <div v-if="selectedRateColumnType == 'bool'">
                        <input type="checkbox" v-model="newCheckInOutValue">
                </div>
            </div>
    
            <div v-if="openEditUniversalRates">
                <div v-if="selectedRateColumnType == 'misc'">
                    Enter value ({{ currencyCode }}):
                    <v-text-field v-model="newRate" placeholder="Enter value" autofocus></v-text-field> 
                </div>
            </div>
    
            <div class="mt-4">
                <v-btn @click="close()" class="">Cancel</v-btn>
                <v-btn @click="submitEditForm()" class="lime">Update</v-btn>
            </div>
        </div>
    </div>
</template>


                                      

<script>
/**
* A Vue component dedicated to letting the user control availability and rates per
* hotel room for a given date range
*
* Props:  hotelkey
*/

export default {
    name: 'hotel-planner-edit-form',
    props: {
        
        selectedRateDates: {
            type: Array,
            default: []
        },

        selectedAvailabilityDates: {
            type: Array,
            default: []
        },

        openEditAvailabilityDates: {
            type: Boolean,
            default: false
        },
        
        openEditRateDates: {
            type: Boolean,
            default: false
        },

        openEditUniversalRates: {
            type: Boolean,
            default: false
        },
        
        selectedRoomType: {
            type: Object,
            default: {}
        },

        selectedRateColumn: {
            type: String,
            default: ''
        },
        currencyCode: {
            type: String,
            default: ''
        },

        localTableData: {
            type: Object,
            default: {}
        }

    },

    data () {
        return {
            updatedTableData: this.localTableData,
            newAvailabilityStatus: null,
            newAvailability: null,
            rateStatus: null,
            newRate: null,
            newCheckInOutValue: 0,
            editForm: {
                title: '',
                taskDescription: '',
                errorMessage: ''
            },
            availabilityStatuses: [
                { text: 'Available', value: 41 },
                { text: 'On Request', value: 42 },
                { text: 'Fully Booked', value: 43 }
            ],
            rateStatuses: [
                { text: 'Exact', value: 'exact' },
                { text: 'Indicative', value: 'indicative' },
                { text: 'Close', value: 'close' }
            ],
            additionalRateColumns: [
                'rate_single',
                'rate_extra_adult', 
                'rate_extra_child', 
                'rate_extra_baby', 
                'rate_extra_oldchild',
                'extra_adult_rate_amount',
                'extra_child_rate_amount',
                'extra_baby_rate_amount',
                'extra_oldchild_rate_amount'
            ],
            weekdays: {
                monday: 'Mon',
                tuesday: 'Tue',
                wednesday: 'Wed',
                thursday: 'Thu',
                friday: 'Fri',
                saturday: 'Sat',
                sunday: 'Sun'
            },

            selectedWeekdays: {
                monday: 1,
                tuesday: 1,
                wednesday: 1,
                thursday: 1,
                friday: 1,
                saturday: 1,
                sunday: 1
            },
        }
    },

    methods: {

        newRateIsValid: function(){
            let isNumber = isFinite(Number(this.newRate));
            let aboveZero = this.newRate > 0;
            let notNegative = this.newRate >= 0;
            let notLeadingZero = this.newRate == 0 || this.newRate.toString().startsWith('0') == false;

            if (this.selectedRateColumn == 'rate_standard'){
                return isNumber && aboveZero && notLeadingZero;
            }

            if (this.selectedRateColumn == 'min_stay'){
                return isNumber && aboveZero && notLeadingZero;
            }

            if (this.selectedRateColumn == 'max_stay'){
                let maxStayBelowMin = this.maxStayBelowMin();
                return isNumber && notNegative && notLeadingZero && !maxStayBelowMin;
            }

            if (this.editingStandardRate || this.additionalRateColumns.includes(this.selectedRateColumn)){
                return isNumber && notNegative && notLeadingZero;
            }
        },

        newAvailabilityIsValid: function(){
            let isWholeNumber = Number.isInteger(Number(this.newAvailability));
            let notNegative = this.newAvailability >= 0;
            let statusAvailableOrRequest = this.newAvailabilityStatus == 41 || this.newAvailabilityStatus == 42;
            
            return isWholeNumber && notNegative && !(statusAvailableOrRequest && this.newAvailability == 0);
        },

        newCheckInOutValueIsValid: function(){
            let isBoolValue = this.newCheckInOutValue == 1 || this.newCheckInOutValue == 0;
            
            return isBoolValue;
        },

        missingStandardRate: function(rateDate){
            let hasStandardRate = rateDate.rate_standard != null;
            
            return !hasStandardRate && !this.editingStandardRate;
        },

        rateClosedColumnLocked: function(rateDate){
            return rateDate.is_open == 0 && !this.editingStandardRate && this.selectedWeekdays[rateDate.weekday];
        },

        submitEditForm(){
            
            let missingStandardRate = this.selectedRateDates.filter((rateDate) => {
                return this.missingStandardRate(rateDate);
            });
         
            if (missingStandardRate.length > 0){
                this.editForm.errorMessage = 'A date must have a standard rate before it can be edited';
                return;
            } 
            
            let rateClosedColumnLocked = this.selectedRateDates.filter((rateDate) => {
                return this.rateClosedColumnLocked(rateDate);
            })

            if (rateClosedColumnLocked.length > 0){
                this.editForm.errorMessage = 'Cannot set value on closed rate';
                return;
            }

            if (this.newRate != null && !this.newRateIsValid()){ 
                this.editForm.errorMessage = 'Invalid value';
                return;
            }

            if (this.newAvailability != null && !this.newAvailabilityIsValid){
                this.editForm.errorMessage = 'Invalid availability';
                return;
            }

            this.updateRateDateCells();
            this.updateAvailabilityCells();
            if (this.updatedTableData.universalRates){
                this.updateUniversalRate(this.selectedRateColumn);
            }

            this.$emit('update:localTableData', this.updatedTableData);
            this.close();
            this.$emit('unselectDates');
        },

        close(){
            this.resetForm();
            this.$emit('closeEditForm');
        },

        resetForm(){
            this.editForm.errorMessage = '';

            this.newAvailability = null;
            this.newAvailabilityStatus = null;
            this.newRate = null;
            this.rateStatus = null;
            
            this.selectedWeekdays = {
                monday: 1,
                tuesday: 1,
                wednesday: 1,
                thursday: 1,
                friday: 1,
                saturday: 1,
                sunday: 1
            }
        },


        minStayAboveMax(){
            let hasMaxStay = this.lowestSelectedMaxStay != null;
            let newValueSelected = this.newRate != null && this.newRate != '';
            let newValueAboveMaxStay = this.newRate > this.lowestSelectedMaxStay;

            return hasMaxStay && newValueSelected && newValueAboveMaxStay
        },

        maxStayBelowMin(){
            let hasMinStay = this.highestSelectedMinStay != null;
            let newValueSelected = this.newRate != null && this.newRate != '' && this.newRate != 0;
            let newValueBelowMinStay = this.newRate < this.highestSelectedMinStay;

            return hasMinStay && newValueSelected && newValueBelowMinStay;
        },

        updateRateDateCells(){
            for (let i = 0; i < this.selectedRateDates.length; i++){
                let rateDate = this.selectedRateDates[i];

                if (!this.selectedWeekdays[rateDate.weekday]){
                    continue;
                }

                let rateDateTableData = this.updatedTableData['hotelRooms'][rateDate.hotel_room_key].rateTypes[rateDate.rate_type_key].ratesPerDate[rateDate.date];

                if (this.rateStatus == 'close') {
                    rateDateTableData['is_open'] = 0;
                    continue;
                }

                rateDateTableData['is_open'] = 1;
                
                if (this.newRate != null){
                    rateDateTableData[this.selectedRateColumn] = this.newRate;
                }

                if (this.selectedRateColumn == 'days_checkin' || this.selectedRateColumn == 'days_checkout'){
                    rateDateTableData[this.selectedRateColumn] = this.newCheckInOutValue ? 1 : 0;
                }
                
                if (this.rateStatus == 'indicative' && this.selectedRateColumn == 'rate_standard'){
                    rateDateTableData['is_indicative'] = 1;
                }
                
                if (this.rateStatus == 'exact' && this.selectedRateColumn == 'rate_standard'){
                    rateDateTableData['is_indicative'] = 0;
                }
            }
        },


        updateAvailabilityCells(){
            this.selectedAvailabilityDates.forEach((availability) => {
                if (!this.selectedWeekdays[availability.weekday]){
                    return;
                }
                
                let tableCellRef = this.updatedTableData['hotelRooms'][availability.hotel_room_key].availabilityPerDate[availability.date];
                
                // if opening availability use on request as default status
                if (tableCellRef.quantity == 0 && this.newAvailability > 0 && !this.newAvailabilityStatus){
                    tableCellRef.status_key = 42;
                }

                // if creating a new availability use on request as default status
                if (tableCellRef.status_key == null && !this.newAvailabilityStatus){
                    tableCellRef.status_key = 42;
                }

                // if creating a new availability use 2 as default quantity
                if (!tableCellRef.quantity  && !this.newAvailability && this.newAvailabilityStatus != 43){
                    return;
                }

                if (this.newAvailability || this.newAvailability == 0){
                    tableCellRef.quantity = this.newAvailability;
                }

                if (this.newAvailabilityStatus){
                    tableCellRef.status_key = this.newAvailabilityStatus;
                }
            });
        },

        updateUniversalRate(rateColumn){
            this.updatedTableData['universalRates'][rateColumn] = this.newRate;
        },
    },

    computed: {
        selectedDateFrom: function(){
            if (!this.selectedRateDates.length && !this.selectedAvailabilityDates.length){
                return null;
            }

            let dateObj = null
            
            if (this.openEditRateDates){
                dateObj = new Date(Object.values(this.selectedRateDates).sort((a, b) => new Date(a.date) - new Date(b.date))[0].date);
            } else {
                dateObj = new Date(Object.values(this.selectedAvailabilityDates).sort((a, b) => new Date(a.date) - new Date(b.date))[0].date);
            }

            return dateObj.toLocaleDateString();
        },

        selectedDateUntil: function(){
            if (!this.selectedRateDates.length && !this.selectedAvailabilityDates.length){
                return null;
            }

            let dateObj = null
            
            if (this.openEditRateDates){
                dateObj = new Date(Object.values(this.selectedRateDates).sort((a, b) => new Date(a.date) - new Date(b.date))[this.selectedRateDates.length - 1].date);
            } else {
                dateObj = new Date(Object.values(this.selectedAvailabilityDates).sort((a, b) => new Date(a.date) - new Date(b.date))[this.selectedAvailabilityDates.length - 1].date);
            }
            
            return dateObj.toLocaleDateString();
        },

        selectedDateRange: function(){
            let from = this.selectedDateFrom;
            let until = this.selectedDateUntil;

            if (from == until){
                return from;
            }
            
            return from + ' to ' + until;
        },

        selectedRateColumnType: function(){
            let rateColumns = ['rate_standard', 'rate_extra_adult', 'rate_extra_child', 'rate_extra_baby', 'rate_extra_oldchild'];
            let booleanColumns = ['days_checkin', 'days_checkout'];
            
            if (rateColumns.includes(this.selectedRateColumn)){
                return 'rate';
            }

            if (booleanColumns.includes(this.selectedRateColumn)){
                return 'bool';
            }

            return 'misc';
        },

        editingStandardRate: function(){
            return this.selectedRateColumn == 'rate_standard' && this.newRate;
        },

        lowestSelectedMaxStay: function(){
            return Math.min(...this.selectedRateDates.map(rateDate => rateDate.max_stay).filter(n => n > 0 && n != null));
        },

        highestSelectedMinStay: function(){
            return Math.max(...this.selectedRateDates.map(rateDate => rateDate.min_stay));
        }
    },
}

</script>
