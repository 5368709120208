<template>
    <div>
        <!-- list must be list of ['text' => x, 'value' => y] -->
        <v-select
            v-model="viewValue"
            :label="field.schema.displayName"
            :items="messageClasses"
            autocomplete
        ></v-select>
        <input type="hidden" :name="field.fieldName" :value="field.value">
        <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
            <li v-for="error in field.errors" v-bind:key="error">
                {{ error }}
            </li>
        </v-alert>
    </div>

</template>

<script>
    /**
     * Allows the selection of a hotel ID by choosing its name or code from
     * a selection downloaded from the API on creation
     */
    export default {
        name: 'ies-messageClass',
        props: ['field'],
        data() {
            return {
                messageClasses: [],
                viewValue: parseInt(this.field.value),
            }
        },
        created:function() {
            this.getMessageClasses();
        },
        methods: {
            getMessageClasses:function(){
                axios.get('/api/selectable-message-classes')
                .then(function(response){
                    console.log(response);
                    var messageClasses = [];
                    response.data.forEach(function(messageClass){
                        messageClasses.push({ text: messageClass.name, value: messageClass.message_class_value });
                    });
                    this.messageClasses = messageClasses;
                }.bind(this));
            },
        },
        watch: {
            viewValue: function (newValue) {
                this.field.value = newValue;
            }
        }
    }
</script>
