var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "6", lg: "4", "ma-1": "" } },
    [
      _c(
        "v-card",
        { staticClass: "ma-2" },
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("phone")]),
              _vm._v(" Communications\n      "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-btn",
                        { attrs: { href: "/messagings/list", block: "" } },
                        [_vm._v("Message Templates")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-btn",
                        { attrs: { href: "/email-queue/list", block: "" } },
                        [_vm._v("Email Queue")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-btn",
                        { attrs: { href: "/emails-sent/list", block: "" } },
                        [_vm._v("Emails Sent")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-btn",
                        { attrs: { href: "/emails-failed/list", block: "" } },
                        [_vm._v("Emails Failed")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-btn",
                        { attrs: { href: "/sms-queue/list", block: "" } },
                        [_vm._v("SMS Queue")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-btn",
                        { attrs: { href: "/sms-sent/list", block: "" } },
                        [_vm._v("SMSs Sent")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-btn",
                        { attrs: { href: "/sms-failed/list", block: "" } },
                        [_vm._v("SMSs Failed")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            href: "/communications-service-providers/list",
                            block: "",
                          },
                        },
                        [_vm._v("Service Providers")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            href: "/communication-types/list",
                            block: "",
                          },
                        },
                        [_vm._v("Communication Types")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }