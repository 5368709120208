var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { wrap: "" } },
        [
          _c("v-col", { staticClass: "checkbox", attrs: { sm: "12" } }, [
            _c(
              "label",
              { staticClass: "pr-6", attrs: { for: this.field.fieldName } },
              [_vm._v(" " + _vm._s(this.field.schema.displayName) + ":")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.input,
                  expression: "input",
                },
              ],
              attrs: {
                name: this.field.fieldName,
                type: "checkbox",
                disabled: this.field.disabled,
              },
              domProps: {
                checked: Array.isArray(_vm.input)
                  ? _vm._i(_vm.input, null) > -1
                  : _vm.input,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.input,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.input = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.input = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.input = $$c
                    }
                  },
                  function ($event) {
                    return _vm.updateField()
                  },
                ],
              },
            }),
          ]),
          _vm._v(" "),
          this.field.introduction
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _vm._v(_vm._s(this.field.introduction)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { sm: "12" } },
            [
              this.field.information
                ? _c(
                    "v-alert",
                    { attrs: { type: "info", dismissable: "", value: true } },
                    [_vm._v(_vm._s(this.field.information))]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { sm: "12" } },
            [
              this.field.warning
                ? _c(
                    "v-alert",
                    {
                      attrs: { type: "warning", dismissable: "", value: true },
                    },
                    [_vm._v(_vm._s(this.field.warning))]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { sm: "12" } },
            [
              this.field.error
                ? _c(
                    "v-alert",
                    { attrs: { type: "error", dismissable: "", value: true } },
                    [_vm._v(_vm._s(this.field.error))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }