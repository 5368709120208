<template>
    <v-flex v-if="value">
        {{ value }}

        <v-btn v-on:click="copyToClipboard">
            <v-icon>content_copy</v-icon>
        </v-btn>

        <v-btn :href="payLink" target="_blank">
            <v-icon>open_in_new</v-icon>
        </v-btn>

        <v-btn :href="emailLink">
            <v-icon>email</v-icon>
        </v-btn>
    </v-flex>
</template>

<script>
/**
 * Outputs a link for a payment request based on the provided model's reference field value
 * option to view the reference value, open the link in a new browser tab, copy the entire
 * link to clipboard to be embedded in a message, or open a new email to send to the client
 */
export default {
    name: 'ies-payment-request-link',
    props: ['field', 'value'],
    data () {
        return {}
    },

    methods: {
        getUrl: function(){
            return "https://www.i-escape.com/payment/paylink/" + this.value;
        },

        copyToClipboard: function(){
            navigator.clipboard.writeText(this.getUrl());
        }
    },

    computed: {
        payLink: function(){
            return this.getUrl();
        },

        emailLink: function(){
            return "mailto:?subject=Payment Request&body=" + this.getUrl();
        }
    }
}
</script>
