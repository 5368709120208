var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "row-sm": "", column: "" } },
        [
          this.field.schema.displayName != "none"
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _c("label", { attrs: { for: this.field.fieldName } }, [
                  _vm._v(" " + _vm._s(this.field.schema.displayName) + ": "),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          this.field.introduction
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _vm._v(_vm._s(this.field.introduction)),
              ])
            : _vm._e(),
          _vm._v(" "),
          this.field.information
            ? _c(
                "v-alert",
                { attrs: { type: "info", dismissable: "", value: true } },
                [_vm._v(_vm._s(this.field.information))]
              )
            : _vm._e(),
          _vm._v(" "),
          this.field.warning
            ? _c(
                "v-alert",
                { attrs: { type: "warning", dismissable: "", value: true } },
                [_vm._v(_vm._s(this.field.warning))]
              )
            : _vm._e(),
          _vm._v(" "),
          this.field.error
            ? _c(
                "v-alert",
                { attrs: { type: "error", dismissable: "", value: true } },
                [_vm._v(_vm._s(this.field.error))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "d-flex flex-column", attrs: { sm: "12" } },
                [
                  _c("input", {
                    attrs: { type: "hidden", name: _vm.field.fieldName },
                    domProps: { value: _vm.field.value },
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.telephoneNumbers, function (phone) {
                    return _c("v-row", [
                      _c("h4", [_vm._v(_vm._s(phone.phone_number))]),
                    ])
                  }),
                  _vm._v(" "),
                  _c("v-text-field", {
                    attrs: {
                      label: "Test",
                      name: this.field.fieldName,
                      disabled: this.field.disabled,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _vm.field.errors
                        ? _c(
                            "v-alert",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { type: "error", value: _vm.field.errors },
                            },
                            _vm._l(_vm.field.errors, function (error) {
                              return _c("li", [
                                _vm._v(
                                  "\n\t\t\t\t\t\t  \t\t " +
                                    _vm._s(error) +
                                    "\n\t\t\t\t\t\t\t "
                                ),
                              ])
                            }),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }