var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              return _vm.findHistory($event)
            },
          },
        },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "justify-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", md2: "" } },
                [
                  _c("v-text-field", {
                    attrs: { label: "Reservation reference" },
                    model: {
                      value: _vm.reference,
                      callback: function ($$v) {
                        _vm.reference = $$v
                      },
                      expression: "reference",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                { attrs: { xs12: "", md1: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "lime" },
                      on: {
                        click: function ($event) {
                          return _vm.findHistory($event)
                        },
                      },
                    },
                    [_vm._v("Find history")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-flex", { attrs: { xs1: "" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showProgressIcon,
                        expression: "showProgressIcon",
                      },
                    ],
                    attrs: { id: "searchProgressIcon" },
                  },
                  [
                    _c("v-progress-circular", {
                      attrs: { width: 3, color: "blue", indeterminate: "" },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.history.length
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "", "align-center": "" } },
                    [
                      _c("v-flex", { attrs: { xs12: "", md6: "" } }, [
                        _c("h4", [_vm._v("Reservation History:")]),
                      ]),
                      _vm._v(" "),
                      _c("v-flex", { attrs: { xs12: "", md6: "" } }, [
                        _c("h4", [_vm._v("Record additional history data:")]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.history, function (reservationChange, key) {
                    return _c(
                      "v-layout",
                      { key: key, attrs: { row: "", wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", md6: "" } },
                          [
                            _c("HistoryRecordData", {
                              attrs: { record: reservationChange.record },
                            }),
                            _vm._v(" "),
                            _c("HistoryRecordChanges", {
                              attrs: {
                                changes:
                                  reservationChange.record.record_changes,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        reservationChange.additional
                          ? _c(
                              "v-flex",
                              { attrs: { xs12: "", md6: "" } },
                              [
                                reservationChange.additional.reservation_units
                                  .length
                                  ? _c(
                                      "v-layout",
                                      { attrs: { row: "", wrap: "" } },
                                      [
                                        _c(
                                          "v-expansion-panel",
                                          [
                                            _c(
                                              "v-expansion-panel-content",
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "header" },
                                                    slot: "header",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Reservation Unit changes:"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-card",
                                                  _vm._l(
                                                    reservationChange.additional
                                                      .reservation_units,
                                                    function (unitChange, key) {
                                                      return _c(
                                                        "v-layout",
                                                        {
                                                          key: key,
                                                          attrs: {
                                                            row: "",
                                                            wrap: "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "HistoryRecordData",
                                                                {
                                                                  attrs: {
                                                                    record:
                                                                      unitChange,
                                                                  },
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "HistoryRecordChanges",
                                                                {
                                                                  attrs: {
                                                                    changes:
                                                                      unitChange.record_changes,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                reservationChange.additional
                                  .reservation_std_rates.length
                                  ? _c(
                                      "v-layout",
                                      { attrs: { row: "", wrap: "" } },
                                      [
                                        _c(
                                          "v-expansion-panel",
                                          [
                                            _c(
                                              "v-expansion-panel-content",
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "header" },
                                                    slot: "header",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Reservation Std rates changes:"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-card",
                                                  _vm._l(
                                                    reservationChange.additional
                                                      .reservation_std_rates,
                                                    function (stdChange, key) {
                                                      return _c(
                                                        "v-layout",
                                                        {
                                                          key: key,
                                                          attrs: {
                                                            row: "",
                                                            wrap: "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "HistoryRecordData",
                                                                {
                                                                  attrs: {
                                                                    record:
                                                                      stdChange,
                                                                  },
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "HistoryRecordChanges",
                                                                {
                                                                  attrs: {
                                                                    changes:
                                                                      stdChange.record_changes,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", "pt-4": "", "pb-4": "" } },
                          [_c("v-divider")],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: parseInt(3000),
            "multi-line": "",
            color: "ies-coral",
          },
          model: {
            value: _vm.errorSnackbar.show,
            callback: function ($$v) {
              _vm.$set(_vm.errorSnackbar, "show", $$v)
            },
            expression: "errorSnackbar.show",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "full-height full-width v-align-container text-xs-center",
            },
            [
              _c("h4", { staticClass: "ies-dark-gray--text v-align-div" }, [
                _vm._v(_vm._s(_vm.errorSnackbar.text)),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }