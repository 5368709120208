var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "v-flex",
        [
          _c(
            "v-card",
            { staticClass: "card-with-shadow" },
            [
              _c("v-card-title", [
                _c("h5", [_vm._v("Filter results " + _vm._s(_vm.title))]),
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-list",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-list-item",
                    [
                      _c("v-list-item-content", [_vm._v("Total count:")]),
                      _vm._v(" "),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v(_vm._s(_vm.result.totalVoucherNumber)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    [
                      _c("v-list-item-content", [_vm._v("Total value:")]),
                      _vm._v(" "),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v("£" + _vm._s(_vm.result.totalVoucherValue)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    [
                      _c("v-list-item-content", [_vm._v("Average value:")]),
                      _vm._v(" "),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v("£" + _vm._s(_vm.result.averageVoucherValue)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    [
                      _c("v-list-item-content", [_vm._v("Bookings count:")]),
                      _vm._v(" "),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v(_vm._s(_vm.result.totalBookings)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    [
                      _c("v-list-item-content", [
                        _vm._v("Total redeemed value:"),
                      ]),
                      _vm._v(" "),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v("£" + _vm._s(_vm.result.totalRedeemedValue)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    [
                      _c("v-list-item-content", [
                        _vm._v("Average redeemed value:"),
                      ]),
                      _vm._v(" "),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v("£" + _vm._s(_vm.result.averageRedeemedValue)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    [
                      _c("v-list-item-content", [
                        _vm._v("Total bookings value:"),
                      ]),
                      _vm._v(" "),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v("£" + _vm._s(_vm.result.totalBookingValue)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    [
                      _c("v-list-item-content", [
                        _vm._v("Average bookings value:"),
                      ]),
                      _vm._v(" "),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v("£" + _vm._s(_vm.result.averageBookingValue)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    [
                      _c("v-list-item-content", [
                        _vm._v("Total commission value:"),
                      ]),
                      _vm._v(" "),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v("£" + _vm._s(_vm.result.totalCommissionValue)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    [
                      _c("v-list-item-content", [
                        _vm._v("Average commission value:"),
                      ]),
                      _vm._v(" "),
                      _c("v-list-item-content", { staticClass: "align-end" }, [
                        _vm._v("£" + _vm._s(_vm.result.averageCommissionValue)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }