<template>
    <v-flex>{{ subscriptions }}</v-flex>
</template>

<script>
/**
 * Outputs a list of newsletter types representing the values stored in the
 * model's field.  The field is an integer, but the output is a string
 *
 */
export default {
    name: 'ies-newsletter-subscriptions',
    props: ['field', 'value'],
    data () {
        return {}
    },

    computed: {
        /**
         * Monthly = 1
         * Special Offers = 2
         * Family = 3
         */
        subscriptions: function(){
            var newslettersAsString = "";
            if (this.value & 1){
                newslettersAsString += 'Monthly';
            }
            if (this.value & 2){
                if (newslettersAsString){
                    newslettersAsString += ', ';
                }
                newslettersAsString += 'Special Offers';
            }
            if (this.value & 4){
                if (newslettersAsString){
                    newslettersAsString += ', ';
                }
                newslettersAsString += 'Family';
            }
            return newslettersAsString;
        }
    }

}
</script>
