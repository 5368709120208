<template>
    <v-container fluid>
       <v-layout row-sm column>
            <v-col v-if="this.field.schema.displayName != 'none'" cols="12">
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-col>

            <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            <v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            <v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>
        <!-- list must be list of ['text' => x, 'value' => y] -->

            <v-col cols="12" sm="6">
        <v-select
            v-model="viewValue"
            :items="purposes"
            autocomplete
        ></v-select>
        <input type="hidden" :name="field.fieldName" :value="field.value">
        <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
            <li v-for="error in field.errors" v-bind:key="error">
                {{ error }}
            </li>
        </v-alert>
           </v-col>

      </v-layout>
    </v-container>
</template>

<script>
    /**
     * Allows the selection of a purpose of a voucher's creation by choosing its name from
     * a selection
     */
    export default {
        name: 'ies-vouchercreationpurpose',
        props: ['field'],
        data() {
            return {
                purposes: [
                            {text: 'None', value: 0},
                            {text: 'Replacement', value: 1},
                            {text: 'Freebie/goodwill', value: 2},
                            {text: 'Competition gift', value: 3},
                            {text: 'Test', value: 4},
                            {text: 'Review Reward', value: 5}
                        ],
                viewValue: parseInt(this.field.value),
            }
        },
        watch: {
            viewValue: function (newValue) {
                this.field.value = newValue;
            }
        }
    }
</script>
