console.log("Filters are loading...");
import Vue from 'vue';
import { format, subDays } from 'date-fns';


Vue.filter('capitalize', function (value) {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
})

Vue.filter('dd-mm-yyyy', function (value) {
    if (!value) return '';
    return format(value, 'DD/MM/YYYY');
})

Vue.filter('timestamp', function (value) {
    if (!value) return '';
    return format(value, 'YYYY/MM/DD hh:mm:ss');
})

/**
 * Format a number as pounds
 */
Vue.filter('pounds', function (value) {
    if (!value) return 0;
    value = parseFloat(value, 10);

    return '£${value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}';
});

Vue.filter('voucher-type', function (value) {
    switch(value) {
        case 1:
            return 'Physical';
        case 2:
            return 'Digital';
        default:
            return value;
    }
});
