var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      !_vm.permission
        ? _c(
            "v-alert",
            { attrs: { type: "info", dismissable: "", value: "true" } },
            [_vm._v("You do not have permission to edit this page")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.metadata.info
        ? _c(
            "v-alert",
            { attrs: { type: "info", dismissable: "", value: "true" } },
            [_vm._v(_vm._s(_vm.metadata.info))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.metadata.warn
        ? _c(
            "v-alert",
            { attrs: { type: "warn", dismissable: "", value: "true" } },
            [_vm._v(_vm._s(_vm.metadata.warn))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.metadata.error
        ? _c(
            "v-alert",
            { attrs: { type: "error", dismissable: "", value: "true" } },
            [_vm._v(_vm._s(_vm.metadata.error))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-alert",
        {
          attrs: { color: "warning", icon: "priority_high" },
          model: {
            value: _vm.updatedUpstream,
            callback: function ($$v) {
              _vm.updatedUpstream = $$v
            },
            expression: "updatedUpstream",
          },
        },
        [
          _vm._v("\n           Some of this data has been updated by "),
          _c("strong", [
            _vm._v(
              _vm._s(_vm.upstreamChanges.userName) +
                " (" +
                _vm._s(_vm.upstreamChanges.userType) +
                ")"
            ),
          ]),
          _vm._v(" since you opened this page\n\n\t\t\t"),
          _c(
            "v-row",
            _vm._l(_vm.upstreamChanges.record_changes, function (field, key) {
              return _c(
                "v-col",
                { key: key, attrs: { sm: "12", xs12: "" } },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-col", { attrs: { sm: "12" } }, [
                            _c("span", [_vm._v("The field ")]),
                            _vm._v("\n\t\t\t\t\t\t\t   '"),
                            _c("strong", [_vm._v(_vm._s(field.fieldName))]),
                            _vm._v(
                              "'\n\t\t\t\t\t\t\t   was changed from\n\t\t\t\t\t\t\t   '"
                            ),
                            _c("strong", [_vm._v(_vm._s(field.old_value))]),
                            _vm._v(
                              "'\n\t\t\t\t\t\t\t   to\n\t\t\t\t\t\t\t   '"
                            ),
                            _c("strong", [_vm._v(_vm._s(field.new_value))]),
                            _vm._v("'\n\t\t\t\t\t\t   "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { sm: "12" } }, [
                _vm._v(
                  "If you save without applying these first then some of their changes may be lost.  Click the button below to try to update the data on this page"
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { sm: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.updatePage()
                        },
                      },
                    },
                    [_vm._v("Update page")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.reloadPage()
                        },
                      },
                    },
                    [_vm._v("Reload Page (Discard Changes)")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.openNew()
                        },
                      },
                    },
                    [_vm._v("Open In New Tab")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { id: "editForm" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "no-print d-flex d-md-none" },
            [
              _vm.permission
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ies-green",
                      on: {
                        click: function ($event) {
                          return _vm.saveRecord(_vm.metadata.primaryKey)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("save")])],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.permission
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-1 ies-coral",
                      on: {
                        click: function ($event) {
                          return _vm.deleteRecord(_vm.metadata.primaryKey)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("delete")])],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  on: {
                    click: function ($event) {
                      return _vm.emailLink()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("email")])],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  attrs: { disabled: !_vm.metadata.parentPage },
                  on: {
                    click: function ($event) {
                      return _vm.returnToDashboard()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("dashboard")]), _vm._v(" Dash")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  on: {
                    click: function ($event) {
                      return _vm.listView()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("view_list")])],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  on: {
                    click: function ($event) {
                      return _vm.reportProblem()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("report_problem")])],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "no-print d-none d-md-flex" },
            [
              _vm.permission
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ies-green",
                      on: {
                        click: function ($event) {
                          return _vm.saveRecord(_vm.metadata.primaryKey)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("save")]), _vm._v(" Save")],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.permission
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-1 ies-coral",
                      on: {
                        click: function ($event) {
                          return _vm.deleteRecord(_vm.metadata.primaryKey)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("delete")]), _vm._v(" Delete")],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-btn",
                { staticClass: "ml-1", attrs: { onClick: "window.print()" } },
                [_c("v-icon", [_vm._v("print")]), _vm._v(" Print")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  on: {
                    click: function ($event) {
                      return _vm.emailLink()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("email")]), _vm._v(" Email")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  attrs: { disabled: !_vm.metadata.parentPage },
                  on: {
                    click: function ($event) {
                      return _vm.returnToDashboard()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("dashboard")]), _vm._v(" Dash")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  on: {
                    click: function ($event) {
                      return _vm.listView()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("view_list")]), _vm._v(" List")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  on: {
                    click: function ($event) {
                      return _vm.reportProblem()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("report_problem")]), _vm._v(" Problem")],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c(
                "v-row",
                { staticClass: "ma-3" },
                _vm._l(_vm.formSections, function (section, i) {
                  return _vm.checkSection(section)
                    ? _c(
                        "v-col",
                        {
                          key: i,
                          attrs: {
                            cols: "12",
                            sm: "12",
                            md: "12",
                            lg: section.sectionWidth
                              ? section.sectionWidth
                              : 12,
                          },
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "ma-3", attrs: { flat: "" } },
                            [
                              _c(
                                "fieldset",
                                [
                                  section.sectionName
                                    ? _c("legend", [
                                        _vm._v(_vm._s(section.sectionName)),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  section.info
                                    ? _c(
                                        "v-alert",
                                        {
                                          attrs: {
                                            type: "info",
                                            dismissable: "",
                                            value: "true",
                                          },
                                        },
                                        [_vm._v(_vm._s(section.info))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  section.warn
                                    ? _c(
                                        "v-alert",
                                        {
                                          attrs: {
                                            type: "warn",
                                            dismissable: "",
                                            value: "true",
                                          },
                                        },
                                        [_vm._v(_vm._s(section.warn))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  section.error
                                    ? _c(
                                        "v-alert",
                                        {
                                          attrs: {
                                            type: "error",
                                            dismissable: "",
                                            value: "true",
                                          },
                                        },
                                        [_vm._v(_vm._s(section.error))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  section.introduction
                                    ? _c("div", [
                                        _vm._v(_vm._s(section.introduction)),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    _vm._l(section.fields, function (field, k) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: k,
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "12",
                                            lg: field.fieldWidth
                                              ? field.fieldWidth
                                              : 12,
                                          },
                                        },
                                        [
                                          _c("ies-input-wrapper", {
                                            attrs: {
                                              field: field,
                                              recordData: _vm.resultsArray,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  section.outroduction
                                    ? _c("div", [
                                        _vm._v(_vm._s(section.outroduction)),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e()
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "no-print d-flex d-md-none" },
            [
              _vm.permission
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ies-green",
                      on: {
                        click: function ($event) {
                          return _vm.saveRecord(_vm.metadata.primaryKey)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("save")])],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.permission
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-1 ies-coral",
                      on: {
                        click: function ($event) {
                          return _vm.deleteRecord(_vm.metadata.primaryKey)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("delete")])],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  on: {
                    click: function ($event) {
                      return _vm.emailLink()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("email")])],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  attrs: { disabled: !_vm.metadata.parentPage },
                  on: {
                    click: function ($event) {
                      return _vm.returnToDashboard()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("dashboard")]), _vm._v(" Dash")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  on: {
                    click: function ($event) {
                      return _vm.listView()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("view_list")])],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  on: {
                    click: function ($event) {
                      return _vm.reportProblem()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("report_problem")])],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "no-print d-none d-md-flex" },
            [
              _vm.permission
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ies-green",
                      on: {
                        click: function ($event) {
                          return _vm.saveRecord(_vm.metadata.primaryKey)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("save")]), _vm._v(" Save")],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.permission
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-1 ies-coral",
                      on: {
                        click: function ($event) {
                          return _vm.deleteRecord(_vm.metadata.primaryKey)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("delete")]), _vm._v(" Delete")],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-btn",
                { staticClass: "ml-1", attrs: { onClick: "window.print()" } },
                [_c("v-icon", [_vm._v("print")]), _vm._v(" Print")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  on: {
                    click: function ($event) {
                      return _vm.emailLink()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("email")]), _vm._v(" Email")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  attrs: { disabled: !_vm.metadata.parentPage },
                  on: {
                    click: function ($event) {
                      return _vm.returnToDashboard()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("dashboard")]), _vm._v(" Dash")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  on: {
                    click: function ($event) {
                      return _vm.listView()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("view_list")]), _vm._v(" List")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  on: {
                    click: function ($event) {
                      return _vm.reportProblem()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("report_problem")]), _vm._v(" Problem")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: parseInt(6000),
            "multi-line": "",
            color: "ies-green",
          },
          model: {
            value: _vm.successSnackbar,
            callback: function ($$v) {
              _vm.successSnackbar = $$v
            },
            expression: "successSnackbar",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "full-height full-width v-align-container text-xs-center",
            },
            [
              _c("h4", { staticClass: "ies-dark-gray--text v-align-div" }, [
                _vm._v("Record Saved"),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: parseInt(6000),
            "multi-line": "",
            color: "ies-coral",
          },
          model: {
            value: _vm.errorSnackbar,
            callback: function ($$v) {
              _vm.errorSnackbar = $$v
            },
            expression: "errorSnackbar",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "full-height full-width v-align-container text-xs-center",
            },
            [
              _c("h4", { staticClass: "ies-dark-gray--text v-align-div" }, [
                _vm._v("Save Failed"),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }