<template>
    <v-container fluid>
       <v-layout row-sm column>
            <v-flex v-if="this.field.schema.displayName != 'none'" xs12>
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-flex>

            <v-flex xs12 sm6>
                <input :name="this.field.fieldName" type="text" :value="this.viewModelImage.value" disabled>
            </v-flex>
            <v-flex xs12>
                <img
                    alt="Image from photo library"
                    v-bind:src="generatePath(this.viewModelImage.value)"
                    style="max-height: 200px;"
                >
            </v-flex>

            <v-flex xs12>
                <v-btn @click="selectNewImage">Choose Image</v-btn>
                <v-btn :href="imageURL" target="_blank" :disabled="!this.field.value"><v-icon>image</v-icon></v-btn>
                <v-btn class="ies-coral ies-dark-gray--text" @click="unlinkImage" :disabled="!this.field.value">Remove Image</v-btn>
            </v-flex>


            <v-flex xs12 class="white-out" v-if="displayWhiteOut">&nbsp;</v-flex>


            <div style="position: fixed; top:10vh; left:10vw; width:80vw; height:80vh; background-color:white; z-index:5; padding:5rem;" v-if="displayImagePicker">
                <span class="close-button"><v-btn v-on:click="closeImageSelector()"><v-icon>cancel</v-icon></v-btn></span>
                <h2>Image Library</h2>

                <div style="overflow-y:scroll; height:60vh;">
                <v-container fluid>
                    <v-layout row wrap>

                        <!-- Search tools -->
                        <v-col cols="12">

                          <v-expansion-panels>
                            <v-expansion-panel >
                                <v-expansion-panel-header><strong>Search <v-icon>search</v-icon></strong></v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <fieldset>
                                        <legend>Properties</legend>
                                        <v-text-field label="ID" v-model="searchFilters.imageKey"></v-text-field>
                                        <v-text-field label="Minimum Width (px)"  v-model="searchFilters.minWidth"></v-text-field>
                                        <v-text-field label="Minimum Height (px)" v-model="searchFilters.minHeight"></v-text-field>

                                        <v-select
                                            :items="displayTypes"
                                            v-model="searchFilters.displayType"
                                            label="Select View of Image"
                                            dense
                                        ></v-select>

                                        <v-select
                                            :items="imageTypes"
                                            v-model="searchFilters.imageType"
                                            label="Select Type of Image"
                                            dense
                                            id="image-type"
                                        ></v-select>

                                    </fieldset>

                    <fieldset>
                        <legend>Image Tags</legend>
                        <v-flex xs12 v-for="(tag, index) in imageKeywordOptions">

                            <label :for="index">{{ tag.label }}: </label>
                            <input type="checkbox"
                                   style="float:right;"
                                   :id="index"
                                   v-model="tag.isTicked" 
                                   :label="tag.label">
                        </v-flex>
                        <v-flex xs12>
                            <a href="/keywords/list">Manage Keywords</a>
                        </v-flex>
                    </fieldset>

                                    <fieldset>
                                            <legend>Date Range</legend>
                                            <div class="bold">Date From:</div>
                                            <v-date-picker
                                                :landscape="true"
                                                v-model="searchFilters.dateFrom"
                                                color="lime"
                                                type="date"
                                            ></v-date-picker>

                                            <div class="bold">Date Until:</div>
                                            <v-date-picker
                                                :landscape="true"
                                                v-model="searchFilters.dateUntil"
                                                color="lime"
                                                type="date"
                                            ></v-date-picker>
                                    </fieldset>

                                    <fieldset>
                                        <legend>Relationships</legend>
                                        <v-text-field label="Hotel Code" v-model="searchFilters.hotelCode"></v-text-field>
                                    </fieldset>

                                    <v-btn @click="loadLibraryImages"><v-icon>search</v-icon> Search</v-btn>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>

                        <v-col cols="12">
                        <v-container class="p-5">
                        <v-layout row wrap>

                        <v-col class="pt-3" cols="12" sm="6" md="3" v-for="(libraryImage, image_key) in libraryImages" :key="image_key">
                            <v-card @click.native="chooseImage(libraryImage.image_key)" class="clickable">

                                <v-container flex>
                                <v-layout row wrap>
                                    <v-col cols="12">
                                        <b>ID:</b> {{ libraryImage.image_key }}
                                    </v-col>

                                    <!-- Thumbnail -->
                                    <v-col cols="12">
                                        <img
                                            alt="Image from photo library"
                                            v-bind:src="libraryImage.image_source"
                                            style="max-height: 150px;"
                                        >
                                    </v-col>

                                    <!-- Description -->
                                    <v-col cols="12" v-if="libraryImage.hotel">
                                        <b>Hotel:</b> ({{ libraryImage.hotel.hotel_code }}) {{ libraryImage.hotel.hotel_name }}
                                    </v-col>
                                    <v-col cols="12">
                                        <b>Display:</b> {{ libraryImage.display_slideshow ? 'Yes' : 'No' }}
                                        <b>Type:</b> {{ (typeof libraryImage.image_type === undefined || libraryImage.image_type == null || typeof libraryImage.image_type.display_name_short === undefined) ? null : libraryImage.image_type.display_name_short }}
                                        <b>Order:</b> {{ libraryImage.sort_order }}
                                    </v-col>
                                    <v-col cols="12">
                                        <b>Caption:</b> {{ libraryImage.caption }}
                                    </v-col>
                                    <v-col cols="12">
                                        <b>Copyright:</b> {{ libraryImage.copyright }}
                                    </v-col>
                                    <v-col cols="12">
                                        <b>Description:</b> {{ libraryImage.description }}
                                    </v-col>

                                    <!-- Tag icons - for now this is hardcoded, but needs to be dynamic -->
                                    <v-col cols="12">
                                        <!-- <b>Tags:</b> -->
                                        <span v-if="libraryImage.image_keywords && libraryImage.image_keywords.length">
                                        <span v-for="(tag, index) in libraryImage.image_keywords">
		                            <v-icon :title="tag.keyword.name">{{ tag.keyword.icon_name }}</v-icon>
                                        </span>
                                        </span>
                                    </v-col>
                                </v-layout>
                                </v-container>

                            </v-card>
                        </v-col>
                        </v-layout>

                <!-- Bottom pagination bar -->
                <v-col cols="12" v-if="totalItems >= 1" xs12 class="mt-4 white">
                    <v-layout row align-center class="">  
                        <v-flex>
                            <strong>Total:</strong> {{ totalItems }} Items
                        </v-flex>      
                        <v-spacer></v-spacer>

                        <v-flex>
                            <v-btn v-on:click="goToFirstPage()" depressed><v-icon>first_page</v-icon></v-btn>
                            <v-btn v-on:click="goToPrevPage()" depressed><v-icon>navigate_before</v-icon></v-btn>

                            Page {{ pageNumber + 1 }} of {{ totalPages }}

                            <v-btn v-on:click="goToNextPage()" depressed><v-icon>navigate_next</v-icon></v-btn>
                            <v-btn v-on:click="goToLastPage()" depressed><v-icon>last_page</v-icon></v-btn>
                        </v-flex>

                        <v-spacer></v-spacer>

                        <v-flex>
                            <strong class="text-left">Images per Page:</strong>

                            <v-select
                                :items="rowsPerPageOpts"
                                v-model="chosenRowsPerPage"
                                label=""
                                class=""
                                item-value="value"
                                single-line
                                justify-end
                            ></v-select>

                        </v-flex>
                    </v-layout>
                </v-col>

                        </v-container>
                        </v-col>

                    </v-layout>
                </v-container>
                </div>

                <div style="width:100%; position:relative; bottom:0; background-color:white;">
                    <v-btn class="ies-green ies-dark-gray--text" @click="closeImageSelector">Done</v-btn>
                    <v-btn @click="closeImageSelector">Cancel</v-btn>
                </div>
            </div>

        </v-layout>
    </v-container>
</template>

<script>
    import axios from 'axios';

/**
 * A simple image picker designed for embedding into dynamic edit pages
 * to allow the user to set a field value to an image key from the photo
 * library
 */
export default {

    name: 'ies-disabled',
    props: ['field'],
    data () {
        return {
            /** @var {} imageField - A copy of the image field being edited, used to allow Vue to react to changes on the data model with changes in the computed methods without the need for watchers */
            imageField:     {},

            /** @var boolean displayImagePicker - Should the user be presented with an image picker modal to choose the replacement image record */
            displayImagePicker: false,

            imageKeywordOptions: [],

            /** @var boolean displayWhiteOut - Toggle the div to display blanking out the background */
            displayWhiteOut: false,

            /** @var {} searchFilters - An object encapsulating all of the different parameters that the user is searching the image library by */
            searchFilters:  { imageKey: null, hotelCode: null, dateFrom: null, dateUntil: null, imageType: null, regionKey: null, displayType: '', minWidth: null, minHeight: null, keywords: [] },

            /** @var [{}] libraryImages - An array of objects representing the images returned by the server to choose from */
            libraryImages:  [],

            /** @var [] imageTypes - An array of image types for the user to choose between when searching the photo library */
            imageTypes:     [],

            displayTypes: [{value: "", text: "Any"}, {value: 1, text: "Display Only"}, {value: 0, text: "Hidden Only"}],

            chosenRowsPerPage: 20,
            pageNumber:     0,
            totalItems: 0,
            message: '',
            rowsPerPageOpts:[{ 'text': '20', 'value': 20 }, {'text': '50', 'value': 50}, {'text': '100', 'value': 100}, {'text':'All', 'value':'All'}],
            totalImages: 0,
            totalPages: 0,

            /** @var {} */
            imageTypeInitials: {
                                    '1':  'O',  // Overview
                                    '2':  'R',  // rooms
                                    '3':  'E',  // eating
                                    '4':  'A',  // activities
                                    '5':  'F',  // features
                                    '6':  'S',  // site_photo
                                    '7':  'H',  // home
                                    '9':  'PH', // photo_library
                                    '10': 'OL', // overview_large
                                    '11': 'HL', // home_large
                                    '12': 'RL', // rooms_large
                                    '13': 'EL', // eating_large
                                    '14': 'AL', // activities_large
                                    '15': 'F',  // features_large
                                    '16': 'SL', //site_photo_large
                                    '17': 'K',  // kids
                                    '18': 'KL', //  kids_large
                                    '19': 'V',  //  physical_voucher
                                },
        }
    },

    /**
     * On mounting the component we are cloning the field to this.data
     * so Vue will be reactive to changes and automatically trigger the
     * computed methods to run without us needing to set up numerous
     * watchers to handle everything we want to happen now and in the future
     */
    mounted: function(){
        this.imageField = this.field;
        this.loadImageTypes();
    },

    methods: {
        /**
         * Nullifies the value for this field, thereby breaking the relationship
         * between the record being edited and the image record it once referenced
         * without deleting the image itself from the image library
         */
        unlinkImage: function(){
            this.imageField.value = null;
        },

        /**
         * Opens a dialogue to choose a new image from the library, setting this
         * field's value to the new image's image_key property
         */
        selectNewImage: function(){
            this.displayImagePicker = true;
            this.displayWhiteOut = true;
            this.loadLibraryImages();
        },

        /**
         * Updates the image key in the field to match that of the image chosen from the library
         */
        chooseImage: function(imageKey){
            this.imageField.value = imageKey;
            this.field.value = imageKey;
        },

        closeImageSelector: function(){
            this.displayImagePicker = false;
            this.displayWhiteOut = false;
        },

        /**
         * Requests an array of Image objects from the API which match the
         * criteria specified by the user (if any) limited to a max number
         * of records
         */
        loadLibraryImages: function(){
            var self = this;
            // The tags work differently - theyre presented as a permanent list of available
            // options but we actually only want to submit an array of IDs so we need to add them
            // in now before we submit the search
            this.searchFilters.keywords = [];
            this.searchFilters.offset = this.getOffset();
console.log(this.searchFilters);
            this.imageKeywordOptions.forEach(function(keyword){
                if (keyword.isTicked){
                    self.searchFilters.keywords.push(keyword.keywordKey);
                }
            });

            axios.get('api/images', { params: this.searchFilters } )
                .then(function(response){
                    this.libraryImages = response.data;
                    console.log("will getimagecount now");
                    this.getImageCount();
                    this.loadKeywords();
                }.bind(this))
                .then(function(){
                    // fail
                }.bind(this));
        },

        loadKeywords: function(){
            var imageKeywordOptions = [];
            axios.get('api/keywords')
            .then(function(response){
                this.imageKeywordOptions = [];
                var self = this;
           
                response.data.forEach(function(keyword){
                    // This flag is used to dictate whether or not the keyword should be preticked in the search box as it was found in all of the images
                    var foundInAllImages = self.libraryImages.length > 0;

                    self.libraryImages.forEach(function(libraryImage){
                        // Add new view model to the image which represent all of the keywords available
                        // If the image has any keywords tagged to it then update the relevant view model tick status
                        if (libraryImage.image_keywords && libraryImage.image_keywords.length){
                            var foundInThisImage = false;
                            libraryImage.image_keywords.forEach(function(imageKeyword){
                                if (imageKeyword.keyword_key == keyword.keyword_key){
                                    // Pretick the selected images keyword option because
                                    // we have now found at least one of the selected images owns it?
                                    foundInThisImage = true;
                                }
                            });

                            if (!foundInThisImage){
                                foundInAllImages = false;
                            }
                        }
                        // If the image has no tagged keywords at all then it is of course an instant fail
                        else {
                            foundInAllImages = false;
                        }
                    });
                   
                    // Populate the list of keywords the user can tag for this selection of images
                    self.imageKeywordOptions.push({keywordKey: keyword.keyword_key, label: keyword.name, isTicked: foundInAllImages});

                    // Populate the list of keywords the user can search for
                    //self.imageKeywords.push({keywordKey: keyword.keyword_key, label: keyword.name, isTicked: false});
                });
            }.bind(this))
            .then(function(){
                // fail
            }.bind(this));
        },

        getOffset: function() {
            return (this.pageNumber * this.chosenRowsPerPage);
        },

        /**
         * Requests all the different types of images recognised by the system, used for searching
         * the photo library
         */
        loadImageTypes: function(){
            var self = this;

            axios.get('api/images/types')
            .then(function(response){
                self.imageTypes = [ { text: 'Any', value: '' } ];
                response.data.forEach(function(imageType){
                    self.imageTypes.push( { text: imageType.display_name, value: imageType.image_type_key });
                });
                console.log("image types:",self.imageTypes);
                //this.libraryImages = response.data;
            }.bind(this))
            .then(function(){
                // fail
            }.bind(this));
        },

        getImageCount: function() {
console.log("getting image count()");
            axios.get('api/images/images-count', { params: this.searchFilters } )
                .then(function(response){
                    this.totalItems = response.data;
console.log("total items ", this.totalItems);
                    this.totalPages = this.getTotalPages();
                }.bind(this))
                .then(function(){
                    // fail
                }.bind(this));
        },

        /**
         * Recalculates the number of pages required to list every record using the current settings
         */
        getTotalPages: function(){
console.log("gettotalpages()", this.chosenRowsPerPage, this.totalItems);
            if (this.chosenRowsPerPage == 'All'){
                    return 1
            }
           return Math.ceil(this.totalItems / this.chosenRowsPerPage);
        },

        resetPageNumber: function(){
            this.pageNumber = 0;
        },

        /**
         * Navigate to the first page
         */
        goToFirstPage: function(){
            this.resetPageNumber();
            this.loadLibraryImages();
        },

        /**
         * Navigate to the page before this one
         */
        goToPrevPage: function(){
            if (this.pageNumber >= 1) {
                this.pageNumber--;
            }
            this.loadLibraryImages();
        },

        /**
         * Navigate to the next page
         */
        goToNextPage: function(){
            if (this.pageNumber < this.pages()) {
                this.pageNumber++;
            }
            this.loadLibraryImages();
        },

        /**
         * Navigate to the last page
         */
        goToLastPage: function(){
            this.pageNumber = this.pages();
            this.loadLibraryImages();
        },

        /**
         * Returns the number of pages for a list
         * @return - int
         */
        pages: function() {
console.log("pages ", this.totalItems, this.chosenRowsPerPage);
            return Math.ceil(this.totalItems / this.chosenRowsPerPage) - 1;
        },


        /**
         * Ancient logic taken from the front website, probably originally taken from
         * the legacy website.  This should all be moved to an API request which will
         * allow a central server point to determine image URLs without duplicating
         * logic, but for early development this will allow us to get started on the
         * component without scope creep introducing and testing new components elsewhere
         * too
         */
        generatePath: function(imageKey) {
            if (!imageKey){
                return '';
            }

            var parentDirSize = 1000;
            var childDirSize  = 100;

            var parentModifier   = (imageKey >= parentDirSize && imageKey % parentDirSize == 0) ? -1 : 0;
            var parentLowerLimit = 1 + ((Math.floor(imageKey / parentDirSize) + parentModifier) * parentDirSize);
            var parentUpperLimit = parentLowerLimit + (parentDirSize - 1);

            var childModifier    = (imageKey >= childDirSize && imageKey % childDirSize == 0) ? -1 : 0;
            var childLowerLimit  = 1 + ((Math.floor(imageKey / childDirSize) + childModifier) * childDirSize);
            var childUpperLimit  = childLowerLimit + (childDirSize - 1);
            return '/gallery/' + parentLowerLimit + '_' + parentUpperLimit + '/' + childLowerLimit + '_' + childUpperLimit + '/' + imageKey + '.jpg';
        }
    },

    computed: {

        /**
         * We need the image thumbnail to be dynamically updated when the
         * field value is changed, meaning the cleanest way is a computed
         * property to automatically react to changes to the data model.
         * The actual value to return as a string of the URL is too complex
         * to be handled here, so it is handled in the separate method(s)
         * dedicated to the task
         *
         * @return string URL of the image specified
         */
        imageURL: function(){
            return this.generatePath(this.imageField.value);
        },

        viewModelImage: function(){
            return this.imageField;
        },

        totalNumPages: function(){
            return this.totalPages;
        }
    }
}
</script>
<style lang="scss" scoped>
    .bold {
        font-weight: bold;
    }

    .clickable:hover {
        cursor: pointer;
    }

    .white-out {
        background-color: white;
        opacity: 0.6;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 4;
    }


    .close-button {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
    }
</style>
