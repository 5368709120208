<template>
    <v-container fluid>
		<v-layout row-sm column>
			<v-col v-if="this.field.schema.displayName != 'none'" cols="12">
        		<label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
    		</v-col>
            <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            <v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            <v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>
    		<v-col cols="12">
        	<v-radio-group
				v-model="viewValue"
				row
            	autocomplete >
				<v-radio label=""    :value="0" ></v-radio>
				<v-radio label="*"   :value="1" ></v-radio>
				<v-radio label="**"  :value="2" ></v-radio>
				<v-radio label="***" :value="3" ></v-radio>
			</v-radio-group>
        	<input type="hidden" :name="field.fieldName" :value="field.value">
        	<v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
            	<li v-for="error in field.errors" v-bind:key="error">
                	{{ error }}
            	</li>
        	</v-alert>
    	</v-col>
    </v-layout>
  </v-container>
</template>


<script>
    /**
     * Allows the setting of a star rating via a radio button group
     */
    export default {
        name: 'ies-ratings',
        props: ['field'],
        data() {
            return {
                viewValue: parseInt(this.field.value),
            }
        },
        watch: {
            viewValue: function (newValue) {
                this.field.value = newValue;
            }
        }
    }
</script>
