var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", { staticClass: "mx-5 my-3" }, [
        _vm._v(_vm._s(_vm.componentTitle)),
      ]),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "justify-center": "", "ma-4": "" } },
        [
          _c(
            "v-flex",
            { attrs: { "text-xs-right": "" } },
            [
              _c("v-date-picker", {
                attrs: { color: "lime", landscape: true, type: "month" },
                on: {
                  change: function ($event) {
                    _vm.isSubmitted = false
                  },
                },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { staticStyle: { "max-width": "180px" } },
            [
              _c("h3", { staticClass: "subheader" }, [
                _vm._v("Type Of Invoice"),
              ]),
              _vm._v(" "),
              _c(
                "v-radio-group",
                {
                  attrs: { mandatory: true },
                  on: {
                    change: function ($event) {
                      _vm.isSubmitted = false
                    },
                  },
                  model: {
                    value: _vm.invoiceType,
                    callback: function ($$v) {
                      _vm.invoiceType = $$v
                    },
                    expression: "invoiceType",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: { label: "Commissions", value: "1" },
                  }),
                  _vm._v(" "),
                  _c("v-radio", {
                    attrs: { label: "Annual Fees", value: "2" },
                  }),
                  _vm._v(" "),
                  _c("v-radio", { attrs: { label: "Ad Hoc", value: "3" } }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-switch", {
                attrs: { label: `Group by day ${_vm.groupByDay ? "✔" : "✘"}` },
                on: {
                  change: function ($event) {
                    _vm.isSubmitted = false
                  },
                },
                model: {
                  value: _vm.groupByDay,
                  callback: function ($$v) {
                    _vm.groupByDay = $$v
                  },
                  expression: "groupByDay",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            [
              _c("h3", { staticClass: "subheader" }, [_vm._v("Type Of Hotel")]),
              _vm._v(" "),
              _c(
                "v-radio-group",
                {
                  attrs: { mandatory: true },
                  on: {
                    change: function ($event) {
                      _vm.isSubmitted = false
                    },
                  },
                  model: {
                    value: _vm.hotelType,
                    callback: function ($$v) {
                      _vm.hotelType = $$v
                    },
                    expression: "hotelType",
                  },
                },
                [
                  _c("v-radio", { attrs: { label: "Upfront", value: "1" } }),
                  _vm._v(" "),
                  _c("v-radio", { attrs: { label: "Arrears", value: "2" } }),
                  _vm._v(" "),
                  _c("v-radio", { attrs: { label: "Both", value: "3" } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "justify-center": "", "ma-2": "" } },
        [
          _c(
            "v-btn",
            {
              class: { lime: !_vm.isSubmitted },
              on: { click: _vm.getAllBatchReports },
            },
            [_vm._v("Submit")]
          ),
          _vm._v(" "),
          _c("export-csv", {
            attrs: {
              filename: `${_vm.date}-${_vm.batchReportType}-batch-report`,
              data: _vm.regions,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("batch-report-summary", { attrs: { summary: _vm.summary } }),
      _vm._v(" "),
      _vm._l(_vm.regions, function (region) {
        return _c(
          "div",
          { key: region.key },
          [
            region.report
              ? _c("batch-report-data-table", {
                  attrs: { batchReport: region.report },
                })
              : _vm._e(),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }