<template>
    <v-container fluid>

       <v-alert type="info"  dismissable value="true" v-if="!permission">You do not have permission to edit this page</v-alert>
       <v-alert type="info"  dismissable value="true" v-if="metadata.info">{{ metadata.info }}</v-alert>
       <v-alert type="warn"  dismissable value="true" v-if="metadata.warn">{{ metadata.warn }}</v-alert>
       <v-alert type="error" dismissable value="true" v-if="metadata.error">{{ metadata.error }}</v-alert>


       <form id="importForm" v-on:submit.prevent>

            <v-card class="no-print" v-if="uxStage == 'choose'">
                <v-card-text>
                    <p>Please choose a file to import (.csv)</p>
                </v-card-text>
                <v-card-actions>
                    <input type="file" accept="text/csv" @change="fileSelected($event)">
                    <v-btn class="ies-green" @click="uploadFile"><v-icon>cloud_upload</v-icon> Upload</v-btn>
                </v-card-actions>
            </v-card>

            <v-card class="no-print" v-if="uxStage == 'validated'">
                <v-card-text>
                    The following fields were identified in the file:

                    <ul>
                        <li v-for="(identifiedField, key) in validation.identifiedFieldNames" :key="key">'{{ identifiedField.fileFieldName }}' as {{ identifiedField.schema.modelName }} {{ identifiedField.schema.fieldName }}</li>
                    </ul>

                    <p>There are {{ validation.numRecordsFound }} records in the file</p>

                    <v-alert type="error"  dismissable value="true" v-if="validation.validation.length > 0">
                        <p>Validation failed with {{ validation.validation.length }} problems.  Please correct the problems identified below and resubmit the file</p>
                        <div>
                            <div v-for="(failedCell, key) in validation.validation">
                                Row {{ failedCell.rowIndex + 1 }} Column {{ failedCell.columnIndex + 1 }}: {{ failedCell.message }}
                            </div>
                        </div>
                    </v-alert>

                    <div v-if="validation.validation.length == 0">If this is what you expected to see then you can continue to import the data to the database using the controls below</div>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="resetForm">Upload New File</v-btn>

                    Or

                    <v-text-field
                        label="Match records based on the field:"
                        name="matchField"
                        v-model="matchField"
                    ></v-text-field>

                    <v-btn class="ies-green" @click="acceptValidation" :disabled="validation.validation.length > 0">Import This Data</v-btn>
                </v-card-actions>
            </v-card>

            <v-card class="no-print" v-if="uxStage == 'complete'">
                <v-card-text>
                    The data file will now be imported.  This may take a few minutes
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="resetForm">Upload New File</v-btn>
                    <v-btn class="ies-green" href="/mailing-list/list">View Records</v-btn>
                </v-card-actions>
            </v-card>
       </form>

    </v-container>
</template>


<script>
export default {
    name: 'import',
    props: ['sectionsData','resultsData', 'metadata', 'permission'],
    data () {
        return {
                   csvToUpload: {},

                   /** @var string matchField - the model.field name to match existing records by */
                   matchField: '',
                   stage: 'choose',
                   validation: {},
                   import: {}
               }
    },

    methods: {
        resetForm: function(){
            this.stage = 'choose';
        },

        fileSelected: function($event){
            this.csvToUpload = { 'name': 'datafile', 'file': event.target.files[0]};
        },

        uploadFile: function(event){
            var payload = new FormData();
            payload.append(this.csvToUpload.file.name, this.csvToUpload.file);

            axios.post('mailing-lists/upload', payload)//*******************rename
                .then(response => {
                    this.stage = 'validated';
                    this.validation = response.data;
                    console.log(this.validation);
            }).catch(function (error) {
                    alert("File Upload Error: " + error);
                }
            );

        },

        acceptValidation: function(){
            axios.get('mailing-lists/process-import?uploadID=' + this.validation.uploadID + '&matchField=' + this.matchField)//*******************rename
                .then(response => {
                    this.stage = 'complete';
                    this.import = response.data;
                    console.log(this.import);
            }).catch(function (error) {
                    alert("Import Failed: " + error);
                }
            );

            this.stage = 'complete';
        }
    },

    computed:{
        uxStage: function(){
            console.log(this.stage);
            return this.stage;
        }
    },
    // All initialisation actions that should be performed on page ready
    mounted: function () {
        // create copy of main app vue 'this' so we can call 'this' functions from eventHub scope
        var _this = this;
    }


}
</script>

<style>
    .ies-actions td:first-child{
        padding:0px;
    }

    input:disabled {
        background: #dddddd;
    }
</style>
