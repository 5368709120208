var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    _vm._l(_vm.section.dashboards, function (dashboard, dashboardKey) {
      return _c(
        "v-flex",
        { key: dashboardKey, staticClass: "ma-3", attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "mb-2", attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    [
                      _c("v-flex", { attrs: { sm1: "" } }, [
                        _c("label", [_vm._v("Dashboard System Name")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { sm4: "" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "" },
                            model: {
                              value: dashboard.dashboardName,
                              callback: function ($$v) {
                                _vm.$set(dashboard, "dashboardName", $$v)
                              },
                              expression: "dashboard.dashboardName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mt-3 mb-3" }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }