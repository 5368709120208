var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "d-none d-md-flex mt-3 mb-3", attrs: { "xs-12": "" } },
    [
      this.isBroken
        ? _c("v-flex", [_vm._v("Sorry, this content is not working")])
        : _vm._e(),
      _vm._v(" "),
      this.isReady && !this.isBroken
        ? _c(
            "v-container",
            { staticClass: "mobile-no-padding", attrs: { fluid: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _vm.loadingData
                            ? _c("v-progress-linear", {
                                attrs: { indeterminate: "", color: "lime" },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.componentReady
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", id: "table-external" } },
                                [
                                  _vm.appliedFilters.length > 0 &&
                                  _vm.showFilters
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass: "mb-3",
                                          attrs: { xs12: "" },
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { row: "", wrap: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _vm._v(
                                                    "The following filters are applied:"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.appliedFilters,
                                                function (filter, index) {
                                                  return _c(
                                                    "v-col",
                                                    {
                                                      key: index,
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(index + 1) +
                                                          ". " +
                                                          _vm._s(
                                                            filter.modelName
                                                          ) +
                                                          "." +
                                                          _vm._s(
                                                            filter.fieldName
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            filter.operator
                                                          ) +
                                                          " " +
                                                          _vm._s(filter.value) +
                                                          "\n                                    "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-data-table", {
                                    staticClass: "elevation-1 ies-data-table",
                                    class: _vm.pageSize,
                                    attrs: {
                                      headers: _vm.tableHeadings,
                                      items: _vm.tableRows,
                                      "item-key": _vm.tableHeadings[0].value,
                                      loading: _vm.loadingData,
                                      search: _vm.search,
                                      options: _vm.options,
                                      "server-items-length": _vm.totalItems,
                                      "custom-sort": _vm.customSort,
                                      "hide-default-footer": "",
                                      dense: "",
                                    },
                                    on: {
                                      "update:options": function ($event) {
                                        _vm.options = $event
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function ({ item }) {
                                            return [
                                              _c(
                                                "tr",
                                                _vm._l(
                                                  _vm.tableHeadings,
                                                  function (header) {
                                                    return _c(
                                                      "td",
                                                      [
                                                        _c("ies-list-field", {
                                                          attrs: {
                                                            value:
                                                              item[
                                                                header.value
                                                              ],
                                                            fieldSchema: header,
                                                            pageRecord:
                                                              _vm.page,
                                                            headerProp:
                                                              "header",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.orderByField(
                                                                header
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3559098217
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mt-4", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c("v-col", [
                            _c("strong", [_vm._v("Total:")]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.totalItems) +
                                " Items\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "navigationButton",
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.firstPage()
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("first_page")])],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "navigationButton",
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.prevPage()
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("navigate_before")])],
                                1
                              ),
                              _vm._v(
                                "\n\n                            Page " +
                                  _vm._s(_vm.pageNumber + 1) +
                                  " of " +
                                  _vm._s(_vm.totalPages) +
                                  "\n\n                            "
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "navigationButton",
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.nextPage()
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("navigate_next")])],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "navigationButton",
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.lastPage()
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("last_page")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            [
                              _c("strong", [_vm._v("Rows per Page:")]),
                              _vm._v(" "),
                              _c("v-select", {
                                attrs: {
                                  items: _vm.rowsPerPageOpts,
                                  label: "",
                                  "item-value": "value",
                                  "single-line": "",
                                },
                                model: {
                                  value: _vm.chosenRowsPerPage,
                                  callback: function ($$v) {
                                    _vm.chosenRowsPerPage = $$v
                                  },
                                  expression: "chosenRowsPerPage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }