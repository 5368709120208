<template>
    <div>
        <!-- list must be list of ['text' => x, 'value' => y] -->
		<v-col v-if="this.field.schema.displayName != 'none'" cols="12">
    	    <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
    	</v-col>
        <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
        <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
        <v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
        <v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>

        <v-select
            v-model="viewValue"
            :items="rateBases"
            autocomplete
        ></v-select>
        <input type="hidden" :name="field.fieldName" :value="field.value">
        <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
            <li v-for="error in field.errors" v-bind:key="error">
                {{ error }}
            </li>
        </v-alert>
    </div>

</template>

<script>
    /**
     * Allows the selection of rate basis for hotel room
     */
    export default {
        name: 'ies-input-rate-basis',
        props: ['field'],
        data() {
            return {
                rateBases: [
					{ text: 'Room', 		value: 1 },
					{ text: 'Apartment', 	value: 2 },
					{ text: 'Cottage', 		value: 3 },
					{ text: 'Villa', 		value: 4 },
					{ text: 'House', 		value: 5 },
					{ text: 'Boat', 		value: 6 },
					{ text: 'Unit', 		value: 7 },
					{ text: 'Whole House', 	value: 8 }
				],
                viewValue: parseInt(this.field.value),
            }
        },
        methods: {
            
        },
        watch: {
            viewValue: function (newValue) {
                this.field.value = newValue;
            }
        }
    }
</script>

