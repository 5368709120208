var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _vm.fullStatement.length
        ? _c(
            "v-card-title",
            [
              _c("span", { staticClass: "subheader" }, [
                _vm._v(
                  "Full statement for " +
                    _vm._s(_vm.hotel.hotel_name) +
                    ". Account overall balance " +
                    _vm._s(_vm.balance)
                ),
              ]),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  items: _vm.hotelDropdown,
                  label: "Select hotel",
                  autocomplete: "",
                  "item-text": "text",
                  "item-value": "value",
                  dense: "",
                  hint: "Type to search for a hotel",
                  "persistent-hint": "",
                },
                model: {
                  value: _vm.hotelSelected,
                  callback: function ($$v) {
                    _vm.hotelSelected = $$v
                  },
                  expression: "hotelSelected",
                },
              }),
            ],
            1
          )
        : _c(
            "v-card-title",
            [
              _c("span", { staticClass: "subheader" }, [
                _vm._v("Please select a hotel"),
              ]),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  items: _vm.hotelDropdown,
                  label: "Select hotel",
                  autocomplete: "",
                  "item-text": "text",
                  "item-value": "value",
                  dense: "",
                  hint: "Type to search for a hotel",
                  "persistent-hint": "",
                },
                model: {
                  value: _vm.hotelSelected,
                  callback: function ($$v) {
                    _vm.hotelSelected = $$v
                  },
                  expression: "hotelSelected",
                },
              }),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "v-data-table",
        {
          attrs: {
            headers: _vm.headers,
            items: _vm.fullStatement,
            loading: _vm.loading,
            "item-key": "index",
            "footer-props.items-per-page-options": "rowsPerPage",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "items",
                fn: function (props) {
                  return [
                    _c(
                      "tr",
                      {
                        staticClass: "lime lighten-5",
                        on: {
                          click: function ($event) {
                            props.expanded = !props.expanded
                          },
                        },
                      },
                      [
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.transaction_date)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.reference)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            _vm._s(_vm._f("pounds")(props.item.amount_gbp))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(_vm._f("pounds")(props.item.balance))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "justify-center layout px-0" },
                          [
                            _c(
                              "v-btn",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    _vm.dialog = true
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { color: "teal" } }, [
                                  _vm._v("edit"),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-dialog",
                              {
                                attrs: { width: "500" },
                                model: {
                                  value: _vm.dialog,
                                  callback: function ($$v) {
                                    _vm.dialog = $$v
                                  },
                                  expression: "dialog",
                                },
                              },
                              [
                                _c(
                                  "v-card",
                                  [
                                    _c("v-card-title", [
                                      _vm._v(
                                        "\n                                Corrections Stuff\n                            "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("v-card-text", [
                                      _vm._v(
                                        "\n                                And this is where you will have inputs! :)\n                            "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "v-card-actions",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "primary",
                                              flat: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.dialog = false
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " \n                                Exit\n                            "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "expand",
                fn: function (props) {
                  return props.item.reservations
                    ? [
                        _c(
                          "v-container",
                          { staticClass: "px-4" },
                          [
                            _c(
                              "v-card",
                              { staticClass: "pa-2" },
                              [
                                _c("v-subheader", [_vm._v("Reservations")]),
                                _vm._v(" "),
                                _c(
                                  "v-layout",
                                  { attrs: { row: "", wrap: "" } },
                                  _vm._l(
                                    props.item.reservations,
                                    function (reservation) {
                                      return _c(
                                        "v-flex",
                                        {
                                          key: reservation.reference,
                                          staticClass: "px-4",
                                          attrs: { xs6: "", md3: "" },
                                        },
                                        [
                                          _c("a", { attrs: { href: "#" } }, [
                                            _vm._v(
                                              _vm._s(reservation.reference)
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "\n                            Departure: " +
                                                _vm._s(
                                                  _vm._f("dd-mm-yyyy")(
                                                    reservation.date_departure
                                                  )
                                                ) +
                                                "\n                        "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "\n                            Arrival: " +
                                                _vm._s(
                                                  _vm._f("dd-mm-yyyy")(
                                                    reservation.date_arrival
                                                  )
                                                ) +
                                                "\n                        "
                                            ),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : undefined
                },
              },
            ],
            null,
            true
          ),
        },
        [
          _c("v-progress-linear", {
            attrs: { slot: "progress", color: "lime", indeterminate: "" },
            slot: "progress",
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }