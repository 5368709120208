<template>
    <v-flex xs12 sm12 md2 lg2 ma-1>
        <v-card>
            <v-card-text>
              <a href="/myescapes-members/list">
                <div class="stats-icon"><v-icon style="font-size: 3rem;">person_add</v-icon></div>
                <div class="stats-text">
                    <div class="stats-figure">{{ stats }}</div>
                    <div class="stats-descriptor">New Memberships</div>
                </div>
              </a>
            </v-card-text>
        </v-card>
    </v-flex>
</template>

<script>
    import axios from 'axios';

    export default {
        props:['type'],
        data: function() {
            return {
                data: { new: '...' }
            }
        },
        created:function() {
            this.fetchNotifications();
        },
        methods:{

            fetchNotifications:function(){
                axios.get('/api/member-statistics')
                .then(function(response){
console.log("member stats", response.data);
                    this.data = response.data.stats;
                }.bind(this));
            }
        },
        computed: {
            stats: function(){
                return this.data.new;
            }
        }
    }
</script>

<style>
@media only screen and (max-width: 600px) {
    .list__tile__title {
        font-size: 10px;
    }
}

.stats-icon {
    font-size: 3rem;
    width: 33%;
    display: inline-block;
    text-align: center;
}

.stats-text {
    width: 65%;
    display: inline-block;
}

.stats-figure{
    text-decoration: bold;
    font-size: 1.5rem;
}

.stats-descriptor{
}
</style>
