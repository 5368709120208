var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("accounts-filter", { attrs: { settings: _vm.accountFilterSettings } }),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "subheader" }, [
              _vm._v("Hotels with outstanding balances"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs8: "", lg6: "", "offset-lg1": "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "append-icon": "search",
                          label: "Search",
                          "single-line": "",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    {
                      attrs: {
                        xs2: "",
                        "offset-xs2": "",
                        lg2: "",
                        "offset-lg3": "",
                      },
                    },
                    [
                      _c("export-csv", {
                        attrs: {
                          filename: "OutstandingBalances",
                          data: _vm.filteredItems,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-data-table",
            {
              ref: "table",
              staticClass: "elevation-1",
              attrs: {
                headers: _vm.headers,
                items: _vm.receipts,
                search: _vm.search,
                loading: _vm.isLoading,
              },
              scopedSlots: _vm._u([
                {
                  key: "items",
                  fn: function (props) {
                    return [
                      _c("td", { staticClass: "text-xs-center" }, [
                        _vm._v(_vm._s(props.item.creation)),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-center" }, [
                        _vm._v(_vm._s(props.item.hotel_code)),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-center" }, [
                        _vm._v(_vm._s(props.item.hotel_name)),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-center" }, [
                        _vm._v(_vm._s(props.item.type_of_hotel)),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-center" }, [
                        _vm._v(_vm._s(props.item.payment_method_name)),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-xs-center" }, [
                        _vm._v(_vm._s(_vm._f("pounds")(props.item.amount))),
                      ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c("v-progress-linear", {
                attrs: { slot: "progress", color: "lime", indeterminate: "" },
                slot: "progress",
              }),
              _vm._v(" "),
              _vm._v(" "),
              _c("template", { slot: "footer" }, [
                _c("td", { attrs: { colspan: _vm.headers.length - 1 } }),
                _c("td", { staticClass: "text-xs-center" }, [
                  _c("strong", [_vm._v("Total: ")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("pounds")(_vm.totalAmount)) +
                      "\n                "
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }