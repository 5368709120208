<template>
<div>
    <!--Date range picker-->
    <v-expansion-panel expand>
        <v-expansion-panel-content :value="true">
            <div slot="header" >
                <v-layout row wrap>
                    <v-flex>
                        <span class="headline mb-0">Date Picker</span>
                    </v-flex>
                    <v-flex justyfy-end>
                        <span class="headline mb-0 pl-2 float-right">{{dateRange[0]}} - {{dateRange[1]}}</span>
                    </v-flex>
                </v-layout>
            </div>
            <div>
                <div id="linearWrapper">
                    <v-progress-linear :indeterminate="true" v-show="showProgressIcon" color="lime"></v-progress-linear>
                </div>
                <ies-date-range
                    class="justify-center"
                    :startDate="startDate"
                    :endDate="endDate"
                ></ies-date-range>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>

    <!--Toggle-->
    <v-expansion-panel expand class="my-2" v-if="rooms.length !== 0">
        <v-expansion-panel-content :value="true">
            <div slot="header">
                <h3 class="headline mb-0">Actions</h3>
            </div>
            <v-container class="pb-0">
                <v-layout row>
                    <!-- Toggle -->
                    <v-flex xs2 offset-xs1>
                        <v-switch label="Rate Standard" v-model="rate_standard"></v-switch>
                    </v-flex>
                    <v-flex xs2>
                        <v-switch label="Rate Single" v-model="rate_single"></v-switch>
                    </v-flex>
                    <v-flex xs2>
                        <v-switch label="Extra rates" v-model="extra_rates"></v-switch>
                    </v-flex>

                    <!-- Export button -->
                    <v-flex xs2 offset-xs4>
                        <ies-csv-export 
                            :filename="`${this.hotel.hotel_name} Rate Summary`" 
                            :data="this.rooms"
                        ></ies-csv-export>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-expansion-panel-content>
    </v-expansion-panel>

    <!--Alert-->
    <v-alert :value="rooms.length === 0" type="info">
        There are no rates selected for this hotel on the dates selected.
    </v-alert>

    <!--Results table-->
    <v-card v-for="(rateTypes, roomName) in rooms" :key="roomName" class="my-2">
        <v-card-title class="pb-0">
            <h3 class="headline mb-0">{{ roomName }}</h3>
        </v-card-title>
        <v-expansion-panel v-for="(rates, rateType) in rateTypes" :key="rateType" expand>
            <v-expansion-panel-content :value="false">
                <div slot="header">
                    <h3 class="subheading mb-0 ">Rate Type: {{ rateType }}</h3>
                </div>
                <v-data-table :headers="tableHeaders" :items="rates" class="elevation-1">
                    <template slot="items" slot-scope="props">
                        <td class="text-xs-center">{{ props.item.date_start }}</td>
                        <td class="text-xs-center">{{ props.item.date_end }}</td>
                        <td class="text-xs-center" v-if="rate_standard" v-html="props.item.rate_standard"></td>
                        <td class="text-xs-center" v-if="rate_single">{{ props.item.rate_single }}</td>
                        <td class="text-xs-center" v-if="extra_rates">{{ props.item.rate_extra_baby }}</td>
                        <td class="text-xs-center" v-if="extra_rates">{{ props.item.rate_extra_child }}</td>
                        <td class="text-xs-center" v-if="extra_rates">{{ props.item.rate_extra_oldchild }}</td>
                        <td class="text-xs-center" v-if="extra_rates">{{ props.item.rate_extra_adult }}</td>
                    </template>
                </v-data-table>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-card>
</div>

</template>
<script>
    import EventBus from '../../../../eventBus';
    import {progressBar} from '@/mixins/progressBar';
    import { format, addDays } from 'date-fns';

    export default {
        mixins: [progressBar],
        props: ['hotel'],
        data() {
            return {
                rooms: [],
                tableHeaders: [],
                dateRange: [],
                selected: [],
                rate_standard: true,
                rate_single: true,
                extra_rates: true,
            }
        },
        created() {
            EventBus.$on('ies-date-range Changed', (date) => {
                this.dateRange = date.range;
                this.getRates();
                this.progressIconShow();
            });
        },
        computed: {
            startDate: function() {
                return format(new Date(), 'YYYY-MM-DD')
            },
            endDate: function() {
                return format(addDays(new Date(), 7), 'YYYY-MM-DD')
            }
        },
        watch: {
            rooms: function() {
                this.tableHeaders = this.getHeaders();
                this.userFriendlyText();
            },
            rate_standard: function() {
                this.tableHeaders = this.getHeaders();
            },
            rate_single: function() {
                this.tableHeaders = this.getHeaders();
            },
            extra_rates: function() {
                this.tableHeaders = this.getHeaders();
            }
        },
        methods: {
            /**
             * Ajax request to get rates
             */
            getRates() {
                axios({
                    method: 'get',
                    url: `/ajax/hotel/${this.hotel.hotel_code}/rates`,
                    params: {
                        'date-from': this.dateRange[0],
                        'date-to': this.dateRange[1]
                    }
                })
                .then(res => {
                    this.rooms = res.data.rooms;
                    this.progressIconHide();
                })
                .catch(err => console.log(err))
            },
            /**
             * Changes values in to user friendly values.
             */
            userFriendlyText() {
                for (let room in this.rooms) {
                    for (let rateType in this.rooms[room]) {
                        this.rooms[room][rateType].map(details => {
                            for (let key in details) {
                                // Leave dates intact
                                if (key !== 'date_start' && key !== 'date_end') {
                                    details[key] = this.checkSet(key, details[key]);
                                }
                            }
                        });
                    }
                }
            },
             /**
             * If a value is -1 it's unset so we swap that for a friendly message!! :)
             * @param value
             * @return {string}
             */
            checkSet(key, value) {
                if (key === 'rate_standard' ) {
                     return value === -1 ? '<span style="color: red;">Ask hotel</span>' : value;
                }

                return value === -1 ? 'Not set' : value;
            },
            /**
             * Header tables for Vuetify <data-table>
             * NEEDS TO MATCH ORDER IN DATA TABLE!!
             * @return {*[]}
             */
            getHeaders() {
                let headers = [
                    {
                        text: 'Date Start',
                        value: 'date_start',
                        align: 'center',
                        sortable: true,
                    },
                    {
                        text: 'Date end',
                        value: 'date_end',
                        align: 'center',
                        sortable: true,
                    }
                ];

                if (this.rate_standard) {
                    headers.push({
                        text: 'Rate Standard',
                        value: 'rate_standard',
                        align: 'center',
                        sortable: true,
                    })
                }

                if (this.rate_single) {
                    headers.push(                    {
                        text: 'Rate Single',
                        value: 'rate_single',
                        align: 'center',
                        sortable: true,
                    })
                }

                if (this.extra_rates) {
                    headers.push({
                            text: 'Rate Extra Baby',
                            value: 'rate_extra_baby',
                            align: 'center',
                            sortable: true,
                        },
                        {
                            text: 'Rate Extra Child',
                            value: 'rate_extra_child',
                            align: 'center',
                            sortable: true,
                        },
                        {
                            text: 'Rate Extra Old Child',
                            value: 'rate_extra_oldchild',
                            align: 'center',
                            sortable: true,
                        },
                        {
                            text: 'Rate Extra Adult',
                            value: 'rate_extra_adult',
                            align: 'center',
                            sortable: true,
                        }
                    );
                }

                return headers;
            }
        }
    }
</script>

<style>
    .expansion-panel__header {
        background-color: #f2f2f2;
    }
    .expansion-panel {
        box-shadow: none;
    }
</style>
