<template>
    <v-container fluid>
		<v-row>
			<v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>

			<v-col cols="12">
           		<v-alert type="warning"  dismissable :value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            	<v-alert type="error" dismissable :value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>
			</v-col>

			<v-col col="3">
				<label for="standard_occupation">Standard Occupancy: </label>
			</v-col>
			<v-col cols="2">
				<input 
					style="border: 1px solid grey; width: 100%; box-sizing: border-box; padding: 4px 6px;"
					name="standard_occupancy"
					type="number"
					v-model="viewValues.standard_occupancy"
					required>
			</v-col>
			<v-col cols="8" class="pl-1">
				<span>Guests (in existing beds)</span>
			</v-col>

			<v-col cols="12">
				<v-alert type="info"  dismissable :value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
			</v-col>

    		<v-col v-if="this.field.schema.displayName != 'none'" cols="2">
        		<label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
    		</v-col>

    		<v-col cols="2">
				<v-radio-group
					v-model="viewValues.extra_accommodation"
					row
            		autocomplete>
					<v-radio label="Yes"	:value="1" ></v-radio>
					<v-radio label="No"		:value="0" ></v-radio>
				</v-radio-group>
			</v-col>

			<v-col cols="12">
				<v-container v-if="viewValues.extra_accommodation">
					<v-row>
						<v-col cols="2">
							<label for="extra_beds">Extra Bed Types: </label>
						</v-col>
						<v-col cols="7">
							<v-text-field
								name="extra_beds_description"
								v-model="viewValues.extra_beds_description"
								:disabled="!viewValues.extra_accommodation"
							></v-text-field>
							<span>(eg. 1 rollaway bed OR 1 baby cot)</span>
						</v-col>
						<v-col cols="10">
							<table class="extra-guests"> 
								<thead>
									<tr>
										<th></th>
										<th v-if="ageRanges.baby">Baby: {{ageRanges.baby.start}}-{{ageRanges.baby.end}} yrs</th>
										<th v-if="ageRanges.child">Child: {{ageRanges.child.start}}-{{ageRanges.child.end}} yrs</th>
										<th v-if="ageRanges.oldchild">Older Child: {{ageRanges.oldchild.start}}-{{ageRanges.oldchild.end}} yrs</th>
										<th v-if="ageRanges.adult">Adult: {{ageRanges.adult.start}}+ yrs</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(guest, guestIndex) in viewValues.room_guests" :key="guestIndex">
										<td>Extra Guest {{guestIndex + 1}}:</td>
										<td v-if="ageRanges.baby">
											<input 
												:name="'extra_baby_' + guestIndex" 
												type="checkbox" 
												v-on:change="updateOccupancies()"
												v-model="viewValues.room_guests[guestIndex].baby_cot">
										</td>
										<td v-if="ageRanges.child">
											<input 
												:name="'extra_child_' + guestIndex" 
												type="checkbox" 
												v-on:change="updateOccupancies()"
												v-model="viewValues.room_guests[guestIndex].child_extra_bed">
										</td>
										<td v-if="ageRanges.oldchild">
											<input 
												:name="'extra_oldchild_' + guestIndex" 
												type="checkbox" 
												v-on:change="updateOccupancies()"
												v-model="viewValues.room_guests[guestIndex].oldchild_extra_bed">
										</td>
										<td v-if="ageRanges.adult">
											<input 
												:name="'extra_adult_' + guestIndex" 
												type="checkbox" 
												v-on:change="updateOccupancies()"
												v-model="viewValues.room_guests[guestIndex].adult_extra_bed">
										</td>
										<td>
											<v-btn v-on:click="deleteGuest(guestIndex)">Delete</v-btn>
										</td>
									</tr>
								</tbody>
							</table>
						</v-col>
						<v-col cols="6">
							<v-btn v-on:click="addGuest()">+ add more Extra Guest Options</v-btn>
						</v-col>
					</v-row>
				</v-container>
    		</v-col>

			<v-col cols="3">
				<label for="extra_beds">Maximum Occupancy: </label>				
			</v-col>
			<v-col cols="4">
				<span>Adults: {{ viewValues.max_occ_adults }}<br></span>
				<span v-if="ageRanges.oldchild">Older Children: {{ viewValues.max_occ_older_children }}<br></span>
				<span v-if="ageRanges.child">Children: {{ viewValues.max_occ_children }}<br></span>
				<span v-if="ageRanges.baby">Babies: {{ viewValues.max_occ_babies }}<br></span>
			</v-col>

			<input type="hidden" :name="this.field.fieldName" :value="field.value">

        	<v-alert type="error" dismissable :value="true" v-if="errorMessages && errorMessages.length">
               	<div v-for="(error, index) in errorMessages" :key="index" >{{ error.reason }}*</div>
            </v-alert>

		</v-row>
	</v-container>
</template>

<style>

table.extra-guests {
	display: table;
	width: 100%;
}

.extra-guests tr:first-child {
	height: 32px;
}

.extra-guests td, .extra-guests th {
	width: 20%;
	text-align: center;
}

.extra-guests td:first-child {
	font-weight: bold;
}

</style>

<script>
    /**
     * Allows the addition of multiple extra bed types to a room, based on available age ranges
	 * set by the associated hotel
     */
    export default {
        name: 'ies-input-extra-rooms',
        props: ['field', 'recordData'],
        data() {
            return {
				showValidationGuide: false,
				errorMessages: [],
                viewValues: {
					"room_key": this.recordData.hotel_room_key,
					"standard_occupancy": 1,
					"extra_accommodation": parseInt(this.field.value),
					"extra_beds_description": '',
					"max_occ_adults": 1,
					"max_occ_babies": 0,
					"max_occ_children": 0,
					"max_occ_older_children": 0,
					"room_guests": [
						{ "baby_cot": false, "child_extra_bed": false, "oldchild_extra_bed": false, "adult_extra_bed": false },
						{ "baby_cot": false, "child_extra_bed": false, "oldchild_extra_bed": false, "adult_extra_bed": false },
					],
				},
				ageRanges: {},
            }
        },

		created: function() {
			this.addEventListeners();
			this.getAgeRanges();
			this.getExtraGuests();
			this.getRoomData();
			this.field.value = this.viewValues;
		},

		methods: {
			addEventListeners: function() {
				var self = this;

           		this.$root.$on('Field: set errors', function(payload){
               		console.log("TEXT: I heard ", payload);
                	if (payload.modelName = self.field.modelName && payload.fieldName == self.field.fieldName){
                  		self.errorMessages = payload.errors;
                   		console.log("TEXT: I set ", self.field.errors);
               		}
            	});
			},

			getExtraGuests: function() {
				axios.get("/api/extra-guests/" + this.recordData.hotel_room_key).then((response) => {
					let extraGuests = response.data.guests;
					if (extraGuests.length) {
						this.viewValues.room_guests = [];
						extraGuests.forEach((guest) => {
							let additionalGuest = { 
								'baby_cot': guest.baby_cot ? true : false, 
								'child_extra_bed': guest.child_extra_bed ? true : false, 
								'oldchild_extra_bed': guest.oldchild_extra_bed ? true : false, 
								'adult_extra_bed': guest.adult_extra_bed ? true : false 
							};
							this.viewValues.room_guests.push(additionalGuest);
						});
					}
				});
			},

			getRoomData: function() {
				axios.get("/api/room-data/" + this.recordData.hotel_room_key).then((response) => {
					let room = response.data.room_data;
					console.log('Rooom:', room);
					this.viewValues.standard_occupancy = room.standard_occupancy;
					this.viewValues.extra_beds_description = room.extra_beds;
					this.viewValues.max_occ_adults = room.adult;
					this.viewValues.max_occ_babies = room.baby;
					this.viewValues.max_occ_children = room.child;
					this.viewValues.max_occ_older_children = room.oldchild;
				});
			},
			
			getAgeRanges: function() {
				axios.get("/api/child-age-ranges/" + this.recordData.hotel_key).then((response) => {
					this.ageRanges = response.data.age_ranges;	
				});
			},

			updateOccupancies: function() {
				this.viewValues.max_occ_adults = parseInt(this.viewValues.room_guests.filter((value) => { 
					return value.adult_extra_bed 
				}).length) + parseInt(this.viewValues.standard_occupancy);

				this.viewValues.max_occ_babies = parseInt(this.viewValues.room_guests.filter((value) => { return value.baby_cot }).length);
				this.viewValues.max_occ_children =  parseInt(this.viewValues.room_guests.filter((value) => { return value.child_extra_bed }).length);
				this.viewValues.max_occ_older_children = parseInt(this.viewValues.room_guests.filter((value) => { return value.oldchild_extra_bed }).length);
				this.field.value = this.viewValues;

			},
			
			addGuest: function() {
				this.viewValues.room_guests.push({ 
					'baby_cot': false, 
					'child_extra_bed': false, 
					'oldchild_extra_bed': false, 
					'adult_extra_bed': false 
				});
			},

			deleteGuest: function(guestIndex) {
				this.viewValues.room_guests.splice(guestIndex, 1);
			},
		},

        watch: {
			'viewValues.standard_occupancy': function(newValue) {
				let extraAdults = parseInt(this.viewValues.room_guests.filter((value) => { return value.adult_extra_bed }).length);
				this.viewValues.max_occ_adults = extraAdults + parseInt(newValue);
				this.field.value = this.viewValues;
			},
			'viewValues.extra_accommodation': function(newVaue) {
				this.field.value = this.viewValues;
			},
			'viewValue.extra_beds_description': function(newValue) {
				this.field.value = this.viewValues;
			},
			'viewValues.max_occ_adults': function(newVaue) {
				this.field.value = this.viewValues;
			},
			'viewValues.max_occ_babies': function(newVaue) {
				this.field.value = this.viewValues;
			},
			'viewValues.max_occ_children': function(newVaue) {
				this.field.value = this.viewValues;
			},
			'viewValues.max_occ_older_children': function(newVaue) {
				this.field.value = this.viewValues;
			},
            'viewValues.room_guests': function (newValue) {

				this.viewValues.max_occ_adults = parseInt(this.viewValues.room_guests.filter((value) => { 
					return value.adult_extra_bed 
				}).length) + parseInt(this.viewValues.standard_occupancy);

				this.viewValues.max_occ_babies = parseInt(this.viewValues.room_guests.filter((value) => { return value.baby_cot }).length);
				this.viewValues.max_occ_children =  parseInt(this.viewValues.room_guests.filter((value) => { return value.child_extra_bed }).length);
				this.viewValues.max_occ_older_children = parseInt(this.viewValues.room_guests.filter((value) => { return value.oldchild_extra_bed }).length);
				this.field.value = this.viewValues;
            },
			deep: true
        }
    }
</script>



