<template>
<v-card>
    <v-card-title>
        <span class="subheader">Transaction summary by hotel contract type</span>
    </v-card-title>

    <!-- Headers -->
    <v-container>
        <v-layout row>
            <v-flex xs2 offset-xs1>
                <strong>Hotel Type</strong>
            </v-flex>
            <v-flex v-for="(item, key) in summary" :key="key" xs2 class="text-xs-right">
                <strong>{{ key | capitalize }}</strong>
            </v-flex>
        </v-layout>

        <v-layout row>
            <v-flex xs2 offset-xs1>
                <strong>Upfront</strong>
            </v-flex>
            <v-flex
                xs2 
                v-for="(item, key) in summary" :key="key" 
                class="text-xs-right"
            >
                <span v-if="key === 'transactions'">
                    {{ item.UPF }}
                </span>
                <span v-else>
                    {{ item.UPF | pounds }}
                </span>  
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs2 offset-xs1>
                <strong>In Arrears</strong>
            </v-flex>
            <v-flex 
                xs2 
                v-for="(item, key) in summary" :key="key"
                class="text-xs-right"
            >
                <span v-if="key === 'transactions'">
                    {{ item.ARR }}
                </span>
                <span v-else>
                    {{ item.ARR | pounds }}
                </span>  
            </v-flex>
        </v-layout>

        <v-divider></v-divider>

        <v-layout row>
            <v-flex xs2 offset-xs1>
                <strong>Manual Payments</strong>
            </v-flex>
            <v-flex
                xs2
                v-for="(item, key) in summary" :key="key"
                class="text-xs-right"
            >
                <span v-if="key === 'transactions'">
                    {{ item.manual }}
                </span>
                <span v-else>
                    {{ item.manual | pounds }}
                </span>  
            </v-flex>
        </v-layout>

        <v-layout row>
            <v-flex xs2 offset-xs1>
                <strong>Total</strong>
            </v-flex>
            <v-flex
                xs2 
                v-for="(item, key) in summary" :key="key"
                class="text-xs-right"
            >
                <span v-if="key === 'transactions'">
                    {{ item.total }}
                </span>
                <span v-else>
                    {{ item.total | pounds }}
                </span>  
            </v-flex>
        </v-layout>
    </v-container>
</v-card>    
</template>

<script>
import EventBus from '@/eventBus'

export default {
    data() {
        return {
            summary: [],
            query: {
            type_of_hotel: 'UPF,ARR,FIX',
            balance: 'negative,positive',
            date: {
                from: '2000-01-01', 
                to: '',
                }
            }
        }
    },
    created() {
        this.getSummary()
        // this.registerListeners()
    },
    watch: {
        // summary: function () {
        //     this.totalSummary()
        //     // this.redNegative()
        // }
    },
    methods: {
        async getSummary() {
            const res = await axios('api/accounts/summary-hotel-type', {
                params: {
                    dateFrom:       this.query.date.from,           // invoice from date
                    dateTo:         this.query.date.to,             // invoice to date
                    type_of_hotel:  this.query.type_of_hotel,       // upfront, in arrears or fixed fee
                    balance:        this.query.balance              // negative or positive balance only
                }
            });
            console.log(res.data)
            this.summary = res.data;
        },
        capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1)
        },
        /**
         * TODO - refactor this so that we can run several functions inside the loop.
         */
        totalSummary() {
             for (let type in this.summary) {
                this.summary[type]['total'] = 0;
                for (let hotelType in this.summary[type]) {
                    if (hotelType !== 'total') {
                        this.summary[type]['total'] += this.summary[type][hotelType];
                    }
                }
             }
        },
        redNegative() {
            for (let type in this.summary) {
                for (let hotelType in this.summary[type]) {
                    // Negative numbers are red.
            
                    if (type !== 'transactions') {
                    if (this.summary[type][hotelType] < 0) {
                        this.summary[type][hotelType] = `<span style="color: red;"> £ ${this.summary[type][hotelType]} </span>`
                    } else {
                        // this.summary[type][hotelType] = `${this.summary[type][hotelType]}`
                    }
                    }
                }
            }
        },
        /**
         * Register all event listeners here to keep created() tidy.
         */
        // registerListeners() {
        //     EventBus.$on('accounts-filter', (query) => {
        //         this.query  = query
        //         this.getSummary()
        //     });
        // },
    }
    
}
</script>