import { render, staticRenderFns } from "./ExtraBeds.vue?vue&type=template&id=7165a393&"
import script from "./ExtraBeds.vue?vue&type=script&lang=js&"
export * from "./ExtraBeds.vue?vue&type=script&lang=js&"
import style0 from "./ExtraBeds.vue?vue&type=style&index=0&id=7165a393&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/live/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7165a393')) {
      api.createRecord('7165a393', component.options)
    } else {
      api.reload('7165a393', component.options)
    }
    module.hot.accept("./ExtraBeds.vue?vue&type=template&id=7165a393&", function () {
      api.rerender('7165a393', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/inputs/ExtraBeds.vue"
export default component.exports