<template>
    <div>
        <v-card class="mx-5">
            <v-card-title style="background-color: gainsboro;">
                <span class="subheader">{{ batchReport.region }}</span>
            </v-card-title>
            <v-data-table
                :headers="headers()"
                :items="batchReport.data"
                class="batch-report-table"
                hide-default-footer
                disable-pagination
            >
                <template v-slot:item="props">
                <tr>
                    <td class="text-xs-center">{{ props.item.ContactName }}</td>
                    <td class="text-xs-center">{{ props.item.InvoiceNumber }}</td>
                    <td class="text-xs-center">{{ formattedDateDDMMYYYY(props.item.TransactionDate) }}</td>
                    <td class="text-xs-center">{{ formattedDateDDMMYYYY(props.item.IssuedDate) }}</td>
                    <td class="text-xs-center">{{ formattedDateDDMMYYYY(props.item.DueDate) }}</td>
                    <td class="text-xs-center">{{ props.item.Description }}</td>
                    <td class="text-xs-center">{{ props.item.Quantity }}</td>
                    <td class="text-xs-center">£{{ props.item.NetAmount.toFixed(2) }}</td>
                    <td class="text-xs-center">£{{ props.item.GrossAmount.toFixed(2) }}</td>
                    <td class="text-xs-center">{{ props.item.AccountCode }}</td>
                    <td class="text-xs-center">{{ props.item.TaxType }}</td>
                </tr>
                </template>

                <template slot="footer">
                    <td colspan="7"></td>
                    <td class="font-weight-bold">
                        £{{ batchReport.totalNet.toFixed(2) }}
                    </td>
                    <td class="font-weight-bold">
                        £{{ batchReport.totalGross.toFixed(2) }}
                    </td>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>

export default {
    props: ['batchReport'],
    methods: {
        headers() {
            return [
                {
                    text: 'Contact Name',
                    align: 'center',
                    value: 'ContactName',
                    sortable: false,
                },
                {
                    text: 'Invoice Number',
                    align: 'center',
                    value: 'InvoiceNumber',
                    sortable: false,
                },
                {
                    text: 'Transaction Date',
                    align: 'center',
                    value: 'TransactionDate',
                    sortable: true,
                },
                {
                    text: 'Issued Date',
                    align: 'center',
                    value: 'IssuedDate'
                },
                {
                    text: 'Due Date',
                    align: 'center',
                    value: 'DueDate'
                },
                {
                    text:  'Description',
                    align: 'center',
                    value: 'Description'
                },
                {
                    text:  'Quantity',
                    align: 'center',
                    value: 'Quantity'
                },
                {
                    text: 'Net Amount',
                    align: 'center',
                    value: 'NetAmount'
                },
                {
                    text: 'Gross Amount',
                    align: 'center',
                    value: 'GrossAmount'
                },
                {
                    text: 'AccountCode',
                    align: 'center',
                    value: 'AccountCode'
                }, 
                {
                    text: 'Tax Type',
                    align: 'center',
                    value: 'TaxType'
                }
            ]
        },

        formattedDateDDMMYYYY(dateYYYYMMDD){
            console.log(dateYYYYMMDD);
            if (!dateYYYYMMDD || dateYYYYMMDD == '0000-00-00'){
                return '';
            }
            var date = new Date(Date.parse(dateYYYYMMDD));
            var day = date.getDate();
            var month = date.getMonth() + 1;
            var year = date.getFullYear();
            return (day < 10 ? '0'+day : day) + '/' + (month < 10 ? '0'+month : month) + '/' + year;
        }
    }
}
</script>


<style scoped>

table.table tbody th:first-child {
  padding: 0 !important;
}
  .batch-report-table th {
        padding: 0 !important;
        font-size: .7rem;
        height: 25px;

    }

    .batch-report-table td {
        padding: 0 !important;
        font-size: .8rem;
        height: 25px;
        text-align: center;

    }

    .font-weight-bold {
        font-weight: 700;
    }
</style>
