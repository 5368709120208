<template>
  <v-col cols="12" md="6" lg="4" ma-1>
    <v-card class="ma-2">
        <v-card-title class="title font-weight-black">
            <v-icon class=pr-3>sync</v-icon> Channel Management
        </v-card-title>
        <v-card-text>
            <v-btn block class="mt-1" href="channel-managers/list"><v-icon>sync</v-icon> Channel Managers</v-btn>
            <v-btn block class="mt-1" href="channel-managed-hotels/list"><v-icon>home</v-icon> Channel Managed Hotels</v-btn>
            <v-btn block class="mt-1" href="channel-managed-hotels-on-request/list">Channel Managed Hotels (On Request)</v-btn>
            <v-btn block class="mt-1" href="channel-managed-hotels-no-recent-updates/list"><v-icon>warning</v-icon> No Recent Updates</v-btn>
            <v-btn block class="mt-1" href="owner-managed-hotels/list">Owner Managed Hotels</v-btn>
            <v-btn block class="mt-1" href="owner-managed-hotels-on-request/list">Owner Managed Hotels (On Request)</v-btn>
            <v-btn block class="mt-1" href="channel-manager-size/report"><v-icon>show_chart</v-icon> No. Hotels per Channel</v-btn>
            <v-btn block class="mt-1" href="booking-status/report"><v-icon>show_chart</v-icon> No. Instant vs Request</v-btn>
            <v-btn block class="mt-1" href="incoming-ical-feeds/list">iCal</v-btn>
            <v-btn block class="mt-1" href="ical-connection-types/list">Supported iCal Feed Types</v-btn>
        </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
    /**
     * Display buttons linking through to accounts reports
     */
    export default {
        props:[],

        data: function() {
            return {
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){},

        methods: {},

        computed: {}
    }
</script>
