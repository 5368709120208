var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-flex",
    { attrs: { xs6: "", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c("v-btn", { attrs: { disabled: "" } }, [
                    _vm._v("Invoicing"),
                  ]),
                  _vm._v(" "),
                  _c("v-btn", { attrs: { disabled: "" } }, [
                    _vm._v("Receipts"),
                  ]),
                  _vm._v(" "),
                  _c("v-btn", { attrs: { disabled: "" } }, [
                    _vm._v("Refunds & Adjustments"),
                  ]),
                  _vm._v(" "),
                  _c("v-btn", { attrs: { disabled: "" } }, [
                    _vm._v("Vouchers"),
                  ]),
                  _vm._v(" "),
                  _c("v-btn", { attrs: { disabled: "" } }, [_vm._v("Reports")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }