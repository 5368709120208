
<template>
    <tr class="h-auto"
        :disabled="isDisabled">
        <td
            :title="rowHeader"
            v-bind:class="{
                    'header-cell': true,
                    'rate-header': true,
                    'w-auto': true,
                    'rateName': true,
                    'interactive': true,
                    'pl-2' : true,
                    'text-xs-right': true,
                    'font-weight-bold': rateColumn == 'rate_standard'
                }"
            @mouseenter="handleRowMouseEnter(rateColumn)"
            @mousedown="handleRowMouseDown(rateColumn)"
            @mouseup="handleCellMouseUp(rateColumn)"
            :disabled="isDisabled">
            {{ rowHeader }}
        </td>
        <template v-for="(rateForDate) in ratesPerDate">
            <td 
                :title="`Rate for ${ standardOccupancy } pax on ${  rateForDate.date }`"
                @mousedown="handleCellMouseDown(rateForDate, rateColumn)"
                @mouseenter="handleCellMouseEnter(rateForDate, rateColumn)"
                @mouseup="handleCellMouseUp()"
                v-bind:class="{
                    'interactive' : true,
                    'rate-for-date': true,
                    'pl-1' : true,
                    'pr-1' : true,
                    'unknown' : !rateForDate.rate_standard || !rateForDate.is_open,
                    'selected': cellIsSelected(rateForDate, rateColumn) || cellIsBeingEdited(rateForDate, rateColumn),
                    'updated': cellIsUpdated(rateForDate, rateColumn),
                    'disabled-cell': dateIsOutOfRange(rateForDate.date),
                    'disabled-rate-cell': dateIsOutOfRange(rateForDate.date),
                    'indicative': rateForDate.is_indicative && rateForDate.is_open && rateColumn == 'rate_standard'
                }">
                    
                <span v-if="!isCheckbox"> 
                    {{ getCellDisplayValue(rateForDate, rateColumn) }} 
                </span> 

                <input v-if="isCheckbox" 
                    type="checkbox" 
                    v-model="rateForDate[rateColumn]"
                    @change="toggleBoolean(rateForDate, rateColumn)"/>
            </td>
        </template>
    </tr>
</template>


                                      

<script>
/**
* A Vue component dedicated to letting the user control availability and rates per
* hotel room for a given date range
*
* Props:  hotelkey
*/

export default {
    name: 'hotel-planner-rate-row',
    props: [
        'rowHeader',
        'rateColumn',
        'isDisabled',
        'ratesPerDateSaved',
        'ratesPerDate',
        'standardOccupancy',
        'datesWithinRange',
        
        'rateDateMode',
        'selectedRateColumn',
        'selectedRateDates',
        'editingRateDates',
        ],
 
    methods: {
        /**
         * @see data allFacilities
         */
        selectCell(rateDate){
            let dateIsOutOfRange = this.dateIsOutOfRange(rateDate.date);
            let dateIsAlreadySelected = this.selectedRateDates.includes(rateDate);
            let columnCannotBeEdited = rateDate.is_open == 0 && this.selectedRateColumn != 'rate_standard';
    
            if (dateIsOutOfRange || dateIsAlreadySelected || !this.rateDateMode || columnCannotBeEdited || this.isDisabled){
                return;
            }
            
            this.$emit('selectRateDate', rateDate);
        },

        selectRow(rateColumn){
            this.$emit('update:selectedRateColumn', rateColumn);

            let rates = Object.values(this.ratesPerDate);

            rates.forEach((r) => {
                this.selectCell(r);
            });
        },
        
        handleCellMouseDown(rateDate, rateColumn){

            this.$emit('update:selectedRateColumn', rateColumn);
            this.$emit('update:rateDateMode', true);

            this.$nextTick(() => {
                this.selectCell(rateDate);
            });
        },

        
        handleCellMouseUp(){
            if (this.selectedRateDates.length == 0){
                return;
            }
            
            this.$emit('openEditRateDates', true);
        },

        handleCellMouseEnter(rateDate){
            if (this.rateDateMode && !this.cellIsSelected(rateDate)){
                this.selectCell(rateDate);
            }
        },

        handleRowMouseDown(rateColumn){
            this.$emit('update:rateDateMode', true);
            this.$emit('update:selectedRateColumn', rateColumn);

            this.$nextTick(() => {
                this.selectRow(rateColumn);
            })
        },

        handleRowMouseEnter(rateColumn){
            if (this.rateDateMode && rateColumn == this.selectedRateColumn){
                this.selectRow(rateColumn);
            }
        },
        
        dateIsOutOfRange(date){
            let outOfRange = true
            date = new Date(date);
            this.datesWithinRange.forEach(rangeDate => {
                let sameYear = rangeDate.getFullYear() == date.getFullYear();
                let sameMonth = rangeDate.getMonth() == date.getMonth();
                let sameDay = rangeDate.getDate() == date.getDate();

                if (sameYear && sameMonth && sameDay){
                    outOfRange = false;
                }
            })
            return outOfRange;
        },

        cellIsSelected(rateDate, rateColumn){
            return rateColumn == this.selectedRateColumn && this.selectedRateDates.includes(rateDate);
        },

        cellIsBeingEdited(rateDate, rateColumn){
            return rateColumn == this.selectedRateColumn && this.editingRateDates.includes(rateDate);
        },

        getCellDisplayValue(rateDate, rateColumn){
            if (rateDate.rate_standard == null || rateDate[rateColumn] == -1){
                return '';
            }

            if (!rateDate.is_open && rateColumn == 'rate_standard'){
                return 'N/A';
            }

            if (rateColumn != 'days_checkin' && rateColumn != 'days_checkout' && rateColumn != 'min_stay' && rateColumn != 'max_stay'){
                // format number
                return rateDate[rateColumn].toLocaleString();
            }

            return rateDate[rateColumn];
        },

        cellIsUpdated(rateDate, rateColumn){
            let rateDateUnsaved = rateDate;
            let rateDateSaved = this.ratesPerDateSaved[rateDate.date];
            
            let rateUpdated = rateDateSaved[rateColumn] != rateDateUnsaved[rateColumn];

            if (rateColumn != 'rate_standard'){
                return rateUpdated;
            }

            let indicativeUpdate = rateDateUnsaved.is_indicative != rateDateSaved.is_indicative;
            let openUpdate = rateDateUnsaved.is_open != rateDateSaved.is_open;
            
            return rateUpdated || indicativeUpdate || openUpdate;
        },

        toggleBoolean(rateDate, rateColumn){
            rateDate[rateColumn] = !rateDate[rateColumn] ? 1 : 0;
        },
    },

    computed: {
        isCheckbox(){
            return this.rateColumn == 'days_checkin' || this.rateColumn == 'days_checkout';
        }
    }
}

</script>

<style>
    .rate-header{
        background-color: white;
    }
</style>
