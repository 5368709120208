<template>
    <v-container fluid>
		<v-layout row-sm column>
    		<v-col cols="2">
        		<label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
    		</v-col>
			<v-col cols="1">
				<span v-on:click="toggleValidationGuide" v-if="this.field.validation && this.field.validation.length"><v-icon>information_outline</v-icon></span>
			</v-col>

			<v-col cols="6">
				<v-alert type="info" v-if="this.showValidationGuide && this.field.validation && this.field.validation.length">
                	<div v-for="(rule, ruleIndex) in this.field.validation" :key="ruleIndex">{{ validationRuleAsString(rule) }}</div>
            	</v-alert>
			</v-col>

            <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
           	<v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
           	<v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>
    		<v-col cols="12">
       			<!-- list must be list of ['text' => x, 'value' => y] -->
       			<v-select
            		v-model="viewValue"
            		:items="userAssociationTypes"
           			autocomplete
       			></v-select>
       			<input type="hidden" :name="field.fieldName" :text="field.text" :value="field.value">

       			<v-alert type="error" dismissable value="true" v-if="errorMessages && errorMessages.length">
                	<div v-for="(error, index) in errorMessages" :key="index" >{{ error.reason }}*</div>
            	</v-alert>
   			</v-col>
		</v-layout>
	</v-container>
</template>


<script>
    /**
     * Allows the selection of a voucher type ID by choosing its name from
     * a selection downloaded from the API on creation
     */
    export default {
        name: 'ies-input-user-association-type',
        props: ['field'],
        data() {
            return {
				showValidationGuide: false,
				errorMessages: [],
                userAssociationTypes: [],
                viewValue: this.field.value,
            }
        },
        created:function() {
            this.getUserAssociationTypes();
			this.addEventListeners();
        },
        methods: {
			addEventListeners: function() {
				var self = this;

            	this.$root.$on('Field: set errors', function(payload){
                	console.log("TEXT: I heard ", payload);
                	if (payload.modelName = self.field.modelName && payload.fieldName == self.field.fieldName){
                   		self.errorMessages = payload.errors;
                    	console.log("TEXT: I set ", self.field.errors);
                	}
            	});
			},
		
			toggleValidationGuide: function(){
    	        this.showValidationGuide = !this.showValidationGuide;
	        },
			
			validationRuleAsString: function(rule){
            	console.log("explain rule");
            	var explainer = '';
           		switch(rule.ruleType){
            	    case 'minLength': explainer = "The text must be at least " + rule.value + " characters long"; break;
        	        case 'maxLength': explainer = "The text must not be more than " + rule.value + " characters long"; break;
    	            case 'minValue': explainer = "The value must be more than " + rule.value; break;
	                case 'maxValue': explainer = "The value must be less than " + rule.value; break;
                	case 'notEmpty': explainer = "The field must not be empty"; break;
                	case 'regExp': explainer = "Pattern: " + rule.value; break;
                	default:
                	    explainer = rule.ruleType + ' ' + rule.value;
            	}
            	console.log(explainer);
            	return explainer;
        	},

            getUserAssociationTypes:function(){
                this.userAssociationTypes = [
					{text: 'Reservation',   value: 'reservation'},
					{text: 'Photo Library', value: 'photo_library'}
				];
            },
        },
        watch: {
            viewValue: function (newValue) {
                this.field.value = newValue;
            }
        }
    }
</script>


