var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.allIssues.length
    ? _c(
        "v-col",
        { attrs: { cols: "12", md: "6", lg: "4", "ma-1": "" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "title font-weight-black" },
                [
                  _c("v-icon", { staticClass: "pr-3" }, [_vm._v("assignment")]),
                  _vm._v("Issues "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.showProgressIcon,
                          expression: "!showProgressIcon",
                        },
                      ],
                    },
                    [_vm._v(" (" + _vm._s(_vm.allIssues.length) + ")")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("v-progress-linear", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showProgressIcon,
                        expression: "showProgressIcon",
                      },
                    ],
                    attrs: { indeterminate: true, color: "lime" },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showProgressIcon,
                          expression: "showProgressIcon",
                        },
                      ],
                    },
                    [_vm._v("Loading...")]
                  ),
                  _vm._v(" "),
                  !_vm.showProgressIcon && _vm.allIssues.length == 0
                    ? _c("div", [
                        _vm._v("No issues require your attention right now"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-list",
                    [
                      _vm._l(_vm.data, function (item, index) {
                        return [
                          _c(
                            "v-list-item",
                            {
                              key: index,
                              attrs: { ripple: "", href: item.url },
                            },
                            [
                              _c("v-list-item-title", [
                                _c(
                                  "a",
                                  {
                                    class: {
                                      "priority-medium":
                                        item.priority > 5 && item.priority < 8,
                                      "priority-high": item.priority > 7,
                                    },
                                    attrs: { href: item.url },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { left: "", medium: "" } },
                                      [_vm._v(_vm._s(item.icon))]
                                    ),
                                    _vm._v(" " + _vm._s(item.description)),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          index + 1 < _vm.data.length
                            ? _c("v-divider")
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }