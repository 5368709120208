<template>
    <v-col cols="12" md="3">
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">pool</v-icon> Manage Facilities
            </v-card-title>

            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col>
                            <v-btn block :href="facilitiesUrl">Facilities</v-btn>
							<v-btn block :href="extraFacilitiesUrl">Extra Facilities</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

        </v-card>
    </v-col>
</template>

<script>
    /**
     * Display buttons linking through to a hotels' pages
     */
    export default {
        props:['pageid'],

        data: function() {
            return {
                facilitiesUrl: '',
				extraFacilitiesUrl: '',
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            this.facilitiesUrl = "/hotel/" + this.pageid + "/facility-management";
            this.extraFacilitiesUrl = "/hotel-extra-facilities/" + this.pageid;
        },

        methods: {},

        computed: {}
    }
</script>

