<template>
    <v-col cols="12" md="3">
    <v-card class="ma-2">
        <v-card-title class="title font-weight-black">
            <v-icon class="pr-3">home</v-icon> Hotels
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-layout row wrap>
                    <v-col cols="12"><ies-hotel-picker></ies-hotel-picker></v-col>
                    <v-col cols="12"><v-btn block href="hotels/list">List All Hotels</v-btn></v-col>
                    <v-col cols="12">
                        <fieldset>
                            <legend>Create New Hotel</legend>

                            <div v-if="step == 1">
                                <h4>1. Basic Details</h4>

                                <v-text-field v-model="hotelName" label="Hotel Name"></v-text-field>
                                <v-select
                                    v-model="regionKey"
                                    label="Region"
                                    :items="regions"
                                    autocomplete
                                ></v-select>

                                <v-select 
                                    v-model="displayStatus"
                                    label="Display Status"
                                    :items="displayStatuses"
                                    autocomplete
                                ></v-select>

                                <v-select 
                                    v-model="travelOperator"
                                    label="Travel Operator"
                                    :items="travelOperators"
                                    autocomplete
                                ></v-select>

                                <v-text-field v-model="commission" label="Commission (%)"></v-text-field>

                                <v-label>Nearest Restaurant (km):</v-label>
                                <v-text-field v-model="restaurantDistance" label="Distance"></v-text-field>

                                <v-label>Nearest Airport (km):</v-label>
                                <v-text-field v-model="airportName" label="Name"></v-text-field>
                                <v-text-field v-model="airportDistance" label="Distance"></v-text-field>
                                <v-text-field v-model="airportCode" label="Code"></v-text-field>
                            </div>


                            <div v-if="step == 2">
                                <h4>2. Contact Details</h4>
                                <v-text-field v-model="address1" label="Address 1"></v-text-field>
                                <v-text-field v-model="address2" label="Address 2"></v-text-field>
                                <v-text-field v-model="city" label="City"></v-text-field>
                                <v-text-field v-model="postCode" label="Post Code"></v-text-field>
                                <v-text-field v-model="postalAddress" label="Postal Address"></v-text-field>
                                <v-text-field v-model="website" label="Website"></v-text-field>
                                <v-text-field v-model="owner" label="Owner Name"></v-text-field>
                                <v-text-field v-model="keyContact" label="Main Contact Name"></v-text-field>
                                <v-text-field v-model="marketingName" label="Marketing Contact Name"></v-text-field>
                                <v-text-field v-model="marketingEmail" label="Marketing Email"></v-text-field>
                            </div>


                            <div v-if="step == 3">
                                <h4>3. Bookings</h4>
                                <v-text-field v-model="totalNumRooms" label="Total No. Rooms"></v-text-field>
                                <v-text-field v-model="channelManager" label="Channel Manager"></v-text-field>

                                <v-select
                                    v-model="defaultRateBasis"
                                    label="Default Rate Basis"
                                    :items="rateBasisOptions"
                                    autocomplete
                                ></v-select>

                                <v-label>Optional Extras:</v-label>
                                <v-textarea v-model="rateOptionalExtras"></v-textarea>
                            </div>


                            <div v-if="step == 4">
                                <h4>4. Policies</h4>

                                <br>
                                <div>Accepted Credit Cards:</div>
                                <label>Mastercard</label>
                                <v-checkbox hide-details v-model="acceptsMastercard"></v-checkbox>
                                <label>Visa</label>
                                <v-checkbox hide-details v-model="acceptsVisa"></v-checkbox>
                                <label>Amex</label>
                                <v-checkbox hide-details v-model="acceptsAmex"></v-checkbox>
                                <label>Diners</label>
                                <v-checkbox hide-details v-model="acceptsDiners"></v-checkbox>
                                <label>Maestro</label>
                                <v-checkbox hide-details v-model="acceptsMaestro"></v-checkbox>
                            </div>


                            <div v-if="step == 5">
                                <h4>5. Facilities (to be done via facilities manager?)</h4>
                            </div>


                            <v-btn v-on:click="previousStage()">&lt;</v-btn>
                            <v-btn v-on:click="nextStage()">&gt;</v-btn>
                            <v-btn v-on:click="save()" class="lime">Create</v-btn>
                       </fieldset>
                    </v-col>
                </v-layout>
            </v-container>
        </v-card-text>
    </v-card>
    </v-col>
</template>

<script>
    /**
     * Display buttons linking through to hotels' pages
     */
    export default {
        props:[],

        data: function() {
            return {
                regions: [],
                travelOperators: [],
                displayStatuses: [{value: 0, text: "Offline"}, {value: 1, text: "Online"}, {value: 2, text: "Preview"}],
                step: 1,
                hotelName: "",
                regionKey: null,
                displayStatus: 2,
                commission: 10.0,
                restaurantDistance: "",
                airportName: "",
                airportDistance: "",
                airportCode: "",
                travelOperator: 0,

                address1: "",
                address2: "",
                city: "",
                postCode: "",
                postalAddress: "",
                website: "",
                owner: "",
                keyContact: "",
                marketingName: "",
                marketingEmail: "",

                totalNumRooms: "",
                channelManager: "",
                defaultRateBasis: 1,
                rateBasisOptions: [{value:0, text:"Please choose..."}, {value:1, text:"Room"}, {value:2, text:"Apartment"}, {value:3, text:"Cottage"}, {value:4, text:"Villa"},
                                   {value:5, text:"House"}, {value:6, text:"Boat"}, {value:7, text:"Unit"}, {value:8, text:"Whole&nbsp;House"}],
                rateOptionalExtras: "",

                acceptsMastercard: false,
                acceptsVisa: false,
                acceptsAmex: false,
                acceptsDiners: false,
                acceptsMaestro: false
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            this.getRegions();
            this.getTravelOperators();
        },

        methods: {

            getTravelOperators: function(){
                var self = this;
                this.travelOperators.push({value: 0, text: "New Account"});
                axios.get('/api/travel-operators?x=y')
                .then((response)=>{
                    if (response.data.success){
                        console.log(response.data);
                        response.data.travelOperators.forEach(function(account){
                            self.travelOperators.push({ value: account.user_key, text: account.username });
                        });
                    }
                    // If it failed then simply tell them so
                    else {
                        alert("A problem occurred and the hotel could not be created");
                    }
                });
            },

            previousStage: function(){
                if (this.step > 0){
                    this.step--;
                }
            },

            nextStage: function(){
                if (this.step < 5){
                    this.step++;
                }
            },

            save: function(){
                axios.post('/api/hotels/new', {
                    hotelName: this.hotelName, 
                    regionKey: this.regionKey, 
                    commission: this.commission, 
                    displayStatus: this.displayStatus, 
                    travelOperator: this.travelOperator,

                    address1: this.address1,
                    address2: this.address2,
                    city: this.city,
                    postCode: this.postCode,
                    postalAddress: this.postalAddress,
                    website: this.website,
                    owner: this.owner,
                    keyContact: this.keyContact,
                    marketingName: this.marketingName,
                    marketingEmail: this.marketingEmail,

                    restaurantDistance: this.restaurantDistance,
                    airportName: this.airportName,
                    airportDistance: this.airportDistance,
                    airportCode: this.airportCode,

                    totalNumRooms: this.totalNumRooms,
                    channelManager: this.channelManager,
                    defaultRateBasis: this.defaultRateBasis,
                    rateOptionalExtras: this.rateOptionalExtras,

                    acceptsMastercard: this.acceptsMastercard,
                    acceptsVisa: this.acceptsVisa,
                    acceptsAmex: this.acceptsAmex,
                    acceptsDiners: this.acceptsDiners,
                    acceptsMaestro: this.acceptsMaestro

                })
                .then((response)=>{
                    // If it was successful then reload the voucher details so it is 
                    // displayed up to date on the page
                    if (response.data.success){
                        console.log(response.data);
                        var hotel = response.data.hotel;
                        window.location.href="/hotel/" + hotel.hotel_key + '/dashboard';
                    }
                    // If it failed then simply tell them so
                    else {
                        alert("A problem occurred and the hotel could not be created");
                    }
                });
            },


            getRegions:function(){
                axios.get('/api/selectable-regions')
                .then(function(response){
                    var regions = [];
                    response.data.regions.forEach(function(record){
                        regions.push({ text: record.region_name, value: record.region_key });
                    });
                    this.regions = regions;
                }.bind(this));
            },
        },

        computed: {}
    }
</script>
