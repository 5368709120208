var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "grid-list-xl": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "justify-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs8: "", md8: "", lg8: "", "align-center": "" } },
            [_c("h2", [_vm._v("Page Content import/export tool")])]
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs8: "", md8: "", lg8: "" } },
            [
              _c("h3", [_vm._v("Export page content")]),
              _vm._v(" "),
              _c(
                "v-flex",
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.allPages,
                      "item-text": "display_name",
                      "item-value": "portal_page_key",
                      label: "Select pages",
                      multiple: "",
                      chips: "",
                      "deletable-chips": "",
                      autocomplete: "",
                      hint: "Start typing to search for a page, You can select multiple pages",
                      "persistent-hint": "",
                    },
                    model: {
                      value: _vm.selectedPages,
                      callback: function ($$v) {
                        _vm.selectedPages = $$v
                      },
                      expression: "selectedPages",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "ies-green" },
                      on: {
                        click: function ($event) {
                          return _vm.exportTo("file")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                        Export to file\n                        "
                      ),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("save_alt"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { color: "blue-grey" },
                      on: {
                        click: function ($event) {
                          return _vm.exportTo("text")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                        Export as text\n                        "
                      ),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("format_align_left"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showExportTextArea
                ? _c(
                    "v-flex",
                    { attrs: { xs8: "", md8: "", lg8: "" } },
                    [
                      _c("textarea", { attrs: { id: "selected-textArea" } }, [
                        _vm._v(_vm._s(_vm.selectedPagesTextToDisplay)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs2: "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyToClipboard()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                Copy to Clipboard\n                                "
                                  ),
                                  _c("v-icon", { attrs: { right: "" } }, [
                                    _vm._v("file_copy"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs2: "" } },
                            [
                              _c(
                                "v-alert",
                                {
                                  attrs: {
                                    color: "success",
                                    icon: "check_circle",
                                    outline: "",
                                  },
                                  model: {
                                    value: _vm.successBar.show,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.successBar, "show", $$v)
                                    },
                                    expression: "successBar.show",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.successBar.text) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            {
                              staticClass: "text-xs-right",
                              attrs: { "align-content-end": "" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.showExportTextArea = false
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                Hide\n                            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-divider", { attrs: { id: "divider" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs8: "", md8: "", lg8: "" } },
            [
              _c("h3", [_vm._v("Import page content")]),
              _vm._v(" "),
              _c("v-flex", { attrs: { xs6: "" } }, [
                _c("span", [_vm._v("Import from file ")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "btn", attrs: { id: "fileinput-btn" } },
                  [
                    _c("input", {
                      attrs: {
                        type: "file",
                        id: "fileinput",
                        accept: "text/plain",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticStyle: { color: "gray" } }, [
                  _vm._v("Hint: you can drag and drop text file to this input"),
                ]),
              ]),
              _vm._v(" "),
              _c("textarea", {
                attrs: {
                  placeholder: "or copy valid JSON here",
                  id: "importTextArea",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs8: "", md8: "", lg8: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  !_vm.showImportContent
                    ? _c(
                        "v-flex",
                        { attrs: { xs2: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              attrs: { color: "info" },
                              on: {
                                click: function ($event) {
                                  return _vm.readImport()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Validate\n                            "
                              ),
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v("playlist_add_check"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showImportContent
                    ? _c(
                        "v-flex",
                        { attrs: { xs2: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ies-green",
                              on: {
                                click: function ($event) {
                                  return _vm.importPages()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Import\n                            "
                              ),
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v("cloud_upload"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.importBar.show
                    ? _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-alert",
                            {
                              attrs: { type: _vm.importBar.type },
                              model: {
                                value: _vm.importBar.show,
                                callback: function ($$v) {
                                  _vm.$set(_vm.importBar, "show", $$v)
                                },
                                expression: "importBar.show",
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.importBar.text) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    {
                      staticClass: "text-xs-right",
                      attrs: { "align-content-end": "" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.clearImport()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Clear\n                        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showImportContent
            ? _c(
                "v-flex",
                { attrs: { xs8: "", md8: "", lg8: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _vm.updatedPagesImportCount > 0
                        ? _c("v-flex", { attrs: { xs4: "" } }, [
                            _c("strong", [
                              _vm._v("Pages that will be updated:"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              { staticClass: "import-list" },
                              _vm._l(
                                _vm.validatedPages.update,
                                function (page) {
                                  return _c("li", [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(page.display_name) +
                                        "\n                            "
                                    ),
                                  ])
                                }
                              ),
                              0
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.newPagesImportCount > 0
                        ? _c("v-flex", { attrs: { xs4: "" } }, [
                            _c("strong", [
                              _vm._v("New pages that will be added:"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              { staticClass: "import-list" },
                              _vm._l(_vm.validatedPages.new, function (page) {
                                return _c("li", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(page.display_name) +
                                      "\n                            "
                                  ),
                                ])
                              }),
                              0
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: parseInt(6000),
            "multi-line": "",
            color: _vm.snackbar.color,
          },
          model: {
            value: _vm.snackbar.show,
            callback: function ($$v) {
              _vm.$set(_vm.snackbar, "show", $$v)
            },
            expression: "snackbar.show",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "full-height full-width v-align-container text-xs-center",
            },
            [
              _c("h4", { staticClass: "ies-dark-gray--text v-align-div" }, [
                _vm._v(_vm._s(this.snackbar.text)),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }