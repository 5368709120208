<template>
    <v-col cols="12" md="3">
    <v-card class="ma-1">
        <v-card-title class="title font-weight-black">
            <v-icon class=pr-3>info</v-icon> About Us
        </v-card-title>

        <v-card-text>
            <v-btn block href="/site-texts/1">What is i-escape</v-btn>
            <v-btn block href="/site-texts/2">Who are we</v-btn>
            <v-btn block href="/site-texts/7">Privacy</v-btn>
            <v-btn block href="/site-texts/6">T&Cs</v-btn>
            <v-btn block href="/faqs/list"><v-icon>info</v-icon> FAQs</v-btn>
            <v-btn block href="/site-texts/7"><v-icon>mail</v-icon> Contact us</v-btn>
            <v-btn block href="/site-texts/18"><v-icon>work</v-icon> Jobs</v-btn>
            <v-btn block href="/site-texts/2"><v-icon>face</v-icon> Staff Biographies</v-btn>
        </v-card-text>
    </v-card>
    </v-col>
</template>

<script>
    /**
     * Display buttons linking through to hotels' pages
     */
    export default {
        props:[],

        data: function() {
            return {
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){},

        methods: {},

        computed: {}
    }
</script>
