<template>
    <v-container>
        <v-layout row wrap>
            <v-col v-if="this.field.schema.displayName != 'none'" cols="2">
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-col>

			<v-alert type="info" v-if="this.showValidationGuide && this.field.validation && this.field.validation.length">
                <div v-for="(rule, ruleIndex) in this.field.validation" :key="ruleIndex">{{ validationRuleAsString(rule) }}</div>
            </v-alert>

            <v-col cols="10">
                <v-btn v-on:click="chooseCurrency" 
                       v-show="!shouldShowPicker">{{ buttonLabel }}</v-btn>
                <v-btn v-on:click="clearCurrency" 
                       v-show="selectedCurrency">Clear</v-btn>
            </v-col>

            <v-col cols="10" v-if="shouldShowPicker">
                <v-container>
                    <v-layout row wrap>
 
                        <v-col cols="2">Currency: </v-col>
                        <v-col cols="10">
                            <v-text-field
                                v-model="currencyViewName" 
                                v-on:keyup="updateCurrencyName()"
                             ></v-text-field>
                         </v-col>

                         <v-col cols="12" v-if="currencyOptions.length">
                         	<ul>
                            	<li v-for="currency in currencyOptions" v-on:click="selectCurrency(currency.currency_key)">({{ currency.currency_code }}) {{ currency.currency_name }}</li>
							</ul>
						</v-col>

						<v-col cols="12">
							<v-btn v-on:click="done">Done</v-btn>
						</v-col>

					</v-layout>
				</v-container>
			</v-col>

			<input type="hidden" :name="field.fieldName" :value="field.value">

			<v-alert type="error" dismissable value="true" v-if="errorMessages && errorMessages.length">
            	<div v-for="(error, index) in errorMessages" :key="index" >{{ error.reason }}*</div>
        	</v-alert>

    	</v-layout>
	</v-container>
</template>

<script>
    /**
     * Allows the selection of an currency ID by choosing its name or code from
     * a selection downloaded from the API on creation
     *
     * @todo refactor, make more efficient
     */
    export default {
        name: 'ies-input-currency-key',
        props: ['field', 'configuration'],
        data() {
            return {
				showValidationGuide: false,

				errorMessages: [],

                /** string view model */
                currencyViewName: '',

                /** array of operator options presented to the user */
                currencyOptions: [],

                /** data model of all supported currencies */
                currencies: [],

                /** view model to determine whether the hotel  picker should display */
                shouldShowPicker: false,

                /** The operator chosen by the user */
                viewValue: parseInt(this.field.value),

                selectedCurrency: null,

                /** For where the component is used in event mode to talk to other components */
                config: {'shouldEmitOnSelect': false, 'selectEventName': ''}
            }
        },
        created:function() {
            this.getCurrencies();

            if (this.configuration){
                var config = JSON.parse(this.configuration);
                if (config.shouldEmitOnSelect && config.selectEventName){
                    this.config.shouldEmitOnSelect = config.shouldEmitOnSelect;
                    this.config.selectEventName = config.selectEventName;
                }
            }
			
			this.addEventListeners();
        },
        methods: {
			addEventListeners: function() {
				var self = this;
				this.$root.$on('Field: set errors', function(payload){
                	console.log("TEXT: I heard ", payload);
                	if (payload.modelName = self.field.modelName && payload.fieldName == self.field.fieldName){
                   		self.errorMessages = payload.errors;
                    	console.log("TEXT: I set ", self.field.errors);
                	}
            	});
			},

            getCurrencies:function(){
                var self = this;
                axios.get('/api/currencies')
                .then(function(response){
                    this.currencies = response.data.currencies;
					console.log(this.currencies);
                    this.currencies.forEach(function(currency){
                        if (currency.currency_key == self.field.value){
                            self.selectedCurrency = currency;

                            // Set the view models so that it is reflected to the users
                            self.currencyViewName = currency.currency_name;
                        }
                    });
                }.bind(this));
            },

            updateCurrencyName: function(){
                var self = this;
 
                var currencyOptions = [];
                this.currencies.forEach(function(currency){
                    if (currency.currency_name.toLowerCase().includes(self.currencyViewName.toLowerCase()) || currency.currency_code.toLowerCase().includes(self.currencyViewName.toLowerCase())){
                        currencyOptions.push(currency);
                    }
                });
                this.currencyOptions = currencyOptions;

                // If there is only one hotel left in the list of options then we select it
                if (this.currencyOptions.length === 1){
                    this.selectedCurrency = this.currencyOptions[0];
                    this.updateFieldValue();
                }
            },

            selectCurrency: function(currencyKey){
                var self = this;
                this.currencies.forEach(function(currency){
                    if (currency.currency_key === currencyKey){
                        self.selectedCurrency = currency;
                        self.currencyViewName = currency.currency_name;
                        self.updateFieldValue();
                    }
                });
            },

            chooseCurrency: function(){
                this.shouldShowPicker = true;
            },

            clearCurrency: function(){
                this.selectedCurrency = null;
                this.currencyViewName = '';
                this.field.value = null;

                if (this.config.shouldEmitOnSelect){
                    console.log("I should emit ", this.config.selectEventName);
                    this.$root.$emit(this.config.selectEventName, {value: null});
                }
            },

            done: function(){
                this.shouldShowPicker = false;
            },

            updateFieldValue: function(){
                this.field.value = parseInt(this.selectedCurrency.currency_key);

                if (this.config.shouldEmitOnSelect){
                    this.$root.$emit(this.config.selectEventName, {value: this.field.value});
                }
            },

			toggleValidationGuide: function(){
    	        this.showValidationGuide = !this.showValidationGuide;
	        },

			validationRuleAsString: function(rule){
            	console.log("explain rule");
            	var explainer = '';
           		switch(rule.ruleType){
            	    case 'minLength': explainer = "The text must be at least " + rule.value + " characters long"; break;
        	        case 'maxLength': explainer = "The text must not be more than " + rule.value + " characters long"; break;
    	            case 'minValue': explainer = "The value must be more than " + rule.value; break;
	                case 'maxValue': explainer = "The value must be less than " + rule.value; break;
                	case 'notEmpty': explainer = "The field must not be empty"; break;
                	case 'regExp': explainer = "Pattern: " + rule.value; break;
                	default:
                	    explainer = rule.ruleType + ' ' + rule.value;
            	}
            	console.log(explainer);
            	return explainer;
        	},
        },
        watch: {
            viewValue: function (newValue) {
            }
        },
        computed: {
            buttonLabel: function(){
                return this.selectedCurrency ? "(" + this.selectedCurrency.currency_code + ") " + this.selectedCurrency.currency_name : "Choose";
            }
        }

    }
</script>



