<template>
    <v-flex xs12 ma-1>
        <v-card>

            <!--
            <v-card-title>
                Accounts Overview
            </v-card-title>
            -->

            <v-card-text>
               
                <v-layout row>

                    <!-- Bookings statistics - Previous week -->
                    <v-flex xs12>
                        <v-data-table
                            :headers="headers"
                            :items="stats"
                            class="elevation-1"
                            hide-default-footer
                            disable-initial-sort

                        >
                            <template slot="items" slot-scope="props">
                                <td>{{ props.item.title }}</td>
                                <td>{{ props.item.units }}</td>
                                <td class="text-xs-right">{{ props.item.value0 }}</td>
                                <td class="text-xs-right">{{ props.item.value1 }}</td>
                                <td class="text-xs-right">{{ props.item.value2 }}</td>
                                <td class="text-xs-right">{{ props.item.value3 }}</td>
                                <td class="text-xs-right">{{ props.item.value4 }}</td>
                                <td class="text-xs-right">{{ props.item.value5 }}</td>
                                <td class="text-xs-right">{{ props.item.value6 }}</td>
                            </template>

                        </v-data-table>
                    </v-flex>

                </v-layout>
                
            </v-card-text>

        </v-card>
    </v-flex>
</template>

<script>
    /**
     * Display bookings statistics for previous week
     *
     * @todo There is some duplication between the API loading and the setter methods which could be trimmed neatly into a single dynamic method each, but right now
     * this is being left in place because the API is in such early days it is safer for us to accept slight duplication and keep a more robust component than to trim
     * it and make it potentially more fragile as a result.  When the day comes that this becomes a hinderence rather than an advantage we will need to refactor the methods
     * into one
     *
     * @note The headers are fixed, hard coded to specific names as the ticket dictates.  In future this could also be made dynamic so the component can be more flexible
     */
    export default {
        props:[],

        data: function() {
            return {
                /** @var [] headers - The Vuetify table headings data */
                headers: [ 
                    {
                        text:   'Bookings',
                        value:  'title'
                    }, 
                    {
                        text:   '',
                        value:  'units'
                    }, 
                    {
                        text:   'Today (T)',
                        value:  'value0',
                        align:  'right'
                    }, 
                    {
                        text:   'T-1',
                        value:  'value1',
                        align:  'right'
                    },
                    {
                        text:   'T-2',
                        value:  'value2',
                        align:  'right'
                    },
                    {
                        text:   'T-3',
                        value:  'value3',
                        align:  'right'
                    },
                    {
                        text:   'T-4',
                        value:  'value4',
                        align:  'right'
                    },
                    {
                        text:   'T-5',
                        value:  'value5',
                        align:  'right'
                    },
                    {
                        text:   'T-6',
                        value:  'value6',
                        align:  'right'
                    }
                ],

                /** @var {} data - The bookings data to be presented to the user */
                data: { newEnquiries: [], quotesAccepted: [], noAvailabilities: [], cancellations: [] }
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            this.loadData();
        },

        methods: {
            /**
             * Load all bookings statistics for the given parameters, currently fixed as only to the past week from today inclusive for each of the main status types
             */
            loadData: function(){
                var today       = new Date();
                var startDate   = new Date();
                var numDays     = 6;
                startDate.setDate( today.getDate() - numDays );

                // Make the URL-safe date parameters as yyyy-mm-dd format
                var dateTo   = today.getFullYear() + '-' + (today.getMonth() + 1 < 10 ? '0' : '') + (today.getMonth() + 1) + '-' + (today.getDate() < 10 ? '0' : '') + today.getDate();
                var dateFrom = startDate.getFullYear() + '-' + (startDate.getMonth() + 1 < 10 ? '0' : '') + (startDate.getMonth() + 1) + '-' + (startDate.getDate() < 10 ? '0' : '') + startDate.getDate();

                // Request that the stats data is loaded from the API, for the same date range each
                this.loadEnquiries(dateFrom, dateTo);
                this.loadQuotesAccepted(dateFrom, dateTo);
                this.loadNoAvailability(dateFrom, dateTo);
                this.loadCancellations(dateFrom, dateTo);
            },

            /**
             * Method dedicated to loading the new-enquiries from the API for the given date range
             */
            loadEnquiries: function(dateFrom, dateTo){
                var params = { dateFrom: dateFrom, dateTo: dateTo, granularity: 'day', type: 'new-enquiries' };

                axios.get('/api/bookings/statistics', { params: params })
                .then(this.setEnquiries)
                .catch(function (error) {
                    console.log('%cError loading New Enquiries statistics from API', 'color:red');
                    console.log(error);
                });
            },

            /**
             * Setter method used to unpack the API response data on new enquiries into the format used in this component
             */
            setEnquiries: function(response){
                this.data.newEnquiries = response.data;
            },

            loadQuotesAccepted: function(dateFrom, dateTo){
                var params = { dateFrom: dateFrom, dateTo: dateTo, granularity: 'day', type: 'quotes-accepted' };

                axios.get('/api/bookings/statistics', { params: params })
                .then(this.setQuotesAccepted)
                .catch(function (error) {
                    console.log('%cError loading Quotes Accepted statistics from API', 'color:red');
                    console.log(error);
                });
            },

            setQuotesAccepted: function(response){
                this.data.quotesAccepted = response.data;
            },

            loadNoAvailability: function(dateFrom, dateTo){
                var params = { dateFrom: dateFrom, dateTo: dateTo, granularity: 'day', type: 'no-availabilities' };

                axios.get('/api/bookings/statistics', { params: params })
                .then(this.setNoAvailabilities)
                .catch(function (error) {
                    console.log('%cError loading No Availability statistics from API', 'color:red');
                    console.log(error);
                });
            },

            setNoAvailabilities: function(response){
                this.data.noAvailabilities = response.data;
            },

            loadCancellations: function(dateFrom, dateTo){
                var params = { dateFrom: dateFrom, dateTo: dateTo, granularity: 'day', type: 'cancellations' };

                axios.get('/api/bookings/statistics', { params: params })
                .then(this.setCancellations)
                .catch(function (error) {
                    console.log('%cError loading Cancellation statistics from API', 'color:red');
                    console.log(error);
                });
            },

            setCancellations: function(response){
                this.data.cancellations = response.data;
            },


            /**
             * Returns a view model object representing the data ready to be used by the view
             * Accepts the name and units of measurement along with the array of raw data to be packaged and summarised into the view model to be returned
             *
             * @param string title
             * @param string units
             * @param [] rawData
             * @return {}
             * @see computed:stats()
             */
            getViewModel: function(title, units, rawData){
                var viewModel = { title: title, units: units };
                var index = 0;
                for (const key in rawData){
                    var record = rawData[key];
                    
                    // The Vuetify table expects each column to be based on unique named properties
                    viewModel[ 'value' + index ] = record.count;
                    index++;
                }
                return viewModel;
            },

            /**
             * return {}
             */
            getConversionRateModel: function(){
                var viewModel = { title: 'Conversion Rate (Accepted/New)', units: '%' };
                var index     = 0;

console.log('dude:');
                for (const key in this.data.quotesAccepted){

console.log(this.data.newEnquiries[key]);
console.log(this.data.quotesAccepted[key]);

                    if (this.data.quotesAccepted[key] && this.data.newEnquiries[key]){

                    var numAccepted  = this.data.quotesAccepted[key].count;
                    var numEnquiries = this.data.newEnquiries[key].count;
                    var conversionRate = (numEnquiries) ? (100 * (numAccepted / numEnquiries)).toFixed(2) : 0.00;
                    
                    // The Vuetify table expects each column to be based on unique named properties
                    viewModel[ 'value' + index ] = conversionRate;
                    index++;

                    }
                }

                return viewModel;
            },

            /**
             * @return {}
             */
            getQuotesAcceptedModel: function(){
                var viewModel = { title: 'Value of Quotes Accepted', units: '£' };
                var index = 0;
                for (const key in this.data.quotesAccepted){
                    var record = this.data.quotesAccepted[key];

                    // The Vuetify table expects each column to be based on unique named properties
                    viewModel[ 'value' + index ] = record.quote.sum.toFixed(2);
                    index++;
                }
                return viewModel;
            }
        },

        computed: {
        
            /**
             * Iterates over the data stored in this component and constructs a new array of computed properties ready formatted for the view template to use
             *
             * return []
             */
            stats: function(){
                var viewModels  = [];

                // Use separate reusable methods to package the data to keep this function as small as possible
                viewModels.push( this.getViewModel('New Enquiries',     'No.', this.data.newEnquiries) );
                viewModels.push( this.getViewModel('Quotes Accepted',   'No.', this.data.quotesAccepted) );
                viewModels.push( this.getViewModel('No Availabilities', 'No.', this.data.noAvailabilities) );
                viewModels.push( this.getConversionRateModel() );
                viewModels.push( this.getViewModel('Cancellations',     'No.', this.data.cancellations) );
                viewModels.push( this.getQuotesAcceptedModel() );

                // Return the entire array
                return viewModels;
            }
        
        }
    }
</script>
