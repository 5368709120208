<template>
    <v-container fluid>
       <v-layout row-sm column>
            <v-flex v-if="this.field.schema.displayName != 'none'" xs12>
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}<span v-if="this.field.required">*</span>: <span v-on:click="toggleValidationGuide" v-if="this.field.validation && this.field.validation.length"><v-icon>information_outline</v-icon></span></label>
            </v-flex>
            
            <v-alert type="info" v-if="this.showValidationGuide && this.field.validation && this.field.validation.length">
                <div v-for="(rule, ruleIndex) in this.field.validation" :key="ruleIndex">{{ validationRuleAsString(rule) }}</div>
            </v-alert>

            <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            <v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            <v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>
            <v-alert type="error" dismissable value="true" v-if="errorMessages && errorMessages.length">
                <div v-for="(error, index) in errorMessages" :key="index" >{{ error.reason }}*</div>
            </v-alert>
            <v-flex xs12 sm6>
                <textarea :id="this.field.fieldName + '_textarea'" :name="this.field.fieldName" type="text" v-model="input" v-on:keyup="updateField()" :disabled="this.field.disabled">
                </textarea>
            </v-flex>
           <v-alert type="error" :value="field.errors" style="width: 100%;" v-if="field.errors">
               <li v-for="error in field.errors">
                   {{ error }}
               </li>
           </v-alert>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'ies-text',
    props: ['field'],
    data () {
        return {
            input: this.field.value || this.field.oldValue,
            errorMessages: [],
            showValidationGuide: false
        }
    },
    mounted: function(){
        this.registerEventListeners();
		this.adjustComponentHeight();
        console.log(this.field);
    },
    methods: {
        registerEventListeners: function(){
            var self = this;
            this.$root.$on('Field: set errors', function(payload){
                console.log("TEXTAREA: I heard ", payload);
                if (payload.modelName = self.field.modelName && payload.fieldName == self.field.fieldName){
                    self.errorMessages = payload.errors;
                    console.log("TEXTAREA: I set ", self.field.errors);
                }
            });

            this.$root.$on('Field: clear errors', function(payload){
                if (payload.modelName = self.field.modelName && payload.fieldName == self.field.fieldName){
                    self.field.errors = null;
                }
            });
        },

        updateField: function(){
            this.field.value = this.input
            this.errorMessages = [];
        },

        toggleValidationGuide: function(){
            this.showValidationGuide = !this.showValidationGuide;
        },

        validationRuleAsString: function(rule){
            console.log("explain rule");
            var explainer = '';
            switch(rule.ruleType){
                case 'minLength': explainer = "The text must be at least " + rule.value + " characters long"; break;
                case 'maxLength': explainer = "The text must not be more than " + rule.value + " characters long"; break;
                case 'minValue': explainer = "The value must be more than " + rule.value; break;
                case 'maxValue': explainer = "The value must be less than " + rule.value; break;
                case 'notEmpty': explainer = "The field must not be empty"; break;
                case 'regExp': explainer = "Pattern: " + rule.value; break;
                default:
                    explainer = rule.ruleType + ' ' + rule.value;
            }
            console.log(explainer);
            return explainer;
        },
   		
		adjustComponentHeight: function() {
			var self = this;
			var textarea = document.getElementById(self.field.fieldName + '_textarea');
			var minHeight = 100;
			console.log(self.field.fieldName + '_textarea');
			console.log(Math.max(textarea.scrollHeight, minHeight));
  			textarea.style.height = "";
			textarea.style.height = (Math.max(textarea.scrollHeight, minHeight) + 5) + "px";
		}
    }
}
</script>


