<template>
    <v-container>
       <v-card v-if="metaData.primaryKey">
           <v-layout wrap>
               <v-card flat>
                   <v-container fluid>
                       <v-layout row wrap>
                           <v-col cols="12">
                               <h2><v-icon>history</v-icon> Record History</h2>
                               <v-btn v-on:click="getHistory">Load History</v-btn>
                           </v-col>

                           <v-col cols="12" v-if="noHistory">
                                Sorry, there is no recorded history for the data on this page
                           </v-col>

                           <v-col cols="12" v-for="(recordChange, key) in history" :key='key'>
                               <v-container fluid>

                               <v-layout row wrap>
                                   <v-col cols="12" sm="2">
                                        Changed At:
                                   </v-col>
                                   <v-col cols="12" sm="10">
                                       <span>{{ recordChange.creationDate }}</span> at <span>{{ recordChange.creationTime }}</span>
                                   </v-col>

                                    <v-col cols="12" sm="2">
                                        Changed By:
                                    </v-col>
                                    <v-col cols="12" sm="10">
                                       <a v-on:click="emailChanger(recordChange.userEmail, recordChange.pageURL, recordChange.creationDate)">{{ recordChange.userName }}</a>
                                       ({{ recordChange.userType }})
                                    </v-col>

                                    <v-col cols="12" sm="2">
                                        Changed on Page:
                                    </v-col>
                                   <v-col cols="12" sm="10">
                                       <a :href="recordChange.pageURL" target="_blank">{{ recordChange.pageURL }}</a>
                                   </v-col>

                                   <v-col cols="2">
                                       <strong>Field:</strong>
                                   </v-col>
                                   <v-col cols="2">
                                       <strong>Changed From:</strong>
                                   </v-col>
                                   <v-col cols="8">
                                       <strong>Changed To:</strong>
                                   </v-col>

                                   <v-col cols="12" 
                                          v-for="(field, key) in recordChange.record_changes" 
                                          :key='key'>

                                       <v-layout row wrap>
                                           <v-col cols="2">
                                               <span>{{ field.fieldName }}</span>
                                           </v-col>
                                           <v-col cols="2">
                                               <span>{{field.old_value }}</span>
                                           </v-col>
                                           <v-col cols="8">
                                               <span>{{ field.new_value }}</span>
                                           </v-col>
                                      </v-layout>

                                   </v-col>

                                   <v-col cols="12">
                                       <hr>
                                   </v-col>

                               </v-layout>

                               </v-container>
                           </v-col>
                       </v-layout>
                   </v-container>

               </v-card>
           </v-layout>
       </v-card>
    </v-container>
</template>

<script>
    import axios from 'axios'; 

    export default {
        props:['metaData'],

        data: function() {
            return {
                history:    undefined,
                lastSaved:  0,
                pageOpened: Date.now()
           }
        },

        methods:{


            /**
             * Updates the record history on screen by requesting the data from the server and updating the model views
             */
            getHistory(){
                var history = [];
                axios.get('/ajax/' + this.metaData.editUrl + '/' + this.metaData.primaryKey + '/history')
                                .then(function(response){
                                    this.history = response.data;
                }.bind(this));
            },


            /**
             * Formats an email to conveniently link the page to an email recipient in the users default email client
             */
            emailChanger(emailAddress, pageURL, dateTime){
                var emailAddress = emailAddress;
                var subject      = 'Re: Your Changes to ' + pageURL + ' on ' + dateTime;
                var body         = 'URL: ' + window.location.href + '%0D%0ADate/Time: ' + dateTime;
                var url          = 'mailto:' + emailAddress + '?subject=' + subject + '&body=' + body;
                window.location.assign(url);
            },


        },

        computed: {
            /**
             * Used to show a message to the user if there is no history in the database for the given record
             */
            noHistory: function(){
                return Array.isArray(this.history) && this.history.length == 0;
            }
        }
    }

</script>
