<template>
    <div>
        <h1>Secure Hosting</h1>
        <v-expansion-panel>
            <v-expansion-panel-content  :value="1">
                <template slot="header">
                    Month Picker {{ date }}
                </template>
                <!-- date -->
                <v-layout justify-center ma-4>
                    <v-date-picker color="lime" v-model="date" :landscape="true" type="month"/>
                </v-layout>
                <!-- group by day -->
                <v-layout justify-center ma-2>   
                    <v-switch v-model="groupByDay" :label="`Group by day ${groupByDay.toString()}`" />
                </v-layout>
                <!-- submit -->
                <v-layout justify-center ma-2>
                    <v-btn @click="submit">Submit</v-btn>
                </v-layout>
            </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Vouchers data -->
        <v-expansion-panel>
            <v-expansion-panel-content>
                <template slot="header">
                    <span class="subheader text-xs-left">Vouchers Purchased</span>
                    <span class="subheader text-xs-right">Count: {{ vouchers.count }} | Total Value: {{ vouchers.total_value | pounds }} | Total Charged: {{ vouchers.total_transaction | pounds}}</span>
                </template>
                <v-data-table
                    :headers="voucherHeaders()"
                    :items="vouchers.data"
                    hide-default-footer
                    class="elevation-1"
                >
                    <template slot="items" slot-scope="props">
                        <td class="text-xs-center">{{ props.item.creation_date }}</td>
                        <td class="text-xs-center">{{ props.item.voucher_type }}</td>
                        <td class="text-xs-center">{{ props.item.delivery_type }}</td>
                        <td class="text-xs-center">{{ props.item.voucher_value | pounds }}</td>
                        <td class="text-xs-center">{{ props.item.transaction_value | pounds }}</td>
                    </template>
                </v-data-table>
            </v-expansion-panel-content>
        </v-expansion-panel>   
        
        <!-- Secure Hosting data -->
        <v-expansion-panel>
            <v-expansion-panel-content :value="1">
                <template slot="header">
                    <span class="subheader text-xs-left">
                        Secure Hosting
                    </span>
                    <span class="subheader text-xs-right">
                        Count: {{ secureHosting.count }} | 
                        Total Invoiced: {{ secureHosting.total_invoiced | pounds }} |
                        Total Deposit: {{ secureHosting.total_deposit | pounds }} |
                        Total Commission: {{ secureHosting.total_commission | pounds }} | 
                        Total CC Transaction: {{ secureHosting.total_cc | pounds}} |
                        Total Secure Hosting: {{ secureHosting.total_sh | pounds}}
                        
                    </span>
                </template>
                <v-data-table
                    :headers="secureHostingHeaders()"
                    :items="secureHosting.data"
                    hide-default-footer
                    class="elevation-1"
                >
                    <template slot="items" slot-scope="props">
                        <td class="text-xs-center">{{ props.item.timestamp }}</td>
                        <td class="text-xs-center">{{ props.item.reference }}</td>
                        <td class="text-xs-center">{{ props.item.type_of_hotel }}</td>
                        <td class="text-xs-center">{{ props.item.is_advance_payment}}</td>
                        <td class="text-xs-center">{{ props.item.quote_amount_gbp | pounds }}</td>
                        <td class="text-xs-center">{{ props.item.deposit_amount_gbp | pounds }}</td>
                        <td class="text-xs-center">{{ props.item.commission_amount_gbp | pounds }}</td>
                        <td class="text-xs-center">{{ props.item.total_invoiced | pounds }}</td>
                        <td class="text-xs-center">{{ props.item.voucher_value_redeemed | pounds }}</td>
                        <td class="text-xs-center">{{ props.item.cc_transaction_amount | pounds }}</td>
                        <td class="text-xs-center">{{ props.item.payment_method }}</td>
                        <td class="text-xs-center">{{ props.item.local_secure_hosting | pounds }}</td>
                        <td class="text-xs-center">{{ props.item.confirmation_code }}</td>
                    </template>
                </v-data-table>
            </v-expansion-panel-content>
        </v-expansion-panel>   
    </div>
</template>

<script>
export default {
    data() {
        return {
            date: '',
            groupByDay: true,
            secureHosting: {},
            vouchers: {}
        }
    },
    created() {
        this.setDate();
        this.getSecureHosting();
        this.getVouchers();

    },
    methods: {
        async getSecureHosting() {
            const res = await axios.get('api/accounts/secure-hosting', {
                method: 'GET',
                params: {
                    date: this.date,
                    groupByDay: this.groupByDay
                }
            });

            this.secureHosting = res.data;
        },
        async getVouchers() {
            const res = await axios.get('api/accounts/vouchers', {
                method: 'GET',
                params: {
                    date: this.date,
                    groupByDay: this.groupByDay
                }
            });

            this.vouchers = res.data;
        },
        submit() {
            this.getSecureHosting();
            this.getVouchers();
        },
        setDate() {
            const date = new Date(Date.now());
            this.date = `${date.getFullYear()}-0${date.getMonth() + 1}`;
        },
        voucherHeaders() {
            return [
                {
                    text: 'Timestamp',
                    align: 'center',
                    sortable: true,
                    value: 'timestamp'
                },
                {
                    text: 'Voucher Type',
                    align: 'center',
                    sortable: false,
                    value: 'voucher_type'
                },
                {
                    text: 'Delivery Type',
                    align: 'center',
                    sortable: false,
                    value: 'delivery_type'
                },
                {
                    text: 'Voucher Value',
                    align: 'center',
                    sortable: false,
                    value: 'voucher_value'
                },
                {
                    text: 'Amount Charged',
                    align: 'center',
                    sortable: false,
                    value: 'amount_charged' 
                }
            ]
        },
        secureHostingHeaders() {
             return [
                {
                    text: 'Timestamp',
                    align: 'center',
                    sortable: true,
                    value: 'timestamp'
                },
                {
                    text: 'Reference',
                    align: 'center',
                    sortable: false,
                    value: 'reference'
                },
                {
                    text: 'Type Of Hotel',
                    align: 'center',
                    sortable: false,
                    value: 'type_of_hotel'
                },
                {
                    text: 'Prepaid Booking',
                    align: 'center',
                    sortable: false,
                    value: 'is_advance_payment'
                },
                {
                    text: 'Quote Amount',
                    align: 'center',
                    sortable: false,
                    value: 'quote_amount_gbp' 
                },
                {
                    text: 'Deposit Amount',
                    align: 'center',
                    sortable: false,
                    value: 'deposit_amount_gbp' 
                }, 
                {
                    text: 'Commission Amount',
                    align: 'center',
                    sortable: false,
                    value: 'commission_amount_gbp' 
                }, 
                {
                    text: 'Total Invoiced',
                    align: 'center',
                    sortable: false,
                    value: 'total_invoiced' 
                }, 
                {
                    text: 'Voucher Redeemed',
                    align: 'center',
                    sortable: false,
                    value: 'voucher_value_redeemed' 
                },
                                {
                    text: 'Credit Card Transaction',
                    align: 'center',
                    sortable: false,
                    value: 'cc_transaction_amount' 
                },
                {
                    text: 'Payment Method',
                    align: 'center',
                    sortable: false,
                    value: 'payment_method' 
                }, 
                {
                    text: 'Local Secure Hosting',
                    align: 'center',
                    sortable: false,
                    value: 'local_secure_hosting' 
                }, 
                {
                    text: 'Confirmation Code',
                    align: 'center',
                    sortable: false,
                    value: 'confirmation_code' 
                },
            ];
        }
    }
}
</script>
