<script>
    /**
     * Wrapper for Vue-ChartJS, providing fully automated dynamic polar chart support for the data set named in the prop
     *
     *
     */

    // Import the application event bus so we can listen for data changes and react accordingly
    import EventBus from '../../../eventBus';

    // Import Vue-ChartJS to perform the rendering for us
    import VueCharts from 'vue-chartjs';
    import { PolarArea } from 'vue-chartjs';


    export default{
        extends:    PolarArea,
        props:      ['datasource', 'title', 'viewid'],
        data:       function() {
            return {
                data:       {},
                metaData:   {},
                page:       {},
                report:     {},
                loaded:     false,
            }
        },

        methods:    {
            /**
             * Callback function for event listeners to call when the service updates its data
             */
            loadData(){
                // Load the fresh data from the page service
                if (this.datasource){
                    var allData     = window.app.pageService.getPageData();
                    this.data       = allData[this.datasource];
                    this.metaData   = window.app.pageService.getMetaData();
                    this.loaded     = true;
                    this.config     = window.app.pageService.getVisualisation(this.datasource, this.viewid);
                }
            },

            /**
             * Request VueChartJS re-render the chart
             */
            updateView: function(){
                // Overwriting base render method with actual data
                this.renderChart({
                    labels:                  this.getLabels(),
                    datasets: [
                        {
                            label:           this.metaData.title,
                            backgroundColor: this.getColours(),
                            data:            this.getValues()
                        }
                    ]
                },
                {responsive: true, maintainAspectRatio: false}
                )
            },

            getLabels: function(){
                var labels = [];
                var parts  = this.config.modelFieldA.split('.');
                var labelModelName = parts[0];
                var labelFieldName = parts[1];

                this.data.data.forEach(function(record){
                    labels.push(record[labelFieldName]);
                });
                return labels;
            },

            getValues: function(){
                var values = [];
                var parts  = this.config.modelFieldB.split('.');
                var valueModelName = parts[0];
                var valueFieldName = parts[1];

                this.data.data.forEach(function(record){
                    values.push(record[valueFieldName]);
                });
                return values;
            },

            getColours: function(){
                var colours = [];
                var palette = ['#C1CD23', '#F15C61', '#088193', '#23C1CD', '#61F15C', '#CC99FF', '#ff9966', '#ff99ff', '#5C61F1', '#819308', '#FFFF66', '#CCFFFF'];
                
                var index = 0;
                this.data.data.forEach(function(record){
                    var chosenColour = palette[index];
                    colours.push(chosenColour);
                    index ++;
                    if (index >= palette.length){
                        index = 0;
                    }
                });

                return colours;
            }
        },


        /**
         * Tasks to perform once the Vue component has been constructed and page load event is fired
         *
         */
        mounted () {
            this.data.data = this.content;
            this.updateView();
        },

        /**
         * Tasks to perform when data variables are changed.  This cannot be done by computed properties in this case because we need to call a function
         * to perform the re-render within the parent VueChartJS component
         */
        watch:{
            /**
             * If the main data set is updated then redraw the graph
             */
            data: function(){
                this.updateView();
            }
        },
}
</script>
