<template>
    <v-container fluid>
       <v-layout row-sm column>
            <v-col v-if="this.field.schema.displayName != 'none'" cols="12">
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-col>

            <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            <v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            <v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>

            <v-row>
				<v-col md="12" class="d-flex">
					<input type="hidden" :name="field.fieldName" :value="field.value">
					
					<v-col md="4" class="d-flex flex-column">
						<v-col>
							<label for="beach">Beach (within 5 mins?):</label>
							<input name="beach" type="checkbox" v-model="location.beach">
						</v-col>
						<v-col>
							<label for="city">City:</label>
							<input name="city" type="checkbox" v-model="location.city">
						</v-col>
						<v-col>
							<label for="coast">Coast:</label>
							<input name="coast" type="checkbox" v-model="location.coast">
						</v-col>
						<v-col>
							<label for="desert">Desert:</label>
							<input name="desert" type="checkbox" v-model="location.desert">
						</v-col>
					</v-col>

					<v-col md="4" class="d-flex flex-column" >
						<v-col>
							<label for="forest">Forest:</label>
							<input name="forest" type="checkbox" v-model="location.forest">
						</v-col>
						<v-col>
							<label for="island">Island:</label>
							<input name="island" type="checkbox" v-model="location.island">
						</v-col>
						<v-col>
							<label for="lake">Lake:</label>
							<input name="lake" type="checkbox" v-model="location.lake">
						</v-col>
						<v-col>
							<label for="mountain">Mountain:</label>
							<input name="mountain" type="checkbox" v-model="location.mountain">
						</v-col>
				    </v-col>
					
					<v-col md="4" class="d-flex flex-column">
						<v-col>
							<label for="naturereserve">Nature Reserve:</label>
							<input name="naturereserve" type="checkbox" v-model="location.naturereserve">
						</v-col>
						<v-col>
							<label for="river">River:</label>
							<input name="river" type="checkbox" v-model="location.river">
						</v-col>
						<v-col>
							<label for="rural">Rural:</label>
							<input name="rural" type="checkbox" v-model="location.rural">
						</v-col>
						<v-col>
							<label for="villagetown">Village / Town:</label>
							<input name="villagetown" type="checkbox" v-model="location.villagetown">
						</v-col>
					</v-col>

				</v-col>
				<v-col>
					 <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
				  		 <li v-for="error in field.errors">
					  		 {{ error }}
						 </li>
					 </v-alert>
				</v-col>
            </v-row>
         </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'ies-location-type',
    props: ['field'],
    data () {
        return {
            location: { 
				'beach': false, 
				'city': false, 
				'coast': false,
				'desert': false,
				'forest': false,
				'island': false,
				'lake': false,
				'mountain': false,
				'naturereserve': false,
				'river': false,
				'rural': false,
				'villagetown': false 
			},
        }
    },
    methods: {
		updateViewFromModel: function(){
			this.location.beach         = this.field.value & 1;
			this.location.city          = this.field.value & 2;
			this.location.coast         = this.field.value & 4;
			this.location.desert        = this.field.value & 8;
			this.location.forest        = this.field.value & 16;
			this.location.island        = this.field.value & 32;
			this.location.lake          = this.field.value & 64;
			this.location.mountain      = this.field.value & 128;
			this.location.naturereserve = this.field.value & 256;
			this.location.river         = this.field.value & 512;
			this.location.rural         = this.field.value & 1024;
			this.location.villagetown   = this.field.value & 2048;
		},

		updateModelFromView: function(newValue) {
			this.field.value = (this.location.beach)         ? this.field.value |= 1    : this.field.value & ~1;
			this.field.value = (this.location.city)          ? this.field.value |= 2    : this.field.value & ~2;
			this.field.value = (this.location.coast)         ? this.field.value |= 4    : this.field.value & ~4;
			this.field.value = (this.location.desert)        ? this.field.value |= 8    : this.field.value & ~8;
			this.field.value = (this.location.forest)        ? this.field.value |= 16   : this.field.value & ~16;
			this.field.value = (this.location.island)        ? this.field.value |= 32   : this.field.value & ~32;
			this.field.value = (this.location.lake)          ? this.field.value |= 64   : this.field.value & ~64;
			this.field.value = (this.location.mountain)      ? this.field.value |= 128  : this.field.value & ~128;
			this.field.value = (this.location.naturereserve) ? this.field.value |= 256  : this.field.value & ~256;
			this.field.value = (this.location.river)         ? this.field.value |= 512  : this.field.value & ~512;
			this.field.value = (this.location.rural)         ? this.field.value |= 1024 : this.field.value & ~1024;
			this.field.value = (this.location.villagetown)   ? this.field.value |= 2048 : this.field.value & ~2048;
		}
	},
	
	created:function() {
		this.updateViewFromModel();
	},

	watch: {
		'location.beach'         : 	    function(){ this.updateModelFromView(); },
		'location.city'          : 	    function(){ this.updateModelFromView(); },
		'location.coast'         : 		function(){ this.updateModelFromView(); },
		'location.desert'        : 		function(){ this.updateModelFromView(); },
		'location.forest'        : 		function(){ this.updateModelFromView(); },
		'location.island'        : 		function(){ this.updateModelFromView(); },
		'location.lake'          : 		function(){ this.updateModelFromView(); },
		'location.mountain'      : 		function(){ this.updateModelFromView(); },
		'location.naturereserve' : 		function(){ this.updateModelFromView(); },
		'location.river'         : 		function(){ this.updateModelFromView(); },
		'location.rural'         : 		function(){ this.updateModelFromView(); },
		'location.villagetown'   : 		function(){ this.updateModelFromView(); },

	}	
}
</script>



