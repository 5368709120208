var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-flex", { attrs: { xs12: "" } }, [
        _c("label", [_vm._v("Reason for payment request:")]),
      ]),
      _vm._v(" "),
      _c("v-flex", { attrs: { xs12: "" } }, [
        _vm._v(
          "\n        Reference code of the reservation or hotel annual fee invoice:\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "v-flex",
        { attrs: { xs12: "", sm6: "" } },
        [
          _c("v-text-field", {
            attrs: { name: "reason_reference" },
            on: {
              keyup: function ($event) {
                return _vm.updateField()
              },
            },
            model: {
              value: _vm.viewValue,
              callback: function ($$v) {
                _vm.viewValue = $$v
              },
              expression: "viewValue",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.reasonKey == _vm.reasonBookingDeposit
        ? _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
            _c("strong", [_vm._v("Booking deposit")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "\n        To be issued to " +
                _vm._s(_vm.reservation.user.first_name) +
                " " +
                _vm._s(_vm.reservation.user.last_name) +
                " "
            ),
            _c("a", { attrs: { href: _vm.emailLink } }, [
              _vm._v(_vm._s(_vm.reservation.user.email)),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "\n        Reservation " +
                _vm._s(_vm.reservation.reservation_key) +
                " at (" +
                _vm._s(_vm.reservation.hotel.hotel_code) +
                ") " +
                _vm._s(_vm.reservation.hotel.hotel_name) +
                "\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.reasonKey == _vm.reasonVoucherPurchase
        ? _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
            _c("strong", [_vm._v("Voucher Purchase")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "\n        To be issued to " +
                _vm._s(_vm.voucher.purchaser_email) +
                " "
            ),
            _c("a", { attrs: { href: _vm.emailLink } }, [
              _vm._v(_vm._s(_vm.voucher.purchaser_email)),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "\n        For gift voucher " +
                _vm._s(_vm.voucher.voucher_reference) +
                " (" +
                _vm._s(_vm.voucher.value) +
                " GBP)\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.reasonKey == _vm.reasonBookingTopUp
        ? _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
            _c("strong", [_vm._v("Booking deposit top up")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "\n        To be issued to " +
                _vm._s(_vm.reservation.user.first_name) +
                " " +
                _vm._s(_vm.reservation.user.last_name) +
                " "
            ),
            _c("a", { attrs: { href: _vm.emailLink } }, [
              _vm._v(_vm._s(_vm.reservation.user.email)),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "\n        Reservation " +
                _vm._s(_vm.reservation.reservation_key) +
                " at (" +
                _vm._s(_vm.reservation.hotel.hotel_code) +
                ") " +
                _vm._s(_vm.reservation.hotel.hotel_name)
            ),
            _c("br"),
            _vm._v(
              "\n        Deposit amount " +
                _vm._s(_vm.reservation.deposit_amount_gbp) +
                " (GBP), Quote amount " +
                _vm._s(_vm.reservation.quote_amount_gbp) +
                " (GBP)\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.reasonKey == _vm.reasonHotelAnnualFee
        ? _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
            _c("strong", [_vm._v("Hotel Annual Fee")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "\n        To be issued to (" +
                _vm._s(_vm.invoice.hotel.hotel_code) +
                ") " +
                _vm._s(_vm.invoice.hotel.hotel_name) +
                " "
            ),
            _c("br"),
            _vm._v(
              "\n        Invoice " +
                _vm._s(_vm.invoice.invoice_portal_key) +
                " (" +
                _vm._s(_vm.invoice.amount_gbp) +
                " GBP)\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.reasonKey == _vm.reasonHotelArrears
        ? _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
            _c("strong", [_vm._v("In-Arrears Hotel Commission Invoice")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "\n        To be issued to (" +
                _vm._s(_vm.invoice.hotel.hotel_code) +
                ") " +
                _vm._s(_vm.invoice.hotel.hotel_name) +
                " "
            ),
            _c("br"),
            _vm._v(
              "\n        Invoice " +
                _vm._s(_vm.invoice.invoice_portal_key) +
                " (" +
                _vm._s(_vm.invoice.amount_gbp) +
                " GBP)\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.reasonKey == _vm.reasonAdHoc
        ? _c("v-flex", { attrs: { xs12: "", sm6: "" } }, [
            _c("strong", [_vm._v("Untracked payment")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "\n        This payment request has no association to any reservation, hotel or gift voucher\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: "payment_reason_key" },
        domProps: { value: _vm.reasonKey },
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: "record_key" },
        domProps: { value: _vm.recordKey },
      }),
      _vm._v(" "),
      _vm.field.errors
        ? _c(
            "v-alert",
            {
              staticStyle: { width: "100%" },
              attrs: { type: "error", value: _vm.field.errors },
            },
            _vm._l(_vm.field.errors, function (error) {
              return _c("li", [
                _vm._v("\n            " + _vm._s(error) + "\n        "),
              ])
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }