// initial state
// shape: [{ tinySetup }]
const state = {
    tinySetup: {
        apiKey : '1fw1d076obmn5wsc7eb6d6s75edyfdupyxuanmmcemn8qr6t',
        init: {
            plugins: [
                'advlist autolink lists link image charmap anchor',
                'searchreplace visualblocks code',
                'insertdatetime media contextmenu paste code'
            ],
            toolbar: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
            setup: function (editor) {
                editor.on('change', function () {
                    tinymce.triggerSave();
                });
            },
        },
    }
}

// getters
const getters = {
    /**
     * Get ENTIRE default setup for tinyMCE
     * @param state
     * @param getters
     * @returns {*}
     */
    tinySetup: state => {
        return state.tinySetup;
    },

    /**
     * Return tinyMCE api key
     * @param state
     * @returns {string | * | String | StringConstructor | string}
     */
    apiKey: state => {
        return state.tinySetup.apiKey;
    },

    /**
     * Return tinyMCE initial setup configuration
     * @param state
     * @returns {*}
     */
    init: state => {
        return state.tinySetup.init;
    }
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}