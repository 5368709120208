<template>
<div>
    <v-btn color="primary" @click="exportCSV">Export CSV</v-btn>
</div>
</template>

<script>
/**
 * ExportCSV
 * 
 * This component is a button that when clicked will download a file CSV file of the js object you pass it
 * Props: 
 *  - filename: name of the file downloaded
 *  - data: js object of what you want converted to CSV
 * 
 * e.g. <ies-csv-export
 *          filename="myFile"
 *          :data="myData"
 *      ></ies-csv-export>
 * 
 */

export default {
    props: ['filename', 'data'],
    data () {
        return {
            csv: ''
        }
    },
    methods: {
        exportCSV() {
            this.clearCSV();
            this.parseJsonCSV(this.data);
            this.downloadFile();
        },
        clearCSV() {
            this.csv = '';
        },
        /**
         * Parse json to csv
         */
        parseJsonCSV(obj) {
            for (var key in obj) {
                // If value is not an object we want to print the values
                if (typeof obj[key] != "object") {         
                    this.csv += `${obj[key]} ,`
                    // Check if we are on the last value so we can prepend a new line and a comma
                    if (key == Object.keys(obj)[Object.keys(obj).length -1]) {
                         this.csv += `\n`
                    }
                }   
            
                // If value is an object print key
                if (typeof obj[key] == "object" && obj[key] !== null) {
                    // Make sure it's not actually an array because those get treated differently
                    if (!(obj instanceof Array)) {
                        this.csv += `${key} \n`
                    } else {
                        // if it's an array instead of printing the number we want to print 
                        // all the keys of the first object to be displayed as column headers. 
                        if (obj.indexOf(obj[key]) === 0) {
                            this.csv += `${Object.keys(obj[key]).join(', ')} \n`
                        }
                    }
                   
                    // Do it again!
                    this.parseJsonCSV(obj[key]);
                }
            }
        },
        /**
         * Puts csv in a blob and downloads it
         */
        downloadFile(){
            let data = new Blob([this.csv], {type: "octet/stream"});
            let a = document.createElement("a");
            let url = window.URL.createObjectURL(data);
            document.body.appendChild(a);
            
            a.style = "display: none";
            a.href = url;
            a.download = `${this.filename}.csv`;
            a.click();

            window.URL.revokeObjectURL(url);
        },
       

    }
}
</script>
