<template>
    <v-container>
        <v-layout row wrap>
            <v-col cols="2">
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-col>

            <v-col cols="10">
                <v-btn v-on:click="chooseHotelRoom" 
                       v-show="!shouldShowPicker">{{ buttonLabel }}</v-btn>
                <v-btn v-on:click="clearHotelRoom" 
                       v-show="selectedHotelRoom">Clear</v-btn>
            </v-col>

            <v-col cols="10" v-if="shouldShowPicker">
                <v-container>
                    <v-layout row wrap>
 
                        <v-col cols="2">Hotel: </v-col>
                        <v-col cols="10">
                            <v-text-field
                                v-model="hotelViewName" 
                                v-on:keyup="updateHotelName()"
                             ></v-text-field>

                             <div class="selectable-hotels-results" v-if="hotelOptions.length && shouldShowHotels">
                                 <ul class="selectable-hotels-list">
                                     <li v-for="hotel in hotelOptions" v-on:click="selectHotel(hotel.hotel_key)">({{ hotel.hotel_code }}) {{ hotel.hotel_name }}</li>
                                 </ul>
                             </div>
                         </v-col>

                        <v-col cols="2">Room Type: </v-col>
                         <v-col cols="10">
                              <v-select
                                  v-model="viewHotelRoom"
                                  label="Room Type"
                                  v-on:change="selectHotelRoom"
                                  :items="hotelRoomOptions"
                                  autocomplete
                               ></v-select>
                          </v-col>
<!--
                          <v-col cols="1">
                              <v-btn v-on:click="done">Done</v-btn>
                          </v-col>
-->
                   </v-layout>
               </v-container>
           </v-col>

           <input type="hidden" :name="field.fieldName" :value="field.value">
           <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
               <li v-for="error in field.errors">
                   {{ error }}
               </li>
           </v-alert>
        </v-layout>
    </v-container>
</template>

<script>
    /**
     * Allows the selection of a hotel room ID by choosing its name or code from
     * a selection downloaded from the API on creation
     *
     * @todo refactor, make more efficient
     */
    export default {
        name: 'ies-hotel-room-type-key',
        props: ['field', 'configuration'],
        data() {
            return {
                /** string view model */
                hotelViewName: '',

                /** view model for the hotel room selection */
                viewHotelRoom: null,

                /** array of hotel options presented to the user */
                hotelOptions: [],

                /** data model of all supported hotels */
                hotels: [],
                hotelRoomOptions: [],

                hotelTypeTimer: null,

                /** view model to determine whether the hotel room picker should display */
                shouldShowPicker: true,

                shouldShowHotels: false,

                /** The hotel chosen by the user */
                selectedHotel: null,

                /** The hotel room type chosen by the user */
                selectedHotelRoom: null,
                viewValue: parseInt(this.field.value),

                /** For where the component is used in event mode to talk to other components */
                config: {'shouldEmitOnSelect': false, 'selectEventName': ''}

            }
        },
        created:function() {
            this.getHotelRooms();

            if (this.configuration){
                var config = JSON.parse(this.configuration);
                console.log(config);
                if (config.shouldEmitOnSelect && config.selectEventName){
                    this.config.shouldEmitOnSelect = config.shouldEmitOnSelect;
                    this.config.selectEventName = config.selectEventName;
                }
            }

            this.addEventListeners();
        },
        methods: {
            addEventListeners: function(){
                var self = this;
            
                this.$root.$on('Room Type Key: set hotel', function(payload){
                    console.log("hrt: Have heard ", payload);
                    if (payload.hotelKey){
console.log("will set the hotel");
                        self.selectHotel(payload.hotelKey);
                    }
                    else {
console.log("will clear the hotel room type picker");
                        self.clearHotelRoom();
                    }
                });
            },

            getHotelRooms:function(){
                var self = this;
                axios.get('/api/hotelsWithRooms')
                .then(function(response){
                    this.hotels = response.data;
                    var hotelRooms = [];

                    /*
                    response.data.forEach(function(hotel){
                        hotel.rooms.forEach(function(roomType){
                            hotelRooms.push({ text: '(' + hotel.hotel_code + ') ' + roomType.name, value: roomType.hotel_room_key });
                        });
                    });
                    this.hotelRoomOptions = hotelRooms;
                    */

                    // Set up the view variables used to make the component display the data to the user in a more convenient way
                    this.hotels.forEach(function(hotel){
                        hotel.rooms.forEach(function(hotelRoom){
                            if (hotelRoom.hotel_room_key == self.field.value){
                                self.selectedHotel = hotel;
                                self.selectedHotelRoom = hotelRoom;

                                // Set the view models so that it is reflected to the users
                                self.hotelViewName = hotel.hotel_name;
                                self.viewHotelRoom = hotelRoom.hotel_room_key;

                                var hotelRooms = [];
                                self.selectedHotel.rooms.forEach(function(roomType){
                                    hotelRooms.push({ text: roomType.name, value: roomType.hotel_room_key });
                                });
                                self.hotelRoomOptions = hotelRooms;
                            }
                        });
                    });
                }.bind(this));
            },

            updateHotelName: function(){
                if (this.hotelTypeTimer){
                    clearTimeout(this.hotelTypeTimer);
                }
                this.hotelTypeTimer = setTimeout(this.refineHotelOptions, 333);
           },


            refineHotelOptions: function(){
                var self = this;
 
                // Nullify the hotel room view model because if we are changing the hotel we are looking at
                // then clearly the hotel room will not be correct any more either
                this.selectedHotelRoom = null;
                
                var hotelOptions = [];
                this.hotels.forEach(function(hotel){
                    if (hotel.hotel_code.toLowerCase().includes(self.hotelViewName.toLowerCase()) || hotel.hotel_name.toLowerCase().includes(self.hotelViewName.toLowerCase())){
                        hotelOptions.push(hotel);
                    }
                });
                this.hotelOptions = hotelOptions;
                this.hotelRooms = [];

                // If there is only one hotel left in the list of options then we select it
                if (this.hotelOptions.length === 1){
                    this.selectedHotel = this.hotelOptions[0];
                    
                    // Reset the list of hotel rooms to be those of this selected hotel
                    var hotelRooms = [];
                    this.selectedHotel.rooms.forEach(function(roomType){
                        hotelRooms.push({ text: roomType.name, value: roomType.hotel_room_key });
                    });
                    this.hotelRoomOptions = hotelRooms;
                }
                this.shouldShowHotels = true;
            },

            selectHotel: function(hotelKey){
                var self = this;
                this.hotels.forEach(function(hotel){
                    if (hotel.hotel_key === hotelKey){
                        self.selectedHotel = hotel;
                        self.hotelViewName = hotel.hotel_name;

                        
                        // Reset the list of hotel rooms to be those of this selected hotel
                        var hotelRooms = [];
                        self.selectedHotelRoom = null;
                        hotel.rooms.forEach(function(roomType){
                            hotelRooms.push({ text: roomType.name, value: roomType.hotel_room_key });
                        });
                        self.hotelRoomOptions = hotelRooms;
                    }
                });
                this.shouldShowHotels = false;
            },

            selectHotelRoom: function(){
                var self = this;
                self.selectedHotel.rooms.forEach(function(roomType){
                    if(self.viewHotelRoom === roomType.hotel_room_key){
                        self.selectedHotelRoom = roomType;
                        self.updateFieldValue();
                    }
                });
            },

            chooseHotelRoom: function(){
                this.shouldShowPicker = true;
            },

            clearHotelRoom: function(){
                this.selectedHotel = null
                this.selectedHotel = null;;
                this.selectedHotelRoom = null;
                this.hotelViewName = '';
                this.field.value = null;

                if (this.config.shouldEmitOnSelect){
                    console.log("I should emit ", this.config.selectEventName);
                    this.$root.$emit(this.config.selectEventName, {roomTypeKey: null});
                }
            },

            done: function(){
                this.shouldShowPicker = false;
            },

            updateFieldValue: function(){
                this.field.value = this.selectedHotelRoom.hotel_room_key;

                // What is this for again?
                this.$root.$emit('Edit: Update Field', {'id': 'notset', 'field': this.field, 'value': this.field.value});

                console.log("updatefieldvalue on select: ", this.config);
                if (this.config.shouldEmitOnSelect){
                    console.log("I should emit ", this.config.selectEventName);
                    this.$root.$emit(this.config.selectEventName, {hotelKey: this.selectedHotelRoom.hotel_key, roomTypeKey: this.field.value});
                }

            }
        },
        watch: {
            viewValue: function (newValue) {
            
            }
        },
        computed: {
            buttonLabel: function(){
                return this.selectedHotelRoom ? "(" + this.selectedHotel.hotel_code + ") " + this.selectedHotel.hotel_name + ": " + this.selectedHotelRoom.name : "Choose";
            }
        },
        computed: {
            buttonLabel: function(){
                return this.selectedHotelRoom ? "(" + this.selectedHotel.hotel_code + ") " + this.selectedHotel.hotel_name + ": " + this.selectedHotelRoom.name : "Choose";
            }
        }

    }
</script>
<style>
.selectable-hotels-results{
    background-color: white;
    border: solid 1px #f5f5f5;
    width: auto;
    min-width: 20rem;
    max-height: 10rem;
    overflow-y: auto;
    position: absolute;
    z-index: 1;
}

.selectable-hotels-list {
  list-style-type: none;
}

.selectable-hotels-list li {
    cursor: pointer;
    padding: 3px;
}

.selectable-hotels-list li:hover {
    background-color: #f5f5f5;
}
</style>
