<template>
    <v-col cols="12" md="6" lg="4" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">card_travel</v-icon> VAN Statistics
            </v-card-title>
            <bar-chart :chartdata="chartData" :options="chartOptions"></bar-chart>
        </v-card>
    </v-col>
</template>

<script>
    import BarChart from '@/components/charts/BarChart';
    import axios from 'axios';
    /**
     * Display reservation statistics to the user
     */
    export default {
        /**
         * Data passed into the component from the view itself via attributes on its tag
         */
        props:['datasource'],
        components: {
            BarChart,
        },

        data() {
            return {
                apiData:  {},
                chartData: {},
                chartOptions: {
                    maintainAspectRatio: false,
                    responsive: true,
                    events: ['click'],
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                precision: 0,
                            }
                        }]
                    },
                    title: {
                        display: false,
                        text: ''
                    }
                },
                date:   {
                    from: new Date(new Date().setDate(new Date().getDate()-6)).toISOString().substr(0, 10),
                    to:   new Date().toISOString().substr(0, 10),
                },
            }
        },
        /**
         * Perform the following actions on startup
         */
        mounted() {
            this.loadVANStats();
            setInterval( this.loadVANStats, 60000 );
        },

        methods: {
            /**
             * Load the latest data from the API
             */
            async loadVANStats() {
                const response = await axios.get('api/accounts/vans/statistics', {
                   params: {
                        dateFrom: this.date.from,
                        dateTo:   this.date.to,
                   }
                }).then(response => {
                    this.apiData = response.data;
                    console.log("apiData", this.apiData);
                    this.buildDataObject();
                }).catch(error => {
                    console.log(error);
                });
            },
            /**
             * Build the data object required by the line chart using API data to
             * fill the datasets ofjects and lavels
             */
            buildDataObject() {
                let data = {
                    labels: [],
                    datasets: [{
                        data: [],
                        label: "Vans Generated",
                        backgroundColor: "#C1CD23",
                    }]
                };
                this.apiData.forEach(function (value) {
                   data.labels.push(value.date);
                   data.datasets[0].data.push(value.count);
                });
                this.chartData = data;
            },
        },
    }
</script>
