<template>
<v-container>
    <h1>Hotel Accounts Overview</h1>
    <v-layout row wrap>
        <v-col cols="12" md="6" pa-2>
            <v-container>
                <v-layout row wrap>

                    <v-col cols="12" pa-2>
                        <accounts-summary />
                    </v-col>
                    <v-col cols="12" pa-2>
                        <global-summary />
                    </v-col>
                </v-layout>
            </v-container>
        </v-col>

        <v-col cols="12" md="6" pa-2>
            <full-statement />
        </v-col>
    </v-layout>
</v-container>
</template>

<script>
import AccountsSummary from "@/components/bespoke/pages/Accounts/Summary";
import GlobalSummary from "@/components/bespoke/pages/Accounts/GlobalSummary"
import FullStatement from "@/components/bespoke/pages/Accounts/FullStatement";

export default {
	components: {
		AccountsSummary,
		FullStatement,
		GlobalSummary
	}
}
</script>
