<template>
    <v-container fluid>
        <v-layout row-sm column>
            <v-flex xs12>
                <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-flex>
            <v-flex xs12 sm6>
                <!--<input :name="this.field.fieldName" type="text" v-model="input" v-on:keyup="updateField()">-->
                <select :name="this.field.fieldName" v-model="input" v-on:change="updateField()" style="width: 200px" :required="this.field.required" :disabled="this.field.disabled">
                    <option disabled value="">Select user status</option>
                    <option value="5">Inactive</option>
                    <option value="6">Active</option>
                </select>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: 'ies-user-status',
        props: ['field'],
        data () {
            return {
                input: this.field.value
            }
        },
        methods: {
            updateField: function(){
                this.field.value = this.input;
            }

        }
    }
</script>


