<template>
    <v-card>
        <v-card-content>
            <v-layout column class="ma-3" >
                <h2>Historic Dates:</h2>
                <v-flex xs12>Clone historic rate data for this hotel between</v-flex>
    
                <v-flex xs12>
                    <v-date-picker v-model="dateFrom"></v-date-picker>
                    and 
                    <v-date-picker v-model="dateUntil"></v-date-picker>
                </v-flex>

                <v-flex xs12>
                    <label>Offset Dates:</label>
                    <input type="checkbox" v-model="offsetDates">
                    <div>
                        Offset the dates to account for the days of the week, such that rate dates copied from a Monday are cloned to the next Monday 
                        (Note that this will move the dates forward to the next Monday, not backward)
                    </div>
                </v-flex>


                    <h2>Apply For Rate Types:</h2>
                <v-flex xs12>
                    <v-container fluid>
                        <v-layout row wrap xs12>
                            <v-flex xs12 v-for="(rateType, index) in rateKeysViewModels">
                    <v-container fluid>
                        <v-layout row wrap xs12>
                                <v-flex xs12 md2>
                                    <label>{{ rateType.name }}:</label>
                                </v-flex>
                                <v-flex xs12 md8>
                                    <input type="checkbox" v-model="rateType.selected" v-on:change="updateList()">
                                </v-flex>
                        </v-layout>
                    </v-container>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-flex>

            </v-layout>
        </v-card-content>
        
        <v-card-actions>
            <v-btn color="lime" @click="clone()" >Clone</v-btn>
        </v-card-actions>

        <v-snackbar
            :timeout="parseInt(6000)"
            v-model="successSingleSnackbar"
            multi-line
            color="ies-green"
        >
           <div class="full-height full-width v-align-container text-xs-center">
              <h4 class="ies-dark-gray--text v-align-div">Data Cloned to Current Year</h4>
           </div>
        </v-snackbar>

        <v-snackbar
            :timeout="parseInt(6000)"
            v-model="errorSnackbar"
            multi-line
            color="ies-coral"
        >
           <div class="full-height full-width v-align-container text-xs-center">
              <h4 class="ies-dark-gray--text v-align-div">Data could not be copied</h4>
           </div>
        </v-snackbar>
    </v-card>
</template>

<script>

export default {
    name: 'ies-clone-rate-dates',
    props: ['hotelkey', 'ratetypes'],
    data () {
        return {
            rateKeysViewModels: {},
            rateTypeKeysArray: [],
            dateFrom: '',
            dateUntil: '',
            errorSnackbar: false,
            successSingleSnackbar:false,
            successMultiSnackbar:false,
            offsetDates: true
        }
    },
    methods: {
        clone (){
           axios.post('/api/hotel/' + this.hotelkey + '/clone-rate-dates', { startDate: this.dateFrom, endDate: this.dateUntil, rateTypes: this.rateTypeKeysArray, offsetDates: this.offsetDates })
           .then(function(response) {
               if (response.data.success) {
                   this.successSingleSnackbar = true;
               }
               else {
                   this.errorSnackbar = true;
               }
           }.bind(this));
        },

        updateList(){
            this.rateTypeKeysArray = [];
            this.rateKeysViewModels.forEach(function(rateType){
                if (rateType.selected){
                    this.rateTypeKeysArray.push(rateType.rate_type_key);
                }
            });
        }
    },

    mounted: function(){
console.log(this.hotelkey);
console.log(this.ratetypes);
        this.rateKeysViewModels = JSON.parse(this.ratetypes);
console.log(this.rateKeysViewModels);
        this.rateKeysViewModels.forEach(function(rateType){
            rateType.selected = true;
        });
        this.updateList();
console.log(this.ratetypes);
    }
}
</script>
