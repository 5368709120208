<template>
    <v-container fluid>
       <v-layout row-sm column>
            <v-flex xs12>
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-flex>
            <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            <v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            <v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>
            <v-flex xs12 sm6>
                <input :name="this.field.fieldName" type="text" v-model="input" v-on:keyup="updateField()">
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'ies-text',
    props: ['field'],
    data () {
        return {
            input: this.field.value
        }
    },
    methods: {
    updateField: function(){
        this.field.value = this.input
    }
    
    }
}
</script>


