<template>
    <v-card xs12 class="mt-3 mb-3 no-print button-bar">
        <v-container fluid>
            <v-layout row wrap>
                <v-flex xs12 v-for="field in fields" :key="field.customComponent">

                    <!-- Load custom component by component name-->
                    <v-flex v-if="field.displayType == 'custom'" >
                        <component :is="getCustomComponent(field.customComponent)" ></component>
                    </v-flex>

                </v-flex>
            </v-layout>
        </v-container>
    </v-card>
</template>
<script>

    // var futureComponentName = require("./futurePath.vue");    // left that commented out for future reference
    // how to add component for list pages

    export default {
        name: 'ies-list-section-wrapper',
        props: ['section'],
        data () {
            return {
                /** @var sectionName string - name of this bespoke section */
                sectionName: this.section.sectionName,
                /** $var fields array - array of fields that should be displayed for this section */
                fields: this.section.fields,
                rules: this.section.rules,
            }
        },
        /** here can be stored reusable components for dynamic list pages*/
        components: {
            // 'ies-future-component' : futureComponentName,
        },
        methods: {

            /**
             * Loads requested custom component to current view instance
             *
             * @param name - name/path of the component
             * @return componentName string
             */
            getCustomComponent(name) {
                let componentName = this.getCustomComponentName(name);

                // load this component to vue instance
                var component = require("../bespoke/"+name+".vue");
                Vue.component(componentName, component);

                return componentName;
            },

            /**
             * Get's name of this custom component from given name/path
             * @param name
             * @return string
             */
            getCustomComponentName(name) {
                let componentName = name;

                // check if given name is path, if so component name should be last element of this path
                if (name && name.includes('/')) {
                    let segments = name.split('/');
                    componentName = segments[segments.length - 1];
                }

                return componentName;
            },

        }
    }
</script>
