var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _vm.label && _vm.label !== ""
        ? _c("v-flex", { attrs: { xs12: "" } }, [
            _c("strong", [_vm._v(_vm._s(_vm.label))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("v-flex", { attrs: { xs12: "" } }, [
        _vm._v("\n        " + _vm._s(_vm.text) + "\n    "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }