<template>
<!-- full statement -->
<v-card>
    <v-card-title v-if="fullStatement.length">
        <span class="subheader">Full statement for {{ hotel.hotel_name }}. Account overall balance {{ balance }}</span>
        <v-select
            :items="hotelDropdown"
            v-model="hotelSelected"
            label="Select hotel"
            autocomplete
            item-text="text"
            item-value="value"
            dense
            hint="Type to search for a hotel"
            persistent-hint
        ></v-select>
    </v-card-title>

    <v-card-title v-else>
        <span class="subheader">Please select a hotel</span>
        <v-select
            :items="hotelDropdown"
            v-model="hotelSelected"
            label="Select hotel"
            autocomplete
            item-text="text"
            item-value="value"
            dense
            hint="Type to search for a hotel"
            persistent-hint
        ></v-select>
    </v-card-title>

    <v-data-table
        :headers="headers"
        :items="fullStatement"
        :loading="loading"
        item-key="index"
        footer-props.items-per-page-options="rowsPerPage"
    >
        <v-progress-linear slot="progress" color="lime" indeterminate></v-progress-linear>

        <template slot="items" slot-scope="props">

            <tr class="lime lighten-5" @click="props.expanded = !props.expanded">
                <td class="text-xs-center">{{ props.item.transaction_date }}</td>
                <td class="text-xs-center">{{ props.item.reference }}</td>
                <td class="text-xs-center">{{ props.item.amount_gbp | pounds }}</td>
                <td class="text-xs-center">{{ props.item.balance | pounds }}</td>

                <td class="justify-center layout px-0">
                    <v-btn @click.stop="dialog = true">
                        <v-icon color="teal">edit</v-icon>
                    </v-btn>
                    <v-dialog
                        v-model="dialog"
                        width="500"
                    >

                        <v-card>
                            <v-card-title>
                                Corrections Stuff
                            </v-card-title>
                            <v-card-text>
                                And this is where you will have inputs! :)
                            </v-card-text>
                        <v-card-actions>
                            <v-btn color="primary" flat @click="dialog = false"> 
                                Exit
                            </v-btn>

                        </v-card-actions>
                        </v-card>
                        </v-dialog>
                </td>
            </tr>

        </template>

        <template slot="expand" slot-scope="props" v-if="props.item.reservations">
            <v-container class="px-4">
                <v-card class="pa-2">
                <v-subheader>Reservations</v-subheader>
                <v-layout row wrap  >
                    <v-flex xs6 md3 class="px-4" v-for="reservation in props.item.reservations" :key="reservation.reference">
                        <a href="#">{{ reservation.reference }}</a>
                        <div>
                            Departure: {{ reservation.date_departure | dd-mm-yyyy }}
                        </div>
                        <div>
                            Arrival: {{ reservation.date_arrival | dd-mm-yyyy }}
                        </div>
                    </v-flex>
                </v-layout>
                </v-card>
            </v-container>
        </template>
        
    </v-data-table>
</v-card>
</template>

<script>

export default {
    data() {
        return {
            hotelSelected: 0,
            hotel: {},
            hotels: [],
            loading: false,
            currentFilter: {},
            headers: [],
            fullStatement: [],
            balance: 0,
            dialog: false,
            rowsPerPage: [20, 50, {"text":"All","value":-1}],
        }
    },
    computed: {
        hotelDropdown: function() {
            return this.formatHotels(this.hotels);
        }
    },
    created() {
        // Get hotels for dropdown
        this.getHotels();
        // Get headers from a method so they are out the way
        this.headers = this.tableHeaders();
    },
    watch: {
        hotelSelected: function() {
            this.balance = 0;
            this.getHotel(this.hotelSelected);
            this.getFullStatement(this.hotelSelected)
        },
        fullStatement: function() {
            this.fullStatement.forEach((transaction, index) => {
                // We need this so that all transactions have a common property with a unique value.
                transaction.index = index;

                // Sum balance
                if (transaction.reference) {
                    transaction.balance = this.balance - transaction.amount_gbp;
                }
                if (!transaction.reference) {
                    transaction.balance = this.balance + transaction.amount_gbp;
                }

                this.balance = transaction.balance;
                
                // Add labels
                if (!transaction.reference) {
                    if (transaction.payment_portal_key) transaction.reference = "Payment";
                    if (transaction.refund_portal_key) transaction.reference = "Refund";
                }  
            });
        }
    },
    methods: {  
        // Get a list of Invoices, Credit Notes, Payments and Refunds.
        async getFullStatement(hotelKey) {
            this.loading = !this.loading;
            let res = await axios({
                method: 'get',
                url: 'api/accounts/full-statement',
                params: {
                    hotelKey,
                },
            });

            this.fullStatement = res.data;
            this.loading = !this.loading;
        },
        // Get a listing of hotels to be used in the select dropdown
        async getHotels() {
            let res = await axios('api/hotels')
            this.hotels = res.data;
        },
        // Get the hotel so we can display some hotel info
        async getHotel(hotelKey) {
            let res = await axios({
                method: 'get',
                url: `api/hotels/${hotelKey}`,
            });

            this.hotel = res.data;
        },
        // Format hotels to be displayed in select dropdown.
        formatHotels(hotels) {
            return hotels.map(hotel => {
                return {
                    text: `${hotel.hotel_code} - ${hotel.hotel_name}`,
                    value: hotel.hotel_key
                }
            })
        },
        /**
         * 
         */
        tableHeaders() {
            return [
                {
                    text: "Transaction Date",
                    align: "center",
                    value: "transaction_date"
                },
                {
                    text: "Reference",
                    align: "center",
                    value: "reference"
                },
                {
                    text: "Amount GBP",
                    align: "center",
                    sortable: true,
                    value: "amount_gbp",
                },
                {
                    text: "Balance",
                    align: "center",
                    sortable: false,
                    value: "balance"
                },
                {
                    text: "Actions",
                    align: "center",
                    value: "reference",
                }
            ]
        }
    }
}
</script>

