<script>
    /**
     * Wrapper for Vue-ChartJS, providing fully automated dynamic bubble chart support for the data set named in the prop
     *
     *
     */

    // Import the application event bus so we can listen for data changes and react accordingly
    import EventBus from '../../../eventBus';

    // Import Vue-ChartJS to perform the rendering for us
    import VueCharts from 'vue-chartjs';
    import { Bubble } from 'vue-chartjs';


    export default{
        extends:    Bubble,
        props:      ['datasource', 'title', 'sectionindex', 'viewid', 'config', 'content'],
        data:       function() {
            return {
                data:       {},
                page:       {},
                loaded:     false,
            }
        },

        methods:    {

            /**
             * Request VueChartJS re-render the chart
             */
            updateView: function(){
                // Overwriting base render method with actual data.************
                this.renderChart({
                    datasets: [{
                    label: 'Dataset 1',
                    backgroundColor: 'rgb(255, 99, 132)',
                    data: [{
                           x: 30,
                           y: 20,
                           r: 20
                          }, {
                           x: 20,
                           y: 40,
                           r: 20
                          }, {
                           x: 80,
                           y: 60,
                           r: 20
                          }, {
                           x: 200,
                           y: 90,
                           r: 20
                          }]
                     }]
                },
                {responsive: true, maintainAspectRatio: false}
                )
            }
        },


        /**
         * Tasks to perform once the Vue component has been constructed and page load event is fired
         *
         */
        mounted () {
            this.data.data = this.content;
            this.updateView();
        },

        /**
         * Tasks to perform when data variables are changed.  This cannot be done by computed properties in this case because we need to call a function
         * to perform the re-render within the parent VueChartJS component
         */
        watch:{
            /**
             * If the main data set is updated then redraw the graph
             */
            data: function(){
                this.updateView();
            }
        },
}
</script>
