<template>
    <v-container fluid grid-list-md>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                     <v-card-text>
                         <v-container fluid v-if="reservation.show || 1">
                              <v-layout row wrap>
                                  <v-flex xs12>
                                       The client has made an instant reservation. Please acknowledge below
                                  </v-flex>
                              </v-layout>
                          </v-container>
                      </v-card-text>
                      <v-card-actions>
                          <v-btn class="iescape-green" disabled v-on:click="confirmReservation">Confirm Reservation</v-btn>
                          <v-btn disabled v-on:click="contactIescape">Contact i-escape</v-btn>
                      </v-card-actions>
                 </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    /**
     * A Vue component dedicated to letting the user manage a reservations
     * by the ID specified
     *
     * Props:
     *     int 'reservationkey' - The ID of the reservation to display
     */

    import axios from 'axios';
    export default {
        name: 'hotel-reservation-enquiry',
        props: ['reservationkey'],
        data () {
            return {
                reservation: {}
            }
        },
        methods: {

            loadReservation(){
                axios.get('/api/reservation/' + this.reservationkey)
                .then(function(response){
                    this.reservation = response.data;
                }.bind(this));
            },

            confirmReservation(){
                alert("Reservation confirmed");
            },

            contactIescape(){
               alert("This functionality is not yet supported");
            }
        },

        /**
         * Once mounted this component needs to load the reservation specified
         */
        mounted() {
            this.loadReservation();
        },

        computed: {}
    }
</script>
