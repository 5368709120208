<template>
    <v-container fluid>
       <v-layout row-sm column>
            <v-col v-if="this.field.schema.displayName != 'none'" cols="12">
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-col>

            <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            <v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            <v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>

            <v-row>
				<v-col sm="12" class="d-flex flex-column">
					<input type="hidden" :name="field.fieldName" :value="field.value">
					
					<v-radio-group
						v-model="status"
						row
						autocomplete>
						<v-radio label="Offline"		:value="0"></v-radio>
						<v-radio label="Previewable"	:value="2"></v-radio>
						<v-radio label="Online"			:value="1"></v-radio>
					</v-radio-group>
					<v-col>
						<v-alert type="error" dismissable value="true" v-if="errorMessages && errorMessages.length">
                			<div v-for="(error, index) in errorMessages" :key="index" >{{ error.reason }}*</div>
            			</v-alert>
					</v-col>
				</v-col>
            </v-row>
         </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'ies-input-display-status-type',
    props: ['field'],
    data () {
        return {
			showValidationGuide: false,
			errorMessages: [],

			status: parseInt(this.field.value),
        }
    },

	created: function() {
		this.addEventListeners();
	},

	methods: {
		addEventListeners: function() {
			var self = this;

            this.$root.$on('Field: set errors', function(payload){
              	console.log("TEXT: I heard ", payload);
                if (payload.modelName = self.field.modelName && payload.fieldName == self.field.fieldName){
                  	self.errorMessages = payload.errors;
                   	console.log("TEXT: I set ", self.field.errors);
               	}
            });
		},

		toggleValidationGuide: function(){
            this.showValidationGuide = !this.showValidationGuide;
	    },

		validationRuleAsString: function(rule){
           	console.log("explain rule");
           	var explainer = '';
           	switch(rule.ruleType){
            	case 'minLength': explainer = "The text must be at least " + rule.value + " characters long"; break;
        	    case 'maxLength': explainer = "The text must not be more than " + rule.value + " characters long"; break;
    	        case 'minValue': explainer = "The value must be more than " + rule.value; break;
	            case 'maxValue': explainer = "The value must be less than " + rule.value; break;
               	case 'notEmpty': explainer = "The field must not be empty"; break;
               	case 'regExp': explainer = "Pattern: " + rule.value; break;
                default:
             	    explainer = rule.ruleType + ' ' + rule.value;
            }
           	console.log(explainer);
           	return explainer;
        },
	},

	watch: {
		status: function (newValue) {
			this.field.value = newValue;
		}
	}	
}
</script>



