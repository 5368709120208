<template>
    <v-btn block
           class="ies-green list-btn" 
           v-on:click="editRecord()">View</v-btn>
</template>

<script>
/**
 * Embeds a button linking through to the edit page for the record it has been assigned to
 * by using the data value it has been given
 *
 */
export default {
    name: 'ies-list-edit-button',
    props: ['field', 'value', 'pageRecord'],
    data () {
        return {}
    },
    mounted: function(){
        console.log("EDIT BUTTON: ",this.pageRecord, this.pagerecord);
    },
    methods: {
        editRecord: function(){

            // The actual work is handled centrally by the list component which understands the broader context
            // this user input component is acting on behalf of, so all we need to do is tell it the user clicked
            this.$root.$emit('List: Edit record', {'id': 'notset', 'recordID': this.value});
        }
    }
}
</script>
