var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "d-md-none mt-3 mb-3" },
    [
      this.isBroken
        ? _c("v-flex", [_vm._v("Sorry, this content is not working")])
        : _vm._e(),
      _vm._v(" "),
      this.isReady && !this.isBroken
        ? _c(
            "v-container",
            { staticClass: "mobile-no-padding", attrs: { fluid: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          !_vm.componentReady
                            ? _c("v-progress-linear", {
                                attrs: { indeterminate: "", color: "lime" },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.componentReady
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", id: "table-external" } },
                                [
                                  _c(
                                    "v-layout",
                                    [
                                      _vm.appliedFilters.length > 0 &&
                                      _vm.showFilters
                                        ? _c(
                                            "v-flex",
                                            {
                                              staticClass: "mb-3",
                                              attrs: { xs12: "" },
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  "The following filters are applied:"
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: { row: "", wrap: "" },
                                                },
                                                _vm._l(
                                                  _vm.appliedFilters,
                                                  function (filter, index) {
                                                    return _c(
                                                      "v-flex",
                                                      {
                                                        key: index,
                                                        attrs: { xs12: "" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(index + 1) +
                                                            ". " +
                                                            _vm._s(
                                                              filter.modelName
                                                            ) +
                                                            "." +
                                                            _vm._s(
                                                              filter.fieldName
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              filter.operator
                                                            ) +
                                                            " " +
                                                            _vm._s(filter.value)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { row: "", wrap: "" } },
                                            _vm._l(
                                              _vm.tableRows,
                                              function (row, rowIndex) {
                                                return _c(
                                                  "v-col",
                                                  {
                                                    key: rowIndex,
                                                    attrs: { cols: "12" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-layout",
                                                      {
                                                        attrs: {
                                                          row: "",
                                                          wrap: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._l(
                                                          _vm.tableHeadData,
                                                          function (
                                                            field,
                                                            fieldIndex
                                                          ) {
                                                            return _c(
                                                              "v-col",
                                                              {
                                                                key: fieldIndex,
                                                                attrs: {
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                _vm
                                                                  .tableHeadings[
                                                                  fieldIndex
                                                                ] &&
                                                                _vm
                                                                  .tableHeadings[
                                                                  fieldIndex
                                                                ].displayType !=
                                                                  "hidden"
                                                                  ? _c(
                                                                      "v-layout",
                                                                      {
                                                                        attrs: {
                                                                          row: "",
                                                                          wrap: "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "font-weight":
                                                                                  "bold",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                cols: "5",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm
                                                                                  .tableHeadings[
                                                                                  fieldIndex
                                                                                ]
                                                                                  ? _vm
                                                                                      .tableHeadings[
                                                                                      fieldIndex
                                                                                    ]
                                                                                      .text
                                                                                  : "heading is missing!"
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "7",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm
                                                                              .tableHeadings[
                                                                              fieldIndex
                                                                            ]
                                                                              .displayType !=
                                                                            "editButton"
                                                                              ? _c(
                                                                                  "div",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        row[
                                                                                          field
                                                                                            .fieldName
                                                                                        ]
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm
                                                                              .tableHeadings[
                                                                              fieldIndex
                                                                            ]
                                                                              .displayType ==
                                                                            "editButton"
                                                                              ? _c(
                                                                                  "ies-list-edit-button",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        field:
                                                                                          field,
                                                                                        value:
                                                                                          row[
                                                                                            field
                                                                                              .fieldName
                                                                                          ],
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c("hr", {
                                                          staticStyle: {
                                                            width: "100%",
                                                            color: "lightgray",
                                                          },
                                                        }),
                                                      ],
                                                      2
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", { staticClass: "mt-4", attrs: { cols: "12" } }, [
                    _c("strong", [_vm._v("Total:")]),
                    _vm._v(
                      " " + _vm._s(_vm.totalItems) + " Items\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mt-4 centred", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.firstPage()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("first_page")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.prevPage()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("navigate_before")])],
                        1
                      ),
                      _vm._v(
                        "\n\n                Page " +
                          _vm._s(_vm.pageNumber + 1) +
                          " of " +
                          _vm._s(_vm.totalPages) +
                          "\n\n                "
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.nextPage()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("navigate_next")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.lastPage()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("last_page")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }