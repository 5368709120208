var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value
    ? _c(
        "v-flex",
        [
          _vm._v("\n    " + _vm._s(_vm.value) + "\n\n    "),
          _c(
            "v-btn",
            { on: { click: _vm.copyToClipboard } },
            [_c("v-icon", [_vm._v("content_copy")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            { attrs: { href: _vm.payLink, target: "_blank" } },
            [_c("v-icon", [_vm._v("open_in_new")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            { attrs: { href: _vm.emailLink } },
            [_c("v-icon", [_vm._v("email")])],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }