<template>
    <v-flex xs4 ma-1>
        <v-card>

            <v-card-title>
                <v-icon>show_chart</v-icon> Statistics
            </v-card-title>

            <v-card-text>
               
                <v-btn block href="/accounts/conversions">Commissions & Bookings</v-btn>
                <v-btn block disabled>Top Hotels</v-btn>
                <v-btn block disabled>Conversion Report</v-btn>

            </v-card-text>

        </v-card>
    </v-flex>
</template>

<script>
    /**
     * Display buttons linking through to accounts reports
     */
    export default {
        props:[],

        data: function() {
            return {
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){},

        methods: {},

        computed: {}
    }
</script>
