<template>
    <div>
        <!-- list must be list of ['text' => x, 'value' => y] -->
        <v-select
            v-model="viewValue"
            :label="field.schema.displayName"
            :items="channelManagers"
            autocomplete
        ></v-select>
        <input type="hidden" :name="field.fieldName" :value="field.value">

        <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
            <li v-for="error in field.errors">
                {{ error }}
            </li>
        </v-alert>

        <v-alert type="info" :value="shouldShowGuidance" style="width: 100%" v-if="guidance" v-html="guidance"></v-alert>
    </div>

</template>

<script>
    import axios from 'axios';
    /**
     * Allows the selection of a channel manager ID by choosing its name from
     * a selection downloaded from the API on creation
     */
    export default {
        name: 'ies-channelManagerKey',
        props: ['field'],
        data() {
            return {
                channelManagers: [],
                viewValue: parseInt(this.field.value),
                guidance: "",
                shouldShowGuidance: true
            }
        },
        created:function() {
            this.getChannelManagers();
        },
        methods: {
            getChannelManagers:function(){
                axios.get('/api/selectable-channel-managers')
                .then(function(response){
                    var channelManagers = [];
                    response.data.channelManagers.forEach(function(record){
                        channelManagers.push({ text: record.name, value: record.channel_manager_key, hotelConnectionGuidance: record.hotel_connection_guidance });
                    });
                    this.channelManagers = channelManagers;
                    this.updateGuidance();
                }.bind(this));
            },

            /**
             * Update the view variable powering the channel manager guidance information for connecting
             * the hotel to the channel manager
             */
            updateGuidance: function(){
                var self = this;
                this.channelManagers.forEach(function(channelManager){
                    if (channelManager.value == self.field.value){
                        self.guidance = channelManager.hotelConnectionGuidance;
                    }
                });
            }
        },
        watch: {
            viewValue: function (newValue) {
                this.field.value = newValue;
                this.updateGuidance();
            }
        }
    }
</script>
