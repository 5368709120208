var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "" } },
    [
      _c(
        "v-btn",
        {
          on: {
            click: function ($event) {
              return _vm.openCancelMultipleDialog()
            },
          },
        },
        [
          _c("v-icon", [_vm._v("cancel_presentation")]),
          _vm._v(" Cancel multiple"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.cancelMultiple.cancelMultipleDialogOpen,
            callback: function ($$v) {
              _vm.$set(_vm.cancelMultiple, "cancelMultipleDialogOpen", $$v)
            },
            expression: "cancelMultiple.cancelMultipleDialogOpen",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Cancel multiple referral vouchers"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c("v-flex", { attrs: { xs2: "" } }, [
                            _vm._v(
                              "\n                                Voucher Code\n                            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("v-flex", { attrs: { xs4: "" } }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.cancelMultiple.voucherCode,
                                  expression: "cancelMultiple.voucherCode",
                                },
                              ],
                              attrs: { type: "text", maxlength: "16" },
                              domProps: {
                                value: _vm.cancelMultiple.voucherCode,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.cancelMultiple,
                                    "voucherCode",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs1: "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "info" },
                                  on: { click: _vm.addVoucher },
                                },
                                [_vm._v("Add")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.cancelMultiple.vouchersToCancel.length
                        ? _c(
                            "v-layout",
                            { attrs: { row: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs10: "", "offset-xs2": "" } },
                                [
                                  _c(
                                    "ol",
                                    { staticClass: "voucherCodesList" },
                                    _vm._l(
                                      _vm.cancelMultiple.vouchersToCancel,
                                      function (code, index) {
                                        return _c(
                                          "li",
                                          { key: code },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(code) +
                                                "\n                                        "
                                            ),
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { small: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.clearVoucher(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            delete\n                                        "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cancelMultiple.vouchersToCancel.length
                        ? _c(
                            "v-layout",
                            {
                              staticClass: "margin-top-medium",
                              attrs: { row: "" },
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md10: "", lg8: "" } },
                                [
                                  _c(
                                    "v-expansion-panel",
                                    [
                                      _c(
                                        "v-expansion-panel-content",
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "header" },
                                              slot: "header",
                                            },
                                            [
                                              _vm._v(
                                                "Do you want to cancel this vouchers?"
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "v-layout",
                                                    { attrs: { row: "" } },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs2: "" } },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color: "error",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.cancelReferralVouchers,
                                                              },
                                                            },
                                                            [_vm._v("Cancel")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm
                                                                  .cancelMultiple
                                                                  .cancelBusy,
                                                              expression:
                                                                "cancelMultiple.cancelBusy",
                                                            },
                                                          ],
                                                          attrs: { xs2: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-progress-circular",
                                                            {
                                                              attrs: {
                                                                width: 3,
                                                                color: "blue",
                                                                indeterminate:
                                                                  "",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm
                                                                  .cancelMultiple
                                                                  .cancelError,
                                                              expression:
                                                                "cancelMultiple.cancelError",
                                                            },
                                                          ],
                                                          attrs: { xs6: "" },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v("Error"),
                                                          ]),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cancelMultiple.cancelSuccess
                        ? _c(
                            "v-layout",
                            {
                              staticClass: "margin-top-medium",
                              attrs: { row: "" },
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs0: "", "offset-xs2": "" } },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.cancelMultiple.cancelledCount
                                      ) + " vouchers has been cancelled!"
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", flat: "" },
                      on: {
                        click: function ($event) {
                          return _vm.clearVouchers(true)
                        },
                      },
                    },
                    [_vm._v("Clear")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", flat: "" },
                      on: { click: _vm.closeCancelMultipleDialog },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }