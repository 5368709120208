import { render, staticRenderFns } from "./BlogRegions.vue?vue&type=template&id=f97054e4&"
import script from "./BlogRegions.vue?vue&type=script&lang=js&"
export * from "./BlogRegions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/live/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f97054e4')) {
      api.createRecord('f97054e4', component.options)
    } else {
      api.reload('f97054e4', component.options)
    }
    module.hot.accept("./BlogRegions.vue?vue&type=template&id=f97054e4&", function () {
      api.rerender('f97054e4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/inputs/BlogRegions.vue"
export default component.exports