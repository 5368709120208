<template>
    <v-container fluid>
       <v-layout row-sm column>
            <v-col v-if="this.field.schema.displayName != 'none'" cols="12">
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}<span v-if="this.field.required">*</span>: <span v-on:click="toggleValidationGuide" v-if="this.field.validation && this.field.validation.length"><v-icon>information_outline</v-icon></span></label>
            </v-col>

            <v-alert type="info" v-if="this.showValidationGuide && this.field.validation && this.field.validation.length">
                <div v-for="(rule, ruleIndex) in this.field.validation" :key="ruleIndex">{{ validationRuleAsString(rule) }}</div>
            </v-alert>

            <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            <v-alert type="warning"  dismissable value="true" v-if="this.field.warnings">{{ this.field.warning }}</v-alert>
            <v-alert type="error" dismissable value="true" v-if="errorMessages && errorMessages.length">
                <div v-for="(error, index) in errorMessages" :key="index" >{{ error.reason }}*</div>
            </v-alert>

            <v-col cols="12">
                <v-text-field
                    :name="this.field.fieldName" 
                    v-model="input" v-on:keyup="updateField()"
                    :required="this.field.required" 
                    :disabled="this.field.disabled"
                ></v-text-field>
            </v-col>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'ies-text',
    props: ['field'],
    data () {
        return {
            input: this.field.value || this.field.oldValue,
            errorMessages: [],
            showValidationGuide: false
        }
    },
    mounted: function(){
        this.registerEventListeners();
        console.log(this.field);
    },
    methods: {
        registerEventListeners: function(){
            var self = this;
            this.$root.$on('Field: set errors', function(payload){
                console.log("TEXT: I heard ", payload);
                if (payload.modelName = self.field.modelName && payload.fieldName == self.field.fieldName){
                    self.errorMessages = payload.errors;
                    console.log("TEXT: I set ", self.field.errors);
                }
            });

            this.$root.$on('Field: clear errors', function(payload){
                if (payload.modelName = self.field.modelName && payload.fieldName == self.field.fieldName){
                    self.field.errors = null;
                }
            });
        },

        updateField: function(){
            this.field.value = this.input;
            this.errorMessages = [];
            this.$root.$emit('Edit: Update Field', {'id': 'notset', 'field': this.field, 'value': this.field.value});
        },

        toggleValidationGuide: function(){
            this.showValidationGuide = !this.showValidationGuide;
        },

        validationRuleAsString: function(rule){
            console.log("explain rule");
            var explainer = '';
            switch(rule.ruleType){
                case 'minLength': explainer = "The text must be at least " + rule.value + " characters long"; break;
                case 'maxLength': explainer = "The text must not be more than " + rule.value + " characters long"; break;
                case 'minValue': explainer = "The value must be more than " + rule.value; break;
                case 'maxValue': explainer = "The value must be less than " + rule.value; break;
                case 'notEmpty': explainer = "The field must not be empty"; break;
                case 'regExp': explainer = "Pattern: " + rule.value; break;
                default:
                    explainer = rule.ruleType + ' ' + rule.value;
            }
            console.log(explainer);
            return explainer;
        }
    },
/*
    computed:{
        'errorMessages': {
            get:function(){
                console.log("computed error messages ", this.field.errors);
                return this.field.errors;
            }
        } 
    }
*/
}
</script>
