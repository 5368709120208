<template>
    <v-flex>{{ status }}</v-flex>
</template>

<script>
/**
 * Outputs a list of facility categories representing the values stored in the
 * model's field.  The field is an integer, but the output is a string
 *
 */
export default {
    name: 'ies-status',
    props: ['field', 'value'],
    data () {
        return {}
    },

    computed: {
        status: function(){
            switch(parseInt(this.value)){
				case 1:
				case 3:
				case 5:
					return "Inactive";
				case 2:
				case 4:
				case 6:
					return "Active";
				case 8:
					return "New Enquiry";
				case 9:
					return "Stay Completed";
				case 10:
					return "Re-enquiry";
				case 11:
					return "Availability Confirmed";
				case 12:
					return "Reservation Confirmed";
				case 13:
					return "Quote Accepted";
				case 14:
					return "Quote Expired";
				case 15:
					return "Quote Declined";
				case 16:
					return "No Availability";
				case 17:
					return "New Cancellation";
				case 18:
					return "Cancellation Confirmed By Hotel";
				case 19:
					return "Quote Declined, Confirmed By Hotel";
				case 20:
					return "Alternative Offered";
				default:
					return "Unknown";					
			}
        }
    }
}
</script>
