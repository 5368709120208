<template>
    <v-container fluid>
       <v-layout row-sm column>
            <v-col cols="12">
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-col>

            <v-col cols="12">
    
                <div v-for="(dataGroup, groupIndex) in viewModel">
                    <div>
                        <v-text-field placeholder="Name" v-model="dataGroup.name" v-on:keyup="updateField()"></v-text-field>
                        <v-btn v-on:click="removeGroup(groupIndex)"><v-icon>delete</v-icon> Delete Group</v-btn>
                    </div>

                    <div v-for="(dataProperty, index) in dataGroup.properties">
                        <v-layout row v-if="dataProperty">
                        <v-col cols="12" md="5">
                           The data item named:
                        </v-col>
                        <v-col cols="12" md="3">
                           <v-text-field placeholder="Name" v-model="dataProperty.name" v-on:keyup="updateField()"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="5">
                           has the value:
                        </v-col>
                        <v-col cols="12" md="5">
                           <v-text-field placeholder="Value" v-model="dataProperty.value" v-on:keyup="updateField()"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                           <v-btn v-on:click="removeProperty(groupIndex, index)"><v-icon>delete</v-icon> Delete Property</v-btn>
                        </v-col>
                        </v-layout>
                    </div>
                    <div>
                        <v-btn v-on:click="addNewProperty(groupIndex)"><v-icon>add</v-icon> Add Data Property</v-btn>
                    </div>

                    <div>
                        <v-btn v-on:click="addNewGroup()"><v-icon>add</v-icon> Add Group</v-btn>
                    </div>
                    <input type="hidden" :name="field.fieldName" :value="field.value">
                    <v-alert type="error" :value="field.errors" style="width: 100%">
                        <li v-for="error in field.errors">
                            {{ error }}
                        </li>
                    </v-alert>

                </div>

            </v-col>

        </v-layout>
    </v-container>
</template>

<script>
    /**
     * Allows the management of a channel manager integration configuration
     * 
     */
    export default {
        name: 'ies-channelManagerConfig',
        props: ['field'],
        data() {
            return {
                dataModel: {},
                viewModel: []
            }
        },
        created:function() {
            this.setDataModelFromJSON();
            this.setViewModelFromDataModel();
        },
        methods: {
            setDataModelFromJSON: function(){
                console.log(this.field.value);
                if (!this.field.value){
console.log("has no config data yet");
                    return [];
                }
                this.dataModel = JSON.parse(this.field.value);
                console.log(this.dataModel);
            },
            setViewModelFromDataModel: function(){
                this.viewModel = [];
                console.log("data model is ", this.dataModel);
                var self = this;
                Object.keys(this.dataModel).forEach(function(key){
                    var group = {"name": key, "properties":[]};
                    console.log(key);
                    console.log(typeof self.dataModel[key]);
                    if (typeof self.dataModel[key] != 'object'){
                        group.properties.push({"name":subKey, "type": "value", "value": self.dataModel[key]});
                    }
                    else {
                        Object.keys(self.dataModel[key]).forEach(function(subKey){
                            console.log(subKey)
                            group.properties.push({"name":subKey, "type": "object", "value": self.dataModel[key][subKey]});
                        });
                    }
                    self.viewModel.push(group);
                });
                console.log("view model becomes:", self.viewModel);
            },
            updateField: function(){
                console.log("Not yet working");
                this.setDataModelFromViewModel();
                this.setFieldValueFromDataModel();
            },
            setDataModelFromViewModel: function(){
console.log(this.viewModel);
                var newDataModel = {};
                var self = this;
                this.viewModel.forEach(function(viewModelGroup){

                    // Child object
                    newDataModel[viewModelGroup.name] = {};
console.log(viewModelGroup.properties.length);
                    viewModelGroup.properties.forEach(function(viewModel){
                        if (viewModel){
                            newDataModel[viewModelGroup.name][viewModel.name] = viewModel.value;
                        }
                    });
                });
                this.dataModel = newDataModel;
                console.log("data model becomes:", this.dataModel);
            },
            setFieldValueFromDataModel: function(){
                console.log(JSON.stringify(this.dataModel));
                this.field.value = JSON.stringify(this.dataModel);
            },
            addNewGroup: function(groupIndex){
                this.viewModel.push({"name":"", "properties": []});
            },
            addNewProperty: function(groupIndex){
                this.viewModel[groupIndex].properties.push({"name":"", "type": "value", "value": ""})
            },
            removeGroup: function(groupIndex){
                var newViewModel = [];
                for(var index = 0; index < this.viewModel.length; index++){
                    if(index != groupIndex){
                        newViewModel.push(this.viewModel[index]);
                    }
                }
                this.viewModel = newViewModel;
                this.updateField();
            },
            removeProperty: function(targetGroupIndex, indexToRemove){
                var newViewModel = [];
                for(var groupIndex = 0; groupIndex < this.viewModel.length; groupIndex++){
                    // Add the group in, as it is not the one we are looking for
                    if (groupIndex != targetGroupIndex){
                        console.log("group is not the target so will keep");
                        newViewModel.push(this.viewModel[groupIndex]);
                    }
                    // Make a fresh new copy of the group without the one property we don't want
                    else {
console.log("this is the group we need", this.viewModel[groupIndex]);

                        var newGroup = {"name": this.viewModel[groupIndex].name, "properties": []};
                        for(var index = 0; index < this.viewModel[groupIndex].properties.length; index++){

                            if(index != indexToRemove){
                                newGroup.properties.push(this.viewModel[groupIndex].properties[index]);
                            }
                        }
                        newViewModel.push(newGroup);
                    }
                }
console.log(this.viewModel);
                this.viewModel = newViewModel;
                this.updateField();
            }
        },
        watch: {},
        computed: {}
    }
</script>
