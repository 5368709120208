<template>
    <v-container fluid grid-list-md>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                     <v-card-text>
                         <v-container fluid v-if="reservation.show || 1">
                              <v-layout row wrap>
                                  <v-flex xs12>
                                       No Availability, property is FULLY BOOKED (on some or all of requested dates) and/or cannot accommodate client request
                                  </v-flex>
                                  <v-flex xs12>
                                      <textarea v-model="message"></textarea>
                                  </v-flex>
                              </v-layout>
                          </v-container>
                      </v-card-text>

                      <v-card-actions>
                          <v-btn class="ies-coral" v-on:click="confirmNoAvailability">Confirm No Availability</v-btn>
                      </v-card-actions>
                 </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    /**
     * A Vue component dedicated to letting the user manage a reservations
     * by the ID specified
     *
     * Props:
     *     int 'reservationkey' - The ID of the reservation to display
     */

    import axios from 'axios';
    export default {
        name: 'hotel-reservation-enquiry',
        props: ['reservationkey'],
        data () {
            return {
                reservation: {},
                message: ''
            }
        },
        methods: {

            loadReservation(){
                axios.get('/api/reservation/' + this.reservationkey)
                .then(function(response){
                    this.reservation = response.data;
                }.bind(this));
            },

            confirmNoAvailability(){
console.log(this.reservationkey);
                axios.post('/api/reservation/' + this.reservationkey + '/no-availability', {'message': this.message})
                .then(function(response){
                    alert("No availability message sent to client");
                }.bind(this));
            },
        },

        /**
         * Once mounted this component needs to load the reservation specified
         */
        mounted() {
            this.loadReservation();
        },

        computed: {}
    }
</script>
