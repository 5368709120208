<template>
    <v-col cols="12" md="2" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">description</v-icon> Property Details 
            </v-card-title>

            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col>
                            <v-btn class="mt-1" block :href="mainContactsUrl">Main Contacts</v-btn>
							<v-btn class="mt-1" block :href="reservationContactsUrl">Reservation Contacts</v-btn>
							<v-btn class="mt-1" block :href="telephonesUrl">Telephones</v-btn>
                            <v-btn class="mt-1" block :href="alternativeNamesUrl">Alternative Names</v-btn>
                            <v-btn class="mt-1" block :href="hotelUrlsUrl">Supported URLs</v-btn>
                            <v-btn class="mt-1" block :href="backgroundInfoUrl">Background Info</v-btn>
                            <v-btn class="mt-1" block :href="hotelLocationUrl">Location</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

        </v-card>
    </v-col>
</template>

<script>
    /**
     * Display buttons linking through to a hotels' pages
     */
    export default {
        props:['pageid'],

        data: function() {
            return {
				mainContactsUrl: '',
				reservationContactsUrl: '',
				telephonesUrl: '',
                hotelUrlsUrl: '',
				backgroundInfoUrl: '',
                alternativeNamesUrl: '',
				hotelLocationUrl: ''
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
			this.mainContactsUrl = "/hotel-main-contacts/" + this.pageid;
			this.reservationContactsUrl = "/hotel-reservation-contacts/list?s=as&c[]=HotelContact-hotel_key&o[]===&v[]=" + this.pageid;
			this.telephonesUrl = "/hotel-telephones/list?s=as&c[]=HotelTelephone-hotel_key&o[]===&v[]=" + this.pageid;
            this.alternativeNamesUrl = "/hotel-alternative-names/list?s=as&c[]=Hotel-hotel_key&o[]===&v[]=" + this.pageid;
            this.hotelUrlsUrl = "/hotel-urls/list?s=as&c[]=Hotel-hotel_key&o[]===&v[]=" + this.pageid;
			this.backgroundInfoUrl = "/hotel-background-info/" + this.pageid;
			this.hotelLocationUrl = "/hotel-location/" + this.pageid;
        },

        methods: {},

        computed: {}
    }
</script>



