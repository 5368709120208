/**
* You can pass 'name' property when registering this component, so you can use multiple ies-date-range components on one page
* This component name will be emitted on every date change so you will know which component has been just updated
*
* HOW TO LISTEN FOR THIS EVENT IN PARENT COMPONENT:
* 1. load EvenBus
* 2. add event listener in eg. created() method
*    created() {
*       EventBus.$on('ies-date-range Changed', (data) => {
*           // data.name -> component name
*           // data.range -> ['2019-02-03', '2019-02-15']
*
*           // update parent range data object
*           this.range = data.range
*       });
*   },
*
* 3. update range data in parent component
*
*/
<template>
<v-daterange
        :options="dateRangeOptions"
        :labels="dateRangeLabels"
        @input="onDateRangeChange"
/>
</template>

<script>
    import EventBus from '../../eventBus';
    import { format, subDays, subMonths, subYears } from 'date-fns';

    export default {
        props: ['name', 'startDate', 'endDate'],
        data() {
            return {
                /** Selected main date range, default [today, today] */
                range: [],

                /** date range picker options */
                dateRangeOptions: {
                    startDate: '',
                    endDate: '',
                    minDate: '2000-01-01',
                    maxDate: '2030-01-01',
                    format: 'YYYY-MM-DD',
                    presets: [
                        {
                            label: 'Today',
                            range: [
                                format(new Date(), 'YYYY-MM-DD'),
                                format(new Date(), 'YYYY-MM-DD'),
                            ],
                        },
                        {
                            label: 'Yesterday',
                            range: [
                                format(subDays(new Date(), 1), 'YYYY-MM-DD'),
                                format(subDays(new Date(), 1), 'YYYY-MM-DD'),
                            ],
                        },
                        {
                            label: 'Last 7 Days',
                            range: [
                                format(subDays(new Date(), 7), 'YYYY-MM-DD'),
                                format(new Date(), 'YYYY-MM-DD'),
                            ],
                        },
                        {
                            label: 'Last 30 Days',
                            range: [
                                format(subDays(new Date(), 30), 'YYYY-MM-DD'),
                                format(new Date(), 'YYYY-MM-DD'),
                            ],
                        },
                        {
                            label: 'Last 6 Months',
                            range: [
                                format(subMonths(new Date(), 6), 'YYYY-MM-DD'),
                                format(new Date(), 'YYYY-MM-DD'),
                            ],
                        },
                        {
                            label: 'All Time',
                            range: [
                                '2000-01-01',
                                format(new Date(), 'YYYY-MM-DD'),
                            ],
                        },
                    ],
                },
                dateRangeLabels: {
                    start: 'Start Date',
                    end: 'End Date',
                    preset: 'Date range'
                },
            }
        },
        methods: {
            /**
             * Logic what code should do when dates changes on this date picker
             * 1. updates this.range object
             * 2. emit that change to parent component together with this component name
             */
            onDateRangeChange(range) {
                this.range = range;
                this.emitDateChange();
            },

            /**
             * Emit information using global EventBus that dates has been changes,
             * sending up to the parent current range and component name
             *     Emit name: ies-date-range Changed
             *     data object: {range: [array], name:'string'}
             */
            emitDateChange() {
                EventBus.$emit('ies-date-range Changed', {range: this.range, name: this.getPickerName()});
            },

            /**
             * Method return name of this picker that will be send together with emit on every date change,
             * so parent component can listen for date change,
             *
             * if user set the property 'name' for that component, this will be used as pickerName, if not use default
             * setting specific name for this component can be useful when you want to use multiple date picker on one page
             * so thanks to that name property you will know exactly what datePicker send this emit
             */
            getPickerName() {
                let pickerName = 'dateRange';

                if (this.name) {
                    pickerName = this.name;
                }

                return pickerName;
            },

            /**
             * Sets default date range that is going to be set in date picker, default date is today's
             */
            setDefaultDateRange() {
                this.range = [this.dateRangeOptions.startDate, this.dateRangeOptions.endDate];
                this.emitDateChange();
            },

            /**
             * Set default date range selected on load
             * Check if start and end date was passed to this component as a prop, if not use today's date
             */
            setStartEndDate() {
                this.dateRangeOptions.startDate = this.startDate || format(new Date(), 'YYYY-MM-DD');
                this.dateRangeOptions.endDate = this.endDate || format(new Date(), 'YYYY-MM-DD');
            }
        },
        /**
         * Methods that initialized this page on page load
         */
        mounted() {
            this.setDefaultDateRange();

        },
        created() {
            this.setStartEndDate();
        }
    }
</script>

<style>
    .date-range .date-range__pickers input {
        border: none;
    }
</style>