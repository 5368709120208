import { render, staticRenderFns } from "./ICalConnectionsPage.vue?vue&type=template&id=45e505c6&scoped=true&"
import script from "./ICalConnectionsPage.vue?vue&type=script&lang=js&"
export * from "./ICalConnectionsPage.vue?vue&type=script&lang=js&"
import style0 from "./ICalConnectionsPage.vue?vue&type=style&index=0&id=45e505c6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45e505c6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/live/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('45e505c6')) {
      api.createRecord('45e505c6', component.options)
    } else {
      api.reload('45e505c6', component.options)
    }
    module.hot.accept("./ICalConnectionsPage.vue?vue&type=template&id=45e505c6&scoped=true&", function () {
      api.rerender('45e505c6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/bespoke/Channels/ICalConnectionsPage.vue"
export default component.exports