<template>
    <v-card class="mx-5 my-2">
        <v-card-title style="background-color: gainsboro;">
            <h2 class="subheader">Summary</h2>
        </v-card-title>
        <v-data-table
            :headers="setTableHeaders()"
            :items="[summary]"
            hide-default-footer
        >
            <template v-slot:item="props">
            <tr>
                <td class="text-xs-center">£{{ props.item["United Kingdom"].toFixed(2) }}</td>
                <td class="text-xs-center">£{{ props.item["EU VAT Registered"].toFixed(2) }}</td>
                <td class="text-xs-center">£{{ props.item["EU Non VAT Registered"].toFixed(2) }}</td>
                <td class="text-xs-center">£{{ props.item["Rest Of World"].toFixed(2) }}</td>
                <td class="text-xs-center">£{{ props.item["All Regions"].toFixed(2) }}</td>
            </tr>
            </template>

        </v-data-table>
    </v-card>
</template>

<script>
export default {
    props: ['summary'],
    methods: {
        setTableHeaders() {
            return [
                {
                    text: 'United Kingdom Total',
                    align: 'center',
                    value: 'United Kingdom',
                    sortable: false,
                },
                {
                    text: 'EU VAT Registered Total',
                    align: 'center',
                    value: 'EU VAT Registered',
                    sortable: false,
                },
                {
                    text: 'EU Non VAT Registered Total',
                    align: 'center',
                    value: 'EU Non VAT Registered',
                    sortable: false,
                },
                {
                    text: 'Rest Of World Total',
                    align: 'center',
                    value: 'Rest Of World',
                    sortable: false,
                },
                {
                    text: 'Combined Total',
                    align: 'center',
                    value: 'All Regions',
                    sortable: false,
                },
            ];
        }
    }
}
</script>
