var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "grid-list-md": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("h3", [_vm._v("Message History")]),
          ]),
          _vm._v(" "),
          !_vm.reservation.messages || !_vm.reservation.messages.length
            ? _c("v-flex", { attrs: { xs12: "" } }, [
                _vm._v("\n            There has been no dialogue\n        "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.reservation.messages && _vm.reservation.messages.length
            ? _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-list",
                            { attrs: { "three-line": "" } },
                            _vm._l(
                              _vm.reservation.messages,
                              function (message, index) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: index,
                                    attrs: { avatar: "" },
                                    on: { click: function ($event) {} },
                                  },
                                  [
                                    _c(
                                      "v-list-item-avatar",
                                      [
                                        message.from_where == "to"
                                          ? _c("v-icon", [_vm._v("home")])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        message.from_where != "to"
                                          ? _c("v-icon", [_vm._v("person")])
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            _vm._s(
                                              message.from_where != "to"
                                                ? _vm.reservation.user
                                                    .first_name +
                                                    " " +
                                                    _vm.reservation.user
                                                      .last_name
                                                : _vm.reservation.hotel
                                                    .hotel_name
                                            ) +
                                              " " +
                                              _vm._s(message.creation_date) +
                                              " " +
                                              _vm._s(message.creation_time)
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("v-list-item-sub-title", {
                                          domProps: {
                                            innerHTML: _vm._s(message.message),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-flex",
            [
              _vm._v(
                "\n            Send on behalf of " +
                  _vm._s(
                    _vm.reservation.hotel
                      ? _vm.reservation.hotel.hotel_name
                      : "hotel"
                  ) +
                  ":\n            "
              ),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.message,
                    expression: "message",
                  },
                ],
                domProps: { value: _vm.message },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.message = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { disabled: _vm.message.length == 0 },
                  on: { click: _vm.sendNewMessage },
                },
                [_c("v-icon", [_vm._v("send")]), _vm._v(" Send")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }