var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _c("span", { staticClass: "subheader" }, [
          _vm._v("Transaction summary by hotel contract type"),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c("v-flex", { attrs: { xs2: "", "offset-xs1": "" } }, [
                _c("strong", [_vm._v("Hotel Type")]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.summary, function (item, key) {
                return _c(
                  "v-flex",
                  {
                    key: key,
                    staticClass: "text-xs-right",
                    attrs: { xs2: "" },
                  },
                  [_c("strong", [_vm._v(_vm._s(_vm._f("capitalize")(key)))])]
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c("v-flex", { attrs: { xs2: "", "offset-xs1": "" } }, [
                _c("strong", [_vm._v("Upfront")]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.summary, function (item, key) {
                return _c(
                  "v-flex",
                  {
                    key: key,
                    staticClass: "text-xs-right",
                    attrs: { xs2: "" },
                  },
                  [
                    key === "transactions"
                      ? _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.UPF) +
                              "\n                "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm._f("pounds")(item.UPF)) +
                              "\n                "
                          ),
                        ]),
                  ]
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c("v-flex", { attrs: { xs2: "", "offset-xs1": "" } }, [
                _c("strong", [_vm._v("In Arrears")]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.summary, function (item, key) {
                return _c(
                  "v-flex",
                  {
                    key: key,
                    staticClass: "text-xs-right",
                    attrs: { xs2: "" },
                  },
                  [
                    key === "transactions"
                      ? _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.ARR) +
                              "\n                "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm._f("pounds")(item.ARR)) +
                              "\n                "
                          ),
                        ]),
                  ]
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c("v-flex", { attrs: { xs2: "", "offset-xs1": "" } }, [
                _c("strong", [_vm._v("Manual Payments")]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.summary, function (item, key) {
                return _c(
                  "v-flex",
                  {
                    key: key,
                    staticClass: "text-xs-right",
                    attrs: { xs2: "" },
                  },
                  [
                    key === "transactions"
                      ? _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.manual) +
                              "\n                "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm._f("pounds")(item.manual)) +
                              "\n                "
                          ),
                        ]),
                  ]
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c("v-flex", { attrs: { xs2: "", "offset-xs1": "" } }, [
                _c("strong", [_vm._v("Total")]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.summary, function (item, key) {
                return _c(
                  "v-flex",
                  {
                    key: key,
                    staticClass: "text-xs-right",
                    attrs: { xs2: "" },
                  },
                  [
                    key === "transactions"
                      ? _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.total) +
                              "\n                "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm._f("pounds")(item.total)) +
                              "\n                "
                          ),
                        ]),
                  ]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }