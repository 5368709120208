<template>
    <v-col md="6" lg="4" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon>today</v-icon> Calendar
            </v-card-title>

            <v-card-text>

                <v-container class="d-md-none">
                    <v-layout row wrap>
                        Mobile stack view
                        <v-col cols="12" v-for="(cell, rowIndex) in appointmentsByDate.appointments" :key="rowIndex">
                            <v-flex>
                                {{ cell.date }} - {{ cell.isSelected ? 'selected' : 'false' }}
                                <ul>
                                    <li v-for="(appointment, apptIndex) in cell.appointments">{{ appointment.staff.first_name + ' ' + appointment.staff.last_name  }}: {{ appointment.label }}</li>
                                </ul>
                            </v-flex>
                        </v-col>
                    </v-layout>
                </v-container>

                
                <v-container class="d-none d-md-flex">
                    <v-layout row wrap>
                        Desktop calendar view
                    </v-layout>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-btn v-on:click="selectPreviousDate">< Previous Date</v-btn>
                <v-btn v-on:click="selectNextDate">Next Date ></v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
    import axios from 'axios';

    export default {
        props:['type'],
        data: function() {
            return {
                data: [],
                dateSelectedIndex: 0
            }
        },
        created:function() {
            this.fetchData();
        },
        methods:{

            fetchData:function(){
                axios.get('/api/calendar')
                .then(function(response){
                    this.data = response.data;
                    console.log(this.data);
                }.bind(this));
            },

            resetSelectedDate: function(){
                this.deselectAllDates();
                this.selectDateByIndex(0);
            },

            deselectAllDates: function(){
                this.data.forEach(function(cell){
                    cell.isSelected = false;
                });
            },

            selectDateByIndex: function(index){
                this.data[index].isSelected = true;
console.log(this.data, index);
            },

            selectPreviousDate: function(){
                this.deselectAllDates();
                this.dateSelectedIndex = (this.dateSelectedIndex > 1) ? this.dateSelectedIndex - 1 : 0;
                this.selectDateByIndex(this.dateSelectedIndex);
            },

            selectNextDate: function(){
                this.deselectAllDates();
                this.dateSelectedIndex = this.dateSelectedIndex + 1;
                this.selectDateByIndex(this.dateSelectedIndex);
            },
        },
        computed: {
            appointmentsByDate: function(){
                console.log('apd', this.data);
                return this.data;
            }
        }
    }

</script>

<style>

.list__item__subtitle {
    height: auto;
    white-space: normal;
    font-size: 14px;
}

.list__tile {
    height: auto;
    padding: 5px 16px;
}

@media only screen and (max-width: 600px) {
    .list__tile__title {
        font-size: 10px;
    }
}

</style>
