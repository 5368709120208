var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.type == "list"
    ? _c(
        "v-col",
        { staticClass: "mb-3", attrs: { cols: "12", md: "6", lg: "4" } },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline", attrs: { "primary-title": "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "v-align-container full-width" },
                        [
                          _c(
                            "h4",
                            { staticClass: "v-align-div" },
                            [
                              _c("v-icon", { attrs: { large: "" } }, [
                                _vm._v("info"),
                              ]),
                              _vm._v(
                                " Notifications (" +
                                  _vm._s(_vm.data.length) +
                                  ") !!!"
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _vm.loading
                        ? _c("div", [_vm._v("Loading...")])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.loading
                        ? _c(
                            "v-list",
                            { attrs: { dense: "" } },
                            [
                              _vm._l(_vm.data, function (item, index) {
                                return [
                                  _c(
                                    "v-list-item",
                                    {
                                      key: index,
                                      staticClass: "news-list-item",
                                      attrs: { ripple: "", href: item.url },
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "", medium: "" } },
                                            [_vm._v(_vm._s(item.icon))]
                                          ),
                                          _vm._v(" " + _vm._s(item.text)),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  index + 1 < _vm.data.length
                                    ? _c("v-divider")
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm.type == "icon"
    ? _c(
        "div",
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-5 ies-coral",
              nativeOn: {
                click: function ($event) {
                  _vm.data = []
                },
              },
            },
            [_vm._v("clear data")]
          ),
          _vm._v(" "),
          _c(
            "a",
            { attrs: { href: "/" } },
            [
              _c(
                "v-icon",
                {
                  directives: [
                    {
                      name: "badge",
                      rawName: "v-badge",
                      value: { value: _vm.data.length, left: true },
                      expression: "{ value: data.length, left: true }",
                    },
                  ],
                  staticClass: "ies-green--after ies-dark-gray--text--after",
                  attrs: { large: "" },
                },
                [_vm._v("mail")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-snackbar",
            {
              attrs: {
                timeout: parseInt(6000),
                "multi-line": "",
                color: "ies-green",
              },
              model: {
                value: _vm.snackbar,
                callback: function ($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "full-height full-width v-align-container text-xs-center",
                },
                [
                  _c("h4", { staticClass: "ies-dark-gray--text v-align-div" }, [
                    _vm._v("New Notification"),
                  ]),
                ]
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }