<template>
    <v-container fluid>
       <v-layout row-sm column>
	    <v-col v-if="this.field.schema.displayName != 'none'" cols="12">
    	    <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
    	</v-col>
         <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            <v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            <v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>
    	<v-col cols="12">

        <!-- list must be list of ['text' => x, 'value' => y] -->
        <v-select
            v-model="viewValue"
            :items="priceRanges"
            autocomplete
        ></v-select>
        <input type="hidden" :name="field.fieldName" :text="field.text" :value="field.value">
        <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
            <li v-for="error in field.errors" v-bind:key="error">
                {{ error }}
            </li>
        </v-alert>
    </v-col>
    </v-layout>
  </v-container>
</template>


<script>
    /**
     * Gets + Sets hotel price range fields using a single select box
     */
    export default {
        name: 'ies-input-price-range',
        props: ['field', 'recordData'],
        data() {
            return {
                priceRanges: [
					'Low',
					'Moderate',
					'Expensive',
					'Very Expensive'
				],
                viewValue: this.field.value,
            }
        },
        created:function() {
            this.getPriceRange();
        },
        methods: {
            getPriceRange:function(){
                axios.get('/api/hotel-price-range/' + this.recordData.hotel_key).then((response) => {
					let ranges = response.data.ranges; 
					this.viewValue = Object.keys(ranges).filter(k => ranges[k])[0];
                });
            },
        },
        watch: {
            viewValue: function (newValue) {
                this.field.value = newValue;
            }
        }
    }
</script>

