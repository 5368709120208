<template>
    <v-flex v-if="value">
        <v-btn :href="addressLink" target="_blank">
            <v-icon>mail</v-icon>
        </v-btn>
    </v-flex>
</template>

<script>
/**
 * Outputs a link for a gift voucher's address for mailing
 */
export default {
    name: 'ies-voucher-address-link',
    props: ['field', 'value'],
    data () {
        return {}
    },

    methods: {
        getUrl: function(){
            return "/vouchers-to-post/" + this.value;
        },
    },

    computed: {
        addressLink: function(){
            return this.getUrl();
        },
    }
}
</script>
