<template>
    <div>
        <v-flex v-if="this.field.schema.displayName != 'none'" xs12>
        	<label :for="field.fieldName"> {{ field.schema.displayName }}: </label>
        </v-flex>

        <select style="background-color: white; -moz-appearance: auto; -webkit-appearance: auto;" v-model="value">
            <option v-for="option in options" 
                    :value="option">{{ option }}</option>
        </select>

        <input type="hidden" :name="field.fieldName" :value="value">

        <v-alert
            type="warning"
            v-if="!valueIsInDefaultOptions"
            style="width: 100%;">
            Warning: The selected value "{{ value }}" is not in the default MIME types.
        </v-alert>

        <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
            <li v-for="error in field.errors">
                {{ error }}
            </li>
        </v-alert>
    </div>

</template>

<script>

export default {
    name: 'ies-input-mime-type',
    props: ['field'],
    data () {
        const defaultOptions = [
            '',
            'application/msword',
            'application/octet-stream',
            'image/gif',
            'image/jpeg',
            'image/pjpeg',
            'image/png',
            'image/psd',
            'image/webp',
            'image/x-ms-bmp'
        ];

        return {
            defaultOptions : defaultOptions,
            options: [... defaultOptions],
            value: this.field.value,
        }
    },

    computed: {
        valueIsInDefaultOptions: function () {
            return this.defaultOptions.includes(this.value);
        }
    },

    mounted () {        
        if (!this.valueIsInDefaultOptions) {
            this.options.push(this.value);
        }
    },

    watch: {
        value: function () {
            this.field.value = this.value;
        }
    },
}
</script>


