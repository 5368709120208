var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("h2", [_vm._v("Quick Search")]),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "justify-center": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", lg: "8" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "justify-center": "" } },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        md: "6",
                        lg: "6",
                        "ma-1": "",
                        id: "daterangeColumn",
                        xs12: "",
                        md6: "",
                      },
                    },
                    [
                      _c("v-flex", { attrs: { "pl-3": "" } }, [
                        _c("h6", { staticClass: "title" }, [
                          _vm._v("Filter by Date"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("v-date-picker", {
                        attrs: { range: "", color: "lime" },
                        on: { input: _vm.onDateRangeChange },
                        model: {
                          value: _vm.drprange,
                          callback: function ($$v) {
                            _vm.drprange = $$v
                          },
                          expression: "drprange",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        md: "6",
                        lg: "4",
                        "ma-1": "",
                        id: "filtersColumn",
                        xs2: "",
                        md3: "",
                        lg4: "",
                      },
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c("v-col", { attrs: { "pl-3": "", cols: "12" } }, [
                            _c("h6", { staticClass: "title" }, [
                              _vm._v("Filter by Properties"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "5" } },
                            [_c("v-subheader", [_vm._v("Date selector")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.filterOptions.dateColumn,
                                  "return-object": "",
                                },
                                model: {
                                  value: _vm.filters.dateColumn,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "dateColumn", $$v)
                                  },
                                  expression: "filters.dateColumn",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [_c("v-subheader", [_vm._v("Voucher Type")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "7" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.filterOptions.voucherType,
                                  "return-object": "",
                                },
                                model: {
                                  value: _vm.filters.voucherType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "voucherType", $$v)
                                  },
                                  expression: "filters.voucherType",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [_c("v-subheader", [_vm._v("Source Type")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "7" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.filterOptions.sourceType,
                                  multiple: "",
                                },
                                model: {
                                  value: _vm.filters.sourceType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "sourceType", $$v)
                                  },
                                  expression: "filters.sourceType",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [_c("v-subheader", [_vm._v("Redeem status")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "7" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.filterOptions.redeemStatus,
                                  "return-object": "",
                                },
                                model: {
                                  value: _vm.filters.redeemStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "redeemStatus", $$v)
                                  },
                                  expression: "filters.redeemStatus",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [_c("v-subheader", [_vm._v("Active status")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "7" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.filterOptions.activeStatus,
                                  "return-object": "",
                                },
                                model: {
                                  value: _vm.filters.activeStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "activeStatus", $$v)
                                  },
                                  expression: "filters.activeStatus",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticStyle: { text: "align: center" },
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ies-green",
                          on: {
                            click: function ($event) {
                              return _vm.filter()
                            },
                          },
                        },
                        [_vm._v("Filter")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.reset()
                            },
                          },
                        },
                        [_vm._v("Reset")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              attrs: {
                cols: "12",
                md: "6",
                lg: "4",
                "ma-1": "",
                xs12: "",
                sm6: "",
                md3: "",
                lg2: "",
              },
            },
            [
              _c("ies-voucher-filter-result", {
                attrs: {
                  show: _vm.showFilterResults,
                  result: _vm.filterResults,
                  range: _vm.filteredDates,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "linearWrapper" } },
        [
          _c("v-progress-linear", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showProgressIcon,
                expression: "showProgressIcon",
              },
            ],
            attrs: { indeterminate: true, color: "lime" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("h2", [_vm._v("Comparison With Past Dates")]),
      _vm._v(" "),
      _c(
        "v-expansion-panels",
        [
          _c(
            "v-expansion-panel",
            { attrs: { expand: "" } },
            [
              _c("v-expansion-panel-header", [
                _c("label", [_vm._v("Compare With Past Dates")]),
              ]),
              _vm._v(" "),
              _c(
                "v-expansion-panel-content",
                [
                  _c("v-layout", { attrs: { row: "", wrap: "" } }, [
                    _c(
                      "div",
                      {
                        attrs: {
                          "full-width": "",
                          "offset-y": "",
                          "close-on-content-click": false,
                          bottom: "",
                        },
                        model: {
                          value: _vm.comparisonMenu,
                          callback: function ($$v) {
                            _vm.comparisonMenu = $$v
                          },
                          expression: "comparisonMenu",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-card-text",
                              [
                                _c("v-date-picker", {
                                  attrs: { range: "", color: "lime" },
                                  on: { input: _vm.onCompareDateRangeChange },
                                  model: {
                                    value: _vm.compdrprange,
                                    callback: function ($$v) {
                                      _vm.compdrprange = $$v
                                    },
                                    expression: "compdrprange",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  row: "",
                                  wrap: "",
                                  "justify-center": "",
                                },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { id: "compareBtn", color: "lime" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.compare()
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", [_vm._v("compare_arrows")]),
                                    _vm._v("Compare"),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.showComparisonResultsTables
                    ? _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "", "justify-center": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "", md3: "", lg3: "" } },
                            [
                              _c("ies-voucher-filter-result", {
                                attrs: {
                                  show: _vm.showFilterResults,
                                  result: _vm.filterResults,
                                  range: _vm.filteredDates,
                                  subname: "A",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "", md3: "", lg3: "" } },
                            [
                              _c("ies-voucher-filter-result", {
                                attrs: {
                                  show: _vm.showFilterResults,
                                  result: _vm.filterComparisonResults,
                                  range: _vm.filteredComparisonDates,
                                  subname: "B",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "", md3: "", lg3: "" } },
                            [
                              _c("ies-voucher-filter-result", {
                                attrs: {
                                  show: _vm.showFilterResults,
                                  result: _vm.comparisonDifferences,
                                  difference: "true",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _c("label", [_vm._v("Result Table")]),
              ]),
              _vm._v(" "),
              _c(
                "v-expansion-panel-content",
                { attrs: { value: _vm.showResultTable } },
                [
                  _c(
                    "div",
                    { attrs: { id: "filterResultsTable" } },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            wrap: "",
                            "align-center": "",
                            "justify-center": "",
                          },
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", md10: "" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    row: "",
                                    wrap: "",
                                    "justify-space-between": "",
                                  },
                                },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs3: "", id: "tableSearch" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "append-icon": "search",
                                          label: "Search table",
                                        },
                                        model: {
                                          value: _vm.search,
                                          callback: function ($$v) {
                                            _vm.search = $$v
                                          },
                                          expression: "search",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "lime" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadCSV()
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", [_vm._v("file_download")]),
                                      _vm._v(" CSV"),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "", "justify-center": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", md10: "" } },
                            [
                              _vm._v("***\n                            "),
                              _c(
                                "v-data-table",
                                {
                                  staticClass: "elevation-1",
                                  attrs: {
                                    headers: _vm.vouchersTableHeaders,
                                    items: _vm.filteredVouchers,
                                    search: _vm.search,
                                    "footer-props":
                                      _vm.vouchersTableSettings.items,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "item",
                                      fn: function (props) {
                                        return [
                                          _c("tr", [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(props.item.voucher_code)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(_vm._s(props.item.value)),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  props.item
                                                    .voucher_creation_date
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  props.item.voucher_expiry_date
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  props.item.is_active_string
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  props.item.purchaser_email
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  props.item.recipient_email
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "openVoucherBtn",
                                                    attrs: { small: "" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openVoucherPage(
                                                          props.item.voucher_key
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("send")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        slot: "no-results",
                                        value: true,
                                        color: "error",
                                        icon: "warning",
                                      },
                                      slot: "no-results",
                                    },
                                    [
                                      _vm._v(
                                        '\n                                    Your search for "' +
                                          _vm._s(_vm.search) +
                                          '" found no results.\n                                '
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _c("label", [_vm._v("All time - Overview stats")]),
              ]),
              _vm._v(" "),
              _c(
                "v-expansion-panel-content",
                { attrs: { value: "true" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            _vm._l(_vm.overview.allTime, function (item) {
                              return _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "2",
                                    sm2: "",
                                    md2: "",
                                    lg2: "",
                                  },
                                },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c("v-card-title", [
                                        _c("h5", [_vm._v(_vm._s(item.name))]),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-divider"),
                                      _vm._v(" "),
                                      item.name == "Refer a friend vouchers"
                                        ? _c(
                                            "v-list",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v("Total Count:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "align-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.totalCount)
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v("Active Count:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "align-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.activeCount)
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v("Used Count:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "align-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.usedCount)
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v("Used Value:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "align-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "£ " +
                                                          _vm._s(
                                                            item.usedTotalValue
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : item.name == "Scheduled delivery"
                                        ? _c(
                                            "v-list",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v("Total Delivered:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "align-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.totalDelivered
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v("Delivery failed:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "align-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.totalUndelivered
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-list",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v("Total Count:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "align-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.totalCount)
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v("Total Value:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "align-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "£ " +
                                                          _vm._s(
                                                            item.totalValue
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v("Average Value:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "align-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "£ " +
                                                          _vm._s(
                                                            item.averageValue
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _c("label", [_vm._v("Last 30 days - Overview stats")]),
              ]),
              _vm._v(" "),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            _vm._l(_vm.overview.recent, function (item) {
                              return _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c("v-card-title", [
                                        _c("h5", [_vm._v(_vm._s(item.name))]),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-divider"),
                                      _vm._v(" "),
                                      item.name == "Refer a friend vouchers"
                                        ? _c(
                                            "v-list",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v("Total Count:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "align-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.totalCount)
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v("Active Count:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "align-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.activeCount)
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v("Used Count:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "align-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.usedCount)
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v("Used Value:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "align-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "£ " +
                                                          _vm._s(
                                                            item.usedTotalValue
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : item.name == "Scheduled delivery"
                                        ? _c(
                                            "v-list",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v("Total Delivered:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "align-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.totalDelivered
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v("Delivery failed:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "align-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.totalUndelivered
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-list",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v("Total Count:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "align-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.totalCount)
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v("Total Value:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "align-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "£ " +
                                                          _vm._s(
                                                            item.totalValue
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v("Average Value:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "align-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "£ " +
                                                          _vm._s(
                                                            item.averageValue
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: parseInt(2000),
            "multi-line": "",
            color: "ies-green",
          },
          model: {
            value: _vm.successSnackbar,
            callback: function ($$v) {
              _vm.successSnackbar = $$v
            },
            expression: "successSnackbar",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "full-height full-width v-align-container text-xs-center",
            },
            [
              _c("h4", { staticClass: "ies-dark-gray--text v-align-div" }, [
                _vm._v("Results updated"),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: parseInt(3000),
            "multi-line": "",
            color: "ies-coral",
          },
          model: {
            value: _vm.errorSnackbar,
            callback: function ($$v) {
              _vm.errorSnackbar = $$v
            },
            expression: "errorSnackbar",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "full-height full-width v-align-container text-xs-center",
            },
            [
              _c("h4", { staticClass: "ies-dark-gray--text v-align-div" }, [
                _vm._v("Error"),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }