<template>
	<v-container fluid>
		<v-layout row-sm column>
			<v-col v-if="this.field.schema.displayName != 'none'" cols="12">
				<label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
    		</v-col>
        	<v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
        	
			<v-alert type="info" v-if="this.showValidationGuide && this.field.validation && this.field.validation.length">
                <div v-for="(rule, ruleIndex) in this.field.validation" :key="ruleIndex">{{ validationRuleAsString(rule) }}</div>
            </v-alert>

			<v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
        	<v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
        	<v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>
    		<v-col cols="12">
        		<!-- list must be list of ['text' => x, 'value' => y] -->
        		<v-select
            		v-model="viewValue"
            		:items="reviewers"
            		autocomplete
        		></v-select>
        		<input type="hidden" :name="field.fieldName" :text="field.text" :value="field.value">

				<v-alert type="error" dismissable value="true" v-if="errorMessages && errorMessages.length">
                	<div v-for="(error, index) in errorMessages" :key="index" >{{ error.reason }}*</div>
            	</v-alert>
    		</v-col>
		</v-layout>
	</v-container>
</template>


<script>
    /**
     * Allows the selection of a voucher type ID by choosing its name from
     * a selection downloaded from the API on creation
     */
    export default {
        name: 'ies-hotel-reviewers',
        props: ['field'],
        data() {
            return {
				showValidationGuide:false,
				errorMessages: [],
                reviewers: [],
                viewValue: parseInt(this.field.value),
            }
        },
        created:function() {
            this.getReviewers();
			this.addEventListeners();
        },
        methods: {
			addEventListeners: function() {
				var self = this;

            	this.$root.$on('Field: set errors', function(payload){
                	console.log("TEXT: I heard ", payload);
                	if (payload.modelName = self.field.modelName && payload.fieldName == self.field.fieldName){
                   		self.errorMessages = payload.errors;
                    	console.log("TEXT: I set ", self.field.errors);
                	}
            	});
			},

			toggleValidationGuide: function(){
    	        this.showValidationGuide = !this.showValidationGuide;
	        },

			validationRuleAsString: function(rule){
            	console.log("explain rule");
            	var explainer = '';
           		switch(rule.ruleType){
            	    case 'minLength': explainer = "The text must be at least " + rule.value + " characters long"; break;
        	        case 'maxLength': explainer = "The text must not be more than " + rule.value + " characters long"; break;
    	            case 'minValue': explainer = "The value must be more than " + rule.value; break;
	                case 'maxValue': explainer = "The value must be less than " + rule.value; break;
                	case 'notEmpty': explainer = "The field must not be empty"; break;
                	case 'regExp': explainer = "Pattern: " + rule.value; break;
                	default:
                	    explainer = rule.ruleType + ' ' + rule.value;
            	}
            	console.log(explainer);
            	return explainer;
        	},

            getReviewers: function(){
                axios.get('/api/hotel-reviewers')
                .then(function(response){
                    console.log(response);
                    var reviewers = [];
                    response.data.forEach(function(reviewer){
                        reviewers.push({ text: reviewer.first_name + ' ' + reviewer.surname, value: reviewer.user_key });
                    });
					reviewers.push({ text: "Other...", value: 0 });
                    this.reviewers = reviewers;
                }.bind(this));
            },
        },
        watch: {
            field: function(newValue){
                console.log("HOTEL REVIEWERS: The field watch fired", newValue);
            },
            viewValue: function (newValue) {
                console.log("HOTEL REVIEWERS: The view value changed");
                this.field.value = newValue;
            }
        }
    }
</script>

