<template>
    <v-container fluid class="hidden">
       <v-layout row-sm column>
            <v-flex xs12>
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-flex>
            <v-flex xs12 sm6>
                {{ this.field.value }}
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'ies-hidden',
    props: ['field'],
    data () {
        return {
        }
    },
}
</script>


