<template>
    <v-col cols="12" md="3">
    <v-card class="ma-1">
        <v-card-title class="title font-weight-black">
            <v-icon class=pr-3>add_circle</v-icon> Plus
        </v-card-title>

        <v-card-text>
            <v-btn block href="/plus-tile/list">Plus Tiles</v-btn>
            <v-btn block href="/plus-partner-offer/list">Partner Offers</v-btn>
            <v-btn block href="/plus-secret-sale/list">Secret Sales</v-btn>
        </v-card-text>
    </v-card>
    </v-col>
</template>

<script>
    /**
     * Display buttons linking through to hotels' pages
     */
    export default {
        props:[],

        data: function() {
            return {
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){},

        methods: {},

        computed: {}
    }
</script>
