var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-flex",
    { attrs: { xs12: "", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-data-table",
                        {
                          staticClass: "elevation-1",
                          attrs: {
                            headers: _vm.headers,
                            items: _vm.stats,
                            "hide-default-footer": "",
                            "disable-initial-sort": "",
                            loading: _vm.loadPercentage < 100,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "items",
                              fn: function (props) {
                                return [
                                  _c("td", [_vm._v(_vm._s(props.item.title))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(props.item.units))]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-xs-right" }, [
                                    _vm._v(_vm._s(props.item.monthToDate1)),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-xs-right" }, [
                                    _vm._v(_vm._s(props.item.quarterToDate1)),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-xs-right" }, [
                                    _vm._v(_vm._s(props.item.yearToDate1)),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-xs-right" }, [
                                    _vm._v(_vm._s(props.item.monthToDate2)),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-xs-right" }, [
                                    _vm._v(_vm._s(props.item.quarterToDate2)),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-xs-right" }, [
                                    _vm._v(_vm._s(props.item.yearToDate2)),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("v-progress-linear", {
                            attrs: {
                              slot: "progress",
                              color: "lime",
                              height: "5",
                            },
                            slot: "progress",
                            model: {
                              value: _vm.loadPercentage,
                              callback: function ($$v) {
                                _vm.loadPercentage = $$v
                              },
                              expression: "loadPercentage",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }