<template>
    <v-container fluid>
        <v-flex xs12 class="ma-3">
            <v-layout row>
                <v-flex xs12 class="mb-2">
                    <v-container fluid>
                        <v-layout column>
                            <v-flex sm12>
                                <v-container>
                                <v-layout row>
                                <v-flex sm3>
                                    <label>Visualisation Type:</label>
                                </v-flex>
                                <v-flex sm9>
                                    <v-select
                                        :items="visualisationTypes"
                                        v-model="visualisation.type"
                                        label=""
                                        class=""
                                        item-text="optionText"
                                        item-value="optionValue"
                                        single-line
                                    ></v-select>
                                </v-flex>
                                </v-layout>
                                </v-container>
                            </v-flex>
                                
                            <v-flex sm12>
                                <v-container>
                                <v-layout row>
                                <v-flex sm3>
                                    <label>Model Field A</label>
                                </v-flex>
                                <v-flex sm9>
                                    The variable, as may be reported on the X-axis
                                    {{ visualisation.modelNameA }}: {{ visualisation.fieldNameA }}
                                    <v-select
                                        :items="section.fields"
                                        v-model="visualisation.modelFieldA"
                                        label=""
                                        class=""
                                        item-text="optionText"
                                        item-value="optionValue"
                                        single-line
                                    ></v-select>
                                </v-flex>
                                </v-layout>
                                </v-container>
                            </v-flex>

                            <v-flex sm12>
                                <v-container>
                                <v-layout row>
                                <v-flex sm3>
                                    <label>Model Field B</label>
                                </v-flex>
                                <v-flex sm9>
                                    The value, as may be reported on the Y-axis
                                    {{ visualisation.modelNameB }}: {{ visualisation.fieldNameB }}
                                    <v-select
                                        :items="section.fields"
                                        v-model="visualisation.modelFieldB"
                                        label=""
                                        class=""
                                        item-text="optionText"
                                        item-value="optionValue"
                                        single-line
                                    ></v-select>
                                </v-flex>
                                </v-layout>
                                </v-container>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-flex>
            </v-layout>
            <v-divider class="mt-3 mb-3" ></v-divider>
        </v-flex>
    </v-container>
</template>

<script>
    export default {
        name: 'PortalDesignerReportVisualisations',
        props: ['section', 'sectionkey', 'used_models', 'models', 'field_display_types', 'modelFieldNames', 'joinTypes', 'primaryModel', 'visualisation'],
        data () {
            return {
                'currentSection' : this.section,
                'sectionKey' : this.sectionkey,
                'fieldDisplayTypes' : this.field_display_types,
                'fieldCount': this.section.fields.length,
                'runCount': 0,
                'visualisationTypes': [{'optionText':'Bar Chart', 'optionValue': 'chartBar'},{'optionText':'Line Chart', 'optionValue': 'chartLine'}, {'optionText':'Pie Chart', 'optionValue': 'chartPie'}, {'optionText':'Scatter Graph', 'optionValue': 'chartScatter'}, {'optionText':'Radar', 'optionValue': 'chartRadar'}, {'optionText':'Doughnut Chart', 'optionValue': 'chartDoughnut'}, {'optionText':'Data Table', 'optionValue': 'dataTable'}, {'optionText':'Bubble Chart', 'optionValue': 'chartBubble'}]
            }
        },
        methods: {
            /**
             * Try to load this page data from the data service, we have to put it in setTimeout in order to allow JavaScipt to create
             * this object in parent component
             */
            loadData(){
                var _this = this;
                setTimeout(() => {
                    var pageService = window.app.pageService;

                    do {
                        if (pageService) {
                            _this.pageData = window.app.pageService.getPageData();
                            _this.modelFieldNames = _this.pageData['modelFieldNames'];
                            _this.joinTypes = _this.pageData['joinTypes'];
                        }
                    }
                    while(!pageService);

                }, 20);
            },

            updateObject: function(){
                this.section = this.currentSection;
            },
        },
        mounted: function(){
            // These view models are used to match the field data
            var modelFieldA = this.visualisation.modelNameA + '.' + this.visualisation.fieldNameA;
            var modelFieldB = this.visualisation.modelNameB + '.' + this.visualisation.fieldNameB;
            this.$set(this.visualisation, 'modelFieldA', modelFieldA);
            this.$set(this.visualisation, 'modelFieldB', modelFieldB);
        },

        watch: {
            /**
             * In the event of the user updating the view model which denotes the 
             * X-axis field name for the visualisation then we need to set the data 
             * attributes accordingly
             */
            'visualisation.modelFieldA': function(){
                var parts = this.visualisation.modelFieldA.split('.');
                this.visualisation.modelNameA = parts[0];
                this.visualisation.fieldNameA = parts[1];
            },

            /**
             * In the event of the user updating the view model which denotes the 
             * X-axis field name for the visualisation then we need to set the data 
             * attributes accordingly
             */
            'visualisation.modelFieldB': function(){
                var parts = this.visualisation.modelFieldB.split('.');
                this.visualisation.modelNameB = parts[0];
                this.visualisation.fieldNameB = parts[1];
            }
        }
}
</script>

<style>
    .designer-switch {
        margin: 0;
        max-height: 40px;
    }
</style>
