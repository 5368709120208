var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      this.field.schema.displayName != "none"
        ? _c("v-flex", { attrs: { xs12: "" } }, [
            _c("label", { attrs: { for: _vm.field.fieldName } }, [
              _vm._v(" " + _vm._s(_vm.field.schema.displayName) + ": "),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.value,
              expression: "value",
            },
          ],
          staticStyle: {
            "background-color": "white",
            "-moz-appearance": "auto",
            "-webkit-appearance": "auto",
          },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.value = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        _vm._l(_vm.options, function (option) {
          return _c("option", { domProps: { value: option } }, [
            _vm._v(_vm._s(option)),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: _vm.field.fieldName },
        domProps: { value: _vm.value },
      }),
      _vm._v(" "),
      !_vm.valueIsInDefaultOptions
        ? _c(
            "v-alert",
            { staticStyle: { width: "100%" }, attrs: { type: "warning" } },
            [
              _vm._v(
                '\n        Warning: The selected value "' +
                  _vm._s(_vm.value) +
                  '" is not in the default MIME types.\n    '
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.field.errors
        ? _c(
            "v-alert",
            {
              staticStyle: { width: "100%" },
              attrs: { type: "error", value: _vm.field.errors },
            },
            _vm._l(_vm.field.errors, function (error) {
              return _c("li", [
                _vm._v("\n            " + _vm._s(error) + "\n        "),
              ])
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }