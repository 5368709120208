var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "6", lg: "4", "ma-1": "" } },
    [
      _c(
        "v-card",
        { staticClass: "ma-2" },
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("cloud_upload")]),
              _vm._v(" Channel Activity\n      "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("div", [_c("strong", [_vm._v("Incoming:")])]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "Incoming price and availability updates from channel managers"
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { lock: "", href: "channel-requests-incoming/list" },
                },
                [
                  _c("v-icon", [_vm._v("cloud_download")]),
                  _vm._v(" Requests Received"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { block: "", href: "channel-responses-outgoing/list" },
                },
                [
                  _c("v-icon", [_vm._v("cloud_upload")]),
                  _vm._v(" Responses Returned"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { block: "", href: "channel-faults/list" },
                },
                [_c("v-icon", [_vm._v("report")]), _vm._v(" Channel Faults")],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mt-3" }, [
                _c("strong", [_vm._v("Outgoing:")]),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "Outgoing booking/cancellation notifications sent to channel managers"
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { block: "", href: "channel-queue/list" },
                },
                [_c("v-icon", [_vm._v("queue")]), _vm._v(" Queue")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { block: "", href: "channel-sent/list" },
                },
                [_c("v-icon", [_vm._v("send")]), _vm._v(" Sent")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { block: "", href: "channel-failed/list" },
                },
                [_c("v-icon", [_vm._v("warning")]), _vm._v(" Failed")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { block: "", href: "channel-audit-events/list" },
                },
                [_vm._v("Audit")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }