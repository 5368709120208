<template>
    <v-col cols="12" md="6" lg="4" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">assignment</v-icon> Notifications
            </v-card-title>

            <v-card-text>
                <v-list>
                    <template v-for="(item, index) in notifications">
                        <v-list-item ripple v-bind:key="index" :href="item.url">
                            <v-list-item-subtitle class="list__item__subtitle"><v-icon left medium>{{item.icon}}</v-icon> <!--<span>{{ formatYYYYMMDD(item.creation_date) }} {{ item.creation_time }}</span>--> {{ item.description }}</v-list-item-subtitle>
                        </v-list-item>
                        <v-divider v-if="index + 1 < data.length" v-bind:key="index"></v-divider>
                    </template>
                </v-list>
            </v-card-text>

            <v-card-actions>
                <v-btn href="/portal-notifications/list">All Notifications</v-btn>
                <v-btn href="/portal-notifications/create">New Notification</v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
    import axios from 'axios';

    export default {
        props:['type'],
        data: function() {
            return {
                data: []
            }
        },
        created:function() {
            this.fetchNotifications(true);
            setInterval( this.fetchNotifications, 60000 );
        },
        methods:{

            fetchNotifications:function(){
                axios.get('/api/notifications')
                .then(function(response){
                    this.data = response.data.notifications;
                }.bind(this));
            },

            formatYYYYMMDD(dateString){
                var date = new Date(Date.parse(dateString));
console.log(date);

                var dd = date.getDate();
                var mm = date.getMonth()+1;
                var yyyy = date.getFullYear();
                if(dd<10) {dd='0'+dd}
                if(mm<10) {mm='0'+mm}
                return yyyy+'-'+mm+'-'+dd;
            },
        },
        computed: {
            notifications: function(){
console.log(this.data);
                return this.data;
            }
        }
    }

</script>

<style>

.list__item__subtitle {
    height: auto;
    white-space: normal;
    font-size: 14px;
}

.list__tile {
    height: auto;
    padding: 5px 16px;
}

@media only screen and (max-width: 600px) {
    .list__tile__title {
        font-size: 10px;
    }
}

</style>
