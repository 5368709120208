<template>
<div>
    <v-container fluid>
        <accounts-filter
            :settings="accountFilterSettings"
        ></accounts-filter>

        <v-progress-linear 
           :indeterminate="true" 
            v-show="isLoading" 
            color="lime"
        ></v-progress-linear>

        <!-- <v-alert :value="noResults" type="info">
            There are no results matching your current filter.
        </v-alert> -->
    </v-container>

    <v-container fluid>
        <!-- <contract-type-summary
        ></contract-type-summary> -->
    </v-container>

    <v-container fluid>
        <hotel-summary
        ></hotel-summary>
    </v-container>

</div>
</template>

<script>
// import EventBus from '@/eventBus';
// Components
import AccountsFilter from '../AccountsFilter'
// import ContractTypeSummary from './ContractTypeSummary'
import HotelSummary from './HotelSummary'

export default {
    components: {
        AccountsFilter,
        // ContractTypeSummary,
        HotelSummary,
    },
    data() {
        return {
            invoices: [],
            isLoading: false,
            noResults: false,
            balance: 'negative,positive',
            type_of_hotel: 'ARR,UPF,FIX',
            query: {
                hotel: {},
                region: '',
                date: {
                    from: '',
                    to: ''
                },
            },
            // Settings object for AccountsFilter
            accountFilterSettings: {
                datePicker: {
                    dateFrom: '2010-01-01',
                    dateTo: '2018-10-03',
                },
                hotelPicker: {
                    hotels: [],
                },
                regionPicker: {
                    regions: [],
                },
                typePicker: {
                    type: 'UPF,ARR',
                },
                balancePicker: {
                    balance: 'negative,positive'
                }
            }
        }
    },
    created() {
        this.accountFilterSettings.datePicker.dateFrom = '2010-01-01'
        this.accountFilterSettings.datePicker.dateTo = ''
    },
    methods: {
        /**
         * Check if there are no invoices so we can display a message that
         * search didn't return any results.
         */
        checkNoResults() {
            !this.invoices.length ? this.noResults = true : this.noResults = false;
        },
    }
}
</script>
