var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "6", lg: "4", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("assignment")]),
              _vm._v(" Notifications\n        "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-list",
                [
                  _vm._l(_vm.notifications, function (item, index) {
                    return [
                      _c(
                        "v-list-item",
                        { key: index, attrs: { ripple: "", href: item.url } },
                        [
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "list__item__subtitle" },
                            [
                              _c(
                                "v-icon",
                                { attrs: { left: "", medium: "" } },
                                [_vm._v(_vm._s(item.icon))]
                              ),
                              _vm._v(" "),
                              _vm._v(" " + _vm._s(item.description)),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      index + 1 < _vm.data.length
                        ? _c("v-divider", { key: index })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-btn", { attrs: { href: "/portal-notifications/list" } }, [
                _vm._v("All Notifications"),
              ]),
              _vm._v(" "),
              _c("v-btn", { attrs: { href: "/portal-notifications/create" } }, [
                _vm._v("New Notification"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }