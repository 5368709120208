<template>
    <v-container fluid>
        <v-layout row wrap class="ies-switch">
            <v-col cols="12">
                <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-col>

            <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            <v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            <v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>

            <v-col cols="12">
                <v-switch
                        v-model="input"
                        :label="labelString"
                        :color="colorParsed"
                        v-on:change="updateField()"
                        :disabled="this.field.disabled">
                </v-switch>

                <!--hidden input with this field value, so we can access it from other component as v-switch will be rendered as div-->
                <input :name="this.field.fieldName" type="hidden" :value="input">
            </v-col>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: 'ies-switch',
        props: ['field', 'color', 'label'],
        data () {
            return {
                input: (this.field.value == '1' ? 1 : 0),
                passedColor: this.color,
                defaultColor: 'lime',   // it's ies-green
            }
        },
        methods: {
            updateField: function(){
                this.field.value = this.input;
                this.emitCheckboxChange();
            },

            /**
             * Because vue renders v-switch as normal div, if we ever want to check this input value from outside of this component
             * and listen for that change - we need to use backup hidden input, but we need to manually trigger change event
             * when v-switch is being changed
             */
            emitCheckboxChange() {
                let search = "input[name='" + this.field.fieldName + "']";
                let input = $(search);
                input.change();
            },

        },
        computed: {
            /**
             * Decides what color apply to switch component
             * @returns {string}
             */
            colorParsed() {
                let color = this.defaultColor;
                let passed = this.passedColor;


                if (passed && (passed === 'switch' || passed === 'switch_green')) {
                    color = this.defaultColor;
                }
                else if (passed && passed.includes('switch_')) {
                    // color was send with switch string, we need to remove switch string
                    color = passed.replace('switch_','');
                }
                else if (passed) {
                    color = passed;
                }

                return color;
            },

            labelString() {
                if (this.label && this.label !== 'No') {
                    return this.input ? 'Yes' : 'No';
                }
                else {
                    return '';
                }
            }
        },
    }
</script>

<style>
    .ies-switch .input-group__details {
        display: none;
    }

</style>
