<template>
    <v-container fluid grid-list-md>
        <v-layout row wrap>
            <v-flex xs12>
                <h3>Message History</h3>
            </v-flex>

            <v-flex xs12 v-if="!reservation.messages || !reservation.messages.length">
                There has been no dialogue
            </v-flex>

            <v-flex xs12 v-if="reservation.messages && reservation.messages.length">
                <v-card>
                    <v-card-text>

                        <v-list three-line>
                            <v-list-item
                                v-for="(message, index) in reservation.messages"
                                :key="index"
                                avatar
                                @click=""
                            >

                                <v-list-item-avatar>
                                    <v-icon v-if="message.from_where == 'to'">home</v-icon>
                                    <v-icon v-if="message.from_where != 'to'">person</v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title>{{ message.from_where != 'to' ? reservation.user.first_name + ' ' + reservation.user.last_name : reservation.hotel.hotel_name }} {{ message.creation_date }} {{ message.creation_time }}</v-list-item-title>
                                    <v-list-item-sub-title v-html="message.message"></v-list-item-sub-title>
                                 </v-list-item-content>
                             </v-list-item>
                         </v-list>

                     </v-card-text>
                 </v-card>
            </v-flex>

            <v-flex>
                Send on behalf of {{ reservation.hotel ? reservation.hotel.hotel_name : 'hotel' }}:
                <textarea v-model="message"></textarea>
                <v-btn v-on:click="sendNewMessage" :disabled="message.length == 0"><v-icon>send</v-icon> Send</v-btn>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    /**
     * A Vue component dedicated to letting the user manage a reservations
     * by the ID specified
     *
     * Props:
     *     int 'reservationkey' - The ID of the reservation to display
     */
    import axios from 'axios';
    export default {
        name: 'hotel-reservation-enquiry',
        props: ['reservationkey'],
        data () {
            return {
                reservation: {},
                message: ""
            }
        },

        methods: {
            loadReservation(){
                axios.get('/api/reservations/id/' + this.reservationkey)
                .then(function(response){
                    this.reservation = response.data.reservation;
                    console.log("messenger: ", response);

                    // In the circumstances where the server may not include the empty relationship
                    // but we still need an empty array for the component to work properly
                    if(!this.reservation.messages){
                        this.reservation.messages = [];
                    }

                }.bind(this));
            },

            sendNewMessage(){
                axios.post('/api/reservation/' + this.reservationkey + '/new-message', {'message': this.message})
                .then(function(response){
                    if (response.data.success){
                        // Update the reservation message chain so the message should appear in the list above
                        this.loadReservation();
                        this.message = "";
                    }
                    else {
                        alert("An error occurred; Message could not be sent");
                    }
                }.bind(this));
            }
        },

        /**
         * Once mounted this component needs to load the reservation specified
         */
        mounted() {
            this.loadReservation();
        },

        computed: {}
    }
</script>

<style>
    .client {
	background: #ffffdd;
    }
    .client:after {
	border-top-color: #ffffdd;
	border-right: 0;
    }
    .hotelier {
	background: #ffddff;
    }
    .hotelier:after {
	border-top-color: #ffddff;
	border-left: 0;
    }

.speech-bubble {
	position: relative;
	border-radius: .4em;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-bottom: 0;
	margin-bottom: -20px;
	margin-left: -10px;
}

textarea {
    border: solid 1px lightgray;
}
</style>
