var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "row-sm": "", column: "" } },
        [
          this.field.schema.displayName != "none"
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _c("label", { attrs: { for: this.field.fieldName } }, [
                  _vm._v(" " + _vm._s(this.field.schema.displayName) + ": "),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          this.field.introduction
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _vm._v(_vm._s(this.field.introduction)),
              ])
            : _vm._e(),
          _vm._v(" "),
          this.field.information
            ? _c(
                "v-alert",
                { attrs: { type: "info", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.information))]
              )
            : _vm._e(),
          _vm._v(" "),
          this.field.warning
            ? _c(
                "v-alert",
                { attrs: { type: "warning", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.warning))]
              )
            : _vm._e(),
          _vm._v(" "),
          this.field.error
            ? _c(
                "v-alert",
                { attrs: { type: "error", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.error))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "d-flex flex-column", attrs: { sm: "12" } },
                [
                  _c("input", {
                    attrs: { type: "hidden", name: _vm.field.fieldName },
                    domProps: { value: _vm.field.value },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-radio-group",
                    {
                      attrs: { row: "", autocomplete: "" },
                      model: {
                        value: _vm.status,
                        callback: function ($$v) {
                          _vm.status = $$v
                        },
                        expression: "status",
                      },
                    },
                    [
                      _c("v-radio", { attrs: { label: "Offline", value: 0 } }),
                      _vm._v(" "),
                      _c("v-radio", {
                        attrs: { label: "Previewable", value: 2 },
                      }),
                      _vm._v(" "),
                      _c("v-radio", { attrs: { label: "Online", value: 1 } }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _vm.errorMessages && _vm.errorMessages.length
                        ? _c(
                            "v-alert",
                            {
                              attrs: {
                                type: "error",
                                dismissable: "",
                                value: "true",
                              },
                            },
                            _vm._l(_vm.errorMessages, function (error, index) {
                              return _c("div", { key: index }, [
                                _vm._v(_vm._s(error.reason) + "*"),
                              ])
                            }),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }