<template>
    <v-flex>

        <v-flex v-for="(visualisation, index) in visualisationList" :key="index">

            <v-flex v-if="visualisation.type == 'dataTable'">
                <ies-list datasource="pageData0" :sectionindex="sectionindex" :viewid="index" title="Not Yet Set" :config="visualisation"></ies-list>
            </v-flex>

            <v-flex xs8  class="mt-3" v-if="visualisation.type == 'chartBar'">
                <v-card>
                    <ies-chart-bar datasource="pageData0" :sectionindex="sectionindex" :viewid="index" :config="visualisation" :content="content"></ies-chart-bar>
                </v-card>
            </v-flex>

            <v-flex xs8 class="mt-3" v-if="visualisation.type == 'chartLine'">
                <v-card>
                    <ies-chart-line datasource="pageData0" :sectionindex="sectionindex" :viewid="index" :config="visualisation" :content="content"></ies-chart-line>
                </v-card>
            </v-flex>

            <v-flex xs8 class="mt-3" v-if="visualisation.type == 'chartPie'">
                <v-card>
                    <ies-chart-pie datasource="pageData0" :sectionindex="sectionindex" :viewid="index" :config="visualisation" :content="content"></ies-chart-pie>
                </v-card>
            </v-flex>

            <v-flex xs8 class="mt-3" v-if="visualisation.type == 'chartBubble'">
                <v-card>
                    <ies-chart-bubble datasource="pageData0" :sectionindex="sectionindex" :viewid="index" :config="visualisation" :content="content"></ies-chart-bubble>
                </v-card>
            </v-flex>

            <v-flex xs8 class="mt-3" v-if="visualisation.type == 'chartPolar'">
                <v-card>
                    <ies-chart-polar datasource="pageData0" :sectionindex="sectionindex" :viewid="index" :config="visualisation" :content="content"></ies-chart-polar>
                </v-card>
            </v-flex>

            <v-flex xs8 class="mt-3" v-if="visualisation.type == 'chartRadar'">
                <v-card>
                    <ies-chart-radar datasource="pageData0" :sectionindex="sectionindex" :viewid="index" :config="visualisation" :content="content"></ies-chart-radar>
                </v-card>
            </v-flex>

            <v-flex xs8 class="mt-3" v-if="visualisation.type == 'chartDoughnut'">
                <v-card>
                    <ies-chart-doughnut datasource="pageData0" :sectionindex="sectionindex" :viewid="index" :config="visualisation" :content="content"></ies-chart-doughnut>
                </v-card>
            </v-flex>

        </v-flex>

        <v-flex xs12 class="mt-3" v-if="visualisations.length == 0">
            There is nothing to display.  Please use the buttons on the option bars to choose how you would like to visualise this data
        </v-flex>

    </v-flex>
</template>


<script>
    export default{
        /**
         * Data passed into the component from the view itself via attributes on its tag
         */
        props:['datasource', 'sectionindex', 'page', 'sectionfields', 'content', 'visualisations'],

        /**
         * Define all the data variables which Vue needs to bind to the view template directly, or react to automatically to regenerate computed properties
         */
        data: function() {
            return {
                pageSections: [],

                /** @var [] visualisations - The array of visualisations of the data which we are displaying to the user */
                visualisationList:     [],

            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
console.log("Running report vue component");
            // Register an event listener callback function with the page service so we can be alerted when the service updates its data with fresh copies
            //EventBus.$on('dataChange', this.loadData);
            //EventBus.$on('visualisationChange', this.loadData);
            //EventBus.$on('pageServiceReady', this.loadData);
            this.pageRecord = JSON.parse(this.page);
            //this.fields = JSON.parse(this.sectionfields);
            //this.data = JSON.parse(this.content);

console.log("PAGE RECORD: ", this.pageRecord);
console.log("vis ", this.content);

console.log(this.datasource, this.sectionindex, this.page, this.sectionfields, this.content);
            this.visualisationList = JSON.parse(this.visualisations);
            //this.loadData();
        },

        /**
         *
         */
        methods: {
/*
            loadData: function(){
console.log("LOADING DATA");
                var queryURL = '/ajax/' + this.pageRecord.page_name + '/report';
                console.log("report parent: load data from url ", queryURL);
                this.$root.$emit('Report: Loading data', {'id': 'notset'});


                axios.post(queryURL,{
                    filters: this.filters,
                    sortOrders: this.sortOrders,
                    pageNumber: this.pageNumber,
                    rowsPerPage: this.rowsPerPage,
                    primaryModel: this.primaryModel,
                })
                .then(function(response) {
                    if (response.status === 200) {
                        if (response.data.success) {
                            //this.addPageData(name, response.data.data);
                            console.log(response.data.data);
                            //this.fields = response.data.data.fields;
                            this.data = response.data.data.data;
                            //this.rowsPerPage = response.data.data.pagesPerRow;

                            // Pass this on to any other component which is working alongside this one
console.log("i just downloaded ", response.data.data);

                            console.log("this page json", this.page.json);

                            this.$root.$emit('Report: Data updated', {'id': 'notset', 'data': response.data.data});
                        }
                        else{
                            alert('An error was encountered when trying obtain data from the server.  Please report this problem to web@i-escape.com');
                        }
                    }
                }.bind(this))
                .catch(function(error) {
                    console.log('Error occured during loading page data in pageService');
                }.bind(this));

/*
console.log("visualisations warpper will load data...");
                this.loaded         = false;
                this.loadingData    = true;
                this.pageSections   = window.app.pageService.getPageSections();

                // Load the fresh data from the page service
                this.metaData       = window.app.pageService.getMetaData();

                // We can only load data if we have been told via the prop which data to load
console.log(this.datasource);
                if (this.datasource){
                    var allData     = window.app.pageService.getPageData();
                    this.data       = allData[this.datasource];

                    // Ensure we have some display data.  If there is nothing set in the data already then default to data table
                    //this.display = this.data.display ? this.data.display : {};

                    // Reload the visualisations from the service so we are fully up to date.  Vue doesn't detect an array replacement if it is taken directly
                    // from the service so we need the new array to let it notice it is a replacement/update
//                    this.visualisations = [].concat(window.app.pageService.getVisualisations(this.datasource));
this.visualisations = this.pageSections[this.sectionindex].visualisations;
console.log('my new visualisations:', this.visualisations);

                    console.log('new ones:');
                    console.log(this.visualisations);

                    // Set the flags which tell the view that the component is itself loaded and ready, and that the data in the table has now finished loading
                    this.loaded     = true;
                }
*//*
            },
*/
       },


        /**
         * Define live data bindings so that the view template will automatically update when Vue detects that the dependencies within the functions defining them below 
         * change.  All data displayed on the template should be defined as a computed property, otherwise it will never change in future when the data variable it is based
         * on does.
         */
        computed: {}
    }

</script>

<style scoped>
    .ies-actions td:first-child{
        padding:0px;
    }
</style>
