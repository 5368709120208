/**
 * Mixin that holds reusable logic to interact with URL
 *
 * How to use:
 * 1. import this mixin to your component:  eg. import {urlParams} from './../../mixins/urlParams';
 * 2. register mixin in your component:         mixins: [urlParams],
 * 3. Now you can use methods from this mixin
 *
 */
export const urlParams = {
    methods:{
        /**
         * returns value of requested query parameter from current page
         */
        getUrlParam: function(paramName) {
            if (paramName) {
                let url_string = window.location.href;
                let url = new URL(url_string);
                let paramValue = url.searchParams.get(paramName);

                return paramValue;
            }
        },

        /**
         * Adds given params to URL as query parameters
         * @param {} params should be in format key=>value, eg. {year=>2018} =>  www.portal.i-esacpe.com/page?year=2018
         * @param pageName|false - we can pass page name to this method, if not provided use this URL path name
         */
        setUrlParams: function(params, pageName = false) {
            const urlNoParams = [location.protocol, '//', location.host, location.pathname].join('');
            const url = new URL(urlNoParams);

            // get all entries in params object
            const entries = Object.entries(params);

            // loop trough each of them and add to url
            if (entries.length) {
                entries.forEach((param) => {
                    if (param[0] && param[1]) {
                        url.searchParams.append(param[0], param[1]);
                    }
                })
            }

            // get this page URL that will be sent to browser history
            if (!pageName) {
                pageName = location.pathname.replace('/', '');
            }

            // update URL
            window.history.pushState(pageName, pageName, url);
        },
    }
}