var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    _vm._l(_vm.section.fields, function (field, fieldKey) {
      return _c(
        "v-flex",
        { key: fieldKey, staticClass: "ma-3", attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c(
                "v-col",
                { staticClass: "mb-2", attrs: { cols: "12" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c("v-flex", { attrs: { sm2: "" } }, [
                        field.isCustom
                          ? _c("strong", [_vm._v("Custom field:")])
                          : field.isComposite
                          ? _c("strong", [_vm._v("Composite field:")])
                          : field.isAssociation
                          ? _c("strong", [_vm._v("Associated field:")])
                          : _c("strong", [_vm._v("Model field:")]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mb-5", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.moveField("up", fieldKey)
                            },
                          },
                        },
                        [_vm._v("Move up ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ies-coral",
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.deleteField(fieldKey)
                            },
                          },
                        },
                        [_vm._v("  Delete ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.moveField("down", fieldKey)
                            },
                          },
                        },
                        [_vm._v(" Mode Down ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !field.isCustom && !field.isComposite
                ? _c(
                    "v-col",
                    { staticClass: "mb-3", attrs: { cols: "12", md: "5" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c("v-flex", { attrs: { sm4: "" } }, [
                            _c("label", [_vm._v("Model")]),
                          ]),
                          _vm._v(" "),
                          _c("v-flex", { attrs: { sm8: "" } }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: field.modelName,
                                    expression: "field.modelName",
                                  },
                                ],
                                staticClass: "full-width",
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        field,
                                        "modelName",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function ($event) {
                                      field.fieldName = ""
                                      _vm.selectModel(field.modelName)
                                    },
                                  ],
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("Please Select A Model "),
                                ]),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.sortModels(_vm.models),
                                  function (modelName) {
                                    return _c(
                                      "option",
                                      {
                                        key: modelName,
                                        domProps: { value: modelName },
                                      },
                                      [_vm._v(" " + _vm._s(modelName) + " ")]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !field.isCustom && (!field.isComposite || field.isAssociation)
                ? _c(
                    "v-col",
                    { staticClass: "mb-3", attrs: { cols: "12", md: "5" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c("v-flex", { attrs: { sm4: "" } }, [
                            _c("label", [_vm._v(" Column")]),
                          ]),
                          _vm._v(" "),
                          _c("v-flex", { attrs: { sm8: "" } }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: field.fieldName,
                                    expression: "field.fieldName",
                                  },
                                ],
                                staticClass: "full-width",
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        field,
                                        "fieldName",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function ($event) {
                                      field.displayType = "default"
                                    },
                                  ],
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("Please Select a Column"),
                                ]),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.sortColumns(field.modelName),
                                  function (columnFieldName) {
                                    return _c(
                                      "option",
                                      { domProps: { value: columnFieldName } },
                                      [
                                        _vm._v(
                                          " " + _vm._s(columnFieldName) + " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                field.isAssociation
                                  ? _c("option", { attrs: { value: "all" } }, [
                                      _vm._v(
                                        "Whole model (works only with custom component)"
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "mb-3", attrs: { cols: "12", md: "5" } },
                [
                  _c(
                    "v-layout",
                    [
                      _c("v-flex", { attrs: { sm4: "" } }, [
                        _c("label", [_vm._v("Display Name")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { sm8: "" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "" },
                            model: {
                              value: field.displayName,
                              callback: function ($$v) {
                                _vm.$set(field, "displayName", $$v)
                              },
                              expression: "field.displayName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "mb-3", attrs: { cols: "12", md: "5" } },
                [
                  _c(
                    "v-layout",
                    [
                      _c("v-flex", { attrs: { sm4: "" } }, [
                        _c("label", [_vm._v("Alias Field Name")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { sm8: "" } },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "" },
                            model: {
                              value: field.alias,
                              callback: function ($$v) {
                                _vm.$set(field, "alias", $$v)
                              },
                              expression: "field.alias",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              (field.fieldName && field.fieldName != "" && !field.isCustom) ||
              (field.models && field.models.length > 0) ||
              field.isAssociation ||
              field.isComposite
                ? _c(
                    "v-col",
                    { staticClass: "mb-3", attrs: { cols: "12", md: "5" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c("v-flex", { attrs: { sm4: "" } }, [
                            _c("label", [_vm._v("Display Type")]),
                          ]),
                          _vm._v(" "),
                          _c("v-flex", { attrs: { sm8: "" } }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: field.displayType,
                                    expression: "field.displayType",
                                  },
                                ],
                                staticClass: "full-width",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      field,
                                      "displayType",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "default" } }, [
                                  _vm._v("Default"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "custom" } }, [
                                  _vm._v("Custom"),
                                ]),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.selectDisplayTypes(field),
                                  function (type, typeKey) {
                                    return _c(
                                      "option",
                                      {
                                        key: typeKey,
                                        domProps: { value: type.value },
                                      },
                                      [_vm._v(" " + _vm._s(type.name) + " ")]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.design.page_mode == "2"
                ? _c(
                    "v-col",
                    { staticClass: "mb-3", attrs: { cols: "12", md: "5" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { sm: "4" } }, [
                            _c("label", [_vm._v("Field Width")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { sm: "8" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  type: "number",
                                  min: "1",
                                  max: "12",
                                  placeholder: "12",
                                },
                                model: {
                                  value: field.fieldWidth,
                                  callback: function ($$v) {
                                    _vm.$set(field, "fieldWidth", $$v)
                                  },
                                  expression: "field.fieldWidth",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              [
                "custom",
                "text",
                "password",
                "integer",
                "email",
                "tinymce",
                "htmlinput",
              ].includes(field.displayType)
                ? _c(
                    "v-col",
                    {
                      staticClass: "mb-3 designer-switch",
                      attrs: { cols: "12", md: "3" },
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("v-flex", { attrs: { sm4: "" } }, [
                            _c("label", [_vm._v("Required?")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { sm8: "" } },
                            [
                              _c("v-switch", {
                                attrs: { light: "" },
                                model: {
                                  value: field.required,
                                  callback: function ($$v) {
                                    _vm.$set(field, "required", $$v)
                                  },
                                  expression: "field.required",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              [
                "text",
                "password",
                "integer",
                "email",
                "textarea",
                "htmlinput",
              ].includes(field.displayType)
                ? _c(
                    "v-col",
                    {
                      staticClass: "mb-3 designer-switch",
                      attrs: { cols: "12", md: "3" },
                    },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("v-flex", { attrs: { sm4: "" } }, [
                            _c("label", [_vm._v("Disabled?")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { sm8: "" } },
                            [
                              _c("v-switch", {
                                attrs: { color: "red", light: "" },
                                model: {
                                  value: field.disabled,
                                  callback: function ($$v) {
                                    _vm.$set(field, "disabled", $$v)
                                  },
                                  expression: "field.disabled",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.design.page_mode == "2"
                ? _c(
                    "v-col",
                    { staticClass: "mb-3", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("v-flex", { attrs: { sm4: "" } }, [
                            _c("label", [_vm._v("Pre-Notice")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { sm8: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { outlined: "" },
                                model: {
                                  value: field.introduction,
                                  callback: function ($$v) {
                                    _vm.$set(field, "introduction", $$v)
                                  },
                                  expression: "field.introduction",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.design.page_mode == 2
                ? _c(
                    "v-col",
                    { staticClass: "mb-3", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("v-flex", { attrs: { sm4: "" } }, [
                            _c("label", [_vm._v("Information")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { sm8: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { outlined: "" },
                                model: {
                                  value: field.information,
                                  callback: function ($$v) {
                                    _vm.$set(field, "information", $$v)
                                  },
                                  expression: "field.information",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.design.page_mode == 2
                ? _c(
                    "v-col",
                    { staticClass: "mb-3", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("v-flex", { attrs: { sm4: "" } }, [
                            _c("label", [_vm._v("Warning")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { sm8: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { outlined: "" },
                                model: {
                                  value: field.warning,
                                  callback: function ($$v) {
                                    _vm.$set(field, "warning", $$v)
                                  },
                                  expression: "field.warning",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.design.page_mode == 2
                ? _c(
                    "v-col",
                    { staticClass: "mb-3", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("v-flex", { attrs: { sm4: "" } }, [
                            _c("label", [_vm._v("Post-Notice")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { sm8: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { outlined: "" },
                                model: {
                                  value: field.outroduction,
                                  callback: function ($$v) {
                                    _vm.$set(field, "outroduction", $$v)
                                  },
                                  expression: "field.outroduction",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "mb-3", attrs: { cols: "12" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "2" } }, [
                        _c("label", [_vm._v("Validation")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-container",
                            _vm._l(
                              field.validation,
                              function (validation, ruleIndex) {
                                return _c(
                                  "v-layout",
                                  { key: ruleIndex, attrs: { row: "" } },
                                  [
                                    _c("v-col", { attrs: { cols: "3" } }, [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: validation.ruleType,
                                              expression: "validation.ruleType",
                                            },
                                          ],
                                          staticClass: "full-width",
                                          attrs: { label: "Rule Type" },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                validation,
                                                "ruleType",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "" } },
                                            [_vm._v("Please Select Type")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "minLength" } },
                                            [_vm._v("Minimum Length")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "maxLength" } },
                                            [_vm._v("Maximum Length")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "minValue" } },
                                            [_vm._v("Minimum Value")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            {
                                              attrs: { value: "maximumValue" },
                                            },
                                            [_vm._v("Maximum Value")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "notEmpty" } },
                                            [_vm._v("Not Empty")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "regEx" } },
                                            [_vm._v("Regular Expression")]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Value (where applicable)",
                                          },
                                          model: {
                                            value: validation.value,
                                            callback: function ($$v) {
                                              _vm.$set(validation, "value", $$v)
                                            },
                                            expression: "validation.value",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "1" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeValidation(
                                                  fieldKey,
                                                  ruleIndex
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("- Remove")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addValidation(fieldKey)
                                },
                              },
                            },
                            [_vm._v("+ Add Rule")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              (field.fieldName && field.fieldName != "" && !field.isCustom) ||
              (field.models && field.models.length > 0) ||
              field.isAssociation ||
              field.isComposite
                ? _c(
                    "v-col",
                    { staticClass: "mb-3", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c("v-flex", { attrs: { sm4: "" } }, [
                            _c("label", [_vm._v("Aggregation")]),
                          ]),
                          _vm._v(" "),
                          _c("v-flex", { attrs: { sm8: "" } }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: field.aggregation,
                                    expression: "field.aggregation",
                                  },
                                ],
                                staticClass: "full-width",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      field,
                                      "aggregation",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("None - use Raw Value"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "AVG" } }, [
                                  _vm._v("Average"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "COUNT" } }, [
                                  _vm._v("Count"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "MAX" } }, [
                                  _vm._v("Max"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "MIN" } }, [
                                  _vm._v("Min"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "SUM" } }, [
                                  _vm._v("Sum"),
                                ]),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              field.displayType && field.displayType == "custom"
                ? _c(
                    "v-col",
                    { staticClass: "mb-3", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("v-flex", { attrs: { sm4: "" } }, [
                            _c("label", [_vm._v("Custom Component")]),
                          ]),
                          _vm._v(" "),
                          _c("v-flex", { attrs: { sm8: "" } }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: field.customComponent,
                                  expression: "field.customComponent",
                                },
                              ],
                              attrs: { type: "text" },
                              domProps: { value: field.customComponent },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    field,
                                    "customComponent",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              field.isComposite
                ? _c(
                    "v-flex",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "" } },
                            [
                              _c("v-flex", { attrs: { sm4: "" } }, [
                                _c("label", [_vm._v("Model A")]),
                              ]),
                              _vm._v(" "),
                              _c("v-flex", { attrs: { sm8: "" } }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: field.modelNameA,
                                        expression: "field.modelNameA",
                                      },
                                    ],
                                    staticClass: "full-width",
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            field,
                                            "modelNameA",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function ($event) {
                                          field.fieldNameA = ""
                                          _vm.selectModel(field.modelNameA)
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "" } }, [
                                      _vm._v("Please Select Model A"),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.sortModels(_vm.models),
                                      function (modelName) {
                                        return _c(
                                          "option",
                                          {
                                            key: modelName,
                                            domProps: { value: modelName },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(modelName) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                              _vm._v(" "),
                              _c("v-flex", { attrs: { sm4: "" } }, [
                                _c("label", [_vm._v(" Column A")]),
                              ]),
                              _vm._v(" "),
                              _c("v-flex", { attrs: { sm8: "" } }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: field.fieldNameA,
                                        expression: "field.fieldNameA",
                                      },
                                    ],
                                    staticClass: "full-width",
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            field,
                                            "fieldNameA",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function ($event) {
                                          field.displayType = "default"
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "" } }, [
                                      _vm._v("Please Select Column A"),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.sortColumns(field.modelNameA),
                                      function (columnFieldName) {
                                        return _c(
                                          "option",
                                          {
                                            domProps: {
                                              value: columnFieldName,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(columnFieldName) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "" } },
                            [
                              _c("v-flex", { attrs: { sm4: "" } }, [
                                _c("label", [_vm._v("Model B")]),
                              ]),
                              _vm._v(" "),
                              _c("v-flex", { attrs: { sm8: "" } }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: field.modelNameB,
                                        expression: "field.modelNameB",
                                      },
                                    ],
                                    staticClass: "full-width",
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            field,
                                            "modelNameB",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function ($event) {
                                          field.fieldNameB = ""
                                          _vm.selectModel(field.modelNameB)
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "" } }, [
                                      _vm._v("Please Select Model B"),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.sortModels(_vm.models),
                                      function (modelName) {
                                        return _c(
                                          "option",
                                          {
                                            key: modelName,
                                            domProps: { value: modelName },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(modelName) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                              _vm._v(" "),
                              _c("v-flex", { attrs: { sm4: "" } }, [
                                _c("label", [_vm._v(" Column B")]),
                              ]),
                              _vm._v(" "),
                              _c("v-flex", { attrs: { sm8: "" } }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: field.fieldNameB,
                                        expression: "field.fieldNameB",
                                      },
                                    ],
                                    staticClass: "full-width",
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            field,
                                            "fieldNameB",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function ($event) {
                                          field.displayType = "default"
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "" } }, [
                                      _vm._v("Please Select Column B"),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.sortColumns(field.modelNameB),
                                      function (columnFieldName) {
                                        return _c(
                                          "option",
                                          {
                                            domProps: {
                                              value: columnFieldName,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(columnFieldName) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showAdditionalRelationships(field)
                ? _c(
                    "v-flex",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("strong", [
                                  _vm._v(
                                    "Enter relationships between primary model and the model that you want to display in this field."
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._l(
                            field.fieldRelationships,
                            function (relation, index) {
                              return _c(
                                "v-layout",
                                { key: index, attrs: { row: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-container",
                                        {
                                          staticClass: "pt-0 pb-0",
                                          attrs: { fluid: "" },
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { row: "", wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { xs12: "", sm4: "" },
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items:
                                                        _vm.modelFieldNames,
                                                      label: "Model-field A",
                                                      "item-value": "value",
                                                      "single-line": "",
                                                      autocomplete: "",
                                                    },
                                                    model: {
                                                      value:
                                                        relation.modelFieldA,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          relation,
                                                          "modelFieldA",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "relation.modelFieldA",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { xs12: "", sm2: "" },
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.joinTypes,
                                                      label: "Join Type",
                                                      "item-value": "value",
                                                      "single-line": "",
                                                    },
                                                    model: {
                                                      value: relation.joinType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          relation,
                                                          "joinType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "relation.joinType",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { xs12: "", sm4: "" },
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items:
                                                        _vm.modelFieldNames,
                                                      label: "Model-field B",
                                                      "item-value": "value",
                                                      "single-line": "",
                                                      autocomplete: "",
                                                    },
                                                    model: {
                                                      value:
                                                        relation.modelFieldB,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          relation,
                                                          "modelFieldB",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "relation.modelFieldB",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                {
                                                  staticClass: "mt-2",
                                                  attrs: { xs12: "", sm1: "" },
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeRelationship(
                                                            fieldKey,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("delete"),
                                                      ]),
                                                      _vm._v(" Remove"),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addRelationship(fieldKey)
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("add")]),
                                  _vm._v(" Add Relationship"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.getFieldRelationshipsLength(
                                        fieldKey
                                      ) == 0,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeAllRelationships(
                                        fieldKey
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("cancel")]),
                                  _vm._v(" Remove Relationships"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mt-3 mb-3" }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }