var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          this.field.introduction
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _vm._v(_vm._s(this.field.introduction)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              this.field.warning
                ? _c(
                    "v-alert",
                    {
                      attrs: { type: "warning", dismissable: "", value: true },
                    },
                    [_vm._v(_vm._s(this.field.warning))]
                  )
                : _vm._e(),
              _vm._v(" "),
              this.field.error
                ? _c(
                    "v-alert",
                    { attrs: { type: "error", dismissable: "", value: true } },
                    [_vm._v(_vm._s(this.field.error))]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-col", { attrs: { col: "3" } }, [
            _c("label", { attrs: { for: "standard_occupation" } }, [
              _vm._v("Standard Occupancy: "),
            ]),
          ]),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "2" } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.viewValues.standard_occupancy,
                  expression: "viewValues.standard_occupancy",
                },
              ],
              staticStyle: {
                border: "1px solid grey",
                width: "100%",
                "box-sizing": "border-box",
                padding: "4px 6px",
              },
              attrs: {
                name: "standard_occupancy",
                type: "number",
                required: "",
              },
              domProps: { value: _vm.viewValues.standard_occupancy },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.viewValues,
                    "standard_occupancy",
                    $event.target.value
                  )
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("v-col", { staticClass: "pl-1", attrs: { cols: "8" } }, [
            _c("span", [_vm._v("Guests (in existing beds)")]),
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              this.field.information
                ? _c(
                    "v-alert",
                    { attrs: { type: "info", dismissable: "", value: true } },
                    [_vm._v(_vm._s(this.field.information))]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          this.field.schema.displayName != "none"
            ? _c("v-col", { attrs: { cols: "2" } }, [
                _c("label", { attrs: { for: this.field.fieldName } }, [
                  _vm._v(" " + _vm._s(this.field.schema.displayName) + ": "),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [
              _c(
                "v-radio-group",
                {
                  attrs: { row: "", autocomplete: "" },
                  model: {
                    value: _vm.viewValues.extra_accommodation,
                    callback: function ($$v) {
                      _vm.$set(_vm.viewValues, "extra_accommodation", $$v)
                    },
                    expression: "viewValues.extra_accommodation",
                  },
                },
                [
                  _c("v-radio", { attrs: { label: "Yes", value: 1 } }),
                  _vm._v(" "),
                  _c("v-radio", { attrs: { label: "No", value: 0 } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm.viewValues.extra_accommodation
                ? _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "2" } }, [
                            _c("label", { attrs: { for: "extra_beds" } }, [
                              _vm._v("Extra Bed Types: "),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "7" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  name: "extra_beds_description",
                                  disabled: !_vm.viewValues.extra_accommodation,
                                },
                                model: {
                                  value: _vm.viewValues.extra_beds_description,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.viewValues,
                                      "extra_beds_description",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "viewValues.extra_beds_description",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v("(eg. 1 rollaway bed OR 1 baby cot)"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-col", { attrs: { cols: "10" } }, [
                            _c("table", { staticClass: "extra-guests" }, [
                              _c("thead", [
                                _c("tr", [
                                  _c("th"),
                                  _vm._v(" "),
                                  _vm.ageRanges.baby
                                    ? _c("th", [
                                        _vm._v(
                                          "Baby: " +
                                            _vm._s(_vm.ageRanges.baby.start) +
                                            "-" +
                                            _vm._s(_vm.ageRanges.baby.end) +
                                            " yrs"
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.ageRanges.child
                                    ? _c("th", [
                                        _vm._v(
                                          "Child: " +
                                            _vm._s(_vm.ageRanges.child.start) +
                                            "-" +
                                            _vm._s(_vm.ageRanges.child.end) +
                                            " yrs"
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.ageRanges.oldchild
                                    ? _c("th", [
                                        _vm._v(
                                          "Older Child: " +
                                            _vm._s(
                                              _vm.ageRanges.oldchild.start
                                            ) +
                                            "-" +
                                            _vm._s(_vm.ageRanges.oldchild.end) +
                                            " yrs"
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.ageRanges.adult
                                    ? _c("th", [
                                        _vm._v(
                                          "Adult: " +
                                            _vm._s(_vm.ageRanges.adult.start) +
                                            "+ yrs"
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("th"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.viewValues.room_guests,
                                  function (guest, guestIndex) {
                                    return _c("tr", { key: guestIndex }, [
                                      _c("td", [
                                        _vm._v(
                                          "Extra Guest " +
                                            _vm._s(guestIndex + 1) +
                                            ":"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _vm.ageRanges.baby
                                        ? _c("td", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.viewValues.room_guests[
                                                      guestIndex
                                                    ].baby_cot,
                                                  expression:
                                                    "viewValues.room_guests[guestIndex].baby_cot",
                                                },
                                              ],
                                              attrs: {
                                                name:
                                                  "extra_baby_" + guestIndex,
                                                type: "checkbox",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.viewValues.room_guests[
                                                    guestIndex
                                                  ].baby_cot
                                                )
                                                  ? _vm._i(
                                                      _vm.viewValues
                                                        .room_guests[guestIndex]
                                                        .baby_cot,
                                                      null
                                                    ) > -1
                                                  : _vm.viewValues.room_guests[
                                                      guestIndex
                                                    ].baby_cot,
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$a =
                                                        _vm.viewValues
                                                          .room_guests[
                                                          guestIndex
                                                        ].baby_cot,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.viewValues
                                                              .room_guests[
                                                              guestIndex
                                                            ],
                                                            "baby_cot",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.viewValues
                                                              .room_guests[
                                                              guestIndex
                                                            ],
                                                            "baby_cot",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.viewValues
                                                          .room_guests[
                                                          guestIndex
                                                        ],
                                                        "baby_cot",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                  function ($event) {
                                                    return _vm.updateOccupancies()
                                                  },
                                                ],
                                              },
                                            }),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.ageRanges.child
                                        ? _c("td", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.viewValues.room_guests[
                                                      guestIndex
                                                    ].child_extra_bed,
                                                  expression:
                                                    "viewValues.room_guests[guestIndex].child_extra_bed",
                                                },
                                              ],
                                              attrs: {
                                                name:
                                                  "extra_child_" + guestIndex,
                                                type: "checkbox",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.viewValues.room_guests[
                                                    guestIndex
                                                  ].child_extra_bed
                                                )
                                                  ? _vm._i(
                                                      _vm.viewValues
                                                        .room_guests[guestIndex]
                                                        .child_extra_bed,
                                                      null
                                                    ) > -1
                                                  : _vm.viewValues.room_guests[
                                                      guestIndex
                                                    ].child_extra_bed,
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$a =
                                                        _vm.viewValues
                                                          .room_guests[
                                                          guestIndex
                                                        ].child_extra_bed,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.viewValues
                                                              .room_guests[
                                                              guestIndex
                                                            ],
                                                            "child_extra_bed",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.viewValues
                                                              .room_guests[
                                                              guestIndex
                                                            ],
                                                            "child_extra_bed",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.viewValues
                                                          .room_guests[
                                                          guestIndex
                                                        ],
                                                        "child_extra_bed",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                  function ($event) {
                                                    return _vm.updateOccupancies()
                                                  },
                                                ],
                                              },
                                            }),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.ageRanges.oldchild
                                        ? _c("td", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.viewValues.room_guests[
                                                      guestIndex
                                                    ].oldchild_extra_bed,
                                                  expression:
                                                    "viewValues.room_guests[guestIndex].oldchild_extra_bed",
                                                },
                                              ],
                                              attrs: {
                                                name:
                                                  "extra_oldchild_" +
                                                  guestIndex,
                                                type: "checkbox",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.viewValues.room_guests[
                                                    guestIndex
                                                  ].oldchild_extra_bed
                                                )
                                                  ? _vm._i(
                                                      _vm.viewValues
                                                        .room_guests[guestIndex]
                                                        .oldchild_extra_bed,
                                                      null
                                                    ) > -1
                                                  : _vm.viewValues.room_guests[
                                                      guestIndex
                                                    ].oldchild_extra_bed,
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$a =
                                                        _vm.viewValues
                                                          .room_guests[
                                                          guestIndex
                                                        ].oldchild_extra_bed,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.viewValues
                                                              .room_guests[
                                                              guestIndex
                                                            ],
                                                            "oldchild_extra_bed",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.viewValues
                                                              .room_guests[
                                                              guestIndex
                                                            ],
                                                            "oldchild_extra_bed",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.viewValues
                                                          .room_guests[
                                                          guestIndex
                                                        ],
                                                        "oldchild_extra_bed",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                  function ($event) {
                                                    return _vm.updateOccupancies()
                                                  },
                                                ],
                                              },
                                            }),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.ageRanges.adult
                                        ? _c("td", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.viewValues.room_guests[
                                                      guestIndex
                                                    ].adult_extra_bed,
                                                  expression:
                                                    "viewValues.room_guests[guestIndex].adult_extra_bed",
                                                },
                                              ],
                                              attrs: {
                                                name:
                                                  "extra_adult_" + guestIndex,
                                                type: "checkbox",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.viewValues.room_guests[
                                                    guestIndex
                                                  ].adult_extra_bed
                                                )
                                                  ? _vm._i(
                                                      _vm.viewValues
                                                        .room_guests[guestIndex]
                                                        .adult_extra_bed,
                                                      null
                                                    ) > -1
                                                  : _vm.viewValues.room_guests[
                                                      guestIndex
                                                    ].adult_extra_bed,
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$a =
                                                        _vm.viewValues
                                                          .room_guests[
                                                          guestIndex
                                                        ].adult_extra_bed,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.viewValues
                                                              .room_guests[
                                                              guestIndex
                                                            ],
                                                            "adult_extra_bed",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.viewValues
                                                              .room_guests[
                                                              guestIndex
                                                            ],
                                                            "adult_extra_bed",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.viewValues
                                                          .room_guests[
                                                          guestIndex
                                                        ],
                                                        "adult_extra_bed",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                  function ($event) {
                                                    return _vm.updateOccupancies()
                                                  },
                                                ],
                                              },
                                            }),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteGuest(
                                                    guestIndex
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Delete")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addGuest()
                                    },
                                  },
                                },
                                [_vm._v("+ add more Extra Guest Options")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "3" } }, [
            _c("label", { attrs: { for: "extra_beds" } }, [
              _vm._v("Maximum Occupancy: "),
            ]),
          ]),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "4" } }, [
            _c("span", [
              _vm._v("Adults: " + _vm._s(_vm.viewValues.max_occ_adults)),
              _c("br"),
            ]),
            _vm._v(" "),
            _vm.ageRanges.oldchild
              ? _c("span", [
                  _vm._v(
                    "Older Children: " +
                      _vm._s(_vm.viewValues.max_occ_older_children)
                  ),
                  _c("br"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.ageRanges.child
              ? _c("span", [
                  _vm._v(
                    "Children: " + _vm._s(_vm.viewValues.max_occ_children)
                  ),
                  _c("br"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.ageRanges.baby
              ? _c("span", [
                  _vm._v("Babies: " + _vm._s(_vm.viewValues.max_occ_babies)),
                  _c("br"),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: this.field.fieldName },
            domProps: { value: _vm.field.value },
          }),
          _vm._v(" "),
          _vm.errorMessages && _vm.errorMessages.length
            ? _c(
                "v-alert",
                { attrs: { type: "error", dismissable: "", value: true } },
                _vm._l(_vm.errorMessages, function (error, index) {
                  return _c("div", { key: index }, [
                    _vm._v(_vm._s(error.reason) + "*"),
                  ])
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }