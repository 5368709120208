var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          this.field.schema.displayName != "none"
            ? _c("v-col", { attrs: { cols: "2" } }, [
                _c("label", { attrs: { for: this.field.fieldName } }, [
                  _vm._v(" " + _vm._s(this.field.schema.displayName) + ":"),
                  this.field.required ? _c("span", [_vm._v("*")]) : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "1" } }, [
            this.field.validation && this.field.validation.length
              ? _c(
                  "span",
                  { on: { click: _vm.toggleValidationGuide } },
                  [_c("v-icon", [_vm._v("information_outline")])],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.shouldShowPicker,
                      expression: "!shouldShowPicker",
                    },
                  ],
                  on: { click: _vm.chooseHotel },
                },
                [_vm._v(_vm._s(_vm.buttonLabel))]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selectedHotel,
                      expression: "selectedHotel",
                    },
                  ],
                  on: { click: _vm.clearHotel },
                },
                [_vm._v("Clear")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              this.showValidationGuide &&
              this.field.validation &&
              this.field.validation.length
                ? _c(
                    "v-alert",
                    { attrs: { type: "info" } },
                    _vm._l(this.field.validation, function (rule, ruleIndex) {
                      return _c("div", { key: ruleIndex }, [
                        _vm._v(_vm._s(_vm.validationRuleAsString(rule))),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.shouldShowPicker
            ? _c(
                "v-col",
                { attrs: { cols: "10" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "10" } },
                            [
                              _c("v-text-field", {
                                attrs: { required: this.field.required },
                                on: {
                                  keyup: function ($event) {
                                    return _vm.updateHotelName()
                                  },
                                },
                                model: {
                                  value: _vm.hotelViewName,
                                  callback: function ($$v) {
                                    _vm.hotelViewName = $$v
                                  },
                                  expression: "hotelViewName",
                                },
                              }),
                              _vm._v(" "),
                              _vm.hotelOptions.length && _vm.shouldShowHotels
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "selectable-hotels-results",
                                    },
                                    [
                                      _c(
                                        "ul",
                                        {
                                          staticClass: "selectable-hotels-list",
                                        },
                                        _vm._l(
                                          _vm.hotelOptions,
                                          function (hotel) {
                                            return _c(
                                              "li",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.selectHotel(
                                                      hotel.hotel_key
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "(" +
                                                    _vm._s(hotel.hotel_code) +
                                                    ") " +
                                                    _vm._s(hotel.hotel_name)
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: _vm.field.fieldName },
            domProps: { value: _vm.field.value },
          }),
          _vm._v(" "),
          _vm.errorMessages && _vm.errorMessages.length
            ? _c(
                "v-alert",
                { attrs: { type: "error", dismissable: "", value: "true" } },
                _vm._l(_vm.errorMessages, function (error, index) {
                  return _c("div", { key: index }, [
                    _vm._v(_vm._s(error.reason) + "*"),
                  ])
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }