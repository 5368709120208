var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { xs: "12", md: "6", lg: "4", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("card_giftcard")]),
              _vm._v(" Voucher Summary Statistics\n        "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("line-chart", {
                attrs: { chartdata: _vm.chartData, options: _vm.chartOptions },
              }),
              _vm._v(" "),
              _c("v-flex", { attrs: { x12: "" } }, [
                _vm._v(
                  "\n                Vouchers Purchased: " +
                    _vm._s(_vm.chartData.count_purchased) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("v-flex", { attrs: { x12: "" } }, [
                _vm._v(
                  "\n                Vouchers Redeemed: " +
                    _vm._s(_vm.chartData.count_redeemed) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("v-flex", { attrs: { x12: "" } }, [
                _vm._v(
                  "\n                Vouchers Expired: " +
                    _vm._s(_vm.chartData.count_expired) +
                    "\n            "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }