<template>
    <div class="field-container">

    <v-container fluid>
        <v-layout column>

            <!-- Prepend HTML -->
            <v-flex v-if="this.field.prependHtml != ''">
                {{ this.field.prependHtml }}
            </v-flex>

            <!-- Not editable -->
            <v-flex v-if="this.field.displayType == 'notEditable'">
                <ies-input-disabled :field="this.field"></ies-input-disabled>
            </v-flex>

            <!-- Hidden -->
            <v-flex v-if="this.field.displayType == 'hidden'">
                <ies-text-hidden :field="this.field"></ies-text-hidden>
            </v-flex>

            <!-- Hidden Input -->
            <v-flex v-if="this.field.displayType == 'hiddeninput'">
                <ies-input-hidden :field="this.field"></ies-input-hidden>
            </v-flex>

            <!-- Text box -->
            <v-flex  v-if="this.field.displayType == 'text'" >
               <ies-input-text :field="this.field"></ies-input-text>
            </v-flex>

            <!-- Standard html input -->
            <v-flex  v-if="this.field.displayType == 'htmlinput'" >
                <ies-input-htmlinput :field="this.field"></ies-input-htmlinput>
            </v-flex>

            <!-- Text area -->
            <v-flex v-if="this.field.displayType == 'textarea'" >
                <ies-input-textarea :field="this.field"></ies-input-textarea>
            </v-flex>

            <!-- Email -->
            <v-flex v-if="this.field.displayType == 'email'" >
                <ies-input-email :field="this.field"></ies-input-email>
            </v-flex>

            <!-- Integer -->
            <v-flex v-if="this.field.displayType == 'integer'" >
                <ies-input-integer :field="this.field"></ies-input-integer>
            </v-flex>

            <!-- Hotel Facility Display Group -->
            <v-flex v-if="this.field.displayType == 'displaygroup'" >
                <ies-input-displaygroup :field="this.field"></ies-input-displaygroup>
            </v-flex>

            <!-- Hotel Facility Category (e.g. in room, dining, activity etc -->
            <v-flex v-if="this.field.displayType == 'facility_categories'" >
                <ies-input-facilitycategories :field="this.field"></ies-input-facilitycategories>
            </v-flex>

            <!-- Facility ID -->
            <v-flex v-if="this.field.displayType == 'facilitykey'" >
                <ies-input-facilitykey :field="this.field"></ies-input-facilitykey>
            </v-flex>

            <!-- Hotel ID -->
            <v-flex v-if="this.field.displayType == 'hotelkey'" >
                <ies-input-hotelkey :field="this.field"></ies-input-hotelkey>
            </v-flex>

            <!-- Hotel Room ID -->
            <v-flex v-if="this.field.displayType == 'hotelRoomKey'" >
                <ies-input-hotelroomtypekey :field="this.field"></ies-input-hotelroomtypekey>
            </v-flex>

            <!-- Tagging Keyword -->
            <v-flex v-if="this.field.displayType == 'keywordKey'" >
                <ies-input-keyword :field="this.field"></ies-input-keyword>
            </v-flex>

            <!-- Hotel Rate Type ID -->
            <v-flex v-if="this.field.displayType == 'rateTypeKey'" >
                <ies-input-ratetypekey :field="this.field"></ies-input-ratetypekey>
            </v-flex>

            <!-- Region ID -->
            <v-flex v-if="this.field.displayType == 'regionkey'" >
                <ies-input-regionkey :field="this.field"></ies-input-regionkey>
            </v-flex>
            <v-flex v-if="this.field.displayType == 'regionHierarchy'" >
                <ies-input-regionhierarchy :field="this.field"></ies-input-regionhierarchy>
            </v-flex>

            <!-- Channel Manager ID -->
            <v-flex v-if="this.field.displayType == 'channelManagerkey'" >
                <ies-input-channelmanagerkey :field="this.field"></ies-input-channelmanagerkey>
            </v-flex>

            <!-- Channel Manager Configuration -->
            <v-flex v-if="this.field.displayType == 'channelManagerConfig'" >
                <ies-input-channelmanagerconfig :field="this.field"></ies-input-channelmanagerconfig>
            </v-flex>

            <!-- Channel Manager Hotel Mapping Configuration -->
            <v-flex v-if="this.field.displayType == 'channelManagerHotelConfig'" >
                <ies-input-channelmanagerhotelconfig :field="this.field"></ies-input-channelmanagerhotelconfig>
            </v-flex>

            <!-- Status Code -->
            <v-flex v-if="this.field.displayType == 'statuskey'" >
                <ies-input-statuskey :field="this.field"></ies-input-statuskey>
            </v-flex>

            <!-- Message Class -->
            <v-flex v-if="this.field.displayType == 'messageclass'" >
                <ies-input-messageclass :field="this.field"></ies-input-messageclass>
            </v-flex>
            <v-flex v-if="this.field.displayType == 'messageTemplate'" >
                <ies-input-message-template :field="this.field"></ies-input-message-template>
            </v-flex>

            <!-- Sender Type -->
            <v-flex v-if="this.field.displayType == 'sender'" >
                <ies-input-sendertype :field="this.field"></ies-input-sendertype>
            </v-flex>
			
			<!-- Currency Key -->
            <v-flex v-if="this.field.displayType == 'currencyKey'" >
                <ies-input-currency-key :field="this.field"></ies-input-currency-key>
            </v-flex>

            <!-- Airport Transfer Type -->
            <v-flex v-if="this.field.displayType == 'transferType'" >
                <ies-input-airport-transfer-type :field="this.field"></ies-input-airport-transfer-type>
            </v-flex>

			<!-- User Association Type -->
            <v-flex v-if="this.field.displayType == 'userAssociationType'" >
                <ies-input-user-association-type :field="this.field"></ies-input-user-association-type>
            </v-flex>

			<!-- Billing Type -->
			<v-flex v-if="this.field.displayType == 'billingType'">
				<ies-input-billing-type :field="this.field"></ies-input-billing-type>
			</v-flex>
			
			<!-- Booking System -->
			<v-flex v-if="this.field.displayType == 'bookingSystem'">
				<ies-input-booking-system :field="this.field" :recordData="this.recordData"></ies-input-booking-system>
			</v-flex>

			<!-- Deposit Required -->
			<v-flex v-if="this.field.displayType == 'depositRequired'">
				<ies-input-deposit-required :field="this.field" :recordData="this.recordData"></ies-input-deposit-required>
			</v-flex>


			<!-- Car Hire Type -->
			<v-flex v-if="this.field.displayType == 'carHireType'">
				<ies-input-car-hire-type :field="this.field"></ies-input-car-hire-type>
			</v-flex>
			
			<!-- Channel Manager Region -->
			<v-flex v-if="this.field.displayType == 'channelManagerRegion'">
				<ies-input-channel-manager-region :field="this.field"></ies-input-channel-manager-region>
			</v-flex>
			
			<!-- Google Map Settings -->
			<v-flex v-if="this.field.displayType == 'googleMapSettings'">
				<ies-input-google-map-settings :field="this.field"></ies-input-google-map-settings>
			</v-flex>

			<!-- Location Type -->
            <v-flex v-if="this.field.displayType == 'locationType'" >
                <ies-input-location-type :field="this.field"></ies-input-location-type>
            </v-flex>

			<!-- General Child Policy -->
            <v-flex v-if="this.field.displayType == 'childPolicy'" >
                <ies-input-child-policy :field="this.field" :recordData="this.recordData"></ies-input-child-policy>
            </v-flex>

			<!-- Age Ranges -->
            <v-flex v-if="this.field.displayType == 'ageRanges'" >
                <ies-input-age-ranges :field="this.field" :recordData="this.recordData"></ies-input-age-ranges>
            </v-flex>

			<!-- Best For Kids -->
            <v-flex v-if="this.field.displayType == 'bestForKids'" >
                <ies-input-best-for-kids :field="this.field" :recordData="this.recordData"></ies-input-best-for-kids>
            </v-flex>

			<!-- Price Ranges -->
            <v-flex v-if="this.field.displayType == 'priceRanges'" >
                <ies-input-price-ranges :field="this.field" :recordData="this.recordData"></ies-input-price-ranges>
            </v-flex>

			<!-- Preset True -->
            <v-flex v-if="this.field.displayType == 'presetTrue'" >
                <ies-input-preset-true :field="this.field" :recordData="this.recordData"></ies-input-preset-true>
            </v-flex>

			<!-- Group Sizes -->
            <v-flex v-if="this.field.displayType == 'groupSizes'" >
                <ies-input-group-size :field="this.field" :recordData="this.recordData"></ies-input-group-size>
            </v-flex>

			<!-- Display Status Type -->
            <v-flex v-if="this.field.displayType == 'displayStatusType'" >
                <ies-input-display-status-type :field="this.field"></ies-input-display-status-type>
            </v-flex>

			<!-- Hotel Reviewers -->
			<v-flex v-if="this.field.displayType == 'hotelReviewers'" >
                <ies-input-hotel-reviewers :field="this.field"></ies-input-hotel-reviewers>
            </v-flex>
			
			<!-- Travel Operator Key -->
			<v-flex v-if="this.field.displayType == 'travelOperatorKey'" >
                <ies-input-travel-operator-key :field="this.field"></ies-input-travel-operator-key>
            </v-flex>

			<!-- Extra Beds -->
			<v-flex v-if="this.field.displayType == 'extraBeds'" >
                <ies-input-extra-beds :field="this.field" :recordData="this.recordData"></ies-input-extra-beds>
            </v-flex>

			<!-- Rate Basis -->
			<v-flex v-if="this.field.displayType == 'rateBasis'" >
                <ies-input-rate-basis :field="this.field"></ies-input-rate-basis>
            </v-flex>

			<!-- Ratings -->
			 <v-flex v-if="this.field.displayType == 'ratings'" >
                <ies-input-ratings :field="this.field"></ies-input-ratings>
            </v-flex>
		
			<!-- Visit Type -->
			<v-flex v-if="this.field.displayType == 'setVisitType'" >
                <ies-input-visit-type :field="this.field"></ies-input-visit-type>
            </v-flex>
            
            <v-flex v-if="this.field.displayType == 'sendStatus'" >
                <ies-input-send-status :field="this.field"></ies-input-send-status>
            </v-flex>

            <!-- Voucher Type -->
            <v-flex v-if="this.field.displayType == 'voucherType'" >
                <ies-input-vouchertype :field="this.field"></ies-input-vouchertype>
            </v-flex>

            <!-- Voucher Source Page -->
            <v-flex v-if="this.field.displayType == 'voucherSourcePage'" >
                <ies-input-vouchersourcepage :field="this.field"></ies-input-vouchersourcepage>
            </v-flex>

            <!-- Send Digital Voucher -->
            <v-flex v-if="this.field.displayType == 'sendVoucher'" >
                <ies-input-sendvoucher :field="this.field"></ies-input-sendvoucher>
            </v-flex>

            <v-flex v-if="this.field.displayType == 'voucherCreationPurpose'" >
                <ies-input-vouchercreationpurpose :field="this.field"></ies-input-vouchercreationpurpose>
            </v-flex>

            <!-- Payment Request Status -->
            <v-flex v-if="this.field.displayType == 'paymentRequestStatus'" >
                <ies-input-paymentrequeststatus :field="this.field"></ies-input-paymentrequeststatus>
            </v-flex>

            <v-flex v-if="this.field.displayType == 'PaymentRequestReason'" >
                <ies-input-payment-request-reason :field="this.field" :recordData="this.recordData"></ies-input-payment-request-reason>
            </v-flex>

            <!-- Payment service provider -->
            <v-flex v-if="this.field.displayType == 'paymentProviderKey'" >
                <ies-input-paymentproviderkey :field="this.field"></ies-input-paymentproviderkey>
            </v-flex>

            <!-- VAN service provider -->
            <v-flex v-if="this.field.displayType == 'vanProviderKey'" >
                <ies-input-vanproviderkey :field="this.field"></ies-input-vanproviderkey>
            </v-flex>

            <!-- Payment orchestration action -->
            <v-flex v-if="this.field.displayType == 'paymentAction'" >
                <ies-input-paymentaction :field="this.field"></ies-input-paymentaction>
            </v-flex>

            <!-- Dashboard -->
            <v-flex v-if="this.field.displayType == 'dashboardKey'" >
                <ies-input-dashboardkey :field="this.field"></ies-input-dashboardkey>
            </v-flex>

            <!-- Dashboard Panel -->
            <v-flex v-if="this.field.displayType == 'dashboardPanelKey'" >
                <ies-input-dashboardpanelkey :field="this.field"></ies-input-dashboardpanelkey>
            </v-flex>

            <v-flex v-if="this.field.displayType == 'adminUserKey'" >
                <ies-input-adminuserkey :field="this.field"></ies-input-adminuserkey>
            </v-flex>

            <v-flex v-if="this.field.displayType == 'adminDepartmentKey'" >
                <ies-input-admindepartmentkey :field="this.field"></ies-input-admindepartmentkey>
            </v-flex>
            <v-flex v-if="this.field.displayType == 'adminRoleKey'" >
                <ies-input-adminrolekey :field="this.field"></ies-input-adminrolekey>
            </v-flex>

            <!-- Quick Menu Link -->
            <v-flex v-if="this.field.displayType == 'quickMenuLink'" >
                <ies-input-quickmenulink :field="this.field"></ies-input-quickmenulink>
            </v-flex>

            <!-- Portal Content Type -->
            <v-flex v-if="this.field.displayType == 'portalContentTypeKey'" >
                <ies-input-portalcontenttypekey :field="this.field"></ies-input-portalcontenttypekey>
            </v-flex>

            <!-- Photo -->
            <v-flex v-if="this.field.displayType == 'image'" >
                <ies-input-image :field="this.field"></ies-input-image>
            </v-flex>

            <!-- Check box -->
            <v-flex v-if="this.field.displayType == 'checkbox'" >
                <ies-input-checkbox :field="this.field"></ies-input-checkbox>
            </v-flex>

            <!-- Switch -->
            <v-flex v-if="this.field.displayType == 'switch' || this.field.displayType == 'switch_green' || this.field.displayType == 'switch_blue' || this.field.displayType == 'switch_red'" >
                <ies-input-switch :field="this.field" :color="this.field.displayType" label="true"></ies-input-switch>
            </v-flex>

            <!-- Date -->
            <v-flex v-if="this.field.displayType == 'date'" >
                <ies-input-date :field="this.field"></ies-input-date>
            </v-flex>

            <!-- Time -->
            <v-flex v-if="this.field.displayType == 'time'" >
                <ies-input-time :field="this.field"></ies-input-time>
            </v-flex>

            <!-- Datetime -->
            <v-flex v-if="this.field.displayType == 'datetime'" >
                <ies-input-datetime :field="this.field"></ies-input-datetime>
            </v-flex>

            <!-- Select box -->
            <v-flex v-if="this.field.displayType == 'select'">
                <ies-input-select :field="this.field"></ies-input-select>
            </v-flex>

            <!-- Day of Week (bitwise) -->
            <v-flex v-if="this.field.displayType == 'dayOfWeek'">
                <ies-input-weekday :field="this.field"></ies-input-weekday>
            </v-flex>

            <v-flex v-if="this.field.displayType == 'iCalFeedType'">
                <ies-ical-feed-type :field="this.field"></ies-ical-feed-type>
            </v-flex>

            <v-flex v-if="this.field.displayType == 'newsletter_subscriptions'">
                <ies-newsletter-subscriptions :field="this.field"></ies-newsletter-subscriptions>
            </v-flex>

            <!-- radio button -->

            <!-- Map -->

            <!-- Password  -->
            <v-flex v-if="this.field.displayType == 'password'" >
                <ies-input-password :field="this.field"></ies-input-password>
            </v-flex>


            <v-flex v-if="this.field.displayType == 'UserAssociations'" >
                <ies-input-user-associations :field="this.field"></ies-input-user-associations>
            </v-flex>

			<v-flex v-if="this.field.displayType == 'telephones'" >
                <ies-input-telephone-numbers :field="this.field" :recordData="this.recordData"></ies-input-telephone-numbers>
            </v-flex>


            <!-- Custom -->
            <v-flex v-if="this.field.displayType == 'custom'" >

            
                <!-- Blog Regions -->
                <v-flex v-if="this.field.customComponent == 'blogRegions'" >
                    <ies-input-blogregions :field="this.field" :recordData="this.recordData"></ies-input-blogregions>
                </v-flex>

                <!-- Exclude any component name if there is a discrepancy between the name of the component and what is actually registered with VueJS, until we can get them to match again -->
<!--
                <component  :is="this.field.customComponent" :field="this.field" :recordData="this.recordData" ></component>
-->
<!--
.{{ this.field.customComponent }}.
                <v-flex v-if="this.field.customComponent == 'PaymentRequestReason'">***
                    <ies-input-payment-request-reason :field="this.field" :recordData="this.recordData"></ies-input-payment-request-reason>
                </v-flex>
-->
            </v-flex>


            <!-- Append HTML -->
            <v-flex v-if="this.field.appendHtml != ''">
                {{ this.field.appendHtml }}
            </v-flex>

            <!-- TinyMCE editor -->
            <v-flex v-if="this.field.displayType == 'tinymce'">
                <ies-input-tinymce :field="this.field"></ies-input-tinymce>
            </v-flex>

            <!-- Mime Type -->
            <v-flex v-if="this.field.displayType == 'mimeType'">
                <ies-input-mime-type :field="this.field"></ies-input-mime-type>
            </v-flex>

        </v-layout>
        <div v-if="field.outroduction">{{ field.outroduction }}</div>
    </v-container>
    </div>
</template>
<script>

var disabledInput = require("./Disabled.vue").default;
var textInput     = require("./Text.vue").default;
var textareaInput = require("./Textarea.vue").default;
var integerInput  = require("./Integer.vue").default;
var imageInput    = require("./Image.vue").default;
var checkboxInput = require("./Checkbox.vue").default;
var emailInput    = require("./Email.vue").default;
var facilityKeyInput = require("./FacilityKey.vue").default;
var displayGroupInput = require("./HotelFacilityDisplayGroup.vue").default;
var hotelKeyInput = require("./HotelKey.vue").default;
var portalContentTypeKeyInput = require("./PortalContentTypeKey.vue").default;
var regionKeyInput = require("./RegionKey.vue").default;
var channelManagerKeyInput = require("./ChannelManagerKey.vue").default;
var channelManagerConfigInput = require("./ChannelManagerConfig.vue").default;
var channelManagerHotelConfigInput = require("./ChannelManagerHotelConfig.vue").default;
var statusKeyInput = require("./StatusKey.vue").default;
var dayOfWeekInput = require("./DayOfWeek.vue").default;
var iCalFeedTypeInput = require("./ICalFeedType.vue").default;
var messageClassInput = require("./MessageClass.vue").default;
var paymentActionInput = require("./PaymentAction.vue").default;
var paymentProviderInput = require("./PaymentProviderKey.vue").default;
var vanProviderInput = require("./VanProviderKey.vue").default;
var messagingInput = require("./MessageTemplate.vue").default;
var sendStatusInput = require("./SendStatus.vue").default;
var senderTypeInput = require("./SenderType.vue").default;
var currencyKey = require("./CurrencyKey.vue").default;
var airportTransferTypeInput = require("./AirportTransferType.vue").default;
var userAssociationType = require("./UserAssociationType.vue").default;
var telephoneNumbers = require("./TelephoneNumbers.vue").default;
var billingType = require("./BillingType.vue").default;
var bookingSystem = require("./BookingSystem.vue").default;
var depositRequired = require("./DepositRequired.vue").default;
var carHireTypeInput = require("./CarHireType.vue").default;
var channelManagerRegion = require("./ChannelManagerRegion.vue").default;
var googleMapSettings = require("./GoogleMap.vue").default;
var hotelReviewers = require("./HotelReviewers.vue").default;
var locationType = require("./LocationType.vue").default;
var childPolicy = require("./ChildPolicy.vue").default;
var ageRanges = require("./AgeRanges.vue").default;
var bestForKids = require("./BestForKids.vue").default;
var priceRanges = require("./PriceRange.vue").default;
var presetTrue = require("./PresetTrue.vue").default;
var groupSizes = require("./GroupSize.vue").default;
var displayStatusType = require("./DisplayStatusType.vue").default;
var travelOperatorKey = require("./TravelOperatorKey.vue").default;
var extraBeds = require("./ExtraBeds.vue").default;
var rateBasis = require("./RateBasisPer.vue").default;
var ratings = require("./Ratings.vue").default;
var setVisitType = require("./SetVisitType.vue").default;
var sendStatusInput = require("./SendStatus.vue").default;
var voucherTypeInput = require("./VoucherType.vue").default;
var sendVoucherInput = require("./ResendVoucher.vue").default;
var voucherPurposeInput = require("./VoucherCreationPurpose.vue").default;
var voucherSourcePageInput = require("./VoucherSourcePage.vue").default;
var paymentRequestStatusInput = require("./PaymentRequestStatus.vue").default;
var paymentRequestReasonInput = require("./PaymentRequestReason.vue").default;
var dashboardKeyInput = require("./DashboardKey.vue").default;
var dashboardPanelKeyInput = require("./DashboardPanelKey.vue").default;
var blogRegionsInput = require("./BlogRegions.vue").default;
var quickMenuLinkInput = require("./QuickMenuLink.vue").default;
var adminUserInput = require("./AdminUserKey.vue").default;
var adminDepartmentInput = require("./AdminDepartmentKey.vue").default;
var adminRoleInput = require("./AdminRoleKey.vue").default;
var dateInput     = require("./Date.vue").default;
var timeInput     = require("./Time.vue").default;
var datetimeInput = require("./Datetime.vue").default;
var passwordInput = require("./Password.vue").default;
var hiddenText    = require("./Hidden.vue").default;
var hiddenInput   = require("./HiddenInput.vue").default;
var tinymceInput  = require("./TinyMCE.vue").default;
var switchInput   = require("./Switch.vue").default;
var select        = require("./Select.vue").default;
var htmlinput     = require("./HTMLinput.vue").default;
var facilityCategoriesInput = require("./FacilityCategories.vue").default;
var hotelRoomTypeKeyInput = require("./HotelRoomTypeKey.vue").default;
var rateTypeKeyInput = require("./RateTypeKey.vue").default;
var portalContentTypeKeyInput = require("./PortalContentTypeKey.vue").default;
var regionHierarchyInput = require("./RegionHierarchy.vue").default;
var statusKeyInput = require("./StatusKey.vue").default;
var newsletterSubscriptionsInput = require("./NewsletterSubscriptions.vue").default;
var keywordKeyInput = require("./KeywordKey.vue").default;
var userAssocInput = require("./UserAssociations.vue").default;
var mimeTypeInput = require("./MimeType.vue").default;


export default {
    name: 'ies-input',
    props: ['field', 'recordData'],
    data () {
        return {
        }
    },
    components: {
        'ies-input-disabled' : disabledInput,
        'ies-input-text'     : textInput,
        'ies-input-textarea' : textareaInput,
        'ies-input-integer'  : integerInput,
        'ies-input-image'    : imageInput,
        'ies-input-checkbox' : checkboxInput,
        'ies-input-email'    : emailInput,
        'ies-input-date'     : dateInput,
        'ies-input-time'     : timeInput,
        'ies-input-datetime' : datetimeInput,
        'ies-input-password' : passwordInput,
        'ies-text-hidden'    : hiddenText,
        'ies-input-hidden'   : hiddenInput,
        'ies-input-tinymce'  : tinymceInput,
        'ies-input-switch'   : switchInput,
        'ies-input-select'   : select,
        'ies-input-htmlinput': htmlinput,
        'ies-input-displaygroup' : displayGroupInput,
        'ies-input-facilitycategories' : facilityCategoriesInput,
        'ies-input-facilitykey' : facilityKeyInput,
        'ies-input-hotelkey' : hotelKeyInput,
        'ies-input-hotelroomtypekey' : hotelRoomTypeKeyInput,
        'ies-input-keyword' : keywordKeyInput,
        'ies-input-ratetypekey' : rateTypeKeyInput,
        'ies-input-regionkey': regionKeyInput,
        'ies-input-regionhierarchy': regionHierarchyInput,
        'ies-input-channelmanagerkey': channelManagerKeyInput,
        'ies-input-channelmanagerconfig': channelManagerConfigInput,
        'ies-input-channelmanagerhotelconfig': channelManagerHotelConfigInput,
        'ies-input-statuskey': statusKeyInput,
        'ies-input-weekday': dayOfWeekInput,
        'ies-ical-feed-type': iCalFeedTypeInput,
        'ies-input-messageclass': messageClassInput,
        'ies-input-adminuserkey': adminUserInput,
        'ies-input-admindepartmentkey': adminDepartmentInput,
        'ies-input-adminrolekey': adminRoleInput,
        'ies-input-paymentaction': paymentActionInput,
        'ies-input-paymentproviderkey': paymentProviderInput,
        'ies-input-vanproviderkey': vanProviderInput,
        'ies-input-sendertype': senderTypeInput,
		'ies-input-currency-key': currencyKey,
        'ies-input-message-template': messagingInput,
        'ies-input-send-status': sendStatusInput,
		'ies-input-airport-transfer-type': airportTransferTypeInput,
		'ies-input-user-association-type': userAssociationType,
		'ies-input-telephone-numbers': telephoneNumbers,
		'ies-input-billing-type': billingType,
		'ies-input-booking-system': bookingSystem,
		'ies-input-deposit-required': depositRequired,
		'ies-input-channel-manager-region': channelManagerRegion,
		'ies-input-car-hire-type': carHireTypeInput,
		'ies-input-google-map-settings': googleMapSettings,
		'ies-input-hotel-reviewers': hotelReviewers,
		'ies-input-ratings': ratings, 
		'ies-input-visit-type': setVisitType, 
		'ies-input-location-type': locationType,
		'ies-input-child-policy': childPolicy,
		'ies-input-age-ranges': ageRanges,
		'ies-input-best-for-kids': bestForKids,
		'ies-input-price-ranges': priceRanges,
		'ies-input-preset-true': presetTrue,
		'ies-input-group-size': groupSizes,
		'ies-input-display-status-type': displayStatusType,
		'ies-input-travel-operator-key': travelOperatorKey,
		'ies-input-extra-beds': extraBeds,
		'ies-input-rate-basis': rateBasis,
        'ies-input-message-template': messagingInput,
        'ies-input-send-status': sendStatusInput,
        'ies-input-vouchertype': voucherTypeInput,
        'ies-input-sendvoucher': sendVoucherInput,
        'ies-input-vouchersourcepage': voucherSourcePageInput,
        'ies-input-vouchercreationpurpose': voucherPurposeInput,
        'ies-input-paymentrequeststatus': paymentRequestStatusInput,
        'ies-input-payment-request-reason': paymentRequestReasonInput,
        'ies-input-dashboardkey': dashboardKeyInput,
        'ies-input-dashboardpanelkey': dashboardPanelKeyInput,
        'ies-input-blogregions': blogRegionsInput,
        'ies-input-quickmenulink': quickMenuLinkInput,
        'ies-input-portalcontenttypekey': portalContentTypeKeyInput,
        'ies-newsletter-subscriptions': newsletterSubscriptionsInput,
        'ies-input-user-associations': userAssocInput,
        'ies-input-mime-type': mimeTypeInput,
    },
    created: function(){
//        this.getCustomComponent()
    },
    methods: {
/*
        getCustomComponent: function(){
            
            if(this.field.displayType == "custom"){
                var component = require("/" + this.field.customComponent+".vue");
                Vue.component(this.field.customComponent, component);
            }
            
            console.log("Adding custom component: ", this.field.customComponent+".vue");
        }
*/
    }
}
</script>


