var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return this.isReady
    ? _c(
        "v-card",
        { staticClass: "mt-3 mb-3", attrs: { xs12: "", flat: "" } },
        [
          this.isBroken
            ? _c("v-flex", [_vm._v("Sorry, this content is not working")])
            : _vm._e(),
          _vm._v(" "),
          this.isReady && !this.isBroken
            ? _c(
                "v-flex",
                [
                  _c("ies-searchlist", {
                    attrs: { datasource: "pageData0", fields: _vm.fields },
                  }),
                  _vm._v(" "),
                  _c("ies-list-options", {
                    attrs: { datasource: "pageData0" },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      !this.data.length
                        ? _c("v-col", { attrs: { cols: "12" } }, [
                            _vm._v("Sorry, there are no results to show you"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          attrs: { cols: "12", v: "", if: "this.data.length" },
                        },
                        [
                          _c("ies-list-stack", {
                            attrs: {
                              datasource: "pageData0",
                              page: this.pageRecord,
                              fields: this.fields,
                              content: this.data,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          attrs: { cols: "12", v: "", if: "this.data.length" },
                        },
                        [
                          _c("ies-list-table", {
                            attrs: {
                              datasource: "pageData0",
                              page: this.pageRecord,
                              fields: this.fields,
                              content: this.data,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("ies-list-options", {
                    attrs: { datasource: "pageData0" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }