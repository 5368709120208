<template>
    <v-col cols="12" md="3" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">reviews</v-icon> Review
            </v-card-title>

            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col>
                            <v-btn class="mt-1" block :href="overviewUrl">Overview</v-btn>
                            <v-btn class="mt-1" block :href="detailedReviewsUrl">Detailed Review</v-btn>
                            <v-btn class="mt-1" block :href="guestReviewsUrl" disabled href="">Guest Reviews</v-btn>
                            <v-btn class="mt-1" block :href="starRatingsUrl">Star Ratings</v-btn>
                            <v-btn class="mt-1" block :href="awardsUrl" disabled href="">Awards</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<script>
    /**
     * Display buttons linking through to a hotels' pages
     */
    export default {
        props:['pageid'],

        data: function() {
            return {
                overviewUrl: '',
                detailedReviewsUrl: '',
                guestReviewsUrl: '',
                starRatingsUrl: '',
                pressReviewsUrl: '',
                awardsUrl: '',
                marketingUrl: ''
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            //this.overviewUrl = "/hotel-writeup/" + this.pageid;
            this.detailedReviewsUrl = "/hotel-detailed-review/" + this.pageid;
            this.starRatingsUrl = "/hotel-ratings/" + this.pageid;
            this.pressReviewsUrl = "/hotel-press/" + this.pageid;

            // At this time there is only a generic listing page for all hotel awards, and there
            // is no version of the listing page which is capable of autofiltering by hotel ID
            this.awardsUrl = "/hotel-awards/list";
            // @todo this is hotel id not the writeup id which is going to be needed to look up from an api end point since its not supplied with page data
            this.overviewUrl = "/hotel-overview/" + this.pageid;
        },

        methods: {},

        computed: {}
    }
</script>

