<template>
    <v-flex>
        <v-container class="hotelRoomPickerWrapper">
            <v-layout row>

                <v-flex xs6>
                    <v-select
                            :items="hotelsList"
                            label="Select hotel"
                            v-model="selectedHotel"
                            :disabled="!hotelSelectorActive"
                            dense
                            autocomplete
                            hint="Type to search for a hotel"
                            persistent-hint
                            prepend-icon="house"
                    ></v-select>
                </v-flex>

                <v-flex xs5>
                    <v-select
                            :items="hotelRooms"
                            label="Select room"
                            v-model="selectedRoom"
                            :disabled="!roomsSelectorActive"
                            item-text="name"
                            item-value="hotel_room_key"
                            prepend-icon="room"
                            return-object
                            dense
                            autocomplete
                    ></v-select>
                </v-flex>

            </v-layout>
        </v-container>
    </v-flex>
</template>

<script>
    // Import the application event bus so we can listen for data changes and react accordingly
    import EventBus from '@/eventBus';

    /**
     * Component to allow the user to select a hotel and immediately be taken through to their dashboard page
     *
     * You can pass picker name as property when registering this component, and this name will be passed with emit
     * when user select hotel+room, useful when you want to use multiple components and listen for each change
     */
    export default {
        props:['name'],
        data: function(){
            return {
                /** @var [] allHotels     - all hotels with rooms that were pulled from API */
                allHotels:  [],
                /** @var [] hotelsList    - The list of select options as an array of objects ready for the :items prop of the select box */
                hotelsList: [],
                /** @var [] hotelRooms    - when hotel will be selected, here there will be added rooms that belongs to this hotel */
                hotelRooms: [],

                /** @var {} selectedHotel - The view model showing what hotel the user has selected in the UI */
                selectedHotel:  { value: '', text: '' },
                /** @var {} selectedHotel - The view model showing what hotel the user has selected in the UI */
                selectedRoom:  { value: '', text: '' },
                /** @var {} selected - object with data about selected hotel & room as one object that will be passed with emit to parent component */
                selected: {},

                hotelSelectorActive: false,
                roomsSelectorActive: false,

            };
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            this.loadData();
            this.setUpEventListener();
        },

        methods: {
            /**
             * Obtains a fresh local copy of the hotel listing to be used in this component from the app
             * Updates the this.allHotels list to match the values received, such that the value of the select box will be the hotel key and the text label will be code and hotel name
             */
            loadData(){
                var self        = this;

                axios.get('/api/hotelsWithRooms')
                    .then(self.setHotelList)
                    .catch(function(exception){
                        console.log('%cError obtaining hotel list for hotel room picker component', 'color:red');
                        console.log(exception);
                    });
            },

            /**
             * On successfully obtaining a new list of user hotels we need to set up a new view model representing the data in a format that the user interface components can use
             * Presently this is a select box which expects to have an array of objects containing the properties:
             *      'text':     labels of (hotel_code) hotel_name
             *      'value':    hotel_key
             */
            setHotelList: function(response){
                var selectList  = [];

                if (response.data) {
                    this.allHotels = response.data;

                    response.data.forEach(function(hotel){
                        selectList[ selectList.length ] = ( { value: hotel.hotel_key, text: '(' + hotel.hotel_code + ') ' + hotel.hotel_name } );
                    });
                }

                this.hotelsList = selectList;
                this.hotelSelectorActive = true;
            },

            /**
             * Finds requested hotel & rooms and sets hotelRooms variable with this hotel rooms
             * Make rooms selector active
             */
            setHotelRooms(hotelKey) {
                let hotel = this.getHotelByKey(hotelKey);

                // clear previous selectors
                this.selected = {};
                this.selectedRoom =  { value: '', text: '' };

                if (hotel.hotel_key > 0) {
                    this.selected.hotel_key = hotel.hotel_key;
                    this.selected.hotel_code = hotel.hotel_code;
                    this.selected.hotel_name = hotel.hotel_name;

                    this.hotelRooms = hotel.rooms;
                    this.roomsSelectorActive= true;
                }
            },

            /**
             * Finds requested hotel
             * @param hotelKey
             * @return {}
             */
            getHotelByKey(hotelKey) {
                let found = {};

                this.allHotels.forEach(hotel => {
                    if (hotel.hotel_key === hotelKey) {
                        found = hotel;
                    }
                });

                return found;
            },


            /**
             * Validates if selected object contains all necessary data and can be passed to parent component
             * @return boolean
             */
            validateSelection() {
                if (this.selected !== {} && this.selected.hotel_room_key > 0 && this.selected.hotel_key > 0) {
                    return true;
                }

                return false;
            },

            setSelectedRoom(selectedRoom) {
                if (selectedRoom && selectedRoom.hotel_room_key > 0) {
                    this.selected.room_name = selectedRoom.name;
                    this.selected.hotel_room_key = selectedRoom.hotel_room_key;

                    if (this.validateSelection()) {
                        this.emitPickerState();
                    }
                }
            },

            /**
             * Emit to parent component picker state using EventBus
             * emit event will look like : HotelRoomUpdated:name
             * name of the picker can be passed as prop when registering this component, as default it will be 'HotelRoomPicker'
             */
            emitPickerState() {
                let pickerName = this.name || 'HotelRoomPicker';
                let emitName = 'HotelRoomPickerUpdated:' + pickerName;
                EventBus.$emit(emitName, this.selected);
            },

            /**
             * Register event listener that will be listening for clearSelector event to clear it's selected state
             */
            setUpEventListener() {
                let pickerName = this.name || 'HotelRoomPicker';
                let emitName = 'HotelRoomPickerClear:' + pickerName;

                EventBus.$on(emitName, this.clearSelectors);
            },

            /**
             * Clear hotel/room selector
             */
            clearSelectors() {
                this.hotelRooms = [];
                this.selectedHotel =  { value: '', text: '' };
                this.selectedRoom =  { value: '', text: '' };
                this.selected = {};
                this.roomsSelectorActive = false;
            },

        },

        watch: {
            selectedHotel: function (val, oldVal) {
                if (val > 0) {
                    this.setHotelRooms(val);
                }
            },

            selectedRoom: function (val, oldVal) {
                if (val && val.hotel_room_key > 0) {
                    this.setSelectedRoom(val);
                }
            },
        },
    }
</script>

<style scoped>

    .hotelRoomPickerWrapper .menu__content--select {
        z-index: 300 !important;
    }

    .hotelRoomPickerWrapper .menu__content--dropdown {
        z-index: 300 !important;
    }
</style>
