var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-flex",
    { attrs: { xs12: "" } },
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.hotelsTableHeaders,
          items: _vm.hotels,
          pagination: _vm.pagination,
        },
        on: {
          "update:pagination": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function (props) {
              return [
                _c("td", [_vm._v(_vm._s(props.item.hotel_key))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(props.item.hotel_code))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(props.item.hotel_name))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(props.item.most_recent_update))]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }