<template>
    <v-col cols="12">
        <v-btn class="mt-1" :href="channelManagersUrl"><v-icon>sync</v-icon></v-btn>
        <v-btn class="mt-1" :href="icalUrl">iCal</v-btn>
        <v-btn class="mt-1" :href="channelsSizeReportUrl"><v-icon>show_chart</v-icon></v-btn>
        <v-btn class="mt-1" :href="bookingStatusReportUrl"><v-icon>show_chart</v-icon></v-btn>
        <v-btn class="mt-1" :href="channelFaultsUrl"><v-icon>report</v-icon></v-btn>
    </v-col>
</template>

<script>
    /**
     * Display buttons linking through to a hotels' pages
     */
    export default {
        props:['pageid'],

        data: function() {
            return {
                channelManagersUrl: '',
                icalUrl: '',
                channelsSizeReportUrl: '',
                bookingStatusReportUrl: '',
                channelFaultssUrl: '',
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            this.channelManagersUrl = "/channel-managers/list";
            this.icalUrl = "/incoming-ical-feeds/list";
            this.channelsSizeReportUrl = "/channel-manager-size/report";
            this.bookingStatusReportUrl = "/booking-status/report";
            this.channelFaultsUrl = "/channel-faults/list";
        },

        methods: {},

        computed: {}
    }
</script>
