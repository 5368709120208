<template>
    <v-container fluid>
       <v-layout row-sm column>
            <v-col v-if="this.field.schema.displayName != 'none'" cols="12">
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-col>

            <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            <v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            <v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>

            <v-row>
				<v-col sm="12" class="d-flex flex-column">
					<input type="hidden" :name="field.fieldName" :value="field.value">
					
					<v-radio-group
						v-model="billing"
						row
						autocomplete>
						<v-radio label="Upfront Deposit"	:value="1"></v-radio>
						<v-radio label="In Arrears"			:value="0"></v-radio>
					</v-radio-group>
					<v-col>
						 <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
					  		 <li v-for="error in field.errors">
						  		 {{ error }}
							 </li>
					 	</v-alert>
					</v-col>
				</v-col>
            </v-row>
         </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'ies-input-billing-type',
    props: ['field'],
    data () {
        return {
			billing: parseInt(this.field.value),
        }
    },

	watch: {
		billing: function (newValue) {
			this.field.value = newValue;
		}
	}	
}
</script>
