var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "row-sm": "", column: "" } },
        [
          this.field.schema.displayName != "none"
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _c("label", { attrs: { for: this.field.fieldName } }, [
                  _vm._v(" " + _vm._s(this.field.schema.displayName) + ": "),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          this.field.introduction
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _vm._v(_vm._s(this.field.introduction)),
              ])
            : _vm._e(),
          _vm._v(" "),
          this.field.information
            ? _c(
                "v-alert",
                { attrs: { type: "info", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.information))]
              )
            : _vm._e(),
          _vm._v(" "),
          this.field.warning
            ? _c(
                "v-alert",
                { attrs: { type: "warning", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.warning))]
              )
            : _vm._e(),
          _vm._v(" "),
          this.field.error
            ? _c(
                "v-alert",
                { attrs: { type: "error", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.error))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "d-flex", attrs: { md: "12" } },
                [
                  _c("input", {
                    attrs: { type: "hidden", name: _vm.field.fieldName },
                    domProps: { value: _vm.field.value },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "d-flex flex-column", attrs: { md: "4" } },
                    [
                      _c("v-col", [
                        _c("label", { attrs: { for: "beach" } }, [
                          _vm._v("Beach (within 5 mins?):"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.location.beach,
                              expression: "location.beach",
                            },
                          ],
                          attrs: { name: "beach", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.location.beach)
                              ? _vm._i(_vm.location.beach, null) > -1
                              : _vm.location.beach,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.location.beach,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.location,
                                      "beach",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.location,
                                      "beach",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.location, "beach", $$c)
                              }
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("v-col", [
                        _c("label", { attrs: { for: "city" } }, [
                          _vm._v("City:"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.location.city,
                              expression: "location.city",
                            },
                          ],
                          attrs: { name: "city", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.location.city)
                              ? _vm._i(_vm.location.city, null) > -1
                              : _vm.location.city,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.location.city,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.location,
                                      "city",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.location,
                                      "city",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.location, "city", $$c)
                              }
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("v-col", [
                        _c("label", { attrs: { for: "coast" } }, [
                          _vm._v("Coast:"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.location.coast,
                              expression: "location.coast",
                            },
                          ],
                          attrs: { name: "coast", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.location.coast)
                              ? _vm._i(_vm.location.coast, null) > -1
                              : _vm.location.coast,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.location.coast,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.location,
                                      "coast",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.location,
                                      "coast",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.location, "coast", $$c)
                              }
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("v-col", [
                        _c("label", { attrs: { for: "desert" } }, [
                          _vm._v("Desert:"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.location.desert,
                              expression: "location.desert",
                            },
                          ],
                          attrs: { name: "desert", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.location.desert)
                              ? _vm._i(_vm.location.desert, null) > -1
                              : _vm.location.desert,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.location.desert,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.location,
                                      "desert",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.location,
                                      "desert",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.location, "desert", $$c)
                              }
                            },
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "d-flex flex-column", attrs: { md: "4" } },
                    [
                      _c("v-col", [
                        _c("label", { attrs: { for: "forest" } }, [
                          _vm._v("Forest:"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.location.forest,
                              expression: "location.forest",
                            },
                          ],
                          attrs: { name: "forest", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.location.forest)
                              ? _vm._i(_vm.location.forest, null) > -1
                              : _vm.location.forest,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.location.forest,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.location,
                                      "forest",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.location,
                                      "forest",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.location, "forest", $$c)
                              }
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("v-col", [
                        _c("label", { attrs: { for: "island" } }, [
                          _vm._v("Island:"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.location.island,
                              expression: "location.island",
                            },
                          ],
                          attrs: { name: "island", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.location.island)
                              ? _vm._i(_vm.location.island, null) > -1
                              : _vm.location.island,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.location.island,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.location,
                                      "island",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.location,
                                      "island",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.location, "island", $$c)
                              }
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("v-col", [
                        _c("label", { attrs: { for: "lake" } }, [
                          _vm._v("Lake:"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.location.lake,
                              expression: "location.lake",
                            },
                          ],
                          attrs: { name: "lake", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.location.lake)
                              ? _vm._i(_vm.location.lake, null) > -1
                              : _vm.location.lake,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.location.lake,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.location,
                                      "lake",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.location,
                                      "lake",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.location, "lake", $$c)
                              }
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("v-col", [
                        _c("label", { attrs: { for: "mountain" } }, [
                          _vm._v("Mountain:"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.location.mountain,
                              expression: "location.mountain",
                            },
                          ],
                          attrs: { name: "mountain", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.location.mountain)
                              ? _vm._i(_vm.location.mountain, null) > -1
                              : _vm.location.mountain,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.location.mountain,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.location,
                                      "mountain",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.location,
                                      "mountain",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.location, "mountain", $$c)
                              }
                            },
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "d-flex flex-column", attrs: { md: "4" } },
                    [
                      _c("v-col", [
                        _c("label", { attrs: { for: "naturereserve" } }, [
                          _vm._v("Nature Reserve:"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.location.naturereserve,
                              expression: "location.naturereserve",
                            },
                          ],
                          attrs: { name: "naturereserve", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.location.naturereserve)
                              ? _vm._i(_vm.location.naturereserve, null) > -1
                              : _vm.location.naturereserve,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.location.naturereserve,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.location,
                                      "naturereserve",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.location,
                                      "naturereserve",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.location, "naturereserve", $$c)
                              }
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("v-col", [
                        _c("label", { attrs: { for: "river" } }, [
                          _vm._v("River:"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.location.river,
                              expression: "location.river",
                            },
                          ],
                          attrs: { name: "river", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.location.river)
                              ? _vm._i(_vm.location.river, null) > -1
                              : _vm.location.river,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.location.river,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.location,
                                      "river",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.location,
                                      "river",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.location, "river", $$c)
                              }
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("v-col", [
                        _c("label", { attrs: { for: "rural" } }, [
                          _vm._v("Rural:"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.location.rural,
                              expression: "location.rural",
                            },
                          ],
                          attrs: { name: "rural", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.location.rural)
                              ? _vm._i(_vm.location.rural, null) > -1
                              : _vm.location.rural,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.location.rural,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.location,
                                      "rural",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.location,
                                      "rural",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.location, "rural", $$c)
                              }
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("v-col", [
                        _c("label", { attrs: { for: "villagetown" } }, [
                          _vm._v("Village / Town:"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.location.villagetown,
                              expression: "location.villagetown",
                            },
                          ],
                          attrs: { name: "villagetown", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.location.villagetown)
                              ? _vm._i(_vm.location.villagetown, null) > -1
                              : _vm.location.villagetown,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.location.villagetown,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.location,
                                      "villagetown",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.location,
                                      "villagetown",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.location, "villagetown", $$c)
                              }
                            },
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _vm.field.errors
                    ? _c(
                        "v-alert",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { type: "error", value: _vm.field.errors },
                        },
                        _vm._l(_vm.field.errors, function (error) {
                          return _c("li", [
                            _vm._v(
                              "\n\t\t\t\t\t  \t\t " +
                                _vm._s(error) +
                                "\n\t\t\t\t\t\t "
                            ),
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }