var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", sm8: "" } }, [
            _c("label", [
              _vm._v("\n                Permission Type:\n            "),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "v-flex",
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", sm4: "" } }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.mainObject.portal_content_type_key,
                            expression: "mainObject.portal_content_type_key",
                          },
                        ],
                        staticClass: "full-width",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.mainObject,
                              "portal_content_type_key",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("No Restrictions"),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.permissions, function (column, colKey) {
                          return _c(
                            "option",
                            { domProps: { value: column.value } },
                            [_vm._v(" " + _vm._s(column.text) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }