<template>
    <v-col cols="12" md="3" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">subject</v-icon> Policies
            </v-card-title>

            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col>
                            <v-btn class="mt-1" block disabled href="">Credit Cards</v-btn>
                            <v-btn class="mt-1" block disabled href="">Booking Policies</v-btn>
                            <v-btn class="mt-1" block :href="childPolicyUrl">Children</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        
        </v-card>
    </v-col>
</template>

<script>
    /**
     * Display buttons linking through to a hotels' pages
     */
    export default {
        props:['pageid'],

        data: function() {
            return {
				childPolicyUrl: '',
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
			this.childPolicyUrl = "/hotel-children/" + this.pageid;
		},

        methods: {},

        computed: {}
    }
</script>

