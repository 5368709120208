<template>

<v-container fluid pa-0>
<v-layout row wrap>
    <v-flex class="d-flex justify-space-between">
        <v-btn v-for="button in buttons" @click="performActions(button)" style="height:100%;" class="qa-button">
            <span class="qa-button-label">
                <v-icon style="font-size:2rem;">{{ button.icon }}</v-icon><br>
                <div class="d-none d-md-block" style="font-size:0.7rem; letter-spacing: none;">{{ button.label }}</div>
                <span class="qa-button-counter" v-if="button.counter">{{ button.counter }}</span>
            </span>
        </v-btn>
    </v-flex>
</v-layout>
</v-container>

</template>

<script>
    import axios from 'axios';

    /**
     * A component to render utility buttons into the navbar at the top of the page for
     * relevant device sizes so that they can provide an immediate one-click link to the
     * most common tasks needed by the current user, thereby saving them from having to use
     * the menu or clicking through multiple pages, or needing to bookmark common pages
     */
    export default {
        props:['metadata','user'],
        data: function() {
            return {
                items: []
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            this.fetchItems();
        },

        methods: {
            /**
             * Perform the relevant actions on button use
             */
            performActions: function(button){
                console.log("This feature is not yet functional");
                console.log(button);
                button.actions.forEach(function(step){
console.log(step);
                    switch(step.action){
                        case 'alert':
                            alert(step.message);
                            break;
                        case 'redirect':
                            window.location.assign(step.url);
                            break;
             
                    }
                });
            },

            fetchItems:function(){
                axios.get('/api/quick-access-items')
                .then(function(response){
                   this.items = response.data;
                }.bind(this));
            }
        },

        computed: {
            buttons: function(){
console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~", this.items);
                return this.items;
            }
        }
    }
</script>

<style>

    .qa-button {
        background: none !important;
        border: none !important;
        box-shadow: none;
        height: fit-content !important;
        margin-bottom: 5px;
        margin-top: 5px;
        width: fit-content !important;
        padding: 0 !important;
    }

    .qa-button-label { 
        color: rgb(118,118,118);
        text-align: center;
        text-transform: none;
        white-space: normal;
    }

    .qa-button-counter {
        background-color: #F15C61;
        color: white;
        text-align: center;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        display: inline-block;
        line-height: 1.5rem;
        position: absolute;
        top: 0;
        font-size: 0.8rem;
    }
</style>
