var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-flex",
    { attrs: { xs12: "", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", [
            _vm._v("\n            " + _vm._s(_vm.salutation) + "\n        "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }