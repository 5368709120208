var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "3" } },
    [
      _c(
        "v-card",
        { staticClass: "ma-1" },
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [
                _vm._v("desktop_windows"),
              ]),
              _vm._v(" Home Pages\n    "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-btn",
                { attrs: { block: "", href: "/main-home-header-images/list" } },
                [_vm._v("Header Images")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    block: "",
                    href: "/main-home-collections-tiles/list",
                  },
                },
                [_vm._v("Collections Tiles")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { block: "", href: "/main-home-blogs-tiles/list" } },
                [_vm._v("Blogs Tiles")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { block: "", href: "/main-home-campaigns/list" } },
                [_vm._v("Campaigns")]
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { block: "", href: "/kids-home-header-images/list" } },
                [_vm._v("Header Images (kids)")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    block: "",
                    href: "/kids-home-collections-tiles/list",
                  },
                },
                [_vm._v("Collections Tiles (kids)")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { block: "", href: "/kids-home-blogs-tiles/list" } },
                [_vm._v("Blogs Tiles (kids)")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { block: "", href: "/kids-home-campaigns/list" } },
                [_vm._v("Campaigns (kids)")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }