<template>
    <v-col cols="12" md="3">
    <v-card class="ma-1">
        <v-card-title class="title font-weight-black">
            <v-icon class=pr-3>local_offer</v-icon> News & Offers
        </v-card-title>

        <v-card-text>
            <v-btn block disabled href="">Bulk offers</v-btn>
            <v-btn block disabled href="">New hotels</v-btn>
            <v-btn block href="/newsletters/list">Newsletters</v-btn>
            <v-btn block href="/press-releases/list">Press releases</v-btn>
            <v-btn block href="/press-comments/list">Press comments</v-btn>
            <v-btn block href="/competitions/list"><v-icon>star</v-icon> Competitions</v-btn>
            <v-btn block href="/promotions/list"><v-icon>local_offer</v-icon> Promotions</v-btn>
            <v-btn block href="/promotional-hotels/list"><v-icon>local_offer</v-icon> Promotional hotels</v-btn>
        </v-card-text>
    </v-card>
    </v-col>
</template>

<script>
    /**
     * Display buttons linking through to hotels' pages
     */
    export default {
        props:[],

        data: function() {
            return {
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){},

        methods: {},

        computed: {}
    }
</script>
