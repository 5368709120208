<template>
    <v-col cols="12" md="3">
    <v-card class="ma-1">
        <v-card-title class="title font-weight-black">
            <v-icon class=pr-3>desktop_windows</v-icon> Home Pages
        </v-card-title>

        <v-card-text>
            <v-btn block href="/main-home-header-images/list">Header Images</v-btn>
            <v-btn block href="/main-home-collections-tiles/list">Collections Tiles</v-btn>
            <v-btn block href="/main-home-blogs-tiles/list">Blogs Tiles</v-btn>
            <v-btn block href="/main-home-campaigns/list">Campaigns</v-btn>
            <v-divider></v-divider>
            <v-btn block href="/kids-home-header-images/list">Header Images (kids)</v-btn>
            <v-btn block href="/kids-home-collections-tiles/list">Collections Tiles (kids)</v-btn>
            <v-btn block href="/kids-home-blogs-tiles/list">Blogs Tiles (kids)</v-btn>
            <v-btn block href="/kids-home-campaigns/list">Campaigns (kids)</v-btn>
        </v-card-text>
    </v-card>
    </v-col>
</template>

<script>
    /**
     * Display buttons linking through to hotels' pages
     */
    export default {
        props:[],

        data: function() {
            return {
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){},

        methods: {},

        computed: {}
    }
</script>
