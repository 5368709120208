var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _vm.metaData.primaryKey
        ? _c(
            "v-card",
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "h2",
                                    [
                                      _c("v-icon", [_vm._v("history")]),
                                      _vm._v(" Record History"),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    { on: { click: _vm.getHistory } },
                                    [_vm._v("Load History")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.noHistory
                                ? _c("v-col", { attrs: { cols: "12" } }, [
                                    _vm._v(
                                      "\n                            Sorry, there is no recorded history for the data on this page\n                       "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(_vm.history, function (recordChange, key) {
                                return _c(
                                  "v-col",
                                  { key: key, attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "v-container",
                                      { attrs: { fluid: "" } },
                                      [
                                        _c(
                                          "v-layout",
                                          { attrs: { row: "", wrap: "" } },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "2" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    Changed At:\n                               "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "10" },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      recordChange.creationDate
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" at "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      recordChange.creationTime
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "2" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    Changed By:\n                                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "10" },
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.emailChanger(
                                                          recordChange.userEmail,
                                                          recordChange.pageURL,
                                                          recordChange.creationDate
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        recordChange.userName
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  "\n                                   (" +
                                                    _vm._s(
                                                      recordChange.userType
                                                    ) +
                                                    ")\n                                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "2" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    Changed on Page:\n                                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "10" },
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: recordChange.pageURL,
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        recordChange.pageURL
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              [_c("strong", [_vm._v("Field:")])]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _c("strong", [
                                                  _vm._v("Changed From:"),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "8" } },
                                              [
                                                _c("strong", [
                                                  _vm._v("Changed To:"),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              recordChange.record_changes,
                                              function (field, key) {
                                                return _c(
                                                  "v-col",
                                                  {
                                                    key: key,
                                                    attrs: { cols: "12" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-layout",
                                                      {
                                                        attrs: {
                                                          row: "",
                                                          wrap: "",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "2",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  field.fieldName
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "2",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  field.old_value
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "8",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  field.new_value
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [_c("hr")]
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }