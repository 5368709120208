var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { md: "6", lg: "4", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }),
              _vm._v(" Newsletter Signups Statistics\n        "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-expansion-panel",
                { attrs: { expand: "" } },
                [
                  _c(
                    "v-expansion-panel-content",
                    { attrs: { ripple: "" } },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c("strong", [_vm._v("Select Date Range")]),
                      ]),
                      _vm._v(" "),
                      _c("ies-date-range", {
                        attrs: { name: "newsletter-signups" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("line-chart", {
                attrs: { chartdata: _vm.chartData, options: _vm.chartOptions },
              }),
              _vm._v(" "),
              _c("v-flex", { attrs: { x12: "" } }, [
                _vm._v(
                  "\n                Total Monthly-Newsletter Signups: " +
                    _vm._s(_vm.chartData.count_monthly_newsletters) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("v-flex", { attrs: { x12: "" } }, [
                _vm._v(
                  "\n                Total Family-Newsletter Signups: " +
                    _vm._s(_vm.chartData.count_family_newsletters) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("v-flex", { attrs: { x12: "" } }, [
                _vm._v(
                  "\n                Total Signed up to Both Newsletters: " +
                    _vm._s(_vm.chartData.count_both_newsletters) +
                    "\n            "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }