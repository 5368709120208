<template>
    <v-flex xs12 ma-1>
        <v-card>
            <v-card-text>
                {{salutation}}
            </v-card-text>
        </v-card>
    </v-flex>
</template>

<script>
    import axios from 'axios';

    /**
     * Display a greeting to the user
     */
    export default {
        props:[],

        data: function() {
            return {
                salutations:   [],
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            this.loadGreetings();
        },

        methods: {
            /**
             * Load the data describing all the panels for the dashboard to host by requesting them from the API
             */
            loadGreetings(){
                axios.get('api/salutations')
                .then(response => {
                    this.salutations = response.data;
                })
                .catch(error => {
                    console.log(error);
                });
            },
        },

        computed: {
            salutation: function(){
                var index =  Math.floor( Math.random() * this.salutations.length );
                var salutation = this.salutations[index];
                return salutation;
            }
        }
    }
</script>
