<template>
    <div>
        <div v-for="blogRegion in viewValues" class="mb-3">
            {{ getRegionNameForKey(blogRegion) }}
            <v-btn v-on:click="deleteRegion(blogRegion)" class="ml-3">
                Remove</v-icon>
            </v-btn>
        </div>

        <hr class="mt-3 mb-3">

        <!-- list must be list of ['text' => x, 'value' => y] -->
        <v-select
            v-model="regionToAdd"
            label="Choose a Region"
            :items="regionOptions"
            autocomplete
        ></v-select>
        <v-btn class="ies-green" 
               v-on:click="addRegion"><v-icon>add</v-icon> Add</v-btn>

        <input type="hidden" :name="field.fieldName" :value="field.value">
        <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
            <li v-for="error in field.errors" v-bind:key="error">
                {{ error }}
            </li>
        </v-alert>


    </div>
</template>

<script>
    /**
     * Allows the selection of zero or more regions for this blog record to be
     * assigned to, so that the blog can be advertised to clients visiting the
     * relevant regions' pages
     *
     * A blog can be pinned to multiple regions, or none at all so that it will
     * be unrestricted
     */
    export default {
        name: 'blogRegion',
        props: ['field', 'recordData'],
        data() {
            return {
                regionToAdd: {},
                /** @var array regionOptions the list of regions the user can choose from */
                regionOptions: [],
                /** @var array blogRegions the regions already assigned to this blog post */
                //blogRegions: [],
                viewValues: [],
            }
        },

        created:function() {
console.log("blog regions", this);
            this.getRegionOptions();
            this.getBlogRegions();
        },

        methods: {
            getRegionOptions:function(){
                axios.get('/api/selectable-regions')
                .then(function(response){
                    var regionOptions = [];
                    response.data.regions.forEach(function(region){
                        regionOptions.push({ text: region.region_name, value: region.region_key });
                    });
                    this.regionOptions = regionOptions;
                }.bind(this));
            },

            getBlogRegions:function(){
console.log('field is ', this.field, " page record ", this.recordData);
                axios.get('/api/blog-regions?spbek=' + this.recordData.search_page_blog_entry_key)
                .then(function(response){
                    var blogRegions = [];
                    response.data.forEach(function(entry){
                        //ogRegions.push(10);// Hard coded to one region to start with
                        response.data.forEach(function(region){
                            blogRegions.push(region.region_key);
                        });
                    });
                    this.viewValues = blogRegions;
                }.bind(this));
            },

            getRegionNameForKey: function(keyToFind){
                console.log(this.viewValues, this.regionOptions);
                var regionName = "Unknown";
                this.regionOptions.forEach(function(regionOption){
 console.log(regionOption.value + " vs " + keyToFind);
                    if (regionOption.value == keyToFind){
console.log("Found it");
                        regionName = regionOption.text;
                    }
                });
                return regionName;
            },

            addRegion: function(){
                var isAlreadySet = false;
                var keyToFind = this.regionToAdd;
console.log(this.regionToAdd);

                this.viewValues.forEach(function(regionKey){
                    console.log(regionKey + " vs " + keyToFind);
                    if (regionKey == keyToFind){
                        isAlreadySet = true;
                    }
                });

                if (!isAlreadySet){
                    this.viewValues.push(this.regionToAdd);
                }
            },


            deleteRegion: function(regionKey){
                for(var i = 0; i < this.viewValues.length; i++) {
                    if (this.viewValues[i] === regionKey) {
                        this.viewValues.splice(i, 1);
                    }
                }
            }
        },

        watch: {
            viewValues: function (newValues) {
                this.field.value = newValues;
                //console.log("view values has changed is now ". this.viewValues);
            }
        }
    }
</script>
