<template>
    <v-container grid-list-xl>
        <v-layout row wrap justify-center>
            <v-flex xs8 md8 lg8 align-center>
                <h2>Page Content import/export tool</h2>
            </v-flex>

<!------- EXPORT PAGE CONTENT ------------->
            <v-flex xs8 md8 lg8>
                <h3>Export page content</h3>

                <v-flex>
                    <v-select
                            :items="allPages"
                            v-model="selectedPages"
                            item-text="display_name"
                            item-value="portal_page_key"
                            label="Select pages"
                            multiple
                            chips
                            deletable-chips
                            autocomplete
                            hint="Start typing to search for a page, You can select multiple pages"
                            persistent-hint
                    ></v-select>

                    <v-btn
                            color="ies-green"
                            @click="exportTo('file')"
                    >
                        Export to file
                        <v-icon right>save_alt</v-icon>
                    </v-btn>

                    <v-btn
                            color="blue-grey"
                            class="white--text"
                            @click="exportTo('text')"
                    >
                        Export as text
                        <v-icon right>format_align_left</v-icon>
                    </v-btn>
                </v-flex>

                <v-flex xs8 md8 lg8 v-if="showExportTextArea">

                    <textarea id="selected-textArea">{{ selectedPagesTextToDisplay }}</textarea>

                    <v-layout row >
                        <v-flex xs2>
                            <v-btn @click="copyToClipboard()">
                                Copy to Clipboard
                                <v-icon right>file_copy</v-icon>
                            </v-btn>
                        </v-flex>
                        <v-flex xs2>
                            <v-alert
                                    v-model="successBar.show"
                                    color="success"
                                    icon="check_circle"
                                    outline
                            >
                                {{ successBar.text }}
                            </v-alert>
                        </v-flex>
                        <v-flex class="text-xs-right" align-content-end>
                            <v-btn @click="showExportTextArea = false">
                                Hide
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-divider id="divider"></v-divider>
            </v-flex>


<!------- IMPORT PAGE CONTENT ------------->

            <v-flex xs8 md8 lg8>
                <h3>Import page content</h3>

                <v-flex xs6>
                    <span>Import from file </span>
                    <div class="btn" id="fileinput-btn">
                        <input type="file" id="fileinput" accept="text/plain">
                    </div>
                    <span style="color:gray">Hint: you can drag and drop text file to this input</span>
                </v-flex>

                <textarea placeholder="or copy valid JSON here" id="importTextArea"></textarea>
            </v-flex>

            <v-flex xs8 md8 lg8>
                <v-layout row >

                    <v-flex xs2 v-if="!showImportContent">
                        <v-btn
                                color="info"
                                class="white--text"
                                @click="readImport()"
                        >
                            Validate
                            <v-icon right>playlist_add_check</v-icon>
                        </v-btn>
                    </v-flex>

                    <v-flex xs2 v-if="showImportContent">
                        <v-btn
                                class="ies-green"
                                @click="importPages()"
                        >
                            Import
                            <v-icon right>cloud_upload</v-icon>
                        </v-btn>
                    </v-flex>

                    <v-flex xs4 v-if="importBar.show">
                        <v-alert
                                v-model="importBar.show"
                                :type="importBar.type"
                        >
                            {{ importBar.text }}
                        </v-alert>
                    </v-flex>
                    <v-flex class="text-xs-right" align-content-end>
                        <v-btn @click="clearImport()">
                            Clear
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>

<!------- PAGE CONTENT DIFFERENCE SCREEN ------------->
            <v-flex xs8 md8 lg8 v-if="showImportContent">
                <v-layout row>
                    <v-flex xs4 v-if="updatedPagesImportCount > 0">
                        <strong>Pages that will be updated:</strong>
                        <ul class="import-list">
                            <li v-for="page in validatedPages.update">
                                {{ page.display_name }}
                            </li>
                        </ul>
                    </v-flex>
                    <v-flex xs4 v-if="newPagesImportCount > 0">
                        <strong>New pages that will be added:</strong>
                        <ul class="import-list">
                            <li v-for="page in validatedPages.new">
                                {{ page.display_name }}
                            </li>
                        </ul>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>

<!------- SUCCESS / ERROR SNACKBAR ------------->
        <v-snackbar
                :timeout="parseInt(6000)"
                v-model="snackbar.show"
                multi-line
                :color="snackbar.color"
        >
            <div class="full-height full-width v-align-container text-xs-center">
                <h4 class="ies-dark-gray--text v-align-div">{{ this.snackbar.text }}</h4>
            </div>
        </v-snackbar>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import FileSaver from 'file-saver';
    export default {
        name: 'PageContentImportExport',
        props: ['pages'],
        data () {
            return {
                allPages: this.pages,
		        selectedPages: [],
                selectedPagesTextToDisplay: '',
                showExportTextArea: false,
                successBar: {
                    show : false,
                    text : 'Success',
                },
                importBar: {
                    show : false,
                    text : 'Message',
                    type: 'info', // success/info/warning/error
                },
                contentUploaded: '',
                showImportContent: false,
                validatedPages: {
                    new : [],
                    update: []
                },
                snackbar: {
                    show: false,
                    color: '', // ies-green -> success / ies-coral -> error
                    text: '',
                },

            }
        },
        methods: {
            /**************************** EXPORT METHODS *******************************/

            /**
             * Export button handler, checks if any pages were selected, loads them and converts to string,
             * and trigger next export methods depends which option was selected.
             */
            exportTo(where) {
                if (this.selectedPages.length) {
                    // clear previous text area
                    this.selectedPagesTextToDisplay = '';

                    this.generatePagesTextToDisplay();

                    if (where === 'file') {
                        this.exportFile();
                    }
                    else if (where === 'text') {
                        this.exportText();
                    }
                }
                else {
                    alert('Select pages that you want to export first');
                }
            },

            /**
             * Generates the file with selected pages content, adds creation date to the filename
             */
            exportFile() {
                var today = new Date();
                var todayString = '_' + today.getDate() + '_' + (Number(today.getMonth())+1) + '_' + today.getFullYear();
                var filename = 'portal_page_export' + todayString + '.txt';

                // create file
                var file = new Blob([this.selectedPagesTextToDisplay], {type: 'text/plain;charset=utf-8'});
                FileSaver.saveAs(file, filename);
            },

            /**
             * Display JSON of selected pages in the text area
             */
            exportText() {
                this.showExportTextArea = true;
            },

            copyToClipboard() {
                document.getElementById('selected-textArea').select();
                // this.selectedPagesTextToDisplay.select();
                document.execCommand('copy');
                document.getSelection().removeAllRanges();
                this.showSuccess('Copied!');
            },

            /**
             * Get all selected pages and convert them in to big JSON string to be copied/exported to file
             */
            generatePagesTextToDisplay() {
                // create copy of this object
                var _this = this;
                var pagesArray = [];

                this.selectedPages.forEach(function(id) {
                    pagesArray.push(_this.getPageJson(id));
                });

                this.selectedPagesTextToDisplay = JSON.stringify(pagesArray);
            },

            /**
             * Looks for requested page content
             * @param id
             */
            getPageJson(id) {
                var found;
                this.allPages.forEach(function (page) {
                    if (page.portal_page_key === id) {
                        found = page;
                        return;
                    }
                });
                return found;
            },

            showSuccess(msg) {
                var _this = this;
                this.successBar.text = msg;
                this.successBar.show = true;
                setTimeout(function(){
                    _this.successBar.show = false;
                }, 1500);
            },

            /**************************** IMPORT METHODS *******************************/

            /**
             * Methods import pages content validated earlier and sends it to the backend by AJAX call
             */
            importPages() {
                // create json that will be send in ajax call
                var dataToSend = JSON.stringify({'new':this.validatedPages.new, 'update':this.validatedPages.update});

                // send ajax call
                axios.post('ajax/pagecontent/post',{ data : dataToSend})
                    .then(function(response) {
                        if (response.data.success) {
                            this.showSnackbar('success', 'Saved');
                        }
                        else {
                            this.showSnackbar('error', 'There was a problem with updating database');
                        }
                    }.bind(this))
                    .catch(function(error) {
                        this.showSnackbar('error', 'There was a problem with API call');
                    }.bind(this));
            },

            /**
             * Methods loads the file from <input type="file"> if file was uploaded or
             * reads JSON that was copied to text area then calls appropriate next method
             */
            readImport() {
                // get the first file from object
                var fileInput = document.getElementById('fileinput');
                var file = fileInput.files[0];
                var textArea = document.getElementById('importTextArea');
                var textAreaContent = textArea.value;

                // check any if file is added to file input OR if something is copied to textarea
                if (file) {
                    this.readFromFile(file);
                }
                else if (textAreaContent.length > 10) {
                    this.validateImport(textAreaContent);
                }
                else {
                    // there wasn't any file or nothing in the text area
                    alert('Choose a file to upload, or copy valid JSON to import textarea');
                    textArea.focus();
                }
            },

            /**
             * Methods accepts file from <input type="file">, when it loads it's data is passing it to validateImport method
             */
            readFromFile(file) {
                // only accept text files
                if (file.type === 'text/plain') {
                    var _this = this;
                    var textArea = document.getElementById('importTextArea');
                    //clear textarea
                    textArea.value = '';

                    // create FileRender object
                    var reader = new FileReader();
                    reader.readAsText(file);

                    // call validateImport method on load
                    reader.onload = function(e) {
                        var fileContent = e.target.result;
                        _this.validateImport(fileContent)
                    };
                }
                else {
                    // this alert is just in case, file input element should only accepts text files by default
                    alert('We only accepts text files');
                }
            },

            /**
             * Method accepts JSON as a text and validate its content, mainly checking if the page contains valid object structure
             */
            validateImport(content) {
                var _this = this;
                var validatedPagesUpdate = [];
                var validatedPagesNew = [];

                // get rid of white spaces at the beginning of the text
                content = content.trim();

                try {
                    var contentObj = JSON.parse(content);

                    contentObj.forEach(function(page) {
                        if (page.hasOwnProperty('portal_page_key') && page.hasOwnProperty('page_name') &&
                            page.hasOwnProperty('page_mode') && page.hasOwnProperty('json')) {

                            var validJson = _this.validateJson(page.json);
                            var validPageName = _this.validatePageName(page.page_name);

                            if (validPageName && validJson) {
                                // page passed client side validation for correct JSON structure
                                // data will be validated server side
                                validatedPagesUpdate.push(page);
                            }
                            else if (validJson) {
                                validatedPagesNew.push(page);
                            }
                        }
                    });

                    // check if at least one page passed client side validation
                    if (validatedPagesUpdate.length || validatedPagesNew.length) {
                        var validated = [];
                        validated['new'] = validatedPagesNew;
                        validated['update'] = validatedPagesUpdate;
                        this.checkForPageDifferences(validated);
                    }
                    else {
                        _this.showImportAlert('error', 'Data did not pass validation, pages object does not contain necessary properties');
                    }
                }
                catch (e) {
                    _this.showImportAlert('error', 'Data did not pass validation, try to upload valid JSON');
                    // console.log('ERROR = ', e);
                }
            },

            /**
             *  Method checks if given page name is one of the supported pages obtained from the backend for client-side validation.
             */
            validatePageName(pageName) {
                var found = false;
                this.pages.forEach(function(validPage) {

                    if (validPage.page_name === pageName) {
                        found = true;
                        return true;
                    }
                });

                if(found) {
                    return true;
                }
            },

            /**
             * Methods checks if given page JSON contains objects with necessary properties
             */
            validateJson(objects) {
                var validationResults = [];

                objects.forEach(function(obj) {

                    if (obj.hasOwnProperty('sectionName') && obj.hasOwnProperty('fields')) {
                        validationResults.push(true);
                    }
                    else {
                        validationResults.push(false);
                    }
                });

                if (validationResults.includes(true) && !validationResults.includes(false)) {
                    return true;
                }

            },

            /**
             * Method check if there are any differences between given pages and the pages that we store already in vue from database.
             * If page json is different add that page to validatedPages object which will be next send with AJAX to backend.
             *
             * @param {Array} - array with pages that passed validation
             */
            checkForPageDifferences(validatedPagesArray) {
                var _this = this;
                var changedPages = [];

                validatedPagesArray['update'].forEach(function (page) {
                    // search for base page content that will be compared with new one
                    var base = _this.getBasePageObject(page.page_name, page.page_mode);
                    var difference = _this.objectDifference(base, page);

                    // check if this page has any changes compering with the page got from database
                    if (difference) {
                        changedPages.push(page);
                    }
                });

                // if there are any changed/new pages assign them to validatedPages property and generate difference message
                if (changedPages.length || validatedPagesArray['new'].length) {
                    validatedPagesArray['update'] = changedPages;
                    this.validatedPages = validatedPagesArray;
                    this.showImportSection();
                }
                else {
                    _this.showImportAlert('info', 'Pages in the database are the same in comparison to the given');
                }
            },

            /**
             * Display section with import data, disable text area and file input
             */
            showImportSection() {
                var textArea = document.getElementById('importTextArea');
                textArea.disabled = true;
                var fileInput = document.getElementById('fileinput');
                fileInput.disabled = true;

                this.showImportContent = true;
            },

            /**
             * Simply compare object JSON as string and check if there are any changes
             * @param  {Object} base   Object to compare with
             * @param  {Object} updated Object compared
             *
             * @return bool
             */
            objectDifference(base, updated) {
                var baseString = JSON.stringify(base.json);
                var updatedString = JSON.stringify(updated.json);

                return baseString.trim() !== updatedString.trim();
            },

            /**
             * Search for page in allPages array and return it
             * @param name - string
             * @param mode - int
             * @return {Object} - page object
             */
            getBasePageObject(name, mode) {
                var found = false;

                this.allPages.forEach(function(page) {
                    if (page.page_name === name && page.page_mode === mode) {
                        found = page;
                    }
                    if (found) {
                        return found;
                    }
                });

                return found;
            },

            /**
             * Method clears all import data, object that holds any import info, import text area, etc
             */
            clearImport() {
                this.contentUploaded = '';
                this.validatedPages.new = [];
                this.validatedPages.update = [];
                this.importBar.show = false;
                var textArea = document.getElementById('importTextArea');
                textArea.value = '';
                textArea.disabled = false;
                var fileInput = document.getElementById('fileinput');
                fileInput.value = '';
                fileInput.disabled = false;
                this.showImportContent = false;
            },

            showImportAlert(type, text) {
                this.importBar.show = true;
                this.importBar.type = type;
                this.importBar.text = text;
            },

            /**
             *  Display success / error snackbar
             */
            showSnackbar(type, text) {
                if (type === 'success') {
                    this.snackbar.color = 'ies-green';
                }
                else if (type === 'error') {
                    this.snackbar.color = 'ies-coral';
                }
                this.snackbar.text = text;
                this.snackbar.show = true;
            },
        },
        computed: {
            updatedPagesImportCount: function() {
                return this.validatedPages.update.length;
            },
            newPagesImportCount: function () {
                return this.validatedPages.new.length;
            },
        },
    }
</script>

<style>
    .alert {
        padding: 7px;
    }

    .hideBtn {
        margin: 0;
        float: right;
    }

    #select-pages {
        max-width: 300px;
    }

    #fileinput-btn {
        height: 70px;
        width: 300px;
    }
    #fileinput {
        padding-top: 21px;
        height: 100%;
    }

    .import-list li {
        margin-left: 20px;
    }

    h3 {
        font-size: 20px;
    }

    #divider {
        margin-top: 25px;
        margin-bottom: 15px;
    }

</style>