<template>
    <v-flex>{{ displayStatus }}</v-flex>
</template>

<script>
/**
 * Outputs a list of facility categories representing the values stored in the
 * model's field.  The field is an integer, but the output is a string
 *
 */
export default {
    name: 'ies-display-status',
    props: ['field', 'value'],
    data () {
        return {}
    },

    computed: {
        displayStatus: function(){
            switch(parseInt(this.value)){
				case 0:
					return "Offline";
				case 1:
					return "Online";
				case 2:
					return "Previewable";
				default:
					return "Unknown";					
			}
        }
    }
}
</script>

