<template>
    <v-layout row wrap>
        <!-- Buttons -->
        <v-btn @click="openCancelMultipleDialog()"><v-icon>cancel_presentation</v-icon>&nbsp;Cancel multiple</v-btn>

        <!-- Dialog for cancel multiple-->
        <v-dialog v-model="cancelMultiple.cancelMultipleDialogOpen" max-width="700px">
            <v-card>
                <v-card-title>
                    <span class="headline">Cancel multiple referral vouchers</span>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                            <v-layout row>
                                <v-flex xs2>
                                    Voucher Code
                                </v-flex>
                                <v-flex xs4>
                                    <input type="text" v-model="cancelMultiple.voucherCode" maxlength="16">
                                </v-flex>
                                <v-flex xs1>
                                    <v-btn color="info" @click="addVoucher">Add</v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout row v-if="cancelMultiple.vouchersToCancel.length">
                                <v-flex xs10 offset-xs2>
                                    <ol class="voucherCodesList">
                                        <li v-for="(code, index) in cancelMultiple.vouchersToCancel" :key="code">
                                            {{ code }}
                                            <v-icon
                                                    small
                                                    @click="clearVoucher(index)"
                                            >
                                                delete
                                            </v-icon>
                                        </li>
                                    </ol>
                                </v-flex>
                            </v-layout>
                            <v-layout row v-if="cancelMultiple.vouchersToCancel.length" class="margin-top-medium">
                                <v-flex xs12 md10 lg8>
                                    <v-expansion-panel >
                                        <v-expansion-panel-content>
                                            <div slot="header">Do you want to cancel this vouchers?</div>
                                            <v-card>
                                                <v-card-text>
                                                    <v-layout row>
                                                        <v-flex xs2>
                                                            <v-btn color="error" @click="cancelReferralVouchers">Cancel</v-btn>
                                                        </v-flex>
                                                        <v-flex xs2 v-show="cancelMultiple.cancelBusy">
                                                            <v-progress-circular
                                                                    :width="3"
                                                                    color="blue"
                                                                    indeterminate
                                                            ></v-progress-circular>
                                                        </v-flex>
                                                        <v-flex xs6 v-show="cancelMultiple.cancelError">
                                                            <span>Error</span>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-card-text>
                                            </v-card>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-flex>
                            </v-layout>
                            <v-layout row v-if="cancelMultiple.cancelSuccess" class="margin-top-medium">
                                <v-flex xs0 offset-xs2>
                                    <span>{{ cancelMultiple.cancelledCount }} vouchers has been cancelled!</span>
                                </v-flex>
                            </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" flat @click="clearVouchers(true)">Clear</v-btn>
                    <v-btn color="blue darken-1" flat @click="closeCancelMultipleDialog">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
    export default {
        name: 'ies-referral-options',
        // props: ['field'],
        data () {
            return {
                /** data for cancel multiple referral vouchers feature   */
                cancelMultiple: {
                    voucherCode: '',
                    vouchersToCancel: [],
                    cancelMultipleDialogOpen: false,
                    cancelBusy: false,
                    cancelSuccess: false,
                    cancelError: false,
                    cancelledCount: '',
                },
            }
        },
        methods: {

            openCancelMultipleDialog() {
                this.cancelMultiple.cancelMultipleDialogOpen = true;
            },

            closeCancelMultipleDialog() {
                this.cancelMultiple.cancelMultipleDialogOpen = false;
            },

            /**
             * Add voucher code from dialog input to vouchersToCancel array
             */
            addVoucher() {
                let voucherCode = this.cancelMultiple.voucherCode;

                // check if this could be valid voucher code
                if (voucherCode && voucherCode.length === 16) {
                    if (!this.cancelMultiple.vouchersToCancel.includes(voucherCode)) {
                        this.cancelMultiple.vouchersToCancel.push(voucherCode);
                    }
                    this.cancelMultiple.voucherCode = '';
                }
            },

            /**
             * Call our API to cancel entered vouchers
             */
            cancelReferralVouchers() {
                if (this.cancelMultiple.vouchersToCancel.length) {
                    this.clearMultipleVouchersMessages(true);
                    this.cancelMultiple.cancelBusy = true;
                    let dataObj = {action: 'cancel-multiple-referral', data: {vouchers: this.cancelMultiple.vouchersToCancel}};
                    let url = 'ajax/process-vouchers';

                    axios.post(url, dataObj)
                        .then(function(response) {
                            this.cancelMultiple.cancelDone = true;
                            this.cancelMultiple.cancelBusy = false;

                            if(response.data.success) {
                                this.cancelMultipleSuccess(response.data.data.vouchersCancelledCount);
                            }
                            else {
                                this.cancelMultiple.cancelError = true;
                            }
                        }.bind(this));
                }
            },

            /**
             * Handle success response after cancelling the vouchers,
             * Show success message and clear cancelled vouchers
             */
            cancelMultipleSuccess(count) {
                this.cancelMultiple.cancelledCount = parseInt(count);
                this.cancelMultiple.cancelSuccess = true;
                this.clearVouchers(false);
            },

            /**
             * Removes single voucher from vouchers to cancel array
             */
            clearVoucher(index) {
                this.$delete(this.cancelMultiple.vouchersToCancel, index)
            },

            /**
             * Removes all vouchers to cancel from data object
             */
            clearVouchers(clearSuccess) {
                this.cancelMultiple.vouchersToCancel = [];
                this.cancelMultiple.voucherCode = '';
                this.clearMultipleVouchersMessages(clearSuccess);
            },

            /**
             * Clear all messages that are displayed in Cancel Multiple dialog
             */
            clearMultipleVouchersMessages(clearSuccess) {
                this.cancelMultiple.cancelBusy = false;
                this.cancelMultiple.cancelError = false;

                // clear info about success cancellation
                if (clearSuccess) {
                    this.cancelMultiple.cancelSuccess = false;
                    this.cancelMultiple.cancelledCount = '';
                }
            }

        }
    }
</script>

<style>
    .voucherCodesList .icon {
        color: red;
        cursor: pointer;
    }
    .margin-top-medium {
        margin-top: 15px!important;
    }
</style>
