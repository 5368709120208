<template>
    <v-container fluid class="hiddenNNN"> <h2>test</h2>
        <v-layout row-sm column>
            <v-flex xs12>
                <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-flex>
            <v-flex xs12 sm6>
                <input type="hidden" :value="input" :name="this.field.fieldName">
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: 'ies-hidden-input',
        props: ['field'],
        data () {
            return {
                input: this.field.value
            }
        },
        watch: {
            input: function(val) {
                this.field.value = this.input;
            }
        }
    }
</script>


