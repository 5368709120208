<template>
    <v-container fluid>
        <v-layout row wrap>
            <v-flex xs12 sm2>
                <span class="changeTitle">Save action:</span>
            </v-flex>
            <v-flex xs12 sm10>
                <span class="saveAction">{{ record.save_action }}</span>
            </v-flex>

            <v-flex xs12 sm2>
                <span class="changeTitle">Changed at:</span>
            </v-flex>
            <v-flex xs12 sm10>
                <span>{{ getChangedAt(record) }}</span>
            </v-flex>

            <v-flex xs12 sm2>
                <span class="changeTitle">Changed by:</span>
            </v-flex>
            <v-flex xs12 sm10>
                <span>{{ getChangedBy(record) }}</span>
            </v-flex>

            <v-flex xs12 sm2>
                <span class="changeTitle">Changed on:</span>
            </v-flex>
            <v-flex xs12 sm10>
                <span>{{ getChangedOn(record.app_edited) }}</span>
            </v-flex>

            <v-flex xs12 sm2>
                <span class="changeTitle">Changed on page:</span>
            </v-flex>
            <v-flex xs12 sm10>
                <span>{{ record.page_url }}</span>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        props: ['record'],
        data() {
            return {

            }
        },
        methods: {
            /**
             * Return user who made that change
             */
            getChangedBy(record) {
                let changedDisplay = '';

                if (record.hasOwnProperty('createdBy')) {
                    changedDisplay += record.createdBy + ' ';
                }

                if (record.user_key) {
                    changedDisplay +=  'user_key: ' + record.user_key + '';
                }

                return changedDisplay;
            },

            /**
             * Map app_edited which is stored as integer to correct app as string
             */
            getChangedOn(app) {
                let display = app;

                switch (app) {
                    case 1 : display = 'Front page';    break;
                    case 2 : display = 'Portal';        break;
                    case 3 : display = 'Legacy admin';  break;

                }

                return display;
            },

            /**
             * Display creation date and creation time as one string
             */
            getChangedAt(record) {
                let creationDate = record.creation_date;
                let creationTime = record.creation_time;

                return creationDate.replace('00:00:00','') + ' at ' + creationTime;
            },
        }
    }
</script>

<style scoped>
    .changeTitle {
        font-weight: bold;
    }

    .saveAction {
        font-weight: bold;
        color: red;
    }
</style>
