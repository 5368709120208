<template>
                <v-card>
                    <v-container fluid class="px-6 pt-4 pb-8">
						<v-row>
							<v-col md="4">
								<v-text-field v-model="searchText"
												@keyup.enter="onEnter"
												v-on:click="showResults()"
												v-on:keyup="onTextEntry()"
        										tabindex="0"
												></v-text-field>
								<div v-if="facilitySelectOptions.length && shouldShowOptions" class="options-box">
									<ul>
										<li v-for="facility in facilitySelectOptions"
											v-on:click="addFacility(facility.facility_key)">{{ facility.title }}</li>
									</ul>
								</div>
							</v-col>

							<v-col md="5">
								<v-card-actions>
                        			<v-btn class="ies-green" @click="saveOwnedFacilities()" :disabled="!canSave"><v-icon>save</v-icon> Save</v-btn>
                        			<v-btn class="ies-coral" @click="removeAllOwnedFacilities()" :disabled="!ownedFacilities.length">Remove All</v-btn>
                    				<v-btn @click="loadAllFacilities()"><v-icon>refresh</v-icon> Refresh</v-btn>
									<v-btn href="/facilities/list" target="_blank"><v-icon>library_add</v-icon> Library</v-btn>
								</v-card-actions>

							</v-col>
						</v-row>
							
						<v-row wrap>
							<v-col md="2"
									v-for="(facility, facility_key) in ownedFacilities" :key="facility_key"
									@click="removeOwnedFacility(facility.facility_key)">
									{{ facility.title }}
							</v-col>
						</v-row>
                    </v-container>
                </v-card>
</template>

<script>
    /**
     * A Vue component dedicated to letting the user choose which facilities to
     * add to the hotel
     *
     * Props:
     *     int 'facility-type' - The numeric value representing what type of facilities this component is responsible for (e.g dining)
     *     int 'availability'  - Bitwise integer representing whether the facility is available to the entire hotel (=1) or just rooms (=2) or both (=3)
     */

    import axios from 'axios';
    export default {
        name: 'hotel-facility-manager',
        props: ['hotelkey', 'facilityType', 'availability'],
        data () {
            return {
                /** @var {} allFacilities - The full spectrum of facilities that the i-escape system can understand */
                allFacilities:      [],

                /** @var {} ownedFacilities - The full list of facilities that this hotel owns */
                ownedFacilities:    [],

                /** string searchText - what the user typed to search for */
                searchText: '',

                /** @var {} selectedFacility - The identity of the facility to be added to the list */
                selectedFacility:   {},

                facilitySelectOptions: [],

                /** @var boolean canSave - Used to disable the save button if there are no changes */
                canSave:    false,

                /** @var bool shouldShowOptions dictates whether the list of results should show */
                shouldShowOptions: false
            }
        },
        methods: {

            /**
             * @see data allFacilities
             */
            loadAllFacilities(){
                axios.get('/api/facilities/', {'params': { 'facilityCategory': this.facilityType } } )
                .then(function(response){
                    this.allFacilities = response.data;
                    this.updateOptions();
                }.bind(this));
            },

			handleFocusOut() {
				this.shouldShowOptions = this.shouldShowOptions ? false : true;
			},

            /**
             * @see data ownedFacilities
             */
            loadOwnedFacilities(){
                axios.get('/api/hotel-facilities/' + this.hotelkey, { 'params': { 'facilityCategory': this.facilityType } })
                .then(function(response){
                    this.ownedFacilities = response.data;
                }.bind(this));
            },

            removeAllOwnedFacilities(){
                this.ownedFacilities = [];
                this.canSave = true;
            },

            /**
             * @param int facilityKey
             */
            removeOwnedFacility(facilityKey){
                for (var index = 0; index < this.ownedFacilities.length; index++){
                    var ownedFacility = this.ownedFacilities[index];
                    if (ownedFacility.facility_key == facilityKey){
                        this.ownedFacilities.splice(index, 1);
                        this.canSave = true;
                    }
                }
            },

            showResults: function(){
                this.shouldShowOptions = this.shouldShowOptions ? false : true;
            },

            hideResults: function(){
                this.shouldShowOptions = false;
            },

            onTextEntry: function(){
                this.updateOptions();

                if (this.searchText){
                    this.showResults();
                }
                else {
                    this.hideResults();
                }
            },

            updateOptions: function(){
                 var options = [];
                 if (Object.keys( this.allFacilities ).length){
                     for(var facilityKey in this.allFacilities){
                         var facility = this.allFacilities[facilityKey];
                         if (facility.title.toLowerCase().includes(this.searchText.toLowerCase())){
                             options.push(facility);
                         }
                     }
                 }
                 this.facilitySelectOptions = options;
            },

            /**
             * @param int facilityKey
             */
            addFacility: function(facilityKey){
                var _this = this;
                this.allFacilities.forEach(function(facility){
                    if (facility.facility_key == facilityKey){
                        _this.ownedFacilities.push(facility);
                        _this.canSave = true;
                    }
                });

                // Reset ready for the next one
                this.searchText = '';
            },

            onEnter: function(){
                console.log("enter detected, lengfth", this.facilitySelectOptions.length);
                if (this.facilitySelectOptions.length > 0){
                    this.addFacility(this.facilitySelectOptions[0].facility_key);
                    this.facilitySelectOptions = [];
                }
            },

            /**
             * Makes a POST request to the API to save the ownership of the
             * facilities that the user has chosen
             */
            saveOwnedFacilities(){
                // No point passing the objects when all we need are their IDs
                var keyList = [];
                this.ownedFacilities.forEach(function(facility){
                    keyList.push(facility.facility_key);
                });
                console.log(keyList);

                // Submit via POST
                axios.post('/api/hotel-facilities/' + this.hotelkey, { params: { facilityCategory: this.facilityType, facilityKeys: keyList, availability: this.availability ? this.availability : 1 } })
                .then(function(response){
                    alert('Saved');
                    this.canSave = false;
                }.bind(this));
            },

        },

        /**
         * One mounted this component needs to load all available models And
         * al those owned by this hotel
         *
         * @see data filterParams - This is set once based on the props passed in
         * but can be changed dynamically if needed to allow any type of searching
         * in future versions
         */
        mounted() {
            // Obtain all facilities the i-escape system understands
            this.loadAllFacilities();

            // Obtain a list of the facilities this hotel actually owns
            this.loadOwnedFacilities();
        },

        computed: {

            /**
             * Returns an array of objects to be used by the view to populate
             * theselect box with options reflecting the facilities the users
             * can choose from
             */
/*
             facilitySelectOptions(){
                 var options = [];
                 if (Object.keys( this.allFacilities ).length){
                     for(var facilityKey in this.allFacilities){
                         var facility = this.allFacilities[facilityKey];
                         options.push({ text: facility.title, value: facility.facility_key });
                     }
                 }
                 return options;
             }
*/
        }
    }
</script>

<style>
    .options-box {
        position: absolute;
        z-index: 100;
        background: white;
        display: block;
        border: solid 1px lightgray;
        padding: 1rem;
    }
</style>
