<template>
<v-footer class="pa-6 no-print mt-3" height="auto">
    <v-layout justify-center row wrap>
        <v-flex
            lighten-2
            text-xs-center
            xs12
        >
            <v-flex xs12>i-escape.com (Ram Tinto Associates Ltd)</v-flex>
            <v-flex>23 Westfield Park, Bristol, BS6 6LT, United Kingdom</v-flex>
            <v-flex>
               <v-icon>alternate_email</v-icon> <a href="mailto:info@i-escape.com">info@i-escape.com</a> |
               <v-icon>phone</v-icon> <a href="tel:00441179467072">+44 117 946 7072</a> (general enquiries)
            </v-flex>
            <v-flex>9am-6pm Monday-Thursday, 9am-5pm Friday (UK times)</v-flex>
        </v-flex>
    </v-layout>
</v-footer>
</template>

<script>
    export default {
            props:[],
            data: function(){
                return {};
            },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){},

        methods: {},

        computed: {}
    }
</script>
