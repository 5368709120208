<template>
    <v-col cols="12" md="6" lg="4" ma-1 v-if="allIssues.length">
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">assignment</v-icon>Issues <span v-show="!showProgressIcon"> ({{ allIssues.length }})</span>
            </v-card-title>
        
                <v-card-text >
                    <v-progress-linear :indeterminate="true" v-show="showProgressIcon" color="lime"></v-progress-linear>
                    <div v-show="showProgressIcon">Loading...</div>
                    <div v-if="!showProgressIcon && allIssues.length == 0">No issues require your attention right now</div>

                    <v-list>
                        <template v-for="(item, index) in data">
                            <v-list-item ripple v-bind:key="index" :href="item.url">
                                <v-list-item-title>
                                    <a :href="item.url" :class="{ 'priority-medium': item.priority > 5 && item.priority < 8, 'priority-high': item.priority > 7 }"><v-icon left medium>{{item.icon}}</v-icon> {{ item.description }}</a>
                                </v-list-item-title>
                            </v-list-item>
                            <v-divider v-if="index + 1 < data.length"></v-divider>
                        </template>
                    </v-list>
                </v-card-text>

        </v-card>
    </v-col>
</template>

<script>
    import axios from 'axios';

    export default {
        props:['type', 'pageid'],
        data: function() {
            return {
                allIssues: [],
                data: [],
                showProgressIcon: true,
                snackbar: false
            }
        },
        created:function() {
            this.fetchIssues();
            setInterval( this.fetchIssues ,60000 );
        },
        methods:{
            fetchIssues:function(){
                axios.get('/api/issues/hotel/' + this.pageid)
                .then(function(response){
                   this.allIssues = response.data.issues;
                   this.data = response.data.issues.slice(0, 10);
                   this.showProgressIcon = false;
                }.bind(this));
            }
        }
    }
</script>

<style>
a:link .priority-high {
    color: #f44336!important;
}

a:link .priority-medium {
    color: orange !important;
}
</style>
