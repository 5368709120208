<template>
    <v-col cols="12" sm="6" md="4" lg="3" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">card_giftcard</v-icon>Voucher Editor
            </v-card-title>
        
            <v-card-text class="body-2">
                <div v-if="systemError">Sorry, this content is not working</div>
                <div v-if="foundVoucher === false">Sorry, no voucher could be found with this code</div>

                <!-- Overview of selected voucher -->
                <div v-if="voucher" class="m-3">
                    <div>
                        <v-container>
                            <v-layout row wrap>
                                <v-col cols="4">Code</v-col>
                                <v-col cols="8">{{ voucher.voucher_code }}</v-col>
                                <v-col cols="4">Value (GBP)</v-col>
                                <v-col cols="8">{{ voucher.value }}</v-col>
                                <v-col cols="4">Expiry:</v-col>
                                <v-col cols="8">{{ voucher.expires }}</v-col>
                                <v-col cols="4">Notes:</v-col>
                                <v-col cols="8">{{ voucher.notes }}</v-col>
                                
                                <v-col cols="4">Status</v-col>
                                <v-col cols="8">{{ voucher.statusText }}</v-col>
                            </v-layout>
                        </v-container>
                    </div>

                    <!-- Options available for this voucher -->
                    <div>
                        <v-btn :href="editUrl" 
                               class="ies-green">View</v-btn>
                        <v-btn v-if="voucher.is_active && !voucher.is_cancelled" 
                               v-on:click="cancelVoucher()"><v-icon>cancel</v-icon> Cancel</v-btn>
                        <v-btn v-if="!voucher.is_active && !voucher.redeemed" 
                               v-on:click="reactivate()">Reactivate</v-btn>
                        <v-btn v-if="voucher.voucher_type == 1 && voucher.is_active && !voucher.redeemed" 
                               v-on:click="resendVoucher()"><v-icon>send</v-icon> Resend</v-btn>
                    </div>
                </div>

                <!-- Cancellation dialogue -->
                <div v-if="!systemError && mode == 'cancel'" class="mt-3">
                    <div>Please enter a cancellation note explaining why this voucher is being cancelled</div>
                    <textarea type="text"
                              v-model="reason"></textarea>
                    <v-btn v-on:click="submitCancellation()" class="ies-red"><v-icon>cancel</v-icon> Cancel Voucher</v-btn>
                </div>

                <!-- Reactivation dialogue -->
                <div v-if="!systemError && mode == 'reactivate'">
                    <div>Please choose a new expiry date:</div>
                                    <v-date-picker
                                        v-model="newExpiry"
                                        color="lime"
                                        type="date"
                                    ></v-date-picker>
                    <div>Please enter a note explaining why this voucher is being reactivated</div>
                    <textarea type="text"
                              v-model="reason"></textarea>
                        <v-btn v-if="!voucher.is_active && !voucher.redeemed" 
                               class="ies-green"
                               v-on:click="reactivateVoucher()">Reactivate</v-btn>
                </div>
            </v-card-text>

            <v-card-actions>
                <v-text-field
                    placeholder="Voucher code"
                    v-model="voucherCode"
                ></v-text-field>
                <v-btn v-on:click="performSearch()"><v-icon>search</v-icon> Search</v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
    import axios from 'axios';

    export default {
        props:[],

        data: function() {
            return {
                voucherCode: "",
                voucher: null,
                systemError: false,
                foundVoucher: null,
                mode: "view",
                reason: ""
            }
        },

        /**
         * On mounted
         */
        mounted: function(){},

        methods: {
            /**
             */
            performSearch(){
console.log("performing search: ", this.voucherCode);
              this.systemError = false;
              this.mode = "view";
              this.voucher = null;
              this.reason = '';
              this.foundVoucher = null;

              try {
              if (this.voucherCode){
                axios.get('/api/voucher/?code='+this.voucherCode)
                .then((response)=>{
                    if (response.data.success){
                        this.voucher = response.data.voucher;

                        this.voucher.statusText = this.getVoucherStatusText(this.voucher);

                        this.foundVoucher = true;
                    }
                    else {
                        this.foundVoucher = false;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
              }
              } catch(exception){
                  this.systemError = true;
                  console.log(exception);
              }
            },

            getVoucherStatusText(voucher){
                if (voucher.redeemed){
                    return "Redeemed";
                }
                if (voucher.is_cancelled){
                    return "Cancelled";
                }
console.log(Date.parse(voucher.expires));
                if (Date.parse(voucher.expires) <= Date.now()){
                    return "Expired";
                }
                return voucher.is_active ? "Active" : "Inactive";
            },

            
            cancelVoucher(){
                this.mode = "cancel"; 
                this.reason = '';
            },
            submitCancellation(){
                var requestConfig = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };

                axios.post('/api/voucher', {action:"cancel", code:this.voucherCode, reason: this.reason}, requestConfig)
                .then((response)=>{
                    // If it was successful then reload the voucher details so it is 
                    // displayed up to date on the page
                    if (response.data.success){
                        this.voucherCode = this.voucher.voucher_code;
                        this.performSearch();
                    }
                    // If it failed then simply tell them so
                    else {
                        alert("A problem occurred and the voucher could not be cancelled" + (response.data.reason ? ".\nReason: "+response.data.reason : ""));
                    }
                });
            },
            resendVoucher(){
                axios.post('/ajax/resend-voucher',{ voucher_key : this.voucher.voucher_key })
                   .then(function(response) {
                       
                       if (response.data.success) {
                           alert("The voucher will be resent to its recipient");
                           this.voucherCode = this.voucher.voucher_code;
                           this.performSearch();
                       }
                       else {
                           alert("A problem occurred and the voucher could not be resent");
                       }
                   }.bind(this));

            },
            reactivate(){
                this.mode = "reactivate";
                this.reason = '';
            },

            /**
             * Trigger reactivation of cancelled voucher,
             * voucher can be reactivated only if it was cancelled, we cannot reactivate redeemed/expired vouchers
             */
            reactivateVoucher() {
                var requestConfig = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };

                axios.post('/api/voucher', {action:"reactivate", code:this.voucherCode, newExpiry: this.newExpiry, reason: this.reason}, requestConfig)
                .then((response)=>{
                    // If it was successful then reload the voucher details so it is 
                    // displayed up to date on the page
                    if (response.data.success){
                        this.voucherCode = this.voucher.voucher_code;
                        this.performSearch();
                    }
                    // If it failed then simply tell them so
                    else {
                        alert("A problem occurred and the voucher could not be reactivated" + (response.data.reason ? ".\nReason: "+response.data.reason : ""));
                    }
                });
                
            },

        },

        computed: {
           // The URL for viewing the voucher in its main edit page
           editUrl(){
               return "/Vouchers/" + this.voucher.voucher_key;
           }
        }
    }
</script>
