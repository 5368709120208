<template>
<v-container fluid>
    <h1>UPF Invoicing</h1>

    <v-layout justify-center pa-4>
        <v-flex shrink text-xs-right mx-2>
            <v-date-picker 
                type="month"
                landscape
                color="lime lighten-1"
                @change="isSubmitted = false"
                v-model="queryParams.date" 
            />
        </v-flex>
        <v-flex shrink style="min-width:180px;">
            <span class="subheader">Regions</span>
            <v-checkbox label="United Kingdom" @change="isSubmitted = false" v-model="queryParams.uk" />
            <v-checkbox label="EU Vatable" @change="isSubmitted = false" v-model="queryParams.eu_vat" />
            <v-checkbox label="EU Non-Vatable" @change="isSubmitted = false" v-model="queryParams.eu_novat" />
            <v-checkbox label="Rest of World" @change="isSubmitted = false" v-model="queryParams.rest_world" />
        </v-flex>

        <v-flex shrink style="min-width:180px;">
            <span class="subheader">Payment Method</span>
            <v-checkbox label="Visa" @change="isSubmitted = false" v-model="queryParams.visa" />
            <v-checkbox label="Mastercard" @change="isSubmitted = false" v-model="queryParams.mastercard" />
            <v-checkbox label="American Express" @change="isSubmitted = false" v-model="queryParams.amex" />

            <hr /><br />

             <v-switch
                :label="`Group By Day: ${queryParams.groupByDay ? '✔' : '✘'}`"
                @change="isSubmitted = false"
                v-model="queryParams.groupByDay"
            ></v-switch>
        </v-flex>
       
    </v-layout>
    <v-layout justify-center pa-2>
            <v-btn :class="{'lime' : !isSubmitted}" @click="getReport">Submit</v-btn>
            <export-csv
                :filename="`${queryParams.date}-UPF-invoicing`"
                :data="report" 
            />
        </v-layout>

    <v-card>
        <v-card-title style="background: gainsboro;">
            Summary
        </v-card-title>
        <v-data-table
            :headers="summaryHeaders"
            :items="[this.report]"
            :loading="isLoading"
            hide-default-footer
            class="elevation-1"
        >
            <v-progress-linear slot="progress" color="lime lighten-1" indeterminate />
            <template v-slot:item="props">
              <tr>
                <td class="text-xs-center">{{ props.item.count }}</td>
                <td class="text-xs-center">£{{ props.item.sum_quotes.toFixed(2) }}</td>
                <td class="text-xs-center">£{{ props.item.sum_deposits.toFixed(2) }}</td>
                <td class="text-xs-center">£{{ props.item.sum_commissions.toFixed(2) }}</td>
                <td class="text-xs-center">£{{ props.item.sum_vouchers.toFixed(2) }}</td>
                <td class="text-xs-center">£{{ props.item.sum_vans.toFixed(2) }}</td>
                <td class="text-xs-center">£{{ props.item.sum_cc_transactions.toFixed(2) }}</td>
                <td class="text-xs-center">£{{ props.item.sum_sh_transactions.toFixed(2) }}</td>
                <td class="text-xs-center">£{{ props.item.sum_invoiced.toFixed(2) }}</td>
              </tr>
            </template>
        </v-data-table>
    </v-card>

    <v-card>
        <v-card-title style="background: gainsboro;">
            Detail
        </v-card-title>
        <v-data-table
            :headers="tableHeaders"
            :items="this.report.data"
            :loading="isLoading"
            hide-default-footer
            class="elevation-1"
        >
            <v-progress-linear slot="progress" color="lime lighten-1" indeterminate />
            <template v-slot:item="props">
                <td class="text-xs-center">{{ formattedDateDDMMYYYY(props.item.date) }}</td>
                <td class="text-xs-center">{{ props.item.reference }}</td>
                <td class="text-xs-center">£{ { props.item.quotes.toFixed(2) } }</td>
                <td class="text-xs-center">£{{ props.item.deposit.toFixed(2) }}</td>
                <td class="text-xs-center">£{{ props.item.commission.toFixed(2) }}</td>
                <td class="text-xs-center">{{ props.item.is_prepaid }}</td>
                <td class="text-xs-center">£{{ props.item.voucher.toFixed(2) }}</td>
                <td class="text-xs-center">{{ props.item.payment_method }}</td>
                <td class="text-xs-center">£{{ props.item.cc_transaction.toFixed(2) }}</td>
                <td class="text-xs-center">£{{ props.item.sh_transaction.toFixed(2) }}</td>
                <td class="text-xs-center">£{{ props.item.van.toFixed(2) }}</td>
                <td class="text-xs-center">£{{ props.item.invoiced.toFixed(2) }}</td>
            </template>
        </v-data-table>
    </v-card>

</v-container>    
</template>

<script>
import ExportCsv from '@/components/common/ExportCSV';

export default {
    components: {
        ExportCsv
    },
    data() {
        return {
            isSubmitted: true,
            isLoading: false,
            tableHeaders: [],
            report: {},
            queryParams: {
                date: '',
                // Regions
                uk: true,
                eu_vat: true,
                eu_novat: true,
                rest_world: true,
                //Payment Methods
                visa: true,
                mastercard: true,
                amex: true,
                // Grouping
                groupByDay: false
            }
        }
    },
    created() {
        this.setSummaryHeaders();
        this.setTableHeaders();
        this.setDefaultDate();
        this.getReport();
    },

    methods: {
        /**
         * getReport makes an ajax request to retrieve the report
         */
        async getReport() {
            this.isLoading = !this.isLoading;
            const res = await axios('/api/accounts/upf-invoicing', {
                method: 'GET',
                params: { ...this.queryParams }
            });

            this.report = res.data;
            this.isLoading = !this.isLoading;
        },
        /**
         * setDefaultDate gets today's year and month and sets it
         * as the date to constrain report by on page load
         */
        setDefaultDate() {
            const date = new Date();
            this.queryParams.date = `${date.getFullYear()}-${date.getMonth() + 1}`;
        },
        /**
         * summaryHeaders created the config array
         * for the summary table headers
         */
        setSummaryHeaders() {
            this.summaryHeaders = [
                {
                    text: 'Count',
                    align: 'center',
                    sortable: false,
                    value: 'count'
                },
                {
                    text: 'Quotes',
                    align: 'center',
                    sortable: false,
                    value: 'sum_quotes'
                },
                {
                    text: 'Deposit',
                    align: 'center',
                    sortable: false,
                    value: 'sum_deposit'
                },
                {
                    text: 'Commission',
                    align: 'center',
                    sortable: false,
                    value: 'sum_commission'
                },
                {
                    text: 'Vouchers',
                    align: 'center',
                    sortable: false,
                    value: 'sum_vouchers'
                },
                {
                    text: 'Vans',
                    align: 'center',
                    sortable: false,
                    value: 'sum_vans'
                },
                {
                    text: 'CC Transactions',
                    align: 'center',
                    sortable: false,
                    value: 'sum_cc_transactions'
                },
                {
                    text: 'SH Transactions',
                    align: 'center',
                    sortable: false,
                    value: 'sum_sh_transactions'
                },
                {
                    text: 'Invoiced',
                    align: 'center',
                    sortable: false,
                    value: 'sum_invoiced'
                },
            ];
        },
        /**
         * setTableHeaders creates the config array
         * for the table headers
         */
        setTableHeaders() {
            this.tableHeaders = [
                {
                    text: 'Date',
                    align: 'center',
                    sortable: false,
                    value: 'date'
                },
                {
                    text: 'Reference',
                    align: 'center',
                    sortable: false,
                    value: 'reference'
                },
                {
                    text: 'Quote',
                    align: 'center',
                    sortable: false,
                    value: 'quote'
                },
                {
                    text: 'Deposit',
                    align: 'center',
                    sortable: false,
                    value: 'deposit'
                },
                {
                    text: 'Commission',
                    align: 'center',
                    sortable: false,
                    value: 'commission'
                },
                {
                    text: 'Prepaid',
                    align: 'center',
                    sortable: false,
                    value: 'is_prepaid'
                },
                {
                    text: 'Voucher',
                    align: 'center',
                    sortable: false,
                    value: 'voucher'
                },
                {
                    text: 'Payment Method',
                    align: 'center',
                    sortable: false,
                    value: 'payment_method'
                },
                {
                    text: 'CC Transaction',
                    align: 'center',
                    sortable: false,
                    value: 'cc_transaction'
                },
                {
                    text: 'SH Transaction',
                    align: 'center',
                    sortable: false,
                    value: 'sh_transaction'
                },
                {
                    text: 'Van',
                    align: 'center',
                    sortable: false,
                    value: 'van'
                },
                {
                    text: 'Invoiced',
                    align: 'center',
                    sortable: false,
                    value: 'invoiced'
                },

            ]
        },


        formattedDateDDMMYYYY(dateYYYYMMDD){
            console.log(dateYYYYMMDD);
            if (!dateYYYYMMDD || dateYYYYMMDD == '0000-00-00'){
                return '';
            }
            var date = new Date(Date.parse(dateYYYYMMDD));
            var day = date.getDate();
            var month = date.getMonth() + 1;
            var year = date.getFullYear();
            return (day < 10 ? '0'+day : day) + '/' + (month < 10 ? '0'+month : month) + '/' + year;
        }
    }
}
</script>


