var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-expansion-panel",
        [
          _c(
            "v-expansion-panel-content",
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Fields changes:\n                "),
                !_vm.changesObject.length
                  ? _c("span", { staticClass: "changesNotFound" }, [
                      _vm._v(" Changes not found"),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.changesObject.length
                ? _c(
                    "v-card",
                    { staticClass: "fieldChanges" },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "columnDescription",
                          attrs: { row: "", wrap: "" },
                        },
                        [
                          _c("v-flex", { attrs: { xs3: "" } }, [
                            _c("span", [_vm._v("Field:")]),
                          ]),
                          _vm._v(" "),
                          _c("v-flex", { attrs: { xs2: "" } }, [
                            _c("span", [_vm._v("Changed From:")]),
                          ]),
                          _vm._v(" "),
                          _c("v-flex", { attrs: { xs7: "" } }, [
                            _c("span", [_vm._v("Changed To:")]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.changesObject, function (field, key) {
                        return _c(
                          "v-layout",
                          { key: key, attrs: { row: "", wrap: "" } },
                          [
                            _c("v-flex", { attrs: { xs3: "" } }, [
                              _c("span", [_vm._v(_vm._s(field.fieldName))]),
                            ]),
                            _vm._v(" "),
                            _c("v-flex", { attrs: { xs2: "" } }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.parseValue(field.old_value))),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("v-flex", { attrs: { xs7: "" } }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.parseValue(field.new_value))),
                              ]),
                            ]),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }