var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "3" } },
    [
      _c(
        "v-card",
        { staticClass: "ma-1" },
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("text_format")]),
              _vm._v(" Site Texts\n    "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-btn",
                { attrs: { block: "", href: "/site-texts/create" } },
                [_vm._v("Add New Site text")]
              ),
              _vm._v(" "),
              _c("v-btn", { attrs: { block: "", href: "/site-texts/list" } }, [
                _vm._v("Site Text All"),
              ]),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    block: "",
                    href: "/site-texts/list?s=qs&value=home",
                  },
                },
                [_vm._v("Site Text Homepage")]
              ),
              _vm._v(" "),
              _c("v-btn", { attrs: { block: "", disabled: "", href: "" } }, [
                _vm._v("Site Text My Escapes"),
              ]),
              _vm._v(" "),
              _c("v-btn", { attrs: { block: "", disabled: "", href: "" } }, [
                _vm._v("Site Text TG & PTS"),
              ]),
              _vm._v(" "),
              _c("v-btn", { attrs: { block: "", disabled: "", href: "" } }, [
                _vm._v("Site Text News"),
              ]),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    block: "",
                    href: "/site-texts/list?s=qs&value=about_us",
                  },
                },
                [_vm._v("Site Text About Us")]
              ),
              _vm._v(" "),
              _c("v-btn", { attrs: { block: "", href: "/site-images/list" } }, [
                _vm._v("Site Images"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }