
<template>
<tr
    @mousedown="handleMouseDown(rateColumn)"
    @mouseup="handleMouseUp(rateColumn)"
    :disabled="disabled"    
    >
        <td>
            <button class="interactive rate-for-date">
                {{ rowHeader }}
            </button>
        </td>
        <template v-for="(date) in calendarDates">
            <td 
            v-bind:class="{
                'disabled-cell': dateIsOutOfRange(date.date),
                'disabled-rate-cell': dateIsOutOfRange(date.date),
                'selected' : rateIsSelected(rateColumn) && !dateIsOutOfRange(date.date),
                'updated' : rateIsUpdated(rateColumn) && !dateIsOutOfRange(date.date)
            }">
                <button :class="{
                    'interactive' : true, 
                    'rate-for-date': true,
                    'disabled-cell': dateIsOutOfRange(date.date),
                    }">
                    {{ rowValue }}
                </button>
            </td>
        </template>
    </tr>
</template>
                                      
<script>
/**
* A Vue component dedicated to letting the user control availability and rates per
* hotel room for a given date range
*
* Props:  hotelkey
*/

export default {
    name: 'hotel-planner-universal-rate-row',
    props: [
        'disabled',
        'rowHeader',
        'rowValue',
        'rateColumn',
        'calendarDates',
        'datesWithinRange',
        'savedRates',
        'unsavedRates',
        'selectedRateColumn',
        ],
    
    methods: {
        handleMouseDown(rateColumn){
            this.$emit('update:selectedRateColumn', rateColumn);
        },
        
        handleMouseUp(){
            this.$emit('openEditUniversalRates', true);
        },
        
        rateIsSelected(rateColumn){
            return this.selectedRateColumn == rateColumn;
        },

        rateIsUpdated(rateColumn){
            return this.savedRates[rateColumn] != this.unsavedRates[rateColumn];
        },

        dateIsOutOfRange(date){
            let outOfRange = true
            date = new Date(date);
            this.datesWithinRange.forEach(rangeDate => {
                let sameYear = rangeDate.getFullYear() == date.getFullYear();
                let sameMonth = rangeDate.getMonth() == date.getMonth();
                let sameDay = rangeDate.getDate() == date.getDate();
                if (sameYear && sameMonth && sameDay){
                    outOfRange = false;
                }
            });
            return outOfRange;
        },
    },
}

</script>
