<template>
<v-container 
    v-if="shouldDisplay" 
    class="smart-search-results"
    >
    <v-btn class="close-button" v-on:click="closeResults">X</v-btn>
    <v-layout row wrap>
        <v-col cols="12" v-if="!results.length">
            No results found
        </v-col>

        <v-col cols="12" v-if="results.length" v-for="group in results">
            <div class="smart-search-results--group-label">{{ group.label }}</div>
            <v-layout row wrap style="max-height: 10rem; overflow-y: auto; margin-top: 5px;">
                <v-col cols="12" v-for="item in group.items"><a :href="item.url">{{ item.label }}</a></v-col>
            </v-layout>
        </v-col>
    </v-layout>
</v-container>
</template>

<script>
    export default {
            props:[],
            data: function(){
                return {
                    searchText: '',
                    searchTool: null,
                    results: [],
                    shouldDisplay: false,
                };
            },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            var self = this;
            this.$root.$on('Smart Search: Search Complete', function(payload){
                console.log("results received", payload);
                self.results = payload.results;
                self.searchTool = payload.searchTool;
                self.shouldDisplay = true;
            });
        },

        methods: {
            closeResults: function(){
                this.shouldDisplay = false;
                this.clearResults();
            },

            clearResults: function(){
                this.results = [];
            }
        },

        computed: {}
    }
</script>
<style>
    .smart-search-results { 
        display: block;
        position: relative;
        width: 100%;
        height: auto;
        max-height: 600px;
        background-color: white;
        overflow-y: auto;
        border: solid 1px lightgray;
        z-index: 99;
    }

    .smart-search-results--group-label {
        font-size: 1.2rem;
        font-weight: bold;
    }

    .close-button {
       position: absolute;
       top: 0;
       right: 0;
    }
</style>
