<script>
    /**
     * Wrapper for Vue-ChartJS, providing fully automated dynamic radar chart support for the data set named in the prop
     *
     *
     */

    // Import the application event bus so we can listen for data changes and react accordingly
    import EventBus from '../../../eventBus';

    // Import Vue-ChartJS to perform the rendering for us
    import VueCharts from 'vue-chartjs';
    import { Radar } from 'vue-chartjs';


    export default{
        extends:    Radar,
        props:      ['datasource', 'title', 'sectionindex', 'viewid', 'config', 'content'],
        data:       function() {
            return {
                data:       {},
                metaData:   {},
                page:       {},
                report:     {},
                loaded:     false,
            }
        },

        methods:    {
            getLabels: function(){
                var labels = [];
                var self = this;
                this.data.data.forEach(function(record){
                    labels.push(record[self.config.fieldNameA]);
                });
                return labels;
            },

            getValues: function(){
                var values = [];
                var self = this;
                this.data.data.forEach(function(record){
                    values.push(record[self.config.fieldNameB]);
                });
                return values;
            },

            /**
             * Request VueChartJS re-render the chart
             */
            updateView: function(){
                // Overwriting base render method with actual data.************
                this.renderChart({
                    labels:                  this.getLabels(),
                    datasets: [
                        {
                            label:           this.metaData.title,
                            //backgroundColor: ['#C1CD23', '#F15C61', '#088193', '#23C1CD', '#61F15C', '#CC99FF', '#ff9966', '#ff99ff', '#5C61F1', '#819308', '#FFFF66', '#CCFFFF'],
                            backgroundColor: '#C1CD23',
                            data:            this.getValues()
                        }
                    ]
                },
                {responsive: true, maintainAspectRatio: false}
                )
            },

        },


        /**
         * Tasks to perform once the Vue component has been constructed and page load event is fired
         *
         */
        mounted () {
            this.data.data = this.content;
            this.updateView();
        },

        /**
         * Tasks to perform when data variables are changed.  This cannot be done by computed properties in this case because we need to call a function
         * to perform the re-render within the parent VueChartJS component
         */
        watch:{
            /**
             * If the main data set is updated then redraw the graph
             */
            data: function(){
                this.updateView();
            }
        },
}
</script>
