var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { md: "6", lg: "4", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("hotel")]),
              _vm._v(" Reservation Statistics\n        "),
            ],
            1
          ),
          _vm._v(" "),
          _vm.systemError
            ? _c("div", [_vm._v("Sorry, this content is not working")])
            : _vm._e(),
          _vm._v(" "),
          !_vm.systemError && _vm.loading
            ? _c("div", [_vm._v("Loading...")])
            : _vm._e(),
          _vm._v(" "),
          !_vm.systemError && !_vm.loading
            ? _c(
                "div",
                [
                  _c("line-chart", {
                    attrs: {
                      chartdata: _vm.chartData,
                      options: _vm.chartOptions,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _vm._v(
                              "\n                        Total: " +
                                _vm._s(_vm.chartData.count_total) +
                                "\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("v-col", [
                            _vm._v(
                              "\n                        Instant: " +
                                _vm._s(_vm.chartData.count_instant) +
                                "\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("v-col", [
                            _vm._v(
                              "\n                        On Request: " +
                                _vm._s(_vm.chartData.count_request) +
                                "\n                    "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }