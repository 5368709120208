<template>
    <div>
        <!-- list must be list of ['text' => x, 'value' => y] -->
        <v-select
            v-model="viewValue"
            :label="field.schema.displayName"
            :items="messagings"
            autocomplete
        ></v-select>
        <input type="hidden" :name="field.fieldName" :value="field.value">
        <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
            <li v-for="error in field.errors" v-bind:key="error">
                {{ error }}
            </li>
        </v-alert>
    </div>

</template>

<script>
    /**
     * Allows the selection of a messaging type ID by choosing its name from
     * a selection downloaded from the API on creation
     */
    export default {
        name: 'ies-messaging',
        props: ['field'],
        data() {
            return {
                messagings: [],
                viewValue: this.field.value,
            }
        },
        created:function() {
            this.getMessagings();
        },
        methods: {
            getMessagings:function(){
                axios.get('/api/messaging/selectable-message-templates')
                .then(function(response){
                    console.log(response);
                    var messagings = [];
                    response.data.forEach(function(messaging){
                        // The issue here is that the original code used a string field instead of the ID number of the foreign key
                        // so until that is rectified at source we need to reference the title instead of the ID in the value
                        //messagings.push({ text: messaging.title, value: messaging.messaging_key });
                        messagings.push({ text: messaging.title, value: messaging.title });
                    });
                    this.messagings = messagings;
                }.bind(this));
            },
        },
        watch: {
            viewValue: function (newValue) {
                this.field.value = newValue;
            }
        }
    }
</script>
