<template>
    <v-col cols="12">
    <v-card class="ma-2">
        <v-card-title class="title font-weight-black">
            <v-icon class="pr-3">home</v-icon> Create New Hotel
        </v-card-title>

        <v-card-text>
            <v-container>
              <fieldset>
                  <legend>Create New Hotel</legend>
                  <v-layout row wrap>

                      <v-col cols="3"><v-label>Hotel Name:</v-label></v-col>
                      <v-col cols="8">
                          <v-text-field v-model="hotelName" label="Hotel Name"></v-text-field>
                      </v-col>

                      <v-col cols="3"><v-label>Your Name:</v-label></v-col>
                      <v-col cols="8">Skipped for now, as we are unsure how to determine which field(s) it would go into</v-col>

                      <v-col cols="3"><v-label>Editorial Region:</v-label></v-col>
                      <v-col cols="8">
                          <v-select
                              v-model="regionKey"
                              label="Region"
                              :items="regions"
                              autocomplete
                          ></v-select>
                      </v-col>

                      <v-col cols="3"><v-label>Address 1:</v-label></v-col>
                      <v-col cols="8"><v-text-field v-model="address1" label="Address 1"></v-text-field></v-col>

                      <v-col cols="3"><v-label>Address 2:</v-label></v-col>
                      <v-col cols="8"><v-text-field v-model="address2" label="Address 2"></v-text-field></v-col>

                      <v-col cols="3"><v-label>City:</v-label></v-col>
                      <v-col cols="8"><v-text-field v-model="city" label="City"></v-text-field></v-col>

                      <v-col cols="3"><v-label>Post Code:</v-label></v-col>
                      <v-col cols="8"><v-text-field v-model="postCode" label="Post Code"></v-text-field></v-col>

                      <v-col cols="3"><v-label>Postal Address:</v-label></v-col>
                      <v-col cols="8"><v-text-field v-model="postalAddress" label="Postal Address"></v-text-field></v-col>

                      <v-col cols="3"><v-label>Website:</v-label></v-col>
                      <v-col cols="8"><v-text-field v-model="website" label="Website"></v-text-field></v-col>

                      <v-col cols="3"><v-label>Reservations contact:</v-label></v-col>
                      <v-col cols="8">
                            <v-text-field v-model="reservationsName" label="Reservations Contact Name"></v-text-field>
                            <v-text-field v-model="reservationsEmail" label="Reservations Email"></v-text-field>
                            <v-text-field v-model="reservationsPhone" label="Reservations Phone"></v-text-field>
                            <v-checkbox hide-details v-model="reservationsSMS" label="Send SMS Notifications"></v-checkbox>
                      </v-col>

                      <v-col cols="3"><v-label>Secondary Email:</v-label></v-col>
                      <v-col cols="8">Will skip this as it is not determinable which field it would go into</v-col>

                      <v-col cols="3"><v-label>Main Contact Name:</v-label></v-col>
                      <v-col cols="8"><v-text-field v-model="keyContact" label="Main Contact Name"></v-text-field></v-col>

                      <v-col cols="3"><v-label>Marketing Contact Name:</v-label></v-col>
                      <v-col cols="8"><v-text-field v-model="marketingName" label="Marketing Contact Name"></v-text-field></v-col>

                      <v-col cols="3"><v-label>Marketing Email:</v-label></v-col>
                      <v-col cols="8"><v-text-field v-model="marketingEmail" label="Marketing Email"></v-text-field></v-col>

                      <v-col cols="3"><v-label>Owner:</v-label></v-col>
                      <v-col cols="8"><v-text-field v-model="owner" label="Owner Name"></v-text-field></v-col>

                      <v-col cols="3"><v-label>GPS Location (Latitude, Longitude):</v-label></v-col>
                      <v-col cols="8"><v-text-field v-model="googleMapPosition" label="GPS"></v-text-field></v-col>

                      <v-col cols="3"><v-label>Detailed directions:</v-label></v-col>
                      <v-col cols="8"><v-textarea v-model="detailedDirections"></v-textarea></v-col>

                      <v-col cols="3"><v-label>When Did Property First Open?</v-label></v-col>
                      <v-col cols="8"><v-textarea v-model="dateOpened"></v-textarea></v-col>

                      <v-col cols="3"><v-label>Type of Property:</v-label></v-col>
                      <v-col cols="8">
                          <v-select
                                v-model="propertyType"
                                label="Property Type"
                                :items="propertyTypes"
                                autocomplete
                          ></v-select>
                      </v-col>

                      <v-col cols="3"><v-label>Total No. Rooms:</v-label></v-col>
                      <v-col cols="8"><v-text-field v-model="totalNumRooms" label="Total No. Rooms"></v-text-field></v-col>

                      <v-col cols="3"><v-label>Rates:</v-label></v-col>
                      <v-col cols="8">Anything regarding rates must be entered by the rates department</v-col>

                      <v-col cols="3"><v-label>Channel Manager:</v-label></v-col>
                      <v-col cols="8"><v-text-field v-model="channelManager" label="Channel Manager"></v-text-field></v-col>

                      <v-col cols="3"><v-label>Currency:</v-label></v-col>
                      <v-col cols="8">
                          <v-select
                                v-model="currencyKey"
                                label="Currency"
                                :items="currencies"
                                autocomplete
                          ></v-select>
                      </v-col>


                      <v-col cols="3"><v-label>Default Rate Basis</v-label></v-col>
                      <v-col cols="8">
                          <v-select
                                v-model="defaultRateBasis"
                                label="Default Rate Basis"
                                :items="rateBasisOptions"
                                autocomplete
                          ></v-select>
                      </v-col>

                      <v-col cols="3"><v-label>Optional Extras:</v-label></v-col>
                      <v-col cols="8"><v-textarea v-model="rateOptionalExtras"></v-textarea></v-col>

                      <v-col cols="3"><v-label>Accepted Credit Cards:</v-label></v-col>
                      <v-col cols="8">
                          <v-layout row wrap>
                                <v-col cols="2"><label>Mastercard <img src="/images/logos/mastercard.svg" class="cc-logo" alt="MasterCard"></label></v-col>
                                <v-col cols="10"><v-checkbox hide-details v-model="acceptsMastercard"></v-checkbox></v-col>
                                <v-col cols="2"><label>Visa <img src="/images/logos/visa.png" class="cc-logo" alt="Visa"></label></v-col>
                                <v-col cols="10"><v-checkbox hide-details v-model="acceptsVisa"></v-checkbox></v-col>
                                <v-col cols="2"><label>Amex <img src="/images/logos/american-express.svg" class="cc-logo" alt="American Express"></label></v-col>
                                <v-col cols="10"><v-checkbox hide-details v-model="acceptsAmex"></v-checkbox></v-col>
                                <v-col cols="2"><label>Diners <img src="/images/logos/diners.svg" class="cc-logo" alt="Diners Club" style="max-height: 2rem; margin-bottom: -10px;"></label></v-col>
                                <v-col cols="10"><v-checkbox hide-details v-model="acceptsDiners"></v-checkbox></v-col>
                                <v-col cols="2"><label>Maestro <img src="/images/logos/maestro.png" class="cc-logo" alt="Maestro"></label></v-col>
                                <v-col cols="10"><v-checkbox hide-details v-model="acceptsMaestro"></v-checkbox></v-col>
                          </v-layout>
                      </v-col>

                      <v-col cols="3"><v-label>Nearest Airport (km):</v-label></v-col>
                      <v-col cols="8">
                          <v-text-field v-model="airportName" label="Name"></v-text-field>
                          <v-text-field v-model="airportDistance" label="Distance"></v-text-field>
                          <v-text-field v-model="airportCode" label="Code"></v-text-field>
                      </v-col>

                      <v-col cols="3"><v-label>Display Status:</v-label></v-col>
                      <v-col cols="8">
                          <v-select 
                                v-model="displayStatus"
                                label="Display Status"
                                :items="displayStatuses"
                                autocomplete
                          ></v-select>
                       </v-col>

                       <v-col cols="3"><v-label>Travel Operator:</v-label></v-col>
                       <v-col cols="8">
                           <v-select 
                                v-model="travelOperator"
                                label="Travel Operator"
                                :items="travelOperators"
                                autocomplete
                            ></v-select>
                        </v-col>

                        <v-col cols="3"><v-label>Commission (%):</v-label></v-col>
                        <v-col cols="8"><v-text-field v-model="commission" label="Commission (%)"></v-text-field></v-col>

                        <v-col cols="3"><v-label>Nearest Restaurant (km):</v-label></v-col>
                        <v-col cols="8"><v-text-field v-model="restaurantDistance" label="Distance"></v-text-field></v-col>

                        <v-col cols="12"><v-btn v-on:click="save()" class="lime">Create</v-btn></v-col>
                  </v-layout>
              </fieldset>
            </v-container>
        </v-card-text>
    </v-card>
    </v-col>
</template>

<script>
    /**
     * Display new hotels form
     */
    export default {
        props:[],

        data: function() {
            return {
                regions: [],
                travelOperators: [],
                displayStatuses: [{value: 0, text: "Offline"}, {value: 1, text: "Online"}, {value: 2, text: "Preview"}],
                hotelName: "",
                propertyTypes: [{value: "hotel", text: "Hotel/B&B"}, {value: "rental", text: "Rental"}, {value: "tmt", text: "Tailor Made Tour"}, {value: "special", text: "Special Interest"}],
                propertyType: "hotel",
                dateOpened: "",
                currency: 0,
                currencies: [],
                regionKey: null,
                displayStatus: 2,
                commission: 10.0,
                restaurantDistance: "",
                airportName: "",
                airportDistance: "",
                airportCode: "",
                travelOperator: 0,

                address1: "",
                address2: "",
                city: "",
                postCode: "",
                postalAddress: "",
                detailedDirections: "",
                googleMapPosition: "",
                website: "",
                owner: "",
                keyContact: "",
                marketingName: "",
                marketingEmail: "",
                reservationsName: "",
                reservationsEmail: "",
                reservationsPhone: "",
                reservationsSMS: false,

                totalNumRooms: "",
                channelManager: "",
                defaultRateBasis: 1,
                rateBasisOptions: [{value:0, text:"Please choose..."}, {value:1, text:"Room"}, {value:2, text:"Apartment"}, {value:3, text:"Cottage"}, {value:4, text:"Villa"},
                                   {value:5, text:"House"}, {value:6, text:"Boat"}, {value:7, text:"Unit"}, {value:8, text:"Whole&nbsp;House"}],
                rateOptionalExtras: "",

                acceptsMastercard: false,
                acceptsVisa: false,
                acceptsAmex: false,
                acceptsDiners: false,
                acceptsMaestro: false
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            this.getRegions();
            this.getTravelOperators();
            this.getCurrencies();
        },

        methods: {

            getTravelOperators: function(){
                var self = this;
                this.travelOperators.push({value: 0, text: "New Account"});
                axios.get('/api/travel-operators?x=y')
                .then((response)=>{
                    if (response.data.success){
                        console.log(response.data);
                        response.data.travelOperators.forEach(function(account){
                            self.travelOperators.push({ value: account.user_key, text: account.username });
                        });
                    }
                    // If it failed then simply tell them so
                    else {
                        alert("A problem occurred and the hotel could not be created");
                    }
                });
            },

            previousStage: function(){
                if (this.step > 0){
                    this.step--;
                }
            },

            nextStage: function(){
                if (this.step < 5){
                    this.step++;
                }
            },

            getCurrencies: function(){
                var self = this;
                this.currencies.push({value: 0, text: "Use Region Default"});
                axios.get('/api/selectable-currencies')
                .then((response)=>{
                    if (response.data.success){
                        console.log(response.data);
                        response.data.currencies.forEach(function(currency){
                            self.currencies.push({ value: currency.currency_key, text: currency.currency_name });
                        });
                    }
                    // If it failed then simply tell them so
                    else {
                        alert("A problem occurred and the currencies could not be downloaded");
                    }
                });
            },

            save: function(){
                axios.post('/api/hotels/new', {
                    hotelName: this.hotelName, 
                    propertyType: this.propertyType,
                    dateOpened: this.dateOpened,
                    regionKey: this.regionKey, 
                    currencyKey: this.currencyKey,
                    commission: this.commission, 
                    displayStatus: this.displayStatus, 
                    travelOperator: this.travelOperator,

                    address1: this.address1,
                    address2: this.address2,
                    city: this.city,
                    postCode: this.postCode,
                    postalAddress: this.postalAddress,
                    detailedDirections: this.detailedDirections,
                    googleMapPosition: this.googleMapPosition,
                    website: this.website,
                    owner: this.owner,
                    keyContact: this.keyContact,
                    marketingName: this.marketingName,
                    marketingEmail: this.marketingEmail,
                    reservationsName: this.reservationsName,
                    reservationsEmail: this.reservationsEmail,
                    reservationsPhone: this.reservationsPhone,
                    reservationsSMS: this.reservationsSMS,

                    restaurantDistance: this.restaurantDistance,
                    airportName: this.airportName,
                    airportDistance: this.airportDistance,
                    airportCode: this.airportCode,

                    totalNumRooms: this.totalNumRooms,
                    channelManager: this.channelManager,
                    defaultRateBasis: this.defaultRateBasis,
                    rateOptionalExtras: this.rateOptionalExtras,

                    acceptsMastercard: this.acceptsMastercard,
                    acceptsVisa: this.acceptsVisa,
                    acceptsAmex: this.acceptsAmex,
                    acceptsDiners: this.acceptsDiners,
                    acceptsMaestro: this.acceptsMaestro

                })
                .then((response)=>{
                    // If it was successful then reload the voucher details so it is 
                    // displayed up to date on the page
                    if (response.data.success){
                        console.log(response.data);
                        var hotel = response.data.hotel;
                        window.location.href="/hotel/" + hotel.hotel_key + '/dashboard';
                    }
                    // If it failed then simply tell them so
                    else {
                        alert("A problem occurred and the hotel could not be created");
                    }
                });
            },


            getRegions:function(){
                axios.get('/api/selectable-regions')
                .then(function(response){
                    var regions = [];
                    response.data.regions.forEach(function(record){
                        regions.push({ text: record.region_name, value: record.region_key });
                    });
                    this.regions = regions;
                }.bind(this));
            },

        },

        computed: {}
    }
</script>

<style>
.v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
}

.accepted-credit-cards .col .v-input--selection-controls {
    margin-top: 0;
}

.cc-logo {
    border: none;
    max-height: 1rem;
}
</style>
