var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-daterange", {
    attrs: { options: _vm.dateRangeOptions, labels: _vm.dateRangeLabels },
    on: { input: _vm.onDateRangeChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }