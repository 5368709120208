<template>
    <v-col cols="12" md="6" lg="4" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">credit_score</v-icon> Successful Credit Card Transactions
            </v-card-title>
            <bar-chart :chartdata="chartData" :options="chartOptions"></bar-chart>
        </v-card>
    </v-col>
</template>

<script>
    import BarChart from '@/components/charts/BarChart';
    import axios from 'axios';
    /**
     * Display successful CC transaction statistics to the user
     */
    export default {
        /**
         * Data passed into the component from the view itself via attributes on its tag
         */
        props:['datasource'],
        components: {
            BarChart,
        },

        data() {
            return {
                apiData:  {},
                chartData: {},
                chartOptions: {
                    maintainAspectRatio: false,
                    responsive: true,
                    events: ['click'],
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                precision: 0,
                            }
                        }]
                    },
                    title: {
                        display: false,
                        text: ''
                    }
                },
            }
        },
        /**
         * Perform the following actions on startup
         */
        mounted() {
            this.loadCCStats();
            setInterval( this.loadVANStats, 60000 );
        },

        methods: {

            formatDate(date){
                var dd = date.getDate();
                var mm = date.getMonth()+1;
                var yyyy = date.getFullYear();
                if(dd<10) {dd='0'+dd}
                if(mm<10) {mm='0'+mm}
                date = yyyy+'-'+mm+'-'+dd;
                return date
            },

            /**
             * Load the latest data from the API
             */
            async loadCCStats() {
                const response = await axios.get('api/successful-credit-card-transactions').
                then(response => {
                    console.log(response);
                    this.apiData = response.data.stats;
                    this.buildDataObject();
                }).catch(error => {
                    console.log(error);
                });
            },
            /**
             * Build the data object required by the line chart using API data to
             * fill the datasets ofjects and lavels
             */
            buildDataObject() {
                let data = {
                    labels: [],
                    datasets: [{
                        data: [],
                        label: "Successful Credit Card Transactions",
                        backgroundColor: "#C1CD23",
                    }]
                };

                //Adds previous 7 days date to labels
                for (var i=0; i<7; i++) {
                    var d = new Date;
                    d.setDate(d.getDate() - i);
                    data.labels.unshift(this.formatDate(d));
                }

                data.labels.forEach((date, key) => {
                    data.datasets[0].data[key] = 0;
                    //Loop through each set of results and overwrite dataset with result count if date is matching
                    for (let result of Object.entries(this.apiData)) {
                        if (date == result[1].creation_date) {
                            data.datasets[0].data[key] = result[1].my_count;
                        }
                    }
                });

                this.chartData = data;
            },
        },
    }
</script>
