<template>
    <v-flex xs12 class="no-print">
      <v-expansion-panels v-model="panel" value="0">

        <!-- QUICK SEARCH-->
        <v-expansion-panel >
            <v-expansion-panel-header class="list-search--header">
                <strong><v-icon>search</v-icon> Quick Search</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content
                    :value="showPanel"
                    class="quickSearchPanel"
                  ripple
            >
                <v-card flat>
                    <v-card-text>
                        <form v-on:submit="quickSearch($event)">
                            <v-icon>search</v-icon>
                            <div id="quickSearchOuter">
                                <v-text-field
                                        id="quickSearchInput"
                                        label="Value to search for"
                                        v-model="quickSearchText"
                                ></v-text-field>
                            </div>
                            <v-btn class="ies-green" 
                                   @click="quickSearch($event)">Search</v-btn>
                            <div id="searchProgressIcon" v-show="searchBusy">
                                <v-progress-circular
                                        :width="3"
                                        color="lime-green"
                                        indeterminate
                                ></v-progress-circular>
                            </div>
                        </form>
                    </v-card-text>
                 </v-card>
            </v-expansion-panel-content>
        </v-expansion-panel>


        <!-- ADVANCED SEARCH-->
        <v-expansion-panel>
            <v-expansion-panel-header class="list-search--header">
                <strong><v-icon>search</v-icon> Advanced Search</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-card flat>
                    <v-card-text>
                        <v-flex>Show me all records where <strong>all</strong> of these conditions are true:</v-flex>
                        <v-layout column>
                            <v-flex xs12 v-for="(filter, index) in filters" :key='index' xs12>
                                <v-container fluid class="pt-0 pb-0">
                                    <v-layout row wrap>


                                        <v-flex xs12 sm4 class="mr-2">

                                            <v-select
                                                    :items="modelNames"
                                                    v-model="filter.modelField"
                                                    label="The model-field"
                                                    class=""
                                                    item-value="value"
                                                    single-line
                                            ></v-select>

                                        </v-flex>

                                        <v-flex xs12 sm2 class="mr-2">
                                            <v-select
                                                    :items="operators"
                                                    v-model="filter.operator"
                                                    label="Operator"
                                                    class=""
                                                    item-value="value"
                                                    single-line
                                            ></v-select>

                                        </v-flex>

                                        <v-flex xs12 sm2 class="mr-2">
                                            <input type="text" v-model="filter.value" label="Value" class="mt-3 mr-2" placeholder="Value">
                                        </v-flex>

                                        <v-flex xs12 sm1 class="mt-2">
                                            <v-btn v-on:click="removeFilter(index)"><v-icon>delete</v-icon> Remove Condition</v-btn>
                                        </v-flex>

                                    </v-layout>
                                </v-container>
                            </v-flex>

                            <v-flex xs12>
                                <v-btn v-on:click="addFilter()"><v-icon>add</v-icon> Add Condition</v-btn>
                            </v-flex>

                        </v-layout>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn v-on:click="search()" class="ies-green" ><v-icon>search</v-icon> Search</v-btn>
                        <v-btn v-on:click="removeAllFilters()" :disabled="filters.length == 0"><v-icon>cancel</v-icon> Remove Filters</v-btn>
                    </v-card-actions>
                </v-card>

            </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
<!--
        <v-snackbar
                :timeout="parseInt(2000)"
                v-model="successSnackbar"
                multi-line
                class="ies-green"
        >
            <div class="full-height full-width v-align-container text-xs-center">
                <h4 class="ies-dark-gray--text v-align-div">Your Filters Have Been Applied</h4>
            </div>
        </v-snackbar>

        <v-snackbar
                :timeout="parseInt(2000)"
                v-model="errorSnackbar"
                multi-line
                class="ies-coral"
        >
            <div class="full-height full-width v-align-container text-xs-center">
                <h4 class="ies-dark-gray--text v-align-div">Search Failed</h4>
            </div>
        </v-snackbar>
-->
    </v-flex>
</template>


<script>
    
    // Import the application event bus so we can listen for user or menu data changes and react accordingly
    import EventBus from '../../eventBus';

    // Export the component for inclusion within the page
    export default {
        props: ['datasource', 'fields'],

        /**
         * Core data required by the component and template
         */
        data: function(){
            return {
                /** @var array Boolean flags to state whether the expansion panels are open */
                panel: [0],

                /** @var The list of filters to be applied to the search */
                filters:     [],

                /** @var The page record model */
                page:        {},

                /** @var The results of the data */
                data:        {},

                /** @var Flag identifying if the page has finished loading yet */
                loaded:      false,

                /** @var Feedback snackbar toggles, dictating whether the response messages should show informing the user that an action has occurred */
                successSnackbar:    false,
                errorSnackbar:      false,

                /** @var Text that is going to be displayed as quick search input placeholder */
                quickSearchText: '',

                /** @var determines whether progress icon should be displayed */
                searchBusy: false,

                /** @var set quick search open as default */
                showPanel: true,

                myA: true,
                myB: true
            };
        },

        /**
         * On page load complete build the component
         */
        mounted: function(){
            // Register an event listener callback function with the page service so we can be alerted when the service updates its data with fresh copies
            this.$root.$on('List: Data updated', this.loadData);
            console.log("- SEARCH LIST: data updated detected", this.fields);
        },

        /**
         * Business logic functions performing useful tasks for this component
         */
        methods: {
            /**
             * Obtain a copy of the data required by this component in order to function at all
             */
             loadData: function(){
                this.searchBusy = false;
             },

            /**
             * Request the server return an updated form of the data matching these filter criteria
             */
            search: function(){
                var newFilters = [];
                console.log(this.filters);
                this.filters.forEach(function(appliedFilter){
                    console.log(appliedFilter);
                    // The way the filters works is that the Vue component binds the list of options to the select element
                    // for us, meaning all we can get back is the value - the value may not be a perfect match to just 
                    // one of the fields, for example if several of them have the same name but come from different tables
                    // This means that we need to reverse engineer the value to get the model and field name back from that
                    var parts = appliedFilter.modelField.split('.');
                    var modelName = parts[0];
                    var fieldName = parts[1];
                    newFilters.push({ 'modelName': modelName, 'fieldName': fieldName, 'operator': appliedFilter.operator, 'value': appliedFilter.value });
                });
                console.log("search list new filters:", newFilters);
                // Request the page service to do all the hard work for us
                this.$root.$emit('List: Search', {'id': 'notset', 'condition': 'AND', 'fields': newFilters, 'tool': 'advancedSearch'});
            },

            /**
             * Run quick search
             */
            quickSearch(e = false) {
                if (e) {
                    e.preventDefault();
                }

                var value = this.quickSearchText;
                // In quick search we will use LIKE as a comparison operator for every field
                var operator = 'LIKE';

                // Only trigger quick search if the user enter at least 3 characters to prevent too many matches
                if (value.length > 2) {
                    // show progress icon
                    this.searchBusy = true;

                    var newFilters = [];
                    console.log("will do a quick search", this.fields);
                    this.fields.forEach(function(field){
                        console.log(field);
                        if (field.displayType != 'editButton'){
                            var filterObject = { 'modelName': field.modelName, 'fieldName': field.fieldName, 'operator': operator, 'value': value };
                            newFilters.push(filterObject);
                        }
                    });
console.log("new filters should be ", newFilters);
                    this.$root.$emit('List: Search', {'id': 'notset', 'condition': 'OR', 'fields': newFilters, 'tool': 'quickSearch'});
                }

                // If it is empty then just re-submit a blank search
                if (!value.length){
                    this.$root.$emit('List: Search', {'id': 'notset', tool: 'none', 'condition':'AND', 'fields': []});
                }
            },


            /**
             * Remove the filter at the specified index number from the list altogether
             *
             * @param int index - The index position of the filter within the array
             */
            removeFilter: function(index){
                this.filters.splice(index, 1);
            },

            /**
             * Removes all filters from the tool and triggers a re-search, thereby getting an unfiltered and fully up-to-date copy of the full dataset
             */
             removeAllFilters: function(){
                this.filters = [];
                this.search();
             },

            /**
             * Add a new blank filter to the array
             * Make operator IS default
             */
            addFilter: function(){
                this.filters.push({ 'modelField': '', 'operator': '==', 'value': '' });
            },


        },

        /**
         * Dynamic data that is bound to the template such that the template will auto update when the data changes
         */
        computed: {
            /**
             * All of the models used by this page which the user can choose to search on
             */
            modelNames: function(){
                var modelNames = [];
                this.fields.forEach(function(field){
                    if (field.displayType != 'editButton'){
                        modelNames.push({ 'text': '(' + field.modelName + ') ' + field.fieldName, 'value': field.modelName + '.' + field.fieldName });
                    }
                });
                return modelNames;
            },

            /**
             * Construct a list of operators to be applied while searching for data.  Presently this is just a mega list and does not take field data type into account
             */
            operators: function(){
                return [
                            // Value comparisons
                            { 'text': 'Is',                     'value': '==' },
                            { 'text': 'Is Not',                 'value': '!=' },

                            // Textual comparisons
                            { 'text': 'Contains',               'value': 'LIKE' },
                            { 'text': 'Does Not Contain',       'value': 'NOT LIKE' },
                            { 'text': 'Starts With',            'value': 'STARTS' },
                            { 'text': 'Does Not Start With',    'value': 'NOT STARTS' },
                            { 'text': 'Ends With',              'value': 'ENDS' },
                            { 'text': 'Does Not End With',      'value': 'NOT ENDS' },
                            { 'text': 'Is Empty Text',          'value': 'EMPTY' },

                            // Existance checks
                            { 'text': 'Is Null',                'value': 'NULL' },
                            { 'text': 'Is Not Null',            'value': '<> NULL' },

                            // Numeric comparison
                            { 'text': 'Is Less Than',           'value': '<' },
                            { 'text': 'Is Less Than/Equal To',     'value': '<=' },
                            { 'text': 'Is More Than',              'value': '>' },
                            { 'text': 'Is More Than/Equal To',     'value': '>=' },

                            // Boolean comparison
                            { 'text': 'Is True',                'value': 'TRUE' },
                            { 'text': 'Is False',               'value': 'FALSE' },

                            // Bitwise comparison
                            { 'text': 'Logical And',            'value': '&' },
                            { 'text': 'Logical And Invert',     'value': '&~' },
                            { 'text': 'Logical Or',             'value': '|' },
                            { 'text': 'Logical Or Invert',      'value': '|~' },

                       ];
            },
        }
    }
</script>

<style>
    .list-search--header {
        color: rgb(118,118,118);
    }

    .quickSearchPanel > .expansion-panel__header {
        padding-top: 0px;
        padding-bottom: 0px;
        height: 15px;
    }

    .quickSearchPanel  .card__text {
        padding-top: 0px;
    }

    #quickSearchOuter {
        display: inline-block;
        margin-left: 10px;
        margin-right: 20px;
        width: 500px;
    }

    #quickSearchInput {
        border: none;
    }

    #searchProgressIcon {
        display: inline-block;
        position: relative;
        top:10px;
    }

</style>
