var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "justify-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs8: "", md10: "", lg9: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "justify-space-between": "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", md3: "", lg3: "" } }, [
                    _c("h3", [_vm._v("iCal connections")]),
                  ]),
                  _vm._v(" "),
                  _vm.updateMessage.display
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "", md4: "", lg4: "", "mt-2": "" } },
                        [
                          _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.updateMessage.text) +
                                "\n                    "
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", md3: "", lg3: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", "justify-end": "" } },
                        [
                          _c("v-flex", { attrs: { xs3: "", "mr-2": "" } }, [
                            _vm.updateBusy
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "searchProgressIcon",
                                    staticStyle: { float: "right" },
                                  },
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        width: 3,
                                        color: "blue",
                                        indeterminate: "",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs6: "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    outline: "",
                                    color: "green",
                                    block: "",
                                    disabled: _vm.updateBusy,
                                  },
                                  on: { click: _vm.runFullUpdate },
                                },
                                [_vm._v("Run full update")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.connectionErrors.length
            ? _c(
                "v-flex",
                {
                  staticClass: "connectionErrors",
                  attrs: { xs12: "", md10: "", lg9: "", mt3: "", mb3: "" },
                },
                [
                  _c("h4", [_vm._v("Connections errors:")]),
                  _vm._v(" "),
                  _vm._l(_vm.connectionErrors, function (error, key) {
                    return _c(
                      "div",
                      { key: key },
                      [
                        _c(
                          "v-alert",
                          { attrs: { value: true, type: "error" } },
                          [
                            _c("span", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(error.message) +
                                  "\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "connectionErrorsDelete" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { color: "white" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteErrorConnection(
                                          error.ical_connection,
                                          key
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            delete\n                        "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "", md10: "", lg9: "", "mb-4": "" } },
            [
              _c(
                "v-card",
                { staticClass: "statsSection" },
                _vm._l(_vm.stats, function (val, name) {
                  return _c("div", { key: name }, [
                    _c("strong", [_vm._v(_vm._s(name) + ":")]),
                    _vm._v(" " + _vm._s(val) + "\n                "),
                  ])
                }),
                0
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs8: "", md10: "", lg9: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-expansion-panel",
                    { attrs: { expand: "" } },
                    _vm._l(_vm.connectionsByHotels, function (data, hotelCode) {
                      return _c(
                        "v-expansion-panel-content",
                        { key: hotelCode },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(hotelCode) +
                                    " - " +
                                    _vm._s(data.hotel.hotel_name)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  " - connected rooms: " +
                                    _vm._s(data.connections.length)
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    _vm._l(
                                      data.connections,
                                      function (connection, key) {
                                        return _c(
                                          "v-flex",
                                          {
                                            key: connection.ical_connection_key,
                                            attrs: { xs12: "" },
                                          },
                                          [
                                            _c("i-cal-connection", {
                                              attrs: {
                                                connection: connection,
                                                hotelcode: hotelCode,
                                                sort: key + 1,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "justify-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs8: "", md10: "", lg9: "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "", "justify-space-between": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs1: "", "ml-0": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { color: "primary", disabled: _vm.updateBusy },
                          on: {
                            click: function ($event) {
                              _vm.newConnectionDialog = true
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Add new connection\n                        "
                          ),
                          _c("v-icon", { attrs: { right: "", dark: "" } }, [
                            _vm._v("add"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs1: "", "mr-0": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "lime", disabled: _vm.updateBusy },
                          on: {
                            click: function ($event) {
                              return _vm.save()
                            },
                          },
                        },
                        [_vm._v("SAVE")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.newConnectionDialog,
            callback: function ($$v) {
              _vm.newConnectionDialog = $$v
            },
            expression: "newConnectionDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("add new iCal connection"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [_c("hotel-room-picker", { attrs: { name: "ical" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "iCal URL" },
                            model: {
                              value: _vm.newConnection.url,
                              callback: function ($$v) {
                                _vm.$set(_vm.newConnection, "url", $$v)
                              },
                              expression: "newConnection.url",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Staff notes" },
                            model: {
                              value: _vm.newConnection.notes,
                              callback: function ($$v) {
                                _vm.$set(_vm.newConnection, "notes", $$v)
                              },
                              expression: "newConnection.notes",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs2: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              attrs: {
                                color: "pink",
                                disabled: !_vm.newConnectionTestValid,
                              },
                              on: { click: _vm.testConnection },
                            },
                            [
                              _vm._v(
                                "\n                            Test connection\n                            "
                              ),
                              _c("v-icon", { attrs: { right: "", dark: "" } }, [
                                _vm._v("compare_arrows"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showProgressIcon,
                                  expression: "showProgressIcon",
                                },
                              ],
                              staticClass: "searchProgressIcon",
                            },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  width: 3,
                                  color: "blue",
                                  indeterminate: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.newConnectionValid
                        ? _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("span", [
                              _vm._v(
                                "\n                            Connection test: "
                              ),
                              _c(
                                "strong",
                                { staticStyle: { color: "green" } },
                                [_vm._v("success")]
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                            Connection can be added\n                        "
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.newConnectionValid
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: _vm.apiBusy,
                                  },
                                  on: { click: _vm.addNewConnection },
                                },
                                [_vm._v("Add")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", flat: "" },
                      on: { click: _vm.clearNewConnectionDialog },
                    },
                    [_vm._v("Clear")]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", flat: "" },
                      on: {
                        click: function ($event) {
                          _vm.newConnectionDialog = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: parseInt(2000),
            "multi-line": "",
            color: "ies-green",
          },
          model: {
            value: _vm.snackbar.successSnackbar,
            callback: function ($$v) {
              _vm.$set(_vm.snackbar, "successSnackbar", $$v)
            },
            expression: "snackbar.successSnackbar",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "full-height full-width v-align-container text-xs-center",
            },
            [
              _c("h4", { staticClass: "ies-dark-gray--text v-align-div" }, [
                _vm._v(_vm._s(_vm.snackbar.message)),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: parseInt(3000),
            "multi-line": "",
            color: "ies-coral",
          },
          model: {
            value: _vm.snackbar.errorSnackbar,
            callback: function ($$v) {
              _vm.$set(_vm.snackbar, "errorSnackbar", $$v)
            },
            expression: "snackbar.errorSnackbar",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "full-height full-width v-align-container text-xs-center",
            },
            [
              _c("h4", { staticClass: "ies-dark-gray--text v-align-div" }, [
                _vm._v(_vm._s(_vm.snackbar.message)),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }