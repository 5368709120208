var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-select", {
        attrs: {
          label: _vm.field.schema.displayName,
          items: _vm.rateTypes,
          autocomplete: "",
        },
        model: {
          value: _vm.viewValue,
          callback: function ($$v) {
            _vm.viewValue = $$v
          },
          expression: "viewValue",
        },
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: _vm.field.fieldName },
        domProps: { value: _vm.field.value },
      }),
      _vm._v(" "),
      _c(
        "v-alert",
        {
          staticStyle: { width: "100%" },
          attrs: { type: "error", value: _vm.field.errors },
        },
        _vm._l(_vm.field.errors, function (error) {
          return _c("li", [
            _vm._v("\n            " + _vm._s(error) + "\n        "),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }