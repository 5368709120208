<template>
    <v-container>
        <h1>Edit Voucher: {{ voucher.voucher_code }}</h1>
        <p>Note: this page is deprecated, and will be removed in future</p>
            <v-flex xs12 style="margin: 20px 0;">
                <v-card class="no-print">
                    <v-btn class="ies-green" @click="save()">Save</v-btn>
                    <v-btn :disabled="!canBeCancelled" class="ies-coral" @click="openDialog('cancel')">Cancel</v-btn>
                    <ies-resend-voucher :field="metaData" style="display: inline;"></ies-resend-voucher>
                    <v-btn :disabled="!canBeReactivated" outline color="blue" @click="openDialog('reactivate')">Reactivate</v-btn>
                    <span v-if="!canBeReactivated">Reactivation Denied: {{ cannotReactiveReason }} </span>
                    <div id="linearWrapper">
                        <v-progress-linear :indeterminate="true" v-show="showProgressIcon" color="lime" ></v-progress-linear>
                    </div>
                </v-card>
            </v-flex>


        <v-card>
            <v-layout row wrap justify-center>
                <v-flex xs12>

                    <v-layout row wrap justify-space-between>
                        <v-flex xs3>
                            <v-alert
                                    :value="showRememberAlert"
                                    type="warning"
                            >
                                Remember to press save button
                            </v-alert>
                        </v-flex>
                        <v-flex xs2 class="alertStatus">
                            <v-alert
                                    :value="true"
                                    :type="alertType"
                            >
                                {{ actionStatus }}
                            </v-alert>
                        </v-flex>
                    </v-layout>

<!--Details column-->
                    <v-flex xs12>
                        <v-layout column class="ma-3" >
                            <fieldset>
                                <legend>Details:</legend>
                                <v-layout column>

                                <not-editable label="Voucher key:" :text="voucher.voucher_key"/>
                                <not-editable label="Created at:" :text="createdAt"/>
                                <div style="padding: 16px">
                                    <v-flex xs12>
                                        <strong>Expires at:</strong>
                                    </v-flex>
                                    <v-flex xs12>
                                        {{ voucher.expires }}
                                        <v-icon
                                                small
                                                class="ml-2 editExpiryBtn"
                                                @click="openDialog('expiry')"
                                        >edit</v-icon>
                                    </v-flex>
                                </div>
                                <v-flex>
                                    <not-editable label="Created on page:" :text="sourcePage"/>
                                    <not-editable label="Reissue parent voucher_key:" :text="voucher.reissue_voucher_key" v-if="voucher.reissue_voucher_key > 0"/>
                                    <not-editable label="Creation purpose:" :text="creationPurpose" v-if="voucher.creation_purpose > 0"/>
                                    <not-editable label="Voucher type:" :text="voucherType"/>
                                </v-flex>
<!--Value column-->
                                <v-flex xs12 md4>
                                    <v-layout row wrap>
                                        <not-editable label="Value:" :text="voucher.value | pounds"/>
                                        <not-editable label="Redeemed value:" :text="voucher.redeemed_value | pounds" v-if="!voucher.is_active"/>
                                    </v-layout>
                                </v-flex>

<!--Status column-->
                                <v-flex xs12 md4 >
                                    <v-layout row wrap>
                                        <v-flex xs12 style="padding: 16px">
                                            <strong>Status:</strong><br>
                                            <strong v-if="voucher.is_active" class="voucherActive">
                                                Active <v-icon color="lime">done_outline</v-icon>
                                            </strong>
                                            <strong v-else class="voucherInactive">
                                                Inactive <v-icon color="red">clear</v-icon> - {{ actionStatus }}
                                            </strong>
                                        </v-flex>
                                        <v-flex xs12 sm10 v-if="status === consts.ACTION_REDEMPTION">
                                            <not-editable label="Redeemed value:" :text="voucher.redeemed_value | pounds"/>
                                            <not-editable label="Redeemed at:" :text="voucher.redeemed"/>
                                            <not-editable label="Reservation:" :text="voucher.reservation.reference" v-if="voucher.reservation"/>
                                            <not-editable label="Booking value:" :text="voucher.reservation.quote_amount_gbp | pounds" v-if="voucher.reservation"/>
                                            <not-editable label="Commission:" :text="voucher.reservation.commission_amount_local | pounds" v-if="voucher.reservation"/>
                                        </v-flex>
                                        <v-flex xs12 sm10 v-if="status === consts.ACTION_EXPIRATION">
                                            <not-editable label="Expired at:" :text="voucher.expires"/>
                                            <not-editable label="Deactivated at:" :text="voucher.deactivate_date"/>
                                        </v-flex>
                                        <v-flex xs12 sm10 v-if="status === consts.ACTION_CANCELLATION" class="cancellationWrapper">
                                            <not-editable label="Cancelled at:" :text="voucher.cancelled_date"/>
                                            <not-editable label="Cancelled by:" :text="voucher.cancelled_by_username"/>
                                            <input-wrapper :field="fields.cancellation_notes" />
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                </v-layout>
                            </fieldset>

<!-- customer section - EDITABLE  -->
                            <fieldset>
                                <legend>Customer:</legend>
                                    <v-layout column>
                                        <v-flex xs12 md4 class="customerInfo">
                                            <v-layout row wrap>
                                                <v-flex xs10>
                                                    <input-wrapper :field="fields.purchaser_email" />
                                                    <input-wrapper :field="fields.recipient_email" />
                                                    <input-wrapper :field="fields.to_text" />
                                                    <input-wrapper :field="fields.message" />
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                            </fieldset>

<!-- switches section -->
                            <fieldset>
                                <legend>Switches:</legend>
                                    <v-layout column>
                                        <v-flex xs12 md4>
                                            <v-layout row wrap class="switchesWrapper">
                                                <v-flex xs10>
                                                    <input-wrapper :field="fields.is_promotional" />
                                                    <input-wrapper :field="fields.is_referral"  />
                                                    <not-editable label="Referral type:" :text="voucher.source" v-if="voucher.is_referral"/>
                                                    <input-wrapper :field="fields.is_delivered"  />
                                                    <input-wrapper :field="fields.creation_purpose"  />
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                            </fieldset>
<!-- address section -->
                            <fieldset>
                                <legend>Address:</legend>
                                    <v-layout column>
                                        <v-flex xs12 md4>
                                            <v-layout row wrap v-if="voucher.scheduled_delivery">
                                                <not-editable label="Scheduled delivery at:" :text="voucher.scheduled_delivery"/>
                                                <not-editable v-if="voucher.is_delivered" label="Delivered:" text="Yes"/>
                                                <not-editable v-else label="Delivered:" text="No"/>
                                            </v-layout>
                                            <v-layout row wrap v-if="voucher.address_key > 0 && voucher.address" class="addressWrapper">
                                                <not-editable label="Address" :text="voucher.address.address_1"/>
                                                <not-editable label="" :text="voucher.address.address_2"/>
                                                <not-editable label="Postcode" :text="voucher.address.postcode"/>
                                                <not-editable label="Town" :text="voucher.address.town"/>
                                                <not-editable label="To" :text="voucher.address.recipient_name"/>
                                                <not-editable label="Delivery Type" :text="deliveryType" v-if="voucher.delivery_type" />
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                            </fieldset>
<!-- i-escape notes -->
                            <fieldset>
                                <legend>Staff notes:</legend>
                                    <v-layout column>
                                        <v-flex xs12 xs10>
                                            <textarea name="main-notes" type="text" v-model="notes" @change="updateNotes"></textarea>
                                        </v-flex>
                                    </v-layout>
                            </fieldset>
                        </v-layout>
                    </v-flex>
                </v-flex>
            </v-layout>
        </v-card>


         <v-card class="no-print">
            <v-btn class="ies-green" @click="save()">Save</v-btn>
            <v-btn :disabled="!canBeCancelled" class="ies-coral" @click="openDialog('cancel')">Cancel</v-btn>
            <ies-resend-voucher :field="metaData" style="display: inline;"></ies-resend-voucher>
            <v-btn :disabled="!canBeReactivated" outline color="blue" @click="openDialog('reactivate')">Reactivate</v-btn>
            <span v-if="!canBeReactivated">Reactivation Denied: {{ cannotReactiveReason }} </span>
            <div id="linearWrapper">
                <v-progress-linear :indeterminate="true" v-show="showProgressIcon" color="lime" ></v-progress-linear>
            </div>
        </v-card>


        <!-- Voucher history -->
        <RecordHistory :meta-data="metaData" style="padding: 20px 0;"></RecordHistory>


        <!-- Dialog -->
        <v-dialog v-model="dialog.show" max-width="700px">
            <v-card>
                <v-card-title>
                    <span class="headline" v-if="dialog.mode === 'expiry'">Change voucher expiry date</span>
                    <span class="headline" v-else>Do you want to {{ dialog.mode }} this voucher?</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-layout row wrap v-if="dialog.mode === 'cancel'">
                            <v-flex xs12>
                                Enter cancellation note why this voucher is being cancel
                            </v-flex>
                            <v-flex xs12 mt-2>
                                <input-wrapper :field="fields.cancellation_notes" />
                            </v-flex>
                        </v-layout>

                        <v-layout row wrap v-if="dialog.mode === 'reactivate' || dialog.mode === 'expiry'">
                            <v-flex xs12>
                                <span v-if="dialog.mode === 'reactivate'">
                                    Add the reason why / when / by who this voucher is being reactivate
                                </span>
                                <span v-if="dialog.mode === 'expiry'">
                                    Add the reason why / when / by who expiry date is being change
                                </span>
                            </v-flex>
                            <v-flex xs12 mt-2>
                                <textarea name="dialog-notes" type="text" v-model="notes" @change="updateNotes"></textarea>
                            </v-flex>
                            <v-flex xs12 mt-1>
                                Enter new expiration date:
                            </v-flex>
                            <v-flex xs12>
                                <input :value="expiryLocal" type="datetime-local" id="new-expiry" name="new-expiry">
                            </v-flex>
                            <v-flex xs12 mt-4 v-if="dialog.mode === 'expiry'">
                                <v-btn color="info" @click="editExpires">Change expiry date</v-btn>
                            </v-flex>
                            <v-flex xs12 v-if="dialog.mode === 'expiry'">
                                Remember you still need to save the voucher page to update your changes!
                            </v-flex>
                        </v-layout>

                        <v-layout row class="margin-top-medium" v-if="dialog.mode === 'reactivate' || dialog.mode === 'cancel'">
                            <v-flex xs12 md10 lg8>
                                <v-expansion-panel >
                                    <v-expansion-panel-content>
                                        <div slot="header">Do you really want to {{ dialog.mode }} this voucher?</div>
                                        <v-card>
                                            <v-card-text>
                                                <v-layout row wrap>
                                                    <v-flex xs12>
                                                        <v-btn color="error" @click="cancel" v-if="dialog.mode === 'cancel'">Yes, cancel</v-btn>
                                                        <v-btn color="info" @click="reactivate" v-if="dialog.mode === 'reactivate'">Yes, reactivate</v-btn>
                                                    </v-flex>

                                                    <v-flex xs12 mt-3>
                                                        Remember you still need to save the voucher page to update your changes!
                                                    </v-flex>

                                                </v-layout>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" flat @click="closeDialog">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

<!-- Success and error snack bars -->
        <v-snackbar
                :timeout="parseInt(2000)"
                v-model="successSnackbar"
                multi-line
                color="ies-green"
        >
            <div class="full-height full-width v-align-container text-xs-center">
                <h4 class="ies-dark-gray--text v-align-div">Voucher updated</h4>
            </div>
        </v-snackbar>
        <v-snackbar
                :timeout="parseInt(3000)"
                v-model="errorSnackbar"
                multi-line
                color="ies-coral"
        >
            <div class="full-height full-width v-align-container text-xs-center">
                <h4 class="ies-dark-gray--text v-align-div">Error {{ errorMessage }}</h4>
            </div>
        </v-snackbar>
    </v-container>
</template>


<script>
    import { format, compareAsc, parse } from 'date-fns';
    import axios from 'axios';
    import {progressBar} from '@/mixins/progressBar';
    import InputWrapper from '@/components/inputs/Wrapper';
    import NotEditable from '@/components/inputs/NotEditable';

    import RecordHistory from '@/components/common/RecordHistory/DynamicRecordHistory';

    export default {
        props: ['voucheroriginal', 'user', 'consts'],
        mixins: [progressBar],
        components: {
            InputWrapper, NotEditable, RecordHistory
        },
        data() {
            return {
                voucher: this.voucheroriginal,
                showRememberAlert: false,
                successSnackbar: false,
                errorSnackbar: false,
                errorMessage: '',
                dialog: {
                    show: false,
                    mode: 'cancel', // this can have three modes: cancel / reactivate / expires
                },
                cannotReactiveReason: '',

                expiryLocal: '',
                notes: this.voucheroriginal.notes,
                // meta data will be passed to ResendVoucher and RecordHistory component
                metaData: {
                    primaryKey: this.voucheroriginal.voucher_key,
                    editUrl: 'Vouchers',
                    fieldName: 'voucher_key',
                    value: this.voucheroriginal.voucher_key,
                },

                // editable fields
                editableFields: ['is_promotional', 'is_referral', 'is_delivered', 'purchaser_email', 'recipient_email', 'to_text', 'message', 'cancellation_notes'],
                editableSelects: ['creation_purpose'],
                fields: {
                    purchaser_email: {
                        displayType: 'email',
                        fieldName: 'purchaser_email',
                        errors: false,
                        value: this.voucheroriginal.purchaser_email,
                        schema: { displayName: 'Purchaser email' },
                    },
                    recipient_email: {
                        displayType: 'email',
                        fieldName: 'recipient_email',
                        errors: false,
                        value: this.voucheroriginal.recipient_email,
                        schema: { displayName: 'Recipient email' },
                    },
                    to_text: {
                        displayType: 'text',
                        fieldName: 'to_text',
                        errors: false,
                        value: this.voucheroriginal.to_text,
                        schema: { displayName: 'To text' },
                    },
                    message: {
                        displayType: 'textarea',
                        fieldName: 'message',
                        errors: false,
                        value: this.voucheroriginal.message,
                        schema: { displayName: 'Voucher message' },
                    },
                    is_promotional: {
                        displayType: 'switch_blue',
                        fieldName: '',
                        errors: false,
                        value: this.voucheroriginal.is_promotional,
                        schema: { displayName: 'Is promotional' },
                    },
                    is_referral: {
                        displayType: 'switch_red',
                        fieldName: 'is_referral',
                        errors: false,
                        value: this.voucheroriginal.is_referral,
                        schema: { displayName: 'Is referral' },
                    },
                    cancellation_notes: {
                        displayType: 'textarea',
                        fieldName: 'cancellation_notes',
                        errors: false,
                        value: this.voucheroriginal.cancellation_notes,
                        schema: { displayName: 'Cancellation notes' },
                    },
                    is_delivered: {
                        displayType: 'switch',
                        fieldName: 'is_delivered',
                        errors: false,
                        value: this.voucheroriginal.is_delivered,
                        schema: { displayName: 'Is delivered' },
                    },
                    creation_purpose: {
                        displayType: 'select',
                        fieldName: 'creation_purpose',
                        errors: false,
                        oldValue: this.voucheroriginal.creation_purpose,
                        value: this.voucheroriginal.creation_purpose,
                        schema: { displayName: 'Creation Purpose' },
                        list: [
                            {text: 'Replacement', value: 1},
                            {text: 'Freebie/goodwill', value: 2},
                            {text: 'Competition gift', value: 3},
                            {text: 'Test', value: 4},
                            {text: 'None', value: null},
                        ]
                    },
                },
            }
        },
        methods: {

            /**
             * Trigger voucher save through API
             */
            save() {
                // assign edited values from fields to this.voucher object
                this.assignFieldsValues();

                this.callApi();
            },

            /**
             * Assign all editable field values which are separate vue component to main this.voucher object
             * so it contains the same values what are displayed on page
             */
            assignFieldsValues() {
                // assign fields
                this.editableFields.forEach((field) => {
                   this.voucher[field] = this.fields[field]['value'];
                });

                // assign selects, select do not hold data in this.field object but in hidden input
                this.editableSelects.forEach((field) => {
                    // get this select hidden input that holds value
                    let input = document.querySelector("input[name="+field+"]");

                    if (input) {
                        this.voucher[field] = input.value;
                    }
                })

            },

            /**
             * Trigger voucher cancellation
             */
            cancel() {
                // update current voucher data
                this.voucher.is_cancelled = true;
                this.voucher.is_active = false;
                this.voucher.cancellation_notes = this.fields.cancellation_notes.value;
                this.voucher.deactivate_action = this.consts.ACTION_CANCELLATION;
                this.voucher.cancelled_by_user_key = this.user.user_key;
                this.voucher.cancelled_by_username = this.user.username;
                this.voucher.cancelled_date = format(new Date(), 'YYYY-MM-DD HH:mm:ss');
                this.voucher.deactivate_date = format(new Date(), 'YYYY-MM-DD HH:mm:ss');
                this.closeDialog();
            },

            /**
             * Trigger reactivation of cancelled voucher,
             * voucher can be reactivated only if it was cancelled, we cannot reactivate redeemed/expired vouchers
             */
            reactivate() {
                let newExpires = this.checkExpiryDate();
                if (newExpires) {
                    // check if new Expires is from the future and we can mark this voucher as Active
                    let expires = parse(newExpires);
                    let now = new Date();

                    let expiresInFuture = (compareAsc(expires, now) === 1) ? true : false;

                    if (expiresInFuture) {
                        // create copy of current deactivate actions, save them in voucher notes
                        let currentDeactivate = ". Voucher reactivated on:"+ format(now, 'YYYY-MM-DD') +", Previous deactivate_action: " +
                            this.deactivateActionAsString(this.voucher.deactivate_action) +
                            ", previous deactivated date: "+ this.voucher.deactivate_date;
                        if (this.voucher.cancellation_notes) {
                            currentDeactivate += ", previous cancellation_notes: " + this.voucher.cancellation_notes;
                        }

                        // update voucher notes
                        let currentNote = this.notes ? this.notes : '';
                        this.notes = currentNote + currentDeactivate;
                        this.updateNotes();

                        // clear deactivation data
                        this.voucher.deactivate_action = null;
                        this.voucher.deactivate_date = null;
                        this.cancelled_date = null;
                        this.cancelled_by_user_key = null;
                        this.cancellation_notes = null;
                        this.is_cancelled = false;

                        // set new expiry date
                        this.voucher.expires = newExpires;

                        // set voucher to active
                        this.voucher.is_active = true;

                        this.closeDialog();
                    }
                    else {
                        alert('New expiry date is from the past, voucher can not be reactivated, enter expiry date from the future if you wish to reactivate this voucher');
                    }
                }
            },

            /**
             * Check if new entered date is correct format, if so update this.voucher expiry date
             * close dialog if success
             */
            editExpires() {
                let newExpires = this.checkExpiryDate();
                if (newExpires) {
                    this.voucher.expires = newExpires;
                    this.closeDialog();
                }
            },

            updateNotes() {
                this.voucher.notes = this.notes;
            },

            /**
             * Sends ajax to our vouchers API endpoint and updates this voucher data
             */
            callApi() {
                this.progressIconShow();
                this.clearApiErrors();

                let dataToSend = {'voucher':this.voucher};
                const apiUrl = 'api/vouchers/' + this.voucher.voucher_key;

                // send ajax call
                axios.post(apiUrl, dataToSend)
                    .then(function(response) {

                        if (response.data.success) {
                            this.showSnackbar('success');
                        }
                        else {
                            this.showSnackbar('error', response.data.data.message);

                            this.showApiErrors(response.data.data.errors);
                        }
                        this.progressIconHide();
                    }.bind(this))
                    .catch(function(error) {
                        this.showSnackbar('error', 'There was a problem with API call');
                        this.progressIconHide();
                    }.bind(this));
            },

            /**
             * Check if expiry date set in <input> is correct format and can be parsed
             * @return string (date string) or false
             */
            checkExpiryDate() {
                let dateControl = document.getElementById('new-expiry');
                let dateObject = parse(dateControl.value);
                let dateString = format(dateObject, 'YYYY-MM-DD hh:mm:ss');

                if (!dateString || dateString === 'Invalid Date') {
                    alert('You enter invalid expiry date date');
                    return false;
                }
                else {
                    return dateString;
                }
            },

            /**
             * Show errors that came after back-end validation from API
             */
            showApiErrors(errors) {
                let errorFields = Object.keys(errors);
                if (errorFields.length) {
                    errorFields.forEach((field) => {
                        this.fields[field]['errors'] = errors[field];
                    })
                }
            },

            /**
             * Clear all the error messages from dynami inputs
             */
            clearApiErrors() {
                this.fields.purchaser_email.errors = false;
                this.fields.recipient_email.errors = false;
                this.fields.to_text.errors = false;
                this.fields.is_promotional.errors = false;
                this.fields.is_referral.errors = false;
                this.fields.cancellation_notes.errors = false;
                this.fields.is_delivered.errors = false;
                this.fields.creation_purpose.errors = false;
            },

            /**
             * Replaces silly 00:00:00 from date string and return date time string as one
             * @param {string} date
             * @param {string} time
             * @returns {string}
             */
            parseDate(date, time) {
                if (date && time) {
                    let dateString = date.replace(' 00:00:00', '');
                    let dateTimeString = dateString + ' ' + time;

                    return dateTimeString;
                }
                else {
                    return '';
                }

            },


            /**
             * Show success or error snack bar
             * @param snack string - success|error
             * @param message = false
             */
            showSnackbar(snack, message = false) {
                var _this = this;

                switch (snack) {
                    case 'success' :
                        _this.successSnackbar = true;
                        break;
                    case 'error' :
                        _this.errorMessage = message ? message : '';
                        _this.errorSnackbar = true;
                        break;
                }
            },

            /**
             * Setting dialog mode and opening it
             * @param string mode - cancel, reactivate or expiry
             */
            openDialog(mode) {
                // make sure expiry date is always up to date
                this.expiryLocal = this.expiryToDateLocal;

                if (mode === 'cancel' || mode === 'reactivate' || mode === 'expiry') {
                    this.dialog.mode = mode;
                    this.dialog.show = true;
                }
            },

            closeDialog() {
                this.dialog.show = false;
            },

            /**
             * Show deactivate action as string
             */
            deactivateActionAsString(action) {
                switch (action) {
                    case this.consts.ACTION_REDEMPTION:   return 'Redeemed';    break;
                    case this.consts.ACTION_EXPIRATION:   return 'Expired';     break;
                    case this.consts.ACTION_CANCELLATION: return 'Cancelled';   break;
                }
                return 'unknown';
            },

        },
        /**
         * Methods that initialized this page on page load
         */
        mounted() {

        },
        computed: {
            /**
             * Return created at as date time string without 00:00:00 time what is stored for creation_date
             * @returns {string}
             */
            createdAt() {
                return this.parseDate(this.voucher.creation_date, this.voucher.creation_time);
            },

            /**
             * Return last_updated_date at as date time string without 00:00:00 time what is stored for last_updated_date
             * @returns {string}
             */
            editedAt() {
                return this.parseDate(this.voucher.last_updated_date, this.voucher.last_updated_time);
            },

            /**
             * Return alert type depending if voucher is active
             * @return {string}
             */
            alertType() {
                if (this.voucher.is_active) {
                    return 'success';
                }
                else {
                    return 'error';
                }
            },

            status() {
                return this.voucher.deactivate_action;
            },

            /**
             * Show deactivation action as string
             * @returns {string}
             */
            actionStatus() {
                if (this.voucher.is_active) {
                    return 'Active';
                }
                else {
                    switch (this.voucher.deactivate_action) {
                        case this.consts.ACTION_REDEMPTION:   return 'Redeemed';    break;
                        case this.consts.ACTION_EXPIRATION:   return 'Expired';     break;
                        case this.consts.ACTION_CANCELLATION: return 'Cancelled';   break;
                    }
                }

                return 'Inactive';
            },

            /**
             * Transform source page to string using voucher constants
             * @returns {string}
             */
            sourcePage() {
                switch (this.voucher.source_page) {
                    case this.consts.SOURCE_GIFT_PAGE:   return 'Gift page';    break;
                    case this.consts.SOURCE_PORTAL:      return 'Portal';       break;
                    case this.consts.SOURCE_REISSUE:     return 'Re-issue';     break;
                    case this.consts.SOURCE_BIRTHDAY:    return 'Birthday';     break;
                }
                return 'unknown';
            },

            /**
             * Transform voucher type key to string using voucher constants
             * @returns {string}
             */
            voucherType() {
                let type = 'unknown';

                if (this.voucher.voucher_type === this.consts.VOUCHER_PHYSICAL) {
                    type = 'Physical';
                }
                else if (this.voucher.voucher_type === this.consts.VOUCHER_DIGITAL) {
                    type = 'Digital';
                }

                return type;
            },

            /**
             * Transform delivery type key to string using voucher constants
             * @returns {string}
             */
            deliveryType() {
                switch (this.voucher.delivery_type) {
                    case this.consts.DELIVERY_UK:       return 'UK';        break;
                    case this.consts.DELIVERY_EUROPE:   return 'Europe';    break;
                    case this.consts.DELIVERY_WORLD:    return 'World';     break;
                }
                return 'unknown';
            },

            /**
             * Check if voucher can be cancelled
             * Voucher can be cancelled only if it's active
             * @return boolean
             */
            canBeCancelled() {
                if (this.voucher.is_active) {
                    return true;
                }
                else {
                    return false;
                }
            },

            /**
             * Check if voucher can be reactivated
             * Voucher can be reactivated only if:
             * - been cancelled before but is not expired
             * - is only expired
             * @return boolean
             */
            canBeReactivated() {
                let expires = parse(this.voucher.expires);
                let now = new Date();

                let expiredAlready = (compareAsc(now, expires) === 1) ? true : false;

                // do not even try to reactivate redeemed voucher
                if (this.voucher.redeemed_value && this.voucher.redeemed_value > 0) {
                    if (this.voucher.redeemed_value >= this.voucher.value) {
                        this.cannotReactiveReason = 'Voucher has been fully redeemed';
                    } else if (this.voucher.redeemed_value < this.voucher.value) {
                        this.cannotReactiveReason = 'Voucher has been partially redeemed';
                    }
                    return false;
                }

                // if been cancelled but it's not expired
                if (this.voucher.is_cancelled && this.voucher.deactivate_action === this.consts.ACTION_CANCELLATION && !expiredAlready) {
                    return true;
                } else if (this.voucher.is_cancelled && this.voucher.deactivate_action === this.consts.ACTION_CANCELLATION && expiredAlready) {
                    this.cannotReactiveReason = 'Voucher has been cancelled and has expired';
                }

                // only expired
                if (expiredAlready && this.voucher.deactivate_action === this.consts.ACTION_EXPIRATION) {
                    return true;
                } else if ((expiredAlready && this.voucher.deactivate_action !== this.consts.ACTION_EXPIRATION) || !expiredAlready) {
                    this.cannotReactiveReason = 'Voucher is still active';
                }

                return false;
            },

            /**
             * Convert expiry date format to datetime-local format accepted by <input type="datetime-local">
             * @returns {string}
             */
            expiryToDateLocal() {
                let expires = parse(this.voucher.expires);
                let expiresLocal = format(expires, 'YYYY-MM-DDThh:mm');
                return expiresLocal;
            },

            /**
             * Convert creation_purpose key into string
             * @returns {string}
             */
            creationPurpose() {
                let purpose = '';

                switch (this.voucher.creation_purpose) {
                    case 1 : purpose = 'Replacement';      break;
                    case 2 : purpose = 'Freebie/Goodwill'; break;
                    case 3 : purpose = 'Competition gift'; break;
                    case 4 : purpose = 'Test';             break;
                }

                return purpose;
            }
        },
        watch: {

        },
    };
</script>

<style scoped>
    .editWrapper {
        padding: 20px;
    }

    .voucherActive {
        color: #C1CD23;
    }

    .voucherInactive {
        color: red;
    }

    .alertStatus {
        font-weight: bold;
        font-size: 16px;
    }

    .customerInfo .input-group {
        padding: 2px 0 0;
    }

    .editWrapper .container {
        padding:0;
        margin-top: 5px;
    }

    .editWrapper .input-group__details {
        display:none!important;
    }

    .switchesWrapper >>> .ies-switch {
        margin-top: 10px;
    }

    .cancellationWrapper >>> textarea {
        height: 100px;
    }

    .dialog >>> textarea {
        width: 300px;
    }

    #linearWrapper {
        height: 7px;
        width: 100%;
    }

    .progress-linear {
        margin: 0;
    }

    .editExpiryBtn:hover {
        cursor: pointer;
        color: green;
    }
</style>
