<template>
    <v-flex xs6 ma-1>
        <v-card>
            <v-card-text>
               
                <v-layout row>
                    <v-btn disabled>Invoicing</v-btn>
                    <v-btn disabled>Receipts</v-btn>
                    <v-btn disabled>Refunds & Adjustments</v-btn>
                    <v-btn disabled>Vouchers</v-btn>
                    <v-btn disabled>Reports</v-btn>
                </v-layout>
                
            </v-card-text>
        </v-card>
    </v-flex>
</template>

<script>
    /**
     * Display buttons linking through to accounts reports
     */
    export default {
        props:[],

        data: function() {
            return {
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){},

        methods: {},

        computed: {}
    }
</script>
