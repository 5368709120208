<template>
<v-card xs12 class="mt-3 mb-3" v-if="this.isReady" flat>

  <v-flex v-if="this.isBroken">Sorry, this content is not working</v-flex>

  <v-flex v-if="this.isReady && !this.isBroken">
    <ies-searchlist   datasource="pageData0" :fields="fields"></ies-searchlist>
    <ies-list-options datasource="pageData0"></ies-list-options>

    <v-layout row wrap>
        <v-col cols="12" v-if="!this.data.length">Sorry, there are no results to show you</v-col>
        <v-col cols="12" v  if="this.data.length">
        <ies-list-stack datasource="pageData0" 
                            :page="this.pageRecord" 
                            :fields="this.fields"
                            :content="this.data"></ies-list-stack>
        </v-col>
        <v-col cols="12" v if="this.data.length">
        <ies-list-table datasource="pageData0" 
                            :page="this.pageRecord" 
                            :fields="this.fields"
                            :content="this.data"></ies-list-table>
        </v-col>
    </v-layout>

    <ies-list-options datasource="pageData0"></ies-list-options>
  </v-flex>
</v-card>
</template>


<script>
    // Import the application event bus so we can listen for user or menu data changes and react accordingly
    import EventBus from '../../eventBus';
    import axios from 'axios';

    // @todo review if just getting the server to return it as a CSV is sufficient, we dont want dependencies if we can avoid them
    import FileSaver from 'file-saver';

    // Export the component for inclusion within the page
    export default {
        props: ['page', 'sectionfields', 'content'],

        /**
         * Core data required by the component and template
         */
        data: function(){
            return {
                /** @var bool Is this component ready for use? true|false */
                isReady: false,

                /** @var bool Is this component broken and unusable? true|false */
                isBroken: false,

                /** @var The list of filters to be applied to the search */
                filters:     {tool: 'none', condition: 'AND', fields: []},

                /** @var Details about the page being viewed, used to load more data etc */
                pageRecord:    {},

                /** @var int pageNumber The index number of the batch of results being viewed, used for pagination of results */
                pageNumber: 0,

                rowsPerPage: 20,

                /** @var The results of the data */
                data:        {},

                /** @var Flag identifying if the page has finished loading yet */
                loaded:      false,

                /** @var Object live view timer instance */
                liveViewTimer: null
            };
        },

        /**
         * On page load complete build the component
         */
        mounted: function(){
            try {
                //console.log("list component live:", this);
                this.pageRecord = JSON.parse(this.page);
                this.fields = JSON.parse(this.sectionfields);
                this.data = JSON.parse(this.content);

                //console.log("list parent: page:", this.pageRecord);
                //console.log("list parent: data", this.data);

                this.setFiltersFromURL();

                this.registerEventListeners();

                this.isReady = true;
                this.loadData();
            }
            catch(exception){
                console.log(exception);
                this.isBroken = true;
            }
        },

        /**
         * Business logic functions performing useful tasks for this component
         */
        methods: {
            registerEventListeners: function(){
                var self = this;

                // A component has asked the list to obtain a batched segment of the paginated results from the API
                this.$root.$on('List: Change page', function(payload){
                    //console.log("List parent: I should change the pagination to page: " + payload.pageNumber); 
                    self.setPagination(payload.pageNumber);
                    self.loadData();
                });

                this.$root.$on('List: Change rows per page', function(payload){
                    //console.log("List parent: I should change the number of rows per pagination: " + payload.rowsPerPage); 
                    self.setRowsPerPage(payload.rowsPerPage);
                    self.loadData();
                });
/*
                this.$root.$on('List: Quick Search', function(payload){
                    //console.log("List parent: I should do a quick search for ", payload);
                    self.performQuickSearch(payload);
                });
*/
                this.$root.$on('List: Search', function(payload){
                    console.log("List parent: I should search for ", payload);
                    self.performSearch(payload);
                });

                /**
                 * The request to change the sort orders should include an object containing
                 * the list of filters to be applied
                 */
                this.$root.$on('List: Set sorting', function(payload){
                    //console.log("List parent: I should set sorting by ", payload);
                    self.sortOrders = payload.orderedFields;
                    self.loadData();
                });

                this.$root.$on('List: Refresh', function(payload){
                    //console.log("List parent: I should refresh: " + payload); 
                    self.loadData();
                });

                this.$root.$on('List: Auto Refresh', function(payload){
                    //console.log("List parent: I should live refresh: " + payload); 
                    self.liveRefresh(payload);
                });

                this.$root.$on('List: New record', function(payload){
                    //console.log("List parent: I should create record: " + payload); 
                    self.goToNewRecord();
                });

                this.$root.$on('List: Edit record', function(payload){
                    //console.log("List parent: I should edit record: " + payload); 
                    self.goToEditRecord(payload.recordID);
                });

                this.$root.$on('List: Download CSV', function(payload){
                    //console.log("List parent: I should download CSV: "); 
                    self.downloadCSV();
                });

                this.$root.$on('List: Send page', function(payload){
                    //console.log("List parent: I should send link to page: " + payload); 
                    self.emailPage();
                });

                this.$root.$on('List: Print page', function(payload){
                    //console.log("List parent: I should print page: " + payload); 
                    self.printPage();
                });

                this.$root.$on('List: Report problem', function(payload){
                    //console.log("List parent: I should report problem on page: " + payload); 
                    self.reportProblem();
                });
            },

            setPagination: function(pageIndex){
                this.pageNumber = pageIndex;
            },
            setRowsPerPage: function(rowsPerPage){
                this.rowsPerPage = rowsPerPage;
            },

            performQuickSearch: function(searchData){
                console.log(searchData);
                alert("Functionality not yet complete");
            },

            /**
             * Replaces the filters and then requests a new data batch from the API
             */
            performSearch: function(searchData){
                //console.log("will perform a search", searchData);
                //console.log("current filters", this.filters);
                console.log("new filters", searchData.filters);
console.log(searchData);
                this.filters = searchData;
             //   if (searchData.tool == "quickSearch"){
//
  //              }
    //            else {
      //              this.filters = {''};
        //        }

                //this.filters = searchData;
                //this.filters = searchData.filters;
                this.loadData();
            },

            goToNewRecord: function(){
                if (this.pageRecord.create_url){
                    window.location = '/' + this.pageRecord.create_url;
                }
                // By default we view a record in edit mode by visiting this same URL but with the ID on the end
                else {
                    window.location = '/' + this.pageRecord.page_name + '/create';
                }
            },

            goToEditRecord: function(recordID){
                // If the page design wants to let a specific page handle the view/edit of the record
                // then use that URL - at the moment it is presumed to just append the ID on the end
                if (this.pageRecord.edit_url){
                    window.location = '/' + this.pageRecord.edit_url + '/' + recordID;
                }
                // By default we view a record in edit mode by visiting this same URL but with the ID on the end
                else {
                    window.location = '/' + this.pageRecord.page_name + '/' + recordID;
                }
            },
            emailPage: function(){
                //console.log('mailto:?subject=' + encodeURIComponent(this.pageRecord.heading) + '%20on%20Portal&body=' + encodeURIComponent(this.pageRecord.heading) + '%20available%20here%3A%20'  + encodeURIComponent(window.location.href));
                window.location = 'mailto:?subject=' + encodeURIComponent(this.pageRecord.heading) + '%20on%20Portal&body=' + encodeURIComponent(this.pageRecord.heading) + '%20available%20here%3A%20'  + encodeURIComponent(window.location.href);
            },

            reportProblem: function(){
                //console.log('mailto:web@i-escape.com?subject=Problem on page ' + encodeURIComponent(this.pageRecord.page_name) + '&body=There%20is%20a%20problem%20on%20page%20' + encodeURIComponent(this.pageRecord.heading) + '%20available%20here%20'  + encodeURIComponent(window.location.href));
                window.location = 'mailto:web@i-escape.com?subject=Problem on page ' + encodeURIComponent(this.pageRecord.page_name) + '&body=There%20is%20a%20problem%20on%20page%20' + encodeURIComponent(this.pageRecord.heading) + '%20available%20here%20'  + encodeURIComponent(window.location.href);
            },

            printPage: function(){
                window.print();
            },

            /**
             *
             */
            loadData: function(){
                var queryURL = '/ajax/' + this.pageRecord.page_name + '/list/filter';
                console.log("list parent: load data from url ", queryURL);
                this.$root.$emit('List: Loading data', {'id': 'notset'});
                console.log(this.filters);

                axios.post(queryURL,{
                    filters: this.filters,
                    sortOrders: this.sortOrders,
                    pageNumber: this.pageNumber,
                    rowsPerPage: this.rowsPerPage,
                    primaryModel: this.primaryModel,
                })
                .then(function(response) {
                    if (response.status === 200) {
                        if (response.data.success) {
                            //this.addPageData(name, response.data.data);
                            console.log('response data data',response.data.data);
                            //this.fields = response.data.data.fields;
                            this.data = response.data.data.data;
                            this.rowsPerPage = response.data.data.pagesPerRow;

                            // Pass this on to any other component which is working alongside this one
                            console.log("i will pass ", response.data.data);
                            this.$root.$emit('List: Data updated', {'id': 'notset', 'data': response.data.data});

                            this.updateURL();
                        }
                        else{
                            alert('An error was encountered when trying obtain data from the server.  Please report this problem to web@i-escape.com');
                        }
                    }
                }.bind(this))
                .catch(function(error) {
                    console.log('Error occured during loading page data in List pageService');
                }.bind(this));
            },

            /**
             * Ask the list to auto-update periodically so the data is never too out-of-date
             */
            liveRefresh: function(payload){
                console.log("List parent: live view toggled", payload);
                if (payload.liveRefresh){
                    console.log("Live view on");
                    this.liveViewTimer = setInterval(this.loadData, 15000);
                }
                else {
                    console.log("Live view off");
                    clearInterval(this.liveViewTimer);
                }

                this.$root.$emit('List: Live view changed', {'id': 'notset', 'liveRefresh': payload.liveRefresh});
            },

            /**
             * Open the creation page for adding a new record
             */
            addNew: function(){
                console.log("list parent: add new record");
            },

            /**
             * Triggers a download of the data in CSV format
             */
            downloadCSV: function(){
                console.log("list parent: download csv");
                var _this = this;

                // build data object that we have to send with AJAX call
                var params = {filters: this.filters, primaryModel: this.pageRecord.primaryModel};
                var url = '/' + this.pageRecord.page_name + '/list?format=csv';

                // AJAX request to i-escape server to get the same data as a CSV formatted data
                axios.get(url, params)
                    .then(function(response) {
                        if(!response.data.success) {
                            _this.exportFile(response.data);
                        }
                        else if(response.data.success) {
                            alert('There was a problem exporting the CSV file');
                        }
                    }.bind(this));

            },

            /**
             * Generates the file with given content
             */
            exportFile(csv) {
                console.log("list parent: export file", csv);
                var filename = this.generateFilename();

                // create file
                var file = new Blob([csv], {type: 'text/csv;charset=utf-8'});
                FileSaver.saveAs(file, filename);
            },

            /**
             * Methods adds current date to filename
             */
            generateFilename() {
                // get current page to include in filename
                var pageName = this.pageRecord.page_name;

                var today = new Date();
                var todayString = '_' + today.getDate() + '_' + (Number(today.getMonth())+1) + '_' + today.getFullYear();
                var filename = pageName + todayString + '.csv';

                return filename;
            },


            /**
             * Triggers a download of the data in a PDF document
             */
            downloadPDF: function(){
                alert('Sorry, this feature is not yet available');
            },

            /**
             * Query the URL to determine what filters have been requested, if any
             * set the filters to equal those, but only provided that the page 
             */
            setFiltersFromURL: function(){
                var urlParts = window.location.href.split('?');
                var self = this;

                if (urlParts.length > 0){
                    console.log("Has query string portion", urlParts[1]);
                    var searchParams = new URLSearchParams(window.location.search);
                    console.log("Should update the filters from the following query params", searchParams);

                  // Check if the query string says there is a search (s) in place, don't do work if it is not
                  if (searchParams.has('s')){
                      console.log("has search", searchParams.get('s'));
                      // If it is an advanced search (s=as) then we need to make a separate filter for each
                      switch (searchParams.get('s').toLowerCase()){
                          case 'as':
                              console.log("is advanced search");

                              //console.log(searchParams.getAll('c[]'));
                              //console.log(searchParams.getAll('o[]'));
                              //console.log(searchParams.getAll('v[]'));

                              var columns = searchParams.getAll('c[]')
                              var operations = searchParams.getAll('o[]')
                              var values = searchParams.getAll('v[]')
                              if (columns.length === operations.length && operations.length === values.length){
                                  this.filters = {tool:'advancedSearch', condition: 'AND', fields: []};
                                  for (var index = 0; index < columns.length; index++){
                                      var columnParts = columns[index].split('-');
                                      this.filters.fields.push({ modelName: columnParts[0], fieldName: columnParts[1], operator: operations[index], value: values[index], tool: "advancedSearch" });
                                  }
                              }
                              else {
                                  console.log("different number of search parameters in the query string");
                              }
                              break;

                          // If it is a quick search then there will just be a single value and we search in all of the fields
                          // for anything containing it
                          case 'qs':
                              console.log("is quick search");
                              var value = searchParams.get('value');
                              this.filters = {tool: 'quickSearch', condition: 'OR', fields: []};
                              this.fields.forEach(function(field){
                                  self.filters.fields.push({ modelName: field.modelName, fieldName: field.fieldName, operator: "LIKE", value: value, tool: "quickSearch" });
                              });
                              break;
                       }
                   }
                }
            },

            /**
             * @note I actually think that this will need to be converted to something more obscure like a reference
             * number or hash or something to reference the field without needing to tell the world what model and
             * database field names we are using.  Unfortunately we do expose this information anyway, but we don't
             * want to encourage this further
             */
            updateURL: function(){
                console.log("Will update url now");
                console.log("data: ", this.data);
                console.log("filters: ", this.filters);

                if (this.filters && this.filters.fields.length){
                    var queryString = "?";

                    // This is quite crude, but the data saying what search was done is in the filters - this is perhaps a
                    // mistake or oversight, but should be easily rectified in future, but right now we just need to check
                    // the first filter to see what tool is used instead of having it in the this.filters as a property itself

                    // If it is a quick search then they're all the same, we don't need to list them all in a huge url
                    if (this.filters.tool == "quickSearch"){
                        queryString += "s=qs&value=" + this.filters.fields[0].value;
                    }

                    // If it is an advanced search then we need to list the exact details of the query parameters being applied
                    if (this.filters.tool == "advancedSearch"){
                        queryString += "s=as";

                        for(var index = 0; index < this.filters.fields.length; index++){
                            var filter = this.filters.fields[index];
                            queryString += '&c[]=' + filter.modelName + '-' + filter.fieldName + "&o[]=" + filter.operator + '&v[]=' + filter.value;
                        }
                    }

                    var url = window.location.href.split('?')[0] + queryString;

                    console.log(url);
                    // Add the new url to the browser's history so the user can return to it when interacting
                    history.pushState({ page: 1 }, document.title, url);
                }
            },

            /**
             * Triggers the browser to open a print dialogue box
             */
            printPage: function(){
                window.print();
            },

            /**
             * Turns on/off the periodic auto-refresh the data
             *
             */
            toggleLiveView: function(){
                console.log("list parent: toggle live view");
            },
        }
    }

</script>
