var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "row-sm": "", column: "" } },
        [
          this.field.schema.displayName != "none"
            ? _c("v-flex", { attrs: { xs12: "" } }, [
                _c("label", { attrs: { for: this.field.fieldName } }, [
                  _vm._v(" " + _vm._s(this.field.schema.displayName) + ":"),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          this.field.introduction
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _vm._v(_vm._s(this.field.introduction)),
              ])
            : _vm._e(),
          _vm._v(" "),
          this.field.information
            ? _c(
                "v-alert",
                { attrs: { type: "info", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.information))]
              )
            : _vm._e(),
          _vm._v(" "),
          this.field.warning
            ? _c(
                "v-alert",
                { attrs: { type: "warning", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.warning))]
              )
            : _vm._e(),
          _vm._v(" "),
          this.field.error
            ? _c(
                "v-alert",
                { attrs: { type: "error", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.error))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "E-mail",
                  name: this.field.fieldName,
                  disabled: this.field.disabled,
                  rules: [_vm.rules.required, _vm.rules.email],
                },
                on: {
                  keyup: function ($event) {
                    return _vm.updateField()
                  },
                },
                model: {
                  value: _vm.input,
                  callback: function ($$v) {
                    _vm.input = $$v
                  },
                  expression: "input",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.field.errors
            ? _c(
                "v-alert",
                {
                  staticStyle: { width: "100%" },
                  attrs: { type: "error", value: _vm.field.errors },
                },
                _vm._l(_vm.field.errors, function (error) {
                  return _c("li", [
                    _vm._v(
                      "\n               " + _vm._s(error) + "\n           "
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }