<template>
    <v-container fluid grid-list-md>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                     <v-card-text>
                         <v-container fluid v-if="reservation.show || 1">
                              <v-layout row wrap>
                                  <v-flex xs12>
                                       The client has declined your quote. Please acknowledge the enquiry is now cancelled.
                                  </v-flex>

                                  <v-flex xs4>
                                      <strong>Rooms</strong>
                                  </v-flex>
                                  <v-flex xs8>
                                      <div v-for="(room, index) in reservation.rooms">
                                          1 x {{ room.name }}
                                      </div>
                                  </v-flex>

                                  <v-flex xs4>
                                      <strong>Guests</strong>
                                  </v-flex>
                                  <v-flex xs8>
                                      ???
                                  </v-flex>

                                  <v-flex xs4>
                                      <strong>Rates</strong>
                                  </v-flex>
                                  <v-flex xs8>
                                      <div v-for="(rateType, index) in reservation.rateTypes">
                                          {{ rateType.name }} (reservation.stay_length) nights
                                      </div>
                                  </v-flex>
                        
                                  <v-flex xs4>
                                      <strong>Total Rate</strong>
                                  </v-flex>
                                  <v-flex xs8>
                                      ???
                                  </v-flex>
                                  <v-flex xs4>
                                      <strong>Tax</strong>
                                  </v-flex>
                                  <v-flex xs8>
                                      ???
                                  </v-flex>
                                  <v-flex xs4>
                                      <strong>Total Cost Inclusive</strong>
                                  </v-flex>
                                  <v-flex xs8>
                                      ???
                                  </v-flex>

                                  <v-flex xs4>
                                      <strong>Deposit</strong>
                                  </v-flex>
                                  <v-flex xs8>
                                      ???
                                  </v-flex>

                                  <v-flex xs4>
                                      <strong>Payment Policy</strong>
                                  </v-flex>
                                  <v-flex xs8>
                                      {{ reservation.payment_policy }}
                                  </v-flex>

                                  <v-flex xs4>
                                      <strong>Cancellation Policy</strong>
                                  </v-flex>
                                  <v-flex xs8>
                                      {{ reservation.cancellation_policy }}
                                  </v-flex>
                              </v-layout>
                          </v-container>
                      </v-card-text>

                      <v-card-actions>
                          <v-btn class="ies-green" v-on:click="confirmDeclination">Confirm Declination</v-btn>
                      </v-card-actions>
                 </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    /**
     * A Vue component dedicated to letting the user manage a reservations
     * by the ID specified
     *
     * Props:
     *     int 'reservationkey' - The ID of the reservation to display
     */

    import axios from 'axios';
    export default {
        name: 'hotel-reservation-enquiry',
        props: ['reservationkey'],
        data () {
            return {
                reservation: {}
            }
        },
        methods: {

            loadReservation(){
                axios.get('/api/reservation/' + this.reservationkey)
                .then(function(response){
                    this.reservation = response.data;
                }.bind(this));
            },

            /**
             * Actions to perform when confirming that the quote was 
             * declined by the client
             */
            confirmDeclination(){
                axios.post('/api/reservation/' + this.reservationkey + '/confirm-declined', this.reservation)
                .then(function(response){
                    this.reservation = response.data;
                    if (response.data.success){
                        alert("Confirmed Quote Declined");
                    }
                    else {
                        alert("An error occurred: Declination confirmation failed");
                    }
                }.bind(this));
            }
        },

        /**
         * Once mounted this component needs to load the reservation specified
         */
        mounted() {
            this.loadReservation();
        },

        computed: {}
    }
</script>
