var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "smart-search" },
    [
      _c(
        "v-layout",
        { attrs: { row: "" } },
        [
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "2" } },
            [_c("v-icon", [_vm._v("search")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "10" } },
            [
              _c("v-text-field", {
                on: {
                  keyup: [
                    function ($event) {
                      return _vm.performTextEntry()
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.performTextEntry()
                    },
                  ],
                },
                model: {
                  value: _vm.searchText,
                  callback: function ($$v) {
                    _vm.searchText = $$v
                  },
                  expression: "searchText",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.results.length
        ? _c(
            "v-layout",
            { attrs: { row: "" } },
            [_c("ies-smart-search-results")],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }