var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-flex",
    { attrs: { xs4: "", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("v-icon", [_vm._v("show_chart")]),
              _vm._v(" Statistics\n        "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-btn",
                { attrs: { block: "", href: "/accounts/conversions" } },
                [_vm._v("Commissions & Bookings")]
              ),
              _vm._v(" "),
              _c("v-btn", { attrs: { block: "", disabled: "" } }, [
                _vm._v("Top Hotels"),
              ]),
              _vm._v(" "),
              _c("v-btn", { attrs: { block: "", disabled: "" } }, [
                _vm._v("Conversion Report"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }