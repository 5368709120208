var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "3" } },
    [
      _c(
        "v-card",
        { staticClass: "ma-2" },
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("home")]),
              _vm._v(" Hotels\n    "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [_c("ies-hotel-picker")],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-btn",
                            { attrs: { block: "", href: "hotels/list" } },
                            [_vm._v("List All Hotels")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c(
                          "fieldset",
                          [
                            _c("legend", [_vm._v("Create New Hotel")]),
                            _vm._v(" "),
                            _vm.step == 1
                              ? _c(
                                  "div",
                                  [
                                    _c("h4", [_vm._v("1. Basic Details")]),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: { label: "Hotel Name" },
                                      model: {
                                        value: _vm.hotelName,
                                        callback: function ($$v) {
                                          _vm.hotelName = $$v
                                        },
                                        expression: "hotelName",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("v-select", {
                                      attrs: {
                                        label: "Region",
                                        items: _vm.regions,
                                        autocomplete: "",
                                      },
                                      model: {
                                        value: _vm.regionKey,
                                        callback: function ($$v) {
                                          _vm.regionKey = $$v
                                        },
                                        expression: "regionKey",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("v-select", {
                                      attrs: {
                                        label: "Display Status",
                                        items: _vm.displayStatuses,
                                        autocomplete: "",
                                      },
                                      model: {
                                        value: _vm.displayStatus,
                                        callback: function ($$v) {
                                          _vm.displayStatus = $$v
                                        },
                                        expression: "displayStatus",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("v-select", {
                                      attrs: {
                                        label: "Travel Operator",
                                        items: _vm.travelOperators,
                                        autocomplete: "",
                                      },
                                      model: {
                                        value: _vm.travelOperator,
                                        callback: function ($$v) {
                                          _vm.travelOperator = $$v
                                        },
                                        expression: "travelOperator",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: { label: "Commission (%)" },
                                      model: {
                                        value: _vm.commission,
                                        callback: function ($$v) {
                                          _vm.commission = $$v
                                        },
                                        expression: "commission",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("v-label", [
                                      _vm._v("Nearest Restaurant (km):"),
                                    ]),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: { label: "Distance" },
                                      model: {
                                        value: _vm.restaurantDistance,
                                        callback: function ($$v) {
                                          _vm.restaurantDistance = $$v
                                        },
                                        expression: "restaurantDistance",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("v-label", [
                                      _vm._v("Nearest Airport (km):"),
                                    ]),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: { label: "Name" },
                                      model: {
                                        value: _vm.airportName,
                                        callback: function ($$v) {
                                          _vm.airportName = $$v
                                        },
                                        expression: "airportName",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: { label: "Distance" },
                                      model: {
                                        value: _vm.airportDistance,
                                        callback: function ($$v) {
                                          _vm.airportDistance = $$v
                                        },
                                        expression: "airportDistance",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: { label: "Code" },
                                      model: {
                                        value: _vm.airportCode,
                                        callback: function ($$v) {
                                          _vm.airportCode = $$v
                                        },
                                        expression: "airportCode",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.step == 2
                              ? _c(
                                  "div",
                                  [
                                    _c("h4", [_vm._v("2. Contact Details")]),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: { label: "Address 1" },
                                      model: {
                                        value: _vm.address1,
                                        callback: function ($$v) {
                                          _vm.address1 = $$v
                                        },
                                        expression: "address1",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: { label: "Address 2" },
                                      model: {
                                        value: _vm.address2,
                                        callback: function ($$v) {
                                          _vm.address2 = $$v
                                        },
                                        expression: "address2",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: { label: "City" },
                                      model: {
                                        value: _vm.city,
                                        callback: function ($$v) {
                                          _vm.city = $$v
                                        },
                                        expression: "city",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: { label: "Post Code" },
                                      model: {
                                        value: _vm.postCode,
                                        callback: function ($$v) {
                                          _vm.postCode = $$v
                                        },
                                        expression: "postCode",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: { label: "Postal Address" },
                                      model: {
                                        value: _vm.postalAddress,
                                        callback: function ($$v) {
                                          _vm.postalAddress = $$v
                                        },
                                        expression: "postalAddress",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: { label: "Website" },
                                      model: {
                                        value: _vm.website,
                                        callback: function ($$v) {
                                          _vm.website = $$v
                                        },
                                        expression: "website",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: { label: "Owner Name" },
                                      model: {
                                        value: _vm.owner,
                                        callback: function ($$v) {
                                          _vm.owner = $$v
                                        },
                                        expression: "owner",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: { label: "Main Contact Name" },
                                      model: {
                                        value: _vm.keyContact,
                                        callback: function ($$v) {
                                          _vm.keyContact = $$v
                                        },
                                        expression: "keyContact",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Marketing Contact Name",
                                      },
                                      model: {
                                        value: _vm.marketingName,
                                        callback: function ($$v) {
                                          _vm.marketingName = $$v
                                        },
                                        expression: "marketingName",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: { label: "Marketing Email" },
                                      model: {
                                        value: _vm.marketingEmail,
                                        callback: function ($$v) {
                                          _vm.marketingEmail = $$v
                                        },
                                        expression: "marketingEmail",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.step == 3
                              ? _c(
                                  "div",
                                  [
                                    _c("h4", [_vm._v("3. Bookings")]),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: { label: "Total No. Rooms" },
                                      model: {
                                        value: _vm.totalNumRooms,
                                        callback: function ($$v) {
                                          _vm.totalNumRooms = $$v
                                        },
                                        expression: "totalNumRooms",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: { label: "Channel Manager" },
                                      model: {
                                        value: _vm.channelManager,
                                        callback: function ($$v) {
                                          _vm.channelManager = $$v
                                        },
                                        expression: "channelManager",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("v-select", {
                                      attrs: {
                                        label: "Default Rate Basis",
                                        items: _vm.rateBasisOptions,
                                        autocomplete: "",
                                      },
                                      model: {
                                        value: _vm.defaultRateBasis,
                                        callback: function ($$v) {
                                          _vm.defaultRateBasis = $$v
                                        },
                                        expression: "defaultRateBasis",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("v-label", [_vm._v("Optional Extras:")]),
                                    _vm._v(" "),
                                    _c("v-textarea", {
                                      model: {
                                        value: _vm.rateOptionalExtras,
                                        callback: function ($$v) {
                                          _vm.rateOptionalExtras = $$v
                                        },
                                        expression: "rateOptionalExtras",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.step == 4
                              ? _c(
                                  "div",
                                  [
                                    _c("h4", [_vm._v("4. Policies")]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v("Accepted Credit Cards:"),
                                    ]),
                                    _vm._v(" "),
                                    _c("label", [_vm._v("Mastercard")]),
                                    _vm._v(" "),
                                    _c("v-checkbox", {
                                      attrs: { "hide-details": "" },
                                      model: {
                                        value: _vm.acceptsMastercard,
                                        callback: function ($$v) {
                                          _vm.acceptsMastercard = $$v
                                        },
                                        expression: "acceptsMastercard",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("label", [_vm._v("Visa")]),
                                    _vm._v(" "),
                                    _c("v-checkbox", {
                                      attrs: { "hide-details": "" },
                                      model: {
                                        value: _vm.acceptsVisa,
                                        callback: function ($$v) {
                                          _vm.acceptsVisa = $$v
                                        },
                                        expression: "acceptsVisa",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("label", [_vm._v("Amex")]),
                                    _vm._v(" "),
                                    _c("v-checkbox", {
                                      attrs: { "hide-details": "" },
                                      model: {
                                        value: _vm.acceptsAmex,
                                        callback: function ($$v) {
                                          _vm.acceptsAmex = $$v
                                        },
                                        expression: "acceptsAmex",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("label", [_vm._v("Diners")]),
                                    _vm._v(" "),
                                    _c("v-checkbox", {
                                      attrs: { "hide-details": "" },
                                      model: {
                                        value: _vm.acceptsDiners,
                                        callback: function ($$v) {
                                          _vm.acceptsDiners = $$v
                                        },
                                        expression: "acceptsDiners",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("label", [_vm._v("Maestro")]),
                                    _vm._v(" "),
                                    _c("v-checkbox", {
                                      attrs: { "hide-details": "" },
                                      model: {
                                        value: _vm.acceptsMaestro,
                                        callback: function ($$v) {
                                          _vm.acceptsMaestro = $$v
                                        },
                                        expression: "acceptsMaestro",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.step == 5
                              ? _c("div", [
                                  _c("h4", [
                                    _vm._v(
                                      "5. Facilities (to be done via facilities manager?)"
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.previousStage()
                                  },
                                },
                              },
                              [_vm._v("<")]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.nextStage()
                                  },
                                },
                              },
                              [_vm._v(">")]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                staticClass: "lime",
                                on: {
                                  click: function ($event) {
                                    return _vm.save()
                                  },
                                },
                              },
                              [_vm._v("Create")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }