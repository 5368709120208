var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-flex",
        { staticClass: "ma-3", attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "mb-2", attrs: { xs12: "" } },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { column: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { sm12: "" } },
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "" } },
                                    [
                                      _c("v-flex", { attrs: { sm3: "" } }, [
                                        _c("label", [
                                          _vm._v("Visualisation Type:"),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { sm9: "" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.visualisationTypes,
                                              label: "",
                                              "item-text": "optionText",
                                              "item-value": "optionValue",
                                              "single-line": "",
                                            },
                                            model: {
                                              value: _vm.visualisation.type,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.visualisation,
                                                  "type",
                                                  $$v
                                                )
                                              },
                                              expression: "visualisation.type",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { sm12: "" } },
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "" } },
                                    [
                                      _c("v-flex", { attrs: { sm3: "" } }, [
                                        _c("label", [_vm._v("Model Field A")]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { sm9: "" } },
                                        [
                                          _vm._v(
                                            "\n                                The variable, as may be reported on the X-axis\n                                " +
                                              _vm._s(
                                                _vm.visualisation.modelNameA
                                              ) +
                                              ": " +
                                              _vm._s(
                                                _vm.visualisation.fieldNameA
                                              ) +
                                              "\n                                "
                                          ),
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.section.fields,
                                              label: "",
                                              "item-text": "optionText",
                                              "item-value": "optionValue",
                                              "single-line": "",
                                            },
                                            model: {
                                              value:
                                                _vm.visualisation.modelFieldA,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.visualisation,
                                                  "modelFieldA",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "visualisation.modelFieldA",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { sm12: "" } },
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "" } },
                                    [
                                      _c("v-flex", { attrs: { sm3: "" } }, [
                                        _c("label", [_vm._v("Model Field B")]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { sm9: "" } },
                                        [
                                          _vm._v(
                                            "\n                                The value, as may be reported on the Y-axis\n                                " +
                                              _vm._s(
                                                _vm.visualisation.modelNameB
                                              ) +
                                              ": " +
                                              _vm._s(
                                                _vm.visualisation.fieldNameB
                                              ) +
                                              "\n                                "
                                          ),
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.section.fields,
                                              label: "",
                                              "item-text": "optionText",
                                              "item-value": "optionValue",
                                              "single-line": "",
                                            },
                                            model: {
                                              value:
                                                _vm.visualisation.modelFieldB,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.visualisation,
                                                  "modelFieldB",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "visualisation.modelFieldB",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mt-3 mb-3" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }