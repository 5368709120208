<template>
    <v-container fluid>
       <v-layout row-sm column>
            <v-flex v-if="this.field.schema.displayName != 'none'" xs12>
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}:</label>
            </v-flex>
            <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            <v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            <v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>
            <v-flex xs12 sm6>
                <v-text-field
                    label="E-mail"
                    :name="this.field.fieldName" 
                    v-model="input" v-on:keyup="updateField()" 
                    :disabled="this.field.disabled"
                    :rules="[rules.required, rules.email]"
                ></v-text-field>                
            </v-flex>
           <v-alert type="error" :value="field.errors" style="width: 100%;" v-if="field.errors">
               <li v-for="error in field.errors">
                   {{ error }}
               </li>
           </v-alert>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'ies-text',
    props: ['field'],
    data () {
        return {
            input: this.field.value || this.field.oldValue,
            rules: {
                required: value => !!value || 'Required.',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
            }
        }
    },
    methods: {
        updateField: function(){
            this.field.value = this.input
        }
    
    }
}
</script>


