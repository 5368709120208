<template>

    <v-layout>
        <v-flex xs12>
            <v-card >
                <v-btn class="ies-green" type="submit" v-on:click="saveDesign()"><v-icon>save</v-icon> Save</v-btn>
                <v-btn large fixed bottom right class="ies-green floating-save" v-on:click="saveDesign()">SAVE</v-btn>
                <v-btn class="ies-coral" v-on:click="deleteRecord()" disabled><v-icon>delete</v-icon> Delete</v-btn>
                <v-btn onClick="window.print()"><v-icon>print</v-icon> Print</v-btn>
                <v-btn v-on:click="emailLink()" disabled><v-icon>email</v-icon> Email</v-btn>
                <v-btn v-on:click="listView()" disabled><v-icon>view_list</v-icon> List Version</v-btn>
                <v-btn v-on:click="editView()" disabled><v-icon>view_list</v-icon> Edit Version</v-btn>
                <v-btn v-on:click="reportProblem()" disabled><v-icon>report_problem</v-icon> Problem</v-btn>
            </v-card>
        </v-flex>
    </v-layout>

</template>

<script>
    export default {
        name: "PortalDesignerOptionsBar",

        methods: {

            saveDesign(){
                this.$emit('saveDesign');
            },

        }
    }

</script>