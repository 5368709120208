var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "grid-list-md": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _vm.reservation.show || 1
                        ? _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _vm._v(
                                      "\n                                   No Availability, property is FULLY BOOKED (on some or all of requested dates) and/or cannot accommodate client request\n                              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.message,
                                          expression: "message",
                                        },
                                      ],
                                      domProps: { value: _vm.message },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.message = $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ies-coral",
                          on: { click: _vm.confirmNoAvailability },
                        },
                        [_vm._v("Confirm No Availability")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }