<template>
    <div>
        <!-- list must be list of ['text' => x, 'value' => y] -->
        <v-select
            v-model="viewValue"
            :label="field.schema.displayName"
            :items="statuses"
            autocomplete
        ></v-select>
        <input type="hidden" :name="field.fieldName" :value="field.value">
        <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
            <li v-for="error in field.errors">
                {{ error }}
            </li>
        </v-alert>
    </div>

</template>

<script>
    /**
     */
    export default {
        name: 'ies-statuskey',
        props: ['field'],
        data() {
            return {
                statuses: [{text: "Inactive", value: 1}, {text: "Active", value: 2}, 
				{text: "Inactive", value: 3}, {text: "Active", value: 4},
				{text: "Inactive", value: 5}, {text: "Active", value: 6}, 
				{text: "New Enquiry", value: 8}, { text: "Stay Completed", value: 9}, {text: "Re-enquiry", value: 10},
                {text: "Availability Confirmed", value: 11}, { text: "Reservation Confirmed", value: 12}, {text: "Quote Accepted", value: 13},
                {text: "Quote Expired", value: 14}, { text: "Quote Declined", value: 15}, {text: "No Availability", value: 16},
                {text: "New Cancellation", value: 17}, { text: "Cancellation Confirmed By Hotel", value: 18}, {text: "Quote Declined, Confirmed By Hotel", value: 19},
                {text: "Alternative Offered", value: 20}],
                viewValue: parseInt(this.field.value),
            }
        },
        watch: {
            viewValue: function (newValue) {
                this.field.value = newValue;
            }
        }
    }
</script>
