<template>
<div>

    <accounts-filter
        :settings="accountFilterSettings"
    ></accounts-filter>

    <v-card>
        <v-card-title>
            <span class="subheader">Hotels with outstanding balances</span>
        </v-card-title>
        <v-container fluid>
            <v-layout row>
                <v-flex xs8 lg6 offset-lg1>
                    <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-flex>
                <v-flex xs2 offset-xs2 lg2 offset-lg3>
                    <export-csv
                        filename="OutstandingBalances"
                        :data="filteredItems"
                    ></export-csv>
                </v-flex>
            </v-layout>
        </v-container>

        <v-data-table
            :headers="headers"
            :items="receipts"
            :search="search"
            :loading="isLoading"
            class="elevation-1"
            ref="table"
        >
        
            <v-progress-linear slot="progress" color="lime" indeterminate></v-progress-linear>
            <template slot="items" slot-scope="props">
                <td class="text-xs-center">{{ props.item.creation }}</td>
                <td class="text-xs-center">{{ props.item.hotel_code }}</td>
                <td class="text-xs-center">{{ props.item.hotel_name }}</td>
                <td class="text-xs-center">{{ props.item.type_of_hotel }}</td>
                <td class="text-xs-center">{{ props.item.payment_method_name }}</td>
                <td class="text-xs-center">{{ props.item.amount | pounds }}</td>
            </template>

            <template slot="footer">
                <td :colspan="headers.length - 1">
                <td class="text-xs-center">
                    <strong>Total: </strong> {{ totalAmount | pounds }}
                </td>
            </template>

        </v-data-table>
    </v-card>
    
</div>  
</template>

<script>
import EventBus from '@/eventBus';
import AccountsFilter from '../AccountsFilter'
import ExportCsv from '@/components/common/ExportCSV.vue'

export default {
    components: {
        AccountsFilter,
        ExportCsv
    },
    data() {
        return {
            headers: [],
            receipts: [],
            filteredItems: [],
            isLoading: false,
            search: '',
            // Query paramms to be sent on receipts requests.
            query: {
                type_of_hotel: 'UPF,ARR',
                balance: 'negative,positive',
                date: {
                    from: '2019-01-01', 
                    to: '',
                }
            },
            // Settings object for AccountsFilter
            accountFilterSettings: {
                datePicker: {
                    dateFrom: '2018-10-02',
                    dateTo: '2018-10-03',
                },
                hotelPicker: {
                    hotels: [],
                },
                regionPicker: {
                    regions: [],
                },
                typePicker: {
                    type: 'UPF,ARR',
                },
                balancePicker: {
                    balance: 'negative,positive'
                }
            }
        }
    },
    mounted() {
        this.$watch(
            // Get filtered items from data table
            () => this.$refs.table.filteredItems, (value) => { this.filteredItems = value; }
        )
    },
    created() {
        this.getHeaders();
        this.getReceipts();
        this.registerListeners();
       
    },
    computed: {
        /**
         * Receipts amount totalled
         */
        totalAmount: function() {
            return this.filteredItems.reduce((accumulator, receipt) => accumulator + parseFloat(receipt.amount), 0)
        }
    },
    methods: {
        /**
         * 
         */
        async getReceipts() {
            this.toggleIsLoading()
            const res = await axios('api/accounts/receipts', {
                params: {
                    // region:         this.query.region,           // hotel region or parent region
                    dateFrom:       this.query.date.from,           // invoice from date
                    dateTo:         this.query.date.to,             // invoice to date
                    type_of_hotel:  this.query.type_of_hotel,       // upfront, in arrears or fixed fee
                    balance:        this.query.balance              // negative or positive balance only
                }
            });

            this.receipts = res.data;
            this.toggleIsLoading();
        },
        /**
         * Register all event listeners here to keep created() tidy.
         */
        registerListeners() {
            EventBus.$on('accounts-filter', (query) => {
                console.log(query)
                this.query = query;
                this.getReceipts();
            });
        },
        /**
         * Toggle is loading
         */
        toggleIsLoading() {
            this.isLoading = !this.isLoading;
        },
        getHeaders() {
            this.headers = [
                {
                    text: 'Timestamp',
                    align: 'center',
                    sortable: true,
                    value: 'creation'
                },
                { 
                    text: 'Hotel Code',
                    align: 'center',
                    sortable: true,
                    value: 'hotel_code' 
                },
                { 
                    text: 'Hotel Name',
                    align: 'center',
                    sortable: true,
                    value: 'hotel_name'
                },
                { 
                    text: 'Type of Hotel',
                    align: 'center',
                    sortable: true,
                    value: 'type_of_hotel'
                },
                { 
                    text: 'Payment Method',
                    align: 'center',
                    sortable: true,
                    value: 'payment_method_name'
                },
                { 
                    text: 'Amount',
                    align: 'center',
                    sortable: true,
                    value: 'amount'
                }
            ];
        }
    }
}
</script>
