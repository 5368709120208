<template>
    <v-container fluid pa-0>
        <v-expansion-panel>
            <v-expansion-panel-content>
                <div slot="header">Fields changes:
                    <span v-if="!changesObject.length" class="changesNotFound"> Changes not found</span>
                </div>
                    <v-card class="fieldChanges" v-if="changesObject.length">
                    <v-layout row wrap class="columnDescription">
                        <v-flex xs3>
                            <span>Field:</span>
                        </v-flex>
                        <v-flex xs2>
                            <span>Changed From:</span>
                        </v-flex>
                        <v-flex xs7>
                            <span>Changed To:</span>
                        </v-flex>
                    </v-layout>
                    <v-layout row wrap v-for="(field, key) in changesObject" :key='key'>
                        <v-flex xs3>
                            <span>{{ field.fieldName }}</span>
                        </v-flex>
                        <v-flex xs2>
                            <span>{{ parseValue(field.old_value) }}</span>
                        </v-flex>
                        <v-flex xs7>
                            <span>{{ parseValue(field.new_value) }}</span>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-container>
</template>

<script>
    import { format, parse } from 'date-fns';

    export default {
        props: ['changes'],
        data() {
            return {
                allChanges: this.changes,
            }
        },
        computed: {
            changesObject() {
                if (this.allChanges) {
                    return JSON.parse(this.allChanges);
                }
                else {
                    return [];
                }
            }
        },
        methods: {
            /**
             * This method can parse value if for example value can be an object, it can try to read object values
             * for known objects.
             *
             * @param string val
             */
            parseValue(val) {
                // if this object contains date use this date as value, parse it to display correct format
                if (val && typeof val === 'object' && val.hasOwnProperty('date') && val.date) {

                    let dateObject = parse(val.date);
                    val =  format(dateObject, 'YYYY-MM-DD');
                }

                return val;
            }
        },
    }
</script>

<style scoped>
    .columnDescription {
        font-weight: bold;
    }

    .fieldChanges {
        padding-left: 25px;
    }

    .changesNotFound {
        font-weight: bold;
        font-style: italic;
        color: green;
    }
</style>
