var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-flex",
    { attrs: { xs12: "", "pa-3": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", xs12: "" } },
        [
          _vm._l(_vm.panels, function (panel) {
            return _c(panel.system_name, {
              key: panel.dashboard_panel_key,
              tag: "component",
              attrs: { pageid: _vm.primarykey },
            })
          }),
          _vm._v(" "),
          _vm.configuration.user_configurable
            ? _c(
                "v-flex",
                { attrs: { xs1: "", "offset-xs11": "" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { outline: "", large: "", disabled: "" } },
                    [_c("v-icon", [_vm._v("settings")])],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }