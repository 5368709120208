<template>
    <v-container fluid>
       <v-layout row-sm column>

           <v-flex xs12 sm6>
               <label for="password">New Password</label>
           </v-flex>
           <v-flex xs12 sm6>
               <input type="password" name="password" v-model="data.password">
           </v-flex>

           <v-flex xs12 sm6>
               <label for="confirmation">Password Confirmation</label>
           </v-flex>
           <v-flex xs12 sm6>
               <input type="password" name="confirmation" v-model="data.passwordConfirmation">
           </v-flex>
           <v-flex xs12 sm6>
               <v-btn class="ies-coral ies-dark-gray--text" @click="changePassword()" >Change Password</v-btn>
           </v-flex>
       </v-layout>

        <v-snackbar
        :timeout="parseInt(6000)"                          
        v-model="successSingleSnackbar"
        multi-line
        color="ies-green"
        > 
           <div class="full-height full-width v-align-container text-xs-center">
              <h4 class="ies-dark-gray--text v-align-div">Password Changed</h4>
           </div>
        </v-snackbar>

        <v-snackbar
        :timeout="parseInt(6000)"                          
        v-model="errorSnackbar"
        multi-line
        color="ies-coral"
        > 
           <div class="full-height full-width v-align-container text-xs-center">
              <h4 class="ies-dark-gray--text v-align-div">{{ this.errorSnackbarText }}</h4>
           </div>
        </v-snackbar>

    </v-container>
</template>

<script>

import axios from 'axios';
export default {
    props: ['field'],
    data () {
        return {
            errorSnackbar: false,
            errorSnackbarText: 'Password not changed',
            successSingleSnackbar:false,
            data:{  'user_key':this.field.value,
                    'portal_table':this.field.schema.tableName,
                    'password': '',
                    'passwordConfirmation': ''}
        }
    },
    methods: {
        changePassword (){
            var pass = true;        
            if(this.data.password == ''){
                this.errorSnackbarText = 'Password cannot be empty';
                this.errorSnackbar = true;
                pass = false;
            }

            if(this.data.password != this.data.passwordConfirmation){
                this.errorSnackbarText = 'Passwords must match';
                this.errorSnackbar = true;
                pass = false;
            }

            if(pass){
               axios.post('/ajax/changePassword',{ data : this.data })
               .then(function(response) {
                   if (response.data.success) {
                       this.successSingleSnackbar = true;
                       this.data.password = '';
                       this.data.passwordConfirmation = '';
                   }
                   else {
                       this.errorSnackbarText = response.data.error;
                       this.errorSnackbar = true;
                   }
              }.bind(this));
           }
        }
    },
    mounted() {
    }
}
</script>


