<template>
    <v-container fluid>
       <v-layout row-sm column>
            <v-col v-if="this.field.schema.displayName != 'none'" cols="12">
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-col>

            <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            <v-alert type="warning"  dismissable value="true" v-if="this.field.warnings">{{ this.field.warning }}</v-alert>
            <v-alert type="error" dismissable value="true" v-if="errorMessages && errorMessages.length">
                <div v-for="(error, index) in errorMessages" :key="index" >{{ error.reason }}*</div>
            </v-alert>

            <v-col cols="12" sm="6">
                <input :name="this.field.fieldName" 
                       type="number" 
                       v-model="input" 
                       :required="this.field.required" 
                       :disabled="this.field.disabled"
                       v-on:change="updateField()">
            </v-col>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'ies-integer',
    props: ['field'],
    data () {
        return {
            input: this.field.value || this.field.oldValue,
            errorMessages: []
        }
    },
    mounted: function(){
        this.registerEventListeners();
        console.log(this.field);
    },
    methods: {
        registerEventListeners: function(){
            var self = this;
            this.$root.$on('Field: set errors', function(payload){
                console.log("INT: I heard ", payload);
                if (payload.modelName = self.field.modelName && payload.fieldName == self.field.fieldName){
                    self.errorMessages = payload.errors;
                    console.log("INT: I set ", self.field.errors);
                }
            });

            this.$root.$on('Field: clear errors', function(payload){
                if (payload.modelName = self.field.modelName && payload.fieldName == self.field.fieldName){
                    self.field.errors = null;
                }
            });
        },
        updateField: function(){
            this.field.value = this.input
        }
    },
}
</script>


