<template>
    <v-col cols="12" sm="6" md="4" lg="3" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">card_giftcard</v-icon>Voucher Creator
            </v-card-title>
        
            <v-card-text class="body-2">
                <div v-if="systemError">Sorry, this content is not working</div>

                <!-- Overview of new voucher -->
                <div v-if="!systemError" class="m-3">
                    <v-container>
                        <v-layout row wrap>
                            <v-col cols="6>Type</v-col>
                            <v-col cols="6>****</v-col>
                            <v-col cols="6>Quantity</v-col>
                            <v-col cols="6>****</v-col>
                            <v-col cols="6">Value (GBP)</v-col>
                            <v-col cols="6">****</v-col>
                            <v-col cols="6">Expiry:</v-col>
                            <v-col cols="6">****</v-col>
                            <v-col cols="6">Purpose</v-col>
                            <v-col cols="6">****</v-col>
                            <v-col cols="6">Notes:</v-col>
                            <v-col cols="6">****</v-col>
                        </v-layout>
                    </v-container>
                </div>
            </v-card-text>

            <v-card-actions>
                <v-btn v-on:click="create()"><v-icon>create</v-icon> Create Vouchers</v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
    import axios from 'axios';

    export default {
        props:[],

        data: function() {
            return {
                voucherCode: "",
                voucher: null,
                systemError: false,
                foundVoucher: null,
                mode: "view",
                reason: ""
            }
        },

        /**
         * On mounted
         */
        mounted: function(){},

        methods: {

        }
    }
</script>
