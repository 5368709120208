var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    { staticClass: "h-auto", attrs: { disabled: _vm.isDisabled } },
    [
      _c(
        "td",
        {
          class: {
            "header-cell": true,
            "rate-header": true,
            "w-auto": true,
            rateName: true,
            interactive: true,
            "pl-2": true,
            "text-xs-right": true,
            "font-weight-bold": _vm.rateColumn == "rate_standard",
          },
          attrs: { title: _vm.rowHeader, disabled: _vm.isDisabled },
          on: {
            mouseenter: function ($event) {
              return _vm.handleRowMouseEnter(_vm.rateColumn)
            },
            mousedown: function ($event) {
              return _vm.handleRowMouseDown(_vm.rateColumn)
            },
            mouseup: function ($event) {
              return _vm.handleCellMouseUp(_vm.rateColumn)
            },
          },
        },
        [_vm._v("\n        " + _vm._s(_vm.rowHeader) + "\n    ")]
      ),
      _vm._v(" "),
      _vm._l(_vm.ratesPerDate, function (rateForDate) {
        return [
          _c(
            "td",
            {
              class: {
                interactive: true,
                "rate-for-date": true,
                "pl-1": true,
                "pr-1": true,
                unknown: !rateForDate.rate_standard || !rateForDate.is_open,
                selected:
                  _vm.cellIsSelected(rateForDate, _vm.rateColumn) ||
                  _vm.cellIsBeingEdited(rateForDate, _vm.rateColumn),
                updated: _vm.cellIsUpdated(rateForDate, _vm.rateColumn),
                "disabled-cell": _vm.dateIsOutOfRange(rateForDate.date),
                "disabled-rate-cell": _vm.dateIsOutOfRange(rateForDate.date),
                indicative:
                  rateForDate.is_indicative &&
                  rateForDate.is_open &&
                  _vm.rateColumn == "rate_standard",
              },
              attrs: {
                title: `Rate for ${_vm.standardOccupancy} pax on ${rateForDate.date}`,
              },
              on: {
                mousedown: function ($event) {
                  return _vm.handleCellMouseDown(rateForDate, _vm.rateColumn)
                },
                mouseenter: function ($event) {
                  return _vm.handleCellMouseEnter(rateForDate, _vm.rateColumn)
                },
                mouseup: function ($event) {
                  return _vm.handleCellMouseUp()
                },
              },
            },
            [
              !_vm.isCheckbox
                ? _c("span", [
                    _vm._v(
                      " \n                " +
                        _vm._s(
                          _vm.getCellDisplayValue(rateForDate, _vm.rateColumn)
                        ) +
                        " \n            "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isCheckbox
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: rateForDate[_vm.rateColumn],
                        expression: "rateForDate[rateColumn]",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(rateForDate[_vm.rateColumn])
                        ? _vm._i(rateForDate[_vm.rateColumn], null) > -1
                        : rateForDate[_vm.rateColumn],
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = rateForDate[_vm.rateColumn],
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  rateForDate,
                                  _vm.rateColumn,
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  rateForDate,
                                  _vm.rateColumn,
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(rateForDate, _vm.rateColumn, $$c)
                          }
                        },
                        function ($event) {
                          return _vm.toggleBoolean(rateForDate, _vm.rateColumn)
                        },
                      ],
                    },
                  })
                : _vm._e(),
            ]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }