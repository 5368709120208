<template>
    <v-flex>
    <v-flex v-if="value">
        <v-btn v-on:click="testFeed">
            <v-icon>link</v-icon>
        </v-btn>
    </v-flex>

    <v-flex v-if="shouldShowAnalysis" class="analysis-panel">
        <v-flex v-if="analysis.isValid">The feed appears to be valid</v-flex>
        <v-flex v-if="!analysis.isValid">The feed failed to load</v-flex>

        <div v-if="analysis.isValid">
            <v-flex>Time taken: {{ analysis.timeTaken }} seconds</v-flex>
            <v-flex>Future Bookings: {{ analysis.futureBookings }}</v-flex>
            <v-flex>The i-escape system would reduce availability on the following dates:</v-flex>
            <v-flex v-for="update in analysis.updates">From {{ update.from }} until  {{ update.until }}</v-flex>
        </div>
        <v-btn v-on:click="closeAnalysis">Close</v-btn>
    </v-flex>
    </v-flex>
</template>

<script>
/**
 * Triggers a request to the server to update this room type
 */
export default {
    name: 'ies-ical-feed',
    props: ['field', 'value'],
    data () {
        return {
            analysis: null,
            shouldShowAnalysis: false
        }
    },

    methods: {
        testFeed: function(){
            var url = "/api/ical/feed/check?feedKey=" + this.value;
            axios.get(url)
            .then(function(response){
                console.log(response);
                this.analysis = response.data;
                this.shouldShowAnalysis = true;
            }.bind(this));
        },

        closeAnalysis: function(){
            this.shouldShowAnalysis = false;
        }
    }
}
</script>
<style>
    .analysis-panel {
        background-color: white;
position: fixed;
top: 100px;
left: 100px;
width: calc(100vw - 200px);
height: calc(100vh - 200px);
display: block;
z-index: 999;
margin-top: 100px;
overflow: auto;
padding: 2rem;
text-align: left;
    }
</style>
