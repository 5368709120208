<template>
    <v-col md="6" lg="4" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">generating_tokens</v-icon> Card Tokenisation Statistics
            </v-card-title>
              <line-chart :chartdata="chartData" :options="chartOptions"></line-chart>
        </v-card>
    </v-col>
</template>

<script>
    import LineChart from '@/components/charts/LineChart';
    import axios from 'axios';
    /**
     * Display payment card tokenisation & charge statistics to the user
     */
    export default {
        /**
         * Data passed into the component from the view itself via attributes on its tag
         */
        props:['datasource'],
        components: {
            LineChart,
        },

        data() {
            return {
                apiData:  {},
                chartData: {},
                chartOptions: {
                    maintainAspectRatio: false,
                    responsive: true,
                    events: ['click'],
                    title: {
                        display: false,
                        text: ''
                    }
                },
                date:   {
                    from: new Date(new Date().setDate(new Date().getDate()-6)).toISOString().substr(0, 10),
                    to:   new Date().toISOString().substr(0, 10),
                },
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted() {
            this.loadStats();
            setInterval( this.loadStats, 60000 );
        },

        methods: {
            /**
             * Load the latest data from the API
             */
            async loadStats() {
                const response = await axios.get('api/accounts/tokens/statistics', {
                   params: {
                        dateFrom: this.date.from,
                        dateTo:   this.date.to,
                   }
                }).then(response => {
                    this.apiData = response.data;
                    console.log("apiData", this.apiData);
                    this.buildDataObject();
                }).catch(error => {
                    console.log(error);
                });
            },

            /**
             * Build the data object required by the line chart using API data to
             * fill the datasets objects and labels
             */
            buildDataObject() {
                let data = {
                    labels: [],
                    datasets: [
                      {
                          data: [],
                          label: "No. Tokenisation Attempts",
                          borderColor: "#C1CD23",
                          fill: false
                      },
                      {
                          data: [],
                          label: "No. Tokens",
                          borderColor: "#088193",
                          fill: false
                      },
                      {
                          data: [],
                          label: "No. Charges Completed",
                          borderColor: "#088193",
                          fill: false
                      }
                    ]
                };
                for (let day of Object.entries(this.apiData)) {
                    // Chart data
                    data.labels.push(day[1].date);
                    data.datasets[0].data.push(day[1].num_attempts);
                    data.datasets[1].data.push(day[1].num_tokens);
                    data.datasets[2].data.push(day[1].num_charges);
                }
                this.chartData = data;
            },
        },
    }
</script>
