<template>
    <v-col md="6" lg="4" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3"></v-icon> Newsletter Signups Statistics
            </v-card-title>
            <v-card-text>
                <v-expansion-panel expand>
                    <v-expansion-panel-content ripple>
                            <div slot="header"><strong>Select Date Range</strong></div>
                                <ies-date-range name="newsletter-signups"></ies-date-range>
                        </v-expansion-panel-content>
                </v-expansion-panel>
                <line-chart :chartdata="chartData" :options="chartOptions"></line-chart>
                <v-flex x12>
                    Total Monthly-Newsletter Signups: {{ chartData.count_monthly_newsletters }}
                </v-flex>
                <v-flex x12>
                    Total Family-Newsletter Signups: {{ chartData.count_family_newsletters }}
                </v-flex>
                <v-flex x12>
                    Total Signed up to Both Newsletters: {{ chartData.count_both_newsletters }}
                </v-flex>
                
            </v-card-text>
        </v-card>
    </v-col>
</template>

<script>
    import LineChart from '@/components/charts/LineChart';
    import axios from 'axios';
    import EventBus from '@/eventBus';

    /**
     * Display newsletter statistics to the user
     */
    export default {
        props:['datasource'],
        components: {
            LineChart,
        },

        data() {
            return {
                apiData:  {},
                chartData: {},
                chartOptions: {
                    maintainAspectRatio: false,
                    responsive: true,
                    events: ['click'],
                    title: {
                        display: false,
                        text: ''
                    }
                },
                range: [],
                count_monthly_newsletters: 0,
                count_family_newsletters: 0,
                count_both_newsletters: 0,
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            console.log(this.range);
            this.loadNewsletterSignUpStats();
            setInterval( this.loadNewsletterSignUpStats, 60000 );
        },

        created() {
            EventBus.$on('ies-date-range Changed', (data) => {
                if (data.name = 'newsletter-signups') {
                    this.range = data.range;
                    this.loadNewsletterSignUpStats();
                }
            });
        },

        methods: {

            /**
             * Load the latest data from the API
             */
            async loadNewsletterSignUpStats(){
                const response = await axios.get('api/newsletter-signups', {
                   params: {
                        dateFrom: this.range[0],
                        dateTo:   this.range[1],
                   }
                }).then(response => {
                    this.apiData = response.data;
                    this.buildDataObject();
                }).catch(error => {
                    console.log(error);
                });
            },

            /**
             * Build the data object required by the line chart using API data to
             * fill the datasets objects and labels
             */
            buildDataObject() {
                let data = {
                    labels: [],
                    datasets: [
                      {
                          data: [],
                          label: "Monthly Newsletter Signups",
                          borderColor: "#C1CD23",
                          fill: false
                      },
                      {
                          data: [],
                          label: "Family Newsletter Signups",
                          borderColor: "#F15C61",
                          fill: false
                      },
                      {
                          data: [],
                          label: "Both Newsletter Signups",
                          borderColor: "#088193",
                          fill: false
                      },
                    ]
                };

                for (let day of Object.entries(this.apiData)) {
                    // Chart data
                    data.labels.push(day[0]);
                    data.datasets[0].data.push(day[1].monthlyNewsletterSignups);
                    data.datasets[1].data.push(day[1].familyNewsletterSignups);
                    data.datasets[2].data.push(day[1].allNewsletterSignups);
                }

                //Get count totals
                data.count_monthly_newsletters = data.datasets[0].data.reduce((a, b) => a + b, 0);
                data.count_family_newsletters = data.datasets[1].data.reduce((a, b) => a + b, 0);
                data.count_both_newsletters = data.datasets[2].data.reduce((a, b) => a + b, 0);

                this.chartData = data;
            },

        }
    }
</script>
