<template>
    <v-col cols="12" md="2" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">join_full</v-icon> Setup 
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-btn class="mt-1" block :href="travelOperators">Travel Operator</v-btn>
                            <v-btn class="mt-1" block :href="displayStatus">Display Status</v-btn>
                            <v-btn class="mt-1" block :href="visitStatus">Visit Status</v-btn>
                            <v-btn class="mt-1" block :href="contract">Contract and Format</v-btn>
                            <v-btn class="mt-1" block :href="bookingSystem">Booking System</v-btn>
                            <v-btn class="mt-1" block disabled href="">Commission</v-btn>
                            <v-btn class="mt-1" block :href="currency">Currency</v-btn>
                            <v-btn class="mt-1" block disabled href="">Permissions</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<script>
    /**
     * Display buttons linking through to a hotels' pages
     */
    export default {
        props:['pageid'],

        data: function() {
            return {
				travelOperators: '',
				displayStatus: '',
				visitStatus: '',
				contract: '',
				bookingSystem: '',
				currency: ''
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
			this.travelOperators = "/hotel-travel-operators/list?s=as&c[]=Hotel-hotel_key&o[]===&v[]=" + this.pageid;
			this.displayStatus = "/hotel-display-status/list?s=as&c[]=Hotel-hotel_key&o[]===&v[]=" + this.pageid;
			this.visitStatus = "/hotel-visits/list?s=as&c[]=HotelVisit-hotel_key&o[]===&v[]=" + this.pageid,
			this.contract = "/hotel-contract-format/" + this.pageid,
			this.bookingSystem = "/hotel-booking-system/" + this.pageid,
			this.currency = "/hotel-currency/list?s=as&c[]=Hotel-hotel_key&o[]===&v[]=" + this.pageid;
		},

        methods: {},

        computed: {}
    }
</script>


