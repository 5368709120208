<template>
    <v-container fluid>
       <v-layout row-sm>
            <v-flex xs12 sm1>
                 <label>{{ this.field.schema.displayName }}:</label>
            </v-flex>
            <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            <v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            <v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>
            <v-flex xs12 sm11>
                <input type="hidden" :name="field.fieldName" :value="field.value">

                <label for="monthly">Monthly:</label>
                <input name="monthly" type="checkbox" v-model="subscriptions.monthly">
                <label for="specialOffers">Special Offers:</label>
                <input name="specialOffers" type="checkbox" v-model="subscriptions.specialOffers">
                <label for="family">Family:</label>
                <input name="family" type="checkbox" v-model="subscriptions.family">

                <v-alert type="error" :value="field.errors" style="width: 100%">
                    <li v-for="error in field.errors">
                        {{ error }}
                    </li>
                </v-alert>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    /**
     * Allows the user to select newsletter subscription types based
     * on their name
     *
     * The database stores these values as integers/tinyintegers
     * with each day represented as a boolean value by one bit, but the
     * user expects to interact with them using checkboxes to toggle
     * each type separately, meaning we will display several checkboxes per
     * field.  We will use view models to abstract the data from the 
     * data model it is representing as it will make it cleaner and
     * safer to modify the UI, provided we keep the two models in 
     * synchronisation
     *
     */
    export default {
        name: 'ies-newsletter-subscriptions',
        props: ['field'],
        data() {
            return {
                /** @var object week - A view model representing the newsletters as a boolean flag for each - this will be used to power the UI easier than the data model */
                subscriptions: {'monthly': false, 'specialOffers': false, 'family':false},
            }
        },
        methods: {
            /**
             * Update the view models so that the view model data 
             * reflects the underlying data model for the field 
             * which will actually be saved to the database later
             */
            updateViewFromModel: function(){
                this.subscriptions.monthly = this.field.value & 1;
                this.subscriptions.specialOffers = this.field.value & 2;
                this.subscriptions.family = this.field.value & 4;
            },

            /**
             * Update the data model for the field to match the data
             * represented in the view model which powers the user 
             * interface so that it matches what the user has entered
             */
            updateModelFromView: function(newValue){
                this.field.value = (this.subscriptions.monthly)   ? this.field.value |= 1  : this.field.value & ~1;
                this.field.value = (this.subscriptions.specialOffers)  ? this.field.value |= 2  : this.field.value & ~2;
                this.field.value = (this.subscriptions.family)? this.field.value |= 4  : this.field.value & ~4;
            }
        },

        created:function() {
            this.updateViewFromModel();
        },

        /**
         * In the event that the user changes the view model's data then
         * we need to trigger a corresponding change to the data model too
         */
        watch: {
            'subscriptions.monthly':    function(){ this.updateModelFromView(); },
            'subscriptions.specialOffers':   function(){ this.updateModelFromView(); },
            'subscriptions.family': function(){ this.updateModelFromView(); },
        }
    }
</script>
