var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", sm2: "" } }, [
            _c("span", { staticClass: "changeTitle" }, [
              _vm._v("Save action:"),
            ]),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { xs12: "", sm10: "" } }, [
            _c("span", { staticClass: "saveAction" }, [
              _vm._v(_vm._s(_vm.record.save_action)),
            ]),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { xs12: "", sm2: "" } }, [
            _c("span", { staticClass: "changeTitle" }, [_vm._v("Changed at:")]),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { xs12: "", sm10: "" } }, [
            _c("span", [_vm._v(_vm._s(_vm.getChangedAt(_vm.record)))]),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { xs12: "", sm2: "" } }, [
            _c("span", { staticClass: "changeTitle" }, [_vm._v("Changed by:")]),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { xs12: "", sm10: "" } }, [
            _c("span", [_vm._v(_vm._s(_vm.getChangedBy(_vm.record)))]),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { xs12: "", sm2: "" } }, [
            _c("span", { staticClass: "changeTitle" }, [_vm._v("Changed on:")]),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { xs12: "", sm10: "" } }, [
            _c("span", [
              _vm._v(_vm._s(_vm.getChangedOn(_vm.record.app_edited))),
            ]),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { xs12: "", sm2: "" } }, [
            _c("span", { staticClass: "changeTitle" }, [
              _vm._v("Changed on page:"),
            ]),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { xs12: "", sm10: "" } }, [
            _c("span", [_vm._v(_vm._s(_vm.record.page_url))]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }