<template>
    <v-container fluid>
       <v-row wrap>
			<v-col sm="12" class="checkbox">
				<label :for="this.field.fieldName" class="pr-6"> {{ this.field.schema.displayName }}:</label>
				<input :name="this.field.fieldName" 
                       type="checkbox" 
                       v-model="input" 
                       v-on:change="updateField()" 
                       :disabled="this.field.disabled">

            </v-col>
			<v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
			<v-col sm="12">
            	<v-alert type="info"  dismissable :value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            </v-col>
			<v-col sm="12">
				<v-alert type="warning"  dismissable :value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            </v-col>
			<v-col sm="12">
				<v-alert type="error" dismissable :value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>
			</v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'ies-text',
    props: ['field'],
    data () {
        return {
            input: (this.field.value == '1' ? 1 : 0)
        }
    },
    methods: {
    	updateField: function(){
        	this.field.value = this.input
    	}
    }
}
</script>
