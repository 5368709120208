<template>
    <v-container fluid>
        <v-layout row-sm column>
            <v-flex xs12>
                <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-flex>
            <v-flex xs12 sm6>
                <select :name="this.field.fieldName" v-model="input" v-on:change="updateField()" style="width: 200px" :required="this.field.required" :disabled="this.field.disabled">
                    <option disabled value="">Select department</option>
                    <option v-for="(value,key) in departments":value="value">{{ key }}</option>
                </select>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: 'ies-text',
        props: ['field'],
        data () {
            return {
                input: this.field.value,
                departments : {
                    'Bookings'  : 1,
                    'Directors' : 2,
                    'Editorial' : 3,
                    'Marketing' : 4,
                    'Rates'     : 5,
                    'Web'       : 6,
                    'Accounts'  : 7,
                }
            }
        },
        methods: {
            updateField: function(){
                this.field.value = this.input;
            }

        }
    }
</script>


