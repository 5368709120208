<template>
    <v-flex>{{ providerName }}</v-flex>
</template>

<script>
export default {
    name: 'ies-communication-service-providers',
    props: ['providerKey'],
    data () {
        return {}
    },

    mounted: function (){
        this.loadProviders();
    },

    methods: {
        loadProviders: function(){
            axios.get('api/communications-service-providers')
            .then(function(response){
                this.data.providers = response.data;
            }.bind(this))
            .then(function(){
                // fail
            }.bind(this));
        },
    },

    computed: {
        providerName: function(){
            var key = this.providerKey;
            this.providers.forEach(function(provider){
                if (provider === key){
                    return provider.name;
                }
            });
        }
    }

}
</script>
