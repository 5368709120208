<template>
    <v-container fluid grid-list-md>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                     <v-card-text>
                         <v-container fluid v-if="reservation.show || 1">
                              <v-layout row wrap>
                                  <v-flex xs12>
                                       Reservation completed
                                  </v-flex>
                              </v-layout>
                          </v-container>
                      </v-card-text>
                      <v-card-actions>
                          <v-btn disabled v-on:click="printReservation">Print Reservation</v-btn>
                          <v-btn :href="contactIescapeUrl">Contact i-escape</v-btn>
                      </v-card-actions>
                 </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    /**
     * A Vue component dedicated to letting the user manage a reservations
     * by the ID specified
     *
     * Props:
     *     int 'reservationkey' - The ID of the reservation to display
     */

    import axios from 'axios';
    export default {
        name: 'hotel-reservation-enquiry',
        props: ['reservationkey'],
        data () {
            return {
                reservation: {},
                contactIescapeUrl: ''
            }
        },
        methods: {

            loadReservation(){
                axios.get('/api/reservations/id/' + this.reservationkey)
                .then(function(response){
                    this.reservation = response.data.reservation;
                    this.contactIescapeUrl = "mailto:reservations@i-escape.com?subject=Re:%20" + this.reservation.reference + "";
                }.bind(this));
            },

            printReservation(){
               alert("This functionality is not yet supported");
            },

            contactIescape(){
               alert("This functionality is not yet supported");
            }
        },

        /**
         * Once mounted this component needs to load the reservation specified
         */
        mounted() {
            this.loadReservation();
        },

        computed: {}
    }
</script>
