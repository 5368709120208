// v2.0

import Vue from 'vue';

import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
Vue.use(Vuetify);




//import Vuetify from 'vuetify/lib';
//Vue.use(Vuetify);
//const opts = {};
//export default new Vuetify(opts);

//import vuetify from 'vuetify';

//import App from './App.vue'
/*
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

const opts = {  };
Vue.use(Vuetify);

/*
const vuetifyOpts = {
  theme: appConfig.theme
};
*?
/*
window.app = new Vue({
   vuetify: new Vuetify(opts)
}).$mount('#app');
*/

/*
window.app = new Vue({
    el: '#app',
    vuetify : new Vuetify(),
//    vuetify,
});

/*window.app = new Vue({
  el: '#app',
  //router,
  store,
  //components: { App },
  //render: h => h(App),
  template: '<App/>',
  //vuetify: new Vuetify(vuetifyOpts)
});


/*
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */


/*

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import App from './App.vue';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const vuetifyOpts = {
  theme: appConfig.theme
};

/ eslint-disable no-new */
/*
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  render: h => h(App),
  template: '<App/>',
  vuetify: new Vuetify(vuetifyOpts)
});


/
require('./bootstrap');

window.Vue = require('vue');
window.Vuetify = require('vuetify');
*/
/*
 * Now we can load Vuetify so we can support pre-defined components and styling for generic pages
 */
//Vue.use(Vuetify);
require('./bootstrap');

/**
 * Add support of vuex store to vue instance
 * to access vuext store data object -> this.$store
 */
import store from './store/vuex.js';

/**
 * Because vuetify doesn't come by default with date picker, we need to inlude that, and make it globally accesible
 *
 * Commented out 2023 because it is causing issues with compatibility when upgrading other components
 */
//import VDateRange from 'vuetify-daterange-picker';
//import 'vuetify-daterange-picker/dist/vuetify-daterange-picker.css';
//Vue.use(VDateRange);

/**
 * Load filters into Vue
 */
require('./filters')//*******************/////// how to add this?

/**
 * Add bespoke components to Vue instance
 */
require('./vueComponents');

//e.component('pageService', require('./components/dynamicViews/SearchList.vue'));

// Add a global event bus to the entire app so that disconnected components can still use event listeners to be made aware of core actions
Vue.prototype.$eventHub = new Vue();

// Create our main page-wide Vue application
/*
window.app = new Vue({
    el: '#app',
    store
});
*/
/**
 * Vue is only a view framework, and cannot do much with data or business logic, so we need to build our own custom services to provide core shared functionality
 * This is presently being published on the window to make it global, although it ought to be exported as a global by Webpack somehow.  This is loaded first to ensure
 * that all Vue application instances will load with the service already in existance should they need it.  This could probably be exposed the same way as Vue is at the 
 * bottom of this page by defining it as a const too
 */
//port {PageService} from './pageService.js';

//nsole.log(new PageService());


// Adding the service as a property of the view application itself so that it is easily accessed by any other component at any time
//window.app.pageService = new PageService();


window.app = new Vue({
    el: '#app',
    vuetify : new Vuetify({
      icons: {
        iconfont: 'md',
      }
    }),
    //store: store
 //   pageService: new PageService(),
 //   flipper: "dolphin",
 //   ffs: "work"
//    vuetify,
});

console.log("created app: ", window.app);


//require('./vueComponents');
