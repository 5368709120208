<template>
    <v-container>
        <v-layout row wrap>
            <v-col v-if="this.field.schema.displayName != 'none'" cols="2">
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-col>

			<v-col cols="1">
				<span v-on:click="toggleValidationGuide" v-if="this.field.validation && this.field.validation.length"><v-icon>information_outline</v-icon></span>
			</v-col>

            <v-col cols="3">
                <v-btn v-on:click="chooseOperator" 
                       v-show="!shouldShowPicker">{{ buttonLabel }}</v-btn>
                <v-btn v-on:click="clearOperator" 
                       v-show="selectedOperator">Clear</v-btn>
            </v-col>

			<v-col cols="6">
				<v-alert type="info" v-if="this.showValidationGuide && this.field.validation && this.field.validation.length">
                	<div v-for="(rule, ruleIndex) in this.field.validation" :key="ruleIndex">{{ validationRuleAsString(rule) }}</div>
            	</v-alert>
			</v-col>

            <v-col cols="10" v-if="shouldShowPicker">
                <v-container>
                    <v-layout row wrap>
 
                        <v-col cols="2">Travel Operator: </v-col>
                        <v-col cols="10">
                            <v-text-field
                                v-model="operatorViewName" 
                                v-on:keyup="updateOperatorName()"
                             ></v-text-field>
                         </v-col>

                         <v-col cols="12" v-if="operatorOptions.length">
                                 <ul>
                                     <li v-for="operator in operatorOptions" v-on:click="selectOperator(operator.user_key)">({{ operator.username }}) {{ operator.first_name }} {{ operator.surname }}</li>
                                 </ul>
                         </v-col>

                          <v-col cols="12">
                              <v-btn v-on:click="done">Done</v-btn>
                          </v-col>

                   </v-layout>
               </v-container>
           </v-col>

           <input type="hidden" :name="field.fieldName" :value="field.value">

			<v-alert type="error" dismissable value="true" v-if="errorMessages && errorMessages.length">
                <div v-for="(error, index) in errorMessages" :key="index" >{{ error.reason }}*</div>
            </v-alert>

        </v-layout>
    </v-container>
</template>

<script>
    /**
     * Allows the selection of an operator ID by choosing its name or code from
     * a selection downloaded from the API on creation
     *
     * @todo refactor, make more efficient
     */
    export default {
        name: 'ies-input-travel-operator-key',
        props: ['field', 'configuration'],
        data() {
            return {
                /** string view model */
                operatorViewName: '',

                /** array of operator options presented to the user */
                operatorOptions: [],

                /** data model of all supported operators */
                operators: [],

				showValidationGuide: false,
				errorMessages: [],

                /** view model to determine whether the hotel  picker should display */
                shouldShowPicker: false,

                /** The operator chosen by the user */
                viewValue: parseInt(this.field.value),

                selectedOperator: null,

                /** For where the component is used in event mode to talk to other components */
                config: {'shouldEmitOnSelect': false, 'selectEventName': ''}
            }
        },
        created:function() {
            this.getOperators();

            if (this.configuration){
                var config = JSON.parse(this.configuration);
                console.log(config);
                if (config.shouldEmitOnSelect && config.selectEventName){
                    this.config.shouldEmitOnSelect = config.shouldEmitOnSelect;
                    this.config.selectEventName = config.selectEventName;
                }
            }
			this.addEventListeners();
        },
        methods: {
			addEventListeners: function(){
                var self = this;

            	this.$root.$on('Field: set errors', function(payload){
                	console.log("TEXT: I heard ", payload);
                	if (payload.modelName = self.field.modelName && payload.fieldName == self.field.fieldName){
                   		self.errorMessages = payload.errors;
                    	console.log("TEXT: I set ", self.field.errors);
                	}
            	});
            },

			toggleValidationGuide: function(){
    	        this.showValidationGuide = !this.showValidationGuide;
	        },
			
			validationRuleAsString: function(rule){
            	console.log("explain rule");
            	var explainer = '';
           		switch(rule.ruleType){
            	    case 'minLength': explainer = "The text must be at least " + rule.value + " characters long"; break;
        	        case 'maxLength': explainer = "The text must not be more than " + rule.value + " characters long"; break;
    	            case 'minValue': explainer = "The value must be more than " + rule.value; break;
	                case 'maxValue': explainer = "The value must be less than " + rule.value; break;
                	case 'notEmpty': explainer = "The field must not be empty"; break;
                	case 'regExp': explainer = "Pattern: " + rule.value; break;
                	default:
                	    explainer = rule.ruleType + ' ' + rule.value;
            	}
            	console.log(explainer);
            	return explainer;
        	},

            getOperators:function(){
                var self = this;
                axios.get('/api/travelOperators')
                .then(function(response){
                    this.operators = response.data.travelOperators;
                    this.operators.forEach(function(operator){
                        if (operator.user_key == self.field.value){
                            self.selectedOperator = operator;

                            // Set the view models so that it is reflected to the users
                            self.operatorViewName = operator.username;
                        }
                    });
                }.bind(this));
            },

            updateOperatorName: function(){
                var self = this;
 
                var operatorOptions = [];
                this.operators.forEach(function(operator){
                    let fullname = operator.first_name + ' ' + operator.surname;
                    if (operator.username.toLowerCase().includes(self.operatorViewName.toLowerCase()) || fullname.toLowerCase().includes(self.operatorViewName.toLowerCase())){
                        operatorOptions.push(operator);
                    }
                });
                this.operatorOptions = operatorOptions;

                // If there is only one hotel left in the list of options then we select it
                if (this.operatorOptions.length === 1){
                    this.selectedOperator = this.operatorOptions[0];
                    this.updateFieldValue();
                }
            },

            selectOperator: function(operatorKey){
                var self = this;
                this.operators.forEach(function(operator){
                    if (operator.user_key === operatorKey){
                        self.selectedOperator = operator;
                        self.operatorViewName = operator.first_name + ' ' + operator.surname;
                        self.updateFieldValue();
                    }
                });
            },

            chooseOperator: function(){
                this.shouldShowPicker = true;
            },

            clearOperator: function(){
                this.selectedOperator = null;
                this.operatorViewName = '';
                this.field.value = null;

                if (this.config.shouldEmitOnSelect){
                    console.log("I should emit ", this.config.selectEventName);
                    this.$root.$emit(this.config.selectEventName, {value: null});
                }
            },

            done: function(){
                this.shouldShowPicker = false;
            },

            updateFieldValue: function(){
                this.field.value = this.selectedOperator.user_key;

                console.log("updatefieldvalue on select: ", this.config);
                if (this.config.shouldEmitOnSelect){
                    console.log("I should emit ", this.config.selectEventName);
                    this.$root.$emit(this.config.selectEventName, {value: this.field.value});
                }
            }
        },
        watch: {
            viewValue: function (newValue) {
            }
        },
        computed: {
            buttonLabel: function(){
                return this.selectedOperator ? "(" + this.selectedOperator.username + ") " + this.selectedOperator.first_name + ' ' + this.selectedOperator.surname : "Choose";
            }
        }

    }
</script>


