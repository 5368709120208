var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-flex",
        { attrs: { md1: "" } },
        [
          _c(
            "v-btn",
            {
              attrs: { id: "addNewType", color: "info" },
              on: { click: _vm.openNewDialog },
            },
            [_vm._v("Add New")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "ies-green",
          attrs: { large: "", fixed: "", bottom: "", right: "" },
          on: { click: _vm.saveChanged },
        },
        [_vm._v("SAVE")]
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.editDialog,
            callback: function ($$v) {
              _vm.editDialog = $$v
            },
            expression: "editDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Edit Content Type"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-flex", { attrs: { md10: "" } }, [
                            _vm._v(
                              "\n                            Edit name\n                            "
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.editName,
                                  expression: "editName",
                                },
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.editName },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.editName = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("v-flex", { attrs: { md10: "" } }, [
                                _c("br"),
                                _c("br"),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-expansion-panel",
                                      [
                                        _c(
                                          "v-expansion-panel-content",
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "header" },
                                                slot: "header",
                                              },
                                              [
                                                _vm._v(
                                                  "Do you want to delete this content type?"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-card",
                                              [
                                                _c(
                                                  "v-card-text",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color: "error",
                                                          "data-key":
                                                            _vm.editKey,
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.deleteRecord,
                                                        },
                                                      },
                                                      [_vm._v("Delete")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", flat: "" },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v("Close")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", flat: "" },
                      on: { click: _vm.updateName },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          model: {
            value: _vm.newDialog,
            callback: function ($$v) {
              _vm.newDialog = $$v
            },
            expression: "newDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Add new Content Type"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-flex", { attrs: { md12: "" } }, [
                            _c("div", { staticClass: "margin-bottom-medium" }, [
                              _vm._v(
                                "\n                                Enter name\n                                "
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.newType.name,
                                    expression: "newType.name",
                                  },
                                ],
                                attrs: { type: "text", maxlength: "30" },
                                domProps: { value: _vm.newType.name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.newType,
                                      "name",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "margin-bottom-medium" },
                              [
                                _c("span", { staticClass: "headline" }, [
                                  _vm._v("GUARD"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-layout",
                                  { attrs: { row: "", "justify-center": "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs4: "" } },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.guards[1])),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs8: "" } },
                                          [
                                            _c("v-select", {
                                              staticClass:
                                                "input-group--focused",
                                              attrs: {
                                                light: "",
                                                dense: "",
                                                items:
                                                  _vm.newType
                                                    .permissionsOptions,
                                                "item-text": "text",
                                                "item-value": "value",
                                              },
                                              model: {
                                                value:
                                                  _vm.newType.permissions
                                                    .guard[1],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newType.permissions
                                                      .guard,
                                                    1,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newType.permissions.guard[1]",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs4: "" } },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.guards[2])),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs8: "" } },
                                          [
                                            _c("v-select", {
                                              staticClass:
                                                "input-group--focused",
                                              attrs: {
                                                light: "",
                                                dense: "",
                                                items:
                                                  _vm.newType
                                                    .permissionsOptions,
                                                "item-text": "text",
                                                "item-value": "value",
                                              },
                                              model: {
                                                value:
                                                  _vm.newType.permissions
                                                    .guard[2],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newType.permissions
                                                      .guard,
                                                    2,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newType.permissions.guard[2]",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs4: "" } },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.guards[3])),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs8: "" } },
                                          [
                                            _c("v-select", {
                                              staticClass:
                                                "input-group--focused",
                                              attrs: {
                                                light: "",
                                                dense: "",
                                                items:
                                                  _vm.newType
                                                    .permissionsOptions,
                                                "item-text": "text",
                                                "item-value": "value",
                                              },
                                              model: {
                                                value:
                                                  _vm.newType.permissions
                                                    .guard[3],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newType.permissions
                                                      .guard,
                                                    3,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newType.permissions.guard[3]",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "margin-bottom-medium" },
                              [
                                _c("span", { staticClass: "headline" }, [
                                  _vm._v("DEPARTMENTS"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-layout",
                                  { attrs: { row: "", "justify-center": "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs4: "" } },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.departments[3])),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs10: "" } },
                                          [
                                            _c("v-select", {
                                              staticClass:
                                                "input-group--focused",
                                              attrs: {
                                                light: "",
                                                dense: "",
                                                items:
                                                  _vm.newType
                                                    .permissionsOptions,
                                                "item-text": "text",
                                                "item-value": "value",
                                              },
                                              model: {
                                                value:
                                                  _vm.newType.permissions
                                                    .department[3],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newType.permissions
                                                      .department,
                                                    3,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newType.permissions.department[3]",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs4: "" } },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.departments[6])),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs10: "" } },
                                          [
                                            _c("v-select", {
                                              staticClass:
                                                "input-group--focused",
                                              attrs: {
                                                light: "",
                                                dense: "",
                                                items:
                                                  _vm.newType
                                                    .permissionsOptions,
                                                "item-text": "text",
                                                "item-value": "value",
                                              },
                                              model: {
                                                value:
                                                  _vm.newType.permissions
                                                    .department[6],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newType.permissions
                                                      .department,
                                                    6,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newType.permissions.department[6]",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs4: "" } },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.departments[4])),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs10: "" } },
                                          [
                                            _c("v-select", {
                                              staticClass:
                                                "input-group--focused",
                                              attrs: {
                                                light: "",
                                                dense: "",
                                                items:
                                                  _vm.newType
                                                    .permissionsOptions,
                                                "item-text": "text",
                                                "item-value": "value",
                                              },
                                              model: {
                                                value:
                                                  _vm.newType.permissions
                                                    .department[4],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newType.permissions
                                                      .department,
                                                    4,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newType.permissions.department[4]",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs4: "" } },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.departments[5])),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs10: "" } },
                                          [
                                            _c("v-select", {
                                              staticClass:
                                                "input-group--focused",
                                              attrs: {
                                                light: "",
                                                dense: "",
                                                items:
                                                  _vm.newType
                                                    .permissionsOptions,
                                                "item-text": "text",
                                                "item-value": "value",
                                              },
                                              model: {
                                                value:
                                                  _vm.newType.permissions
                                                    .department[5],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newType.permissions
                                                      .department,
                                                    5,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newType.permissions.department[5]",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs4: "" } },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.departments[1])),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs10: "" } },
                                          [
                                            _c("v-select", {
                                              staticClass:
                                                "input-group--focused",
                                              attrs: {
                                                light: "",
                                                dense: "",
                                                items:
                                                  _vm.newType
                                                    .permissionsOptions,
                                                "item-text": "text",
                                                "item-value": "value",
                                              },
                                              model: {
                                                value:
                                                  _vm.newType.permissions
                                                    .department[1],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newType.permissions
                                                      .department,
                                                    1,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newType.permissions.department[1]",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs4: "" } },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.departments[2])),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs10: "" } },
                                          [
                                            _c("v-select", {
                                              staticClass:
                                                "input-group--focused",
                                              attrs: {
                                                light: "",
                                                dense: "",
                                                items:
                                                  _vm.newType
                                                    .permissionsOptions,
                                                "item-text": "text",
                                                "item-value": "value",
                                              },
                                              model: {
                                                value:
                                                  _vm.newType.permissions
                                                    .department[2],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newType.permissions
                                                      .department,
                                                    2,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newType.permissions.department[2]",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs4: "" } },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.departments[7])),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs10: "" } },
                                          [
                                            _c("v-select", {
                                              staticClass:
                                                "input-group--focused",
                                              attrs: {
                                                light: "",
                                                dense: "",
                                                items:
                                                  _vm.newType
                                                    .permissionsOptions,
                                                "item-text": "text",
                                                "item-value": "value",
                                              },
                                              model: {
                                                value:
                                                  _vm.newType.permissions
                                                    .department[7],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newType.permissions
                                                      .department,
                                                    7,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newType.permissions.department[7]",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("span", { staticClass: "headline" }, [
                                  _vm._v("ROLES"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-layout",
                                  { attrs: { row: "", "justify-center": "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs4: "" } },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.roles[1])),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs8: "" } },
                                          [
                                            _c("v-select", {
                                              staticClass:
                                                "input-group--focused",
                                              attrs: {
                                                light: "",
                                                dense: "",
                                                items:
                                                  _vm.newType
                                                    .permissionsOptions,
                                                "item-text": "text",
                                                "item-value": "value",
                                              },
                                              model: {
                                                value:
                                                  _vm.newType.permissions
                                                    .role[1],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newType.permissions
                                                      .role,
                                                    1,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newType.permissions.role[1]",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs4: "" } },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.roles[2])),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs8: "" } },
                                          [
                                            _c("v-select", {
                                              staticClass:
                                                "input-group--focused",
                                              attrs: {
                                                light: "",
                                                dense: "",
                                                items:
                                                  _vm.newType
                                                    .permissionsOptions,
                                                "item-text": "text",
                                                "item-value": "value",
                                              },
                                              model: {
                                                value:
                                                  _vm.newType.permissions
                                                    .role[2],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newType.permissions
                                                      .role,
                                                    2,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newType.permissions.role[2]",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs4: "" } },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(this.roles[3])),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs8: "" } },
                                          [
                                            _c("v-select", {
                                              staticClass:
                                                "input-group--focused",
                                              attrs: {
                                                light: "",
                                                dense: "",
                                                items:
                                                  _vm.newType
                                                    .permissionsOptions,
                                                "item-text": "text",
                                                "item-value": "value",
                                              },
                                              model: {
                                                value:
                                                  _vm.newType.permissions
                                                    .role[3],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newType.permissions
                                                      .role,
                                                    3,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newType.permissions.role[3]",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", flat: "" },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v("Close")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", flat: "" },
                      on: { click: _vm.saveNew },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: parseInt(6000),
            "multi-line": "",
            color: "ies-green",
          },
          model: {
            value: _vm.successSingleSnackbar,
            callback: function ($$v) {
              _vm.successSingleSnackbar = $$v
            },
            expression: "successSingleSnackbar",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "full-height full-width v-align-container text-xs-center",
            },
            [
              _c("h4", { staticClass: "ies-dark-gray--text v-align-div" }, [
                _vm._v(_vm._s(this.snackbarText)),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: parseInt(6000),
            "multi-line": "",
            color: "ies-coral",
          },
          model: {
            value: _vm.errorSnackbar,
            callback: function ($$v) {
              _vm.errorSnackbar = $$v
            },
            expression: "errorSnackbar",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "full-height full-width v-align-container text-xs-center",
            },
            [
              _c("h4", { staticClass: "ies-dark-gray--text v-align-div" }, [
                _vm._v(_vm._s(this.snackbarText)),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }