var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _c("span", { staticClass: "subheader" }, [_vm._v("Global Summary")]),
      ]),
      _vm._v(" "),
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.continents,
          "item-key": "region_key",
          "hide-default-footer": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function (props) {
              return [
                _c(
                  "tr",
                  {
                    on: {
                      click: function ($event) {
                        props.expanded = !props.expanded
                      },
                    },
                  },
                  [
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(props.item.region_name)),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-xs-center" }, [
                      _vm._v("£" + _vm._s(props.item.invoices.toFixed(2))),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-xs-center" }, [
                      _vm._v("£" + _vm._s(props.item.payments.toFixed(2))),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-xs-center" }, [
                      _vm._v("£" + _vm._s(props.item.balance.toFixed(2))),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "expand",
            fn: function (props) {
              return [
                _c("region", { attrs: { regionKey: props.item.region_key } }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }