var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { staticClass: "ies-switch", attrs: { row: "", wrap: "" } },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("label", { attrs: { for: this.field.fieldName } }, [
              _vm._v(" " + _vm._s(this.field.schema.displayName) + ": "),
            ]),
          ]),
          _vm._v(" "),
          this.field.introduction
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _vm._v(_vm._s(this.field.introduction)),
              ])
            : _vm._e(),
          _vm._v(" "),
          this.field.information
            ? _c(
                "v-alert",
                { attrs: { type: "info", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.information))]
              )
            : _vm._e(),
          _vm._v(" "),
          this.field.warning
            ? _c(
                "v-alert",
                { attrs: { type: "warning", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.warning))]
              )
            : _vm._e(),
          _vm._v(" "),
          this.field.error
            ? _c(
                "v-alert",
                { attrs: { type: "error", dismissable: "", value: "true" } },
                [_vm._v(_vm._s(this.field.error))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-switch", {
                attrs: {
                  label: _vm.labelString,
                  color: _vm.colorParsed,
                  disabled: this.field.disabled,
                },
                on: {
                  change: function ($event) {
                    return _vm.updateField()
                  },
                },
                model: {
                  value: _vm.input,
                  callback: function ($$v) {
                    _vm.input = $$v
                  },
                  expression: "input",
                },
              }),
              _vm._v(" "),
              _c("input", {
                attrs: { name: this.field.fieldName, type: "hidden" },
                domProps: { value: _vm.input },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }