<template>
    <v-container fluid>
        <v-layout row-sm column>
            <v-flex xs12>
                <strong>Cancelled By User:</strong>
                <span v-if="showCancelledByName">{{ cancelledByName }}</span>

                <input :name="this.field.fieldName" type="hidden" v-model="userKey" v-on:input="updateField()" :ref="this.field.fieldName">

            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import { loggedUser } from '@/mixins/loggedUser';
    import { adminUser } from '@/mixins/adminUser';
    import { format } from 'date-fns';

    export default {
        name: 'ies-voucher-cancel',
        props: ['field'],
        mixins: [loggedUser, adminUser],
        data () {
            return {
                /** @var userKey this field value passed from Dynamic Page Loader */
                userKey: this.field.value,
                /** @var showCancelledByName - determine whether we should display cancelled by name */
                showCancelledByName: true,
            }
        },
        methods: {
            /**
             * Update this field value
             */
            updateField: function(){
                this.field.value = this.userKey
            },

            /**
             * Set up even listener on is_cancelled checkbox, that will update cancelled_by_user_name & cancelled_date on change
             */
            setUpListeners() {
                let isCancelled = $("input[name='is_cancelled']");

                isCancelled.on('change', () => {
                    // we need to set up timeout here to be sure that v-switch value has been updated before trying to get it's value
                    setTimeout(() => {
                        if (isCancelled.val() == 'true') {
                            this.cancelChecked();
                        }
                        else {
                            this.cancelUnchecked();
                        }
                    },100);

                })
            },


            /**
             * Handle the logic when user select that this voucher should be cancelled:
             * Updates cancelled_date and cancelled_by inputs
             */
            cancelChecked() {
                // get logged in user user_key from mixin
                let userKey = this.getLoggedUserKey();
                this.setCancelledBy(userKey);
                this.setCancelledDate();
                this.setDeactivateActionDate(true);
                this.showCancelledByName = true;
            },

            /**
             * Clear cancelled_date and cancelled_by_user_name inputs when user un checks checkbox
             */
            cancelUnchecked() {
                this.setCancelledBy('');
                this.updateInput('cancelled_date', '');
                this.setDeactivateActionDate(false);
                this.showCancelledByName = false;
            },

            /**
             * Set cancelled_by_user_name field input with passed user key
             * @param userKey - AdminUser user_key
             */
            setCancelledBy(userKey) {
                this.userKey = userKey;
                this.updateField();
            },

            /**
             * Set cencelled_date input with current date
             */
            setCancelledDate() {
                let today = format(new Date(), 'YYYY-MM-DD HH:mm:ss');

                // update this field
                this.updateInput('cancelled_date', today);
            },

            /**
             * Set voucher deactivated_at and deactivate_action, if val=true set to current, if false set to null
             * @param boolean
             */
            setDeactivateActionDate(val) {
                if (val) {
                    let today = format(new Date(), 'YYYY-MM-DD HH:mm:ss');
                    this.updateInput('deactivate_action', 3); // 3 is cancel
                    this.updateInput('deactivate_date', today);
                }
                else {
                    this.updateInput('deactivate_action', '');
                    this.updateInput('deactivate_date', '');
                }
            },

            /**
             * Update given input value and trigger this input updateFields method
             * @param name
             * @param value
             */
            updateInput(name, value) {
                let search = "input[name='" + name + "']";
                let input = $(search);

                if (input) {
                    input.val(value);

                    // dispatch input even so vue will be notify to trigger updateField() method
                    var e = document.createEvent('HTMLEvents');
                    e.initEvent('input', true, true);
                    input[0].dispatchEvent(e);
                }
            },


        },
        mounted() {
            this.setUpListeners();

        },
        computed: {
            /**
             * Find AdminUser username using adminUser mixin
             * @returns string username
             */
            cancelledByName() {
                let user = this.getAdminUser(this.userKey);
                if (user) {
                    return user.username;
                }
            }
        }
    }
</script>


