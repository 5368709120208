var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar",
    {
      staticClass: "no-print",
      staticStyle: { "z-index": "1000" },
      attrs: { fixed: "", flex: "" },
    },
    [
      _c(
        "v-app-bar-nav-icon",
        {
          on: {
            click: function ($event) {
              return _vm.updateNav()
            },
          },
        },
        [_c("v-icon", [_vm._v("menu")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-toolbar-title",
        [
          _c("v-flex", [
            _c("a", { attrs: { href: "/" } }, [
              _c("img", {
                staticClass: "navbar-logo",
                attrs: {
                  src: "/images/i-escape-logo.png",
                  alt: "i-escape logo",
                },
              }),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-toolbar-items",
        {
          staticClass: "d-none d-sm-flex",
          staticStyle: { "max-width": "100%", "min-width": "70%" },
        },
        [
          _c(
            "v-layout",
            { staticClass: "mt-1 ml-1", attrs: { row: "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "qa-button-container",
                  attrs: { lg: "9", md: "12" },
                },
                [_c("ies-quick-access-buttons")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "d-none d-lg-flex", attrs: { lg: "3" } },
                [_c("ies-smart-search")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }