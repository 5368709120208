<template>
    <v-col cols="12" lg="4" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">hotel</v-icon> Reservations
            </v-card-title>

            <v-card-text>
                <div xs12 v-if="!systemError && loading">Loading...</div>
                <div xs12 v-if="systemError">Sorry, this content is not working</div>

                <div xs12 v-if="!systemError && !loading">
                    <v-container fluid>
                        <h3>Upcoming Reservations</h3>
                        <div v-if="!reservations.length">This hotel has no upcoming reservations</div>
                        <v-list dense>
                            <template v-for="(reservation, index) in reservations">
                                <v-list-item ripple v-bind:key="index" :href="reservation.url" class="news-list-item">
                                    <v-list-item-title>
                                        <v-icon left medium>event</v-icon>
                                        <strong>{{ reservation.date_arrival_string }}</strong>
                                        <span>{{ reservation.stay_length }} nights</span>
                                        <span v-if="!hotelKey">at ({{ reservation.hotel.hotel_code }}) {{ reservation.hotel.hotel_name }}</span>
                                        <span>{{ reservation.reference }}</span>
                                        <span>{{ reservation.user.first_name }} {{ reservation.user.last_name }}</span>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-divider v-if="index + 1 < reservations.length"></v-divider>
                            </template>
                        </v-list>
                    </v-container>
                </div>

                <div xs12 v-if="enquiries.length">
                     <h3>Enquiries</h3>
                     <v-list dense>
                         <template v-for="(enquiry, index) in enquiries">
                             <v-list-item ripple v-bind:key="index" :href="enquiry.url" class="news-list-item">
                                 <v-list-item-title>
                                     <v-icon left medium>live_help</v-icon>
                                     <strong>{{ enquiry.date_arrival_string }}</strong>
                                     <span>{{ enquiry.stay_length }} nights</span>
                                     <span v-if="!hotelKey">at ({{ enquiry.hotel.hotel_code }}) {{ enquiry.hotel.hotel_name }}</span>
                                     <span>{{ enquiry.reference }}</span>
                                     <span>{{ enquiry.user.first_name }} {{ enquiry.user.last_name }}</span>
                                 </v-list-item-title>
                             </v-list-item>
                             <v-divider v-if="index + 1 < enquiries.length"></v-divider>
                         </template>
                     </v-list>
                </div>

                <v-btn block :href="resAdminUrl">Reservations Management</v-btn>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<script>
    import axios from 'axios';

    /**
     * Display buttons linking through to a hotels' pages
     */
    export default {
        props:['pageid'],

        data: function() {
            return {
                monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                hotelKey: 0,
                systemError: false,
                loading: true,
                resAdminUrl: '',
                enquiries: [],
                reservations: []
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            var parts = window.location.pathname.split('/');
            //this.hotelKey = parts[2];// This is surely not the best way to do this, but for now it will have to do
            this.hotelKey = this.pageid;
            this.resAdminUrl = "/hotel/" + this.hotelKey + '/reservation-administration';
            this.loadReservations();
            this.loadEnquiries();
            setInterval( this.loadReservations, 60000 );
        },

        methods: {
            /**
             * Load the latest data from the API
             */
            async loadReservations() {
                var self = this;
                const response = await axios.get('api/reservations/upcoming' + (this.hotelKey ? '?hotelKey=' + this.hotelKey : ''))
                .then(response => {
                    this.reservations = response.data.reservations;
                    this.reservations.forEach(function(reservation){
                        var arrivalDate = new Date(reservation.date_arrival);
                        var departureDate = new Date(reservation.date_departure);
                        reservation.date_arrival_string = arrivalDate.getDate() + ' ' + self.monthNames[arrivalDate.getMonth()] + ' ' + arrivalDate.getFullYear();
                        reservation.date_departure_string = departureDate.getDate() + ' ' + self.monthNames[departureDate.getMonth()] + ' ' + departureDate.getFullYear();
                    });
console.log('my reservations: ',this.reservations);
                    self.loading = false;
                    this.systemError = false;
                }).catch(error => {
                    this.systemError = true;
                    console.log(error);
                });
            },

            async loadEnquiries() {
                var self = this;
                const response = await axios.get('api/reservations/enquiries' + (this.hotelKey ? '?hotelKey=' + this.hotelKey : ''))
                .then(response => {
                    this.enquiries = response.data.enquiries;
                    this.enquiries.forEach(function(reservation){
                        var arrivalDate = new Date(reservation.date_arrival);
                        var departureDate = new Date(reservation.date_departure);
                        reservation.date_arrival_string = arrivalDate.getDate() + ' ' + self.monthNames[arrivalDate.getMonth()] + ' ' + arrivalDate.getFullYear();
                        reservation.date_departure_string = departureDate.getDate() + ' ' + departureDate.getMonth() + ' ' + departureDate.getFullYear();
                        self.loading = false;
                    });
console.log('my enquiries: ',this.enquiries);
                }).catch(error => {
                    console.log(error);
                });
            }
        },

        computed: {}
    }
</script>

