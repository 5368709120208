var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "4", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("subject")]),
              _vm._v(" Key Information\n        "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-container", [
                _vm.hasProblem
                  ? _c("div", [_vm._v("Sorry, this content is not working")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.loaded && !_vm.hasProblem
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-row",
                          { attrs: { wrap: "" } },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _vm._v(
                                  "\n                    Country, Region:\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c(
                                  "a",
                                  { attrs: { href: _vm.hotelAddressUrl } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.hotel.region.country.region_name
                                      ) +
                                        ", " +
                                        _vm._s(_vm.hotel.region.region_name)
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _vm._v(
                                  "\n                    URL:\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.hotelUrl,
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.hotel.website))]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _vm._v(
                                  "\n                    Reservation contact / Tel:\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "8" },
                              },
                              _vm._l(
                                _vm.hotel.reservation_phones.slice(0, 1),
                                function (contact) {
                                  return _c("div", [
                                    _c(
                                      "a",
                                      { attrs: { href: _vm.hotelContactsUrl } },
                                      [
                                        contact.phone_number
                                          ? _c("span", [
                                              _vm._v(
                                                "+" +
                                                  _vm._s(contact.country_code) +
                                                  " " +
                                                  _vm._s(contact.area_code) +
                                                  " " +
                                                  _vm._s(contact.phone_number)
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ])
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _vm._v(
                                  "\n                    Travel operator:\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "8" },
                              },
                              _vm._l(
                                _vm.hotel.user_associations,
                                function (assoc) {
                                  return _c("div", [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: _vm.travelOperatorUrl },
                                      },
                                      [
                                        assoc.type_of_association !=
                                        "photo_library"
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  assoc.legacy_admin_user
                                                    .first_name
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    assoc.legacy_admin_user
                                                      .surname
                                                  )
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ])
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _vm._v(
                                  "\n                    Display status:\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _vm.hotel.display
                                  ? _c("span", [_vm._v("✔")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  { attrs: { href: _vm.displayStatusUrl } },
                                  [
                                    _vm.mostRecentDisplayStatus
                                      ? _c("span", [
                                          _vm.mostRecentDisplayStatus.made_live
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.mostRecentDisplayStatus
                                                      .display_status == 2
                                                      ? "Previewable"
                                                      : "Made live"
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.mostRecentDisplayStatus.removed
                                            ? _c("span", [_vm._v("Removed")])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.mostRecentDisplayStatus.reinstated
                                            ? _c("span", [_vm._v("Reinstated")])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.mostRecentDisplayStatus.upgraded
                                            ? _c("span", [_vm._v("Upgraded")])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.mostRecentDisplayStatus
                                                  .date_of_change
                                              )
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.mostRecentDisplayStatus
                                      ? _c("span", [_vm._v("No data")])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _vm._v(
                                  "\n                    Visit status:\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _vm.mostRecentVisit
                                  ? _c("span", [_vm._v("✔")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  { attrs: { href: _vm.recentVisitUrl } },
                                  [
                                    _vm.mostRecentVisit
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.hotel.visit_status
                                                ? "Authored review"
                                                : "Pre-review"
                                            ) +
                                              _vm._s(
                                                _vm.mostRecentVisit.visit_date
                                                  ? ", latest visit " +
                                                      _vm.mostRecentVisit
                                                        .visit_date
                                                  : ""
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.mostRecentVisit
                                      ? _c("span", [_vm._v("No data")])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _vm._v(
                                  "\n                    Billing method:\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c("a", { attrs: { href: _vm.contractUrl } }, [
                                  _vm._v(_vm._s(_vm.billingMethod)),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _vm._v(
                                  "\n                    Standard Commission:\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c("a", { attrs: { href: _vm.contractUrl } }, [
                                  _vm._v(
                                    _vm._s(_vm.hotel.commission_agreed) + "%"
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _vm._v(
                                  "\n                    Special offers:\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "8" },
                              },
                              _vm._l(
                                _vm.hotel.special_offers,
                                function (offer) {
                                  return _c("div", [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(offer.online ? "✔" : "✖") + " "
                                      ),
                                      _c(
                                        "a",
                                        { attrs: { href: _vm.offerUrl } },
                                        [_vm._v(_vm._s(offer.name))]
                                      ),
                                    ]),
                                  ])
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _vm._v(
                                  "\n                    Rate types:\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c("a", { attrs: { href: _vm.rateTypesUrl } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.numOnlineRateTypes
                                        ? _vm.numOnlineRateTypes + " online"
                                        : ""
                                    ) +
                                      _vm._s(
                                        _vm.numOnlineRateTypes &&
                                          _vm.numOfflineRateTypes
                                          ? ", "
                                          : ""
                                      ) +
                                      _vm._s(
                                        _vm.numOfflineRateTypes
                                          ? _vm.numOfflineRateTypes + " offline"
                                          : ""
                                      )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _vm._v(
                                  "\n                    Room types:\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c("a", { attrs: { href: _vm.roomTypesUrl } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.numOnlineRoomTypes
                                        ? _vm.numOnlineRoomTypes + " online"
                                        : ""
                                    ) +
                                      _vm._s(
                                        _vm.numOnlineRoomTypes &&
                                          _vm.numOfflineRoomTypes
                                          ? ", "
                                          : ""
                                      ) +
                                      _vm._s(
                                        _vm.numOfflineRoomTypes
                                          ? _vm.numOfflineRoomTypes + " offline"
                                          : ""
                                      )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _vm._v(
                                  "\n                    Currency:\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c("a", { attrs: { href: _vm.currencyUrl } }, [
                                  _vm._v(
                                    _vm._s(_vm.hotel.currency.currency_code)
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _vm._v(
                                  "\n                    Booking system:\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c(
                                  "a",
                                  { attrs: { href: _vm.bookingSystemUrl } },
                                  [_vm._v(_vm._s(_vm.bookingSystem))]
                                ),
                                _vm._v(" "),
                                _vm.hotel.i_calendar_connections.length
                                  ? _c("span", [
                                      _c(
                                        "a",
                                        { attrs: { href: _vm.iCalUrl } },
                                        [
                                          _vm._v(
                                            "iCal (" +
                                              _vm._s(
                                                _vm.hotel.i_calendar_connections
                                                  .length
                                              ) +
                                              ")"
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _vm._v(
                                  "\n                    i-escape gift:\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c("a", { attrs: { href: _vm.contractUrl } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.hotel.extra_discount
                                        ? _vm.hotel.extra_discount
                                        : "None"
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _vm._v(
                                  "\n                    Review format:\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c("a", { attrs: { href: _vm.contractUrl } }, [
                                  _vm._v(_vm._s(_vm.reviewFormat)),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _vm._v(
                                  "\n                    Accommodation type:\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c(
                                  "a",
                                  { attrs: { href: _vm.backgroundUrl } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.hotel.exact_no_of_rooms
                                          ? _vm.hotel.exact_no_of_rooms
                                          : "No data"
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  { attrs: { href: _vm.backgroundUrl } },
                                  [_vm._v(_vm._s(_vm.hotel.no_of_rooms))]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _vm._v(
                                  "\n                    Rate Parity:\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-1",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _vm.mostRecentParity
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "pa-2",
                                        class: {
                                          "parity-good":
                                            _vm.mostRecentParity.status ==
                                            "Good",
                                          "parity-partial":
                                            _vm.mostRecentParity.status ==
                                            "Partial",
                                          "parity-bad":
                                            _vm.mostRecentParity.status ==
                                            "Bad",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.mostRecentParity.status
                                            ) +
                                            " (" +
                                            _vm._s(
                                              _vm.mostRecentParity
                                                .date_of_change
                                            ) +
                                            ")\n                    "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.mostRecentParity
                                  ? _c("span", [_vm._v("No data")])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }