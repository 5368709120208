var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-flex",
    { attrs: { xs12: "", lg2: "", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _vm._v("\n            Create Invoice\n        "),
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c("v-btn", { attrs: { disabled: "" } }, [
                    _vm._v("Annual Fee Invoice"),
                  ]),
                  _vm._v(" "),
                  _c("v-btn", { attrs: { disabled: "" } }, [
                    _vm._v("Manual Invoice"),
                  ]),
                  _vm._v(" "),
                  _c("v-btn", { attrs: { disabled: "" } }, [
                    _vm._v("Arrears Invoice"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }