var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          this.field.introduction
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _vm._v(_vm._s(this.field.introduction)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              this.field.information
                ? _c(
                    "v-alert",
                    { attrs: { type: "info", dismissable: "", value: true } },
                    [_vm._v(_vm._s(this.field.information))]
                  )
                : _vm._e(),
              _vm._v(" "),
              this.field.warning
                ? _c(
                    "v-alert",
                    {
                      attrs: { type: "warning", dismissable: "", value: true },
                    },
                    [_vm._v(_vm._s(this.field.warning))]
                  )
                : _vm._e(),
              _vm._v(" "),
              this.field.error
                ? _c(
                    "v-alert",
                    { attrs: { type: "error", dismissable: "", value: true } },
                    [_vm._v(_vm._s(this.field.error))]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-col", { attrs: { col: "2" } }, [
            _c("label", { attrs: { for: "range_baby_end" } }, [
              _vm._v("Age Ranges: "),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { id: "age-ranges", cols: "10" } },
            [
              _c("p", { staticStyle: { "margin-bottom": "2.4em" } }, [
                _vm._v(
                  "Enter the Age ranges for Babies, Children and Adults that determine the rates charged, use of cots and extra beds etc."
                ),
                _c("br"),
                _vm._v(
                  "\n\t\t\t\t\tIf Children are NOT accepted, enter Range 4 only."
                ),
                _c("br"),
                _vm._v(
                  "\n\t\t\t\t\tIf Children ARE accepted but there is no rate difference, enter Range 1 as 0-2 yrs and Range 2 as 2-12 yrs)"
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "4" } }, [
                    _c("p", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v("Range 1(optional): Babies"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: [
                            { text: "", value: -1 },
                            { text: 0, value: 0 },
                            { text: 1, value: 1 },
                            { text: 2, value: 2 },
                            { text: 3, value: 3 },
                            { text: 4, value: 4 },
                            { text: 5, value: 5 },
                          ],
                        },
                        model: {
                          value: _vm.viewValues.range_baby_end,
                          callback: function ($$v) {
                            _vm.$set(_vm.viewValues, "range_baby_end", $$v)
                          },
                          expression: "viewValues.range_baby_end",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "4" } }, [
                    _c("p", [_vm._v(" years and under in Baby Cot")]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "4" } }, [
                    _c("p", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v("Range 2(optional): Children"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: [
                            { text: "", value: -1 },
                            { text: 0, value: 0 },
                            { text: 1, value: 1 },
                            { text: 2, value: 2 },
                            { text: 3, value: 3 },
                            { text: 4, value: 4 },
                            { text: 5, value: 5 },
                            { text: 6, value: 6 },
                            { text: 7, value: 7 },
                            { text: 8, value: 8 },
                            { text: 9, value: 9 },
                            { text: 10, value: 10 },
                            { text: 11, value: 11 },
                            { text: 12, value: 12 },
                            { text: 13, value: 13 },
                            { text: 14, value: 14 },
                            { text: 15, value: 15 },
                            { text: 16, value: 16 },
                            { text: 17, value: 17 },
                            { text: 18, value: 18 },
                          ],
                        },
                        model: {
                          value: _vm.viewValues.range_child_start,
                          callback: function ($$v) {
                            _vm.$set(_vm.viewValues, "range_child_start", $$v)
                          },
                          expression: "viewValues.range_child_start",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "2" } }, [
                    _c("p", [_vm._v("years to")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: [
                            { text: "", value: -1 },
                            { text: 0, value: 0 },
                            { text: 1, value: 1 },
                            { text: 2, value: 2 },
                            { text: 3, value: 3 },
                            { text: 4, value: 4 },
                            { text: 5, value: 5 },
                            { text: 6, value: 6 },
                            { text: 7, value: 7 },
                            { text: 8, value: 8 },
                            { text: 9, value: 9 },
                            { text: 10, value: 10 },
                            { text: 11, value: 11 },
                            { text: 12, value: 12 },
                            { text: 13, value: 13 },
                            { text: 14, value: 14 },
                            { text: 15, value: 15 },
                            { text: 16, value: 16 },
                            { text: 17, value: 17 },
                            { text: 18, value: 18 },
                          ],
                        },
                        model: {
                          value: _vm.viewValues.range_child_end,
                          callback: function ($$v) {
                            _vm.$set(_vm.viewValues, "range_child_end", $$v)
                          },
                          expression: "viewValues.range_child_end",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "2" } }, [
                    _c("p", [_vm._v("years")]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "4" } }, [
                    _c("p", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v("Range 3(optional): Older Children"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: [
                            { text: "", value: -1 },
                            { text: 0, value: 0 },
                            { text: 1, value: 1 },
                            { text: 2, value: 2 },
                            { text: 3, value: 3 },
                            { text: 4, value: 4 },
                            { text: 5, value: 5 },
                            { text: 6, value: 6 },
                            { text: 7, value: 7 },
                            { text: 8, value: 8 },
                            { text: 9, value: 9 },
                            { text: 10, value: 10 },
                            { text: 11, value: 11 },
                            { text: 12, value: 12 },
                            { text: 13, value: 13 },
                            { text: 14, value: 14 },
                            { text: 15, value: 15 },
                            { text: 16, value: 16 },
                            { text: 17, value: 17 },
                            { text: 18, value: 18 },
                          ],
                        },
                        model: {
                          value: _vm.viewValues.range_oldchild_start,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.viewValues,
                              "range_oldchild_start",
                              $$v
                            )
                          },
                          expression: "viewValues.range_oldchild_start",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "2" } }, [
                    _c("p", [_vm._v("years to")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: [
                            { text: "", value: -1 },
                            { text: 0, value: 0 },
                            { text: 1, value: 1 },
                            { text: 2, value: 2 },
                            { text: 3, value: 3 },
                            { text: 4, value: 4 },
                            { text: 5, value: 5 },
                            { text: 6, value: 6 },
                            { text: 7, value: 7 },
                            { text: 8, value: 8 },
                            { text: 9, value: 9 },
                            { text: 10, value: 10 },
                            { text: 11, value: 11 },
                            { text: 12, value: 12 },
                            { text: 13, value: 13 },
                            { text: 14, value: 14 },
                            { text: 15, value: 15 },
                            { text: 16, value: 16 },
                            { text: 17, value: 17 },
                            { text: 18, value: 18 },
                          ],
                        },
                        model: {
                          value: _vm.viewValues.range_oldchild_end,
                          callback: function ($$v) {
                            _vm.$set(_vm.viewValues, "range_oldchild_end", $$v)
                          },
                          expression: "viewValues.range_oldchild_end",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "2" } }, [
                    _c("p", [_vm._v("years")]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "4" } }, [
                    _c("p", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v("Range 4: Adult rates are charged for guests"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: [
                            { text: "", value: -1 },
                            { text: 0, value: 0 },
                            { text: 1, value: 1 },
                            { text: 2, value: 2 },
                            { text: 3, value: 3 },
                            { text: 4, value: 4 },
                            { text: 5, value: 5 },
                            { text: 6, value: 6 },
                            { text: 7, value: 7 },
                            { text: 8, value: 8 },
                            { text: 9, value: 9 },
                            { text: 10, value: 10 },
                            { text: 11, value: 11 },
                            { text: 12, value: 12 },
                            { text: 13, value: 13 },
                            { text: 14, value: 14 },
                            { text: 15, value: 15 },
                            { text: 16, value: 16 },
                            { text: 17, value: 17 },
                            { text: 18, value: 18 },
                          ],
                        },
                        model: {
                          value: _vm.viewValues.range_adult_start,
                          callback: function ($$v) {
                            _vm.$set(_vm.viewValues, "range_adult_start", $$v)
                          },
                          expression: "viewValues.range_adult_start",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "4" } }, [
                    _c("p", [_vm._v("+  years")]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          this.field.schema.displayName != "none"
            ? _c("v-col", { attrs: { cols: "2" } }, [
                _c("label", { attrs: { for: this.field.fieldName } }, [
                  _vm._v(" " + _vm._s(this.field.schema.displayName) + ": "),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: this.field.fieldName },
            domProps: { value: _vm.field.value },
          }),
          _vm._v(" "),
          _vm.errorMessages && _vm.errorMessages.length
            ? _c(
                "v-alert",
                { attrs: { type: "error", dismissable: "", value: true } },
                _vm._l(_vm.errorMessages, function (error, index) {
                  return _c("div", { key: index }, [
                    _vm._v(_vm._s(error.reason) + "*"),
                  ])
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }