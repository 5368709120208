var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { staticClass: "d-flex justify-space-between" },
            _vm._l(_vm.buttons, function (button) {
              return _c(
                "v-btn",
                {
                  staticClass: "qa-button",
                  staticStyle: { height: "100%" },
                  on: {
                    click: function ($event) {
                      return _vm.performActions(button)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "qa-button-label" },
                    [
                      _c("v-icon", { staticStyle: { "font-size": "2rem" } }, [
                        _vm._v(_vm._s(button.icon)),
                      ]),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "d-none d-md-block",
                          staticStyle: {
                            "font-size": "0.7rem",
                            "letter-spacing": "none",
                          },
                        },
                        [_vm._v(_vm._s(button.label))]
                      ),
                      _vm._v(" "),
                      button.counter
                        ? _c("span", { staticClass: "qa-button-counter" }, [
                            _vm._v(_vm._s(button.counter)),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }