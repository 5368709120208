<template>
    <v-card class="d-none d-md-flex mt-3 mb-3" xs-12>
        <v-flex v-if="this.isBroken">Sorry, this content is not working</v-flex>

        <v-container fluid class="mobile-no-padding" v-if="this.isReady && !this.isBroken">

            <v-layout row wrap>
                <v-col cols="12">
                    <v-layout row wrap>
                       <v-progress-linear
                          indeterminate
                          color="lime"
                          v-if="loadingData"
                        ></v-progress-linear>

                        <v-col cols="12" v-if="componentReady" id="table-external">

                            <v-flex xs12 class="mb-3" v-if="appliedFilters.length > 0 && showFilters">
                                <v-layout row wrap>
                                    <v-col cols="12">The following filters are applied:</v-col>
                                    <v-col cols="12" 
                                            v-for="(filter, index) in appliedFilters" 
                                            :key="index"
                                    >
                                        {{ index + 1 }}. {{ filter.modelName }}.{{ filter.fieldName }} {{ filter.operator }} {{ filter.value }}
                                    </v-col>
                                </v-layout>
                            </v-flex>

                            <v-data-table
                                :headers        = "tableHeadings"
                                :items          = "tableRows"
                                :item-key       = "tableHeadings[0].value"
                                :loading        = "loadingData"
                                :search         = "search"
                                :options.sync   = "options"
                                :server-items-length = "totalItems"
                                class           = "elevation-1 ies-data-table"
                                v-bind:class    = "pageSize"
                                :custom-sort    = "customSort"
                               
                                hide-default-footer
                                dense
                            >

                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td v-for="header in tableHeadings">
                                            <ies-list-field :value="item[header.value]" 
                                                            :fieldSchema="header"
                                                            :pageRecord="page"
                                                            @click="orderByField(header)"
                                                            headerProp="header"></ies-list-field>
                                        </td>
                                    </tr>
                                </template>
<!--
                                <template v-slot:header="{ props }">
                                    <tr>
                                       <th v-for="header in props.headers" :key="header.text"
                                           class="hidden-sm-and-down text-xs-right column sortable"
                                           v-bind:class="{
                                                        active: header.isSorted,
                                                        asc:    header.order == 'ASC',
                                                        desc:   header.order == 'DESC'
                                                     }"
                                           @click="orderByField(header)"
                                       >
                                           <v-icon>arrow_upward</v-icon>
                                           {{ header.text }}
                                       </th>

                                       <th
                                           class="hidden-md-and-up text-xs-right no-wrap"
                                           @click="orderByField(tableHeadings[0])"
                                       >
                                           <v-icon>arrow_upward</v-icon>
                                           {{ tableHeadings[0].text }}
                                       </th>
                                    </tr>
                                </template>
-->

                            </v-data-table>
                        </v-col>
                    </v-layout>
                </v-col>

                <v-col cols="12" class="mt-4">
                    <v-layout row>
                        <v-col>
                            <strong>Total:</strong> {{ totalItems }} Items
                        </v-col>

                        <v-spacer></v-spacer>

                        <v-col>
                            <v-btn v-on:click="firstPage()"
                                plain
                                class="navigationButton"
                            ><v-icon>first_page</v-icon></v-btn>
                            <v-btn v-on:click="prevPage()"
                                plain
                                class="navigationButton"
                            ><v-icon>navigate_before</v-icon></v-btn>

                            Page {{ pageNumber + 1 }} of {{ totalPages }}

                            <v-btn v-on:click="nextPage()"
                                plain
                                class="navigationButton"
                            ><v-icon>navigate_next</v-icon></v-btn>
                            <v-btn v-on:click="lastPage()"
                                plain
                                class="navigationButton"
                            ><v-icon>last_page</v-icon></v-btn>
                        </v-col>

                        <v-spacer></v-spacer>

                        <v-col>
                            <strong>Rows per Page:</strong>
                            <v-select
                                :items="rowsPerPageOpts"
                                v-model="chosenRowsPerPage"
                                label=""
                                class=""
                                item-value="value"
                                single-line
                            ></v-select>
                        </v-col>
                    </v-layout>
                </v-col>
            </v-layout>

        </v-container>
    </v-card>
</template>


<script>
    // Import the application event bus so we can listen for data changes and react accordingly
    import EventBus from '../../eventBus';
    import axios from 'axios';

    export default{
        /**
         * Data passed into the component from the view itself via attributes on its tag
         */
        props:['datasource', 'reducedHeader', 'hideActionButtons', 'permission', 'page', 'sections', 'pagemetadata', 'fields', 'pagerecord'],

        /**
         * Define all the data variables which Vue needs to bind to the view template directly, or react to automatically to regenerate computed properties
         */
        data: function() {
            return {
                isReady:        false,
                isBroken:       false,
                search:         '',
                pageSections:   {},
                loaded:         false,
                loadingData:    true,
                pageRecord:     {},
                metaData:       {},
                appliedFilters: [],
                //fields:         [],
                data:           [],
                totalDBRecords: 0,
                tableRowData:   [],
                tableHeadData:  [],
                rowsPerPageOpts:[{ 'text': '20', 'value': 20 }, {'text': '50', 'value': 50}, {'text': '100', 'value': 100}, {'text':'All', 'value':'All'}],
                pageNumber:     0,
                chosenRowsPerPage: 20,
                showFilters:    false,
                searchTool:     '',
                listSection:    {},
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            try{
                this.registerEventListeners();
                this.pageRecord = this.page;// is already parsed by parent?

                this.isReady = true;
                console.log("LIST TABLE mounted complete ", this.page);
            }
            catch(exception){
                console.log("List table:", exception);
                this.isBroken = true;
            }
        },

        /**
         * React to changes on any models which need to trigger on-change events.  This is more effective than the v-on:change property
         */
        watch: {
            /**
             * The server side will restrict the maximum records downloaded in a single request so the user won't actually get all of the dataset above that threshold
             * As a result we need to pre-empt this problem and tell the user what is about to happen, and then offer them some helpful advice on how to still get all
             * the data they need
             */
            chosenRowsPerPage: function(newValue, oldValue){
                var shouldLoad = true;

                if (newValue == 'All' && this.totalDBRecords > 500){
                    var message = 'Loading all ' + this.totalDBRecords + ' in one page is not allowed for performance reasons.  If you continue the system will restrict the number of records itself automatically to avoid crashing your webpage.\n\nIf you need to see all the records please choose to download them as a CSV document instead';

                    shouldLoad = confirm(message);
                }

                // If everything is fine then ask the service to reload the dataset
                if (shouldLoad){
                    this.$root.$emit('List: Change rows per page', {'id': 'notset', 'rowsPerPage': this.chosenRowsPerPage});
                }
                // If the user has opted not to resize the dataset after all then reset their select box to the old value again
                else {
                    this.chosenRowsPerPage = oldValue;
                }
            },
        },


        /**
         *
         */
        methods: {
             customSort: function(items, index, isDesc) {
             },

//            customSort(sort, z, ty){
  //              console.log("custom sort", sort, z, ty);
    //        },

            registerEventListeners(){
                var self = this;
                this.$root.$on('List: Loading data', function(payload){
                    self.loadingData = true;
                });

                // A component has asked the list to obtain a batched segment of the paginated results from the API
                this.$root.$on('List: Data updated', function(payload){
                    console.log("List table: I should change the data: " + payload); 
                    self.setData(payload.data);
                });
            },

            setData(payload){
                console.log('List table settign data:', payload);
                this.appliedFilters = payload.filters;
                this.data = payload.data;
                this.pageNumber = payload.pageNumber;
                this.rowsPerPage = payload.pagesPerRow;// Misnamed in response from server
                this.totalDBRecords = payload.totalDBRecords;

                console.log("LIST", this.fields);
                console.log("LIST", this.data);
                console.log("LIST", this);

                this.setTableHeadings();
                this.setTableRows();
                this.loadingData = false;
                this.loaded = true;
            },


            /**
             * Creates the table headings to be used on the view template, and is called whenever new data is loaded
             *
             * @see loadData()
             * @see this.tableHeadData
             * @todo: Remove references to page structure array, as this component is supposed to be a reusable independent item distinct from page design
             */
            setTableHeadings: function(){
                var headers = [];
                // loop through and format the `headers`
                console.log("List Table: setting table headings, page = ", this.pageRecord);
                if (!this.fields){
                    console.log("List Table: i dont have fields!!!!");
                    return;
                }


                console.log("List table: fields heading", this.fields);
                this.fields.forEach(function(header){
                    // The field heading could be either a pre-determined friendly name, or just figured out from the field name itself
                    // Note that special fields such as composites
                    var heading = header;

                    if (header.isComposite){
                        heading.text  = header.displayName;
                        heading.value = header.alias;
                    }
                    else if (header.isAssociation) {
                        heading.text = header.displayName;
                        heading.value = header.fieldName;
                    }
                    else {
                        heading.text  = header.schema.displayName;
                        heading.value = header.schema.fieldName;
                    }

                    if (header.displayName){
                        heading.text  = header.displayName;
                    }
                    if (header.alias){
                        heading.value = header.alias;
                    }

                    // If some form of aggregation is being applied to the field's values then we should
                    // change the display name to report this clearly to the user in natural language
                    if (header.aggregation){
                        switch (header.aggregation){
                            case 'COUNT': heading.text = 'No. of '   + heading.text + 's';  break;
                            case 'SUM'  : heading.text = 'Sum of '   + heading.text + 's';  break;
                            case 'AVG'  : heading.text = 'Average '  + heading.text;        break;
                            case 'MIN'  : heading.text = 'Smallest ' + heading.text;        break;
                            case 'MAX'  : heading.text = 'Largest '  + heading.text;        break;
                        }
                    }

                    if (header.displayType !== 'hidden') {
                        headers.push(heading);
                    }

               });

               this.tableHeadData = headers;
               console.log("LIST", this.tableHeadData);
            },

            /**
             * This function is called whenever new data is loaded, and allows the content of the data table to be established based on the raw data received
             *
             * @see loadData()
             * @see this.tableRowData
             */
            setTableRows: function(){

                var items          = [];
                var headers        = [];
                //var sectionFields  = this.fields ? this.fields : [];
                var primaryKeyName = '';

                // Find the primary key field name for this row
                if (this.data) {

                    this.fields.forEach(function(field){
                        if(field.isPrimaryKey){
                            primaryKeyName = field.fieldName;
                        }
                        headers[field.fieldName] = field;
                    });


                    this.data.forEach(function(item){
                        var dataItem = {};

                        // Loop through the attributes of the row and build up the `dataItem` (items row), matching the key to the `headers` array so they are named correctly
                        // It this this matched naming that allows the ordering and search functionality
                        for (let [key, col] of Object.entries(item)) {
                            var val = col;

                            if(val == null){
                                val = '';
                            }

                            // Add the value to the list of data to be displayed in the view template
                            dataItem[key] = val;

                            // Add the primary key value identifying this row
                            if (primaryKeyName == key){
                                dataItem.primaryKey = val;
                            }
                        }

                        // Add `dataItem` to the full `items` array
                        items.push(dataItem);
                    }.bind(this));
                }

                //
                this.tableRowData = items;



/*
                var items = [];
                var headers        = [];
                var primaryKeyName = '';

                    this.data.fields.forEach(function(field){
                        if(field.isPrimaryKey){
                            primaryKeyName = field.fieldName;
                        }
                        headers[field.fieldName] = field;
                    });

                    this.data.data.forEach(function(item){
                        var dataItem = {};

                        // Loop through the attributes of the row and build up the `dataItem` (items row), matching the key to the `headers` array so they are named correctly
                        // It this this matched naming that allows the ordering and search functionality
                        for (let [key, col] of Object.entries(item)) {
                            var val = col;

                            if(val == null){
                                val = '';
                            }

                            // Add the value to the list of data to be displayed in the view template
                            dataItem[key] = val;

                            // Add the primary key value identifying this row
                            if (primaryKeyName == key){
                                dataItem.primaryKey = val;
                            }
                        }

                        // Add `dataItem` to the full `items` array
                        items.push(dataItem);
                    }.bind(this));

                this.tableRowData = items;
*/
                console.log("LIST: table row data: ", this.tableRowData);
            },

            /**
             * Set which search tool was used to get the data
             */
            setSearchTool(tool) {
                if (tool === 'quickSearch') {
                    this.showFilters = false;
                    this.searchTool = 'quickSearch';
                }
                else if (tool === 'advancedSearch') {
                    this.showFilters = true;
                    this.searchTool = 'advancedSearch';
                }
            },

           /**
            * Navigates the browser to the edit page for the specified record
            */
           editRecord: function(ID){
               this.$root.$emit('List: Edit record', {'id': 'notset', 'recordID': ID});
           },

            /**
             * Returns the number of pages for a list
             * @return - int
             */
            pages: function() {
                return Math.ceil(this.totalItems / this.chosenRowsPerPage) - 1
            },

            /**
             * Requests the page service change the page index number in the pagination of the data set for us
             */
            changePage: function(){
                this.$root.$emit('List: Change page', {'id': 'notset', 'pageNumber': this.pageNumber});
            },

            /**
             * @param array newOrdering array of models and fields to order by, and in which direction
             */
            setOrdering: function(newOrdering){
                console.log('LIST, setOrdering', newOrdering);
                this.$root.$emit('List: Set sorting', {'id': 'notset', 'orderedFields': newOrdering });
            },


            /**
             * Navigate to the first page
             */
            firstPage: function(){
                this.pageNumber = 0;
                this.changePage();
            },

            /**
             * Navigate to the page before this one
             */
            prevPage: function(){
                if (this.pageNumber >= 1) {
                    this.pageNumber--;
                    this.changePage();
                }
            },

            /**
             * Navigate to the next page
             */
            nextPage: function(){
                if (this.pageNumber < this.pages()) {
                    this.pageNumber++;
                    this.changePage();
                }
            },

            /**
             * Navigate to the last page
             */
            lastPage: function(){
                this.pageNumber = this.pages();
                this.changePage();
            },

            /**
             * Handles click events for users clicking the table field head cells, and changes the sort ordering on the dataset accordingly.  While the back-end is
             * capable of sorting by any number of fields, including those not present in the view, the UX of the table headings makes it capable only of handling
             * a single column at a time
             *
             * @param {} field  - The field to order by
             */
            orderByField(field, x, y) {
                console.log("LIST order by field", field, x, y);

                var newOrdering = [{ 'modelName': 'lorem', 'fieldName': 'ipsum', 'direction': 'ASC' }];
                this.setOrdering(newOrdering);

/*
return;

                // Unset the others, since the way the table headings are generated means they don't get wiped or replaced
                for (var index = 0; index < this.tableHeadData.length; index++){
                    delete(this.tableHeadData[index].isSorted);
                    delete(this.tableHeadData[0].order);
                }
console.log("orderby 2");
                // The one complexity is that if a user clicks on a field that's already being sorted then we reverse the sort order on that field, whereas if they click
                // on a different field then we must replace all existing ordering with the new field in ascending order
                // If there is already a sort order being applied check if it is the one requested.  If there are multiple sort orders then replace the lot with this
                // new one because the view isn't capable of handling complex UI well, and the UX would be very poor if we tried
                if (this.data.sortOrders && this.data.sortOrders.length == 1){
                    var firstSort = this.data.sortOrders[0];

                    // If the first one is the same field then toggle its direction
                    if ((field.isComposite && firstSort.isComposite && field.alias == firstSort.alias)
                        || (field.modelName == firstSort.modelName && field.fieldName == firstSort.fieldName)) {
                        field.order = !firstSort.order || firstSort.order == 'DESC' ? 'ASC' : 'DESC';
                    }
                    // Under all other circumstances just default to ascending order
                    else {
                        field.order = 'ASC';
                    }
                }
                // If no sort order is already applied then just default to ascending order
                else {
                    field.order = 'ASC';
                }
                field.isSorted  = true;

                // Replace the entire sort ordering with just the one field - the one specified by the user, in the direction we have established above
                var newSortOrder = [field];
//                var queryURL = '/ajax/' + this.pageRecord.page_name + '/list/filter';
//                window.app.pageService.sortDataset(this.datasource, newSortOrder, queryURL);
*/
console.log("LIST done like");
            },


        },


        /**
         * Define live data bindings so that the view template will automatically update when Vue detects that the dependencies within the functions defining them below
         * change.  All data displayed on the template should be defined as a computed property, otherwise it will never change in future when the data variable it is based
         * on does.
         */
       computed: {
            /**
             * Method gets the page size from page record and decides what class should be applied
             * @return string - class that will be applied to vue table
             */
            pageSize: function() {
                console.log("LIST page size computed");
                var size = this.pageRecord.page_size;
                var classUses = '';

                if (size) {
                    switch (size) {
                        case 1 : classUses = 'pageSizeLarge';   break;
                        case 2 : classUses = 'pageSizeMedium';  break;
                        case 3 : classUses = 'pageSizeSmall';   break;
                        default : classUses = '';
                    }
                }

                return classUses;
            },

            /**
             * Establishes how the results are displayed within the v-data-table component itself.  This is independent of any breakdown in the results that the server did
             * before passing the results array to this component
             */
            options: {
                get: function(){
                    var pagination = {};
                    pagination.rowsPerPage = this.rowsPerPage    ? this.rowsPerPage     : 20;
                    pagination.page        = this.pageNumber + 1;
                    pagination.descending  = this.sortDescending ? this.sortDescending  : 0;
                    //pagination.sortBy      = this.sortColumn;

                    console.log('LIST pagination', pagination);
                    return pagination;
                },
                set: function(dataTableOptions){
                    console.log("LIST set options func called", dataTableOptions);
       
                    // Receives the data table options object, which means we only have that data to 
                    // interrogate to find which field was sorted.  This is unhelpful as it is so crude
                    // we have only the field heading and direction of a single column to work with
                    // so in order to pass this on to the search we need to look up which model field
                    // we think v-data-table was actually referring to by cross referencing field names
                    var newOrdering = [];
                    this.fields.forEach(function(field){
                        // The field name will be in an array of strings, 1 element long
                        // so we compare the string against the field names for each field
                        if (field.fieldName == dataTableOptions.sortBy[0]){
                            console.log("LIST", field);
                            newOrdering.push( { 'modelName': field.modelName, 'fieldName': field.fieldName, 'order': (dataTableOptions.sortDesc[0] ? 'DESC' : 'ASC') } );
                        }
                    });
                    this.setOrdering(newOrdering);
                }
            },

            /**
             * Recalculates the number of pages required to list every record using the current settings
             *
             * @return int
             */
            totalPages: function(){
                if (this.chosenRowsPerPage == 'All'){
                    return 1
                }
                console.log("LIST totl pages", this.totalItems, this.chosenRowsPerPage);
                return Math.ceil(this.totalItems / this.chosenRowsPerPage);
            },

            /**
             * Returns an array of filter conditions being used to limit the dataset to a smaller subset of records than the page's default
             *
             * @return []
             */
            filtersApplied: function(){
                console.log("LIST filters : ", this.appliedFilters);
                return this.appliedFilters;
            },

            /**
             * Returns an array of fields which are being used for sort-ordering in the present dataset
             *
             * @return []
             */
            sortingApplied: function(){
                console.log('LIST sorting', this.data.sortOrders);
                return this.data.sortOrders;
            },

            /**
             * Computes the live bindings for the table headings so they are up-to date
             *
             * @see this.tableHeadData
             * @return []
             */
            tableHeadings: function(){
                console.log("LIST table headings", this.tableHeadData);
                return this.tableHeadData;
            },

            /**
             * Computes the table data to pass into the v-data-table component as a live-binding
             *
             * @see this.tableRowData
             * @return []
             */
            tableRows: function(){
                console.log("LIST: table rows", this.tableRowData);
                return this.tableRowData ? this.tableRowData : [];
            },

            /**
             * Provides the number of records in the database for the query behind the data the user is viewing, regardless of whether or not the user is
             * viewing all of them
             *
             * @return int
             */
            totalItems: function(){
                console.log("LIST: totalItems", this.totalDBRecords);
                return this.totalDBRecords ? this.totalDBRecords : 0;
            },

            /**
             * Shows the view template whether or not the component has finished loading data
             * @return Boolean
             */
            componentReady: function(){
                return this.loaded;
            },

        }
    }

</script>

<style scoped>

    .ies-actions td:first-child{
        padding:0px;
    }

    #table-external {
        overflow-y: hidden;
        overflow-x: hidden;
        white-space: nowrap;
    }

    .table__overflow {
        overflow-x:scroll;
    }

    .ies-data-table .v-data-table__wrapper > table > tbody > tr > td {
        font-size: 0.75rem;
    }

    .navigationButton {
        padding: 0 !important;
        min-width: auto !important;
    }

    .list-btn {
        max-width: 200px;
    }

    .list-btn-td {
        width: 10%;
    }

    /* page size Medium styles: */

    .pageSizeMedium .table .container{
        padding: 0;
    }

    /* page size Small styles: */

    .pageSizeSmall td{
        font-size: 11px!important;
    }

    .pageSizeSmall .table .container{
        padding: 0;
    }

    .pageSizeSmall .table .no-wrap{
        padding: 5px;
    }


</style>
