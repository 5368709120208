<template>
    <v-container fluid grid-list-md>
        <v-layout row wrap>
            <input type="hidden" :name="field.fieldName" :value="field.value">

            <v-flex xs12 sm4>
                <!-- list must be list of ['text' => x, 'value' => y] -->
                <v-select
                    v-model="regionToAdd"
                    :items="regionOptions"
                    autocomplete
                ></v-select>
            </v-flex>

            <v-flex xs12 sm2>
                <v-btn class="ies-green"
                    v-on:click="pinBlogPostToRegion"
                >
                    Add
                </v-btn>
            </v-flex>

            <v-alert type="error" :value="field.errors" style="width: 100%">
                <li v-for="error in field.errors" v-bind:key="error">
                    {{ error }}
                </li>
            </v-alert>
        </v-flex>

        
        <v-flex xs12 v-for="blogRegion in blogRegions">
            <v-container fluid grid-list-md>
                <v-layout row wrap>
                    <v-flex xs12 sm3>{{ blogRegion.region_name }}</v-flex>
                    <v-flex xs12 sm2>
                        <v-btn v-on:click="unpinBlogPostFromRegion(blogRegion.region_key)">
                            <v-icon>delete</v-icon>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-flex>

        </v-layout>
    </v-container>
</template>

<script>
    /**
     * Allows the selection of zero or more regions for this blog record to be
     * assigned to, so that the blog can be advertised to clients visiting the
     * relevant regions' pages
     *
     * A blog can be pinned to multiple regions.  Blogs pinned tor no regions
     * are considered to be unrestricted and therefore show for any search page
     */
    export default {
        name: 'blogRegions',
        props: ['field', 'recordData'],
        data() {
            return {
                blogKey: 0,
                allRegions: {},
                regionOptions: [],
                regionToAdd: null,
                blogRegions: [],
                viewValues: [],
            }
        },

        created:function() {
            // This component exists to link a search page blog entry
            // record to n region records using their primary keys, therefore
            // we must be provided with the blog post's ID number via the 
            // recordData prop which publishes the data about this page record overall
            this.blogKey = this.recordData.search_page_blog_entry_key;

            // Having completed set up of the component itself we can now obtain a copy of the
            // editorial regions and subsequently complete set up of the data models too
            this.getRegionOptions();
        },

        methods: {
            /**
             * Downloads a copy of all regions from the server API so that it
             * can use this master list as a point of reference for describing
             * which regions the blog is pinned to, and presenting options to pin
             * the blog to more
             */
            getRegionOptions:function(){
                axios.get('/api/regions')
                .then(function(response){
                    this.regionOptions = [];
                    this.addRegionsToOptions(response.data);
                    this.getBlogRegions();
                }.bind(this));
            },

            /**
             * Iterative function which will append the regions to the list
             * of all regions, and the view model of them which the user can
             * select new regions by, and then calls itself again for any 
             * subregions which it owns
             */
            addRegionsToOptions:function(regions)
            {
                var self = this;
                regions.forEach(function(region){
                    self.allRegions[region.region_key] = region;
                    self.regionOptions.push({ text: region.region_name, value: region.region_key });

                    if (region.child_region && region.child_region.length){
                        self.addRegionsToOptions(region.child_region);
                    }
                });
            },

            /**
             * Downloads a list of regions to which the blog post is currently
             * pinned in the database
             */
            getBlogRegions:function(){
                axios.get('/api/blog-regions?blogKey=' + this.blogKey)
                .then(function(response){
                    var blogRegions = [];
                    var self = this;

                    response.data.forEach(function(pinnedRegion){
                        blogRegions.push(self.allRegions[pinnedRegion.region_key]);
                    });
                    this.blogRegions = blogRegions;
                    this.updateFieldValue();
                }.bind(this));
            },

            /**
             * Pins the blog post to the given region by adding it to the list
              * of regions for which links should be saved on form submission
             */
            pinBlogPostToRegion:function(){
                var self = this;
                var alreadySet = false;
                this.blogRegions.forEach(function(region){
                    if (region.region_key == self.regionToAdd){
                       alreadySet = true;
                       return;
                    }
                });
                
                if(alreadySet){
                    return;
                }

                self.blogRegions.push(self.allRegions[self.regionToAdd]);
                this.updateFieldValue();
            },

            /**
             * Removes the region with the given region ID from the list of regions
             * to which the blog post being edited is to be pinned
             */
            unpinBlogPostFromRegion:function(regionToRemove){
                for (var index = 0; index < this.blogRegions.length; index++){
                    if(this.blogRegions[index].region_key == regionToRemove){
                        this.blogRegions.splice(index, 1);
                    }
                }
                this.updateFieldValue();
            },

            /**
             * The field is not a single value correlating to a database field
             * in the record, but nevertheless we can try to reuse that functionality
             * to pass the data through the form submission.  This will allow the
             * server to receive all the data and understand that it is a custom field
             */
            updateFieldValue:function(){
                var newValues = [];
                this.blogRegions.forEach(function(region){
                    newValues.push(region.region_key);
                });
                this.field.value = newValues;
            }
        }

    }
</script>
