<template>
<div>
    <v-expansion-panel>
        <v-expansion-panel-content v-for="(title, region) in batchReport" :key="title">

            <!-- <div slot="header">
                {{ title }}
            </div> -->

            <!-- <v-card v-for="(category, title) in type" :key="title"> -->
                <!-- <v-card-title>
                    <span class="heading">{{ title }}</span>
                </v-card-title> -->
                <v-data-table
                    :headers="headers()"
                    :items="region"
                >
                    <template slot="items" slot-scope="props">
                        <td class="text-xs-center">{{ props.item.ContactName }}</td>
                        <td class="text-xs-center">{{ props.item.InvoiceNumber }}</td>
                        <td class="text-xs-center">{{ props.item.TransactionDate }}</td>
                        <td class="text-xs-center">{{ props.item.IssuedDate }}</td>
                        <td class="text-xs-center">{{ props.item.DueDate }}</td>
                        <td class="text-xs-center">{{ props.item.IssuedDate }}</td>
                        <td class="text-xs-center">{{ props.item.DueDate }}</td>
                        <td class="text-xs-center">{{ props.item.Description }}</td>
                        <td class="text-xs-center">{{ props.item.Quantity }}</td>
                        <td class="text-xs-center">{{ props.item.NetAmount }}</td>
                        <td class="text-xs-center">{{ props.item.GrossAmount }}</td>
                        <td class="text-xs-center">{{ props.item.AccountCode }}</td>
                        <td class="text-xs-center">{{ props.item.TaxType }}</td>
                    </template>
                </v-data-table>
            <!-- </v-card> -->
            
        </v-expansion-panel-content>
  </v-expansion-panel>
</div>
</template>

<script>
export default {
    data() {
        return {
            batchReport: []
        }
    },
    created() {
        this.getBatchReport();
    },
    methods: {
        async getBatchReport() {
            const res  = await axios('api/accounts/batch-report')
            this.batchReport = res.data
        },
        headers() {
            return [
                {
                    text: 'Contact Name',
                    align: 'center',
                    value: 'ContactName'
                },
                 {
                    text: 'Invoice Number',
                    align: 'center',
                    value: 'InvoiceNumber'
                },
                {
                    text: 'Transaction Date',
                    align: 'center',
                    value: 'TransactionDate'
                },
                {
                    text: 'Issued Date',
                    align: 'center',
                    value: 'IssuedDate'
                },
                {
                    text:  'Description',
                    align: 'center',
                    value: 'Description'
                },
                {
                    text:  'Quantity',
                    align: 'center',
                    value: 'Quantity'
                },
                {
                    text: 'Net Amount',
                    align: 'center',
                    value: 'NetAmount'
                },
                 {
                    text: 'Gross Amount',
                    align: 'center',
                    value: 'GrossAmount'
                },
                 {
                    text: 'AccountCode',
                    align: 'center',
                    value: 'AccountCode'
                }, 
                {
                    text: 'Tax Type',
                    align: 'center',
                    value: 'TaxType'
                }
            ]
        }
    }
}
</script>