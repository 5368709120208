<template>
    <v-col cols="12" md="3">
    <v-card class="ma-1">
        <v-card-title class="title font-weight-black">
            <v-icon class=pr-3>format_list_numbered</v-icon> Lists
        </v-card-title>

        <v-card-text>
            <v-btn block disabled href="">Pop ups</v-btn>
            <v-btn block disabled href="">URLs</v-btn>
            <v-btn block disabled href="">Info tags</v-btn>
            <v-btn block href="/newsletter-subscribers/list">Mailing list</v-btn>
            <v-btn block disabled href="">Import Mailing list</v-btn>
            <v-btn block href="/competition-entrants/list">Competition Entrants</v-btn>
            <v-btn block href="/messagings/list">Messaging</v-btn>
            <v-btn block href="/facilities/list">Facility Management</v-btn>
        </v-card-text>
    </v-card>
    </v-col>
</template>

<script>
    /**
     * Display buttons linking through to hotels' pages
     */
    export default {
        props:[],

        data: function() {
            return {
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){},

        methods: {},

        computed: {}
    }
</script>
