var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "connectionWrapper", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", "justify-space-between": "" } },
        [
          _c("v-flex", { attrs: { xs12: "", md1: "" } }, [
            _vm._v("\n            " + _vm._s(_vm.sort) + ".\n        "),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { xs12: "", md3: "" } }, [
            _vm._v(
              "\n            Room: " +
                _vm._s(_vm.connection.hotelRoomName) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("v-flex", { attrs: { xs12: "", md3: "" } }, [
            _vm._v(
              "\n            Last updated at: " +
                _vm._s(_vm.lastUpdated) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "", md2: "" } },
            [
              _c("v-switch", {
                attrs: { label: `Active: ${_vm.activeString}` },
                on: { change: _vm.activeChanged },
                model: {
                  value: _vm.active,
                  callback: function ($$v) {
                    _vm.active = $$v
                  },
                  expression: "active",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { staticClass: "actionIcons", attrs: { xs1: "" } },
            [
              _c(
                "v-icon",
                {
                  attrs: { color: "green" },
                  on: {
                    click: function ($event) {
                      return _vm.showDetails()
                    },
                  },
                },
                [_vm._v("\n                description\n            ")]
              ),
              _vm._v(" "),
              _c(
                "v-icon",
                {
                  attrs: { color: "red" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteConnection()
                    },
                  },
                },
                [_vm._v("\n                delete\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("iCal Connection details"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _vm._v(
                          "\n                        ical_connection_key: " +
                            _vm._s(_vm.connection.ical_connection_key)
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                        Url: " +
                            _vm._s(_vm.connection.url) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.connection.notes
                        ? _c("v-flex", { attrs: { xs12: "" } }, [
                            _vm._v(
                              "\n                        Notes: " +
                                _vm._s(_vm.connection.notes) +
                                "\n                    "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        outline: "",
                        color: "green",
                        disabled: _vm.connectionBusy,
                        loading: _vm.connectionBusy,
                      },
                      on: { click: _vm.updateSingleConnection },
                    },
                    [_vm._v("Update this connection")]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", flat: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }