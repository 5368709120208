<template>
    <v-container fluid grid-list-md>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                     <v-card-text>
                         <v-container fluid v-if="reservation.show || 1">
                              <v-layout row wrap>
                                  <v-flex xs12>
                                       You have received a new reservation enquiry.  Please respond as soon as possible
                                  </v-flex>
                                  
                                  <v-flex xs12>
                                      <v-btn class="ies-green" v-on:click="chooseOfferQuote">Offer Quote</v-btn>
                                      <v-btn class="ies-green" v-on:click="chooseAlternativeDates">Alternative Dates</v-btn>
                                      <v-btn class="ies-green" v-on:click="chooseAlternativeRooms">Alternative Rooms</v-btn>
                                      <v-btn class="ies-coral" v-on:click="chooseNoAvailability">No Availability</v-btn>
                                  </v-flex>

                                  <v-flex xs12 v-if="option == 'offerQuote'">
                                      <ies-reservation-administration-offer-quote :reservationkey="reservationkey"></ies-reservation-administration-offer-quote>
                                  </v-flex>
                                  <v-flex xs12 v-if="option == 'alternativeDates'">
                                      <ies-reservation-administration-alternative-dates :reservationkey="reservationkey"></ies-reservation-administration-alternative-dates>
                                  </v-flex>
                                  <v-flex xs12 v-if="option == 'alternativeRooms'">
                                      <ies-reservation-administration-alternative-rooms :reservationkey="reservationkey"></ies-reservation-administration-alternative-rooms>
                                  </v-flex>
                                  <v-flex xs12 v-if="option == 'noAvailability'">
                                      <ies-reservation-administration-no-availability :reservationkey="reservationkey"></ies-reservation-administration-no-availability>
                                  </v-flex>
                              </v-layout>
                          </v-container>
                      </v-card-text>

                 </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    /**
     * A Vue component dedicated to letting the user manage a reservations
     * by the ID specified
     *
     * Props:
     *     int 'reservationkey' - The ID of the reservation to display
     */

    import axios from 'axios';
    export default {
        name: 'hotel-reservation-enquiry',
        props: ['reservationkey'],
        data () {
            return {
                reservation: {},
                option: '',
            }
        },
        methods: {

            loadReservation(){
return;
                axios.get('/api/reservation/' + this.reservationkey + '/administration')
                .then(function(response){
                    this.reservation = response.data;
                }.bind(this));
            },

            chooseOfferQuote(){
                this.option = 'offerQuote';
            },

            chooseAlternativeRooms(){
                this.option = 'alternativeRooms';
            },

            chooseAlternativeDates(){
                this.option = 'alternativeDates';
            },

            chooseNoAvailability(){
                this.option = 'noAvailability';
            }
        },

        /**
         * Once mounted this component needs to load the reservation specified
         */
        mounted() {
            this.loadReservation();
        },

        computed: {}
    }
</script>
