<template>
    <v-container fluid>
       <v-layout row-sm>
            <v-flex xs12 sm1>
                 <label>{{ this.field.schema.displayName }}:</label>
            </v-flex>
            <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            <v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            <v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>
            <v-flex xs12 sm11>
                <input type="hidden" :name="field.fieldName" :value="field.value">

                <label for="monday">Monday:</label>
                <input name="monday" type="checkbox" v-model="week.monday">
                <label for="tuesday">Tuesday:</label>
                <input name="tuesday" type="checkbox" v-model="week.tuesday">
                <label for="wednesday">Wednesday:</label>
                <input name="wednesday" type="checkbox" v-model="week.wednesday">
                <label for="thursday">Thursday:</label>
                <input name="thursday" type="checkbox" v-model="week.thursday">
                <label for="friday">Friday:</label>
                <input name="friday" type="checkbox" v-model="week.friday">
                <label for="saturday">Saturday:</label>
                <input name="saturday" type="checkbox" v-model="week.saturday">
                <label for="sunday">Sunday:</label>
                <input name="sunday" type="checkbox" v-model="week.sunday">

                <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
                    <li v-for="error in field.errors">
                        {{ error }}
                    </li>
                </v-alert>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    /**
     * Allows the user to select days of the week for which the given
     * field will represent, as used for example when entering the 
     * checkin/out restrictions' days for hotels' rate types.
     *
     * The database stores these values as integers/tinyintegers
     * with each day represented as a bolean value by a bit, but the
     * user expects to interact with them using checkboxes to toggle
     * each day separately, meaning we will display 7 checkboxes per
     * field.  We will use view models to abstract the data from the 
     * data model it is representing as it will make it cleaner and
     * safer to modify the UI, provided we keep the two models in 
     * synchronisation
     *
     * Note that the days of the week in i-escape's system correlate 
     * to the days of the business week, not the common week, so all
     * weeks begin on Monday
     */
    export default {
        name: 'ies-dayOfWeek',
        props: ['field'],
        data() {
            return {
                /** @var object week - A view model representing the days of the week and a boolean flag for each - this will be used to power the UI easier than the data model */
                week: {'monday': false, 'tuesday': false, 'wednesday':false, 'thursday':false, 'friday':false, 'saturday':false, 'sunday':false},
            }
        },
        methods: {
            /**
             * Update the view models so that the view model data 
             * reflects the underlying data model for the field 
             * which will actually be saved to the database later
             */
            updateViewFromModel: function(){
                this.week.monday    = this.field.value & 1;
                this.week.tuesday   = this.field.value & 2;
                this.week.wednesday = this.field.value & 4;
                this.week.thursday  = this.field.value & 8;
                this.week.friday    = this.field.value & 16;
                this.week.saturday  = this.field.value & 32;
                this.week.sunday    = this.field.value & 64;
            },

            /**
             * Update the data model for the field to match the data
             * represented in the view model which powers the user 
             * interface so that it matches what the user has entered
             */
            updateModelFromView: function(newValue){
                this.field.value = (this.week.monday)   ? this.field.value |= 1  : this.field.value & ~1;
                this.field.value = (this.week.tuesday)  ? this.field.value |= 2  : this.field.value & ~2;
                this.field.value = (this.week.wednesday)? this.field.value |= 4  : this.field.value & ~4;
                this.field.value = (this.week.thursday) ? this.field.value |= 8  : this.field.value & ~8;
                this.field.value = (this.week.friday)   ? this.field.value |= 16 : this.field.value & ~16;
                this.field.value = (this.week.saturday) ? this.field.value |= 32 : this.field.value & ~32;
                this.field.value = (this.week.sunday)   ? this.field.value |= 64 : this.field.value & ~64;
            }
        },

        created:function() {
            this.updateViewFromModel();
        },

        /**
         * In the event that the user changes the view model's data then
         * we need to trigger a corresponding change to the data model too
         */
        watch: {
            'week.monday':    function(){ this.updateModelFromView(); },
            'week.tuesday':   function(){ this.updateModelFromView(); },
            'week.wednesday': function(){ this.updateModelFromView(); },
            'week.thursday':  function(){ this.updateModelFromView(); },
            'week.friday':    function(){ this.updateModelFromView(); },
            'week.saturday':  function(){ this.updateModelFromView(); },
            'week.sunday':    function(){ this.updateModelFromView(); }
        }
    }
</script>
