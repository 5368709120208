var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-flex",
    { attrs: { xs12: "", sm12: "", md2: "", lg2: "", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", [
            _c("a", { attrs: { href: "/myescapes-members/list" } }, [
              _c(
                "div",
                { staticClass: "stats-icon" },
                [
                  _c("v-icon", { staticStyle: { "font-size": "3rem" } }, [
                    _vm._v("person_add"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "stats-text" }, [
                _c("div", { staticClass: "stats-figure" }, [
                  _vm._v(_vm._s(_vm.stats)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "stats-descriptor" }, [
                  _vm._v("New Memberships"),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }