<template>
    <v-container fluid>
        <v-layout row >

            <v-flex xs12 sm8>
                <label>
                    Permission Type:
                </label>
            </v-flex>

            <v-flex>
                <v-layout column>
                    <v-flex xs12 sm4>
                        <select  class="full-width"
                            v-model="mainObject.portal_content_type_key">
                            <option value="">No Restrictions</option>
                            <option :value="column.value" v-for="(column, colKey) in permissions" > {{ column.text }} </option>
                        </select>
                    </v-flex>

                 </v-layout>
             </v-flex>

        </v-layout>
    </v-container>

</template>

<script>
/**
 * Defines the level of permissions required by the user to be able to view or edit this section of the page
 */
export default {
    name: 'PortalDesignerPermissions',
    // Object object: The model to display
    // [] permissions: Array of PortalContentType records for the user to choose between
    props: ['object', 'permissions'],
    data () {
        return {
            mainObject: this.object
        }
    },
    methods: {}
}
</script>
