/**
 * How to use it:
 * 1. Add that component to parent component
 *     a) import BarChart from '@/components/charts/BarChart';
 *     b) components: {
 *           BarChart,
 *       },
 *
 * 2. display on page:  <bar-chart :chartdata="chartData" :options="chartOptions"/>
 */
import { Bar } from 'vue-chartjs'

export default {
    extends: Bar,
    props: {
        chartdata: {
            type: Object,
            default: null
        },
        options: {
            type: Object,
            default: null
        }
    },
    methods: {
        /**
         * make sure that chartdata is provided, if not do not render this chart
         */
        tryToRender() {
            if (this.chartdata && this.chartdata !== {}) {
                this.renderChart(this.chartdata, this.options);
            }
        },
    },
    mounted () {
        // when this component is mounted try to render this chart with chartdata provided
        this.tryToRender();
    },
    watch: {
        // watch for any chartadata change, if it was change, re-render chart
        chartdata: function() {
            this.tryToRender();
        }
    }
}