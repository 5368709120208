<template>
    <v-container fluid>
       <v-layout row-sm column>
            <v-col v-if="this.field.schema.displayName != 'none'" cols="12">
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-col>

            <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            <v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            <v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>
			<v-col>
            	<v-row>
					<v-col md="12" class="d-flex">
						<input type="hidden" :name="field.fieldName" :value="field.value">
							<v-row>
								<v-col cols="12" id="best_for_kids">
									<label for="babies">Babies (0-1 years) :</label>
									<input name="babies" type="checkbox" v-model="bestFor.babies">
									<label for="toddlers">Toddlers (1-4 years):</label>
									<input name="toddlers" type="checkbox" v-model="bestFor.toddlers">
									<label for="children">Children (4-12 years) :</label>
									<input name="children" type="checkbox" v-model="bestFor.children">
									<label for="teenagers">Teenagers (12-18 years):</label>
									<input name="teenagers" type="checkbox" v-model="bestFor.teenagers">
								</v-col>
							</v-row>
					</v-col>
					<v-col>
						<v-alert type="error" dismissable :value="true" v-if="errorMessages && errorMessages.length">
               				<div v-for="(error, index) in errorMessages" :key="index" >{{ error.reason }}*</div>
            			</v-alert>
					</v-col>
            	</v-row>
			</v-col>
         </v-layout>
    </v-container>
</template>

<style>
#best_for_kids input {
	margin-right: 2em;
}
#best_for_kids label {
	font-weight: normal;
}

</style>

<script>
export default {
    name: 'ies-input-best-for-kids',
    props: ['field'],
    data () {
        return {
			showValidationGuide: false,
			errorMessages: [],
            bestFor: { 
				'babies': false, 
				'toddlers': false, 
				'children': false,
				'teenagers': false,
			},
        }
    },
    methods: {
		updateViewFromModel: function(){
			this.bestFor.babies 	= this.field.value & 1;
			this.bestFor.toddlers	= this.field.value & 2;
			this.bestFor.children 	= this.field.value & 4;
			this.bestFor.teenagers 	= this.field.value & 8;
		},

		updateModelFromView: function(newValue) {
			this.field.value = (this.bestFor.babies)	? this.field.value |= 1    : this.field.value & ~1;
			this.field.value = (this.bestFor.toddlers)	? this.field.value |= 2    : this.field.value & ~2;
			this.field.value = (this.bestFor.children)	? this.field.value |= 4    : this.field.value & ~4;
			this.field.value = (this.bestFor.teenagers)	? this.field.value |= 8    : this.field.value & ~8;
		}
	},
	
	created:function() {
		this.updateViewFromModel();
	},

	watch: {
		'bestFor.babies':		function(){ this.updateModelFromView(); },
		'bestFor.toddlers':		function(){ this.updateModelFromView(); },
		'bestFor.children':		function(){ this.updateModelFromView(); },
		'bestFor.teenagers':	function(){ this.updateModelFromView(); },
	}	
}
</script>
