<template>
<v-card>
    <v-card-title>
        <span class="subheader">Global Summary</span>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="continents"
        item-key="region_key"
        hide-default-footer
    >
   
        <template v-slot:item="props">
            <tr @click="props.expanded = !props.expanded">
                <td class="text-xs-left">{{ props.item.region_name }}</td>
                <td class="text-xs-center">£{{ props.item.invoices.toFixed(2) }}</td>
                <td class="text-xs-center">£{{ props.item.payments.toFixed(2) }}</td>
                <td class="text-xs-center">£{{ props.item.balance.toFixed(2) }}</td>
            </tr>
        </template>
      
        <template slot="expand" slot-scope="props">
            <region :regionKey="props.item.region_key" />
        </template>

    </v-data-table> 
</v-card>
</template>

<script>

export default {
    name: 'region',
    props: ['regionKey'],
    data() {
        return {
           continents: [],
           headers: [],
        }
    },
    created() {
        this.headers = this.getHeaders();
        this.getData();
    },
    methods: {
        async getData() {
            const res = await axios({
                method: 'GET',
                url: 'api/accounts/continent-summary',
                params: {
                    region_key: this.regionKey
                }
            });
            this.continents = res.data;
        },
        getHeaders() {
            return [
                {
                    text: 'Region Name',
                    value: 'region_name',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'Invoices',
                    value: 'invoices',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Payments',
                    value: 'payments',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Balance',
                    value: 'balance',
                    align: 'center',
                    sortable: false,
                }
            ];
        }
    }
}
</script>
