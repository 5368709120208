<template>
    <v-container fluid class="hidden">
       <v-layout row-sm column>
            <v-flex xs12>
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-flex>
            <v-flex xs12 sm6>
				<input type="hidden" :value="field.value" :name="field.fieldName">
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'ies-preset-true',
    props: ['field'],
    data () {
        return {
        }
    },
	created: function() {
		this.field.value = true;
	}
}
</script>

