var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Secure Hosting")]),
      _vm._v(" "),
      _c(
        "v-expansion-panel",
        [
          _c(
            "v-expansion-panel-content",
            { attrs: { value: 1 } },
            [
              _c("template", { slot: "header" }, [
                _vm._v(
                  "\n                Month Picker " +
                    _vm._s(_vm.date) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-layout",
                { attrs: { "justify-center": "", "ma-4": "" } },
                [
                  _c("v-date-picker", {
                    attrs: { color: "lime", landscape: true, type: "month" },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-layout",
                { attrs: { "justify-center": "", "ma-2": "" } },
                [
                  _c("v-switch", {
                    attrs: {
                      label: `Group by day ${_vm.groupByDay.toString()}`,
                    },
                    model: {
                      value: _vm.groupByDay,
                      callback: function ($$v) {
                        _vm.groupByDay = $$v
                      },
                      expression: "groupByDay",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-layout",
                { attrs: { "justify-center": "", "ma-2": "" } },
                [
                  _c("v-btn", { on: { click: _vm.submit } }, [
                    _vm._v("Submit"),
                  ]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-expansion-panel",
        [
          _c(
            "v-expansion-panel-content",
            [
              _c("template", { slot: "header" }, [
                _c("span", { staticClass: "subheader text-xs-left" }, [
                  _vm._v("Vouchers Purchased"),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "subheader text-xs-right" }, [
                  _vm._v(
                    "Count: " +
                      _vm._s(_vm.vouchers.count) +
                      " | Total Value: " +
                      _vm._s(_vm._f("pounds")(_vm.vouchers.total_value)) +
                      " | Total Charged: " +
                      _vm._s(_vm._f("pounds")(_vm.vouchers.total_transaction))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.voucherHeaders(),
                  items: _vm.vouchers.data,
                  "hide-default-footer": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.creation_date)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.voucher_type)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.delivery_type)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            _vm._s(_vm._f("pounds")(props.item.voucher_value))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("pounds")(props.item.transaction_value)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-expansion-panel",
        [
          _c(
            "v-expansion-panel-content",
            { attrs: { value: 1 } },
            [
              _c("template", { slot: "header" }, [
                _c("span", { staticClass: "subheader text-xs-left" }, [
                  _vm._v(
                    "\n                    Secure Hosting\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "subheader text-xs-right" }, [
                  _vm._v(
                    "\n                    Count: " +
                      _vm._s(_vm.secureHosting.count) +
                      " | \n                    Total Invoiced: " +
                      _vm._s(
                        _vm._f("pounds")(_vm.secureHosting.total_invoiced)
                      ) +
                      " |\n                    Total Deposit: " +
                      _vm._s(
                        _vm._f("pounds")(_vm.secureHosting.total_deposit)
                      ) +
                      " |\n                    Total Commission: " +
                      _vm._s(
                        _vm._f("pounds")(_vm.secureHosting.total_commission)
                      ) +
                      " | \n                    Total CC Transaction: " +
                      _vm._s(_vm._f("pounds")(_vm.secureHosting.total_cc)) +
                      " |\n                    Total Secure Hosting: " +
                      _vm._s(_vm._f("pounds")(_vm.secureHosting.total_sh)) +
                      "\n                    \n                "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.secureHostingHeaders(),
                  items: _vm.secureHosting.data,
                  "hide-default-footer": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.timestamp)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.reference)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.type_of_hotel)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.is_advance_payment)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("pounds")(props.item.quote_amount_gbp)
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("pounds")(props.item.deposit_amount_gbp)
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("pounds")(props.item.commission_amount_gbp)
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            _vm._s(_vm._f("pounds")(props.item.total_invoiced))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("pounds")(
                                props.item.voucher_value_redeemed
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("pounds")(props.item.cc_transaction_amount)
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.payment_method)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("pounds")(props.item.local_secure_hosting)
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(_vm._s(props.item.confirmation_code)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }