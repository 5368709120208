<template>
    <v-col cols="12" md="4" ma-1>

        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">subject</v-icon> Key Information
            </v-card-title>

            <v-card-text>
              <v-container>
              <div v-if="hasProblem">Sorry, this content is not working</div>
              <div v-if="loaded && !hasProblem">
    
                <v-row wrap>
                    
                    <v-col cols="12" md="4" class="pa-1">
                        Country, Region:
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                        <a :href="hotelAddressUrl">{{ hotel.region.country.region_name }}, {{ hotel.region.region_name }}</a>
                    </v-col>

                    <v-col cols="12" md="4" class="pa-1">
                        URL:
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                        <a :href="hotelUrl" target="_blank">{{ hotel.website }}</a>
                    </v-col>

                    <v-col cols="12" md="4" class="pa-1">
                        Reservation contact / Tel:
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                        <div v-for="contact in hotel.reservation_phones.slice(0, 1)">
                            <a :href="hotelContactsUrl"><span v-if="contact.phone_number">+{{ contact.country_code }} {{ contact.area_code }} {{ contact.phone_number }}</span></a>
                        </div>
                    </v-col>

                    <v-col cols="12" md="4" class="pa-1">
                        Travel operator:
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                        <div v-for="assoc in hotel.user_associations">
                            <a :href="travelOperatorUrl"><span v-if="assoc.type_of_association != 'photo_library'">{{ assoc.legacy_admin_user.first_name }} {{ assoc.legacy_admin_user.surname }}</span></a>
                        </div>
                    </v-col>

                    <v-col cols="12" md="4" class="pa-1">
                        Display status:
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                        <span v-if="hotel.display">✔</span>
                        <a :href="displayStatusUrl">
                            <span v-if="mostRecentDisplayStatus">
                                <span v-if="mostRecentDisplayStatus.made_live">{{ mostRecentDisplayStatus.display_status == 2 ? 'Previewable' : 'Made live' }}</span>
                                <span v-if="mostRecentDisplayStatus.removed">Removed</span>
                                <span v-if="mostRecentDisplayStatus.reinstated">Reinstated</span>
                                <span v-if="mostRecentDisplayStatus.upgraded">Upgraded</span>

                                <span>{{ mostRecentDisplayStatus.date_of_change }}</span>
                            </span>
                            <span v-if="!mostRecentDisplayStatus">No data</span>
                        </a>
                    </v-col>

                    <v-col cols="12" md="4" class="pa-1">
                        Visit status:
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                        <span v-if="mostRecentVisit">✔</span>
                        <a :href="recentVisitUrl">
                            <span v-if="mostRecentVisit">{{ (hotel.visit_status) ? 'Authored review' : 'Pre-review' }}{{ mostRecentVisit.visit_date ? ', latest visit ' + mostRecentVisit.visit_date : '' }}</span>
                            <span v-if="!mostRecentVisit">No data</span>
                        </a>
                    </v-col>

                    <!--
                    <v-col cols="12" md="4" class="pa-1">
                        Contract:
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                        <span v-if="hotel.contract_signed">✔ <a :href="contractUrl">Signed {{ hotel.contract_signed }}</a></span>
                    </v-col>
                    -->

                    <v-col cols="12" md="4" class="pa-1">
                        Billing method:
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                        <a :href="contractUrl">{{ billingMethod }}</a>
                    </v-col>

                    <v-col cols="12" md="4" class="pa-1">
                        Standard Commission:
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                        <a :href="contractUrl">{{ hotel.commission_agreed }}%</a>
                    </v-col>
    
                    <!--
                    <v-col cols="12" md="4" class="pa-1">
                        Variable commission:
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                        TO DO
                    </v-col>
                    -->

                    <v-col cols="12" md="4" class="pa-1">
                        Special offers:
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                        <div v-for="offer in hotel.special_offers">
                            <span>{{ offer.online ? '✔' : '✖' }} <a :href="offerUrl">{{ offer.name }}</a></span>
                        </div>
                    </v-col>
    
                    <v-col cols="12" md="4" class="pa-1">
                        Rate types:
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                        <a :href="rateTypesUrl">{{ numOnlineRateTypes ? numOnlineRateTypes + " online" : "" }}{{ numOnlineRateTypes && numOfflineRateTypes ? ", " : "" }}{{ numOfflineRateTypes ? numOfflineRateTypes + " offline" : '' }}</a>
                    </v-col>
    
                    <v-col cols="12" md="4" class="pa-1">
                        Room types:
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                        <a :href="roomTypesUrl">{{ numOnlineRoomTypes ? numOnlineRoomTypes + " online" : "" }}{{ numOnlineRoomTypes && numOfflineRoomTypes ? ", " : "" }}{{ numOfflineRoomTypes ? numOfflineRoomTypes + " offline" : '' }}</a>
                    </v-col>
    
                    <v-col cols="12" md="4" class="pa-1">
                        Currency:
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                        <a :href="currencyUrl">{{ hotel.currency.currency_code }}</a>
                    </v-col>
    
                    <v-col cols="12" md="4" class="pa-1">
                        Booking system:
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                        <a :href="bookingSystemUrl">{{ bookingSystem }}</a> <span v-if="hotel.i_calendar_connections.length"><a :href="iCalUrl">iCal ({{ hotel.i_calendar_connections.length }})</a></span>
                    </v-col>
    
                    <v-col cols="12" md="4" class="pa-1">
                        i-escape gift:
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                        <a :href="contractUrl">{{ hotel.extra_discount ? hotel.extra_discount : 'None' }}</a>
                    </v-col>
    
                    <v-col cols="12" md="4" class="pa-1">
                        Review format:
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                        <a :href="contractUrl">{{ reviewFormat }}</a>
                    </v-col>
                    
                    <v-col cols="12" md="4" class="pa-1">
                        Accommodation type:
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                        <a :href="backgroundUrl">{{ hotel.exact_no_of_rooms ? hotel.exact_no_of_rooms : 'No data' }}</a>
                        <a :href="backgroundUrl">{{ hotel.no_of_rooms }}</a>
                    </v-col>

                    <v-col cols="12" md="4" class="pa-1">
                        Rate Parity:
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                        <div class="pa-2" 
                             :class="{ 'parity-good': mostRecentParity.status == 'Good', 'parity-partial': mostRecentParity.status == 'Partial', 'parity-bad': mostRecentParity.status == 'Bad' }"
                             v-if="mostRecentParity"
                        >
                            {{ mostRecentParity.status }} ({{ mostRecentParity.date_of_change }})
                        </div>
                        <span v-if="!mostRecentParity">No data</span>
                    </v-col>

                </v-row>
              </div>
              </v-container>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<script>
    /**
     * Display buttons linking through to a hotels' pages
     */
    export default {
        props:['pageid'],

        data: function() {
            return {
                hotel: {},
                loaded: false,
                hasProblem: false
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            this.loadHotel();
        },

        methods: {
            loadHotel: function(){

                try {
                    axios.get('/api/hotel/' + this.pageid + '/summary-information/')
                        .then(function(response){
                           this.hotel = response.data.hotel;
                           this.hotel.region.country = response.data.country;// temporary patch to put the data in where the models dont currently support this

                           this.showProgressIcon = false;
                           this.loaded = true;
                           console.log(response);
                           console.log(this.hotel);
                        }.bind(this));
                    }
                catch(exception){
                    console.log(exception);
                    this.hasProblem = true;
                }
            }
        },

        computed: {
            /**
              * Link to the hotel's own website
              */
            hotelUrl: function(){
                return (this.hotel.website.includes('http')) ? this.hotel.website : "https://" + this.hotel.website;
            },

            hotelAddressUrl: function(){
                return "/hotel-main-contacts/" + this.pageid;
            },

            hotelContactsUrl: function(){
                return "/hotel-reservation-contacts/list?s=as&c[]=HotelContact-hotel_key&o[]===&v[]=" + this.pageid;
            },

            travelOperatorUrl: function(){
                return "/hotel-travel-operators/list?s=as&c[]=Hotel-hotel_key&o[]===&v[]=" + this.pageid;
            },

            displayStatusUrl: function(){
                return "/hotel-display-status/list?s=as&c[]=Hotel-hotel_key&o[]===&v[]=" + this.pageid;
            },

            recentVisitUrl: function(){
                return "/hotel-visits/list?s=as&c[]=HotelVisit-hotel_key&o[]===&v[]=" + this.pageid;
            },

            bookingSystemUrl: function(){
                return "/hotel-booking-system/" + this.pageid;
            },

            /**
              * Live overview page on i-escape booking site
              */
            liveHotelPageUrl: function(){
                return "https://www.i-escape.com/" + this.hotel.main_url_name.url_name;
            },

            rateTypesUrl: function(){
                return "/rate-types/list?s=as&c[]=Hotel-hotel_key&o[]===&v[]=" + this.pageid;
            },

            roomTypesUrl: function(){
                return "/hotel-rooms/list?s=as&c[]=HotelRoom-hotel_key&o[]===&v[]=" + this.pageid;
            },

            contractUrl: function(){
                return "/hotel-contract-format/" + this.pageid;
            },

            iCalUrl: function(){
                return "/incoming-ical-feeds/list&" + this.pageid;//***************
            },

            offerUrl: function(){
                return "/special-offers/list?" + this.pageid;//*************
            },

            currencyUrl: function(){
                return "/hotel-currency/list?s=as&c[]=Hotel-hotel_key&o[]===&v[]=" + this.pageid;
            },

            backgroundUrl: function(){
                return "/hotel-background-info/" + this.pageid;
            },

            /**
             * The way the hotel is contracted is not clearly defined in the database, so it
             * must keep being determined by working it out from combinations of fields
             */
            billingMethod: function() {
                if (this.hotel.fixed_fee == 1) {
                    return 'Fixed fee';
                }
                else {
                    if (this.hotel.collect_deposit == '1') {
                        return 'Upfront';
                    }
                    else {
                        if (this.hotel.collect_deposit == '0') {
                            return 'In arrears';
                        }
                    }
               }
               return 'no data';
            },

            /**
             * The booking system data is stored in different fields instead of one
             * so we need to figure it out based on the values of those fields
             */
            bookingSystem: function(){
                if(this.hotel.book_via_email){
                    return "Email - Quote and Availability on Request";
                }
                if(this.hotel.book_via_email_precalc){
                    return "Email - Quote pre-calculated, Availability on Request";
                }
                if(this.hotel.book_via_web){
                    // Formerly "Web - Quote and Availability on Request"
                    return "On Request";
                }
                if(this.hotel.book_via_web_precalc){
                    return "Web - Quote pre-calculated, Availability on Request";
                }
                if(this.hotel.book_via_instant){
                     // Formerly "Instant Online Availability";
                     return "Instant";
                }
                return "No data";
            },

            /**
              * Again, the data is in non-sensible formats, meaning that we need
              * to check the relevant fields and concoct some sort of string to
              * give the user some info the same way that the legacy admin version
              * actually did
              */
            reviewFormat: function(){
                var reviewFormat = '';
                if(this.hotel.is_hotel){
                    reviewFormat = "Hotel / B&B";
                }
             
                if(this.hotel.is_tailormade_tour){
                    if(reviewFormat){
                        reviewFormat += ", ";
                    }
                    reviewFormat += "TailorMade Tour";
                }
                if(this.hotel.is_special_interest){
                    if(reviewFormat){
                        reviewFormat += ", ";
                    }
                    reviewFormat += "Special Interest";
                }
                if(this.hotel.is_rental){
                    if(reviewFormat){
                        reviewFormat += ", ";
                    }
                    reviewFormat += "Rental";
                }
                if(this.hotel.is_kids){
                    if(reviewFormat){
                        reviewFormat += ", ";
                    }
                    reviewFormat += "Kids";
                }
                return reviewFormat ? reviewFormat : "No data";
            },

            mostRecentVisit: function(){
                if (this.hotel.visits.length){
                    var orderedList = _.orderBy(this.hotel.visits, 'review_date');
                    return orderedList[orderedList.length - 1];
                }
            },

            mostRecentParity: function(){
                if (this.hotel.rate_parities.length){
                    var orderedList = _.orderBy(this.hotel.rate_parities, 'date_of_change');
                    return orderedList[orderedList.length - 1];
                }
            },

            mostRecentDisplayStatus: function(){
                if (this.hotel.display_statuses.length){
                    var orderedList = _.orderBy(this.hotel.display_statuses, 'date_of_change');
                    return orderedList[orderedList.length - 1];
                }
            },

            numOnlineRoomTypes: function(){
                var numOnline = 0;
                this.hotel.room_types.forEach(function(roomType){
                   if (roomType.is_online){
                       numOnline++;
                   }
                });
                return numOnline;
            },
            numOfflineRoomTypes: function(){
                var numOffline = 0;
                this.hotel.room_types.forEach(function(roomType){
                   if (!roomType.is_online){
                       numOffline++;
                   }
                });
                return numOffline;
            },
            numOnlineRateTypes: function(){
                var numOnline = 0;
                this.hotel.rate_types.forEach(function(rateType){
                   if (rateType.is_enabled){
                       numOnline++;
                   }
                });
                return numOnline;
            },
            numOfflineRateTypes: function(){
                var numOffline = 0;
                this.hotel.rate_types.forEach(function(rateType){
                   if (!rateType.is_enabled){
                       numOffline++;
                   }
                });
                return numOffline;
            }
        }
    }
</script>
<style>
.parity-good {
    background-color: rgb(204,255,204);
    border-color: solid 1px green;
}

.parity-partial {
    background-color: rgb(255,255,204);
    border: solid 1px yellow;
}

.parity-bad {
    background-color: rgb(255,200,200);
    border: solid 1px red;
}
</style>
