<template>
    <v-flex>{{ categories }}</v-flex>
</template>

<script>
/**
 * Outputs a list of facility categories representing the values stored in the
 * model's field.  The field is an integer, but the output is a string
 *
 */
export default {
    name: 'ies-facility-categories',
    props: ['field', 'value'],
    data () {
        return {}
    },

    computed: {
        /**
         * ROOM          = 1;
         * DINING        = 2;
         * ACTIVITY      = 4;
         * ESSENTIAL     = 8;
         * FAMILY        = 16;
         */
        categories: function(){
console.log("ok: ", this.value);
            var categoriesAsString = "";
            if (this.value & 1){
                categoriesAsString += 'In-room';
            }
            if (this.value & 2){
                if (categoriesAsString){
                    categoriesAsString += ', ';
                }
                categoriesAsString += 'Dining';
            }
            if (this.value & 4){
                if (categoriesAsString){
                    categoriesAsString += ', ';
                }
                categoriesAsString += 'Activity';
            }
            if (this.value & 8){
                if (categoriesAsString){
                    categoriesAsString += ', ';
                }
                categoriesAsString += 'Essentials';
            }
            if (this.value & 16){
                if (categoriesAsString){
                    categoriesAsString += ', ';
                }
                categoriesAsString += 'Family';
            }
            return categoriesAsString;
        }
    }

}
</script>
