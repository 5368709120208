var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c("v-col", { attrs: { cols: "12", md: "3", xl4: "" } }, [
            _c("h1", [_vm._v("Vouchers Summary")]),
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3", md: "1" } },
            [
              _vm._v("\n                Filter by:\n                "),
              _c(
                "v-radio-group",
                {
                  attrs: { mandatory: false },
                  model: {
                    value: _vm.filterBy,
                    callback: function ($$v) {
                      _vm.filterBy = $$v
                    },
                    expression: "filterBy",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: { label: "Data range", value: "range" },
                  }),
                  _vm._v(" "),
                  _c("v-radio", { attrs: { label: "Year", value: "year" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.filterBy === "range"
            ? _c(
                "v-col",
                { attrs: { cols: "12", "mt-4": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "3" } }, [
                        _c(
                          "div",
                          {
                            ref: "menu",
                            attrs: {
                              "close-on-content-click": false,
                              "nudge-right": 40,
                              lazy: "",
                              transition: "scale-transition",
                              "offset-y": "",
                              "full-width": "",
                              "max-width": "290px",
                              "min-width": "290px",
                            },
                            model: {
                              value: _vm.range.from.open,
                              callback: function ($$v) {
                                _vm.$set(_vm.range.from, "open", $$v)
                              },
                              expression: "range.from.open",
                            },
                          },
                          [
                            _c("v-text-field", {
                              attrs: {
                                label: "From date",
                                "prepend-icon": "event",
                                readonly: "",
                              },
                              model: {
                                value: _vm.range.from.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.range.from, "date", $$v)
                                },
                                expression: "range.from.date",
                              },
                            }),
                            _vm._v(" "),
                            _c("v-date-picker", {
                              attrs: {
                                type: "month",
                                "no-title": "",
                                min: "2017-11-01",
                                max: _vm.currentYear,
                              },
                              on: {
                                input: function ($event) {
                                  _vm.range.from.open = false
                                },
                              },
                              model: {
                                value: _vm.range.from.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.range.from, "date", $$v)
                                },
                                expression: "range.from.date",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "12", md: "3" } }, [
                        _c(
                          "div",
                          {
                            ref: "menu",
                            attrs: {
                              "close-on-content-click": false,
                              "nudge-right": 40,
                              lazy: "",
                              transition: "scale-transition",
                              "offset-y": "",
                              "full-width": "",
                              "max-width": "290px",
                              "min-width": "290px",
                            },
                            model: {
                              value: _vm.range.to.open,
                              callback: function ($$v) {
                                _vm.$set(_vm.range.to, "open", $$v)
                              },
                              expression: "range.to.open",
                            },
                          },
                          [
                            _c("v-text-field", {
                              attrs: {
                                label: "To date",
                                "prepend-icon": "event",
                                readonly: "",
                              },
                              model: {
                                value: _vm.range.to.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.range.to, "date", $$v)
                                },
                                expression: "range.to.date",
                              },
                            }),
                            _vm._v(" "),
                            _c("v-date-picker", {
                              attrs: {
                                type: "month",
                                "no-title": "",
                                min: "2017-11-01",
                                max: _vm.currentYear,
                              },
                              on: {
                                input: function ($event) {
                                  _vm.range.to.open = false
                                },
                              },
                              model: {
                                value: _vm.range.to.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.range.to, "date", $$v)
                                },
                                expression: "range.to.date",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.filterBy === "year"
            ? _c(
                "v-col",
                { attrs: { cols: "2", "mt-4": "" } },
                [
                  _c("v-select", {
                    attrs: { items: _vm.years, label: "Pick year" },
                    model: {
                      value: _vm.year,
                      callback: function ($$v) {
                        _vm.year = $$v
                      },
                      expression: "year",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "1", "mt-4": "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "lime" },
                  on: {
                    click: function ($event) {
                      return _vm.filter()
                    },
                  },
                },
                [_vm._v("Filter")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "12" } }, [
            _c(
              "div",
              { attrs: { id: "linearWrapper" } },
              [
                _c("v-progress-linear", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showProgressIcon,
                      expression: "showProgressIcon",
                    },
                  ],
                  attrs: { indeterminate: true, color: "lime" },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showStats
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "justify-center": "", "mt-2": "" } },
            [
              _c(
                "v-col",
                { staticClass: "tableWrapper", attrs: { cols: "12" } },
                [
                  _c(
                    "table",
                    { staticClass: "summaryTable" },
                    _vm._l(_vm.tableStats, function (row, rowIndex) {
                      return _c(
                        "tr",
                        { key: rowIndex },
                        _vm._l(row, function (column, columIndex) {
                          return _c("td", { key: columIndex }, [
                            isNaN(column.value)
                              ? _c(
                                  "span",
                                  {
                                    class: {
                                      fieldTitle: column.type === "title",
                                    },
                                  },
                                  [_vm._v(_vm._s(column.value))]
                                )
                              : _c("span", { staticClass: "fieldValue" }, [
                                  _vm._v("£" + _vm._s(column.value.toFixed(2))),
                                ]),
                            _vm._v(" "),
                            column.count > 0
                              ? _c("span", { staticClass: "fieldCount" }, [
                                  _vm._v(_vm._s(column.count)),
                                ])
                              : _vm._e(),
                          ])
                        }),
                        0
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showPanel,
              expression: "showPanel",
            },
          ],
          attrs: { row: "", wrap: "", "mt-5": "" },
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm._v(
                "\n                    Monthly Chart (by voucher count)\n                        "
              ),
              _c(
                "v-card",
                [
                  _c("v-card-text", [
                    _c(
                      "div",
                      { staticClass: "chartLinearWrapper" },
                      [
                        _c("line-chart", {
                          attrs: {
                            chartdata: _vm.monthlyChartByCount.data,
                            options: _vm.monthlyChartByCount.options,
                            height: 600,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm._v(
                "\n                    Monthly Chart (by voucher value)\n                    "
              ),
              _c(
                "v-card",
                [
                  _c("v-card-text", [
                    _c(
                      "div",
                      { staticClass: "chartLinearWrapper" },
                      [
                        _c("line-chart", {
                          attrs: {
                            chartdata: _vm.monthlyChartByValue.data,
                            options: _vm.monthlyChartByValue.options,
                            height: 600,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm._v(
                "\n                    Average vouchers value\n                    "
              ),
              _c(
                "v-card",
                [
                  _c("v-card-text", [
                    _c(
                      "div",
                      { staticClass: "chartLinearWrapper" },
                      [
                        _c("line-chart", {
                          attrs: {
                            chartdata: _vm.averageMonthlyValueChart.data,
                            options: _vm.averageMonthlyValueChart.options,
                            height: 600,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm._v(
                "\n                    Summary Chart\n                    "
              ),
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { staticClass: "chartPieWrapper" },
                            [
                              _c("pie-chart", {
                                attrs: {
                                  chartdata: _vm.sumChartByCount.data,
                                  options: _vm.sumChartByCount.options,
                                  height: 250,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { staticClass: "chartPieWrapper" },
                            [
                              _c("pie-chart", {
                                attrs: {
                                  chartdata: _vm.sumChartByValue.data,
                                  options: _vm.sumChartByValue.options,
                                  height: 250,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: parseInt(2000),
            "multi-line": "",
            color: "ies-green",
          },
          model: {
            value: _vm.snackbar.successSnackbar,
            callback: function ($$v) {
              _vm.$set(_vm.snackbar, "successSnackbar", $$v)
            },
            expression: "snackbar.successSnackbar",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "full-height full-width v-align-container text-xs-center",
            },
            [
              _c("h4", { staticClass: "ies-dark-gray--text v-align-div" }, [
                _vm._v(_vm._s(_vm.snackbar.message)),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: parseInt(3000),
            "multi-line": "",
            color: "ies-coral",
          },
          model: {
            value: _vm.snackbar.errorSnackbar,
            callback: function ($$v) {
              _vm.$set(_vm.snackbar, "errorSnackbar", $$v)
            },
            expression: "snackbar.errorSnackbar",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "full-height full-width v-align-container text-xs-center",
            },
            [
              _c("h4", { staticClass: "ies-dark-gray--text v-align-div" }, [
                _vm._v(_vm._s(_vm.snackbar.message)),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }