<template>
    <v-toolbar fixed class="no-print" flex style="z-index: 1000;">
        <v-app-bar-nav-icon @click="updateNav()">
            <v-icon>menu</v-icon>
        </v-app-bar-nav-icon>

        <v-toolbar-title>
            <v-flex>
                <a href="/">
                    <img src="/images/i-escape-logo.png" class="navbar-logo" alt="i-escape logo" >
                </a>
            </v-flex>
        </v-toolbar-title>

        <v-toolbar-items style="max-width: 100%; min-width: 70%;" class="d-none d-sm-flex">
                <v-layout class="mt-1 ml-1" row>
                    <v-col lg="9" md="12" class="qa-button-container">
                        <ies-quick-access-buttons></ies-quick-access-buttons>
                    </v-col>

                    <v-col lg="3" class="d-none d-lg-flex">
                        <ies-smart-search></ies-smart-search>
                    </v-col>
                </v-layout>
        </v-toolbar-items>

    </v-toolbar>
</template>

<script>
    export default {
        props:['metadata','user', 'recentlysaved'],
        data: function() {
            return {
                sideNav: false,
            }
        },
        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            // If any component announces that the user has requested the main menu toggle then this component must react to the request
            this.$eventHub.$on('main-menu: toggle', sideNav => {
                this.sideNav = sideNav;
            });
        },
        methods: {
            updateNav(){
                // Emit an event announcing the request that the main menu enter the open/closed toggle state so that anything relevant such as the NavDrawer can
                // be made aware and act accordingly
                this.sideNav = !this.sideNav;
                this.$eventHub.$emit('main-menu: toggle', this.sideNav);
            }
        }
    }
</script>

<style>
    header {
        height: 75px;
        max-height: 75px;
    }

    .v-toolbar__content {
        @media screen and (max-width: 960px) {
            justify-content: center;
        }
    }

    .toolbar--fixed {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100001;
    }

    /* Allow the quick access buttons to scroll if there are too many to fit, otherwise it overflows the rest of the page */
    .qa-button-container {
        height: 56px;
        overflow-y: scroll;
    }
</style>
