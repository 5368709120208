<template>
    <v-card>

        <v-container fluid class="mobile-no-padding">
                <v-flex xs12 class="mb-3">
                    <v-expansion-panel>
                        <v-expansion-panel-content
                            :value="showSearchPanel"
                            class="quickSearchPanel">
                            <div slot="header"><v-icon>search</v-icon> Filter</div>
                            <v-daterange
                                :options="dateRangeOptions"
                                @input="setDateRange"
                                :labels="dateRangeLabels" />
                            <v-btn v-on:click="loadData()" class="ies-green">Search</v-btn>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-flex>

                <v-flex xs12>
                    <v-layout column>
                            
                        <v-flex xs12 v-if="loading" class="text-xs-center">
                            <v-progress-circular 
                                indeterminate 
                                color="lime">
                            </v-progress-circular>
                            Loading... this may take several seconds
                        </v-flex>


                        <v-flex xs12 v-if="error && !loading" class="text-xs-center">
                            <v-alert :value="true" type="error">
                                This content failed to load
                            </v-alert>
                        </v-flex>

                        <v-flex xs12 v-if="!Object.keys(regionStats).length && !loading && hasSearched" class="text-xs-center">
                            <v-alert :value="true" type="info">
                                There are no results to display
                            </v-alert>
                        </v-flex>

                        <v-flex v-if="!loading">
                            <h2>Summary</h2>

                            <v-data-table
                                :items="summaryStats"
                                class="elevation-1"
                                hide-actions
                                disable-initial-sort
                            >

                                <template slot="headers" slot-scope="props">
                                    <tr>
                                        <th class="text-xs-left">Type</th>
                                        <th class="text-xs-right">New</th>
                                        <th class="text-xs-right">Accepted Quotes</th>
                                        <th class="text-xs-right">%</th>
                                    </tr>
                                </template>

                                <template slot="items" slot-scope="props">
                                    <tr>
                                        <td>{{ props.item.type }}</td>
                                        <td class="text-xs-right">{{ props.item.new }}</td>
                                        <td class="text-xs-right">{{ props.item.quotes_accepted }}</td>
                                        <td class="text-xs-right">{{ props.item.percentage }}</td>
                                    </tr>
                                </template>

                            </v-data-table>
                        </v-flex>

                        <v-flex xs12 id="table-external" v-if="!loading">
                            <div v-for="(region, index) in regionStats" class="mt-3" :key="index">
                                <h2>{{ region.country_name && region.country_name != region.region_name ? region. country_name + ' : ' + region.region_name : region.region_name }}</h2>
                    
                                <v-data-table
                                    :headers="headers"
                                    :items="region.hotels"
                                    class="elevation-1"
                                    hide-actions
                                    disable-initial-sort
                                >

                                    <template slot="headers">
                                        <tr>
                                            <th class="text-xs-left">Hotel Code</th>
                                            <th class="text-xs-left">Hotel Name</th>
                                            <th class="text-xs-right">New</th>
                                            <th class="text-xs-right">Accepted Quotes</th>
                                            <th class="text-xs-right">%</th>
                                        </tr>
                                    </template>

                                    <template slot="items" slot-scope="props">
                                        <tr>
                                            <td>{{ props.item.hotel_code }}</td>
                                            <td>{{ props.item.hotel_name }}</td>
                                            <td class="text-xs-right">{{ props.item.new }}</td>
                                            <td class="text-xs-right">{{ props.item.quotes_accepted }}</td>
                                            <td class="text-xs-right">{{ props.item.percentage }}</td>
                                        </tr>
                                    </template>

                                </v-data-table>
                            </div>
                        </v-flex>

                    </v-layout>
                </v-flex>

        </v-container>
    </v-card>
</template>


<script>
    import { format, subDays, subMonths, subYears } from 'date-fns';

    /**
     * 
     *
     * @todo Presently this component simply asks for all the hotels by all regions, but it does support passing arrays to limit the requests further - at this time
     * the full functionality to limit it to specific hotel or region goes beyond the brief, so for time saving this search functionality has been omitted for now
     */
    export default{

        /**
         * Define all the data variables which Vue needs to bind to the view template directly, or react to automatically to regenerate computed properties
         */
        data: function() {
            return {
                /** @var boolean loading - Is this component finished loading data */
                loading: false,

                /** @var boolean hasSearched - Has the component already tried to obtain data from the API.  Used to determine which messages to display to the user */
                hasSearched: false,

                /** @var boolean error   - Was an error encountered when trying to load the data */
                error:  false,

                /** @var [] headers - The headers do not need to change so we may as well hard code them the way we want them for simplicity */
                headers: [ 
                    {
                        text:   'Hotel Code',
                        value:  'hotel_code'
                    },
                    {
                        text:   'Hotel Name',
                        value:  'hotel_name'
                    },
                    {
                        text:   'Accepted Quotes',
                        value:  'accepted_quotes',
                        align:  'right'
                    },
                    {
                        text:   'New',
                        value:  'new',
                        align:  'right'
                    },
                    {
                        text:   '%',
                        value:  'percentage',
                        align:  'right'
                    },
                ],

                /** @var boolean showPanel - Should the filter panel be displayed to the user */
                showSearchPanel: true,

                /** @var {} searchData - The filters being applied */
                searchData: { dateRange: null, regionKeys: [], hotelKeys: [] },

                /** @var [] data    - The raw data as loaded from the API */
                data: [],

                /** @var {} dateRangeLabels - An object encapsulating the label data for the date range picker component to use */
                dateRangeLabels: {
                    start: 'Start Date',
                    end: 'End Date',
                    preset: 'Date range'
                },

                /** @var {} dateRangeOptions - An object encapsulating the configuration data for the date range picker component to use */
                dateRangeOptions: {
                    startDate: format(new Date(), 'YYYY-MM-DD'),
                    endDate: format(new Date(), 'YYYY-MM-DD'),
                    minDate: '2011-01-01',
                    maxDate: '2050-01-01',
                    format: 'YYYY-MM-DD',
                    presets: [
                        {
                            label: 'Today',
                            range: [
                                format(new Date(), 'YYYY-MM-DD'),
                                format(new Date(), 'YYYY-MM-DD'),
                            ],
                        },
                        {
                            label: 'Yesterday',
                            range: [
                                format(subDays(new Date(), 1), 'YYYY-MM-DD'),
                                format(subDays(new Date(), 1), 'YYYY-MM-DD'),
                            ],
                        },
                        {
                            label: 'Last 7 Days',
                            range: [
                                format(subDays(new Date(), 7), 'YYYY-MM-DD'),
                                format(new Date(), 'YYYY-MM-DD'),
                            ],
                        },
                        {
                            label: 'Last 30 Days',
                            range: [
                                format(subDays(new Date(), 30), 'YYYY-MM-DD'),
                                format(new Date(), 'YYYY-MM-DD'),
                            ],
                        },
                        {
                            label: 'Last 6 Months',
                            range: [
                                format(subMonths(new Date(), 6), 'YYYY-MM-DD'),
                                format(new Date(), 'YYYY-MM-DD'),
                            ],
                        },
                        {
                            label: 'Last Year',
                            range: [
                                format(subMonths(new Date(), 12), 'YYYY-MM-DD'),
                                format(new Date(), 'YYYY-MM-DD'),
                            ],
                        },
                        {
                            label: 'All Time',
                            range: [
                                '2011-01-01',
                                format(new Date(), 'YYYY-MM-DD'),
                            ],
                        },
                    ],
                },

            }

        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
//            this.loadData();
        },

        /**
         *
         *
         *
         */
        methods: {

            /**
             * Callback function for event listeners to call when the service updates its data
             */
            loadData(){
                this.loading    = true;
                this.error      = false;
                this.hasSearched= true;

                var dateFrom    = this.searchData.dateRange[0];
                var dateTo      = this.searchData.dateRange[1];
                var regionKeys  = this.searchData.regionKeys;
                var hotelKeys   = this.searchData.hotelKeys;
                var reference   = 'ref1';

                this.getDataFromAPI(dateFrom, dateTo, regionKeys, hotelKeys, reference);
            },

            /**
             * Obtains the statistical data from the API for the parameters passed
             */
            getDataFromAPI: function(dateFrom, dateTo, regionKeys, hotelKeys, reference){
                var params = { dateFrom: dateFrom, dateTo: dateTo, regionKeys: regionKeys, hotelKeys: hotelKeys, reference: reference };
                var self = this;

                axios.get('/api/accounts/conversions/statistics', { params: params })
                .then(function(response){ self.processResponse(response, reference) })
                .catch(function (error) {
                    console.log('%cError loading statistics from API', 'color:red');
                    console.log(error);
                    self.loading = false;
                    self.error   = true;
                });
            },

            /**
             *
             */
            processResponse: function(response, reference){
                this.data    = response.data;
                this.loading = false;
            },

            setDateRange: function(dateRange){
                this.searchData.dateRange = dateRange;
            },



        },

        /**
         * Define live data bindings so that the view template will automatically update when Vue detects that the dependencies within the functions defining them below
         * change.  All data displayed on the template should be defined as a computed property, otherwise it will never change in future when the data variable it is based
         * on does.
         */
        computed: {
        
            /**
             * Provide all of the regions and their conversion statistics grouped by region
             */
            regionStats: function(){
                var data = {};
                this.data.forEach(function(hotel){

                    if (!data[hotel.region.region_key]){
                        data[hotel.region.region_key] = { 
                                    country_name: hotel.region.country_name, 
                                    region_name: hotel.region.region_name, 
                                    hotels: []
                        };
                    }

                    data[hotel.region.region_key].hotels.push(
                                                { 
                                                    hotel_key:          hotel.hotel_key,
                                                    hotel_code:         hotel.hotel_code,
                                                    hotel_name:         hotel.hotel_name,
                                                    quotes_accepted:    hotel.stats.numQuotesAccepted,
                                                    new:                hotel.stats.numNewEnquiries,
                                                    percentage:         (100 * (hotel.stats.numQuotesAccepted / hotel.stats.numNewEnquiries)).toFixed(2)
                                                } 
                                    );
                });

                return data;
            },


            /**
             * Calculates the overall summary stats for all of the region stats as a whole
             */
            summaryStats: function(){
                var stats = [];
            
                // Currently the on-request bookings are the only ones relevant to this report.  It is possible it will stay that way, but others such as cancellations are
                // likely to be added too
                var requestStats = { type: 'On Request', new: 0, quotes_accepted: 0, percentage: 0 };
                this.data.forEach(function(hotel){
                    requestStats.new += hotel.stats.numNewEnquiries;
                    requestStats.quotes_accepted += hotel.stats.numQuotesAccepted;
                });
                requestStats.percentage = (100 * (requestStats.quotes_accepted / requestStats.new)).toFixed(2);

                stats.push(requestStats);

                return stats;
            }
        
        }
    }

</script>
