<template>
    <v-container fluid>
        <v-flex xs12 v-for="(rule, ruleKey) in section.rules" :key="ruleKey" class="ma-3">
            <v-layout column>
                <v-flex xs12 class="mb-2">
                    <v-layout row>
                        <v-flex sm1>
                            <v-btn class="ies-coral" @click.native.stop="deleteRule(ruleKey)"> Delete </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
    
                    <!-- Select Model -->
                    <v-flex class="mb-3">
                        <v-layout row>
                            <v-flex sm1>
                                <label>Model</label>
                            </v-flex>
                            <v-flex sm4>
                                <select  class="full-width"
                                v-model="rule.modelName"
                                v-on:change="rule.fieldName = '';" >
                                    <option value="">Please Select A Model </option>
                                    <option :value="modelName" v-for="modelName in sortModels(models)" :key="modelName" > {{ modelName }} </option>
                                </select>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    
                    <!-- Select column -->
                    <v-flex class="mb-3">
                        <v-layout row>
                            <v-flex sm1>
                                <label> Column</label>
                            </v-flex>
                            <v-flex sm4>
                                <select class="full-width"
                                    v-model="rule.fieldName" >
                                    <option value="">Please Select a Column</option>
                                    <option :value="fieldName" v-for="fieldName in sortColumns(rule)" > {{ fieldName }} </option>
                                </select>
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <!-- Select Operator -->
                    <v-flex class="mb-3" >
                        <v-layout row>
                            <v-flex sm1>
                                <label>Operator</label>
                            </v-flex>
                            <v-flex sm4>
                                <select  class="full-width"
                                v-model="rule.operator" >
                                    <option value="">Please Select an Operator </option>
                                    <option :value="operator" v-for="(operator,oppKey) in operators" :key="oppKey" > {{ operator }} </option>
                                </select>
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <!-- Set Value -->
                    <v-flex class="mb-3" >
                        <v-layout row>
                            <v-flex sm1>
                                <div class="v-align-container full-height">
                                    <div class="v-align-div">
                                        <label>Value</label>
                                    </div>
                                </div>
                            </v-flex>
                            <v-flex sm4>
                                <input type="text" v-model="rule.value">
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
                <v-divider class="mt-3 mb-3" ></v-divider>
            </v-flex>
        </v-container>

</template>

<script>
    export default {
        name: 'PortalDesignerRules',
        props: ['section', 'sectionkey','models',],
        data () {
            return {
                'currentSection' : this.section,
                'operators': ["!=","==","<",">","&"]
           }
        },
        methods: {
            updateObject: function(){
                this.section = this.currentSection;
            },

            /**
             * Returns an alphabetised array of names of provided models
             * @param models - an object containing models
             */
            sortModels: function(models){
                let keys = Object.keys(models).sort();
                return keys;
            },

            /**
             * Returns an alphabetised array of fields on a given model
             * @param string modelName - the name of a model
             */
            sortColumns: function(rule){
                var fields = this.models[rule.modelName];
                if (fields) {
                    let keys = Object.keys(fields).sort();
                    fields = keys;
                }
                return  fields;
            },
            deleteRule: function(ruleKey){
            
               delete this.section['rules'][ruleKey];
               this.section['rules'] =  this.section['rules'].filter(function(n){ return n != undefined }); 
               this.currentSection = this.section;
            }
        }
}
</script>


