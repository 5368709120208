<template>
    <div>
        <!-- list must be list of ['text' => x, 'value' => y] -->
        <v-select
            v-model="viewValue"
            :label="field.schema.displayName"
            :items="regions"
            autocomplete
        ></v-select>
        <input type="hidden" :name="field.fieldName" :value="field.value">
        <v-alert type="error" :value="field.errors" style="width: 100%">
            <li v-for="error in field.errors">
                {{ error }}
            </li>
        </v-alert>
    </div>

</template>

<script>
    /**
     * Allows the selection of an editorial region hierarchy by choosing its name from
     * a selection downloaded from the API on creation
     */
    export default {
        name: 'ies-regionHierarchy',
        props: ['field'],
        data() {
            return {
                regions: [],
                viewValue: this.field.value,
            }
        },
        created:function() {
            this.getRegions();
        },
        methods: {
            getRegions:function(){
                axios.get('/api/selectable-regions')
                .then(function(response){
                    var regions = [];
                    response.data.regions.forEach(function(record){
                        regions.push({ text: record.region_name, value: record.hierarchy });
                    });
                    this.regions = regions;
                }.bind(this));
            },
        },
        watch: {
            viewValue: function (newValue) {
                this.field.value = newValue;
            }
        }
    }
</script>
