var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0 no-print", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ies-green",
                      attrs: { disabled: "" },
                      on: { click: _vm.save },
                    },
                    [_c("v-icon", [_vm._v("save")]), _vm._v(" Save")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: "" },
                      on: {
                        click: function ($event) {
                          return _vm.refresh()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("refresh")]), _vm._v(" Refresh")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: "" },
                      on: {
                        click: function ($event) {
                          return _vm.downloadCSV()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("file_download")]), _vm._v(" CSV")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: "" },
                      on: {
                        click: function ($event) {
                          return _vm.downloadPDF()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("file_download")]), _vm._v(" PDF")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.printPage()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("print")]), _vm._v(" Print")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: "" },
                      on: {
                        click: function ($event) {
                          return _vm.email()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("email")]), _vm._v(" Email")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: "" },
                      on: {
                        click: function ($event) {
                          return _vm.reportProblem()
                        },
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("report_problem")]),
                      _vm._v(" Problem"),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { depressed: _vm.showConfig, disabled: "" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleConfig()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("web")]), _vm._v(" Edit View")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showConfig
            ? _c(
                "v-flex",
                { staticClass: "mt-3", attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("h3", { staticStyle: { margin: "15px" } }, [
                        _vm._v("How Would You Like To View This Report?"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "fieldset",
                        [
                          _c("label", [_vm._v("Add New View")]),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { staticClass: "ma-3", attrs: { xs12: "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addVisualisation("dataTable")
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("view_list")]),
                                  _vm._v(" Table"),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addVisualisation("chartBar")
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("insert_chart")]),
                                  _vm._v(" Bar"),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addVisualisation("chartLine")
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("show_chart")]),
                                  _vm._v(" Line"),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addVisualisation("chartPie")
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("pie_chart")]),
                                  _vm._v(" Pie"),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { disabled: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addVisualisation("chartBubble")
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("bubble_chart")]),
                                  _vm._v(" Bubble"),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addVisualisation("chartPolar")
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("pie_chart")]),
                                  _vm._v(" Polar"),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addVisualisation("chartRadar")
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("filter_tilt_shift")]),
                                  _vm._v(" Radar"),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addVisualisation(
                                        "chartDoughnut"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("donut_small")]),
                                  _vm._v(" Doughnut"),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { disabled: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addVisualisation(
                                        "chartScatter"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("show_chart")]),
                                  _vm._v(" Scatter"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel",
                        _vm._l(_vm.visualisations, function (view, index) {
                          return _c(
                            "v-expansion-panel-content",
                            { key: index },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "header" }, slot: "header" },
                                [
                                  view.type == "dataTable"
                                    ? _c("v-icon", [_vm._v("view_list")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  view.type == "chartBar"
                                    ? _c("v-icon", [_vm._v("insert_chart")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  view.type == "chartLine"
                                    ? _c("v-icon", [_vm._v("show_chart")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  view.type == "chartPie"
                                    ? _c("v-icon", [_vm._v("pie_chart")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  view.type == "chartBubble"
                                    ? _c("v-icon", [_vm._v("bubble_chart")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  view.type == "chartPolar"
                                    ? _c("v-icon", [_vm._v("pie_chart")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  view.type == "chartRadar"
                                    ? _c("v-icon", [
                                        _vm._v("filter_tilt_shift"),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  view.type == "chartDoughnut"
                                    ? _c("v-icon", [_vm._v("donut_small")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  view.type == "chartScatter"
                                    ? _c("v-icon", [_vm._v("show_chart")])
                                    : _vm._e(),
                                  _vm._v(
                                    "\n                        Configure \n                        "
                                  ),
                                  view.type == "dataTable"
                                    ? _c("span", [_vm._v("Table")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  view.type == "chartBar"
                                    ? _c("span", [_vm._v("Bar Chart")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  view.type == "chartLine"
                                    ? _c("span", [_vm._v("Line Graph")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  view.type == "chartPie"
                                    ? _c("span", [_vm._v("Pie Chart")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  view.type == "chartBubble"
                                    ? _c("span", [_vm._v("Bubble Chart")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  view.type == "chartPolar"
                                    ? _c("span", [_vm._v("Polar Graph")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  view.type == "chartRadar"
                                    ? _c("span", [_vm._v("Radar Chart")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  view.type == "chartDoughnut"
                                    ? _c("span", [_vm._v("Doughnut Chart")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  view.type == "chartScatter"
                                    ? _c("span", [_vm._v("Scatter Graph")])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card",
                                [
                                  view.type != "dataTable"
                                    ? _c(
                                        "v-card-text",
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "Which fields would you like to compare?"
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "v-layout",
                                            { attrs: { row: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                [
                                                  _vm._v(
                                                    "\n                                    For each \n\n                                    "
                                                  ),
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.modelFields,
                                                      label: "Column A",
                                                      "item-value": "value",
                                                      "single-line": "",
                                                    },
                                                    model: {
                                                      value: view.modelFieldA,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          view,
                                                          "modelFieldA",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "view.modelFieldA",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "\n\n                                    (on the X-axis)\n                                "
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                [
                                                  _vm._v(
                                                    "\n                                    show me the\n\n                                    "
                                                  ),
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.modelFields,
                                                      label: "Column B",
                                                      "item-value": "value",
                                                      "single-line": "",
                                                    },
                                                    model: {
                                                      value: view.modelFieldB,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          view,
                                                          "modelFieldB",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "view.modelFieldB",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "\n\n                                    (on the Y-axis)\n                                "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateViews()
                                            },
                                          },
                                        },
                                        [_vm._v("Update View")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteView(index)
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [_vm._v("delete")]),
                                          _vm._v(" Delete View"),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }