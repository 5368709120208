<template>
    <v-container fluid>
       <v-layout row-sm column>
            <v-flex v-if="this.field.schema.displayName != 'none'" xs12>
                 <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-flex>
            <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            <v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            <v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>
            <v-flex xs12 sm6>

                <tinymce
                        :api-key="configuration.apiKey"
                        :init="configuration.init"
                        v-model="input" 
                        :id="tinyId"
                        :name="this.field.fieldName"
                        :required="this.field.required"
                        v-on:keyup="updateField()"
                >
                    {{ this.field.value }}
                </tinymce>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import Editor from '@tinymce/tinymce-vue';

    export default {

        name: 'ies-input-tinymce',
        props: ['field'],
        components: {
            'tinymce': Editor
        },
        data () {
            return {
                input: this.field.value,
                configuration: {
                    apiKey : '1fw1d076obmn5wsc7eb6d6s75edyfdupyxuanmmcemn8qr6t',
                    init: {
                        plugins: [
                            'advlist autolink lists link image charmap anchor',
                            'searchreplace visualblocks code',
                            'insertdatetime media contextmenu paste code'
                        ],
                        toolbar: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
                        setup: function (editor) {
                            editor.on('change', function () {
                                tinymce.triggerSave();
                            });
                        },
                    },
                }
            }
        },
        methods: {
            updateField: function(){
                this.field.value = this.input
            }
        },
        computed: {
            tinyId() {
                return 'tinymce_' + this.field.fieldName;
            },
        },
        watch: {
            input: {
                handler: function() {
                    this.updateField();
                }
            }
        }
    }
</script>


