<template>
    <v-col md="6" lg="4" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">calendar_view_month</v-icon> Channel Manager Requests
            </v-card-title>
           <line-chart :chartdata="chartData" :options="chartOptions"></line-chart>
        </v-card>
    </v-col>
    
</template>

<script>
 
 import LineChart from '@/components/charts/LineChart';
 import axios from 'axios';

    export default {
        props:['datasource'],
        components: {
            LineChart,
        },

        data() {
            return {
                apiData:  {},
                chartData: {},
                chartOptions: {
                    maintainAspectRatio: false,
                    responsive: true,
                    events: ['click'],
                    title: {
                        display: false,
                        text: ''
                    },
                    legend: {
                        align:'start'
                    },
                },
            }
        },
        /**
         * Perform the following actions on startup
         */
        mounted() {
            this.fetchChannelManagerRequests();

            // Refresh periodically
            setInterval( this.fetchChannelManagerRequests, 60000 );
        },

        methods: {
            /**
             * Load the latest data from the API
             */
            async fetchChannelManagerRequests() {
                const response = await axios.get('api/channel-manager-requests').then(response => {
                    this.apiData = response.data[1];
                    this.channelManagers = response.data[0];
                    this.buildDataObject();
                }).catch(error => {
                    console.log(error);
                });
            },

            formatDate(date){
                var dd = date.getDate();
                var mm = date.getMonth()+1;
                var yyyy = date.getFullYear();
                if(dd<10) {dd='0'+dd}
                if(mm<10) {mm='0'+mm}
                date = yyyy+'-'+mm+'-'+dd;
                return date
            },

            getColour: function(indexToFind){
                var palette = ['#C1CD23', '#F15C61', '#088193', '#23C1CD', '#61F15C', '#CC99FF', '#ff9966', '#ff99ff', '#5C61F1', '#819308', '#FFFF66', '#CCFFFF'];

                if (indexToFind < palette.length){
                    return palette[indexToFind];
                }
                return palette[ indexToFind % palette.length ];
            },

            /**
             * Build the data object required by the line chart using API data to
             * fill the datasets ofjects and lavels
             */
            buildDataObject() {
                let data = {
                    labels: [],
                    datasets: []
                };

                for (var index = 0; index < this.channelManagers.length; index++){
                    var channelManager = this.channelManagers[index];
                    data.datasets.push({
                        data: [],
                        label: channelManager.name,
                        borderColor: this.getColour(index),
                        fill: false
                    });
                }

                var dayIndex = 0;
                  for(const [date, channelsData] of Object.entries(this.apiData)){
                    data.labels.push(date);
                    
                    for (var channelManagerIndex = 0; channelManagerIndex < channelsData.length; channelManagerIndex++){
                        var channelData = channelsData[channelManagerIndex];
                        data.datasets[channelManagerIndex].data.push(channelData.totalRequests);
                    };

                    dayIndex++;
                }
                this.chartData = data;
            },
        }
    }
</script>
