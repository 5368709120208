<template>
    <v-container fluid>
<!--Search -->
        <form v-on:submit="findHistory($event)">
            <v-layout row wrap justify-center>
                <v-flex xs12 md2>
                    <v-text-field
                            v-model="reference"
                            label="Reservation reference"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 md1>
                    <v-btn color="lime" @click="findHistory($event)">Find history</v-btn>
                </v-flex>
                <v-flex xs1>
                    <div id="searchProgressIcon" v-show="showProgressIcon">
                        <v-progress-circular
                                :width="3"
                                color="blue"
                                indeterminate
                        ></v-progress-circular>
                    </div>
                </v-flex>
            </v-layout>
        </form>

<!--History Results -->
       <v-layout row wrap v-if="history.length">
            <v-flex xs12>
                <v-layout row wrap align-center>
                    <v-flex xs12 md6><h4>Reservation History:</h4></v-flex>
                    <v-flex xs12 md6><h4>Record additional history data:</h4></v-flex>
                </v-layout>
                <v-layout row wrap v-for="(reservationChange, key) in history" :key="key">
                    <v-flex xs12 md6>
                        <HistoryRecordData :record="reservationChange.record"></HistoryRecordData>
                        <HistoryRecordChanges :changes="reservationChange.record.record_changes"></HistoryRecordChanges>
                    </v-flex>
                    <v-flex xs12 md6 v-if="reservationChange.additional">
<!--History for this reservation_units that belongs to that history record -->
                        <v-layout row wrap v-if="reservationChange.additional.reservation_units.length">
                            <v-expansion-panel>
                                <v-expansion-panel-content>
                                    <div slot="header">Reservation Unit changes:</div>
                                    <v-card>
                                        <v-layout row wrap v-for="(unitChange, key) in reservationChange.additional.reservation_units" :key="key">
                                            <v-flex xs12>
                                                <HistoryRecordData :record="unitChange"></HistoryRecordData>
                                                <HistoryRecordChanges :changes="unitChange.record_changes"></HistoryRecordChanges>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-layout>
<!--History for reservation_std_rates that belongs to that history record-->
                        <v-layout row wrap v-if="reservationChange.additional.reservation_std_rates.length">
                            <v-expansion-panel>
                                <v-expansion-panel-content>
                                    <div slot="header">Reservation Std rates changes:</div>
                                    <v-card>
                                        <v-layout row wrap v-for="(stdChange, key) in reservationChange.additional.reservation_std_rates" :key="key">
                                            <v-flex xs12>
                                                <HistoryRecordData :record="stdChange"></HistoryRecordData>
                                                <HistoryRecordChanges :changes="stdChange.record_changes"></HistoryRecordChanges>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-layout>

                    </v-flex>
                    <v-flex xs12 pt-4 pb-4>
                        <v-divider></v-divider>
                    </v-flex>
                </v-layout>
            </v-flex>
       </v-layout>

<!--Erro snackbar-->
        <v-snackbar
                :timeout="parseInt(3000)"
                v-model="errorSnackbar.show"
                multi-line
                color="ies-coral"
        >
            <div class="full-height full-width v-align-container text-xs-center">
                <h4 class="ies-dark-gray--text v-align-div">{{ errorSnackbar.text }}</h4>
            </div>
        </v-snackbar>
    </v-container>
</template>


<script>
    import axios from 'axios';
    import {progressBar} from './../../../../mixins/progressBar';
    import HistoryRecordChanges from './../../../common/RecordHistory/HistoryRecordChanges.vue';
    import HistoryRecordData from './../../../common/RecordHistory/HistoryRecordData.vue';

    export default {
        mixins: [progressBar],
        components: {
            HistoryRecordChanges, HistoryRecordData,
        },
        data() {
            return {
                reference: '',
                history: [],
                errorSnackbar: {
                    show: false,
                    text: 'Error',
                },
            }
        },
        methods: {
            /**
             * Handle find history button
             */
            findHistory(e = false) {
                if (e) {
                    e.preventDefault();
                }

                let validReference = this.getValidReference();

                if (validReference) {
                    this.reference = validReference;
                    this.getData(validReference);
                }
                else {
                    this.showError('invalid-reference');
                }
            },

            /**
             * Pre-validate booking reference, add IES- prefix if not given and return validated booking ref
             * or false if incorrect booking ref given
             * @return false|string
             */
            getValidReference() {
                let valid = false;
                let ref = this.reference.trim();

                // pre-validate if reference given and got minimum length
                if (ref && ref.length > 5 && ref.length < 15) {
                    ref = ref.toUpperCase();

                    // ref have to include at least one dash
                    if (ref.includes('-')) {
                        // check if IES prefix is given
                        if (!ref.includes('IES-')) {
                            ref = 'IES-' + ref;
                        }

                        return ref;
                    }
                }

                return valid;
            },

            /**
             * Sends API get method to our endpoint call appropriate methods to handle result
             */
            getData(reference) {
                this.progressIconShow();
                // clear previous history results if there were any
                this.history = [];

                // call API with get method
                axios.get('api/reservations/history', {
                    params: {
                        reference: reference
                    }
                })
                    .then(function (response) {
                        if (response.status === 200 && response.data) {
                            if (response.data.success) {
                                this.showHistory(response.data.history);
                            }
                            else {
                                this.showError(response.data.error);
                            }
                        }
                        else {
                            this.showError('Error with API connection');
                        }
                    }.bind(this))
                    .catch(function (error) {
                        this.showError('Error with API connection');
                    }.bind(this));
            },

            /**
             * Check if any history record has been found for this reservation, if so display them
             */
            showHistory(history) {
                // check if service found any history for that reservation
                if (history.length) {
                    this.history = history;
                }
                else {
                    this.showError("Service couldn't find history for this reservation");
                }

                this.progressIconHide();
            },

            /**
             * Display error snackbar with given error message
             * @param string msg
             */
            showError(msg) {
                this.errorSnackbar.text = msg;
                this.errorSnackbar.show = true;
                this.progressIconHide();
            }
        },
    }
</script>

<style scoped>
    #searchProgressIcon {
        display: inline-block;
        position: relative;
        top:10px;
    }

</style>
