<template>
    <!-- Send button not available in create mode -->
    <div v-if="field.value">
        <v-btn class="ies-green" 
               @click="sendVoucher()" ><v-icon>send</v-icon> Send Voucher</v-btn>

        <v-snackbar
        :timeout="parseInt(6000)"                          
        v-model="successSingleSnackbar"
        multi-line
        color="ies-green"
        > 
           <div class="full-height full-width v-align-container text-xs-center">
              <h4 class="ies-dark-gray--text v-align-div">Voucher Resent</h4>
           </div>
        </v-snackbar>

        <v-snackbar
        :timeout="parseInt(6000)"                          
        v-model="errorSnackbar"
        multi-line
        color="ies-coral"
        > 
           <div class="full-height full-width v-align-container text-xs-center">
              <h4 class="ies-dark-gray--text v-align-div">Voucher did not send</h4>
           </div>
        </v-snackbar>
    </div>
</template>

<script>

export default {
    name: 'ies-resend-voucher',
    props: ['field'],
    data () {
        return {
            errorSnackbar: false,
            successSingleSnackbar:false
        }
    },
    methods: {
        sendVoucher (){
               if(this.field.value){
                   axios.post('/ajax/resend-voucher',{ voucher_key : this.field.value })
                   .then(function(response) {
                       if (response.data.success) {
                           this.successSingleSnackbar = true;
                       }
                       else {
                           this.errorSnackbar = true;
                       }
                   }.bind(this));
               }
       }
    }
}
</script>


