<template>
    <v-card>
        <v-card-title>
            <span class="subheader">Hotels with outstanding balances</span>
        </v-card-title>
        <v-container fluid>
            <v-layout row>
                <v-flex xs8 lg6 offset-lg1>
                    <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-flex>
                <v-flex xs2 offset-xs2 lg2 offset-lg3>
                    <export-csv
                        filename="OutstandingBalances"
                        :data="filteredItems"
                    ></export-csv>
                </v-flex>
            </v-layout>
        </v-container>
        
        <v-data-table
            :headers="headers"
            :items="listing"
            :search="search"
            :loading="isLoading"
            class="elevation-1"
            ref="table"
        >
            <v-progress-linear slot="progress" color="lime" indeterminate></v-progress-linear>
            <template slot="items" slot-scope="props">
                <td class="text-xs-center">{{ props.item.hotel_code }}</td>
                <td class="text-xs-center">{{ props.item.hotel_name }}</td>
                <td class="text-xs-center">{{ props.item.hotel_type }}</td>
                <td class="text-xs-center">{{ props.item.transactions }}</td>
                <td class="text-xs-center">{{ props.item.invoiced | pounds }}</td>
                <td class="text-xs-center">{{ props.item.payments | pounds }}</td>
                <td class="text-xs-center">{{ props.item.balance | pounds}}</td>
            </template>
            
        </v-data-table>
    </v-card>
</template>

<script>
import EventBus from '@/eventBus';
import ExportCsv from '@/components/common/ExportCSV.vue'

export default {
    components: {
        ExportCsv
    },
    data() {
        return {
            headers: [],
            listing: [],
            filteredItems: [],
            search: '',
            isLoading: false,
            query: {
                type_of_hotel: 'UPF,ARR',
                balance: 'negative,positive',
                date: {
                    from: '2000-01-01', 
                    to: '',
                }
            },
        }
    },
    mounted() {
        this.$watch(
            // Get filtered items from data table
            () => this.$refs.table.filteredItems, (value) => { this.filteredItems = value;}
        )
    },
    created() {
        this.getHeaders();
        this.getListing();
        this.registerListeners();
    },
    watch: {
        listing: function () {
            this.redNegativeBalance();
        },
    },
    methods: {
        /**
         * Get list of hotels with outstanding balances.
         */
        async getListing() {
            this.toggleIsLoading()
            let res = await axios('api/accounts/debtors-hotel', {
                params: {
                    // region:         this.query.region,              // hotel region or parent region
                    dateFrom:       this.query.date.from,           // invoice from date
                    dateTo:         this.query.date.to,             // invoice to date
                    type_of_hotel:  this.query.type_of_hotel,       // upfront, in arrears or fixed fee
                    balance:        this.query.balance              // negative or positive balance only
                }
            })

            this.listing = res.data
            this.toggleIsLoading()
        },
         /**
         * Register all event listeners here to keep created() tidy.
         */
        registerListeners() {
            EventBus.$on('accounts-filter', (query) => {
                this.query  = query
                this.getListing()
            });
        },
        /**
         * Toggle is loading
         */
        toggleIsLoading() {
            this.isLoading = !this.isLoading;
        },
        /**
         * Negative blanaces display red.
         */
        redNegativeBalance() {
            this.listing.forEach((item, index) => {
                if ( item.balance < 0) {
                    this.listing[index].balance = item.balance
                }
            })
        },
        /**
         * Create table headers here so they are out of the way
         */
        getHeaders() {
            this.headers = [
                {
                    text: 'Hotel Code',
                    align: 'center',
                    sortable: true,
                    value: 'hotel_code'
                },
                {
                    text: 'Hotel Name',
                    align: 'center',
                    sortable: true,
                    value: 'hotel_name'
                },
                {
                    text: 'Hotel Type',
                    align: 'center',
                    sortable: true,
                    value: 'hotel_type'
                },
                {
                    text: 'Transactions',
                    align: 'center',
                    sortable: true,
                    value: 'transactions'
                },
                {
                    text: 'Invoiced',
                    align: 'center',
                    sortable: true,
                    value: 'invoiced'
                },
                {
                    text: 'Payments',
                    align: 'center',
                    sortable: true,
                    value: 'payments'
                },
                {
                    text: 'Balance',
                    align: 'center',
                    sortable: true,
                    value: 'balance'
                },
            ]
        }
    }   
}
</script>
