<template>
    <v-container fluid>
        <v-layout row-sm column>
            <v-flex xs12>
                <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
            </v-flex>
            <v-flex xs12 sm6>
                <!--<input :name="this.field.fieldName" type="text" v-model="input" v-on:keyup="updateField()">-->
                <select :name="this.field.fieldName" v-model="input" v-on:change="updateField()" style="width: 200px" :required="this.field.required" :disabled="this.field.disabled">
                    <option disabled value="">Select type of user</option>
                    <option v-for="(value,key) in typesOfUser":value="value">{{ key }}</option>
                </select>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: 'ies-text',
        props: ['field'],
        data () {
            return {
                input: this.field.value,
                typesOfUser : {
                    'Staff User'            : 'staff_user',
                    'General User'          : 'general_user',
                    'Travel operator user'  : 'travel_operator_user',
                    'Contractor'            : 'contractor'
                }
            }
        },
        methods: {
            updateField: function(){
                this.field.value = this.input;
            }

        }
    }
</script>


