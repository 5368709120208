var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-expansion-panel",
        _vm._l(_vm.batchReport, function (title, region) {
          return _c(
            "v-expansion-panel-content",
            { key: title },
            [
              _c("v-data-table", {
                attrs: { headers: _vm.headers(), items: region },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "items",
                      fn: function (props) {
                        return [
                          _c("td", { staticClass: "text-xs-center" }, [
                            _vm._v(_vm._s(props.item.ContactName)),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-xs-center" }, [
                            _vm._v(_vm._s(props.item.InvoiceNumber)),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-xs-center" }, [
                            _vm._v(_vm._s(props.item.TransactionDate)),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-xs-center" }, [
                            _vm._v(_vm._s(props.item.IssuedDate)),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-xs-center" }, [
                            _vm._v(_vm._s(props.item.DueDate)),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-xs-center" }, [
                            _vm._v(_vm._s(props.item.IssuedDate)),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-xs-center" }, [
                            _vm._v(_vm._s(props.item.DueDate)),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-xs-center" }, [
                            _vm._v(_vm._s(props.item.Description)),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-xs-center" }, [
                            _vm._v(_vm._s(props.item.Quantity)),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-xs-center" }, [
                            _vm._v(_vm._s(props.item.NetAmount)),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-xs-center" }, [
                            _vm._v(_vm._s(props.item.GrossAmount)),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-xs-center" }, [
                            _vm._v(_vm._s(props.item.AccountCode)),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-xs-center" }, [
                            _vm._v(_vm._s(props.item.TaxType)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }