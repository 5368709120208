<template>
    <div>
        <v-flex xs12>
            <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
        </v-flex>
            <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            <v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            <v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>
        <!-- list must be list of ['text' => x, 'value' => y] -->
        <v-select
            v-model="viewValue"
            :label="field.schema.displayName"
            :items="options"
            autocomplete
        ></v-select>
        <input type="hidden" :name="field.fieldName" :value="field.value">
        <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
            <li v-for="error in field.errors">
                {{ error }}
            </li>
        </v-alert>
    </div>
</template>

<script>
    /**
     * Allows the setting of payment request's status from a list of
     * human readable options, and through extension any business logic
     * additionally required when this is changed
     */
    export default {
        name: 'ies-paymentRequestStatus',
        props: ['field'],
        data() {
            return {
                options: [],
                viewValue: parseInt(this.field.value),
            }
        },
        created:function() {
            this.setOptions();
            if (!this.field.value){
                this.viewValue = 45;
            }
        },
        methods: {
            setOptions:function(){
                var options = [];
                options.push({ text: 'Payment Due', value: 45 });
                options.push({ text: 'Payment Complete', value: 46 });
                this.options = options;
            },
        },
        watch: {
            viewValue: function (newValue) {
                this.field.value = newValue;
            }
        }
    }
</script>
