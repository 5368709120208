<template>
<v-container fluid grid-list-md style="width: 95%;">
    <!-- main heading -->
    <h1>({{ hotelcode }}) {{ hotelname }} Daily Planner</h1>
    
    <div>Rates are in {{ currencycode }} per room/unit per night{{ taxSummary }}</div>
    <v-layout row wrap>
            <v-flex xs12>

                <!-- table controls -->
                <ies-hotel-planner-table-controls
                    :tableViewOptions="tableViewOptions"
                    :selectedTableViewOptions.sync="selectedTableViewOptions"/>
                                    
                <ies-hotel-planner-month-controls
                    v-if="localResponseData" 
                    :hotelkey="hotelkey" 
                    :rateColumns="rateColumns"
                    :startDate.sync="dateFrom"
                    />
                
                <!-- legend -->
                <div><strong>Legend</strong></div>
                <v-card class="mb-4">
                    <v-flex xs12 class="d-flex justify-space-between">
                        <div><span class="legend-icon room-available">&nbsp;</span> Available</div>
                        <div><span class="legend-icon room-on-request">&nbsp;</span> On Request</div>
                        <div><span class="legend-icon room-unavailable">&nbsp;</span> Unavailable</div>
                        <div><span class="legend-icon selected">&nbsp;</span> Selected for editing</div>
                        <div><span class="legend-icon updated">&nbsp;</span> Updated</div> 
                        <div class="indicative"><span class="legend-icon">&nbsp;</span> Indicative Rate</div>
                    </v-flex>                    
                </v-card>

                <!-- save actions -->
                <v-card>
                    <v-card-actions>
                        <v-btn class="ies-green" @click="saveData()"><v-icon>save</v-icon> Save</v-btn>
                        <v-btn class="yellow" @click="unselectDates()" title="Press 'Esc' to clear date selections" ><v-icon>clear</v-icon> Clear Date Selections</v-btn>
                        <v-btn class="ies-coral" @click="undoChanges()"><v-icon>undo</v-icon> Undo Changes</v-btn>
                        
                        <span class="ml-4"> 
                            <span v-if="isSaving">
                                <v-icon class="spin">sync</v-icon>
                            </span>

                            <span v-if="saveSuccess">
                                <v-icon color="green">done</v-icon>
                                <span>Changes saved successfully</span>
                            </span>

                            <span v-if="saveError" class="ies-coral--text">
                                <v-icon color="ies-coral">error</v-icon>
                                <span>Changes could not be saved: {{ localResponseData.message }}</span>
                            </span>
                        </span>
                    </v-card-actions>
                </v-card>

                <v-flex xs12>
                    Last updated at: {{ lastUpdated.date }} *** by {{ lastUpdated.user }} ***
                </v-flex>
         
                <!-- here is the table -->
                <v-card class="mb-4">
                    <v-container fluid>
                        <v-layout row wrap>
                            <v-flex xs12 class="position-relative overflow-y-auto table-container" style="max-height: 80vh;">
                                    <table class="daily-planner">
                                    <thead>                                                
                                        <th class="header-cell"><strong>{{ getMonthOfYear(dateFrom.getMonth()) }} {{ dateFrom.getFullYear() }} </strong></th>
                                        <template v-for="(day) in calendarDates">
                                            <th v-bind:class="{
                                                'calendar-date': true, 
                                                'weekend': day.dayIndex == 5 || day.dayIndex == 6,
                                                }">
                                                <div v-bind:class="{
                                                    'date-out-of-range': dateIsOutOfRange(day.date),
                                                }">
                                                    <div class="date-text">{{ day.dayString }}</div>
                                                    <div class="date-number">{{ day.dateOfMonth }}</div>
                                                    <div class="date-text">{{ day.monthString }}</div>
                                                </div>
                                            </th>
                                        </template>
                                    </thead>

                                    <tbody>
                                        <template v-for="(roomType) in localTableData.hotelRooms" v-if="selectedTableViewOptions['roomTypeOptions'].includes(roomType.hotelRoomKey) || selectedTableViewOptions['roomTypeOptions'].length == 0">
                                            <!-- availability -->
                                            <ies-hotel-planner-availability-row
                                                @openEditAvailabilityDates="handleOpenEditAvailabilityDates"
                                                @selectAvailabilityDate="handleSelectAvailabilityDate"

                                                :roomType="roomType"
                                                :availabilityPerDateSaved="localResponseData.tableData.hotelRooms[roomType.hotelRoomKey].availabilityPerDate"
                                                :datesWithinRange="datesWithinRange"
                                                :isDisabled="isChannelManaged"
                                                :selectedAvailabilityDates="selectedAvailabilityDates"

                                                :availabilityDateMode.sync="availabilityDateMode"
                                                :editingAvailabilityDates.sync="editingAvailabilityDates"
                                                :selectedRoomType.sync="selectedRoomType"
                                                />

                                            <template v-for="(rateType) in roomType.rateTypes" v-if="selectedTableViewOptions['rateTypeOptions'].includes(rateType.rateTypeKey) || selectedTableViewOptions['rateTypeOptions'].length == 0">
                                                <!-- rate standard -->
                                                <ies-hotel-planner-rate-row
                                                    @openEditRateDates="handleOpenEditRateDates"
                                                    @selectRateDate="handleSelectRateDate"

                                                    :rowHeader="rateType.name"
                                                    :ratesPerDate="rateType.ratesPerDate"
                                                    :ratesPerDateSaved="localResponseData.tableData.hotelRooms[roomType.hotelRoomKey].rateTypes[rateType.rateTypeKey].ratesPerDate"
                                                    :standardOccupancy="roomType.standard_occupancy"
                                                    :isDisabled="isChannelManaged"
                                                    :rateColumn="'rate_standard'"
                                                    :datesWithinRange="datesWithinRange"
                                                    :editingRateDates="editingRateDates"
                                                    :selectedRateDates="selectedRateDates"
                                                    
                                                    :rateDateMode.sync="rateDateMode"
                                                    :selectedRateColumn.sync="selectedRateColumn"
                                                    />
                                                
                                                <!-- rate single -->
                                                <ies-hotel-planner-rate-row
                                                    v-if="selectedTableViewOptions['showSingleRate']"
                                                    @openEditRateDates="handleOpenEditRateDates"
                                                    @selectRateDate="handleSelectRateDate"

                                                    :rowHeader="'Single Rate'"
                                                    :ratesPerDate="rateType.ratesPerDate"
                                                    :ratesPerDateSaved="localResponseData.tableData.hotelRooms[roomType.hotelRoomKey].rateTypes[rateType.rateTypeKey].ratesPerDate"
                                                    :standardOccupancy="roomType.standard_occupancy"
                                                    :isDisabled="isChannelManaged"
                                                    :rateColumn="'rate_single'"
                                                    :datesWithinRange="datesWithinRange"
                                                    :editingRateDates="editingRateDates"
                                                    :selectedRateDates="selectedRateDates"
                                                    
                                                    :rateDateMode.sync="rateDateMode"
                                                    :selectedRateColumn.sync="selectedRateColumn"
                                                    />
                                                
                                                <!-- extra cot -->
                                                <ies-hotel-planner-rate-row
                                                    v-if="selectedTableViewOptions['showExtraCotRate'] && !showUniversalRates"
                                                    @openEditRateDates="handleOpenEditRateDates"
                                                    @selectRateDate="handleSelectRateDate"

                                                    :rowHeader="extraBabyText"
                                                    :ratesPerDate="rateType.ratesPerDate"
                                                    :ratesPerDateSaved="localResponseData.tableData.hotelRooms[roomType.hotelRoomKey].rateTypes[rateType.rateTypeKey].ratesPerDate"
                                                    :standardOccupancy="roomType.standard_occupancy"
                                                    :isDisabled="isChannelManaged"
                                                    :rateColumn="'rate_extra_baby'"
                                                    :datesWithinRange="datesWithinRange"
                                                    :editingRateDates="editingRateDates"
                                                    :selectedRateDates="selectedRateDates"
                                                    
                                                    :rateDateMode.sync="rateDateMode"
                                                    :selectedRateColumn.sync="selectedRateColumn"
                                                    />

                                                <!-- extra child -->
                                                <ies-hotel-planner-rate-row
                                                    v-if="selectedTableViewOptions['showExtraChildBedRate'] && !showUniversalRates"
                                                    @openEditRateDates="handleOpenEditRateDates"
                                                    @selectRateDate="handleSelectRateDate"

                                                    :rowHeader="extraChildText"
                                                    :ratesPerDate="rateType.ratesPerDate"
                                                    :ratesPerDateSaved="localResponseData.tableData.hotelRooms[roomType.hotelRoomKey].rateTypes[rateType.rateTypeKey].ratesPerDate"
                                                    :standardOccupancy="roomType.standard_occupancy"
                                                    :isDisabled="isChannelManaged"
                                                    :rateColumn="'rate_extra_child'"
                                                    :datesWithinRange="datesWithinRange"
                                                    :editingRateDates="editingRateDates"
                                                    :selectedRateDates="selectedRateDates"
                                                    
                                                    :rateDateMode.sync="rateDateMode"
                                                    :selectedRateColumn.sync="selectedRateColumn"
                                                    />
                                                    
                                                <!-- extra oldchild -->
                                                 <ies-hotel-planner-rate-row
                                                    v-if="selectedTableViewOptions['showExtraOldChildRate'] && !showUniversalRates"
                                                    @openEditRateDates="handleOpenEditRateDates"
                                                    @selectRateDate="handleSelectRateDate"

                                                    :rowHeader="extraOldChildText"
                                                    :ratesPerDate="rateType.ratesPerDate"
                                                    :ratesPerDateSaved="localResponseData.tableData.hotelRooms[roomType.hotelRoomKey].rateTypes[rateType.rateTypeKey].ratesPerDate"
                                                    :standardOccupancy="roomType.standard_occupancy"
                                                    :isDisabled="isChannelManaged"
                                                    :rateColumn="'rate_extra_old_child'"
                                                    :datesWithinRange="datesWithinRange"
                                                    :editingRateDates="editingRateDates"
                                                    :selectedRateDates="selectedRateDates"
                                                    
                                                    :rateDateMode.sync="rateDateMode"
                                                    :selectedRateColumn.sync="selectedRateColumn"
                                                    />

                                                <!-- extra adult -->
                                                <ies-hotel-planner-rate-row
                                                    v-if="selectedTableViewOptions['showExtraBedRate'] && !showUniversalRates"
                                                    @openEditRateDates="handleOpenEditRateDates"
                                                    @selectRateDate="handleSelectRateDate"

                                                    :rowHeader="extraAdultText"
                                                    :ratesPerDate="rateType.ratesPerDate"
                                                    :ratesPerDateSaved="localResponseData.tableData.hotelRooms[roomType.hotelRoomKey].rateTypes[rateType.rateTypeKey].ratesPerDate"
                                                    :standardOccupancy="roomType.standard_occupancy"
                                                    :isDisabled="isChannelManaged"
                                                    :rateColumn="'rate_extra_adult'"
                                                    :datesWithinRange="datesWithinRange"
                                                    :editingRateDates="editingRateDates"
                                                    :selectedRateDates="selectedRateDates"
                                                    
                                                    :rateDateMode.sync="rateDateMode"
                                                    :selectedRateColumn.sync="selectedRateColumn"
                                                    />

                                                <!-- minimum stay -->
                                                <ies-hotel-planner-rate-row
                                                    v-if="selectedTableViewOptions['showMinimumStay']"
                                                    @openEditRateDates="handleOpenEditRateDates"
                                                    @selectRateDate="handleSelectRateDate"

                                                    :rowHeader="'Minimum Stay'"
                                                    :ratesPerDate="rateType.ratesPerDate"
                                                    :ratesPerDateSaved="localResponseData.tableData.hotelRooms[roomType.hotelRoomKey].rateTypes[rateType.rateTypeKey].ratesPerDate"
                                                    :standardOccupancy="roomType.standard_occupancy"
                                                    :isDisabled="isChannelManaged"
                                                    :rateColumn="'min_stay'"
                                                    :datesWithinRange="datesWithinRange"
                                                    :editingRateDates="editingRateDates"
                                                    :selectedRateDates="selectedRateDates"
                                                    
                                                    :rateDateMode.sync="rateDateMode"
                                                    :selectedRateColumn.sync="selectedRateColumn"
                                                    />

                                                <!-- maximum stay -->
                                                <ies-hotel-planner-rate-row
                                                    v-if="selectedTableViewOptions['showMaximumStay']"
                                                    @openEditRateDates="handleOpenEditRateDates"
                                                    @selectRateDate="handleSelectRateDate"

                                                    :rowHeader="'Maximum Stay'"
                                                    :ratesPerDate="rateType.ratesPerDate"
                                                    :ratesPerDateSaved="localResponseData.tableData.hotelRooms[roomType.hotelRoomKey].rateTypes[rateType.rateTypeKey].ratesPerDate"
                                                    :standardOccupancy="roomType.standard_occupancy"
                                                    :isDisabled="isChannelManaged"
                                                    :rateColumn="'max_stay'"
                                                    :datesWithinRange="datesWithinRange"
                                                    :editingRateDates="editingRateDates"
                                                    :selectedRateDates="selectedRateDates"

                                                    :rateDateMode.sync="rateDateMode"
                                                    :selectedRateColumn.sync="selectedRateColumn"
                                                    />

                                                <!-- no check in days -->
                                                <ies-hotel-planner-rate-row
                                                    v-if="selectedTableViewOptions['showNoCheckInDays']"
                                                    @openEditRateDates="handleOpenEditRateDates"
                                                    @selectRateDate="handleSelectRateDate"

                                                    :rowHeader="'No Check In Days'"
                                                    :ratesPerDate="rateType.ratesPerDate"
                                                    :ratesPerDateSaved="localResponseData.tableData.hotelRooms[roomType.hotelRoomKey].rateTypes[rateType.rateTypeKey].ratesPerDate"
                                                    :standardOccupancy="roomType.standard_occupancy"
                                                    :isDisabled="isChannelManaged"
                                                    :rateColumn="'days_checkin'"
                                                    :datesWithinRange="datesWithinRange"
                                                    :editingRateDates="editingRateDates"
                                                    :selectedRateDates="selectedRateDates"
                                                    
                                                    :rateDateMode.sync="rateDateMode"
                                                    :selectedRateColumn.sync="selectedRateColumn"
                                                    />

                                                <!-- no check out days -->
                                                <ies-hotel-planner-rate-row
                                                    v-if="selectedTableViewOptions['showNoCheckOutDays']"
                                                    @openEditRateDates="handleOpenEditRateDates"
                                                    @selectRateDate="handleSelectRateDate"

                                                    :rowHeader="'No Check Out Days'"
                                                    :ratesPerDate="rateType.ratesPerDate"
                                                    :ratesPerDateSaved="localResponseData.tableData.hotelRooms[roomType.hotelRoomKey].rateTypes[rateType.rateTypeKey].ratesPerDate"
                                                    :standardOccupancy="roomType.standard_occupancy"
                                                    :isDisabled="isChannelManaged"
                                                    :rateColumn="'days_checkout'"
                                                    :datesWithinRange="datesWithinRange"
                                                    :editingRateDates="editingRateDates"
                                                    :selectedRateDates="selectedRateDates"
                                                    
                                                    :rateDateMode.sync="rateDateMode"
                                                    :selectedRateColumn.sync="selectedRateColumn"
                                                    />
                                            </template>
                                        </template>
                                    </tbody>

                                    <!-- universal rates -->
                                    <tfoot v-if="showUniversalRates" class="sticky-footer" style="position:sticky; bottom: -5px; z-index: 1; background-color: white;">
                                        <tr style="background-color: #d0d0d0;" class="sticky-footer-header">
                                            <td>
                                                Extra Beds / Guests for all room types
                                            </td>

                                            <template v-for="(date) in calendarDates">
                                                <td>
                                                    &nbsp;
                                                </td>
                                            </template>
                                        </tr>

                                        <!-- extra baby -->
                                        <ies-hotel-planner-universal-rate-row
                                            v-if="selectedTableViewOptions['showExtraCotRate']"
                                            @openEditUniversalRates="handleOpenEditUniversalRates"
                                            
                                            :disabled="isChannelManaged"
                                            :rowHeader="extraBabyText"
                                            :rowValue="rateExtraBabyUniversal"
                                            :rateColumn="'extra_baby_rate_amount'"
                                            :datesWithinRange="datesWithinRange"
                                            :calendarDates="calendarDates"
                                            :savedRates="localTableData.universalRates"
                                            :unsavedRates="localResponseData.tableData.universalRates"
                                            :selectedRateColumn.sync="selectedRateColumn"
                                            :openEditUniversalRates.sync="openEditUniversalRates"
                                            />
                                        
                                        <!-- extra child -->
                                        <ies-hotel-planner-universal-rate-row
                                            v-if="selectedTableViewOptions['showExtraChildBedRate']"
                                            @openEditUniversalRates="handleOpenEditUniversalRates"
                                            
                                            :disabled="isChannelManaged"
                                            :rowHeader="extraChildText"
                                            :rowValue="rateExtraChildUniversal"
                                            :rateColumn="'extra_child_rate_amount'"
                                            :datesWithinRange="datesWithinRange"
                                            :calendarDates="calendarDates"
                                            :savedRates="localTableData.universalRates"
                                            :unsavedRates="localResponseData.tableData.universalRates"
                                            :selectedRateColumn.sync="selectedRateColumn"
                                            :openEditUniversalRates.sync="openEditUniversalRates"
                                            />
                                        
                                        <!-- extra oldchild -->
                                        <ies-hotel-planner-universal-rate-row
                                            v-if="selectedTableViewOptions['showExtraOldChildBedRate']"
                                            @openEditUniversalRates="handleOpenEditUniversalRates"
                                            
                                            :disabled="isChannelManaged"
                                            :rowHeader="extraOldChildText"
                                            :rowValue="rateExtraOldchildUniversal"
                                            :rateColumn="'extra_oldchild_rate_amount'"
                                            :datesWithinRange="datesWithinRange"
                                            :calendarDates="calendarDates"
                                            :savedRates="localTableData.universalRates"
                                            :unsavedRates="localResponseData.tableData.universalRates"
                                            :selectedRateColumn.sync="selectedRateColumn"
                                            :openEditUniversalRates.sync="openEditUniversalRates"
                                            />
                                        
                                        <!-- extra adult -->
                                        <ies-hotel-planner-universal-rate-row
                                            v-if="selectedTableViewOptions['showExtraBedRate']"
                                            @openEditUniversalRates="handleOpenEditUniversalRates"
                                            
                                            :disabled="isChannelManaged"
                                            :rowHeader="extraAdultText"
                                            :rowValue="rateExtraAdultUniversal"
                                            :rateColumn="'extra_adult_rate_amount'"
                                            :datesWithinRange="datesWithinRange"
                                            :calendarDates="calendarDates"
                                            :savedRates="localTableData.universalRates"
                                            :unsavedRates="localResponseData.tableData.universalRates"
                                            :selectedRateColumn.sync="selectedRateColumn"
                                            :openEditUniversalRates.sync="openEditUniversalRates"
                                            />
                                    </tfoot>
                                </table>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
        
                <!-- save actions (again) -->
                <v-card>
                    <v-card-actions>
                        <v-btn class="ies-green" @click="saveData()"><v-icon>save</v-icon> Save</v-btn>
                        <v-btn class="yellow" @click="unselectDates()" title="Press 'Esc' to clear date selections" ><v-icon>clear</v-icon> Clear Date Selections</v-btn>
                        <v-btn class="ies-coral" @click="undoChanges()"><v-icon>undo</v-icon> Undo Changes</v-btn>
                        
                        <span class="ml-4"> 
                            <span v-if="isSaving">
                                <v-icon class="spin">sync</v-icon>
                            </span>

                            <span v-if="saveSuccess">
                                <v-icon color="green">done</v-icon>
                                <span>Changes saved successfully</span>
                            </span>

                            <span v-if="saveError" class="ies-coral--text">
                                <v-icon color="ies-coral">error</v-icon>
                                <span>Changes could not be saved: {{ localResponseData.message }}</span>
                            </span>
                        </span>

                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        
        <!-- edit form -->
        <ies-hotel-planner-edit-form
            v-if="showEditForm"

            @closeEditForm="handleCloseEditForm"
            @unselectDates="unselectDates"
            
            :selectedRoomType="selectedRoomType"
            :selectedRateColumn="selectedRateColumn"
            :currencyCode="currencycode"
            :selectedRateDates="selectedRateDates"
            :selectedAvailabilityDates="selectedAvailabilityDates"

            :openEditAvailabilityDates="openEditAvailabilityDates"
            :openEditRateDates="openEditRateDates"
            :openEditUniversalRates="openEditUniversalRates"

            :localTableData.sync="localTableData"
            />
    </v-container>
</template>

<script>
/**
* A Vue component dedicated to letting the user control availability and rates per
* hotel room for a given date range
*
* Props:  hotelkey
*/
import axios from 'axios';

export default {
    name: 'hotel-planner',
    props: ['hotelkey', 'hotelname', 'hotelcode', 'currencycode'],
    data () {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); 
        return {
            // Table data
            currentDate: currentDate,
            dateFrom: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
            localResponseData: [],
            localTableData: [],
            
            // Table view options
            selectedTableViewOptions: {
                roomTypeOptions: [],
                rateTypeOptions: [],
                showStandardRate: true,
                showExtraBedRate: false,
                showExtraChildBedRate: false,
                showExtraOldChildBedRate: false,
                showExtraCotRate: false,
                showSingleRate: false,
                showMinimumStay: false,
                showMaximumStay: false,
                showNoCheckInDays: false,
                showNoCheckOutDays: false
            },
            
            // Table reactiveness
            rateDateMode: false,
            availabilityDateMode: false,
        
            // Editing states
            showEditForm: false,
            selectedRoomType: null,
            openEditAvailabilityDates: false,
            openEditRateDates: false,
            openEditRateMisc: false,
            openEditUniversalRates: false,
            
            selectedRateColumn: 'rate_standard',
            selectedRateDates : [],
            selectedAvailabilityDates : [],
            editingRateDates: [],
            editingAvailabilityDates: [],
            
            
            isSaving: false,
            saveSuccess: false,
            saveError: false
        }
    },

    methods: {
        /**
         * @see data allFacilities
         */
        loadAllData(){
            let fromString = this.dateFrom.getFullYear() + '-' + (this.dateFrom.getMonth() +  1) + '-' + this.dateFrom.getDate();

            axios.get('/api/hotel-planner', {'params': { 'hotelKey': this.hotelkey, 'dateFrom': fromString} } )
                .then(function(response){
                    this.localResponseData = response.data;
                    this.localTableData = JSON.parse(JSON.stringify(response.data.tableData));
                }
                .bind(this));
            },

        undoChanges(){
          this.localTableData = JSON.parse(JSON.stringify(this.localResponseData.tableData));  
        },

        getDayOfWeek(index){
            switch(index){
                case 0: return 'Sun';
                case 1: return 'Mon';
                case 2: return 'Tue';
                case 3: return 'Wed';
                case 4: return 'Thur';
                case 5: return 'Fri';
                case 6: return 'Sat';
            }
            return '?';
        },

        dateIsOutOfRange(date){
            date = new Date(date);
            return date < this.currentDate || date > this.dateUntil;
        },

        getMonthOfYear(index){
            switch(index){
                case 0: return 'Jan';
                case 1: return 'Feb';
                case 2: return 'Mar';
                case 3: return 'Apr';
                case 4: return 'May';
                case 5: return 'Jun';
                case 6: return 'Jul';
                case 7: return 'Aug';
                case 8: return 'Sep';
                case 9: return 'Oct';
                case 10: return 'Nov';
                case 11: return 'Dec';
            }

            return '?';
        },

        saveData(){
            this.saveSuccess = false;
            this.saveError = false;
            this.isSaving = true;
            axios.post('/api/hotel-planner', this.localTableData)
            .then(() => {
                this.loadAllData();
                this.isSaving = false;
                this.showSuccessMessage();
                this.saveError = false;
            }).catch((error) => {
                this.isSaving = false;
                this.showErrorMessage();
                this.saveSuccess = false;
                console.log(error);
            })
        },

        showSuccessMessage(){
            this.saveSuccess = true;
            setTimeout(() => {
                this.saveSuccess = false;
            }, 5000);
        },

        showErrorMessage(){
            this.saveError = true;
            setTimeout(() => {
                this.saveError = false;
            }, 5000);
        },

        handleKeyup(event){
            if (event.key === "Escape") {
                this.unselectDates();
            }
        },

        handleSelectAvailabilityDate(date){
            if (this.availabilityDateMode){
                this.selectedAvailabilityDates.push(date);
            }
        },

        handleSelectRateDate(date){
            if (this.rateDateMode){
                this.selectedRateDates.push(date);
            }
        },

        handleOpenEditAvailabilityDates(){
            this.openEditAvailabilityDates = true;
            this.selectedRateDates = [];
            this.showEditForm = true
        },

        handleOpenEditRateDates(){
            this.openEditRateDates = true;
            this.selectedAvailabilityDates = [];
            this.showEditForm = true
        },

        handleOpenEditUniversalRates(){
            this.openEditUniversalRates = true;
            this.selectedRateDates = [];
            this.selectedAvailabilityDates = [];
            this.showEditForm = true
        },

        handleCloseEditForm(){
            this.showEditForm = false;
            this.openEditRateDates = false;
            this.openEditAvailabilityDates = false;
            this.openEditUniversalRates = false;
        },

        unselectDates(){
            this.selectedRateDates = [];
            this.selectedAvailabilityDates = [];
            this.selectedRateColumn = null;
        },

        handleBeforeUnload(event) {
            if (JSON.stringify(this.localTableData) != JSON.stringify(this.localResponseData.tableData)) {
                event.preventDefault();
                event.returnValue = "";
            }
        },
    },

    /**
     * Once mounted this component needs to load all available models
     */
    mounted() {
        this.loadAllData();
        document.addEventListener('keyup', this.handleKeyup);
        document.addEventListener('mouseup', () => {
            this.availabilityDateMode = false;
            this.rateDateMode = false;
        });

        window.addEventListener('beforeunload', this.handleBeforeUnload);
    },

    computed: {
        dateUntil: function(){
            let dateUntil = new Date(this.dateFrom.getFullYear(), this.dateFrom.getMonth() + 1);
            dateUntil.setDate(dateUntil.getDate() - 1);
            dateUntil.setHours(23, 59, 59);

            return dateUntil;
        },

        formattedDateFrom: function(){
            return this.dateFrom.toLocaleDateString();
        },

        formattedDateUntil: function(){
            return this.dateUntil.toLocaleDateString();
        },

        calendarDates: function(){
            var calendarDates = [];

            for(var dayIndex = 0; dayIndex <= 30; dayIndex++){
                var myDate = new Date( this.dateFrom );
                myDate.setDate(myDate.getDate() + dayIndex);
                var day = {
                    date: myDate,
                    dayIndex: myDate.getDay(),
                    dayString: this.getDayOfWeek( myDate.getDay() ),
                    dateOfMonth: myDate.getDate(),
                    monthString: this.getMonthOfYear( myDate.getMonth() ),
                    };
                calendarDates.push(day);
            }

            return calendarDates;
        },

        datesWithinRange: function(){ 
            return this.calendarDates.filter(day => day.date >= this.currentDate && day.date <= this.dateUntil).map(day => day.date);
        },

        tableViewOptions: function(){
            return this.localResponseData.tableViewOptions || {};
        },

        isChannelManaged: function(){
            return this.localResponseData.isChannelManaged ? true : false;
        },


        lastUpdated: function(){
            return this.localResponseData.lastUpdated || { date: '0000-00-00', user: '' };
        },

        taxSummary : function(){
            return this.localResponseData.taxSummary || '';
        },

        hasUniversalRates: function(){
            return this.localTableData.universalRates ? true : false;
        },

        rateExtraAdultUniversal: function(){
            return this.localTableData.universalRates?.extra_adult_rate_amount;
        },

        rateExtraChildUniversal: function(){
            return this.localTableData.universalRates?.extra_child_rate_amount;
        },

        rateExtraBabyUniversal: function(){
            return this.localTableData.universalRates?.extra_baby_rate_amount;
        },

        rateExtraOldchildUniversal: function(){
            return this.localTableData.universalRates?.extra_oldchild_rate_amount;
        },

        showUniversalRates: function(){
            return this.hasUniversalRates && (
                this.selectedTableViewOptions.showExtraBedRate ||
                this.selectedTableViewOptions.showExtraChildBedRate ||
                this.selectedTableViewOptions.showExtraOldChildBedRate ||
                this.selectedTableViewOptions.showExtraCotRate
            );
        },

        extraBabyText: function(){
            let rateOptions = this.localResponseData?.tableViewOptions?.rateViewOptions;

            if (rateOptions){
                let extraBabyText = rateOptions.find(rateOption => rateOption.field == 'rate_extra_baby');

                return extraBabyText?.text;
            }
        },

        extraChildText: function(){
            let rateOptions = this.localResponseData?.tableViewOptions?.rateViewOptions;
            
            if (rateOptions){
                let extraChildText = rateOptions.find(rateOption => rateOption.field == 'rate_extra_child');
                
                return extraChildText?.text;
            }
        },

        extraOldChildText: function(){
            let rateOptions = this.localResponseData?.tableViewOptions?.rateViewOptions;
            
            if (rateOptions){
                let extraOldChildText = rateOptions.find(rateOption => rateOption.field == 'rate_extra_oldchild');
                
                return extraOldChildText?.text;
            }
        },

        extraAdultText: function(){
            let rateOptions = this.localResponseData?.tableViewOptions?.rateViewOptions;
            
            if (rateOptions){
                let extraAdultText = rateOptions.find(rateOption => rateOption.field == 'rate_extra_adult');
                
                return extraAdultText?.text;
            }
        },

        rateColumns : function(){
            let columns = [];

            this.tableViewOptions.rateViewOptions.forEach(rateOption => {
                if (['rate_standard', 'rate_single'].includes(rateOption.field) || !this.hasUniversalRates){
                    columns.push(rateOption.field);
                } 
            });

            return columns;
        }
    },

    watch: 
        {
        dateFrom: function(){
            this.loadAllData();
        },

        availabilityDateMode: function(){
            if (this.availabilityDateMode){
                this.selectedRateDates = [];
            }
        },

        rateDateMode: function(){
            if (this.rateDateMode){
                this.selectedAvailabilityDates = [];
            }
        },
    }
}
</script>

<style>

table.daily-planner {
    border-collapse: collapse;
    width: 100%;
}

table.daily-planner td {
    min-width: 30px;
}

table.daily-planner thead {
    position: sticky;
    top: -5px; 
    z-index: 1; 
}

table.daily-planner thead th {
    font-weight: bold;
    text-align: center;
    background-color: #f2f2f2;
}

table.daily-planner tbody tr td {
    text-align: center;
}

table.daily-planner td {
    border: 1px solid #c2c2c2;
}

table.daily-planner .header-cell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: sticky;
    left: 0;
    z-index: 1;
    max-width: 200px;
}

.sticky-footer-header {
    border : 1px solid black;
    background-color: #d0d0d0;
}

.weekend {
    background-color: #c2c2c2 !important;
}

.interactive {
    cursor: pointer;
}

.interactive:hover {
    background-color: #CCCCFF;
}

.date-out-of-range {
    opacity: 0.5;
}

.disabled-cell {
  color: white; 
  cursor: not-allowed; 
  pointer-events: none;
  opacity: 0.5;
}

.disabled-rate-cell {
    background-color: black !important;
}

.room-available {
    background-color: #C1CD23;
}
.room-on-request {
    background-color: yellow;
}
.room-unavailable {
    background-color: #F15C61;
}

.availability-not-specified {
    background-color: darkslategray;
}

.rate-for-date, .availability-for-date {
    font-size: 0.75rem;
}
.updated {
    background-color: lightgreen !important;
}

.selected {
    background-color: #CCCCFF !important;
}

.indicative {
    color: purple;
    font-style: italic;
}


.date-text {
    font-size: 0.75rem;
    width: 100%;
    display: block;
}

.date-number {
    font-size: 1.25rem;
    width: 100%;
    display: block;
}

.roomName {
    background-color: orange;
}

.white-out {
    background-color: white;
    opacity: 0.4;
    width: 100%;
    height: 100%;
    position:fixed;
    left:0;
    top:0;
    z-index: 2;
}

.edit-form {
    background-color: white;
    position:fixed;
    left: calc(50vw - 255px);
    top: 30vh;
    z-index: 3;
    padding: 3rem;
    width: 510px;
    border: solid 1px black;
}

.legend-icon {
    width: 20px;
    height: 20px;
    display:inline-block;
}

.spin {
    animation: spin 1s infinite linear;
}

.table-container {
    padding: 0 !important;
    border: solid 1px black;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

</style>
