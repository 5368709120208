var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { md: "6", lg: "4", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("email")]),
              _vm._v(" Email Statistics\n        "),
            ],
            1
          ),
          _vm._v(" "),
          _c("line-chart", {
            attrs: { chartdata: _vm.chartData, options: _vm.chartOptions },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }