var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _c("span", { staticClass: "subheader" }, [
          _vm._v("Transaction summary by hotel contract type"),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "v-card-content",
        [
          _c(
            "v-container",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3", md: "2", "offset-xs1": "" } },
                        [_c("strong", [_vm._v("Hotel Type")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-xs-right",
                          attrs: { cols: "3", md: "2" },
                        },
                        [_c("strong", [_vm._v("Invoices")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-xs-right",
                          attrs: { cols: "3", md: "2" },
                        },
                        [_c("strong", [_vm._v("Payments")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-xs-right",
                          attrs: { cols: "3", md: "2" },
                        },
                        [_c("strong", [_vm._v("Balance")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3", md: "2", "offset-xs1": "" } },
                        [_c("strong", [_vm._v("Upfront")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-xs-right",
                          attrs: { cols: "3", md: "2" },
                        },
                        [
                          _vm._v(
                            "\n                £" +
                              _vm._s(_vm.summary.invoices.upfront.toFixed(2)) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-xs-right",
                          attrs: { cols: "3", md: "2" },
                        },
                        [
                          _vm._v(
                            "\n                £" +
                              _vm._s(_vm.summary.payments.upfront.toFixed(2)) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-xs-right",
                          attrs: { cols: "3", md: "2" },
                        },
                        [
                          _vm._v(
                            "\n                £" +
                              _vm._s(_vm.upfrontBalance.toFixed(2)) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3", "offset-xs1": "" } }, [
                        _c("strong", [_vm._v("In Arrears")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "text-xs-right", attrs: { cols: "3" } },
                        [
                          _vm._v(
                            "\n                £" +
                              _vm._s(_vm.summary.invoices.arrears.toFixed(2)) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "text-xs-right", attrs: { cols: "3" } },
                        [
                          _vm._v(
                            "\n                £" +
                              _vm._s(_vm.summary.payments.arrears.toFixed(2)) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "text-xs-right", attrs: { cols: "3" } },
                        [
                          _vm._v(
                            "\n                £" +
                              _vm._s(_vm.arrearsBalance.toFixed(2)) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3", "offset-xs1": "" } }, [
                        _c("strong", [_vm._v("Annual")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "text-xs-right", attrs: { cols: "3" } },
                        [
                          _vm._v(
                            "\n                £" +
                              _vm._s(_vm.summary.invoices.annual.toFixed(2)) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "text-xs-right", attrs: { cols: "3" } },
                        [
                          _vm._v(
                            "\n                £" +
                              _vm._s(_vm.summary.payments.annual.toFixed(2)) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "text-xs-right", attrs: { cols: "3" } },
                        [
                          _vm._v(
                            "\n                £" +
                              _vm._s(_vm.annualBalance.toFixed(2)) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3", "offset-xs1": "" } }, [
                        _c("strong", [_vm._v("Manual")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "text-xs-right", attrs: { cols: "3" } },
                        [
                          _vm._v(
                            "\n                £" +
                              _vm._s(_vm.summary.invoices.manual.toFixed(2)) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "text-xs-right", attrs: { cols: "3" } },
                        [
                          _vm._v(
                            "\n                £" +
                              _vm._s(_vm.summary.payments.manual.toFixed(2)) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "text-xs-right", attrs: { cols: "3" } },
                        [
                          _vm._v(
                            "\n                £" +
                              _vm._s(_vm.manualBalance.toFixed(2)) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3", "offset-xs1": "" } }, [
                        _c("strong", [_vm._v("Total")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "text-xs-right", attrs: { cols: "3" } },
                        [
                          _vm._v(
                            "\n                £" +
                              _vm._s(_vm.totalInvoices.toFixed(2)) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "text-xs-right", attrs: { cols: "3" } },
                        [
                          _vm._v(
                            "\n                £" +
                              _vm._s(_vm.totalPayments.toFixed(2)) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "text-xs-right", attrs: { cols: "3" } },
                        [
                          _vm._v(
                            "\n                £" +
                              _vm._s(_vm.totalBalance.toFixed(2)) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }