<template>
    <v-container>
        <v-flex xs12 v-if="label && label !== ''">
            <strong>{{ label }}</strong>
        </v-flex>
        <v-flex xs12>
            {{ text }}
        </v-flex>
    </v-container>
</template>

<script>
    export default {
        name: 'ies-noteditable',
        props: ['label', 'text'],
    }
</script>
