import Vue from 'vue';
import Vuex from 'vuex';

// import store modules
import quickMenu from './modules/quickMenu';
import tinyMCE from './modules/tinyMCE';
import loggedUser from './modules/loggedUser';

// load vuex to vue
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        quickMenu,
        tinyMCE,
        loggedUser,
    }
});
