<template>
<v-card>
    <v-card-title>
        <span class="subheader">Transaction summary by hotel contract type</span>
    </v-card-title>
    <v-card-content>

    <v-container>
        <!-- Headers -->
        <v-col cols="12">
        <v-layout row>
            <v-col cols="3" md="2" offset-xs1>
                <strong>Hotel Type</strong>
            </v-col>
            <v-col cols="3" md="2" class="text-xs-right">
                <strong>Invoices</strong>
            </v-col>
            <v-col cols="3" md="2" class="text-xs-right">
                <strong>Payments</strong>
            </v-col>
            <v-col cols="3" md="2" class="text-xs-right">
                <strong>Balance</strong>
            </v-col>
        </v-layout>
        </v-col>

        <!-- Upfront -->
        <v-col cols="12">
        <v-layout row>
            <v-col cols="3" md="2" offset-xs1>
                <strong>Upfront</strong>
            </v-col>
            <v-col cols="3" md="2" class="text-xs-right">
                £{{ summary.invoices.upfront.toFixed(2) }}
            </v-col>
            <v-col cols="3" md="2" class="text-xs-right">
                £{{ summary.payments.upfront.toFixed(2) }}
            </v-col>
            <v-col cols="3" md="2" class="text-xs-right">
                £{{ upfrontBalance.toFixed(2) }}
            </v-col>
        </v-layout>
        </v-col>

        <!-- Arrears -->
        <v-col cols="12">
        <v-layout row>
            <v-col cols="3" offset-xs1>
                <strong>In Arrears</strong>
            </v-col>
            <v-col cols="3" class="text-xs-right">
                £{{ summary.invoices.arrears.toFixed(2) }}
            </v-col>
            <v-col cols="3" class="text-xs-right">
                £{{ summary.payments.arrears.toFixed(2) }}
            </v-col>
            <v-col cols="3" class="text-xs-right">
                £{{ arrearsBalance.toFixed(2) }}
            </v-col>
        </v-layout>
        </v-col>

        <v-divider></v-divider>

        <!-- Annual -->
        <v-col cols="12">
        <v-layout row>
            <v-col cols="3" offset-xs1>
                <strong>Annual</strong>
            </v-col>
             <v-col cols="3" class="text-xs-right">
                £{{ summary.invoices.annual.toFixed(2) }}
            </v-col>
            <v-col cols="3" class="text-xs-right">
                £{{ summary.payments.annual.toFixed(2) }}
            </v-col>
            <v-col cols="3" class="text-xs-right">
                £{{ annualBalance.toFixed(2) }}
            </v-col>
        </v-layout>
        </v-col>

        <v-col cols="12">
        <v-layout row>
            <v-col cols="3" offset-xs1>
                <strong>Manual</strong>
            </v-col>
            <v-col cols="3" class="text-xs-right">
                £{{ summary.invoices.manual.toFixed(2) }}
            </v-col>
            <v-col cols="3" class="text-xs-right">
                £{{ summary.payments.manual.toFixed(2) }}
            </v-col>
            <v-col cols="3" class="text-xs-right">
                £{{ manualBalance.toFixed(2) }}
            </v-col>
        </v-layout>
        </v-col>

        <v-divider></v-divider>

        <!-- Total -->
        <v-col cols="12">
        <v-layout row>
            <v-col cols="3" offset-xs1>
                <strong>Total</strong>
            </v-col>
            <v-col cols="3" class="text-xs-right">
                £{{ totalInvoices.toFixed(2) }}
            </v-col>
            <v-col cols="3" class="text-xs-right">
                £{{ totalPayments.toFixed(2) }}
            </v-col>
            <v-col cols="3" class="text-xs-right">
                £{{ totalBalance.toFixed(2) }}
            </v-col>
        </v-layout>
        </v-col>

    </v-container>
    </v-card-content>
</v-card>
</template>

<script>
import EventBus from '@/eventBus'

export default {
    data() {
        return {
            summary: {
                invoices: {
                    upfront: 0,
                    arrears: 0,
                    annual: 0,
                    manual: 0,
                },
                payments: {
                    upfront: 0,
                    arrears: 0,
                    annual: 0,
                    manual: 0,
                }
            },
            query: {
                type_of_hotel: 'UPF,ARR,FIX',
                balance: 'negative,positive',
                date: {
                    from: '2011-01-01', 
                    to: '',
                }
            }
        }
    },
    created() {
        this.getSummary()
    },
    computed: {
        upfrontBalance: function() {
            return this.summary.invoices.upfront - this.summary.payments.upfront;
        },
        arrearsBalance: function() {
            return this.summary.invoices.arrears - this.summary.payments.arrears;
        },
        annualBalance: function() {
            return this.summary.invoices.annual - this.summary.payments.annual;
        },
        manualBalance: function() {
            return this.summary.invoices.manual - this.summary.payments.manual;
        },
        totalInvoices: function() {
            return this.summary.invoices.total;
        },
        totalPayments: function() {
            return this.summary.payments.total;
        },
        totalBalance: function() {
            return this.summary.invoices.total - this.summary.payments.total;
        }
    },
    methods: {
        async getSummary() {
            const res = await axios('api/accounts/invoice-type-summary', {
                params: {
                    dateFrom:       this.query.date.from,           // invoice from date
                    dateTo:         this.query.date.to,             // invoice to date
                    type_of_hotel:  this.query.type_of_hotel,       // upfront, in arrears or fixed fee
                    balance:        this.query.balance              // negative or positive balance only
                }
            });
            this.summary = res.data;
        },
        // TODO - this don't work!!
        redNegative() {
            for (let type in this.summary) {
                for (let hotelType in this.summary[type]) {
                    // Negative numbers are red.
                    if (type !== 'transactions') {
                        if (this.summary[type][hotelType] < 0) {
                            this.summary[type][hotelType] = `<span style="color: red;"> £ ${this.summary[type][hotelType]} </span>`
                        } else {
                            // this.summary[type][hotelType] = `${this.summary[type][hotelType]}`
                        }
                    }
                }
            }
        },
    }
    
}
</script>
