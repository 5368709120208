<template>
    <v-flex>
        <v-btn v-on:click="resend"><v-icon>send</v-icon> Resend</v-btn>
    </v-flex>
</template>

<script>
/**
 * Triggers a request to the server to update this room type
 */
export default {
    name: 'ies-resend-sent-email',
    props: ['field', 'value'],
    data () {
        return {
        }
    },

    methods: {
        resend: function(){
            var url = "/api/messaging/email/resend-sent?id=" + this.value;
            axios.get(url)
            .then(function(response){
                console.log(response);
                alert("Done");
            }.bind(this));
        },

    }
}
</script>
