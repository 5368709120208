var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "6", lg: "4", "ma-1": "" } },
    [
      _c(
        "v-card",
        { staticClass: "ma-2" },
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("sync")]),
              _vm._v(" Channel Management\n      "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { block: "", href: "channel-managers/list" },
                },
                [_c("v-icon", [_vm._v("sync")]), _vm._v(" Channel Managers")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { block: "", href: "channel-managed-hotels/list" },
                },
                [
                  _c("v-icon", [_vm._v("home")]),
                  _vm._v(" Channel Managed Hotels"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: {
                    block: "",
                    href: "channel-managed-hotels-on-request/list",
                  },
                },
                [_vm._v("Channel Managed Hotels (On Request)")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: {
                    block: "",
                    href: "channel-managed-hotels-no-recent-updates/list",
                  },
                },
                [
                  _c("v-icon", [_vm._v("warning")]),
                  _vm._v(" No Recent Updates"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { block: "", href: "owner-managed-hotels/list" },
                },
                [_vm._v("Owner Managed Hotels")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: {
                    block: "",
                    href: "owner-managed-hotels-on-request/list",
                  },
                },
                [_vm._v("Owner Managed Hotels (On Request)")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { block: "", href: "channel-manager-size/report" },
                },
                [
                  _c("v-icon", [_vm._v("show_chart")]),
                  _vm._v(" No. Hotels per Channel"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { block: "", href: "booking-status/report" },
                },
                [
                  _c("v-icon", [_vm._v("show_chart")]),
                  _vm._v(" No. Instant vs Request"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { block: "", href: "incoming-ical-feeds/list" },
                },
                [_vm._v("iCal")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { block: "", href: "ical-connection-types/list" },
                },
                [_vm._v("Supported iCal Feed Types")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }