<template>
    <v-container fluid>
       <v-layout row-sm column>
	    <v-col v-if="this.field.schema.displayName != 'none'" cols="12">
    	    <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
    	</v-col>
         <v-col cols="12" v-if="this.field.introduction">{{ this.field.introduction }}</v-col>
            <v-alert type="info"  dismissable value="true" v-if="this.field.information">{{ this.field.information }}</v-alert>
            <v-alert type="warning"  dismissable value="true" v-if="this.field.warning">{{ this.field.warning }}</v-alert>
            <v-alert type="error" dismissable value="true" v-if="this.field.error">{{ this.field.error }}</v-alert>
    	<v-col cols="12">
        <!-- list must be list of ['text' => x, 'value' => y] -->
        <v-select
            v-model="viewValue"
            :items="transferTypes"
            autocomplete
        ></v-select>
        <input type="hidden" :name="field.fieldName" :text="field.text" :value="field.value">
        <v-alert type="error" :value="field.errors" style="width: 100%" v-if="field.errors">
            <li v-for="error in field.errors" v-bind:key="error">
                {{ error }}
            </li>
        </v-alert>
    </v-col>
    </v-layout>
  </v-container>
</template>


<script>
    /**
     * Allows the selection of a voucher type ID by choosing its name from
     * a selection downloaded from the API on creation
     */
    export default {
        name: 'ies-airportTransferType',
        props: ['field'],
        data() {
            return {
                transferTypes: [],
                viewValue: parseInt(this.field.value),
            }
        },
        created:function() {
            this.getTransferTypes();
        },
        methods: {
            getTransferTypes:function(){
                axios.get('/api/airport-transfer-types')
                .then(function(response){
                    console.log(response);
                    var transferTypes = [];
                    response.data.types.forEach(function(transferType){
                        transferTypes.push({ text: transferType.airport_transfer, value: transferType.airport_transfer_type_key });
                    });
                    this.transferTypes = transferTypes;
                }.bind(this));
            },
        },
        watch: {
            viewValue: function (newValue) {
                this.field.value = newValue;
            }
        }
    }
</script>
