var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "justify-center": "" } },
        [
          _c(
            "v-flex",
            { staticClass: "quickMenuWrapper", attrs: { xs12: "", md4: "" } },
            [
              _c("div", { staticClass: "quickMenuHead toolbar__content" }, [
                _c("div", { staticClass: "list__tile" }, [
                  _vm._v("Quick Menu Builder"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-expansion-panel",
                { attrs: { expand: "" } },
                [
                  _vm._l(_vm.menu, function (mainItem, i) {
                    return _c(
                      "v-expansion-panel-content",
                      { key: i },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [
                            _c("div", { staticClass: "list__tile" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "itemSortWrapper",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.sortUp(mainItem, i)
                                        },
                                      },
                                    },
                                    [_vm._v("keyboard_arrow_up")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-icon",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.sortDown(mainItem, i)
                                        },
                                      },
                                    },
                                    [_vm._v("keyboard_arrow_down")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "list__tile__action" },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v(" " + _vm._s(mainItem.icon) + " "),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "list__tile__content" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "list__tile__title" },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(mainItem.name) +
                                          "\n                                    "
                                      ),
                                      _vm.canEdit
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-3 itemEdit",
                                              attrs: { small: "" },
                                              on: {
                                                click: [
                                                  function ($event) {
                                                    return _vm.editItem(
                                                      mainItem,
                                                      i
                                                    )
                                                  },
                                                  function ($event) {
                                                    $event.stopPropagation()
                                                  },
                                                ],
                                              },
                                            },
                                            [_vm._v("edit")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-card",
                          { staticClass: "subItemsTiles" },
                          [
                            _vm._l(mainItem.subItems, function (subItem, k) {
                              return mainItem.subItems.length
                                ? _c(
                                    "div",
                                    {
                                      key: k,
                                      staticClass: "list__tile",
                                      attrs: { "hide-actions": "" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "itemSortWrapper",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.sortUp(subItem, k)
                                                },
                                              },
                                            },
                                            [_vm._v("keyboard_arrow_up")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-icon",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.sortDown(
                                                    subItem,
                                                    k
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("keyboard_arrow_down")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "list__tile__action" },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [
                                              _vm._v(
                                                " " + _vm._s(subItem.icon) + " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "list__tile__content" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "list__tile__title",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(subItem.name) +
                                                  "\n                                    "
                                              ),
                                              _vm.canEdit
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "ml-3 itemEdit",
                                                      attrs: { small: "" },
                                                      on: {
                                                        click: [
                                                          function ($event) {
                                                            return _vm.editItem(
                                                              subItem,
                                                              k
                                                            )
                                                          },
                                                          function ($event) {
                                                            $event.stopPropagation()
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    [_vm._v("edit")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e()
                            }),
                            _vm._v(" "),
                            _vm.canEdit
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "addNewWrapper",
                                    on: {
                                      click: function ($event) {
                                        return _vm.addNewItemDialog(2, mainItem)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { staticClass: "ml-3 itemEdit" },
                                      [_vm._v("add_circle_outline")]
                                    ),
                                    _vm._v(
                                      " Add new item\n                        "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _vm.canEdit
                    ? _c(
                        "div",
                        {
                          staticClass: "addNewWrapper",
                          on: {
                            click: function ($event) {
                              return _vm.addNewItemDialog(1)
                            },
                          },
                        },
                        [
                          _c("v-icon", { staticClass: "ml-3 itemEdit" }, [
                            _vm._v("add_circle_outline"),
                          ]),
                          _vm._v(" Add new item\n                "),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.canEdit
        ? _c(
            "v-btn",
            {
              staticClass: "ies-green",
              attrs: { large: "", fixed: "", bottom: "", right: "" },
              on: { click: _vm.saveChanges },
            },
            [_vm._v("SAVE")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "900px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Edit menu item"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _vm.editableItem.hasOwnProperty("item")
                        ? _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "", "mr-1": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Text" },
                                    model: {
                                      value: _vm.editableItem.item.name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editableItem.item,
                                          "name",
                                          $$v
                                        )
                                      },
                                      expression: "editableItem.item.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "", "mr-1": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Icon" },
                                    model: {
                                      value: _vm.editableItem.item.icon,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editableItem.item,
                                          "icon",
                                          $$v
                                        )
                                      },
                                      expression: "editableItem.item.icon",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", sm2: "", "ml-3": "" } },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "https://material.io/resources/icons/?style=baseline",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Available icons\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs3: "" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              attrs: { label: "Link type" },
                                              model: {
                                                value:
                                                  _vm.editableItem.item.link
                                                    .type,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editableItem.item.link,
                                                    "type",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editableItem.item.link.type",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "laravel route",
                                                  value:
                                                    this.consts.TYPE_ROUTE_NAME,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "relative path",
                                                  value:
                                                    this.consts
                                                      .TYPE_RELATIVE_PATH,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs4: "", "mr-3": "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label:
                                                _vm.editableItem.item.link
                                                  .type ==
                                                this.consts.TYPE_ROUTE_NAME
                                                  ? "Laravel route name"
                                                  : "Relative path",
                                              hint:
                                                _vm.editableItem.item.link
                                                  .type ==
                                                this.consts.TYPE_ROUTE_NAME
                                                  ? "Laravel route name format"
                                                  : "Relative path must starts with /",
                                            },
                                            model: {
                                              value:
                                                _vm.editableItem.item.link
                                                  .route,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editableItem.item.link,
                                                  "route",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editableItem.item.link.route",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.editableItem.item.link.type ==
                                      this.consts.TYPE_ROUTE_NAME
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs3: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Route parameters",
                                                  hint: "Route params comma delimited, eg.: Reservation, cancelled",
                                                },
                                                model: {
                                                  value:
                                                    _vm.editableItem.item.link
                                                      .params,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editableItem.item
                                                        .link,
                                                      "params",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editableItem.item.link.params",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "", "mr-1": "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: "Permission Content Type",
                                      items: _vm.permissions,
                                      "item-text": "name",
                                      "item-value": "portal_content_type_key",
                                      autocomplete: "",
                                    },
                                    model: {
                                      value:
                                        _vm.editableItem.item
                                          .portal_content_type_key,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editableItem.item,
                                          "portal_content_type_key",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editableItem.item.portal_content_type_key",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-layout",
                        {
                          staticClass: "margin-top-medium",
                          attrs: { row: "" },
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "", "mt-3": "" } },
                            [
                              _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "header" },
                                          slot: "header",
                                        },
                                        [_vm._v("Delete item")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: { row: "", wrap: "" },
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs12: "" } },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    Do you really want to delete this menu item?\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs12: "" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "error",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.deleteItem,
                                                          },
                                                        },
                                                        [_vm._v("Yes, delete")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        "mt-3": "",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                    Remember you still need to save the edit page to update your changes!\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", flat: "" },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v("Close")]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.editableItem.hasOwnProperty("isNew") &&
                  _vm.editableItem.isNew
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", flat: "" },
                          on: { click: _vm.addNewItem },
                        },
                        [_vm._v("Add")]
                      )
                    : _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", flat: "" },
                          on: { click: _vm.updateEdited },
                        },
                        [_vm._v("Update")]
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: parseInt(6000),
            "multi-line": "",
            color: _vm.snackbar.color,
          },
          model: {
            value: _vm.snackbar.show,
            callback: function ($$v) {
              _vm.$set(_vm.snackbar, "show", $$v)
            },
            expression: "snackbar.show",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "full-height full-width v-align-container text-xs-center",
            },
            [
              _c("h4", { staticClass: "ies-dark-gray--text v-align-div" }, [
                _vm._v(_vm._s(this.snackbar.text)),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }