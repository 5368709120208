var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-card",
            {
              staticClass: "mt-3 mb-3 no-print button-bar",
              attrs: { xs12: "" },
            },
            [
              _c(
                "v-container",
                { staticClass: "pa-3", attrs: { fluid: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs2: "", "mr-3": "" } }, [
                        _c("h1", [_vm._v(_vm._s(_vm.title))]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs1: "" } },
                        [
                          _c(
                            "v-btn",
                            { on: { click: _vm.toggleVoucherType } },
                            [_vm._v(_vm._s(_vm.voucherBtnText))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("input", {
        attrs: {
          type: _vm.formInput.voucher_type.displayType,
          name: _vm.formInput.voucher_type.fieldName,
        },
        domProps: { value: _vm.formInput.voucher_type.value },
      }),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "justify-center": "", "mt-3": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", sm12: "", md4: "", "mr-5": "" } },
            [
              _c("input-wrapper", {
                attrs: { field: _vm.formInput.purchaser_email },
              }),
              _vm._v(" "),
              _c("input-wrapper", {
                attrs: { field: _vm.formInput.recipient_email },
              }),
              _vm._v(" "),
              _c("input-wrapper", { attrs: { field: _vm.formInput.value } }),
              _vm._v(" "),
              _c("input-wrapper", {
                attrs: { field: _vm.formInput.is_promotional },
              }),
              _vm._v(" "),
              _c("input-wrapper", { attrs: { field: _vm.formInput.purpose } }),
              _vm._v(" "),
              _c("input-wrapper", { attrs: { field: _vm.formInput.notes } }),
              _vm._v(" "),
              _c("input-wrapper", { attrs: { field: _vm.formInput.message } }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.formInput.voucher_type.value == 1
            ? _c(
                "v-flex",
                { attrs: { xs12: "", sm12: "", md4: "" } },
                [
                  _c("input-wrapper", {
                    attrs: { field: _vm.formInput.quantity },
                  }),
                  _vm._v(" "),
                  _c("input-wrapper", {
                    attrs: { field: _vm.formInput.address_1 },
                  }),
                  _vm._v(" "),
                  _c("input-wrapper", {
                    attrs: { field: _vm.formInput.address_2 },
                  }),
                  _vm._v(" "),
                  _c("input-wrapper", { attrs: { field: _vm.formInput.city } }),
                  _vm._v(" "),
                  _c("input-wrapper", {
                    attrs: { field: _vm.formInput.postcode },
                  }),
                  _vm._v(" "),
                  _c("input-wrapper", {
                    attrs: { field: _vm.formInput.country },
                  }),
                  _vm._v(" "),
                  _c("label", [_vm._v("Postage:")]),
                  _vm._v(" "),
                  _c("label", [_vm._v("UK")]),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { name: "delivery", type: "radio", value: "1" },
                    domProps: { checked: _vm.old.delivery == 1 },
                  }),
                  _vm._v(" "),
                  _c("label", [_vm._v("Europe")]),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { name: "delivery", type: "radio", value: "2" },
                    domProps: { checked: _vm.old.delivery == 2 },
                  }),
                  _vm._v(" "),
                  _c("label", [_vm._v("World Wide")]),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { name: "delivery", type: "radio", value: "3" },
                    domProps: { checked: _vm.old.delivery == 3 },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.formInput.voucher_type.value == 2
            ? _c(
                "v-flex",
                { attrs: { xs12: "", sm12: "", md4: "" } },
                [
                  _c("input-wrapper", {
                    attrs: { field: _vm.formInput.to_text },
                  }),
                  _vm._v(" "),
                  _c("input-wrapper", {
                    attrs: { field: _vm.formInput.from_text },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "justify-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", sm12: "", md4: "" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ies-green",
                  attrs: { type: "submit", block: "" },
                },
                [_vm._v("Make Voucher")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }