var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12" } },
    [
      _c(
        "v-btn",
        { staticClass: "mt-1", attrs: { href: _vm.reservationsUrl } },
        [_c("v-icon", [_vm._v("hotel")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        { staticClass: "mt-1", attrs: { href: _vm.editUrl } },
        [_c("v-icon", [_vm._v("edit")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        { staticClass: "mt-1", attrs: { href: _vm.facilitiesUrl } },
        [_c("v-icon", [_vm._v("pool")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        { staticClass: "mt-1", attrs: { href: _vm.imageLibraryUrl } },
        [_c("v-icon", [_vm._v("insert_photo")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        { staticClass: "mt-1", attrs: { href: _vm.cloneRateDatesUrl } },
        [_c("v-icon", [_vm._v("content_copy")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        { staticClass: "mt-1", attrs: { href: _vm.dailyPlannerUrl } },
        [_c("v-icon", [_vm._v("edit_calendar")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        { staticClass: "mt-1", attrs: { href: _vm.viewLiveUrl } },
        [_c("v-icon", [_vm._v("web")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "mt-1",
          attrs: { href: _vm.reservationsUrl, disabled: "" },
        },
        [_c("v-icon", [_vm._v("hotel")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "mt-1",
          attrs: { href: _vm.reservationsUrl, disabled: "" },
        },
        [_c("v-icon", [_vm._v("hotel")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }