<template>
    <v-container fluid grid-list-md>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                     <v-card-text>
                         <v-container fluid v-if="reservation.show || 1">
                              <v-layout row wrap v-if="reservation.hotel">
                                  <v-flex xs4><strong>Property</strong></v-flex>
                                  <v-flex xs8>{{ reservation.hotel.hotel_name }}</v-flex>

                                  <v-flex xs4><strong>Client</strong></v-flex>
                                  <v-flex xs8>{{ reservation.user.first_name }} {{ reservation.user.last_name }}</v-flex>

                                  <v-flex xs4><strong>Status</strong></v-flex>
                                  <v-flex xs8>{{ statusName(reservation.status_key) }}</v-flex>

                                  <v-flex xs4><strong>Enquiry</strong></v-flex>
                                  <v-flex xs8>{{ reservation.last_action_update_date }} {{ reservation.last_action_update_time }}</v-flex>

                                  <v-flex xs4><strong>Arriving</strong></v-flex>
                                  <v-flex xs8>{{ reservation.date_arrival }}</v-flex>

                                  <v-flex xs4><strong>Leaving</strong></v-flex>
                                  <v-flex xs8>{{ reservation.date_departure }}</v-flex>

                                  <v-flex xs4><strong>Stay Length</strong></v-flex>
                                  <v-flex xs8>{{ reservation.stay_length }}</v-flex>

                                  <v-flex xs4><strong>Total Guests</strong></v-flex>
                                  <v-flex xs8>{{ reservation.adults }} adults{{ reservation.children ? reservation.children + ', children' : '' }}{{ reservation.will_bring_pets ? ', + dog' : '' }}</v-flex>

                                  <v-flex xs4><strong>Rooms Requested</strong></v-flex>
                                  <v-flex xs8>
                                      <div v-if="reservation.units" v-for="(unit, index) in reservation.units">
                                          {{ unit.room.name }} (
                                              {{ unit.occ_adults ? unit.occ_adults + ' adults' : '' }}, 
                                              {{ unit.occ_children ? unit.occ_children + ' young children' : '' }}, 
                                              {{ unit.occ_oldchildren ? unit.occ_oldchildren + ' older children' : '' }}, 
                                              {{ unit.occ_babies ? unit.occ_babies + ' babies' : '' }}
                                          )
                                          {{ unit.rate_type ? unit.rate_type.name : 'No rate type specified' }}
                                      </div>
                                  </v-flex>

                                  <v-flex xs4><strong>Arrival Time</strong></v-flex>
                                  <v-flex xs8>{{ reservation.estimated_arrival }}</v-flex>

                              </v-layout>
                          </v-container>
                      </v-card-text>
                 </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    /**
     * A Vue component dedicated to letting the user manage a reservations
     * by the ID specified
     *
     * Props:
     *     int 'reservationkey' - The ID of the reservation to display
     */

    import axios from 'axios';
    export default {
        name: 'hotel-reservation-enquiry',
        props: ['reservationkey'],
        data () {
            return {
                reservation: {},
            }
        },
        methods: {

            loadReservation(){
console.log(this.reservationkey);
                axios.get('/api/reservations/id/' + this.reservationkey)
                .then(function(response){
                    this.reservation = response.data.reservation;
console.log("RESERVATION overview: ", this.reservation);
                }.bind(this));
            },

            statusName(){
                switch(this.reservation.status_key){
                    case 8: return "New Enquiry";
                    case 9: return "Completed";
                    case 10: return "Re-enquiry";
                    case 11: return "Availability Confirmed";
                    case 12: return "Reservation Confirmed";
                    case 13: return "Quote Accepted";
                    case 14: return "Quote Expired";
                    case 15: return "Quote Declined";
                    case 16: return "No Availability";
                    case 17: return "New Cancellation";
                    case 18: return "Cancellation Confirmed";
                    case 19: return "Declination Confirmed";
                    case 20: return "Alternative Offered";
                }
                return "Unknown";
            }
        },

        /**
         * Once mounted this component needs to load the reservation specified
         */
        mounted() {
            this.loadReservation();
        },

        computed: {}
    }
</script>
