var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "row-sm": "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-card-title",
                    [_c("v-icon", [_vm._v("search")]), _vm._v(" Search")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-expansion-panels",
                        {
                          attrs: { multiple: "" },
                          model: {
                            value: _vm.openedSearchPanels,
                            callback: function ($$v) {
                              _vm.openedSearchPanels = $$v
                            },
                            expression: "openedSearchPanels",
                          },
                        },
                        [
                          _c(
                            "v-expansion-panel",
                            [
                              _c("v-expansion-panel-header", [
                                _c("b", [_vm._v("Relationships")]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panel-content",
                                { attrs: { ripple: "" } },
                                [
                                  _c(
                                    "fieldset",
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("ies-hotel-and-room-key", {
                                                attrs: {
                                                  field: _vm.searchByRoomField,
                                                  configuration:
                                                    '{"shouldEmitOnSelect":true, "selectHotelEventName":"Image Browser: search by hotel", "selectRoomEventName": "Image Browser: search by room type"}',
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  label: "Region",
                                                  items: _vm.regions,
                                                  autocomplete: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.searchFilters
                                                      .region_key,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.searchFilters,
                                                      "region_key",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "searchFilters.region_key",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel",
                            {
                              model: {
                                value: _vm.panel,
                                callback: function ($$v) {
                                  _vm.panel = $$v
                                },
                                expression: "panel",
                              },
                            },
                            [
                              _c("v-expansion-panel-header", [
                                _c("b", [_vm._v("Properties")]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panel-content",
                                { attrs: { ripple: "" } },
                                [
                                  _c(
                                    "fieldset",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticStyle: { margin: "10px" },
                                        attrs: { label: "ID" },
                                        on: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.performSearch.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.searchFilters.imageKey,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchFilters,
                                              "imageKey",
                                              $$v
                                            )
                                          },
                                          expression: "searchFilters.imageKey",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        staticStyle: { margin: "10px" },
                                        attrs: { label: "Min Width (px)" },
                                        on: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.performSearch.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.searchFilters.minWidth,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchFilters,
                                              "minWidth",
                                              $$v
                                            )
                                          },
                                          expression: "searchFilters.minWidth",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        staticStyle: { margin: "10px" },
                                        attrs: { label: "Min Height (px)" },
                                        on: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.performSearch.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.searchFilters.minHeight,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchFilters,
                                              "minHeight",
                                              $$v
                                            )
                                          },
                                          expression: "searchFilters.minHeight",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        staticStyle: { margin: "10px" },
                                        attrs: { label: "Ratio (W:H)" },
                                        on: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.performSearch.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.searchFilters.ratio,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchFilters,
                                              "ratio",
                                              $$v
                                            )
                                          },
                                          expression: "searchFilters.ratio",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { mandatory: false },
                                          model: {
                                            value:
                                              _vm.searchFilters.displayType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.searchFilters,
                                                "displayType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "searchFilters.displayType",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              color: "lime",
                                              label: "Any",
                                              value: "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("v-radio", {
                                            attrs: {
                                              color: "lime",
                                              label: "Display only",
                                              value: "1",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("v-radio", {
                                            attrs: {
                                              color: "lime",
                                              label: "Hidden only",
                                              value: "0",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.imageTypes,
                                          label: "Select Type of Image",
                                          "item-text": "text",
                                          "item-value": "value",
                                          dense: "",
                                          id: "image-type",
                                        },
                                        model: {
                                          value: _vm.searchFilters.imageType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchFilters,
                                              "imageType",
                                              $$v
                                            )
                                          },
                                          expression: "searchFilters.imageType",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ies-green",
                          on: { click: _vm.performSearch },
                        },
                        [_c("v-icon", [_vm._v("search")]), _vm._v(" Search")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { staticClass: "mt-3", attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "pa-1" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled:
                          _vm.selectedImages.images.length ===
                          _vm.libraryImages.length,
                      },
                      on: { click: _vm.selectAll },
                    },
                    [_vm._v("Select All")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.selectedImages.images.length === 0,
                      },
                      on: { click: _vm.deselectAll },
                    },
                    [_vm._v("Deselect All")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ies-green",
                      attrs: {
                        disabled: _vm.selectedImages.images.length === 0,
                      },
                      on: { click: _vm.openImageSelector },
                    },
                    [
                      _vm._v(
                        "Edit Selected" +
                          _vm._s(
                            _vm.selectedImages.images.length
                              ? " (" + _vm.selectedImages.images.length + ")"
                              : ""
                          )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.selectedImages.images.length === 0,
                      },
                      on: { click: _vm.openImageSuitability },
                    },
                    [_vm._v("Check Suitability")]
                  ),
                  _vm._v(" "),
                  _c("v-btn", { on: { click: _vm.refreshLibraryImages } }, [
                    _vm._v("Refresh Results"),
                  ]),
                  _vm._v(" "),
                  _c("v-btn", { on: { click: _vm.clearAllFilters } }, [
                    _vm._v("Clear All Filters"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "red",
                      attrs: {
                        disabled: _vm.selectedImages.images.length === 0,
                      },
                      on: { click: _vm.deleteSelected },
                    },
                    [_vm._v("Delete Selected")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ies-green",
                      attrs: { disabled: _vm.displayMode != "inline" },
                      on: { click: _vm.saveInlineChanges },
                    },
                    [_c("v-icon", [_vm._v("save")]), _vm._v(" Save Changes")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("a", { attrs: { name: "topOptionsBar", id: "topOptionsBar" } }),
          _vm._v(" "),
          _vm.showSticky
            ? _c(
                "v-flex",
                { staticClass: "mt-3 sticky", attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-1" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled:
                              _vm.selectedImages.images.length ===
                              _vm.libraryImages.length,
                          },
                          on: { click: _vm.selectAll },
                        },
                        [_vm._v("Select All")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.selectedImages.images.length === 0,
                          },
                          on: { click: _vm.deselectAll },
                        },
                        [_vm._v("Deselect All")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ies-green",
                          attrs: {
                            disabled: _vm.selectedImages.images.length === 0,
                          },
                          on: { click: _vm.openImageSelector },
                        },
                        [
                          _vm._v(
                            "Edit Selected" +
                              _vm._s(
                                _vm.selectedImages.images.length
                                  ? " (" +
                                      _vm.selectedImages.images.length +
                                      ")"
                                  : ""
                              )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.selectedImages.images.length === 0,
                          },
                          on: { click: _vm.openImageSuitability },
                        },
                        [_vm._v("Check Suitability")]
                      ),
                      _vm._v(" "),
                      _c("v-btn", { on: { click: _vm.refreshLibraryImages } }, [
                        _vm._v("Refresh Results"),
                      ]),
                      _vm._v(" "),
                      _c("v-btn", { on: { click: _vm.clearAllFilters } }, [
                        _vm._v("Clear All Filters"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "red",
                          attrs: {
                            disabled: _vm.selectedImages.images.length === 0,
                          },
                          on: { click: _vm.deleteSelected },
                        },
                        [_vm._v("Delete Selected")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ies-green",
                          attrs: { disabled: _vm.displayMode != "inline" },
                          on: { click: _vm.saveInlineChanges },
                        },
                        [
                          _c("v-icon", [_vm._v("save")]),
                          _vm._v(" Save Changes"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-flex",
            { staticClass: "mt-4 white", attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", "align-center": "" } },
                [
                  _c("v-flex", [
                    _c("strong", [_vm._v("Total:")]),
                    _vm._v(
                      " " + _vm._s(_vm.totalItems) + " Items\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "" },
                          on: {
                            click: function ($event) {
                              return _vm.goToFirstPage()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("first_page")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "" },
                          on: {
                            click: function ($event) {
                              return _vm.goToPrevPage()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("navigate_before")])],
                        1
                      ),
                      _vm._v(
                        "\n\n                Page " +
                          _vm._s(_vm.pageNumber + 1) +
                          " of " +
                          _vm._s(_vm.totalPages) +
                          "\n\n                "
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "" },
                          on: {
                            click: function ($event) {
                              return _vm.goToNextPage()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("navigate_next")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "" },
                          on: {
                            click: function ($event) {
                              return _vm.goToLastPage()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("last_page")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    [
                      _c("strong", { staticClass: "text-left" }, [
                        _vm._v("Rows per Page:"),
                      ]),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          items: _vm.rowsPerPageOpts,
                          label: "",
                          "item-value": "value",
                          "single-line": "",
                          "justify-end": "",
                        },
                        model: {
                          value: _vm.chosenRowsPerPage,
                          callback: function ($$v) {
                            _vm.chosenRowsPerPage = $$v
                          },
                          expression: "chosenRowsPerPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.message
            ? _c("span", { attrs: { "align-center": "" } }, [
                _vm._v(_vm._s(_vm.message) + " "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.displayWhiteOut
            ? _c("v-flex", { staticClass: "white-out", attrs: { xs12: "" } }, [
                _vm._v(" "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.libraryImages, function (libraryImage, image_key) {
            return _c(
              "v-flex",
              { key: image_key, staticClass: "mt-3", attrs: { xs2: "" } },
              [
                _c(
                  "v-card",
                  {
                    staticClass: "pa-3",
                    class: { selected: libraryImage.is_selected },
                    staticStyle: { height: "100%" },
                  },
                  [
                    _c(
                      "v-container",
                      { attrs: { flex: "" } },
                      [
                        _c(
                          "v-layout",
                          { attrs: { row: "", wrap: "" } },
                          [
                            _c("v-flex", { attrs: { xs9: "" } }, [
                              _c("b", [_vm._v("ID:")]),
                              _vm._v(
                                " " +
                                  _vm._s(libraryImage.image_key) +
                                  "\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-flex",
                              {
                                staticStyle: { "text-align": "right" },
                                attrs: { xs3: "" },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: libraryImage.image_source,
                                      download: "",
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("download")])],
                                  1
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: libraryImage.is_selected,
                                      expression: "libraryImage.is_selected",
                                    },
                                  ],
                                  staticClass: "selection-checkbox",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      libraryImage.is_selected
                                    )
                                      ? _vm._i(libraryImage.is_selected, null) >
                                        -1
                                      : libraryImage.is_selected,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                    change: [
                                      function ($event) {
                                        var $$a = libraryImage.is_selected,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                libraryImage,
                                                "is_selected",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                libraryImage,
                                                "is_selected",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            libraryImage,
                                            "is_selected",
                                            $$c
                                          )
                                        }
                                      },
                                      function ($event) {
                                        return _vm.selectLibraryImage(
                                          libraryImage
                                        )
                                      },
                                    ],
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("v-flex", { attrs: { xs12: "" } }, [
                              _c("img", {
                                staticClass: "thumbnail",
                                class: {
                                  "image-landscape":
                                    libraryImage.original_x >
                                    libraryImage.original_y,
                                },
                                attrs: {
                                  alt: "Photo library image not found",
                                  src: libraryImage.image_source,
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            libraryImage.hotel
                              ? _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("b", [_vm._v("Hotel:")]),
                                  _vm._v(
                                    " (" +
                                      _vm._s(libraryImage.hotel.hotel_code) +
                                      ") " +
                                      _vm._s(libraryImage.hotel.hotel_name) +
                                      "\n                "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("v-flex", { attrs: { xs12: "" } }, [
                              _c("b", [_vm._v("Display:")]),
                              _vm._v(" "),
                              _vm.displayMode != "inline"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        libraryImage.display_slideshow
                                          ? "Yes"
                                          : "No"
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.displayMode == "inline"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: libraryImage.display_slideshow,
                                        expression:
                                          "libraryImage.display_slideshow",
                                      },
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(
                                        libraryImage.display_slideshow
                                      )
                                        ? _vm._i(
                                            libraryImage.display_slideshow,
                                            null
                                          ) > -1
                                        : libraryImage.display_slideshow,
                                    },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$a =
                                              libraryImage.display_slideshow,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  libraryImage,
                                                  "display_slideshow",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  libraryImage,
                                                  "display_slideshow",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              libraryImage,
                                              "display_slideshow",
                                              $$c
                                            )
                                          }
                                        },
                                        function ($event) {
                                          return _vm.inlineImageEdited(
                                            libraryImage
                                          )
                                        },
                                      ],
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("b", [_vm._v("Type:")]),
                              _vm._v(" "),
                              _vm.displayMode != "inline"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        typeof libraryImage.image_type ===
                                          undefined ||
                                          libraryImage.image_type == null ||
                                          typeof libraryImage.image_type
                                            .display_name_short === undefined
                                          ? null
                                          : libraryImage.image_type
                                              .display_name_short
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.displayMode == "inline"
                                ? _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: libraryImage.image_type_key,
                                          expression:
                                            "libraryImage.image_type_key",
                                        },
                                      ],
                                      staticClass: "inline-image-type",
                                      attrs: {
                                        items: _vm.imageTypes,
                                        "item-text": "textShort",
                                        "item-value": "value",
                                        dense: "",
                                        id: "image-type",
                                      },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              libraryImage,
                                              "image_type_key",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          function ($event) {
                                            return _vm.inlineImageEdited(
                                              libraryImage
                                            )
                                          },
                                        ],
                                      },
                                    },
                                    _vm._l(
                                      _vm.imageTypes,
                                      function (imageType) {
                                        return _c(
                                          "option",
                                          {
                                            domProps: {
                                              value: imageType.value,
                                            },
                                          },
                                          [_vm._v(_vm._s(imageType.textShort))]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("b", [_vm._v("Order:")]),
                              _vm._v(" "),
                              _vm.displayMode != "inline"
                                ? _c("span", [
                                    _vm._v(
                                      " " + _vm._s(libraryImage.sort_order)
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.displayMode == "inline"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: libraryImage.sort_order,
                                        expression: "libraryImage.sort_order",
                                      },
                                    ],
                                    staticClass: "inline-sort-order",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: libraryImage.sort_order,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.inlineImageEdited(
                                          libraryImage
                                        )
                                      },
                                      keyup: function ($event) {
                                        return _vm.inlineImageEdited(
                                          libraryImage
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          libraryImage,
                                          "sort_order",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            libraryImage.hotel_room
                              ? _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("b", [_vm._v("RT:")]),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "/hotel-rooms/" +
                                          libraryImage.hotel_room
                                            .hotel_room_key,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          libraryImage.hotel_room.name.length <
                                            28
                                            ? libraryImage.hotel_room.name
                                            : libraryImage.hotel_room.name.slice(
                                                0,
                                                25
                                              ) + "..."
                                        )
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            libraryImage.caption || _vm.displayMode == "inline"
                              ? _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("b", [_vm._v("Caption:")]),
                                  _vm._v(" "),
                                  _vm.displayMode != "inline"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            libraryImage.caption.length < 53
                                              ? libraryImage.caption
                                              : libraryImage.caption.slice(
                                                  0,
                                                  50
                                                ) + "..."
                                          ) + " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.displayMode == "inline"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: libraryImage.caption,
                                            expression: "libraryImage.caption",
                                          },
                                        ],
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: libraryImage.caption,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.inlineImageEdited(
                                              libraryImage
                                            )
                                          },
                                          keyup: function ($event) {
                                            return _vm.inlineImageEdited(
                                              libraryImage
                                            )
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              libraryImage,
                                              "caption",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            libraryImage.copyright_info ||
                            _vm.displayMode == "inline"
                              ? _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("b", [_vm._v("Copyright:")]),
                                  _vm._v(" "),
                                  _vm.displayMode != "inline"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            libraryImage.copyright_info.length <
                                              53
                                              ? libraryImage.copyright_info
                                              : libraryImage.copyright_info.slice(
                                                  0,
                                                  50
                                                ) + "..."
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.displayMode == "inline"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: libraryImage.copyright_info,
                                            expression:
                                              "libraryImage.copyright_info",
                                          },
                                        ],
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: libraryImage.copyright_info,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.inlineImageEdited(
                                              libraryImage
                                            )
                                          },
                                          keyup: function ($event) {
                                            return _vm.inlineImageEdited(
                                              libraryImage
                                            )
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              libraryImage,
                                              "copyright_info",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            libraryImage.description ||
                            _vm.displayMode == "inline"
                              ? _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("b", [_vm._v("Description:")]),
                                  _vm._v(" "),
                                  _vm.displayMode != "inline"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            libraryImage.description.length < 53
                                              ? libraryImage.description
                                              : libraryImage.description.slice(
                                                  0,
                                                  50
                                                ) + "..."
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.displayMode == "inline"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: libraryImage.description,
                                            expression:
                                              "libraryImage.description",
                                          },
                                        ],
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: libraryImage.description,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.inlineImageEdited(
                                              libraryImage
                                            )
                                          },
                                          keyup: function ($event) {
                                            return _vm.inlineImageEdited(
                                              libraryImage
                                            )
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              libraryImage,
                                              "description",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("v-flex", { attrs: { xs12: "" } }, [
                              _c("b", [_vm._v("Date:")]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.dateYYYYMMDD(libraryImage.creation_date)
                                  ) +
                                  "\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("v-flex", { attrs: { xs12: "" } }, [
                              _c("b", [_vm._v("Pixels:")]),
                              _vm._v(
                                " " +
                                  _vm._s(libraryImage.original_x) +
                                  "x" +
                                  _vm._s(libraryImage.original_y) +
                                  " (" +
                                  _vm._s(
                                    _vm.imageRatio(
                                      libraryImage.original_x,
                                      libraryImage.original_y
                                    )
                                  ) +
                                  ")\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("v-flex", { attrs: { xs12: "" } }, [
                              libraryImage.image_keywords &&
                              libraryImage.image_keywords.length
                                ? _c(
                                    "span",
                                    _vm._l(
                                      libraryImage.image_keywords,
                                      function (tag, index) {
                                        return _c(
                                          "span",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  title: tag.keyword.name,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(tag.keyword.icon_name)
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm.displayImageSuitability
            ? _c(
                "v-flex",
                { staticClass: "modal-80", attrs: { xs12: "" } },
                [
                  _c(
                    "span",
                    { staticClass: "close-button" },
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.closeImageSuitability()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("cancel")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { "row-sm": "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-container",
                                    { attrs: { fluid: "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { "row-sm": "", wrap: "" } },
                                        [
                                          _c("h1", [
                                            _vm._v("Image Suitability"),
                                          ]),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.selectedImages.images,
                                            function (
                                              selectedImage,
                                              image_key
                                            ) {
                                              return _c(
                                                "v-flex",
                                                {
                                                  key: image_key,
                                                  attrs: { xs12: "" },
                                                },
                                                [
                                                  _c("h2", [
                                                    _vm._v(
                                                      _vm._s(
                                                        selectedImage.image_key
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _vm._v(
                                                      "Full size: " +
                                                        _vm._s(
                                                          selectedImage.original_x
                                                        ) +
                                                        " x " +
                                                        _vm._s(
                                                          selectedImage.original_y
                                                        ) +
                                                        "px (" +
                                                        _vm._s(
                                                          _vm.imageRatio(
                                                            selectedImage.original_x,
                                                            selectedImage.original_y
                                                          )
                                                        ) +
                                                        ") " +
                                                        _vm._s(
                                                          _vm
                                                            .imageRatio(
                                                              selectedImage.original_x,
                                                              selectedImage.original_y
                                                            )
                                                            .includes("~")
                                                            ? "This ratio is approximate; exercise caution when using the image in a page where the image ratio matters"
                                                            : ""
                                                        )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _vm._v(
                                                      "Home Double (795x395px) " +
                                                        _vm._s(
                                                          selectedImage.original_x <
                                                            795 ||
                                                            selectedImage.original_y <
                                                              395
                                                            ? "Warning: This image may not be large enough"
                                                            : "Ok"
                                                        )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _vm._v(
                                                      "Home Single (395x395px) " +
                                                        _vm._s(
                                                          selectedImage.original_x <
                                                            395 ||
                                                            selectedImage.original_y <
                                                              395
                                                            ? "Warning: This image may not be large enough"
                                                            : "Ok"
                                                        )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _vm._v(
                                                      "Desktop Hero (1900x600px) " +
                                                        _vm._s(
                                                          selectedImage.original_x <
                                                            1900 ||
                                                            selectedImage.original_y <
                                                              600
                                                            ? "Warning: This image may not be large enough for desktop screens"
                                                            : "Ok"
                                                        )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _vm._v(
                                                      "PTS Hotel Thumbnail (467x300px) " +
                                                        _vm._s(
                                                          selectedImage.original_x <
                                                            467 ||
                                                            selectedImage.original_y <
                                                              300
                                                            ? "Warning: This image may not be large enough"
                                                            : "Ok"
                                                        )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _vm._v(
                                                      "PTS Footer (395x395px) " +
                                                        _vm._s(
                                                          selectedImage.original_x <
                                                            395 ||
                                                            selectedImage.original_y <
                                                              395
                                                            ? "Warning: This image may not be large enough"
                                                            : "Ok"
                                                        )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-expansion-panels",
                                                    {
                                                      attrs: { multiple: "" },
                                                      model: {
                                                        value:
                                                          _vm.openedSuitabilityPanels,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.openedSuitabilityPanels =
                                                            $$v
                                                        },
                                                        expression:
                                                          "openedSuitabilityPanels",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-expansion-panel",
                                                        [
                                                          _c(
                                                            "v-expansion-panel-header",
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  "More Details"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-expansion-panel-content",
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    row: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "h3",
                                                                        {
                                                                          staticClass:
                                                                            "mt-5",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Hero Image:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "1900x600px " +
                                                                              _vm._s(
                                                                                selectedImage.original_x <
                                                                                  1900 ||
                                                                                  selectedImage.original_y <
                                                                                    600
                                                                                  ? "Warning: This image may not be large enough for desktop screens"
                                                                                  : "Ok"
                                                                              )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "Note that the actual size of the hero image will vary according to the screen size, and the design of the page it is displayed on"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "hero-container",
                                                                          style:
                                                                            {
                                                                              "background-image":
                                                                                "url(" +
                                                                                selectedImage.image_source +
                                                                                ")",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "hero-text",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Lorem Ipsum Dolor Sit Amet"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "12",
                                                                        sm: "6",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "h3",
                                                                        {
                                                                          staticClass:
                                                                            "mt-5",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "PTS Thumbnail:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "467x300px " +
                                                                              _vm._s(
                                                                                selectedImage.original_x <
                                                                                  467 ||
                                                                                  selectedImage.original_y <
                                                                                    300
                                                                                  ? "Warning: This image may not be large enough"
                                                                                  : "Ok"
                                                                              )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "pts-thumbnail",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "hotel-listing--thumb_notice",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "20% OFF"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "pts-prev",
                                                                              attrs:
                                                                                {
                                                                                  src: "/images/thumbnail-left-arrow.png",
                                                                                  alt: "Lef chevron",
                                                                                  width:
                                                                                    "30px",
                                                                                  height:
                                                                                    "48px",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "pts-next",
                                                                              attrs:
                                                                                {
                                                                                  src: "/images/thumbnail-right-arrow.png",
                                                                                  alt: "Right chevron",
                                                                                  width:
                                                                                    "30px",
                                                                                  height:
                                                                                    "48px",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "pts-thumbnail-image",
                                                                              attrs:
                                                                                {
                                                                                  alt: "Image from photo library",
                                                                                  src: selectedImage.image_source,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "12",
                                                                        sm: "6",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "h3",
                                                                        {
                                                                          staticClass:
                                                                            "mt-5",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "PTS Footer Links:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "395x395px " +
                                                                              _vm._s(
                                                                                selectedImage.original_x <
                                                                                  395 ||
                                                                                  selectedImage.original_y <
                                                                                    395
                                                                                  ? "Warning: This image may not be large enough"
                                                                                  : "Ok"
                                                                              )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "pts-footer-container",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "pts-footer-text",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Insiders' Guide:"
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                "Lorem Ipsum"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "pts-footer-image",
                                                                              attrs:
                                                                                {
                                                                                  alt: "Image from photo library",
                                                                                  src: selectedImage.image_source,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "h3",
                                                                        {
                                                                          staticClass:
                                                                            "mt-5",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Home Page Tiles:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "Double: 795x395px " +
                                                                              _vm._s(
                                                                                selectedImage.original_x <
                                                                                  795 ||
                                                                                  selectedImage.original_y <
                                                                                    395
                                                                                  ? "Warning: This image may not be large enough"
                                                                                  : "Ok"
                                                                              )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "Single: 395x395px " +
                                                                              _vm._s(
                                                                                selectedImage.original_x <
                                                                                  395 ||
                                                                                  selectedImage.original_y <
                                                                                    395
                                                                                  ? "Warning: This image may not be large enough"
                                                                                  : "Ok"
                                                                              )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "tile-double-container",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "tile-double-text",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "consectetur adipiscing elit"
                                                                                  ),
                                                                                  _c(
                                                                                    "br"
                                                                                  ),
                                                                                  _vm._v(
                                                                                    "sed do eiusmod"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "img",
                                                                                {
                                                                                  staticClass:
                                                                                    "tile-double-image",
                                                                                  attrs:
                                                                                    {
                                                                                      alt: "Image from photo library",
                                                                                      src: selectedImage.image_source,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "tile-single-container",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "tile-single-text",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Lorem Ipsum Dolor"
                                                                                  ),
                                                                                  _c(
                                                                                    "br"
                                                                                  ),
                                                                                  _vm._v(
                                                                                    "Sit Amet"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "img",
                                                                                {
                                                                                  staticClass:
                                                                                    "tile-single-image",
                                                                                  attrs:
                                                                                    {
                                                                                      alt: "Image from photo library",
                                                                                      src: selectedImage.image_source,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("hr"),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.closeImageSuitability()
                                },
                              },
                            },
                            [_vm._v("Close")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayImageEditor
            ? _c(
                "v-flex",
                { staticClass: "modal-80", attrs: { xs12: "" } },
                [
                  _c(
                    "span",
                    { staticClass: "close-button" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { depressed: "" },
                          on: {
                            click: function ($event) {
                              return _vm.closeImageSelector()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("cancel")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { "row-sm": "", wrap: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-container",
                                    { attrs: { fluid: "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { "row-sm": "", wrap: "" } },
                                        _vm._l(
                                          _vm.selectedImages.images,
                                          function (selectedImage, image_key) {
                                            return _c(
                                              "v-flex",
                                              {
                                                key: image_key,
                                                attrs: { xs1: "" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      selectedImage.image_key
                                                    ) +
                                                    "\n                                    "
                                                ),
                                                _c("img", {
                                                  staticStyle: {
                                                    "max-height": "150px",
                                                  },
                                                  attrs: {
                                                    alt: "Image from photo library",
                                                    src: selectedImage.image_source,
                                                  },
                                                }),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _c("b", { staticStyle: { color: "red" } }, [
                                  _vm._v("Note:"),
                                ]),
                                _vm._v(" "),
                                _c("u", [_vm._v("All")]),
                                _vm._v(
                                  " selected images will be given the properties below.  Their original values will "
                                ),
                                _c("u", [_vm._v("not")]),
                                _vm._v(
                                  " be preserved if you leave the fields blank.\n                    "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _c(
                                  "fieldset",
                                  [
                                    _c("legend", [_vm._v("Common Properties")]),
                                    _vm._v(" "),
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.imageTypes,
                                        label: "Select Type of Image",
                                        "item-text": "text",
                                        "item-value": "value",
                                        dense: "",
                                        id: "image-type",
                                      },
                                      model: {
                                        value: _vm.selectedImages.imageType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.selectedImages,
                                            "imageType",
                                            $$v
                                          )
                                        },
                                        expression: "selectedImages.imageType",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Image Caption:",
                                            name: "caption",
                                          },
                                          model: {
                                            value: _vm.selectedImages.caption,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.selectedImages,
                                                "caption",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "selectedImages.caption",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("b", [_vm._v("Copyright:")]),
                                        _vm._v(" "),
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Copyright Information:",
                                            name: "copyright",
                                          },
                                          model: {
                                            value:
                                              _vm.selectedImages.copyright_info,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.selectedImages,
                                                "copyright_info",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "selectedImages.copyright_info",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-flex", { attrs: { xs12: "" } }, [
                                      _c("b", [_vm._v("Description:")]),
                                      _vm._v(" "),
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.selectedImages.description,
                                            expression:
                                              "selectedImages.description",
                                          },
                                        ],
                                        staticClass: "image-description",
                                        attrs: {
                                          name: "description",
                                          type: "text",
                                        },
                                        domProps: {
                                          value: _vm.selectedImages.description,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.selectedImages,
                                              "description",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "fieldset",
                                  [
                                    _c("legend", [_vm._v("Slideshows")]),
                                    _vm._v(" "),
                                    _c("v-flex", { attrs: { xs12: "" } }, [
                                      _c("label", [
                                        _vm._v("Display in Slideshows: "),
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.selectedImages
                                                .display_slideshow,
                                            expression:
                                              "selectedImages.display_slideshow",
                                          },
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.selectedImages.display_slideshow
                                          )
                                            ? _vm._i(
                                                _vm.selectedImages
                                                  .display_slideshow,
                                                null
                                              ) > -1
                                            : _vm.selectedImages
                                                .display_slideshow,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.selectedImages
                                                  .display_slideshow,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.selectedImages,
                                                    "display_slideshow",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.selectedImages,
                                                    "display_slideshow",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.selectedImages,
                                                "display_slideshow",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Sort Order in Slideshows:",
                                            name: "sort_order",
                                          },
                                          model: {
                                            value:
                                              _vm.selectedImages.sort_order,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.selectedImages,
                                                "sort_order",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "selectedImages.sort_order",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticStyle: { display: "none" },
                                  attrs: { cols: "3" },
                                },
                                [
                                  _c(
                                    "fieldset",
                                    [
                                      _c("legend", [_vm._v("Image Tags")]),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.selectedImages.imageKeywordOptions,
                                        function (tag, index) {
                                          return _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: tag.isTicked,
                                                    expression: "tag.isTicked",
                                                  },
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    tag.isTicked
                                                  )
                                                    ? _vm._i(
                                                        tag.isTicked,
                                                        null
                                                      ) > -1
                                                    : tag.isTicked,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a = tag.isTicked,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            tag,
                                                            "isTicked",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            tag,
                                                            "isTicked",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        tag,
                                                        "isTicked",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("label", [
                                                _vm._v(_vm._s(tag.label)),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c(
                                          "a",
                                          { attrs: { href: "/keywords/list" } },
                                          [_vm._v("Manage Keywords")]
                                        ),
                                      ]),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "5" } }, [
                                _c(
                                  "fieldset",
                                  [
                                    _c(
                                      "v-layout",
                                      { attrs: { row: "", wrap: "" } },
                                      [
                                        _c("legend", [_vm._v("Relationships")]),
                                        _vm._v(" "),
                                        _c("ies-hotel-key", {
                                          attrs: {
                                            field: _vm.selectHotelField,
                                            configuration:
                                              '{"shouldEmitOnSelect":true, "selectEventName":"Image Browser: select hotel"}',
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("ies-hotel-room-key", {
                                          attrs: {
                                            field: _vm.selectRoomField,
                                            configuration:
                                              '{"shouldEmitOnSelect":true, "selectEventName":"Image Browser: select room type"}',
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("v-select", {
                                          attrs: {
                                            label: "Region",
                                            items: _vm.regions,
                                            autocomplete: "",
                                          },
                                          model: {
                                            value:
                                              _vm.selectedImages.region_key,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.selectedImages,
                                                "region_key",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "selectedImages.region_key",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ies-green",
                                      on: { click: _vm.saveChanges },
                                    },
                                    [_vm._v("Save Changes")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "red",
                                      on: { click: _vm.deleteSelected },
                                    },
                                    [_vm._v("Delete Selected")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    { on: { click: _vm.closeImageSelector } },
                                    [_vm._v("Cancel")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.totalItems >= 1
            ? _c(
                "v-flex",
                { staticClass: "mt-4 white", attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", "align-center": "" } },
                    [
                      _c("v-flex", [
                        _c("strong", [_vm._v("Total:")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.totalItems) +
                            " Items\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { depressed: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToFirstPage()
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("first_page")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { depressed: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToPrevPage()
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("navigate_before")])],
                            1
                          ),
                          _vm._v(
                            "\n\n                            Page " +
                              _vm._s(_vm.pageNumber + 1) +
                              " of " +
                              _vm._s(_vm.totalPages) +
                              "\n\n                            "
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { depressed: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToNextPage()
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("navigate_next")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { depressed: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToLastPage()
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("last_page")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        [
                          _c("strong", { staticClass: "text-left" }, [
                            _vm._v("Images per Page:"),
                          ]),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              items: _vm.rowsPerPageOpts,
                              label: "",
                              "item-value": "value",
                              "single-line": "",
                              "justify-end": "",
                            },
                            model: {
                              value: _vm.chosenRowsPerPage,
                              callback: function ($$v) {
                                _vm.chosenRowsPerPage = $$v
                              },
                              expression: "chosenRowsPerPage",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-flex",
            { staticClass: "mt-2", attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled:
                          _vm.selectedImages.images.length ===
                          _vm.libraryImages.length,
                      },
                      on: { click: _vm.selectAll },
                    },
                    [_vm._v("Select All")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.selectedImages.images.length === 0,
                      },
                      on: { click: _vm.deselectAll },
                    },
                    [_vm._v("Deselect All")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ies-green",
                      attrs: {
                        disabled: _vm.selectedImages.images.length === 0,
                      },
                      on: { click: _vm.openImageSelector },
                    },
                    [
                      _vm._v(
                        "Edit Selected" +
                          _vm._s(
                            _vm.selectedImages.images.length
                              ? " (" + _vm.selectedImages.images.length + ")"
                              : ""
                          )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.selectedImages.images.length === 0,
                      },
                      on: { click: _vm.openImageSuitability },
                    },
                    [_vm._v("Check Suitability")]
                  ),
                  _vm._v(" "),
                  _c("v-btn", { on: { click: _vm.refreshLibraryImages } }, [
                    _vm._v("Refresh Results"),
                  ]),
                  _vm._v(" "),
                  _c("v-btn", { on: { click: _vm.clearAllFilters } }, [
                    _vm._v("Clear All Filters"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "red",
                      attrs: {
                        disabled: _vm.selectedImages.images.length === 0,
                      },
                      on: { click: _vm.deleteSelected },
                    },
                    [_vm._v("Delete Selected")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ies-green",
                      attrs: { disabled: _vm.displayMode != "inline" },
                      on: { click: _vm.saveInlineChanges },
                    },
                    [_c("v-icon", [_vm._v("save")]), _vm._v(" Save Changes")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }