<template>
    <div>
        <div>
            <strong>Month Selector</strong>
        </div>
        
        <v-card class="mb-2 d-flex quick-select">
            <v-select
                class="pr-2 pl-2"
                :items="yearOptions"
                v-model="selectedYear"
                label="Year"
                item-text="text"
                item-value="value"
                >
            </v-select>
        
            <v-select
                class="pr-2 pl-2"
                :items="monthOptions"
                v-model="selectedMonth"
                label="Month"
                item-text="text"
                item-value="value"
                >
            </v-select>
        
            <!-- submit -->
            <v-btn @click="setSelectedDateByForm()" class="lime mt-2 ml-2 mr-4">Go</v-btn>
        </v-card>
        
        <v-card class="mb-4">
            <div class="month-controls">
                <v-btn @click="subMonthFromSelectedDate()" style="height:auto;">
                    <v-icon>keyboard_arrow_left</v-icon>
                </v-btn>
            
                <div style="display: flex; flex-direction: column; justify-items: space-between;">
                    <div>Availability</div>
                    <div>&nbsp;</div>
                    <div>Rates</div>
                </div>
            
                <span v-for="(month, index) in visibleMonths" :key="index">
                    <div>
                        <v-icon 
                        v-if="!month.isMissingRates" 
                        title="All rates entered for month" 
                        color="green">
                        check_circle
                        </v-icon>
                    
                        <v-icon 
                        v-if="month.isMissingRates" 
                        title="Some rates missing for month" 
                        color="orange">
                        warning
                        </v-icon>
                    </div>
                    
                    <a 
                    href="javascript:void(0)" 
                    :style="{
                        'color' : 'black !important',
                        'font-weight' : monthIsSelected(month) ? 'bold' : 'normal'
                    }"     
                    @click="setSelectedDateByIndex(month.index)">
                    {{ month.monthName }} {{ month.shortYear }}
                    </a>
                    
                    <div
                        v-bind:class="{
                            'available-percentage' : month.hasAvailable,
                            'on-request-percentage' : !month.hasAvailable && month.hasOnRequest,
                            'none-available' : !month.hasAvailable && !month.hasOnRequest
                        }"
                    >
                        {{ month.eligibleRoomsPercentage }}% 
                    </div>
                    
                </span>
                <v-btn @click="addMonthToSelectedDate()" style="height:auto">
                    <v-icon>keyboard_arrow_right</v-icon>
                </v-btn>
            </div>
        </v-card>
        
    </div>
</template>

<script>
/**
* A Vue component dedicated to letting the user control availability and rates per
* hotel room for a given date range
*
* Props:  hotelkey
*/
import axios from 'axios';

export default {
name: 'hotel-planner-month-controls',
props: ['hotelkey', 'startDate', 'rateColumns'],
data () {
    return {
        responseData: [],
        selectedDate: this.startDate,

        selectedMonth: this.startDate.getMonth() + 1,
        selectedYear: this.startDate.getFullYear(),
    
        monthOptions: [
            { text: 'Jan', value: 1 },
            { text: 'Feb', value: 2 },
            { text: 'Mar', value: 3 },
            { text: 'Apr', value: 4 },
            { text: 'May', value: 5 },
            { text: 'Jun', value: 6 },
            { text: 'Jul', value: 7 },
            { text: 'Aug', value: 8 },
            { text: 'Sep', value: 9 },
            { text: 'Oct', value: 10},
            { text: 'Nov', value: 11},
            { text: 'Dec', value: 12},
        ]    
    }
},

/**
 * Once mounted this component needs to load all available models
 */
mounted() {
    this.getVisibleMonths(this.startDate);
},

computed: {
    visibleMonths(){
        if (!this.responseData){
            return [];
        }

        let months = [];

        for(var monthIndex = 0; monthIndex < this.responseData.length; monthIndex++){
            let month = this.responseData[monthIndex];
            let dateObject = new Date(month.year, month.month - 1, 1);
            month.index = monthIndex;
            month.date = dateObject;
            month.month = dateObject.getMonth();
            month.monthName = dateObject.toLocaleString('month', { month: 'short' });
            month.shortYear = dateObject.getFullYear().toString().slice(-2);

            months.push(month);
        }

        return months;
    },

    yearOptions(){
        let dateCounter = new Date();
        dateCounter.setFullYear(dateCounter.getFullYear() - 5);

        let yearOptions = [];

        for(var i = 0; i < 10; i++){
            let year = dateCounter.getFullYear();
            yearOptions.push({ text: year, value: year });
            dateCounter.setFullYear(dateCounter.getFullYear() + 1);
        }

        return yearOptions;
    }
},

watch: 
{
    selectedDate(newVal){
        this.$emit('update:startDate', newVal);
    },
},

methods: {
    dateIsAfterRange(date){
        let lastDateInRange = this.visibleMonths[this.visibleMonths.length - 1];

        return date > lastDateInRange.date;
    },

    dateIsBeforeRange(date){
        let firstDateInRange = this.visibleMonths[0];

        return date < firstDateInRange.date;
    },

    async addMonthToSelectedDate(){
        let newDate = new Date(this.selectedDate);
        newDate.setMonth(newDate.getMonth() + 1);

        if (this.dateIsAfterRange(newDate)){
            let newRangeStartDate = new Date(this.visibleMonths[0].date);
            newRangeStartDate.setMonth(newRangeStartDate.getMonth() + 1);
            await this.getVisibleMonths(newRangeStartDate);
        }

        this.selectedDate = newDate;  
    },

    async subMonthFromSelectedDate(){
        let newDate = new Date(this.selectedDate);
        newDate.setMonth(newDate.getMonth() - 1);

        if (this.dateIsBeforeRange(newDate)){
            let newRangeStartDate = new Date(this.visibleMonths[0].date);
            newRangeStartDate.setMonth(newRangeStartDate.getMonth());
            await this.getVisibleMonths(newRangeStartDate);
        }

        this.selectedDate = newDate;
    },

    setSelectedDateByIndex(index){
        this.selectedDate = this.visibleMonths[index].date;
    },

    setSelectedDateByForm(){
        this.selectedDate = new Date(this.selectedYear, this.selectedMonth - 1, 1);
    },

    monthIsSelected(month){
        let sameMonth = month.month == this.selectedDate.getMonth();
        let sameYear = month.year == this.selectedDate.getFullYear();

        return sameMonth && sameYear;
    },

    getVisibleMonths(startDate){
        let startString = startDate.getFullYear() + '-' + (startDate.getMonth() +  1) + '-' + 1;
        let rateColumns = ['rate_standard'];

        return axios.get('/api/hotel-planner-month-controls', {
            'params': { 
                'hotelKey': this.hotelkey, 
                'dateFrom': startString, 
                'rateColumns': [...rateColumns, ...this.rateColumns],
            } 
        } 
        )
        .then(function(response){
            this.responseData = response.data;            
        }
        .bind(this));
    },
},

}
</script>

<style>
    .month-controls {
        display: flex;
        justify-content: space-between;
    }

    .month-controls button {
        font-size: large;
    }
    .month-controls span {
        display: inline-block; 
        text-align: center; 
        margin: 0 10px;
    }

    .available-percentage {
        color: #C1CD23;
    }

    .on-request-percentage {
        color: gold;
    }

    .none-available {
        color: #F15C61;
    }

    .quick-select {
        max-width: 20%;
    }

    .quick-select button {
        max-width: 50px;
    }
</style>
