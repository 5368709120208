<template>
    <v-container>
        <h1>  Collection: {{ collectionData.name }}</h1>
        <v-flex xs12>
            <v-card >
                <v-btn class="ies-green" type="submit" v-on:click="saveCollection()"><v-icon>save</v-icon> Save</v-btn>
                <v-btn class="ies-coral" v-on:click="deleteCollection()"><v-icon>delete</v-icon> Delete</v-btn>
                <v-btn onClick="window.print()"><v-icon>print</v-icon> Print</v-btn>
                <v-btn v-on:click="emailLink()"><v-icon>email</v-icon> Email</v-btn>
                <v-btn href="/collections/list"><v-icon>view_list</v-icon> List</v-btn>
                <v-btn v-on:click="reportProblem()"><v-icon>report_problem</v-icon> Problem</v-btn>
            </v-card>
        </v-flex>
        <v-flex xs12>
            <form id="editForm" v-on:submit.prevent>
                <v-card>
                <v-layout column class="ma-3" >
                <v-card flat  xs12 class="ma-3" >
                    <fieldset>
                        <legend >Collection Info</legend>
                        <v-layout column>
                            <v-flex xs12>
                                    <v-text-field
                                        label="Name"
                                        v-model="collectionData.name"
                                    ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                    <v-text-field
                                        label="System Name"
                                        v-model="collectionData.system_name"
                                    ></v-text-field>
                            </v-flex>
                             <v-flex xs12>
                                    <v-select
                                        :items="collectionTypes"
                                        label="Collection Type"
                                        v-model="collectionData.collection_type_key"
                                    ></v-select>
                            </v-flex>
                            <v-flex xs12>
                                    <v-text-field
                                        v-model="collectionData.text"
                                        label="Text"
                                    ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                    <v-text-field
                                        v-model="collectionData.title"
                                        label="Title"
                                    ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                    <v-text-field
                                        v-model="collectionData.description"
                                        label="Description"
                                    ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <label for="isOnline">Is Online: </label>
                                <input type="checkbox" 
                                       id="isOnline"
                                       v-model="collectionData.is_online">
                                </v-checkbox>
                            </v-flex>
                        </v-layout>

                    
                        <v-layout row-sm column>
                            <v-flex xs12>
                                <label for="image_key"> Image Key: </label>
                            </v-flex>

                            <v-flex xs12 sm6>
                                <input name="image_key" type="text" :value="this.collectionData.image_key" disabled>
                            </v-flex>
                            <v-flex xs12>
                                <img
                                    alt="Image from photo library"
                                    v-bind:src="generatePath(this.collectionData.image_key)"
                                    style="max-height: 200px;"
                                >
                            </v-flex>

                            <v-flex xs12>
                                <v-btn @click="selectNewImage">Choose Image</v-btn>
                                <v-btn :href="imageURL" target="_blank" :disabled="!this.collectionData.image_key"><v-icon>image</v-icon></v-btn>
                                <v-btn class="ies-coral ies-dark-gray--text" @click="unlinkImage" :disabled="!this.collectionData.image_key">Remove Image</v-btn>
                            </v-flex>


                            <div style="position: fixed; top:10vh; left:10vw; width:80vw; height:80vh; background-color:white; z-index:999; padding:5rem;" v-if="displayImagePicker">
                                <h2>Image Library...</h2>

                                <div style="overflow-y:scroll; height:60vh;">
                                    <v-container fluid>
                                        <v-layout row wrap>

                                            <!-- Search tools -->
                                            <v-flex xs12>~~
                                              <v-expansion-panels>
                                                <v-expansion-panel >
                                                    <v-expansion-panel-header><strong>Search <v-icon>search</v-icon></strong></v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <fieldset>
                                                            <legend>Properties</legend>
                                                            <v-text-field label="ID" v-model="searchFilters.imageKey"></v-text-field>
                                                            <v-text-field label="Minimum Width (px)"  v-model="searchFilters.minWidth"></v-text-field>
                                                            <v-text-field label="Minimum Height (px)" v-model="searchFilters.minHeight"></v-text-field>

                                                            <v-radio-group v-model="searchFilters.displayType" :mandatory="false">
                                                                <v-radio label="Any" value=""/>
                                                                <v-radio label="Display only" value="1"/>
                                                                <v-radio label="Hidden only"  value="0"/>
                                                            </v-radio-group>

                                                            <v-select
                                                                :items="imageTypes"
                                                                v-model="searchFilters.imageType"
                                                                label="Select Type of Image"
                                                                item-text="text"
                                                                item-value="value"
                                                                dense
                                                                id="image-type"
                                                            ></v-select>
                                                        </fieldset>

                                                        <fieldset>
                                                                <legend>Date Range</legend>
                                                                <div class="bold">Date From:</div>
                                                                <v-date-picker
                                                                    :landscape="true"
                                                                    v-model="searchFilters.dateFrom"
                                                                    color="lime"
                                                                    type="date"
                                                                ></v-date-picker>

                                                                <div class="bold">Date Until:</div>
                                                                <v-date-picker
                                                                    :landscape="true"
                                                                    v-model="searchFilters.dateUntil"
                                                                    color="lime"
                                                                    type="date"
                                                                ></v-date-picker>
                                                        </fieldset>

                                                        <fieldset>
                                                            <legend>Relationships</legend>
                                                            <v-text-field label="Hotel Code" v-model="searchFilters.hotelCode"></v-text-field>
                                                        </fieldset>

                                                        <v-btn @click="loadLibraryImages">Search</v-btn>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                              </v-expansion-panels>
                                            </v-flex>

                                            <v-flex xs12>
                                            <v-container grid-list-md>
                                            <v-layout row wrap>

                                            <v-flex class="pt-3" xs3  v-for="(libraryImage, image_key) in libraryImages" :key="image_key">
                                                <v-card @click.native="chooseImage(libraryImage.image_key)" class="clickable">

                                                    <v-container flex>
                                                    <v-layout row wrap>
                                                        <v-flex xs12>
                                                            <b>ID:</b> {{ libraryImage.image_key }}
                                                        </v-flex>

                                                        <!-- Thumbnail -->
                                                        <v-flex xs12>
                                                            <img
                                                                alt="Image from photo library"
                                                                v-bind:src="libraryImage.image_source"
                                                                style="max-height: 150px;"
                                                            >
                                                        </v-flex>

                                                        <!-- Description -->
                                                        <v-flex xs12 v-if="libraryImage.hotel">
                                                            <b>Hotel:</b> ({{ libraryImage.hotel.hotel_code }}) {{ libraryImage.hotel.hotel_name }}
                                                        </v-flex>
                                                        <v-flex xs12>
                                                            <b>Display:</b> {{ libraryImage.display_slideshow ? 'Yes' : 'No' }}
                                                            <b>Type:</b> {{ (typeof libraryImage.image_type === undefined || libraryImage.image_type == null || typeof libraryImage.image_type.display_name_short === undefined) ? null : libraryImage.image_type.display_name_short }}
                                                            <b>Order:</b> {{ libraryImage.sort_order }}
                                                        </v-flex>
                                                        <v-flex xs12>
                                                            <b>Caption:</b> {{ libraryImage.caption }}
                                                        </v-flex>
                                                        <v-flex xs12>
                                                            <b>Copyright:</b> {{ libraryImage.copyright }}
                                                        </v-flex>
                                                        <v-flex xs12>
                                                            <b>Description:</b> {{ libraryImage.description }}
                                                        </v-flex>
                                                    </v-layout>
                                                    </v-container>

                                                </v-card>
                                            </v-flex>
                                            </v-layout>
                                            </v-container>
                                            </v-flex>

                                        </v-layout>
                                    </v-container>
                                </div>

                                <div style="width:100%; position:relative; bottom:0; background-color:white;">
                                    <v-btn class="ies-green ies-dark-gray--text" @click="closeImageSelector">Done</v-btn>
                                    <v-btn @click="closeImageSelector">Cancel</v-btn>
                                </div>
                            </div>
                        </v-layout>
                    </fieldset>
                </v-card>
                </v-layout>
                </v-card>
            </form>
        </v-flex>

        <v-card v-if="collectionData.collection_type_key == 1">
            <v-card-title class="headline font-weight-black">
                Hotels Assigned to the {{ collectionData.name }} Collection ({{collectionHotels.length }})
            </v-card-title>

            <v-container>
                <v-layout row wrap>
                    <v-col cols="12" >
                        <v-text-field
                             v-model="hotelViewName" 
                             @keyup.enter="onEnter" 
                             v-on:keyup="updateHotelName()"
                        ></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" 
                           style="display: block;" 
                           v-if="hotelOptions.length">
                        <ul>
                            <li v-for="hotel in hotelOptions" 
                                v-on:click="addHotelToCollection(hotel.hotel_key)">({{ hotel.hotel_code }}) {{ hotel.hotel_name }}</li>
                        </ul>
                    </v-col>

                </v-layout>
            </v-container>

            <v-container>
               <v-layout row wrap>

            <v-col cols="12" md="2" class="mt-3"
                    v-for="(hotel, hotel_key) in collectionHotels" 
                    v-bind:key="hotel">
                <v-card min-height="200px">

                    <v-card-text>
                <v-container flex justify-center>
                    <v-layout row wrap>
                    <!-- Thumbnail -->
                        <v-flex d-flex xs8 justify-center>
                                <div class="text-xs-right">
<!--
                                    <img
                                        alt="Hotel image"
                                        v-bind:src="generatePath(hotel.image_key)"
                                        style="max-height: 150px;"
                                    >
-->
                                </div>
                                <div class="ml-3">
                                    <p>Hotel: ({{ hotel.hotel_code }}) {{ hotel.hotel_name }},</p> 
                                    <p>{{hotel.city}}, {{ hotel.country_name }}</p> 
                                </div>
                        </v-flex>
                        <!-- Description -->
                        <v-flex xs-6 justify-center>
                            <v-btn class="ies-coral small" v-on:click="removeHotelFromCollectionHotels(hotel)"><v-icon>delete</v-icon> Remove</v-btn>
                        </v-flex>
                    </v-layout>
                </v-container>

                    </v-card-text>
                </v-card>
            </v-col>

                </v-layout>
            </v-container>

        </v-card>

         <v-card v-if="collectionData.collection_type_key != 1">
            <v-card-title class="headline font-weight-black">
              Selected Filters for the {{ collectionData.name }} Collection
            </v-card-title>

            <v-flex xs8 v-for="(filter, key) in filterOptions" :key="key" justify-center >
                   <v-card>
                        <v-card-title class="headline">{{ filter.title }}</v-card-title>

                        <v-container class="p-3">
                        <div v-for="(option, index) in filter.options" class="m-3">
                            <v-layout row wrap>
                              <v-col cols="3">
                                <label :for="filter_index">{{ option.title }}:</label>
                              </v-col>
                              <v-col cols="9">
                                <input type="checkbox" 
                                    :id="'filter_' + index"
                                    :value="option" 
                                    :key="index" 
                                    :checked="option.isSelected"
                                    :label="option.title"
                                    @change="checkSelected(option)"
                                    v-model="selectedFilters"
                                    multiple>
                                </v-col>
                            </v-layout>
                        </div>
                        </v-container>
                    </v-card>
            </v-flex>
         </v-card>

            <v-flex xs12>
            <v-card >
                <v-btn class="ies-green" type="submit" v-on:click="saveCollection()"><v-icon>save</v-icon> Save</v-btn>
                <v-btn class="ies-coral" v-on:click="deleteCollection()"><v-icon>delete</v-icon> Delete</v-btn>
                <v-btn onClick="window.print()"><v-icon>print</v-icon> Print</v-btn>
                <v-btn v-on:click="emailLink()"><v-icon>email</v-icon> Email</v-btn>
                <v-btn href="/collections/list"><v-icon>view_list</v-icon> List</v-btn>
                <v-btn v-on:click="reportProblem()"><v-icon>report_problem</v-icon> Problem</v-btn>
            </v-card>
        </v-flex>
    </v-container>

</template>

<script>
    import axios from 'axios';
    /**
     * Display reservation statistics to the user
     */
    export default {
        /**
         * Data passed into the component from the view itself via attributes on its tag
         */
        props: ['collectionKey'],

        data() {
            return {
                inputText: '',
                allHotels: [],
                hotelOptions: [],
                searchText: '',
                collectionData: [],
                filterOptions: [],
                collectionHotels: [],
                selectedFilters: [],
                filter: [],
                option: [],
                collectionTypes: [{'value':1, 'text': 'Curated'},{'value': 2, 'text': 'Dynamic'}],
                selectedHotels: [],
                hotelsToAddToCollection: [],
                /** @var {} imageField - A copy of the image field being edited, used to allow Vue to react to changes on the data model with changes in the computed methods without the need for watchers */
                imageField:     {},

                /** @var boolean displayImagePicker - Should the user be presented with an image picker modal to choose the replacement image record */
                displayImagePicker: false,

                /** @var {} searchFilters - An object encapsulating all of the different parameters that the user is searching the image library by */
                searchFilters:  { imageKey: null, hotelCode: null, dateFrom: null, dateUntil: null, imageType: null, regionKey: null, displayType: '', minWidth: null, minHeight: null },

                /** @var [{}] libraryImages - An array of objects representing the images returned by the server to choose from */
                libraryImages:  [],

                /** @var [] imageTypes - An array of image types for the user to choose between when searching the photo library */
                imageTypes:     [],

                /** @var {} */
                imageTypeInitials: {
                                        '1':  'O',  // Overview
                                        '2':  'R',  // rooms
                                        '3':  'E',  // eating
                                        '4':  'A',  // activities
                                        '5':  'F',  // features
                                        '6':  'S',  // site_photo
                                        '7':  'H',  // home
                                        '9':  'PH', // photo_library
                                        '10': 'OL', // overview_large
                                        '11': 'HL', // home_large
                                        '12': 'RL', // rooms_large
                                        '13': 'EL', // eating_large
                                        '14': 'AL', // activities_large
                                        '15': 'F',  // features_large
                                        '16': 'SL', //site_photo_large
                                        '17': 'K',  // kids
                                        '18': 'KL', //  kids_large
                                        '19': 'V',  //  physical_voucher
                                    },
                }
        },
        /**
         * Perform the following actions on startup
         */
        mounted() {
            this.loadCollectionHotels();
            this.loadCollectionData();
            this.loadAllHotels();
            this.loadFilterOptions();
            this.imageField = this.collectionData;
            this.loadImageTypes();
           
        },

        methods: {
            /**
             * Load the latest data from the API
             */
            loadCollectionHotels() {
                axios.get('api/hotelcollection/' + this.collectionKey, {
                   params: { collectionKey: this.collectionKey }
                })
                .then(response => {
                    this.collectionHotels = response.data;
                }).catch(error => {
                    console.log(error);
                });
            },

            loadCollectionData() {
                axios.get('api/collections', {
                    params: { collectionKey: this.collectionKey }
                })
                .then(response => {
console.log("COLLECTION DATA", response.data[0]);
                    this.collectionData = response.data[0];
                }).catch(error => {
                    console.log(error);
                });
            },

            loadAllHotels() {
                axios.get('api/hotels')
                .then(response => {
                    this.allHotels = response.data;
                }).catch(error => {
                    console.log(error);
                });
            },

            loadFilterOptions() {
                var self = this;
                axios.get('api/collections/all-filter-options', {
                    params: { collectionKey: this.collectionKey }
                })
                .then(response => {
                    this.filterOptions = response.data;
                    this.filterOptions.forEach(function (filterGroup) {
                        Object.entries(filterGroup.options).forEach(function ([key, option]) {
                            if(option.isSelected) {
                                self.selectedFilters.push(option);
                            }
                        })
                    })
                }).catch(error => {
                    console.log(error);
                });
            },

            generatePath: function(imageKey) {
                if (!imageKey){
                    return '';
                }

                var parentDirSize = 1000;
                var childDirSize  = 100;

                var parentModifier   = (imageKey >= parentDirSize && imageKey % parentDirSize == 0) ? -1 : 0;
                var parentLowerLimit = 1 + ((Math.floor(imageKey / parentDirSize) + parentModifier) * parentDirSize);
                var parentUpperLimit = parentLowerLimit + (parentDirSize - 1);

                var childModifier    = (imageKey >= childDirSize && imageKey % childDirSize == 0) ? -1 : 0;
                var childLowerLimit  = 1 + ((Math.floor(imageKey / childDirSize) + childModifier) * childDirSize);
                var childUpperLimit  = childLowerLimit + (childDirSize - 1);
                return '/gallery/' + parentLowerLimit + '_' + parentUpperLimit + '/' + childLowerLimit + '_' + childUpperLimit + '/' + imageKey + '.jpg';
            },

            updateHotelName: function(){
                 var self = this;
                 var hotelOptions = [];
                 if (this.hotelViewName){
                     this.allHotels.forEach(function(hotel){
                         if (hotel.hotel_code.toLowerCase().includes(self.hotelViewName.toLowerCase()) || hotel.hotel_name.toLowerCase().includes(self.hotelViewName.toLowerCase())){
                             hotelOptions.push(hotel);
                         }
                     });
                 }
                 this.hotelOptions = hotelOptions;
             },

             onEnter: function(){
                 console.log("enter detected");
                 if (this.hotelOptions.length > 0){
                     this.addHotelToCollection(this.hotelOptions[0].hotel_key);
                 }
              },

            addHotelToCollection: function(hotelKey) {
                var self = this;
                this.allHotels.forEach(function(hotel){
                    if (hotel.hotel_key === hotelKey){
                        self.collectionHotels.push(hotel);
                    }
                });
                this.searchText = '';
            },
/*
            getHotelDataAndAddToCollectionHotels: function() {
                var self = this;
                axios.get('api/collection-get-hotel-data', { params: this.hotelsToAddToCollection})
                .then(function(response){
                    response.data.forEach(function (hotel) {
                        self.collectionHotels.unshift(hotel);
                    })
                }.bind(this));
            },
*/
            removeHotelFromCollectionHotels: function(hotel) {
                var index = this.collectionHotels.indexOf(hotel);
                if (index !== -1) {
                    this.collectionHotels.splice(index, 1);
                }
            },

            saveCollection: function() {
                //Axios request to update new collectionData to DB
                if (confirm("Are you sure you want to make these changes?")) {
                        axios.post('/api/collections', { params: this.collectionData })
                        .then(function(response){
                            if (response) {
                                if(this.collectionData.collection_type_key == 1) {
                                    // Send the list of curated hotels to the server
                                    this.postUpdateHotelsInCuratedCollection();

                                    // Unset all applied filters from the dynamic list so as 
                                    // to ensure that they do not persist and cause confusion later
                                    this.removeAllFilters();
                                    this.postUpdateHotelsInDynamicCollection();
                                } else if (this.collectionData.collection_type_key == 2) {
                                    // Send the list of filters to the server
                                    this.postUpdateHotelsInDynamicCollection();

                                    // Unset the hotels from the curated list so that they 
                                    // do not persist and cause confusion later
                                    this.removeAllHotels();
                                    this.postUpdateHotelsInCuratedCollection();
                                };
                            }

                        alert('Saved')
                        }.bind(this));
                    }
            },

            postUpdateHotelsInCuratedCollection: function() {
                axios.post('/api/update-hotels-in-curated-collection', { params: { hotels: this.collectionHotels, collectionKey: this.collectionKey } })
                .then(function(response){
                }.bind(this));
            },

            postUpdateHotelsInDynamicCollection: function() {
                axios.post('/api/update-filters-in-dynamic-collection', { params: { selectedFilters: this.selectedFilters, collectionKey: this.collectionKey } })
                .then(function(response){
                }.bind(this));
            },

            removeAllHotels: function(){
                this.collectionHotels = [];
            },

            removeAllFilters: function(){
                this.selectedFilters = [];
            },

            deleteCollection: function() {
                //Axios request to delete collection from DB
            },

             /**
             * Formats an email to conveniently link the page to an email recipient in the users default email client
             */
            emailLink(){
                var emailAddress = '';
                var subject      = 'collections ' + this.collectionData.collection_key;
                var body         = 'URL: ' + window.location.href;
                var url          = 'mailto:' + emailAddress + '?subject=' + subject + '&body=' + body;
                window.location.assign(url);
            },

            /**
             * Formats an email to conveniently link the page to an email recipient in the users default email client
             */
            reportProblem(){
                var emailAddress = 'web@i-escape.com';
                var subject      = 'Problem Report: collections ' + this.metadata.primaryKey;
                var body         = 'URL: ' + window.location.href;
                var url          = 'mailto:' + emailAddress + '?subject=' + subject + '&body=' + body;
                window.location.assign(url);
            },

            /**
             * Nullifies the value for this field, thereby breaking the relationship
             * between the record being edited and the image record it once referenced
             * without deleting the image itself from the image library
             */
            unlinkImage: function(){
                this.imageField.value = null;
            },

            /**
             * Opens a dialogue to choose a new image from the library, setting this
             * field's value to the new image's image_key property
             */
            selectNewImage: function(){
                this.displayImagePicker = true;
                this.loadLibraryImages();
            },

            /**
             * Updates the image key in the field to match that of the image chosen from the library
             */
            chooseImage: function(imageKey){
                this.imageField.value = imageKey;
                this.collectionData.image_key = imageKey;
            },

            closeImageSelector: function(){
                this.displayImagePicker = false;
            },

            /**
             * Requests an array of Image objects from the API which match the
             * criteria specified by the user (if any) limited to a max number
             * of records
             */
            loadLibraryImages: function(){
                    axios.get('api/images', { params: this.searchFilters } )
                    .then(function(response){
                        this.libraryImages = response.data;
                    }.bind(this))
                    .then(function(){
                        // fail
                    }.bind(this));
            },

            /**
             * Requests all the different types of images recognised by the system, used for searching
             * the photo library
             */
            loadImageTypes: function(){
                var self = this;

                axios.get('api/images/types')
                .then(function(response){
                    self.imageTypes = [ { text: 'Any', value: '' } ];
                    response.data.forEach(function(imageType){
                        self.imageTypes.push( { text: imageType.display_name, value: imageType.image_type_key });
                    });
                this.libraryImages = response.data;
                }.bind(this))
                .then(function(){
                    // fail
                }.bind(this));
            },
        },

        computed: {

        /**
         * We need the image thumbnail to be dynamically updated when the
         * field value is changed, meaning the cleanest way is a computed
         * property to automatically react to changes to the data model.
         * The actual value to return as a string of the URL is too complex
         * to be handled here, so it is handled in the separate method(s)
         * dedicated to the task
         *
         * @return string URL of the image specified
         */
        imageURL: function(){
            return this.generatePath(this.collectionData.image_key);
        },

        viewModelImage: function(){
            return this.imageField;
        }
    }

    }
</script>

<style scoped>

.selectBox {
    width: 50%;
    padding: 30px 0;
    align-items: center;
    margin-left: 20%;
    display: inline-flex;
}

.selectBox .chip {
    color:blue;
}

button {
    height: 3.3rem;
}

.bold {
    font-weight: bold;
}

.clickable:hover {
    cursor: pointer;
}



</style>

