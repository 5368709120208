<template>
    <v-flex>{{ type }}</v-flex>
</template>

<script>
/**
 * Outputs the visit type based off the is_overnight field
 *
 */
export default {
    name: 'ies-visit-type',
    props: ['field', 'value'],
    data () {
        return {}
    },

    computed: {
        type: function(){
			return parseInt(this.value) ? 'Overnight' : 'Day Visit';
        }
    }
}
</script>

