<template>
    <v-flex xs12 sm12 md2 lg2 ma-1>
        <v-card>
            <v-card-text>
                <div class="stats-icon"><v-icon style="font-size: 3rem;">question_answer</v-icon></div>
                <div class="stats-text">
                    <div class="stats-figure">{{ stats }}</div>
                    <div class="stats-descriptor">New Enquiries</div>
                </div>
            </v-card-text>
        </v-card>
    </v-flex>
</template>

<script>
    import axios from 'axios';
    /**
     * Display reservation statistics to the user
     */
    export default {
        /**
         * Data passed into the component from the view itself via attributes on its tag
         */
        props:['datasource'],
        data() {
            return {
                data: { new: '...' },
                yesterday: null,
                yesterdayString: ''
            }
        },
        /**
         * Perform the following actions on startup
         */
        mounted() {
            this.loadReservationStats();
        },

        methods: {
            loadReservationStats:function(){
                this.yesterday = new Date();
                this.yesterday.setDate(this.yesterday.getDate() - 1);
                this.yesterdayString = this.yesterday.getFullYear() + '-' + (this.yesterday.getMonth() < 9  ? '0'+(this.yesterday.getMonth() + 1) : (this.yesterday.getMonth() + 1)) + '-' + (this.yesterday.getDate() < 10 ? '0'+this.yesterday.getDate() : this.yesterday.getDate());
                var params = { dateFrom: this.yesterdayString};

                axios.get('api/accounts/bookings/statistics',  { params: params })
                .then(function(response){
                    this.data.new = response.data[this.yesterdayString].count_request;
                }.bind(this));
            }
        },
        computed: {
            stats: function(){
                return this.data.new;
            }
        }
    }
</script>
