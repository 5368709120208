<template>
    <v-container fluid grid-list-md>
        <v-layout row wrap>

            <v-col cols="12">

              <v-expansion-panels v-model="panel" value="0">

                  <v-expansion-panel >
                      <v-expansion-panel-header class="list-search--header">
                         <strong><v-icon>search</v-icon> Search</strong>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content
                         
                         class="quickSearchPanel"
                         ripple
                      >

            <v-col cols="12">
                <div>Options:</div>
                <div>Reservation Reference:<input></div>
                <div>Region: <input></div>
                <div>Hotel Code/Name: <input></div>
                <div>Client Name: <input></div>
                <div>Client Email: <input></div>

                <div>Enquiries: <input type="checkbox"></div>
                <div>Cancellations: <input type="checkbox"></div>
                <div>Confirmed Bookings: <input type="checkbox"></div>
                <div>Completed Stays: <input type="checkbox"></div>
            </v-col>

            <v-col cols="12">
                <div>Legend:</div>
                <v-icon>card_giftcard</v-icon> Gift vouchers were redeemed during this booking
                <v-icon>credit_card</v-icon> Virtual card issued for hotel to charge
                <v-icon>paid</v-icon> Full amount prepaid by client at time of booking
                <v-icon>bolt</v-icon> Reservation was made as an instant-booking
                <v-icon>mark_chat_unread</v-icon> Unread messages from client
            </v-col>

                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>

            <v-col cols="12">
                <v-card v-for="reservationGroup in allReservations">
                    <v-container fluid>
                        <v-layout row wrap>
                            <v-col cols="12">
                                <h2>{{ reservationGroup.title }} ({{ reservationGroup.reservations.length }})</h2>
                                {{ reservationGroup.description }}
                               <v-expansion-panels>
                                 <v-expansion-panel
                                     v-for="(reservation, index) in reservationGroup.reservations"
                                     :key="i"
                                     v-model="panel"
                                     expand
                                     focusable
                                 >
                                 <v-expansion-panel-header>
                    <v-container fluid>
                        <v-layout row wrap>
                            <v-col cols="5" md="1" class="d-md-none">
                                <strong>Reference:</strong>
                            </v-col>
                            <v-col cols="7" md="1">
                                <strong>{{ reservation.reference }}</strong>
                            </v-col>

                            <v-col cols="7" md="1" class="d-none d-md-flex">
                                    {{ reservation.date_reservation }}
                            </v-col>

                            <v-col cols="5" md="1" class="d-md-none">
                                <strong>Hotel:</strong>
                            </v-col>
                            <v-col cols="7" md="2">
                                    ({{ reservation.hotel.hotel_code }}) {{ reservation.hotel.hotel_name }}
                            </v-col>


                            <v-col cols="5" md="1" class="d-md-none">
                                <strong>Dates:</strong>
                            </v-col>
                            <v-col cols="7" md="1" class="d-md-none">
                                Arrive {{ reservation.date_arrival }}</br> Depart {{ reservation.date_departure }}
                            </v-col>
                            <v-col cols="12" md="1" class="d-none d-md-flex">
                                    {{ reservation.date_arrival }}
                            </v-col>
                            <v-col cols="12" md="1" class="d-none d-md-flex">
                                    {{ reservation.date_departure }}
                            </v-col>


                            <v-col cols="5" md="1" class="d-md-none">
                                <strong>Nights:</strong>
                            </v-col>
                            <v-col cols="7" md="1">
                                    {{ reservation.num_nights }}
                            </v-col>

                            <v-col cols="5" md="1" class="d-md-none">
                                <strong>Client:</strong>
                            </v-col>
                            <v-col cols="7" md="1">
                                    {{ reservation.client.first_name }} {{ reservation.client.last_name }}
                            </v-col>

                            <v-col cols="5" md="1" class="d-md-none">
                                <strong>Quote (GBP):</strong>
                            </v-col>
                            <v-col cols="7" md="1">
                                    {{ reservation.quote_amount_gbp }}
                            </v-col>

                            <v-col cols="12" md="1">
                                <v-icon v-if="reservation.has_voucher">card_giftcard</v-icon>
                                <v-icon v-if="reservation.has_van">credit_card</v-icon>
                                <v-icon v-if="reservation.is_prepaid">paid</v-icon>
                                <v-icon v-if="reservation.unreadMessages">mark_chat_unread</v-icon>
                            </v-col>
                        </v-layout>
                     </v-container>
                               </v-expansion-panel-header>

                               <v-expansion-panel-content>
                                    <v-card>
                                       <v-card-actions>
                                           <v-btn :href="reservation.viewClientUrl"><v-icon>person</v-icon> View Client Details</v-btn>
                                           <v-btn :href="reservation.emailClientUrl"><v-icon>email</v-icon> Email Client</v-btn>
                                           <v-btn :href="reservation.phoneClientUrl"><v-icon>phone</v-icon> Call Client</v-btn>
                                           <v-btn :href="reservation.emailHotelUrl"><v-icon>email</v-icon> Email Hotel</v-btn>
                                           <v-btn :href="reservation.phoneHotelUrl"><v-icon>phone</v-icon> Call Hotel</v-btn>
                                           <v-btn :href="reservation.hotel.overviewUrl"><v-icon>web</v-icon>View Hotel</v-btn>
                                           <v-btn :href="reservation.rateTypesUrl"><v-icon>money</v-icon> View Hotel Rates</v-btn>
                                           <v-btn :href="reservation.roomTypesUrl"><v-icon>king_bed</v-icon> View Hotel Rooms</v-btn>
                                           <v-btn :href="reservation.specialOffersUrl"><v-icon>local_offer</v-icon> View Hotel Offers</v-btn>
                                           <v-btn disabled><v-icon>settings</v-icon> Edit</v-btn>
                                       </v-card-actions>

                                       <v-card-text>
                                           <!-- <v-btn v-if="!reservation.show" @click="showReservation(reservation.reservation_key)">Show Reservation</v-btn> -->
                                           <v-container fluid v-if="1 || reservation.show">
                                               <v-layout row wrap>

                                                   <v-col cols="12" md="4" class="pa-0">
                                                       <ies-reservation-administration-overview :reservationkey="reservation.reservation_key"></ies-reservation-administration-overview>
                                                   </v-col>
                                                   <v-col cols="12" md="8" v-if="reservationGroup.status_key == 8" class="pa-0">
                                                      <ies-reservation-administration-enquiry :reservationkey="reservation.reservation_key"></ies-reservation-administration-enquiry>
                                                   </v-col>
                                                   <v-col cols="12" md="8" v-if="reservationGroup.status_key == 12" class="pa-0">
                                                      <ies-reservation-administration-accepted :reservationkey="reservation.reservation_key"></ies-reservation-administration-accepted>
                                                   </v-col>
                                                   <v-col cols="12" md="8" v-if="reservationGroup.status_key == 20" class="pa-0">
                                                      <ies-reservation-administration-awaiting :reservationkey="reservation.reservation_key"></ies-reservation-administration-awaiting>
                                                   </v-col>
                                                   <v-col cols="12" md="8" v-if="reservationGroup.status_key == 17" class="pa-0">
                                                      <ies-reservation-administration-cancellation :reservationkey="reservation.reservation_key"></ies-reservation-administration-cancellation>
                                                   </v-col>
                                                   <v-col cols="12" md="8" v-if="reservationGroup.status_key == 18" class="pa-0">
                                                      <ies-reservation-administration-cancelled :reservationkey="reservation.reservation_key"></ies-reservation-administration-cancelled>
                                                   </v-col>
                                                   <v-col cols="12" md="8" v-if="reservationGroup.status_key == 15" class="pa-0">
                                                      <ies-reservation-administration-declined :reservationkey="reservation.reservation_key"></ies-reservation-administration-declined>
                                                   </v-col>
                                                   <v-col cols="12" md="8" v-if="reservationGroup.status_key == 13" class="pa-0">
                                                       <ies-reservation-administration-completed :reservationkey="reservation.reservation_key"></ies-reservation-administration-completed>
                                                   </v-col>
                                                   <v-col cols="12" md="8" v-if="reservationGroup.status_key == 9" class="pa-0">
                                                       <ies-reservation-administration-confirmed :reservationkey="reservation.reservation_key"></ies-reservation-administration-confirmed>
                                                   </v-col>
                                                   <v-col cols="12" md="8" class="pa-0">
                                                       <ies-reservation-administration-messages :reservationkey="reservation.reservation_key"></ies-reservation-administration-messages>
                                                   </v-col>

                                               </v-layout>
                                           </v-container>
                                       </v-card-text>
                                   </v-card>

                               </v-expansion-panel-content>

                               </v-expansion-panel>
                               </v-expansion-panels>

                            </v-col>



                        </v-layout>
                    </v-container>

                                       <v-card-actions>
                                           <v-btn class="ies-green d-md-none" block>Respond</v-btn>
                                       </v-card-actions>
                </v-card>
            </v-col>
        </v-layout>
    </v-container>
</template>

<script>
    /**
     * A Vue component dedicated to letting the user manage the reservations
     * for the hotel specified, or for all hotels if not specified
     *
     * Props:
     *     int 'hotelkey' - The ID of the hotel to filter by, if any
     */

    import axios from 'axios';
    export default {
        name: 'hotel-reservation-administration',
        props: ['hotelkey'],
        data () {
            return {
                /** @var {} allFacilities - The full spectrum of facilities that the i-escape system can understand */
                allReservations: [],
                emailClientUrl: '',
                panel: [],
                i: null
            }
        },
        methods: {

            /**
             * @see data allFacilities
             */
            loadAllReservations(){
                axios.get('/api/hotel/' + (this.hotelkey ? this.hotelkey : 'all') + '/reservation-administration')
                .then(function(response){
                    this.allReservations = response.data;
console.log(this.allReservations);
                    this.allReservations.forEach(function(group){
                        group.reservations.forEach(function(reservation){
                            // Commented out as it is crashing but what does it do?
//                            window.Vue.set(reservation, 'show', false);
                            reservation.show = false;
                            reservation.emailClientUrl = "mailto:" + reservation.client.email + '?subject=i-escape.com Reservation ' + reservation.reference + '&body=Dear ' + reservation.client.first_name + ', ';
                            reservation.phoneClientUrl = "tel:" + reservation.client.phone_number;
                            reservation.viewClientUrl  = "/myescapes-members/" + reservation.client.member_key;
                            reservation.emailHotelUrl  = "mailto:" + reservation.hotel.reservationEmails.join(';') + '?subject=i-escape.com Reservation ' + reservation.reference + '&body=Dear ' + reservation.hotel.hotel_name + ', ';
                            reservation.phoneHotelUrl = "tel:" + reservation.hotel.phone_numbers[0];
                            reservation.rateTypesUrl = "/rate-types/list?s=as&c[]=Hotel-hotel_key&o[]===&v[]=" + reservation.hotel.hotel_key;
                            reservation.roomTypesUrl = "/hotel-rooms/list?s=as&c[]=Hotel-hotel_key&o[]===&v[]=" + reservation.hotel.hotel_key;
                            reservation.specialOffersUrl = "/live-hotels-offers/list?s=as&c[]=Hotel-hotel_key&o[]===&v[]=" + reservation.hotel.hotel_key;
                            console.log('RES:', reservation);
                        });
                    });
                }.bind(this));
            },

            showReservation(reservationKey){
                console.log("Reservation to show: ", reservationKey);
                this.allReservations.forEach(function(group){
                    group.reservations.forEach(function(reservation){
//                window.Vue.set(reservation, 'show', true);
                        reservation.show = true;
                        console.log("Reservation changed to: ", reservation);
                    });
                });
            },
        },

        /**
         * Once mounted this component needs to load all reservations for the
         * hotelkey specified
         */
        mounted() {
            // Obtain all reservations on page load
            this.loadAllReservations();
        },

        computed: {},

        watch: {
            panel: function(){
                console.log('PANEL INDEX CHANGED:', this.panel);
            }
        }
    }
</script>

<style>
    .expansion-panel__header {
        padding: 0;
    }
</style>
