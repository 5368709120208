/**
 * Mixin that holds reusable methods for getting info about logged in user,
 * as default we remove password replacing it with blank string - ''
 *
 * How to use:
 * 1. import this mixin to your component:  eg. import {loggedUser} from '@/mixins/loggedUser';
 * 2. register mixin in your component:         mixins: [loggedUser],
 *
 * now inside of your component you got access to method:
 *    getLoggedUser()
 *    getLoggedUserKey()
 *    getLoggedUsername()
 *    getLoggedUserColumn(columnName)
 *
 */
export const loggedUser = {
    data () {
        return {
            /** data object that is being used only by this mixin is encapsulated within loggedUserData object */
            loggedUserData: {
                /** @var user - object containg logged AdminUser object */
                user: {},
                /** indicated whether we already loaded logged user object, so we don't have to load it again */
                userLoaded: false,
                /** @var forbiddenColumns, array containing columns that user cannot request with getLoggedUserColumn method */
                forbiddenColumns: ['password'],
            },

        }
    },
    /**
     * Code that runs when component was created
     */
    created(){
         //alert('mixin loggedUser has been hooked!'); // You can uncommend that to check if the mixin was hooked successfully
    },
    methods:{
        /**
         * returns entire logged-in AdminUser model
         */
        getLoggedUser: function() {
            if (!this.loggedUserData.userLoaded || this.loggedUserData.user == {}) {
                this.loadAdminUser();
            }
            return this.loggedUserData.user;
        },

        /**
         * returns logged in user - user_key
         */
        getLoggedUserKey: function() {
            let user = this.getLoggedUser();

            return user.user_key;
        },

        /**
         * returns logged in user - username
         */
        getLoggedUsername: function() {
            let user = this.getLoggedUser();

            return user.username;
        },

        /**
         * returns logged in user - username
         */
        getLoggedUserColumn: function(columnName) {
            if (columnName && this.isNotForbiddenColumn(columnName)) {
                let user = this.getLoggedUser();

                if (user.hasOwnProperty(columnName))

                return user[columnName];
            }
        },

        /**
         * Check if this column is not forbidden and user can access requested column
         * @param columnName
         * @returns boolean
         */
        isNotForbiddenColumn: function(columnName) {
            return this.loggedUserData.forbiddenColumns.includes(columnName);
        },

        /**
         * Loads logged AdminUser model from page service
         */
        loadAdminUser: function() {
            if (window.app.pageService) {
                let userObject = window.app.pageService.getUser();

                // remove password from this object and assign it to data property
                if (userObject) {
                    userObject.password = '';
                    this.loggedUserData.user = userObject;
                }

                if (this.loggedUserData.user != {}) {
                    this.loggedUserData.userLoaded = true;
                }
            }
        },
    }
}
