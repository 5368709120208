var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "pa-0": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.regionList,
                  label: "Region",
                  solo: "",
                  outlined: "",
                  dense: "",
                  "search-input": _vm.search,
                  autocomplete: "",
                  "prepend-icon": "place",
                  color: "lime",
                },
                on: {
                  "update:searchInput": function ($event) {
                    _vm.search = $event
                  },
                  "update:search-input": function ($event) {
                    _vm.search = $event
                  },
                  change: _vm.updateRegionSelected,
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.gotoRegionDashboard.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.selectedRegion,
                  callback: function ($$v) {
                    _vm.selectedRegion = $$v
                  },
                  expression: "selectedRegion",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }