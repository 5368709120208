var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", sm: "6", md: "4", lg: "3", "ma-1": "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "title font-weight-black" },
            [
              _c("v-icon", { staticClass: "pr-3" }, [_vm._v("card_giftcard")]),
              _vm._v("Voucher Creator\n        "),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-text", { staticClass: "body-2" }, [
            _vm.systemError
              ? _c("div", [_vm._v("Sorry, this content is not working")])
              : _vm._e(),
            _vm._v(" "),
            !_vm.systemError
              ? _c(
                  "div",
                  { staticClass: "m-3" },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-layout",
                          { attrs: { row: "", wrap: "" } },
                          [
                            _c(
                              "v-col",
                              {
                                attrs: {
                                  cols: "6>Type</v-col>\n                        <v-col cols=",
                                  6: "",
                                },
                              },
                              [_vm._v("****")]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                attrs: {
                                  cols: "6>Quantity</v-col>\n                        <v-col cols=",
                                  6: "",
                                },
                              },
                              [_vm._v("****")]
                            ),
                            _vm._v(" "),
                            _c("v-col", { attrs: { cols: "6" } }, [
                              _vm._v("Value (GBP)"),
                            ]),
                            _vm._v(" "),
                            _c("v-col", { attrs: { cols: "6" } }, [
                              _vm._v("****"),
                            ]),
                            _vm._v(" "),
                            _c("v-col", { attrs: { cols: "6" } }, [
                              _vm._v("Expiry:"),
                            ]),
                            _vm._v(" "),
                            _c("v-col", { attrs: { cols: "6" } }, [
                              _vm._v("****"),
                            ]),
                            _vm._v(" "),
                            _c("v-col", { attrs: { cols: "6" } }, [
                              _vm._v("Purpose"),
                            ]),
                            _vm._v(" "),
                            _c("v-col", { attrs: { cols: "6" } }, [
                              _vm._v("****"),
                            ]),
                            _vm._v(" "),
                            _c("v-col", { attrs: { cols: "6" } }, [
                              _vm._v("Notes:"),
                            ]),
                            _vm._v(" "),
                            _c("v-col", { attrs: { cols: "6" } }, [
                              _vm._v("****"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  on: {
                    click: function ($event) {
                      return _vm.create()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("create")]), _vm._v(" Create Vouchers")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }