var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-flex",
    [
      _c(
        "v-btn",
        { on: { click: _vm.resend } },
        [_c("v-icon", [_vm._v("send")]), _vm._v(" Resend")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }