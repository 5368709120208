var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "grid-list-md": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c("input", {
            attrs: { type: "hidden", name: _vm.field.fieldName },
            domProps: { value: _vm.field.value },
          }),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm4: "" } },
            [
              _c("v-select", {
                attrs: { items: _vm.regionOptions, autocomplete: "" },
                model: {
                  value: _vm.regionToAdd,
                  callback: function ($$v) {
                    _vm.regionToAdd = $$v
                  },
                  expression: "regionToAdd",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm2: "" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ies-green",
                  on: { click: _vm.pinBlogPostToRegion },
                },
                [_vm._v("\n                Add\n            ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-alert",
            {
              staticStyle: { width: "100%" },
              attrs: { type: "error", value: _vm.field.errors },
            },
            _vm._l(_vm.field.errors, function (error) {
              return _c("li", { key: error }, [
                _vm._v("\n                " + _vm._s(error) + "\n            "),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _vm._l(_vm.blogRegions, function (blogRegion) {
            return _c(
              "v-flex",
              { attrs: { xs12: "" } },
              [
                _c(
                  "v-container",
                  { attrs: { fluid: "", "grid-list-md": "" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "", wrap: "" } },
                      [
                        _c("v-flex", { attrs: { xs12: "", sm3: "" } }, [
                          _vm._v(_vm._s(blogRegion.region_name)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm2: "" } },
                          [
                            _c(
                              "v-btn",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.unpinBlogPostFromRegion(
                                      blogRegion.region_key
                                    )
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("delete")])],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }