var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.viewValues, function (blogRegion) {
        return _c(
          "div",
          { staticClass: "mb-3" },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.getRegionNameForKey(blogRegion)) +
                "\n        "
            ),
            _c(
              "v-btn",
              {
                staticClass: "ml-3",
                on: {
                  click: function ($event) {
                    return _vm.deleteRegion(blogRegion)
                  },
                },
              },
              [_vm._v("\n            Remove")]
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("hr", { staticClass: "mt-3 mb-3" }),
      _vm._v(" "),
      _c("v-select", {
        attrs: {
          label: "Choose a Region",
          items: _vm.regionOptions,
          autocomplete: "",
        },
        model: {
          value: _vm.regionToAdd,
          callback: function ($$v) {
            _vm.regionToAdd = $$v
          },
          expression: "regionToAdd",
        },
      }),
      _vm._v(" "),
      _c(
        "v-btn",
        { staticClass: "ies-green", on: { click: _vm.addRegion } },
        [_c("v-icon", [_vm._v("add")]), _vm._v(" Add")],
        1
      ),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: _vm.field.fieldName },
        domProps: { value: _vm.field.value },
      }),
      _vm._v(" "),
      _vm.field.errors
        ? _c(
            "v-alert",
            {
              staticStyle: { width: "100%" },
              attrs: { type: "error", value: _vm.field.errors },
            },
            _vm._l(_vm.field.errors, function (error) {
              return _c("li", { key: error }, [
                _vm._v("\n            " + _vm._s(error) + "\n        "),
              ])
            }),
            0
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }