var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shouldDisplay
    ? _c(
        "v-container",
        { staticClass: "smart-search-results" },
        [
          _c(
            "v-btn",
            { staticClass: "close-button", on: { click: _vm.closeResults } },
            [_vm._v("X")]
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              !_vm.results.length
                ? _c("v-col", { attrs: { cols: "12" } }, [
                    _vm._v("\n            No results found\n        "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.results, function (group) {
                return _vm.results.length
                  ? _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "div",
                          { staticClass: "smart-search-results--group-label" },
                          [_vm._v(_vm._s(group.label))]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-layout",
                          {
                            staticStyle: {
                              "max-height": "10rem",
                              "overflow-y": "auto",
                              "margin-top": "5px",
                            },
                            attrs: { row: "", wrap: "" },
                          },
                          _vm._l(group.items, function (item) {
                            return _c("v-col", { attrs: { cols: "12" } }, [
                              _c("a", { attrs: { href: item.url } }, [
                                _vm._v(_vm._s(item.label)),
                              ]),
                            ])
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e()
              }),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }