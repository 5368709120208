<template>
    <div>
        <!-- list must be list of ['text' => x, 'value' => y] -->
        <v-select
            v-model="viewValue"
            :label="field.schema.displayName"
            :items="categories"
            autocomplete
        ></v-select>
        <input type="hidden" :name="field.fieldName" :value="field.value">
        <v-alert type="error" :value="field.errors" style="width: 100%">
            <li v-for="error in field.errors">
                {{ error }}
            </li>
        </v-alert>
    </div>

</template>

<script>
    /**
     * Allows the application of a facility to a hotel by declaring how the hotel makes the facility
     * available - is it a site-wide facility shared between guests, or private to specific rooms
     */
    export default {
        name: 'ies-displaygroup',
        props: ['field'],
        data() {
            return {
                categories: [{text: "In-room features", value: 1}, { text: "Dining", value: 2}, {text: "Activity", value: 4}, {text: "Essential", value: 8}, {text: "Family", value: 16}],
                viewValue: parseInt(this.field.value),
            }
        },
        watch: {
            viewValue: function (newValue) {
                this.field.value = newValue;
            }
        }
    }
</script>
