var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "row-sm": "", wrap: "" } },
        [
          _vm.shouldShowDropBox
            ? _c(
                "div",
                {
                  staticClass: "drag-drop-image",
                  on: {
                    drop: _vm.dropFiles,
                    dragenter: _vm.checkDrop,
                    dragover: _vm.checkDrop,
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "close-button" },
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.closeDropBox()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("cancel")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "h2",
                    [
                      _c("v-icon", [_vm._v("file_upload")]),
                      _vm._v(" Drag your images here to upload"),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-card-title",
                    [_c("v-icon", [_vm._v("upload")]), _vm._v(" Upload")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-expansion-panels",
                        [
                          _c(
                            "v-expansion-panel",
                            { attrs: { model: "openPanels" } },
                            [
                              _c("v-expansion-panel-header", [
                                _c(
                                  "b",
                                  [
                                    _c("v-icon", [_vm._v("cloud_upload")]),
                                    _vm._v(" Upload New Images"),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panel-content",
                                [
                                  _c(
                                    "v-container",
                                    { attrs: { fluid: "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { "row-sm": "", wrap: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4",
                                              },
                                            },
                                            [
                                              _c(
                                                "fieldset",
                                                [
                                                  _c("legend", [
                                                    _vm._v(
                                                      "Photos From Your Device"
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    [
                                                      _c("v-card-text", [
                                                        _vm._v(
                                                          "Select files for upload:\n                        "
                                                        ),
                                                        _c("input", {
                                                          attrs: {
                                                            type: "file",
                                                            accept:
                                                              "image/jpeg",
                                                            multiple: "",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.imageFileSelected(
                                                                $event
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-card-text", [
                                                    _vm._v("Selected Files:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    _vm.selectedFiles,
                                                    function (file, index) {
                                                      return _c(
                                                        "v-flex",
                                                        { key: index },
                                                        [
                                                          _c(
                                                            "v-card-text",
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  file.file.name
                                                                ) + " "
                                                              ),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeFile(
                                                                          index
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Deselect"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "6" } },
                                            [
                                              _c(
                                                "fieldset",
                                                [
                                                  _c("legend", [
                                                    _vm._v("Properties"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs12: "" } },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: "display",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Display in Slideshows:"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.allImages
                                                                .display_slideshow,
                                                            expression:
                                                              "allImages.display_slideshow",
                                                          },
                                                        ],
                                                        attrs: {
                                                          name: "display",
                                                          id: "display",
                                                          type: "checkbox",
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              _vm.allImages
                                                                .display_slideshow
                                                            )
                                                              ? _vm._i(
                                                                  _vm.allImages
                                                                    .display_slideshow,
                                                                  null
                                                                ) > -1
                                                              : _vm.allImages
                                                                  .display_slideshow,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.allImages
                                                                  .display_slideshow,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm.allImages,
                                                                    "display_slideshow",
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm.allImages,
                                                                    "display_slideshow",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.allImages,
                                                                "display_slideshow",
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs12: "" } },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label:
                                                            "Sort Order in Slideshows:",
                                                          name: "sort_order",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.allImages
                                                              .sort_order,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.allImages,
                                                              "sort_order",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "allImages.sort_order",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs12: "" } },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items: _vm.imageTypes,
                                                          label:
                                                            "Select Type of Image",
                                                          "item-text": "text",
                                                          "item-value": "value",
                                                          dense: "",
                                                          id: "image-type",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.allImages
                                                              .imageType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.allImages,
                                                              "imageType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "allImages.imageType",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs12: "" } },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label:
                                                            "Image Caption:",
                                                          name: "caption",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.allImages
                                                              .caption,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.allImages,
                                                              "caption",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "allImages.caption",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.selectedFiles.length < 2
                                                    ? _c(
                                                        "v-flex",
                                                        { attrs: { xs12: "" } },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              label:
                                                                "Copyright Information:",
                                                              name: "copyright",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.allImages
                                                                  .copyright,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.allImages,
                                                                    "copyright",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "allImages.copyright",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.selectedFiles.length < 2
                                                    ? _c(
                                                        "v-flex",
                                                        { attrs: { xs12: "" } },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              label:
                                                                "Description:",
                                                              name: "description",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.allImages
                                                                  .description,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.allImages,
                                                                    "description",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "allImages.description",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "fieldset",
                                                [
                                                  _c("legend", [
                                                    _vm._v("Relationships"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      attrs: {
                                                        row: "",
                                                        wrap: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.shouldShowHotelPicker,
                                                              expression:
                                                                "shouldShowHotelPicker",
                                                            },
                                                          ],
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c(
                                                            "ies-hotel-and-room-key",
                                                            {
                                                              attrs: {
                                                                field:
                                                                  _vm.selectRoomField,
                                                                configuration:
                                                                  '{"shouldEmitOnSelect":true, "selectHotelEventName":"Image Uploader: select hotel", "selectRoomEventName": "Image Uploader: select room type"}',
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.shouldShowRegionPicker,
                                                              expression:
                                                                "shouldShowRegionPicker",
                                                            },
                                                          ],
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c("v-select", {
                                                            attrs: {
                                                              label: "Region",
                                                              items:
                                                                _vm.regions,
                                                              autocomplete: "",
                                                            },
                                                            on: {
                                                              change:
                                                                _vm.regionChosen,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.allImages
                                                                  .region_key,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.allImages,
                                                                    "region_key",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "allImages.region_key",
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  !_vm.allImages
                                                                    .region_key,
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.clearRegion,
                                                              },
                                                            },
                                                            [_vm._v("Clear")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ies-green",
                                                  on: {
                                                    click: _vm.uploadToLibrary,
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("save"),
                                                  ]),
                                                  _vm._v(" Save"),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }