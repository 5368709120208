<template>
    <v-col xs="12" md="6" lg="4" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">card_giftcard</v-icon> Voucher Summary Statistics
            </v-card-title>
            <v-card-text>
                <line-chart :chartdata="chartData" :options="chartOptions"></line-chart>
                <v-flex x12>
                    Vouchers Purchased: {{ chartData.count_purchased }}
                </v-flex>
                <v-flex x12>
                    Vouchers Redeemed: {{ chartData.count_redeemed }}
                </v-flex>
                <v-flex x12>
                    Vouchers Expired: {{ chartData.count_expired }}
                </v-flex>
                
            </v-card-text>
        </v-card>
    </v-col>
</template>

<script>
    import LineChart from '@/components/charts/LineChart';
    import axios from 'axios';

    /**
     * Display voucher statistics to the user
     */
    export default {
        props:['datasource'],
        components: {
            LineChart,
        },

        data() {
            return {
                apiData:  {},
                chartData: {},
                count_purchased: 0,
                count_redeemed: 0,
                count_expired: 0,
                chartOptions: {
                    maintainAspectRatio: false,
                    responsive: true,
                    events: ['click'],
                    title: {
                        display: false,
                        text: ''
                    }
                }
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            this.loadVoucherStats();
            setInterval( this.loadVoucherStats, 60000 );
        },

        methods: {

            formatDate(date){
                var dd = date.getDate();
                var mm = date.getMonth()+1;
                var yyyy = date.getFullYear();
                if(dd<10) {dd='0'+dd}
                if(mm<10) {mm='0'+mm}
                date = yyyy+'-'+mm+'-'+dd;
                return date
            },

            /**
             * Load the latest data from the API
             */
            async loadVoucherStats(){
                const response = await axios.get('api/vouchers-dashboard-panel').then(response => {
                    this.purchasedVoucherData = response.data[0];
                    this.redeemedVoucherData = response.data[1];
                    this.expiredVoucherData = response.data[2];
                    this.buildDataObject();
                }).catch(error => {
                    console.log(error);
                });
            },

            /**
             * Build the data object required by the line chart using API data to
             * fill the datasets objects and labels
             */
            buildDataObject() {
                let data = {
                    labels: [],
                    datasets: [
                      {
                          data: [],
                          label: "No. of Vouchers Purchased",
                          borderColor: "#C1CD23",
                          fill: false
                      },
                      {
                          data: [],
                          label: "No. of Vouchers Redeemed",
                          borderColor: "#C62828",
                          fill: false
                      },
                      {
                          data: [],
                          label: "No. of Vouchers Expired",
                          borderColor: "#8e5ea2",
                          fill: false
                      }
                    ]
                };

                //Adds previous 7 days date to labels
                for (var i=0; i<7; i++) {
                    var d = new Date;
                    d.setDate(d.getDate() - i);
                    data.labels.unshift(this.formatDate(d));
                }

                //Loop through each date and add a 0 value to each dataset
                data.labels.forEach((date, key) => {
                    data.datasets[0].data[key] = 0;
                    data.datasets[1].data[key] = 0;
                    data.datasets[2].data[key] = 0;
                    //Loop through each set of resulta and overwrite dataset with result count if date is matching
                    for (let result of Object.entries(this.purchasedVoucherData)) {
                        if (date == result[1].creation_date) {
                            data.datasets[0].data[key] = result[1].my_count;
                        }
                    }
                    for (let result of Object.entries(this.redeemedVoucherData)) {
                        if (date == result[1].redeemed.substring(0,10)) {
                            data.datasets[1].data[key] = result[1].my_count;
                        }
                    }
                    for (let result of Object.entries(this.expiredVoucherData)) {
                        if (date == result[1].expires.substring(0,10)) {
                            data.datasets[2].data[key] = result[1].my_count;

                        }
                    }
                });

                //Get count totals
                data.count_purchased = data.datasets[0].data.reduce((a, b) => a + b, 0);
                data.count_redeemed = data.datasets[1].data.reduce((a, b) => a + b, 0);
                data.count_expired = data.datasets[2].data.reduce((a, b) => a + b, 0);

                this.chartData = data;
            },

        }
    }
</script>
