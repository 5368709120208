var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "ma-2" },
    [
      _c(
        "v-card-title",
        { staticClass: "title font-weight-black" },
        [
          _c("v-icon", { staticClass: "pr-3" }, [_vm._v("hotel")]),
          _vm._v(" Upcoming Reservations\n    "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c(
                "v-flex",
                [
                  _c(
                    "v-btn",
                    { attrs: { href: "/upcoming-confirmed-bookings/list" } },
                    [_vm._v("Upcoming Confirmed Bookings")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { href: "/upcoming-confirmed-cancellations/list" },
                    },
                    [_vm._v("Confirmed Cancellations")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        href: "/upcoming-unconfirmed-cancellations/list",
                      },
                    },
                    [_vm._v("New Cancellations")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                [
                  _c("v-btn", { attrs: { href: "/completed-stays/list" } }, [
                    _vm._v("Completed Stays"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }