<template>
    <div>
        <v-flex xs12>
            <label :for="this.field.fieldName"> {{ this.field.schema.displayName }}: </label>
        </v-flex>
        <!-- list must be list of ['text' => x, 'value' => y] -->
        <v-select
            v-model="linkType"
            label="Link Type"
            :items="typeOptions"
            autocomplete
        ></v-select>

        <v-text-field
            label="Route Name"
            v-if="linkType == 1"
            v-model="routeName"
        ></v-text-field>

        <v-text-field
            label="URL"
            v-if="linkType == 2"
            v-model="explicitUrl"
        ></v-text-field>

        <v-select
            v-if="linkType == 3"
            v-model="pageID"
            label="Portal Page to Link To"
            :items="portalPageOptions"
            autocomplete
        ></v-select>


        <input type="hidden" :name="field.fieldName" :value="field.value">
        <v-alert type="error" :value="field.errors" style="width: 100%">
            <li v-for="error in field.errors">
                {{ error }}
            </li>
        </v-alert>
    </div>
</template>

<script>
    /**
     * Allows the setting of quick menu links, which are dynamically generated from JSON
     * which must be set by specialised input tools like this one which can formulate the
     * correct JSON for the set up the user has specified
     */
    export default {
        name: 'ies-quickMenuLink',
        props: ['field'],
        data() {
            return {
                linkType: 0,
                pageID: 0,
                routeName: '',
                explicitUrl: '',
                params: [],
                typeOptions: [{value: 0, text: "No Link"}, { value: 1, text: "Fixed Named Route"}, {value: 2, text: "Explicit URL"}, { value: 3, text: "Dynamically Generated Portal Page"}],
                portalPageOptions: [],
                viewModel: {}
            }
        },
        created:function() {
            var savedValue = JSON.parse(this.field.value);
console.log("SAVED VALUE: ", savedValue);
            this.linkType = savedValue.type;
            this.pageID = savedValue.portal_page_key;
            this.explicitUrl = savedValue.url;
            this.routeName = savedValue.route;
            this.params = savedValue.params;

            this.getPages();
        },
        methods: {
            
            getPages:function(){
                axios.get('/api/portal-pages')
                .then(function(response){
                    var pages = [];
                    response.data.forEach(function(record){
                        var pageType = 'unknown';
                        switch(record.page_mode){
                            case 1: 
                                pageType = "List";
                                break;
                            case 2: 
                                pageType = "Edit Record";
                                break;
                            case 3:
                                pageType = "Report";
                                break;
                            case 4: 
                                pageType = "Dashboard";
                                break;
                        }
                        pages.push({ text: '(' + pageType + ') ' + record.page_name, value: record.portal_page_key });
                    });
                    this.portalPageOptions = pages;
                }.bind(this));
            },

            updateDataModel:function(){
                switch(this.linkType){
                    case 0:
                        this.field.value = JSON.stringify({type: 0});
                        break;
                    case 1:
                        this.field.value = JSON.stringify({type: 1, route: this.routeName});
                        break;
                    case 2:
                        this.field.value = JSON.stringify({type: 2, url: this.explicitUrl});
                        break;
                    case 3:
                        this.field.value = JSON.stringify({type: 3, portal_page_key: this.pageID});
                        break;
                }
            }
        },
        watch: {
            linkType: function (newValue) {
                this.updateDataModel();
            },
            pageID: function (newValue) {
                this.updateDataModel();
            },
            routeName: function (newValue) {
                this.updateDataModel();
            },
            explicitUrl: function (newValue) {
                this.updateDataModel();
            }
        }
    }
</script>
