<template>
    <v-col sm="6" md="4" lg="2" ma-1>
        <v-card>
            <v-card-title class="title font-weight-black">
                <v-icon class="pr-3">dns</v-icon>Server Status
            </v-card-title>
        
            <v-card-text class="body-2">Server load:
                <v-progress-circular 
                    class="right-float"
                    :rotate="-90"
                    :value='serverLoadPercent'
                    :color="getServerLoadColour()"
                ></v-progress-circular>
            </v-card-text>
 
            <v-card-text class="body-2">Memory usage:
                <v-progress-circular
                    class="right-float"
                    :rotate="-90"
                    :value='memoryUsagePercent'
                    :color="getMemoryUsageColour()"
                ></v-progress-circular>
            </v-card-text>

            <v-card-text class="body-2">Disk space used:
                <v-progress-circular
                    class="right-float"
                    :rotate="-90"
                    :value='diskSpacePercent'
                    :color='getDiskSpaceColour()'
                ></v-progress-circular>
            </v-card-text>

            <div class="text-xs-center">
                <v-btn
                    v-on:click='getData'
                    class="ies-green btn"
                >Refresh</v-btn>
            </div>
        </v-card>
    </v-col>
</template>

<script>
    import axios from 'axios';

    export default {
        props:[],

        data: function() {
            return {
                colourOk:           'lime',
                colourWarning:      'orange lighten-1',
                colourSerious:      'red lighten-1',
                requestData:        [],
                serverLoadPercent:  0,
                memoryUsagePercent: 0,
                diskSpacePercent:   0, 
                errors:             null
            }
        },

        /**
         * On mounted - make the first call to the API to get the server status data
         */
        mounted: function(){
            this.getData();
        },

        methods: {
            getServerLoadColour: function(){
                if(this.serverLoadPercent > 80){
                    return this.colourSerious;
                }
                if (this.serverLoadPercent > 50){
                    return this.colourWarning;
                }
                return this.colourOk;
            },

            getMemoryUsageColour: function(){
                if(this.memoryUsagePercent > 70){
                    return this.colourSerious;
                }
                if (this.memoryUsagePercent > 50){
                    return this.colourWarning;
                }
                return this.colourOk;
            },

            getDiskSpaceColour: function(){
                if(this.diskSpacePercent > 80){
                    return this.colourSerious;
                }
                if (this.diskSpacePercent > 70){
                    return this.colourWarning;
                }
                return this.colourOk;
            },

            /*
            * Make a call to the api to retrieve the server-details data, then call for the
            * fields to be updated.
            */
            getData(){
                axios.get('/api/server-details')
                .then((response)=>{
                    this.requestData = response.data;
                    this.updateFields();
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            /*
            * Make a call to the api to retrieve the server-details data, then call for the
            * fields to be updated.
            */
            updateFields(){
                this.processServerLoad();
                this.processMemoryUsage();
                this.processDiskSpace();
            }, 

            /*
            * Get the current server load info from the data object, then calculates the % useage
            * to be displayed.
            */
            processServerLoad(){
                if (!this.requestData.server_load) {
                    this.serverLoadPercent = 0;
                } else {
                    let averageServerLoad = 0;
                    this.requestData.server_load.forEach(element => {
                        averageServerLoad += element;
                    }); 
                    averageServerLoad /= 3;
                    this.serverLoadPercent = Math.round(parseFloat(averageServerLoad));
                }
            },

            /*
            * Get the memory usage info from the data object, then calculates the % useage
            * to be displayed.
            */
            processMemoryUsage(){
                if (!this.requestData.server_memory_usage) {
                    this.memoryUsagePercent = 0;
                } else {
                   let memoryUsage = this.requestData.server_memory_usage.current;
                   let memoryUsageMax = this.requestData.server_memory_usage.total;
                   this.memoryUsagePercent = (memoryUsage / memoryUsageMax) * 100;
                }
            },

            /*
            * Get the current disk space info from the data object, then calculates the % useage
            * to be displayed.
            */
            processDiskSpace(){
                if (!this.requestData.disk_space) {
                    this.diskSpacePercent = 0;
                } else {       
                   let diskSpaceMax = this.requestData.disk_space.total_space;
                   let diskSpaceUsed = diskSpaceMax - this.requestData.disk_space.free_space;
                   this.diskSpacePercent = Math.round((diskSpaceUsed / diskSpaceMax) * 100);
                }
            },

        },

        computed: {
            serverLoadColour: function(){
                if(this.serverLoadPercent > 80){
                    return this.colourSerious;
                }
                if (this.serverLoadPercent > 50){
                    return this.colourWarning;
                }
                return this.colourOk;
            },

            memoryUsageColour: function(){
                if(this.memoryUsagePercent > 70){
                    return this.colourSerious;
                }
                if (this.memoryUsagePercent > 50){
                    return this.colourWarning;
                }
                return this.colourOk;
            },

            diskSpaceColour: function(){
                if(this.diskSpacePercent > 80){
                    return this.colourSerious;
                }
                if (this.diskSpacePercent > 70){
                    return this.colourWarning;
                }
                return this.colourOk;
            }
        }
    }
</script>

<style scoped>

.right-float {
  float: right;
}

</style>
