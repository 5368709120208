<template>
    <v-navigation-drawer v-model="showMenu" id="sideNav" temporary hide-overlay class="no-print">
        <v-list>
    
             <v-list-item @click.native.stop="showMenu = !showMenu">
                 <v-list-item-action>
                     <v-icon>navigate_before</v-icon>
                 </v-list-item-action>
             </v-list-item>

             <v-divider class="mt-3 mb-3"></v-divider>

            <v-list-item class="d-lg-none">
                <ies-smart-search></ies-smart-search>
            </v-list-item>

            <v-divider class="mt-3 mb-3"></v-divider>

            <v-list-group class="group"  v-for="item in menuOptions" :key="item.name" v-if="menuOptions">
<!--HERE IS SINGLE ITEM-->
                <v-list-item slot="activator"
                             :href="item.url"
                             v-if="!item.subItems || item.subItems.length == 0"
                             :disabled="item.is_active === false"
                             class="singleRowLink"
                             :class="{ 'selected': item.selected }"
                >
                    <v-list-item-action>
                        <v-icon left >{{ item.icon }} </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.name }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

<!--HERE IS ITEM WITH SUB ITEMS-->
                <v-list-item slot="activator"
                            :class="{ 'selected': item.selected }"
                            v-if="item.subItems && item.subItems.length > 0"
                            :disabled="item.is_active === false"
                >
                    <v-list-item-action>
                        <v-icon left >{{ item.icon }} </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.name }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
<!--HERE ARE SUB ITEMS-->
                <v-list-item v-if="item.subItems"
                    v-for="subItem in item.subItems"
                    :key="subItem.name"
                    :href="subItem.url"
                    :disabled="subItem.is_active === false"
                     class="subItems"
                >
                    <v-list-item-action>
                        <v-icon left >{{ subItem.icon }} </v-icon>
                    </v-list-item-action>
                    <v-list-item-content :class="{ 'selected': subItem.selected }">
                        <v-list-item-title>
                            {{ subItem.name }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>

            <v-divider class="mt-3 mb-3"></v-divider>

        </v-list>
    </v-navigation-drawer>
</template>

<script>
    // Import the application event bus so we can listen for user or menu data changes and react accordingly
    import EventBus from '../../eventBus';
    import axios from 'axios';

    export default {
        data: function() {
            return {
                // Boolean flag to display the navigation drawer main menu.  Do not show it on page load
                showMenu:       false,
                menuOptions:    [],
                metadata:       {},
                menuOptions:    [],
                recentlysaved:  [],
                user:           null,
            }
        },

        /**
         * Perform the following actions on startup
         */
        mounted: function(){
            // If any component announces that the user has requested the main menu toggle then this component must react to the request
            this.$eventHub.$on('main-menu: toggle', showMenu => {
                this.showMenu = showMenu;
            });

            this.removeIconFromSingles();
            this.loadUser();
            this.getMenu();
        },
        methods: {
            updateNav(){
                this.showMenu = !this.showMenu;
                this.$eventHub.$emit('main-menu: toggle', this.showMenu);
            },

            /**
             * Obtains a new menu directly from the API, replacing the one
             * currently in use if the request is successful.  This will 
             * mean that the user keeps their existing menu if the one
             * downloaded from the API failed
             */
            getMenu:function(){
                axios.get('/api/menu/list')
                .then(function(response){
                    this.menuOptions = response.data.menu;
                }.bind(this));
            },

            removeIconFromSingles(){
/*
// what is this even supposed to do? its broken since vuetify 2.6
                setTimeout(function(){
                    var singles = $('div.singleRowLink');
                    singles.parent().find('div.list__group__header__append-icon').remove();
                }, 200);
*/
            },

            /**
             * Find menu item (and submenu item) what user is currently on so we can set as selected on menu
             * and change color of menu item and it's submenu item
             */
            findActiveItem() {
                let currentPathName = window.location.pathname.toLowerCase();

                if (currentPathName && currentPathName !== '/') {
                    this.menuOptions.forEach(mainItem => {

                        // check if we should select this main item
                        let mainUrl = mainItem.url.toLowerCase();
                        if (mainUrl && mainUrl.includes(currentPathName)) {
                            mainItem.selected = true;
                        }

                        // check if mainItem got any subItems
                        if (mainItem.subItems && Array.isArray(mainItem.subItems) && mainItem.subItems.length) {
                            mainItem.subItems.forEach(subItem => {
                                let subUrl = subItem.url.toLowerCase();
                                // check if we should select this sub item
                                if (subItem.url && subItem.url.includes(currentPathName)) {
                                    subItem.selected = true;
                                    mainItem.selected = true;
                                }
                            })
                        }
                    });
                }
            },

    loadUser(context) {
        var self = this;
        axios.get('/api/auth/user').then(function(response) {
            // check if API returned data
            if (response.status === 200 && response.data.user && response.data.user.user_key > 0) {
                // commit user
                self.user = response.data.user;
                console.log(self.user);
            }
        });
    },


            /**
             *
             */
            getDepartment(departmentID) {
               switch(departmentID){
                   case 1: return "Bookings";
                   case 2: return "Director";
                   case 3: return "Editorial";
                   case 4: return "Marketing";
                   case 5: return "Rates";
                   case 6: return "Web";
                   case 7: return "Accounts";
                   case 8: return "Hotel";
               }
               return 'Unknown';
            }
        },
    }

</script>

<style scoped>
    .v-navigation-drawer__content a {
        color: rgba(0,0,0,.87) !important;
    }

    #sideNav {
        z-index: 10000;
    }

    #sideNav a {
        text-decoration: none;
    }

    #sideNav .singleRowLink > a:hover {
        background-color: unset;
    }

    .selected {
        color: rgb(193, 205, 35) !important;
    }

    .user a {
        color: rgb(8, 129, 147) !important;
    }

    .text--disabled {
        width: 100%;
    }

    .list__group__items {
        padding-left: 30px;
    }

    .list__group__header--active {
        color: rgb(193, 205, 35) !important;
    }

    /* Introduced to reduce the left spacing on main groups */
    .v-list-item {
        padding-left: 0px !important;
    }

    .group .v-list-group__header {
        color: red !important;
    }

    .subItems {
        padding-left: 40px !important;
    }
</style>
