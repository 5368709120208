
<template>
<v-card>
    <v-container>
        <v-layout row justify-center>
            <!-- Date Picker -->
            <v-flex xs12 sm12 md6 lg6 px-4>
                <ies-date-range
                    v-if="settings.datePicker"
                    class="justify-center"
                    :startDate="start"
                    :endDate="end"
                ></ies-date-range>
            </v-flex>
            <!-- Hotel Picker -->
            <v-flex xs12 sm12 md3 lg3 px-4 pt-5>
                    <v-select
                    v-if="settings.hotelPicker"
                    :items="hotelDropdown"
                    v-model="form.hotel"
                    label="Select hotel"
                    autocomplete
                    item-text="text"
                    item-value="value"
                    dense
                    hint="Type to search for a hotel"
                    persistent-hint
                    id="hotel-select"
                ></v-select>
                <!-- Region Picker -->
                <v-select
                    v-if="settings.regionPicker"
                    :items="regions"
                    v-model="form.region"
                    label="Select region"
                    autocomplete
                    item-text="region_name"
                    item-value="region_value"
                    dense
                    hint="Type to search for a region, you can select multiple regions"
                    persistent-hint
                    id="region-select"
                >

                    <template slot="item" slot-scope="data">
                        <option
                            v-text="data.item.region_name"
                            :class="data.item.region_type"
                        ></option>
                    </template>

                </v-select>
            </v-flex>
            <!-- <v-flex xs12 sm12 md3 lg3 px-4> -->
                <!-- type of hotel -->
                <!-- <v-layout row pt-4>
                    <v-flex xs12 class="text-xs-center" v-if="settings.typePicker">
                        <v-subheader>Type of Hotel</v-subheader>
                        <v-checkbox
                            label="Upfront"
                            v-model="typeCheckboxes.UPF"
                        ></v-checkbox>
                        <v-checkbox
                            label="In Arrears"
                            v-model="typeCheckboxes.ARR"
                        ></v-checkbox>
                    </v-flex>
                </v-layout> -->
                <!-- balance -->
                <!-- <v-layout row>
                    <v-flex xs12 class="text-xs-center" v-if="settings.balancePicker">
                        <v-subheader>Balance</v-subheader>
                        <v-checkbox
                            label="Positive"
                            v-model="balanceCheckboxes.positive"
                        ></v-checkbox>
                        <v-checkbox
                            label="Negative"
                            v-model="balanceCheckboxes.negative"
                        ></v-checkbox>
                    </v-flex> 
                </v-layout>
            </v-flex> -->
        </v-layout>
        <!-- Submit -->
        <v-layout row class="text-md-center" ma-4>
            <v-flex justify-center>
                <v-btn @click="submit">Search</v-btn>
                <v-btn color="error" @click="loadDefaults">Clear</v-btn>
            </v-flex>
        </v-layout>
    </v-container>
</v-card>
</template>

<script>
import EventBus from '@/eventBus'
import { format, subDays } from 'date-fns';


/**
 * This component NEEDS the settings PROP to work.
 * Every top level property corresponds to an input that 
 * will SHOW ONLY IF SET in the object. 
 * 
 * Each top level property has an object as its value where 
 * you can set settings for that input. 
 * 
 * For now it only has default values for the inputs. Feel free to add 
 * more and make sure you document them here :)
 * 
 * {
 *      datePicker: {
 *          dateFrom: '2018-10-02',
 *          dateTo: '2018-10-03',
 *      },
 *      hotelPicker: {
 *          hotels: [],
 *      },
 *      regionPicker: {
 *          regions: [],
 *      },
 *      typePicker: {
 *          type: 'UPF,ARR',
 *      },
 *      balancePicker: {
 *          balance: 'negative,positive'
 *      }
 * }
 * 
 */
export default {
    props: ['settings'],
    data() {
        return {
            hotels: [],
            selectedHotels: [],
            regions: [],
            selectedRegions: [],
            typeCheckboxes: {
                UPF: true,
                ARR: true,
            },
            balanceCheckboxes: {
                negative: true,
                positive: true,
            },
            form: {
                hotel: '',
                region: '',
                type_of_hotel: 'UPF,ARR',
                balance: 'negative,positive',
                date: {
                    from: '',
                    to: '',
                }
            },
        }
    },
    created() {
        this.getHotels();
        this.getRegions();
        this.loadDefaults();
        this.registerListeners();
    },
    computed: {
            start: function() {
                return this.settings.datePicker.dateFrom || '2000-01-01';
            },
            end: function() {
                return this.settings.datePicker.dateTo || format(new Date(), 'YYYY-MM-DD');
            },
            hotelDropdown: function() {
                return this.formatHotels(this.hotels)
            }
    },
    watch: {
        typeCheckboxes: { 
            handler(typeCheckboxes) {
                let types = [];
                for (let type in typeCheckboxes) {
                    if (typeCheckboxes[type]) {
                        types.push(type)
                    } 
                }

                this.form.type_of_hotel = types.join(',');
            },
            deep: true
        },
        balanceCheckboxes: { 
            handler(balanceCheckboxes) {
                let types = [];
                for (let type in balanceCheckboxes) {
                    if (balanceCheckboxes[type]) {
                        types.push(type)
                    } 
                }

                this.form.balance = types.join(',');
            },
            deep: true
        }
    },
    methods: {
        // Get all hotels for hotel dropdown
        async getHotels() {
            const res = await axios('api/hotels')
            this.hotels = res.data
        },
        
        // Get all regions for region dropdown
        async getRegions() {
            const res = await axios('api/region-dropdown')
            this.regions = res.data
        },
        
        // Binds defaults passed in props to query object
        loadDefaults() {
            for (let key in  this.settings) {
                switch(key) {
                    case 'datePicker':
                        this.form.date.from    = this.settings[key].dateFrom
                        this.form.date.to      = this.settings[key].dateTo
                        break;
                    case 'hotelPicker':
                        this.form.hotel    = this.settings[key].hotels
                        // this.form.date.to  = this.settings[key].dateTo
                        break;
                }
            }
            
        },
        
        // Register all event listeners here to keep created() tidy.
        registerListeners() {
             EventBus.$on('ies-date-range Changed', (dateRange) => {
                this.form.date.from = dateRange.range[0] 
                this.form.date.to = dateRange.range[1] 
            })
        },
        
        // Format hotels to be displayed in select dropdown.
        formatHotels(hotels) {
            return hotels.map(hotel => {
                return {
                    text: `${hotel.hotel_code} - ${hotel.hotel_name}`,
                    value: hotel.hotel_key
                }
            })
        },

        // Submit current form configuration
        submit() {
            EventBus.$emit('accounts-filter', this.form); 
        },

        // Clear form  back to defaults.
        // clear() {
        //     this.form = {
        //         hotel: '',
        //         region: '',
        //         type_of_hotel: 'UPF,ARR,FIX',
        //         balance: 'negative,positive',
        //         date: {
        //             from: '2000-01-01',
        //             to: format(new Date(), 'YYYY-MM-DD')
        //         }
        //     }
        //     this.typeCheckboxes = {
        //         UPF: true,
        //         ARR: true,
        //         FIX: true,
        //     }
        //     this.balanceCheckboxes = {
        //         negative: true,
        //         positive: true,
        //     }
        // }
        
    }
}
</script>

<style scoped>
    .continent {
        padding: 5px;
        background-color: #C1CD23;
        color: rgba(0,0,0,.7);
        font-weight: 700;
        width: 100%;
    }

    .country {
        padding: 5px;
        background-color: #66d6ff;
        color: rgba(0,0,0,.7);
        width: 60%;
    }

    .region {
        padding: 5px;
        margin-left: 20px;
    }

    .subregion {
        padding: 5px;
        margin-left: 40px;
    }
</style>

