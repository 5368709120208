var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value
    ? _c(
        "v-flex",
        [
          _c(
            "v-btn",
            { attrs: { href: _vm.addressLink, target: "_blank" } },
            [_c("v-icon", [_vm._v("mail")])],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }