<template>
    <v-flex>{{ fullName }}</v-flex>
</template>

<script>
/**
 * Outputs the reviewers full name based on their reviewer_key in hotel_visits
 *
 */
export default {
    name: 'ies-reviewer-name',
    props: ['field', 'value'],
    data () {
        return {
			fullName: '',
//<<<<<<< HEAD
            allReviewers: []
		}
    },

    created(){
        this.loadReviewers();
    },

    methods: {

        loadReviewers(){
		    axios.get('/api/hotel-reviewers')
                .then( (response) => {
                    this.allReviewers = response.data;
                    this.setReviewerName();
                });
        },

        setReviewerName(){
            console.log("HOTEL REVIWERS: set reviewr name");
		    if (this.value == 0) {
			    this.fullName = 'Other';
            }
            else {
                this.allReviewers.forEach( (reviewer) => {
				    if (reviewer.user_key == this.value) {
					    console.log( reviewer.first_name + ' ' + reviewer.surname);
    					this.fullName = reviewer.first_name + ' ' + reviewer.surname; 
	    			}
		    	});
            }
        }

    },

    watch: {
        field: function(newValue){
            console.log("HOTEL REVIEWERS: The field watch fired", newValue);
        },
        value: function(newValue){
            console.log("HOTEL REVIEWERS: The value watch fired", newValue);
            this.setReviewerName();
        }
    }
/*
=======
		}
    },

	created(){
		var userKey = this.value;
		if (userKey == 0) {
			this.fullName = 'Other';
		}

		axios.get('/api/hotel-reviewers')
		.then( (response) => {	
			response.data.forEach( (reviewer) => {
				if (reviewer.user_key == userKey) {
					console.log( reviewer.first_name + ' ' + reviewer.surname);
					this.fullName = reviewer.first_name + ' ' + reviewer.surname; 
				}
			});
		});		
	}
>>>>>>> 069c3b7f45af976b5d0cd4f4e095725d5466a7a8
*/
}
</script>


